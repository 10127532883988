import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import { DatePicker } from 'rsuite'
import '../styles/Pagamentos.scss';
import { ptBR } from 'date-fns/locale';
import AsyncSelect from 'react-select/async';
const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalPagamentos = ({isOpen, onHide, dataLine, isEdit, loadPagamentos}) => {
  const [valorPago, setValorPago] = useState<number | null>(null);
  const [isDataPagamento, setIsDataPagamento] = useState<Date | null>(null)
  const [selectedContaCorrente, setSelectedContaCorrente] = useState<ISelectOption | null>(null);
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState<ISelectOption | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = () => {
    onHide();
    setSelectedContaCorrente(null);
    setIsDataPagamento(null);
    setValorPago(null);
    setSelectedFormaPagamento(null);
    setStatus('ATIVO');
    setDescricao(null);
    onClear();
  };

  const onClear = () => {
    setSelectedContaCorrente(null);
    setIsDataPagamento(null);
    setValorPago(null);
    setSelectedFormaPagamento(null);
    setStatus('ATIVO');
    setDescricao(null);;
  };

  const cadastrarPagamentos = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamentos`;
      if (selectedContaCorrente && isDataPagamento && valorPago && selectedFormaPagamento) {
        const response = await axios.post(url, {
          idContaPagarParcela: id,
          idContaCorrente: selectedContaCorrente?.value,
          valorPago: valorPago,
          dataPagamento: isDataPagamento,
          idFormaPagamento: selectedFormaPagamento?.value,
          status: status,
          descricao: descricao ? descricao : '',
        });

        Swal.fire({
          icon: 'success',
          title: `Pagamento cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadPagamentos();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Preencha os campos obrigatórios',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarPagamentos = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamentos/${dataLine[0]}`;
        const response = await axios.put(url, {
          idContaPagarParcela: id,
          idContaCorrente: selectedContaCorrente?.value,
          valorPago: valorPago,
          dataPagamento: isDataPagamento,
          idFormaPagamento: selectedFormaPagamento?.value,
          status: status,
          descricao: descricao ? descricao : '',
        });
        Swal.fire({
          icon: 'success',
          title: `Pagamento editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadPagamentos();
        });
        setTimeout(() => {}, 3000);
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDatePagamento = (
    value: Date | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataPagamento(null);
    } else {
      setIsDataPagamento(value);
    }
  };

  const loadOptionsContaCorrente = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/listar?numero=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `CC: ${item.numero} | Agência: ${item.agencia} | Banco: ${item.banco} (ID: ${item.id}) `,
      }))
      .filter(
        (option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.value.toString().includes(inputValue)
      );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsFormaPagamento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `${item.formaPagamento} (ID: ${item.id})`,
      }))
      // .filter(
      //   (option) =>
      //     option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      //     option.value.toString().includes(inputValue)
      // );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };
  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarPagamentos() : cadastrarPagamentos())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setSelectedContaCorrente({
        value: dataLine[1],
        label: `conta: ${dataLine[2]} | agencia: ${dataLine[3]} (ID: ${dataLine[1]})`,
      });
      setValorPago(dataLine[4]);
      setIsDataPagamento(new Date(dataLine[5]));
      setSelectedFormaPagamento({
        value: dataLine[6],
        label: dataLine[7],
      });
      setStatus(dataLine[8]);
      setDescricao(dataLine[9]);
    }
  }, [dataLine]);

  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Pagamentos
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Id contas a pagar:
              </label>
              <input
                value={id}
                disabled
                className='form-control'
                type='number'
                placeholder='Id Contas a Pagar'
              />
            </Col>
            <Col>
                <label htmlFor='' className='form-label'>
                  Conta Corrente:
                </label>
                <AsyncSelect
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedContaCorrente(null);
                    }
                  }}
                  value={selectedContaCorrente}
                  placeholder='Selecione o número da conta corrente...'
                  onChange={(e) => {
                    setSelectedContaCorrente(e);
                  }}
                  loadOptions={loadOptionsContaCorrente}
                />
                {!selectedContaCorrente && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione a conta corrente
                  </span>
              )}
            </Col>
            <Col>
              <label className='form-label'>Valor Pago:</label>
              <input
                type='text'
                placeholder='Valor...'
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valorPago || 0)}
                className={`form-control`}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  inputValue = inputValue.replace(/\D/g, '');

                  if (inputValue !== null) {
                    inputValue = inputValue.replace(/^0+/, '');
                    inputValue = inputValue || '0';

                    const numericValue = parseInt(inputValue, 10);
                    const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                    setValorPago(numericValue / 100);
                  } else {
                    setValorPago(null);
                  }
                }}
              />
              {!valorPago && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe um valor
                  </span>
              )}
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label className='form-label'>Data Pagamento:</label>
              <DatePicker
                size='lg'
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='DATA DE PAGAMENTO'
                defaultValue= {new Date()}
                onChange={handleDatePagamento}
                value={isDataPagamento}
              />
              {!isDataPagamento && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe uma data de pagamento
                  </span>
              )}
            </Col>
            <Col>
                <label htmlFor='' className='form-label'>
                  Forma de Pagamento:
                </label>
                <AsyncSelect
                  defaultOptions
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedFormaPagamento(null);
                    }
                  }}
                  value={selectedFormaPagamento}
                  placeholder='Selecione a Forma de Pagamento...'
                  onChange={(e) => {
                    setSelectedFormaPagamento(e);
                  }}
                  loadOptions={loadOptionsFormaPagamento}
                />
                {!selectedFormaPagamento && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe uma forma de pagamento
                  </span>
              )}
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalPagamentos;
