import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import {ContainerOptionsContasReceber} from './async-select-contas-receber-styles';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectContasReceber: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectContaReceberAsync, setSelectContaReceberAsync] = useState<ISelectOption | null>(
    null
  );
  const [contaReceberDetails, setContaReceberDetails] = useState<any[]>();

  const loadOptionsContaReceberById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setContaReceberDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const contaReceber = contaReceberDetails?.find((cR) => Number(cR.id) === Number(option.value));

    if (contaReceber) {
      return (
        <ContainerOptionsContasReceber>
          <div className='container-message-contas-receber'>
            <div className='info-container-contas-receber'>
              <p>
                ID: <span>{contaReceber.id ? contaReceber.id : '-'}</span>
              </p>

              <p>
                Valor:{' '}
                <span>
                  {contaReceber.valor
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(contaReceber.valor)
                    : ' - '}
                </span>
              </p>
              <p>
                Qtd. Parcelas:{' '}
                <span>{contaReceber.qtdParcelas ? contaReceber.qtdParcelas : ' - '}</span>
              </p>
              <p>
                STATUS: <span>{contaReceber.status ? contaReceber.status : '-'}</span>
              </p>
            </div>
          </div>
        </ContainerOptionsContasReceber>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectContaReceberAsync && handleOptions) {
      handleOptions(selectContaReceberAsync);
    }
  }, [selectContaReceberAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsContaReceberById(inputOption.value);
      setSelectContaReceberAsync(inputOption);
    } else if (inputOption == null) {
      setSelectContaReceberAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise a Conta a Receber'
      loadOptions={loadOptionsContaReceberById}
      value={selectContaReceberAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectContaReceberAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectContasReceber;
