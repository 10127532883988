import {useEffect, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';
import {AsyncSelectMarcas, ISelectOption, Modal} from '../../../../../shared';
import Swal from 'sweetalert2';
import axios from 'axios';
import {useFormik} from 'formik';
import clsx from 'clsx';
import {
  createDisponibilidade,
  updateDisponibilidade,
} from '../../../cadastro_requests/cadastro_requests';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  handleRequest?: () => void;
  idItem?: number | string;
  typeModal: 'view' | 'edit' | 'add' | null;
  setTypeModal: React.Dispatch<React.SetStateAction<'view' | 'edit' | 'add' | null>>;
  fetchDisponibilidade: ({page}: {page: number}) => Promise<void>;
  idFornecedor: any;
};

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const initialValuesDisponibilidade = {
  idFornecedor: 0,
  idMarca: 0,
  importados: 0,
  fabricados_a: 0,
  fabricados_b: 0,
  fabricados_c: 0,
  customizados: 0,
  faturamento: 0,
  transporteFrete: 0,
  tipoFrete: 'CIF',
  pedidoMinimoValor: 0,
  pedidoMinimoValorCIF: 0,
  status: 'ATIVO',
};

const ItemDetailsDisponibilidadeModal: React.FC<Props> = ({
  isOpen,
  onHide,
  idItem,
  typeModal,
  setTypeModal,
  fetchDisponibilidade,
  idFornecedor,
}) => {
  const [isSelectAsyncMarca, setIsSelectAsyncMarca] = useState<ISelectOption | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const formikDispView = useFormik({
    initialValues: initialValuesDisponibilidade,
    onSubmit: () => {},
  });

  const onClose = () => {
    onHide!();
    setTimeout(() => {
      formikDisponibilidade.resetForm();
      setIsSelectAsyncMarca(null);
      setTypeModal(null);
    }, 500);
  };

  const loadDetailsItem = async () => {
    if (idItem) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores-disponibilidade/${idItem}`
        );

        const {data} = response;
        formikDispView.setValues(data);
        setIsSelectAsyncMarca({value: data.idMarca, label: ''});
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  const handleAsyncSelectMarca = (option: ISelectOption) => {
    setIsSelectAsyncMarca(option);
  };

  const loadItemById = async (id: string | number) => {
    if (id) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores-disponibilidade/${id}`
        );

        if (response.status === 200) {
          let dataId = response.data;
          setIsSelectAsyncMarca({value: dataId.idMarca, label: ''});
          formikDisponibilidade.setValues(dataId);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao carregar disponibilidade do fornecedor',
            text: 'Ocorreu um erro ao carregar a disponibilidade do fornecedor. Por favor, tente novamente mais tarde.',
            confirmButtonText: 'Ok',
          });
          throw new Error('Erro ao buscar disponibilidade do fornecedor');
        }
      } catch (errors: any) {
        if (errors.response) {
          const status = errors.response.status;

          const {data} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (typeModal === 'view') {
      loadDetailsItem();
    } else if (typeModal === 'edit' && idItem) {
      loadItemById(idItem);
    } else if (typeModal === 'add') {
    }

    formikDisponibilidade.setValues({
      ...formikDisponibilidade.values,
      idFornecedor,
    });
  }, [isOpen]);

  const buttonConfirm = typeModal !== 'view' && (
    <Button
      type='submit'
      variant='success'
      onClick={() => formikDisponibilidade.handleSubmit()}
      disabled={isLoading}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : `Confirmar `}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    title:
      typeModal === 'view'
        ? 'Detalhes da Disponibilidade'
        : typeModal === 'add'
        ? 'Cadastrar Disponibilidade'
        : 'Editar Disponibilidade',
    actions: [buttonConfirm],
    onHide: onClose,
    size: 'xl',
  };

  const formikDisponibilidade = useFormik({
    initialValues: initialValuesDisponibilidade,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        if (typeModal === 'add') {
          const response = await createDisponibilidade({
            customizados: Number(values.customizados),
            fabricados_a: Number(values.fabricados_a),
            fabricados_b: Number(values.fabricados_b),
            fabricados_c: Number(values.fabricados_c),
            faturamento: Number(values.faturamento),
            idFornecedor: values.idFornecedor,
            idMarca: isSelectAsyncMarca?.value || 0,
            importados: Number(values.importados),
            pedidoMinimoValor: values.pedidoMinimoValor,
            pedidoMinimoValorCIF: values.pedidoMinimoValorCIF,
            status: values.status,
            tipoFrete: values.tipoFrete,
            transporteFrete: Number(values.transporteFrete),
          });

          if (response.status === 201) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Disponibilidade criada com sucesso!',
              confirmButtonText: 'Ok',
              timer: 3000,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timerProgressBar: true,
              didClose: () => {
                onClose();
                fetchDisponibilidade({page: 0});
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar a disponibilidade, verifique as informações preenchidas e tente novamente',
              text: response.data.mensagem,
              confirmButtonText: 'Ok',
            });
          }
        } else if (typeModal === 'edit' && idItem) {
          const response = await updateDisponibilidade(
            {
              customizados: Number(values.customizados),
              fabricados_a: Number(values.fabricados_a),
              fabricados_b: Number(values.fabricados_b),
              fabricados_c: Number(values.fabricados_c),
              faturamento: Number(values.faturamento),
              idFornecedor: values.idFornecedor,
              idMarca: isSelectAsyncMarca?.value || 0,
              importados: Number(values.importados),
              pedidoMinimoValor: values.pedidoMinimoValor,
              pedidoMinimoValorCIF: values.pedidoMinimoValorCIF,
              status: values.status,
              tipoFrete: values.tipoFrete,
              transporteFrete: Number(values.transporteFrete),
            },
            Number(idItem)
          );

          if (response.status === 200) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Disponibilidade editada com sucesso!',
              confirmButtonText: 'Ok',
              timer: 3000,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timerProgressBar: true,
              didClose: () => {
                onClose();
                fetchDisponibilidade({page: 0});
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao editar a disponibilidade, verifique as informações preenchidas e tente novamente',
              text: response.data.mensagem,
              confirmButtonText: 'Ok',
            });
          }
        }
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.response.data.message;
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else if (error.response && error.response.status === 409) {
          const [titlePart, detailsPart] = errorMessage.split('*Detalhes:*');
          Swal.fire({
            icon: 'info',
            title: titlePart.trim(), // Exibe "CPF informado já em uso."
            text: `Detalhes:\n${detailsPart.trim()}`, // Exibe o restante da mensagem com quebra de linha
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar a disponibilidade',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        }
      }
    },
  });

  return (
    <>
      <Modal {...modalConfigs}>
        {typeModal === 'view' && (
          <form action='form-control-solid' onSubmit={formikDispView.handleSubmit}>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Marca</label>

                <AsyncSelectMarcas
                  handleOptions={(option) => handleAsyncSelectMarca(option!)}
                  inputOption={isSelectAsyncMarca}
                  isDisabled
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncMarca(null);
                    }
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Importados</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('importados')}
                  className='form-control'
                  placeholder='Importados'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('importados', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados A</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('fabricados_a')}
                  className='form-control'
                  placeholder='Fabricados A'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('fabricados_a', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados B</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('fabricados_b')}
                  className='form-control'
                  placeholder='Fabricados B'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('fabricados_b', value);
                  }}
                />
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados C</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('fabricados_c')}
                  className='form-control'
                  placeholder='Fabricados C'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('fabricados_c', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Customizados</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('customizados')}
                  className='form-control'
                  placeholder='Customizados'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('customizados', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Faturamento</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('faturamento')}
                  className='form-control'
                  placeholder='Faturamento'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('faturamento', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Transporte do Frete</label>
                <input
                  type='number'
                  {...formikDispView.getFieldProps('transporteFrete')}
                  className='form-control'
                  placeholder='Transporte do Frete'
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDispView.setFieldValue('transporteFrete', value);
                  }}
                />
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Valor Mínimo do Pedido </label>
                <input
                  type='text'
                  {...formikDispView.getFieldProps('pedidoMinimoValor')}
                  className='form-control'
                  placeholder='Valor Mínimo do Pedido'
                  readOnly
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formikDispView.values.pedidoMinimoValor || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formikDispView.setFieldValue('pedidoMinimoValor', valorEmCentavos);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Valor Mínimo do Pedido CIF </label>
                <input
                  type='text'
                  {...formikDispView.getFieldProps('pedidoMinimoValorCIF')}
                  className='form-control'
                  placeholder='Valor Mínimo do Pedido CIF'
                  readOnly
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formikDispView.values.pedidoMinimoValorCIF || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formikDispView.setFieldValue('pedidoMinimoValorCIF', valorEmCentavos);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Tipo de frete *</label>
                <select
                  className='form-select'
                  onChange={(e) => formikDispView.setFieldValue('tipoFrete', e.target.value)}
                  value={formikDispView.values.tipoFrete}
                  disabled
                  style={{marginTop: '0px'}}
                >
                  <option value='3'> Selecione </option>
                  <option value='CIF'> CIF </option>
                  <option value='FOB'> FOB </option>
                </select>
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Status *</label>
                <select
                  className='form-select'
                  onChange={(e) => formikDispView.setFieldValue('status', e.target.value)}
                  value={formikDispView.values.status}
                  disabled
                  style={{marginTop: '0px'}}
                >
                  <option value='ATIVO'> ATIVO </option>
                  <option value='INATIVO'> INATIVO </option>
                </select>
              </div>
            </div>
          </form>
        )}

        {(typeModal === 'add' || typeModal === 'edit') && (
          <form action='form-control-solid' onSubmit={() => {}}>
            <div className='mb-5 row'>
              <div className='col-sm-6' style={{display: 'none'}}>
                <label className='form-label'>ID Fornecedor</label>
                <input
                  type='text'
                  placeholder={'Id Fornecedor'}
                  {...formikDisponibilidade.getFieldProps('idFornecedor')}
                  readOnly
                  onChange={(event) => {
                    const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, '').toUpperCase();
                    formikDisponibilidade.setFieldValue('idFornecedor', value);
                  }}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid':
                        formikDisponibilidade.touched.idFornecedor &&
                        formikDisponibilidade.errors.idFornecedor,
                    },
                    {
                      'is-valid':
                        formikDisponibilidade.touched.idFornecedor &&
                        !formikDisponibilidade.errors.idFornecedor,
                    }
                  )}
                />
                {formikDisponibilidade.touched.idFornecedor &&
                  formikDisponibilidade.errors.idFornecedor && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formikDisponibilidade.errors.idFornecedor}</span>
                      </div>
                    </div>
                  )}
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Marca</label>

                <AsyncSelectMarcas
                  handleOptions={(option) => handleAsyncSelectMarca(option!)}
                  inputOption={isSelectAsyncMarca}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncMarca(null);
                    }
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Importados</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('importados')}
                  className='form-control'
                  placeholder='Importados'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('importados', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados A</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('fabricados_a')}
                  className='form-control'
                  placeholder='Fabricados A'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('fabricados_a', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados B</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('fabricados_b')}
                  className='form-control'
                  placeholder='Fabricados B'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('fabricados_b', value);
                  }}
                />
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Fabricados C</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('fabricados_c')}
                  className='form-control'
                  placeholder='Fabricados C'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('fabricados_c', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Customizados</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('customizados')}
                  className='form-control'
                  placeholder='Customizados'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('customizados', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Faturamento</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('faturamento')}
                  className='form-control'
                  placeholder='Faturamento'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('faturamento', value);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Transporte do Frete</label>
                <input
                  type='number'
                  {...formikDisponibilidade.getFieldProps('transporteFrete')}
                  className='form-control'
                  placeholder='Transporte do Frete'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formikDisponibilidade.setFieldValue('transporteFrete', value);
                  }}
                />
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Valor Mínimo do Pedido </label>
                <input
                  type='text'
                  {...formikDisponibilidade.getFieldProps('pedidoMinimoValor')}
                  className='form-control'
                  placeholder='Valor Mínimo do Pedido'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formikDisponibilidade.values.pedidoMinimoValor || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formikDisponibilidade.setFieldValue('pedidoMinimoValor', valorEmCentavos);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Valor Mínimo do Pedido CIF </label>
                <input
                  type='text'
                  {...formikDisponibilidade.getFieldProps('pedidoMinimoValorCIF')}
                  className='form-control'
                  placeholder='Valor Mínimo do Pedido CIF'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formikDisponibilidade.values.pedidoMinimoValorCIF || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formikDisponibilidade.setFieldValue('pedidoMinimoValorCIF', valorEmCentavos);
                  }}
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Tipo de frete *</label>
                <select
                  className='form-select'
                  onChange={(e) => formikDisponibilidade.setFieldValue('tipoFrete', e.target.value)}
                  value={formikDisponibilidade.values.tipoFrete}
                  style={{marginTop: '0px'}}
                >
                  <option value='3'> Selecione </option>
                  <option value='CIF'> CIF </option>
                  <option value='FOB'> FOB </option>
                </select>
              </div>
              <div className='col-sm-3'>
                <label className='form-label'>Status *</label>
                <select
                  className='form-select'
                  onChange={(e) => formikDisponibilidade.setFieldValue('status', e.target.value)}
                  value={formikDisponibilidade.values.status}
                  style={{marginTop: '0px'}}
                >
                  <option value='ATIVO'> ATIVO </option>
                  <option value='INATIVO'> INATIVO </option>
                </select>
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

export default ItemDetailsDisponibilidadeModal;
