import axios from 'axios';
import { useEffect, useState } from 'react';
import { Badge, Button, Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { ClienteOption } from '../../cadastros/proposta-pedido/types/interface';
import { AsyncSelectProjetos, formatarData, IFaturaProjeto, IProjetos, smoothScrollToBottom, useModal } from '../../../shared';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import ModalFaturaProjeto from './components/ModalFaturaProjeto';
import { stat } from 'fs';
import CrudHeader from '../../../shared/components/crud-header/crud-header';


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const FaturaProjeto = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false)


    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [dataLine, setDataLine] = useState<IFaturaProjeto[]>([])
    const [page, setPage] = useState(0)
    const [countSkeleton, setCountSkeleton] = useState(40)

    const [valor, setValor] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<ClienteOption | null>(null)
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [dataEmissao, setDataEmissao] = useState<string | null>(null);
    const [dataVencimento, setDataVencimento] = useState<string | null>(null);
    const [dataPagamento, setDataPagamento] = useState<string | null>(null);

    const [faturaProjeto, setFaturaProjeto] = useState<IFaturaProjeto[]>([])

    const [isProjetosModal, openProjetosModal, closeProjetosModal] = useModal();


    const fetchFaturasProjetos = async (page = 0) => {

        if (page === 0) {
            setPage(0)
        }

        setLoading(true)
        setIsLoadingSearch(true)

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-fatura/listar`,
                {
                    params: {
                        page: page,
                        status: status,
                        idStatusPagamento: selectedStatus ? selectedStatus.value : null,
                        idProjeto: selectedProjeto ? selectedProjeto.value : null,
                        dataEmissao: dataEmissao ? dataEmissao : null,
                        dataVencimento: dataVencimento ? dataVencimento : null,
                        dataPagamento: dataPagamento ? dataPagamento : null,
                        valorFatura: valor ? valor : null,
                        descricao: descricao ? descricao : null,
                    },
                }

            );

            if (response.status === 200) {

                setFaturaProjeto((prev) =>
                    page === 0 ? response.data.content : [...prev, ...response.data.content]
                )
            }
            if (response.data.content.length === 0) {
                setHasMore(false);
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Faturas de projetos para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

            setHasMore(response.data.totalPages >= page + 1);

            if (response.data.last) {
                setHasMore(false);
            }
        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setLoading(false);
            setIsLoadingSearch(false);

        }
    };

    const loadMore = () => {
        setLoading(true)
        setLoading(false)
        setPage((prevPage) => prevPage + 1);
        setCountSkeleton((count) => count + 40)
        smoothScrollToBottom()
    }

    useEffect(() => {
        fetchFaturasProjetos(page)

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        //     }
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        fetchFaturasProjetos()
    }

    const loadOptionsStatus = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamento-status/listar`;

            const response = await axios.get(url, {

                params: {
                    status: 'ATIVO',
                    size: 1000,
                }
            });
            const data = await response.data;
            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        }
    };

    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openProjetosModal();
    }
    const clearFiltros = () => {
        setSelectedStatus(null)
        setSelectedProjeto(null)
        setValor(null)
        setDataEmissao(null)
        setDataVencimento(null)
        setDataPagamento(null)
        setDescricao(null)
        setStatus('ATIVO')
        setIsClear(true)
    }

    useEffect(() => {
        if (isClear) {
            fetchFaturasProjetos(0)
        }
        setIsClear(false)
    })

    return (
        <div>
            <CrudHeader openModal={openModal} title={'Fatura do Projeto'} titleCadastro={'Fatura'} isLoading={isLoadingSearch} clearFiltros={clearFiltros} handleSearch={handleSearch}>
                <Row className='col-12 '>
                    <Col>
                        <label htmlFor="" className='form-label'>Projeto:</label>
                        <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { setSelectedProjeto(option) }}  ></AsyncSelectProjetos>
                    </Col>
                </Row>
                <Row className='col-12 '>

                    <Col>
                        <label htmlFor="" className='form-label'>Valor da Fatura:</label>
                        <input type="text" value={
                            valor
                                ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}`
                                : 'R$ 0,00'
                        }
                            onChange={(event) => {
                                const rawValue = event.target.value;
                                const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                                setValor(numericValue.toString());
                            }} className="form-control" placeholder="Digite o valor" />
                    </Col>
                    <Col>
                        <label className='form-label' htmlFor="">Data de  emissão</label>
                        <input type="date" onChange={(e) => setDataEmissao(e.target.value)} value={dataEmissao ? dataEmissao : ""} name="" className='form-control' id="" />
                    </Col>
                </Row>
                <Row className='col-12 '>
                    <Col>
                        <label className='form-label' htmlFor="">Data de vencimento</label>
                        <input type="date" onChange={(e) => setDataVencimento(e.target.value)} value={dataVencimento ? dataVencimento : ""} name="" className='form-control' id="" />
                    </Col>
                    <Col>
                        <label className='form-label' htmlFor="">Data de pagamento</label>
                        <input type="date" onChange={(e) => setDataPagamento(e.target.value)} value={dataPagamento ? dataPagamento : ""} name="" className='form-control' id="" />
                    </Col>

                </Row>
                <Row className=' col-12'>
                    <Col>
                        <label className='form-label' htmlFor="">Status do Pagamento</label>

                        <AsyncSelect className='py-1' defaultOptions value={selectedStatus} onKeyDown={() => { setSelectedStatus(null) }} onChange={(option) => { setSelectedStatus(option) }} loadOptions={loadOptionsStatus} placeholder="Selecione o Status do Pagamento" ></AsyncSelect>
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className='col-12'>
                    <Col>
                        <label className='form-label' htmlFor="">Descrição</label>
                        <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>

            {loading ? (
                <Skeleton count={countSkeleton} height={25} />
            ) : (
                <>
                    <div className='table-responsive'>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800 overflow-visible'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Projetos</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Valor da Fatura</th>
                                    <th className='text-center'>Data de emissão</th>
                                    <th className='text-center'>Data de vencimento</th>
                                    <th className='text-center'>Data de pagamento</th>
                                    <th className='text-center'>Status do pagamento</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data de Criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de Alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faturaProjeto.map((fatura) => (
                                    <tr key={fatura.id}>
                                        <td className='text-center'>{fatura.id}</td>
                                        <td className='text-center'>{fatura.tituloProjeto}</td>
                                        <td className='text-center'>{fatura.descricao ? fatura.descricao : "..."}</td>
                                        <td className='text-center'>R$ {parseFloat(String(fatura.valorFatura)).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</td>
                                        <td className='text-center'>{formatarData(fatura.dataEmissao)}</td>
                                        <td className='text-center'>{formatarData(fatura.dataVencimento)}</td>
                                        <td className='text-center'>{formatarData(fatura.dataPagamento)}</td>
                                        <td className='text-center'>{fatura.tituloStatusPagamento}</td>
                                        <td className='text-center'>{fatura.usuarioCriacaoNome}</td>
                                        <td className='text-center'>{fatura.dataCriacao}</td>
                                        <td className='text-center'>{fatura.usuarioAlteracaoNome ? fatura.usuarioAlteracaoNome : "..."}</td>
                                        <td className='text-center'>{fatura.dataAlteracao ? fatura.dataAlteracao : "..."}</td>
                                        <td className='text-center'>{fatura.status}</td>
                                        <td className='text-center'>
                                            <button
                                                onClick={() => openModal(true, fatura.id)}
                                                className='btn btn-success btn-sm bi bi-pencil'
                                                data-toggle='tooltip'
                                                data-placement='top'
                                                title={'Editar'}
                                            ></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>

                </>
            )}
            <ModalFaturaProjeto
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchFaturasProjetos}
                isEdit={isEdit}
                isOpen={isProjetosModal}
                onHide={closeProjetosModal}

            ></ModalFaturaProjeto>
        </div>
    )
}
export default FaturaProjeto