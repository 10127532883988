import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {IPagamentos, ISelectOption, smoothScrollToBottom, useModal} from '../../../shared';
import {Link, useParams} from 'react-router-dom';
import {DatePicker} from 'rsuite';
import ModalPagamentos from './components/ModalPagamentos';
import AsyncSelect from 'react-select/async';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemPagamentos = () => {
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [pagamentos, setPagamentos] = useState<IPagamentos[]>([]);
  const [selectedContaCorrente, setSelectedContaCorrente] = useState<ISelectOption | null>(null);
  const [selectedFormaPagamento, setSelectedFormaPagamento] = useState<ISelectOption | null>(null);
  const [valorPago, setValorPago] = useState<number | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [isDataPagamento, setIsDataPagamento] = useState<Date | null>(null);

  const [isPagamentosModal, openPagamentosModal, closePagamentosModal] = useModal();

  const fetchPagamentos = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamentos/listar?idContaPagarParcela=${id}`,
        {
          params: {
            status: status,
            page: page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setPagamentos((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de pagamentos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleDatePagamento = (
    value: Date | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataPagamento(null);
    } else {
      setIsDataPagamento(value);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(0);
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const formatDate = (date) => {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - (offset*60*1000));
        return adjustedDate.toISOString().split('T')[0];
      }
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamentos/listar?idContaPagarParcela=${id}`,
        {
          params: {
            idFormaPagamento: selectedFormaPagamento?.value,
            idContaCorrente: selectedContaCorrente?.value,
            dataPagamento: isDataPagamento ? formatDate(isDataPagamento) : null,
            valorPago: valorPago,
            status: status,
            page: page,
            descricao: descricao ? descricao : null,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setPagamentos((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de pagamentos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const loadOptionsContaCorrente = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/listar?numero=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `CC: ${item.numero} | Agência: ${item.agencia} (ID: ${item.id}) `,
      }))
      .filter(
        (option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.value.toString().includes(inputValue)
      );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsFormaPagamento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `${item.formaPagamento} (ID: ${item.id})`,
      }))
      // .filter(
      //   (option) =>
      //     option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      //     option.value.toString().includes(inputValue)
      // );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openPagamentosModal();
  };

  const clearFiltros = () => {
    setSelectedContaCorrente(null);
    setIsDataPagamento(null);
    setValorPago(null);
    setSelectedFormaPagamento(null);
    setStatus('ATIVO');
    setDescricao(null);
    setPage(0);
    fetchPagamentos(page);
  };

  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  useEffect(() => {
    fetchPagamentos(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Pagamentos
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Pagamentos
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Id contas a pagar:
              </label>
              <input
                value={id}
                disabled
                className='form-control'
                type='number'
                placeholder='Id Contas a Pagar'
              />
            </Col>
            <Col>
                <label htmlFor='' className='form-label'>
                  Conta Corrente:
                </label>
                <AsyncSelect
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedContaCorrente(null);
                    }
                  }}
                  value={selectedContaCorrente}
                  placeholder='Selecione o número da conta corrente...'
                  onChange={(e) => {
                    setSelectedContaCorrente(e);
                  }}
                  loadOptions={loadOptionsContaCorrente}
                />
            </Col>
            <Col>
              <label className='form-label'>Valor Pago:</label>
              <input
                type='text'
                placeholder='Valor...'
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valorPago || 0)}
                className={`form-control`}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  inputValue = inputValue.replace(/\D/g, '');

                  if (inputValue !== null) {
                    inputValue = inputValue.replace(/^0+/, '');
                    inputValue = inputValue || '0';

                    const numericValue = parseInt(inputValue, 10);
                    const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                    setValorPago(numericValue / 100);
                  } else {
                    setValorPago(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label className='form-label'>Data Pagamento:</label>
              <DatePicker
                size='lg'
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='DATA DE PAGAMENTO'
                defaultValue= {new Date()}
                onChange={handleDatePagamento}
                value={isDataPagamento}
              />
            </Col>
            <Col>
                <label htmlFor='' className='form-label'>
                  Forma de Pagamento:
                </label>
                <AsyncSelect
                  defaultOptions
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedFormaPagamento(null);
                    }
                  }}
                  value={selectedFormaPagamento}
                  placeholder='Selecione a Forma de Pagamento...'
                  onChange={(e) => {
                    setSelectedFormaPagamento(e);
                  }}
                  loadOptions={loadOptionsFormaPagamento}
                />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link className='btn mx-5 px-10 btn-info ' to={`/listagem-contas-a-pagar`}>
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Conta a Pagar Parcela</th>
                  <th className='text-center'>Conta Corrente</th>
                  <th className='text-center'>Valor</th>
                  <th className='text-center'>Data do Pagamento</th>
                  <th className='text-center'>Forma de Pagamento</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Editar</th>
                </tr>
              </thead>
              <tbody>
                {pagamentos?.map((pagamento) => (
                  <tr className='text-center'>
                    <td>{pagamento.id}</td>
                    <td>{pagamento.idContaPagarParcela}</td>
                    <td>{pagamento.idContaCorrente}</td>
                    <td>
                      {pagamento.valorPago
                        ? `R$ ${Number(pagamento.valorPago).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : 'R$ 0,00'}
                    </td>
                    <td>
                      {pagamento.dataPagamento
                        ? new Date(pagamento.dataPagamento)?.toLocaleDateString('pt-BR')
                        : '...'}
                    </td>
                    <td>{pagamento.formaPagamento}</td>
                    <td>{pagamento.descricao ? pagamento.descricao : '...'}</td>
                    <td>{pagamento.usuarioCriacaoNome}</td>
                    <td>{pagamento.dataCriacao}</td>
                    <td>
                      {pagamento.usuarioAlteracaoNome ? pagamento.usuarioAlteracaoNome : '...'}
                    </td>
                    <td>{pagamento.dataAlteracao}</td>
                    <td>{pagamento.status}</td>
                    <td>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            openModal(
                              true,
                              pagamento.id,
                              pagamento.idContaCorrente,
                              pagamento.numero ? pagamento.numero : '...',
                              pagamento.agencia ? pagamento.agencia : '...',
                              pagamento.valorPago,
                              pagamento.dataPagamento
                                ? new Date(pagamento.dataPagamento)
                                    ?.toISOString()
                                    .replace('Z', '')
                                : '',
                              pagamento.idFormaPagamento,
                              pagamento.formaPagamento ? pagamento.formaPagamento : '...',
                              pagamento.status,
                              pagamento.descricao
                            );
                          }}
                          className='btn btn-success btn-sm bi bi-pencil'
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Editar'
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalPagamentos
        isOpen={isPagamentosModal}
        onHide={closePagamentosModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadPagamentos={fetchPagamentos}
      />
    </div>
  );
};

export default ListagemPagamentos;
