import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {AsyncSelectEmpresa, AsyncSelectFornecedor, ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalContasAPagar = ({isOpen, onHide, dataLine, isEdit, loadContasAPagar}) => {
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [selectedFornecedor, setSelectedFornecedor] = useState<ISelectOption | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState<ISelectOption | null>(null);
  const [selectedTipoDespesa, setSelectedTipoDespesa] = useState<ISelectOption | null>(null);
  const [selectedSetor, setSelectedSetor] = useState<ISelectOption | null>(null);
  const [selectedParcela, setSelectedParcela] = useState<ISelectOption | null>(null);
  const [valor, setValor] = useState<number | null>(null);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formDataState = {
    setSelectedFornecedor: setSelectedFornecedor,
    setSelectedEmpresa: setSelectedEmpresa,
    setSelectedTipoDespesa: setSelectedTipoDespesa,
    setSelectedSetor: setSelectedSetor,
    setSelectedParcela: setSelectedParcela,
    setValor: setValor,
    setDescricao: setDescricao,
    setStatus: setStatus,
  };
 
  const onClose = () => {
    onHide();
    setSelectedFornecedor(null);
    setSelectedEmpresa(null);
    setSelectedSetor(null);
    setSelectedTipoDespesa(null);
    setValor(null);
    setSelectedParcela(null);
    setStatus('ATIVO');
    setDescricao(null);
    onClear();
  };

  const onClear = () => {
    setSelectedFornecedor(null);
    setSelectedEmpresa(null);
    setSelectedSetor(null);
    setSelectedTipoDespesa(null);
    setValor(null);
    setSelectedParcela(null);
    setStatus('ATIVO');
    setDescricao(null);
  };

  const cadastrarContasAPagar = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar`;
      if (selectedFornecedor && selectedEmpresa && selectedSetor && selectedTipoDespesa && valor && selectedParcela) {
        const response = await axios.post(url, {
          id: id,
          idFornecedor: selectedFornecedor?.value,
          idEmpresa: selectedEmpresa?.value,
          idSetor: selectedSetor?.value,
          qtdParcelas: selectedParcela?.value,
          idTipoDespesa: selectedTipoDespesa?.value,
          status: status,
          valor: valor,
          descricao: descricao ?? '',
        });

        Swal.fire({
          icon: 'success',
          title: `Conta a pagar cadastrada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadContasAPagar();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Preencha os campos obrigatórios',
          confirmButtonText: 'Ok',
        });
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarContasAPagar = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar/${dataLine[0]}`;
      if (selectedFornecedor && selectedFornecedor.value) {
        const response = await axios.put(url, {
          id: id,
          idFornecedor: selectedFornecedor?.value,
          idEmpresa: selectedEmpresa?.value,
          idSetor: selectedSetor?.value,
          qtdParcelas: selectedParcela?.value,
          idTipoDespesa: selectedTipoDespesa?.value,
          status: status,
          valor: valor,
          descricao: descricao ?? '',
        });
        Swal.fire({
          icon: 'success',
          title: `Conta a pagar editada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadContasAPagar();
        });
        setTimeout(() => {}, 3000);
      } else {
        console.log('Usuário não selecionado');
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOptionsFornecedor = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedor/listar?razaoSocial=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.fantasia} (ID: ${item.id})`,
        }))
        .filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().includes(inputValue)
        );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsEmpresa = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas/optionsEmpresas?empresa=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.fantasia} (ID: ${item.id})`,
        }))
        .filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().includes(inputValue)
        );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setSelectedFornecedor(option);
  };

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setSelectedEmpresa(option);
  };

  const loadOptionsSetores = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsTiposDespesa = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const parcelas = Array.from({ length: 12 }, (_, i) => i + 1);

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>    
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarContasAPagar() : cadastrarContasAPagar())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setSelectedFornecedor({
        value: dataLine[1],
        label: dataLine[2],
      });
      setSelectedEmpresa({
        value: dataLine[3],
        label: dataLine[4],
      });
      setSelectedSetor({
        value: dataLine[5],
        label: `${dataLine[6]}`,
      });
      setSelectedTipoDespesa({
        value: dataLine[7],
        label: `${dataLine[8]}`,
      });
      setValor(dataLine[9]);
      setSelectedParcela({
        value: dataLine[10], 
        label: dataLine[10]
      });
      setStatus(dataLine[11]);
      setDescricao(dataLine[12]);
    }
  }, [dataLine]);
  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Contas a Pagar
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Fornecedor
              </label>
              <AsyncSelectFornecedor
                handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
                inputOption={selectedFornecedor}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedFornecedor(null);
                  }
                }}
              />
              {!selectedFornecedor && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione o fornecedor
                  </span>
              )}
            </Col>
            
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Empresa
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
                inputOption={selectedEmpresa}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedEmpresa(null);
                  }
                }}
              />
              {!selectedEmpresa && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione a empresa
                  </span>
              )}
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Setor
              </label>
              <AsyncSelect
                defaultOptions
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedSetor(null);
                  }
                }}
                value={selectedSetor}
                placeholder='Selecione o setor...'
                onChange={(e) => {
                  setSelectedSetor(e);
                }}
                loadOptions={loadOptionsSetores}
              />
              {!selectedSetor && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione o setor
                  </span>
              )}
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Tipo de Despesa
              </label>
              <AsyncSelect
                defaultOptions
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedTipoDespesa(null);
                  }
                }}
                value={selectedTipoDespesa}
                placeholder='Selecione o tipo de despesa...'
                onChange={(e) => {
                  setSelectedTipoDespesa(e);
                }}
                loadOptions={loadOptionsTiposDespesa}
              />
              {!selectedTipoDespesa && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione o tipo de despesa
                  </span>
              )}
            </Col>
            <Col>
              <label className='form-label'>Valor:</label>
              <input
                type='text'
                placeholder='Valor...'
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valor || 0)}
                className={`form-control`}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  inputValue = inputValue.replace(/\D/g, '');

                  if (inputValue !== null) {
                    inputValue = inputValue.replace(/^0+/, '');
                    inputValue = inputValue || '0';

                    const numericValue = parseInt(inputValue, 10);
                    const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                    setValor(numericValue / 100);
                  } else {
                    setValor(null);
                  }
                }}
              />
              {!valor && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o valor
                  </span>
              )}
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
          <Col>
              <label htmlFor='' className='form-label'>
                Quantidade de parcelas
              </label>
              <select 
                className='form-select'
                value={selectedParcela ? selectedParcela.value : ""}
                onChange={(e) => {
                  const inputValue = e.target.value; 
                  if (Number(inputValue) === 0) {
                    setSelectedParcela(null);
                  } else {
                  formDataState.setSelectedParcela({ value: Number(e.target.value), label: `${e.target.value}x` }); 
                  }
                }}
              >
                <option value="">Selecione uma parcela</option>
                <option value={1}>à vista</option>
                {parcelas.slice(1).map((parcela) => (
                  <option key={parcela} value={parcela}>
                    {`${parcela}x`}
                  </option>
                ))}
              </select>
              {!selectedParcela && (
                  <span className={`form-label text-danger`}>
                    *Por favor, selecione a quantidade de parcelas
                  </span>
              )}
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  formDataState.setStatus
                    ? formDataState.setStatus(e.target.value)
                    : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  formDataState.setDescricao
                    ? formDataState.setDescricao(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalContasAPagar;
