import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {AsyncSelectEmpresa, ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalAprovadoresSetores = ({isOpen, onHide, dataLine, isEdit, loadSetores}) => {
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [selectedEmpresa, setSelectedEmpresa] = useState<ISelectOption | null>(null);
  const [selectedSetor, setSelectedSetor] = useState<ISelectOption | null>(null);
  const [limiteCredito, setLimiteCredito] = useState<number | null>(null);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formDataState = {
    setSelectedEmpresa: setSelectedEmpresa,
    setSelectedSetor: setSelectedSetor,
    setLimiteCredito: setLimiteCredito,
    setDescricao: setDescricao,
    setStatus: setStatus,
  };

  const onClose = () => {
    onHide();
    setSelectedEmpresa(null);
    setSelectedSetor(null);
    setLimiteCredito(null);
    setStatus('ATIVO');
    setDescricao(null);
    onClear();
  };

  const onClear = () => {
    setSelectedEmpresa(null);
    setSelectedSetor(null);
    setLimiteCredito(null);
    setStatus('ATIVO');
    setDescricao(null);
  };

  const cadastrarAprovadoresSetores = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores-setores`;
      if (selectedEmpresa && selectedSetor) {
        const response = await axios.post(url, {
          idAprovador: id,
          idEmpresa: selectedEmpresa?.value,
          idSetor: selectedSetor?.value,
          limiteCredito: limiteCredito,
          status: status,
          descricao: descricao ?? '',
        });

        Swal.fire({
          icon: 'success',
          title: `Setor cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadSetores();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Preencha os campos obrigatórios',
          confirmButtonText: 'Ok',
        });
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarAprovadoresSetores = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores-setores/${dataLine[0]}`;

      const response = await axios.put(url, {
        idAprovador: id,
        idEmpresa: selectedEmpresa?.value,
        idSetor: selectedSetor?.value,
        limiteCredito: limiteCredito,
        status: status,
        descricao: descricao ?? '',
      });
      Swal.fire({
        icon: 'success',
        title: `Setor editado com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        onClose();
        loadSetores();
      });
      setTimeout(() => {}, 3000);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOptionsEmpresa = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas/optionsEmpresas?empresa=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.fantasia} (ID: ${item.id})`,
        }))
        .filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().includes(inputValue)
        );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsSetores = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarAprovadoresSetores() : cadastrarAprovadoresSetores())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setSelectedEmpresa(option);
  };

  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setSelectedEmpresa({
        value: dataLine[1],
        label: dataLine[2],
      });
      setSelectedSetor({
        value: dataLine[3],
        label: `${dataLine[4]}`,
      });
      setLimiteCredito(dataLine[5]);
      setStatus(dataLine[6]);
      setDescricao(dataLine[7]);
    }
  }, [dataLine]);

  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Setor
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Empresa
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
                inputOption={selectedEmpresa}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedEmpresa(null);
                  }
                }}
              />
            </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id aprovador:
                </label>
                <input
                  value={Number(id)}
                  disabled
                  className='form-control'
                  type='number'
                  placeholder='Id Aprovador'
                />
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Setor
                </label>
                <AsyncSelect
                  defaultOptions
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedSetor(null);
                    }
                  }}
                  value={selectedSetor}
                  placeholder='Selecione o setor...'
                  onChange={(e) => {
                    setSelectedSetor(e);
                  }}
                  loadOptions={loadOptionsSetores}
                />
                {!selectedSetor && (
                  <span className={`form-label text-danger`}>*Por favor, selecione o setor</span>
                )}
              </Col>
              <Col>
                <label className='form-label'>Limite de Crédito:</label>
                <input
                  type='text'
                  placeholder='Limite de Crédito...'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(limiteCredito || 0)}
                  className={`form-control`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue !== null) {
                      inputValue = inputValue.replace(/^0+/, '');
                      inputValue = inputValue || '0';

                      const numericValue = parseInt(inputValue, 10);
                      const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                      setLimiteCredito(numericValue / 100);
                    } else {
                      setLimiteCredito(null);
                    }
                  }}
                />
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    // handleChangeStatus(e.target.value)
                    formDataState.setStatus
                      ? formDataState.setStatus(e.target.value)
                      : console.log(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  rows={3}
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                    formDataState.setDescricao
                      ? formDataState.setDescricao(e.target.value)
                      : console.log(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAprovadoresSetores;
