import {useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import {DateRangePicker} from 'rsuite';
import {addDays, endOfMonth, startOfMonth, subDays} from 'date-fns';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {ColumnInstance, useTable, Row, Column} from 'react-table';
import {Button, Col, Modal, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {
  AsyncSelectPrevenda,
  AsyncSelectProduct,
  DescriptionComponent,
  formatarData,
  formatDateByFilter,
  formatDateTimeWithHours,
  IComprador,
  IFormularioArquivo,
  IPendenciaCompraCrud,
  ISelectOption,
  LoadMore,
  PDFView,
  SearchComponent,
  useModal,
} from '../../../../shared';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {
  getFormulariosArquivos,
  getPendenciaCompraCrud,
  imprimirPDFArquivo,
  visualizarPDFArquivo,
} from '../../../listagem/listagem_requests/listagem_requests';
import FormulariosArquivosModal from './components/formularios-arquivos-modal';

import {useNavigate, useParams} from 'react-router-dom';

const FormulariosArquivos: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {id} = useParams();

  const pendenciaCompraForms: Column<IFormularioArquivo>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value ? value : value}</div>,
    },
    {
      Header: 'Titulo',
      accessor: 'titulo',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Criado Por',
      accessor: 'usuarioCriacao',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Alterado Por',
      accessor: 'usuarioAlteracao',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Data de Criação',
      accessor: 'dataCriacao',
      Cell: ({value}) => (
        <div className='text-start'>{value ? formatDateTimeWithHours(value) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Alteração',
      accessor: 'dataAlteracao',
      Cell: ({value}) => (
        <div className='text-start'>{value ? formatDateTimeWithHours(value) : '...'}</div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div
          style={{display: 'flex', gap: '1', alignItems: 'center', justifyContent: 'flex-start'}}
        >
          <div className='text-start'>
            <button
              onClick={() => handleOnEdit(row.original.id!)}
              className='btn btn-success btn-sm bi bi-pencil'
              data-toggle='tooltip'
              data-placement='top'
              title='Editar'
            />
          </div>

          <div className='dropdown' style={{marginLeft: '7px'}}>
            <button className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
              <i className='bi bi-file-earmark-arrow-down'>PDF</i>
            </button>
            <ul className='dropdown-menu'>
              <li>
                <button
                  className='dropdown-item'
                  style={{color: 'red', zIndex: 10000}}
                  type='button'
                  onClick={() => viewPDF(row.original.id!, row.original.titulo!)}
                >
                  Visualizar PDF
                </button>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const [isNomeArquivo, setIsNomeArquivo] = useState<string>('');
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [isIdArquivo, setIsIdArquivo] = useState<string | number>();

  const [isPendenciaModal, openPendenciaModal, closePendenciaModal] = useModal();
  const [isFormularioArquivoData, setIsFormularioArquivoModal] = useState<IFormularioArquivo[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [isTitulo, setIsTitulo] = useState<string>('');
  const [isDescricao, setIsDescricao] = useState<string>('');
  const [isStatus, setIsStatus] = useState<string>('ATIVO');

  const [isEdit, setIsEdit] = useState<boolean>();
  const [isIdPendencia, setIsIdPendencia] = useState<number | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const data = useMemo(() => isFormularioArquivoData, [isFormularioArquivoData]);
  const columns = useMemo(() => pendenciaCompraForms, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const handleRequest = () => {
    clearFilters();
  };

  const loadMore = () => {
    if (isHasMore) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const viewPDF = (id: string | number, nameArquivo: string) => {
    visualizarPDFArquivo(setModalShowPDFViewer, id, setIsLoading, setIsPdfUrl, setIsIdArquivo);

    setIsNomeArquivo(nameArquivo);
  };

  const handleOnEdit = (id: string | number) => {
    openPendenciaModal();

    setIsEdit(true);
    setIsIdPendencia(Number(id));
  };

  const handleOnAdd = () => {
    openPendenciaModal();

    setIsEdit(false);

    setIsIdPendencia(null);
  };

  const handleFilters = () => {
    setIsPage(0);
    setIsFiltered(true);
    getPendenciasDataFilter();
  };

  useEffect(() => {
    console.log('status: ', isStatus);
  }, [isStatus]);

  const clearFilters = () => {
    getArquivosFormularioClear();
    setIsTitulo('');
    setIsDescricao('');
    setIsStatus('ATIVO');

    setIsFiltered(false);
    setIsFormularioArquivoModal([]);

    setIsPage(0);
  };

  const getPendenciasDataFilter = async () => {
    setIsLoadingSearch(true);
    setIsLoadingData(true);
    try {
      const response = await getFormulariosArquivos({
        titulo: isTitulo ?? '',
        descricao: isDescricao ?? '',
        status: isStatus,
        page: isPage,
        idFormulario: id,
      });

      const {content, totalPages} = response.data;
      setIsFormularioArquivoModal((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    }
  };

  const getArquivosFormularioClear = async (page = 0) => {
    setIsLoadingData(true);
    setIsLoadingSearch(true);
    try {
      const response = await getFormulariosArquivos({
        page: isPage,
        idFormulario: id,
      });

      const {content, totalPages} = response.data;
      setIsFormularioArquivoModal((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    getPendenciasDataFilter();
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Arquivos de Formulário'
        buttonTitle='+ Novo Arquivo de Formulário'
        handleAction={() => {
          handleOnAdd();
        }}
        isButtonAction
      />

      <SearchComponent
        clearFilters={() => clearFilters()}
        handleFilters={() => handleFilters()}
        isLoadingSearch={isLoadingSearch}
        isBack={true}
        url='/formularios-listagem'
      >
        <form className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-6'>
              <label className='form-label'>Título:</label>
              <input
                type='text'
                placeholder='Digite o arquivo do formulário'
                value={isTitulo}
                onChange={(e) => setIsTitulo(e.target.value)}
                className='form-control'
              />
            </Col>
            <Col className='col-sm-6'>
              <div>
                <label className='form-label'>Status:</label>
                <select
                  onChange={(e) => setIsStatus(e.target.value)}
                  value={isStatus}
                  className='form-control'
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição do formulário'
                onChange={(e) => setIsDescricao(e.target.value)}
                className='form-control'
                value={isDescricao}
              />
            </Col>
          </RowBootstrap>
        </form>
      </SearchComponent>

      {isLoadingData ? (
        <Skeleton count={20} height={30} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IFormularioArquivo>, index: number) => (
                  <th key={column.id} className={index === 0 ? 'text-end' : 'text-start'}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IFormularioArquivo>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} showEditButton />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM DADOS
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <LoadMore
            isHasMore={isHasMore}
            isLoadingSearch={isLoadingSearch}
            loadMore={() => loadMore()}
          />
        </div>
      )}

      <FormulariosArquivosModal
        isOpen={isPendenciaModal}
        onHide={closePendenciaModal}
        isEdit={isEdit}
        idItem={isIdPendencia}
        handleRequest={handleRequest}
      />

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFArquivo(isIdArquivo, setIsLoading, isNomeArquivo)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default FormulariosArquivos;
