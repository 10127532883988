import {useEffect, useState} from 'react';
import axios from 'axios';
import {ISelectOption} from '../../../../shared';
import AsyncSelect from 'react-select/async';
import * as S from './inputUserCompradores.style';

type UserOption = {
  value: any;
  label: '';
};

type IProps = {
  handleOptions?: (product: UserOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const InputUserCompradores: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedUsuario, setSelectedUsuario] = useState<ISelectOption | null>(null);
  const [usuarioDetails, setUsuarioDetails] = useState<any>();

  const loadOptionUsuario = async (inputValue: string) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/optionsUsers/${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      setUsuarioDetails(data);

      return data.map((item) => ({
        value: item.id,
        label: '',
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const usuario = usuarioDetails?.find((usuario) => usuario.id === Number(option.value));

    if (usuario) {
      return (
        <S.ContainerMessageCompradoresUser>
          <div className='box-left-usuario'>
            <div className='info-container-usuario'>
              <p>
                ID: <span>{usuario.id ? usuario.id : '-'}</span>
              </p>
              <p>
                <span>{usuario.nome ? usuario.nome : '-'}</span>
              </p>
            </div>
          </div>
        </S.ContainerMessageCompradoresUser>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedUsuario && handleOptions) {
      handleOptions(selectedUsuario);
    }
    if (selectedUsuario?.value && !usuarioDetails?.id) {
      loadOptionUsuario(String(selectedUsuario.value));
    }
  }, [selectedUsuario]);

  useEffect(() => {
    if (inputOption) {
      setSelectedUsuario(inputOption);
    } else if (inputOption == null) {
      setSelectedUsuario(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Usuario'
      loadOptions={loadOptionUsuario}
      value={selectedUsuario}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectedUsuario(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default InputUserCompradores;
