import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { IPendenciaCompra } from '../../../../shared';

const ResumoCliente = () => {
    return(
      <>
        <h1>Resumo Cliente</h1>
      </>
    );
};

export default ResumoCliente;