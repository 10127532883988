import {useEffect, useState} from 'react';

import {validate} from 'webpack';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useProduto} from '../hooks/useProduto';
import Divider from './Divider';
import {Col, Row} from 'react-bootstrap';
// import { Divider } from 'rsuite';

// import { Container } from './styles';

const Custos = () => {
  const {formik} = useProduto();
  const [inputs, setInputs] = useState<string[]>(['']);
  const [formataPrecoCompra, setFormataPrecoCompra] = useState<string>('');
  const [formataPrecoVenda, setFormataPrecoVenda] = useState<string>('');
  const [formataCustoMedio, setFormataCustoMedio] = useState<string>('');
  const [formataPrecoCusto, setFormataPrecoCusto] = useState<string>('');
  const [formataSaldoAtual, setFormataSaldoAtual] = useState<string>('');
  const [formataSaldoAnterior, setFormataSaldoAnterior] = useState<string>('');
  const [formataImposto, setFormataImposto] = useState<string>('');
  const [formataPrecoPromocional, setFormataPrecoPromocional] = useState<string>('');
  const [valorNegociacao, setValorNegociacao] = useState<string>('');


  useEffect(() => {
    formik.setFieldValue('custo_impostos_rec', formataImposto);
  }, [formataImposto]);

  useEffect(() => {
    formik.setFieldValue('custo_saldo_anterior', formataSaldoAnterior);
  }, [formataSaldoAnterior]);

  useEffect(() => {
    formik.setFieldValue('custo_saldo_atual', formataSaldoAtual);
  }, [formataSaldoAtual]);

  useEffect(() => {
    formik.setFieldValue('custo_preco', formataPrecoCusto);
  }, [formataPrecoCusto]);

  useEffect(() => {
    formik.setFieldValue('preco_compra', formataPrecoCompra);
  }, [formataPrecoCompra]);

  useEffect(() => {
    formik.setFieldValue('custo_medio', formataCustoMedio);
  }, [formataCustoMedio]);

  useEffect(() => {
    formik.setFieldValue('custo_preco_venda', formataPrecoVenda);
  }, [formataPrecoVenda]);

  useEffect(() => {
    formik.setFieldValue('preco_promocional', formataPrecoPromocional);
  }, [formataPrecoPromocional]);

  useEffect(() => {
    formik.setFieldValue('valorNegociacao', valorNegociacao);
  }, [valorNegociacao]);

  useEffect(() => {
    setFormataPrecoCompra({...formik.getFieldProps('preco_compra')}.value);
    setFormataPrecoVenda({...formik.getFieldProps('custo_preco_venda')}.value);
    setFormataCustoMedio({...formik.getFieldProps('custo_medio')}.value);
    setFormataPrecoCusto({...formik.getFieldProps('custo_preco')}.value);
    setFormataSaldoAtual({...formik.getFieldProps('custo_saldo_atual')}.value);
    setFormataSaldoAnterior({...formik.getFieldProps('custo_saldo_anterior')}.value);
    setFormataImposto({...formik.getFieldProps('custo_impostos_rec')}.value);
    setFormataPrecoPromocional({...formik.getFieldProps('preco_promocional')}.value);
    setValorNegociacao({...formik.getFieldProps('valorNegociacao')}.value);
  }, []);

  return (
    <div className='mb-5 mt-8 '>
      {inputs.map((value, index) => (
        <>
          <div className='mb-5 row' key={index}>
            <div className='col-md-3 mt-4'>
              <label className='form-label'>A Partir de:</label>
              <input
                type='date'
                placeholder=''
                {...formik.getFieldProps('custo_a_partir_de')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_a_partir_de && formik.errors.custo_a_partir_de && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_a_partir_de}</span>
                  </div>
                </div>
              )}
            </div>

            <div
              className='col-md-3  mt-4'
              style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
            >
              <label className='form-label'>Impostos Recuperáveis:</label>
              <input
                type='text'
                value={
                  formataImposto
                    ? `R$ ${parseFloat(formataImposto).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                className='form-control bg-transparent'
                onChange={(event) => {
                  const rawValue = event.target.value;
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
                  setFormataImposto(numericValue.toString());
                }}
              />

              {formik.touched.custo_impostos_rec && formik.errors.custo_impostos_rec && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_impostos_rec}</span>
                  </div>
                </div>
              )}
            </div>

            <div
              className='col-md-3  mt-4'
              style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
            >
              <label className='form-label'>Preço de Negociação:</label>
              <input
                type='text'
                value={
                  valorNegociacao
                    ? `R$ ${parseFloat(valorNegociacao).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                className='form-control bg-transparent'
                onChange={(event) => {
                  const rawValue = event.target.value;
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
                  setValorNegociacao(numericValue.toString());
                }}
              />

              {formik.touched.custo_impostos_rec && formik.errors.custo_impostos_rec && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_impostos_rec}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mb-5 row'>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Preço Custo:</label>
              <input
                type='string'
                placeholder='0,00'
                className='form-control bg-transparent'
                value={
                  formataPrecoCusto
                    ? `R$ ${parseFloat(formataPrecoCusto).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                onChange={(event) => {
                  const rawValue = event.target.value;
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
                  setFormataPrecoCusto(numericValue.toString());
                }}
              />

              {formik.touched.custo_preco && formik.errors.custo_preco && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_preco}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-3 mt-4'>
              <label className='form-label'>Preço de Venda:</label>
              <input
                type='string'
                placeholder='0,00'
                // {...formik.getFieldProps('custo_preco_venda')}
                className='form-control bg-transparent'
                // onChange={handleChange}
                value={
                  formataPrecoVenda
                    ? `R$ ${parseFloat(formataPrecoVenda).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                // className='form-control bg-transparent'
                onChange={(event) => {
                  const rawValue = event.target.value;

                  // Remove caracteres não numéricos e mantém os números
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');

                  // Divide o valor por 100 para aplicar casas decimais (evita NaN)
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;

                  // Atualiza o estado com o valor numérico
                  setFormataPrecoVenda(numericValue.toString());
                }}
              />

              {formik.touched.custo_preco_venda && formik.errors.custo_preco_venda && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_preco_venda}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-3  mt-4'>
              <label className='form-label'>Preço Compra:</label>
              <input
                type='string'
                placeholder='R$ 0,00'
                value={
                  formataPrecoCompra
                    ? `R$ ${parseFloat(formataPrecoCompra).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                className='form-control bg-transparent'
                onChange={(event) => {
                  const rawValue = event.target.value;

                  // Remove caracteres não numéricos e mantém os números
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');

                  // Divide o valor por 100 para aplicar casas decimais (evita NaN)
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;

                  // Atualiza o estado com o valor numérico
                  setFormataPrecoCompra(numericValue.toString());
                }}
              />

              {formik.touched.preco_compra && formik.errors.preco_compra && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.preco_compra}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-3  mt-4'>
              <label className='form-label'>Preço Promocional:</label>
              <input
                type='text'
                value={
                  formataPrecoPromocional
                    ? `R$ ${parseFloat(formataPrecoPromocional).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                className='form-control bg-transparent'
                onChange={(event) => {
                  const rawValue = event.target.value;

                  // Remove caracteres não numéricos e mantém os números
                  const cleanedValue = rawValue.replace(/[^\d]/g, '');

                  // Divide o valor por 100 para aplicar casas decimais (evita NaN)
                  const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;

                  // Atualiza o estado com o valor numérico
                  setFormataPrecoPromocional(numericValue.toString());
                }}
              />

              {formik.touched.preco_promocional && formik.errors.preco_promocional && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.preco_promocional}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='col-md-2  mt-10'>
              <label className='form-label'></label>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#0050DC',
                  fontWeight: '500',
                  marginTop: '8px',
                  fontSize: '10pt',
                  textDecoration: 'underline',
                }}
                type='button'
              >
                salvar
              </button>
            </div> */}
          </div>
          <Divider />
          <h4 style={{color: '#3f3f3f', marginTop: '10px'}}>Outras Informações</h4>
          <Row>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Unidade de Conversão (%):</label>
              <input
                type='number'
                placeholder='Unidade de conversão (%)'
                {...formik.getFieldProps('unidade_conversao')}
                className='form-control bg-transparent'
              />

              {formik.touched.unidade_conversao && formik.errors.unidade_conversao && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.unidade_conversao}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Mrg./Mark-up (%):</label>
              <input
                type='number'
                placeholder='Mrg./Mark-up (%)'
                {...formik.getFieldProps('mark_up')}
                className='form-control bg-transparent'
              />

              {formik.touched.mark_up && formik.errors.mark_up && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.mark_up}</span>
                  </div>
                </div>
              )}
            </div>
          </Row>

          <Row className='mt-4'>
            <Col md='3' className='col-12'>
              <label className='form-label'>ST (%):</label>
              <input
                className='form-control bg-transparent'
                type='number'
                placeholder='ST (%)'
                {...formik.getFieldProps('substituicaoTributaria')}
              />
              <small id='' className='form-text text-muted'>
                Valor de Distribuição Tributária %
              </small>
            </Col>
            <Col md='3' className='col-12'>
              <label className='form-label'>Frete (%):</label>
              <input
                className='form-control bg-transparent'
                type='number'
                placeholder='Frete (%)'
                {...formik.getFieldProps('valorFrete')}
              />
            </Col>

            <Col md='3' className='col-12'>
              <label className='form-label'>Difal (%):</label>
              <input
                className='form-control bg-transparent'
                type='number'
                placeholder='Difal (%)'
                {...formik.getFieldProps('diferencialAliquota')}
              />
              <small id='' className='form-text text-muted'>
                Diferencial de Alíquota do ICMS
              </small>
            </Col>

            <Col md='3' className='col-12'>
              <label className='form-label'>Valor do IPI Fixo (%):</label>
              <input
                className='form-control bg-transparent'
                type='number'
                placeholder='Valor do IPI (%)'
                {...formik.getFieldProps('valor_ipi_fixo')}
              />
              <small id='' className='form-text text-muted'>
                Somente para produtos com tributação específica
              </small>
            </Col>
          </Row>
        </>
      ))}

      {/* <div className='col-md-4 mt-4'>
        <button
          onClick={addInput}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#0050DC',
            fontWeight: '500',
            marginTop: '12px',
            fontSize: '10pt',
          }}
          type='button'
        >
          informar novo preço
        </button>
      </div> */}
    </div>
  );
};

export default Custos;
