import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {IBancos, smoothScrollToBottom, useModal} from '../../../shared';
import ModalBancos from './components/ModalBancos';
import { Link } from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemBancos = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [bancos, setBancos] = useState<IBancos[]>([]);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [codigo, setCodBanco] = useState<number | null>(null);
  const [isGrupoDespesasModal, openGrupoDespesasModal, closeGrupoDespesasModal] = useModal();

  const formDataState = {
    setTitulo: setTitulo,
    setDescricao: setDescricao,
    setCodBanco: setCodBanco,
    setStatus: setStatus,
    setBancos: setBancos,
    setPage: setPage,
  };

  const fetchBancos = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/listar`,
        {
          params: {
            status: status,
            page: page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
        }
        const {content, totalPages} = response.data;
        setBancos((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
      }
      if (response.data.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de bancos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsLoadingSearch(true);
    setPage(0);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/listar`,
        {
          params: {
            codigo: codigo,
            status: status,
            titulo: titulo ? titulo : null,
            descricao: descricao ? descricao : null,
            page: page,
          },
        }
      );
      if (response.status === 200) {
        if (page === 0) {
          setBancos([]);
        }
        formDataState.setBancos(response.data.content);
        const {content, totalPages} = response.data;
        setBancos(content);
        setHasMore(page < totalPages - 1);
      }
      if (response.data.content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de bancos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openGrupoDespesasModal();
  };

  const clearFiltros = () => {
    setTitulo(null);
    setCodBanco(null);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    fetchBancos(page);
  };

  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  useEffect(() => {
    fetchBancos(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Bancos
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Banco
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Titulo:
              </label>
              <input
                type='text'
                value={titulo ? titulo : ''}
                className='form-control'
                onChange={(e) => {
                  setTitulo(e.target.value);
                  formDataState.setTitulo
                    ? formDataState.setTitulo(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite o titulo'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Código do banco:
              </label>
              <input
                type='text'
                value={codigo ? codigo : ''}
                className='form-control'
                maxLength={3}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/\D/g, '');
                  inputValue = inputValue || '0';
                  const numericValue = parseInt(inputValue, 10);
                  formDataState.setCodBanco(numericValue);
                }}
                placeholder='Digite o código do banco'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  formDataState.setStatus
                    ? formDataState.setStatus(e.target.value)
                    : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  formDataState.setDescricao
                    ? formDataState.setDescricao(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Código</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {bancos?.map((banco) => (
                  <tr className='text-center'>
                    <td>{banco.id}</td>
                    <td>{banco.titulo}</td>
                    <td>{banco.codigo?banco.codigo : '...'}</td>
                    <td>{banco.descricao ? banco.descricao : '...'}</td>
                    <td>{banco.usuarioCriacaoNome}</td>
                    <td>{banco.dataCriacao}</td>
                    <td>{banco.usuarioAlteracaoNome ? banco.usuarioAlteracaoNome : '...'}</td>
                    <td>{banco.dataAlteracao}</td>
                    <td>{banco.status}</td>
                    <td>
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-success btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                        Opções
                        </button>
                        <ul
                          className='dropdown-menu'
                        >
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                openModal(
                                  true,
                                  banco.id,
                                  banco.titulo,
                                  banco.codigo ? banco.codigo: '',
                                  banco.descricao,
                                  banco.status
                                );
                              }}
                            >
                              <i className='bi-pencil'> </i>
                              <span className='mx-1'>Editar</span>
                            </button>
                          </li>
                          <li>
                            <Link
                              to={`/listagem-conta-corrente/${banco.id}`}
                              className='dropdown-item'
                              type='Button'
                            >
                              <i className='bi-box-arrow-up-right'></i>
                              <span className='mx-2'>Gerenciar Contas Correntes</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalBancos
        isOpen={isGrupoDespesasModal}
        onHide={closeGrupoDespesasModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadBancos={fetchBancos}
      />
    </div>
  );
};

export default ListagemBancos;
