import { Route, Routes, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { MasterLayout } from '../../_sysled/layout/MasterLayout';
import { HomePage } from '../pages/home/HomePage';
import { RelatorioVendasWrapper } from '../pages/dashboard/comercial/RelatorioVendas';
import { CadastroPromocao } from '../pages/campanha-promocional/criacao-promocao/CadastroPromocao';
import { ListagemPromocao } from '../pages/campanha-promocional/pesquisa-promocao/PesquisaListagemPromocao';
import { EdicaoPromocao } from '../pages/campanha-promocional/criacao-promocao/EdicaoPromocao';
import { ConsultaEstoqueWMS } from '../pages/consultas/estoques/ConsultaEstoqueWMS';
import { CadastroClientes } from '../pages/cadastros/clientes/CadastroCliente';
import { CadastroUsuarios } from '../pages/cadastros/usuarios/CadastroUsuario';
import { CadastroEmpresas } from '../pages/cadastros/empresas/CadastroEmpresa';
import { FormCadEmpresa } from '../pages/cadastros/empresas/indexEmpresas';
import { CadastroConsultores } from '../pages/cadastros/consultores/CadastroConsultores';
import { CadastroVendedores } from '../pages/cadastros/vendedores/CadastroVendedor';
import { CadastroPerfis } from '../pages/cadastros/permissoes/CadastroPerfis';
import { EditarUsuario } from '../pages/cadastros/usuarios/EditarUsuario';
import { EditarEmpresas } from '../pages/cadastros/empresas/EditarEmpresa';
import { EditarClientes } from '../pages/cadastros/clientes/EditarClientes';
import { ListagemClientes } from '../pages/listagem/clientes/ListagemClientes';
import { ListagemUsuariosTable } from '../pages/listagem/usuarios/ListagemUsuariosTable';
import { ListagemEmpresa } from '../pages/listagem/empresas/ListagemEmpresas';
import { ListagemPerfis } from '../pages/listagem/perfis/ListagemPerfis';
import { FormComponentIndex } from '../pages/cadastros/test-form-comp/TestFormComp';
import { useAuth } from '../modules/auth';
import CadastroParceiros from '../pages/cadastros/parceiros/CadastroParceiros';
import ListagemParceiros from '../pages/listagem/parceiros/ListagemParceiros';
import CadastroTipoVenda from '../pages/cadastros/tipo-venda/CadastroTipoVenda';
import ListagemTipoVenda from '../pages/listagem/tipo-venda/ListagemTipoVenda';
import { ListagemPropostaPedido } from '../pages/listagem/proposta-pedido/ListagemPropostaPedido';
import { CadastrarPrevenda } from '../pages/cadastros/proposta-pedido/CadastrarPropostaPedido';
import WorkflowAprovacao from '../pages/workflow/workflow_aprovacao/WorkflowAprovacao';
import ListagemWorkflowAprovacao from '../pages/workflow/workflow_aprovacao/ListagemWorkFlowAprovação';
import WorkflowComponentes from '../pages/workflow/workflow_componente/WorkflowComponentes';
import ListagemWorkFlowComponentes from '../pages/workflow/workflow_componente/ListagemWorkFlowComponentes';
import WorkflowSequencia from '../pages/workflow/workflow_sequencia/WorkflowSequencia';
import ListagemWorkFlowSequencia from '../pages/workflow/workflow_sequencia/ListagemWorkFlowSequencia';
import WorkflowPendencia from '../pages/workflow/workflow_pendencia/WorkflowPendencia';
import CadastrarProduto from '../pages/cadastros/produto/CadastroProduto';
import ListagemPendenciaCompra from '../pages/listagem/pendencia-compra/ListagemPendenciaCompra';
import {
  CategorizacaoProdutosCadastroPage,
  CategorizacaoProdutosListagemPage,
  LogisticaExpedicaoPage,
  ConfiguracoesRotinasListagemPage,
  UnidadeMedidaCadastroPage,
  UnidadeMedidaListagemPage,
  PedidoComprasListagemPage,
  PedidoComprasCadastroPage,
  CadastroSuprimentoPage,
  SuprimentoListagemPage,
  EnderecoListagemPage,
  LogListagemPage,
  SysledExpedicaoPage,
  SegmentoEmpreendimentoPage,
  SegmentoQuantidadePage,
  ClassificacaoEmpreendimentoPage,
  PorteEmpreendimentoPage,
  EmpreendimentosPage,
  ClientesEmpreendimentoPage,
  CadastroEmpreendimentoPage,
  TipoDeObraPage,
  FasesDaObraPage,
  SegmentosItensPage,
  DominiosPage,
  CadastroProdutoConsumoPage,
  ListagemProdutoConsumoPage,
  ListagemUnidadeTempoPage,
  GarantiaProdutoPage,
  StatusGarantiaPage,
  TipoGarantiaPage,
  ConfiguracaoPage,
  ConfiguracoesItensPage,
} from '../pages';
import { FluxoLogisticoPage, FluxoLogisticoCadastroPage } from '../pages';
import LogisticaSeparacaoCadastroPage from '../pages/cadastros/separacao-logistica/SeparacaoLogisticaPage';
import LogisticaSeparacaoListagemPage from '../pages/listagem/seperacao-logistica/SeperacaoLogisticaListagem';
import { FormularioCadastroPage, FormulariosListagemPage } from '../pages';
import CadastroSuprimento from '../pages/cadastros/cadastro-suprimento/CadastroSuprimento';
import EnderecoPage from '../pages/cadastros/endereco/EnderecoPage';
import CadastroFormasdePagamento from '../pages/cadastros/formas_de_pagamento/CadastroFormasDePagamento';
import ListagemFormasDePagamento from '../pages/listagem/formas-pagamento/ListagemFormasDePagamento';
import CadastroCondicaoPagamento from '../pages/cadastros/condicoes_pagamento/CadastroCondicoesDePagamento';
import ListagemCondicoesPagamento from '../pages/listagem/condicoes-pagamento/ListagemCondicoesPagamento';
import { CadastroFornecedor } from '../pages/cadastros/fornecedor/CadastroFornecedor';
import { ListagemFornecedor } from '../pages/listagem/fornecedor/ListagemFornecedor';
import { EditarFornecedor } from '../pages/cadastros/fornecedor/EditarFornecedor';
import { CadastroColaborador } from '../pages/cadastros/colaboradores/CadastroColaborador';
import { ListagemColaborador } from '../pages/listagem/colaboradores/ListagemColaborador';
import { EditarColaborador } from '../pages/cadastros/colaboradores/EditarColaborador';
import {
  ListagemEstoqueEntradasPage,
  ListagemEstoqueEntradasProdutosPage,
  ListagemEstoqueInventariMotivoPage,
  ListagemEstoqueInventarioPage,
  ListagemEstoqueInventarioProdutoPage,
  ListagemEstoqueLocalizacaoPage,
  ListagemEstoquesPage,
  ListagemEstoqueTiposPage,
  ListagemEstoqueTransferenciasPage,
  ListagemEstoqueTransferenciasProdutoPage,
  ListagemTipoLocalizacaoPage,
  ListagemTipoMovimentoPage,
} from '../pages/modulo-estoques/estoque';
import ListagemEstoqueMovimento from '../pages/modulo-estoques/estoque/estoque-movimento/ListagemEstoqueMovimento';
import ListagemEstoqueMovimentoProdutos from '../pages/modulo-estoques/estoque/estoque-movimento-produtos/ListagemEstoqueMovimentoProdutos';
import ListagemGrupoDespesas from '../pages/financeiro/grupos-despesas/ListagemGrupoDespesas';
import ListagemAprovadores from '../pages/financeiro/aprovadores/ListagemAprovadores';
import ListagemBancos from '../pages/financeiro/bancos/ListagemBancos';
import ListagemTiposDespesas from '../pages/financeiro/tipos-despesas/ListagemTiposDespesas';
import ListagemContaCorrente from '../pages/financeiro/conta-corrente/ListagemContaCorrente';
import ListagemMarcas from '../pages/suprimentos/marcas/ListagemMarcas';
import {
  ArquivosDoProjetoPage,
  CadastroProjetosPage,
  CustosProjetoPage,
  FaturaProjetoPage,
  OrcamentosProjetoPage,
  ProjetosPage,
  StatusProjetoPage,
  TipoCustosProjetoPage,
} from '../pages/projetos';
import StatusPagamento from '../pages/projetos/status-do-pagamento/StatusPagamento';
import Custos from '../pages/cadastros/produto/components/Custo';
import { AnaliseCredito, Credito, StatusAnalise } from '../pages/cobranca';
import { PendenciaCompraPage } from '../pages/suprimentos/pendencia-compra';
import ListagemCompradores from '../pages/compradores/ListagemCompradores';
import ListagemOrigem from '../pages/tipo-origem/ListagemOrigem';
import { ListagemClientesEmpresas } from '../pages/listagem/clientes-empresas/ListagemClientesEmpresas';
import { CadastroClientesEmpresas } from '../pages/cadastros/cadastro-clientes-empresas/CadastroClienteEmpresas';
import { EditarClientesEmpresas } from '../pages/cadastros/cadastro-clientes-empresas/EditarClientesEmpresas';
import ListagemContasAPagar from '../pages/financeiro/contas-a-pagar/ListagemContasAPagar';
import ListagemParcelas from '../pages/financeiro/parcelas/ListagemParcelas';
import ListagemPagamentos from '../pages/financeiro/pagamentos/ListagemPagamentos';
import ListagemAprovadoresSetores from '../pages/financeiro/aprovadores-setores/ListagemAprovadoresSetores';

import ListagemContasReceber from '../pages/contas-receber/ContasReceber';
import ListagemContasReceberParcelas from '../pages/contas-receber/pages/parcelas/ContasReceberParcelas';
import ListagemContasReceberPagamentos from '../pages/contas-receber/pages/pagamentos/ContasReceberPagamentos';
import ListagemLinhaProdutos from '../pages/linha-produtos/ListagemLinhaProdutos';
import PrazoPagamento from '../pages/financeiro/prazo-pagamento/PrazoPagamento';
import FormulariosArquivos from '../pages/sistema/formularios/formularios-arquivos/formularios-arquivos';

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  function organizaPermissoes(permissions) {
    const permissionMap = {};
    permissions.forEach((permission) => {
      permissionMap[permission.arquivo] = true;
    });
    return permissionMap;
  }

  const permissoes = currentUser?.usuariosPermissoes;
  const permissionMap = organizaPermissoes(permissoes);

  function renderRouteIfPermission(permissionKey, routeComponent) {
    return permissionMap[permissionKey] ? routeComponent : null;
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/inicio' />} />
        <Route path='inicio' element={<HomePage />} />
        {currentUser?.tipouser === 'ADMIN' ? (
          <>
            {/* Pages */}
            {/* Consultores */}
            <Route path='/consultor' element={<CadastroConsultores />} />
            <Route path='/consultor/:id' element={<CadastroConsultores />} />
            {/* Perfis */}
            <Route path='/perfis' element={<CadastroPerfis />} />
            <Route path='/perfis/:id' element={<CadastroPerfis />} />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />
            {/* Propostas Comerciais e/ou Pedido*/}
            <Route path='/propostas-comerciais' element={<CadastrarPrevenda tipo='propostas' />} />
            <Route
              path='/editar-proposta-comercial/:id'
              element={<CadastrarPrevenda tipo='propostas' />}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={<ListagemPropostaPedido tipo='propostas' />}
            />

            <Route path='/listagem-logs' element={<LogListagemPage></LogListagemPage>} />

            {/* Cadastros */}
            <Route path='/status-garantia' element={<StatusGarantiaPage />}></Route>
            <Route path='/tipo-garantia' element={<TipoGarantiaPage />}></Route>

            {/* Pedidos */}
            {/* <Route path='/pedidos' element={<CadastrarPrevenda tipo="pedidos" />} /> */}
            <Route path='/editar-pedidos/:id' element={<CadastrarPrevenda tipo='pedidos' />} />
            <Route path='/listagem-pedidos' element={<ListagemPropostaPedido tipo='pedidos' />} />
            {/* Clientes */}
            <Route path='/cadastro-clientes' element={<CadastroClientes />} />
            <Route path='/listagem-clientes' element={<ListagemClientes />} />
            <Route path='/editar-cliente/:id' element={<EditarClientes />} />

            {/* Clientes Empresas*/}
            <Route path='/listagem-clientes-empresa' element={<ListagemClientesEmpresas />} />
            <Route path='/cadastro-clientes-empresa' element={<CadastroClientesEmpresas />} />
            <Route path='/editar-cliente-empresa/:id' element={<EditarClientesEmpresas />} />

            {/* Empresas */}
            <Route path='/cadastro-empresas' element={<FormCadEmpresa />} />
            <Route path='/listagem-empresas' element={<ListagemEmpresa />} />
            <Route path='/editar-empresa/:id' element={<EditarEmpresas />} />
            {/* Tipos de venda */}
            <Route path='/cadastro-tipo-venda' element={<CadastroTipoVenda />}></Route>
            <Route path='/listagem-tipo-venda' element={<ListagemTipoVenda />}></Route>
            <Route path='/editar-tipo-venda/:id' element={<CadastroTipoVenda />}></Route>
            {/* Pendência de venda */}
            <Route path='/listagem-pendencia-compra' element={<ListagemPendenciaCompra />}></Route>

            <Route path='/pendencia-compra' element={<PendenciaCompraPage />}></Route>
            {/* Usuarios */}
            <Route path='/cadastro-usuarios' element={<CadastroUsuarios />} />
            <Route path='/listagem-usuarios' element={<ListagemUsuariosTable />} />
            <Route path='/editar-usuario/:id' element={<EditarUsuario />} />
            {/* Parceiros */}
            <Route path='/cadastro-parceiros' element={<CadastroParceiros />} />
            <Route path='/editar-parceiro/:id' element={<CadastroParceiros />} />
            <Route path='/listagem-parceiros' element={<ListagemParceiros />} />
            {/* Vendedores */}
            <Route path='/cadastro-vendedores' element={<CadastroVendedores />} />
            {/* Promoções */}
            <Route path='/promocao-cadastro' element={<CadastroPromocao />} />
            <Route path='/promocao-listagem' element={<ListagemPromocao />} />
            <Route path='/promocao-edicao/:id' element={<EdicaoPromocao />} />

            {/* Produtos */}
            <Route path='/produto-cadastro' element={<CadastrarProduto />} />
            <Route
              path='/produto-listagem'
              element={<ListagemProdutoConsumoPage isConsumo={false} />}
            />
            <Route path='/produto-edicao/:id' element={<CadastrarProduto />} />

            <Route path='/produto-categorizacao' element={<CategorizacaoProdutosCadastroPage />} />
            <Route
              path='/produto-categorizacao/:id'
              element={<CategorizacaoProdutosCadastroPage />}
            />
            <Route
              path='/produto-categorizacao-listagem'
              element={<CategorizacaoProdutosListagemPage />}
            />

            <Route path='/produto-unidade-medida' element={<UnidadeMedidaCadastroPage />} />

            <Route path='/produto-unidade-medida/:id' element={<UnidadeMedidaCadastroPage />} />

            <Route
              path='/produto-unidade-medida-listagem'
              element={<UnidadeMedidaListagemPage />}
            />

            <Route path='/sistema/linha-produto' element={<ListagemLinhaProdutos />} />

            <Route path='/produto-consumo-cadastro' element={<CadastroProdutoConsumoPage />} />
            <Route path='/produto-consumo-edicao/:id' element={<CadastroProdutoConsumoPage />} />
            <Route
              path='/produto-consumo-listagem'
              element={<ListagemProdutoConsumoPage isConsumo={true} />}
            />
            {/* Financeiro */}

            <Route
              path='/listagem-contas-a-pagar'
              element={<ListagemContasAPagar></ListagemContasAPagar>}
            />
            <Route path='/listagem-parcelas/:id' element={<ListagemParcelas></ListagemParcelas>} />
            <Route
              path='/listagem-pagamentos/:id'
              element={<ListagemPagamentos></ListagemPagamentos>}
            />
            <Route
              path='/listagem-grupo-despesas'
              element={<ListagemGrupoDespesas></ListagemGrupoDespesas>}
            />
            <Route
              path='/listagem-tipos-despesas/:id'
              element={<ListagemTiposDespesas></ListagemTiposDespesas>}
            />
            <Route
              path='/listagem-aprovadores'
              element={<ListagemAprovadores></ListagemAprovadores>}
            />
            <Route
              path='/listagem-aprovadores-setores/:id'
              element={<ListagemAprovadoresSetores></ListagemAprovadoresSetores>}
            />
            <Route path='/listagem-bancos' element={<ListagemBancos></ListagemBancos>} />
            <Route
              path='/listagem-conta-corrente/:id'
              element={<ListagemContaCorrente></ListagemContaCorrente>}
            />

            {/* Financeiro/ Formas de Pagamento */}
            <Route
              path='/cadastro-formas-de-pagamento'
              element={<CadastroFormasdePagamento></CadastroFormasdePagamento>}
            />
            <Route
              path='/listagem-formas-de-pagamento'
              element={<ListagemFormasDePagamento></ListagemFormasDePagamento>}
            />
            <Route
              path='/cadastro-formas-de-pagamento/:id'
              element={<CadastroFormasdePagamento></CadastroFormasdePagamento>}
            />

            {/* Financeiro/ Condicoes de Pagamento */}
            <Route
              path='/cadastro-condicoes-de-pagamento'
              element={<CadastroCondicaoPagamento></CadastroCondicaoPagamento>}
            />
            <Route
              path='/listagem-condicoes-de-pagamento'
              element={<ListagemCondicoesPagamento></ListagemCondicoesPagamento>}
            />
            <Route
              path='/cadastro-condicoes-de-pagamento/:id'
              element={<CadastroCondicaoPagamento></CadastroCondicaoPagamento>}
            />

            {/* Financeiro/ Contas a Receber */}
            <Route path='/prazo-de-pagamento' element={<PrazoPagamento />} />
            <Route path='/contas-a-receber' element={<ListagemContasReceber />} />
            <Route
              path='/contas-a-receber/parcelas/:id'
              element={<ListagemContasReceberParcelas />}
            />
            <Route
              path='/contas-a-receber/parcelas/:idParcela/pagamentos/:id'
              element={<ListagemContasReceberPagamentos />}
            />

            {/* Financeiro/ Analise de crédito */}
            <Route path='/cobranca/status-analise' element={<StatusAnalise />} />
            <Route path='/cobranca/credito' element={<Credito />} />
            <Route path='/cobranca/analise-credito' element={<AnaliseCredito />} />

            {/* Workflow-Aprovação */}
            <Route path='/workflow-aprovacao' element={<WorkflowAprovacao />} />
            <Route path='/workflow-aprovacao/:id' element={<WorkflowAprovacao />} />
            <Route path='/listagem-workflow-aprovacao' element={<ListagemWorkflowAprovacao />} />
            {/* Workflow-Componente */}
            <Route path='/workflow-componente' element={<WorkflowComponentes />} />
            <Route path='/workflow-componente/:id' element={<WorkflowComponentes />} />
            <Route path='/listagem-workflow-componente' element={<ListagemWorkFlowComponentes />} />
            {/* Workflow-Sequencia */}
            <Route path='/workflow-sequencia' element={<WorkflowSequencia />} />
            <Route path='/workflow-sequencia/:id' element={<WorkflowSequencia />} />
            <Route path='/listagem-workflow-sequencia' element={<ListagemWorkFlowSequencia />} />
            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Logística Expedição */}
            <Route path='/logistica-expedicao-listagem' element={<LogisticaExpedicaoPage />} />

            {/* Logística Expedição Sysled */}
            <Route path='/logistica-expedicao-sysled-listagem' element={<SysledExpedicaoPage />} />

            {/* Fluxo Logistico Listagem */}
            <Route path='/logistica-fluxo-listagem' element={<FluxoLogisticoPage />} />
            {/* Fluxo Logistico Edição */}
            <Route path='/logistica-fluxo-edicao/:id' element={<FluxoLogisticoCadastroPage />} />
            {/* Fluxo Logistico Cadastro */}
            <Route path='/logistica-fluxo-cadastro' element={<FluxoLogisticoCadastroPage />} />

            {/* Suprimento Pedido Status Controler */}
            <Route
              path='/suprimentos-cadastro'
              element={<CadastroSuprimentoPage></CadastroSuprimentoPage>}
            />
            <Route
              path='/suprimentos-listagem'
              element={<SuprimentoListagemPage></SuprimentoListagemPage>}
            />
            <Route
              path='/editar-suprimentos/:id'
              element={<CadastroSuprimentoPage></CadastroSuprimentoPage>}
            />

            {/* Fornecedores */}

            <Route path='/cadastro-fornecedor' element={<CadastroFornecedor />} />
            <Route path='/listagem-fornecedor' element={<ListagemFornecedor />} />
            <Route path='/editar-fornecedor/:id' element={<EditarFornecedor />} />

            {/* Marcas */}

            <Route path='/sistema/marcas' element={<ListagemMarcas />} />

            {/* Colaborador */}

            <Route path='/cadastro-colaborador' element={<CadastroColaborador />} />
            <Route path='/listagem-colaborador' element={<ListagemColaborador />} />
            <Route path='/editar-colaborador/:id' element={<EditarColaborador />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={<RelatorioVendasWrapper />}
            />
            <Route path='consultas/estoques/consulta-estoque' element={<ConsultaEstoqueWMS />} />
            <Route
              path='/consultas/estoques/listagem-estoques'
              element={<ListagemEstoquesPage></ListagemEstoquesPage>}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipos'
              element={<ListagemEstoqueTiposPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-localizacao'
              element={<ListagemEstoqueLocalizacaoPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipo-localizacao'
              element={<ListagemTipoLocalizacaoPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipo-movimento'
              element={<ListagemTipoMovimentoPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-movimento'
              element={<ListagemEstoqueMovimento></ListagemEstoqueMovimento>}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-movimento-produtos'
              element={<ListagemEstoqueMovimentoProdutos />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-entradas'
              element={<ListagemEstoqueEntradasPage></ListagemEstoqueEntradasPage>}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-entradas-produtos/:id'
              element={<ListagemEstoqueEntradasProdutosPage></ListagemEstoqueEntradasProdutosPage>}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-transferencias'
              element={<ListagemEstoqueTransferenciasPage></ListagemEstoqueTransferenciasPage>}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-transferencias-produtos/:id'
              element={<ListagemEstoqueTransferenciasProdutoPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-inventario'
              element={<ListagemEstoqueInventarioPage />}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-inventario-produtos/:id'
              element={
                <ListagemEstoqueInventarioProdutoPage></ListagemEstoqueInventarioProdutoPage>
              }
            />
            <Route
              path='/consultas/estoques/listagem-estoque-motivo-inventario'
              element={<ListagemEstoqueInventariMotivoPage />}
            />

            {/* PROJETOS */}
            <Route path='projetos' element={<ProjetosPage />} />
            <Route path='cadastro-projetos' element={<CadastroProjetosPage />} />
            <Route path='editar-projeto/:id' element={<CadastroProjetosPage />} />
            <Route path='status-do-projeto' element={<StatusProjetoPage />} />
            <Route path='status-pagamento' element={<StatusPagamento />} />
            <Route path='fatura-do-projeto' element={<FaturaProjetoPage />} />
            <Route path='orcamentos-do-projeto' element={<OrcamentosProjetoPage />} />
            <Route path='custos-do-projeto' element={<CustosProjetoPage />} />
            <Route path='tipos-de-custo' element={<TipoCustosProjetoPage />} />
            <Route path='arquivos-do-projeto/:id' element={<ArquivosDoProjetoPage />} />

            {/* Empreeendimentos */}
            <Route path='/empreendimentos' element={<EmpreendimentosPage />} />
            <Route path='/editar-empreendimentos/:id' element={<CadastroEmpreendimentoPage />} />
            <Route path='/empreendimentos-cadastro' element={<CadastroEmpreendimentoPage />} />
            <Route path='/segmentos-empreendimentos' element={<SegmentoEmpreendimentoPage />} />

            <Route
              path='/segmentos-quantidade-empreendimentos/:id'
              element={<SegmentoQuantidadePage />}
            />
            <Route
              path='/classificacao-empreendimentos'
              element={<ClassificacaoEmpreendimentoPage />}
            />
            <Route path='/porte-empreendimentos' element={<PorteEmpreendimentoPage />} />
            <Route path='/clientes-empreendimento/:id' element={<ClientesEmpreendimentoPage />} />
            <Route path='/tipo-de-obra' element={<TipoDeObraPage />} />
            <Route path='/fases-da-obra' element={<FasesDaObraPage />} />
            <Route path='/segmentos-itens/:id' element={<SegmentosItensPage />} />

            {/* Dominios */}
            <Route path='/dominios' element={<DominiosPage />} />

            {/* Unidade de Tempo */}
            <Route path='/unidade-tempo' element={<ListagemUnidadeTempoPage />} />

            {/* Garantia Produto */}
            <Route path='/garantia-produto' element={<GarantiaProdutoPage />} />

            {/* Formulários */}
            <Route path='/formularios-listagem' element={<FormulariosListagemPage />} />

            <Route path='/formularios-cadastro' element={<FormularioCadastroPage />} />

            <Route path='/editar-formularios/:id' element={<FormularioCadastroPage />} />

            <Route path='/formularios-arquivos/:id' element={<FormulariosArquivos />} />

            {/* Compradores */}
            <Route path='/compradores' element={<ListagemCompradores />} />

            {/* Origem */}
            <Route path='/tipo-origem' element={<ListagemOrigem />} />

            {/* { Logistica Separação } */}
            <Route path='/logistica-separacao' element={<LogisticaSeparacaoCadastroPage />} />

            <Route path='/logistica-separacao/:id' element={<LogisticaSeparacaoCadastroPage />} />

            <Route
              path='/logistica-separacao-listagem'
              element={<LogisticaSeparacaoListagemPage />}
            />

            {/* { Logistica Endereço } */}
            <Route path='/logistica-endereco' element={<EnderecoPage />} />
            <Route path='/logistica-endereco/:id' element={<EnderecoPage />} />

            <Route path='/logistica-endereco-listagem' element={<EnderecoListagemPage />} />

            {/* Configurações e Rotinas SQL */}
            <Route
              path='/configuracoes-rotinas-listagem'
              element={<ConfiguracoesRotinasListagemPage />}
            />

            <Route
              path='/configuracoes'
              element={<ConfiguracaoPage />}
            />
            <Route
              path='/configuracoes-itens/:id'
              element={<ConfiguracoesItensPage />}
            />

            {/* Pedido Compras */}

            <Route path='/pedido-compras-listagem' element={<PedidoComprasListagemPage />} />

            <Route path='/pedido-compras' element={<PedidoComprasCadastroPage />} />

            <Route path='/pedido-compras/:id' element={<PedidoComprasCadastroPage />} />
          </>
        ) : (
          <>
            {/* Cadastros */}
            <Route
              path='/status-garantia'
              element={renderRouteIfPermission('status-garantia-devolucao', <StatusGarantiaPage />)}
            ></Route>
            <Route
              path='/tipo-garantia'
              element={renderRouteIfPermission('tipo-garantia-devolucao', <TipoGarantiaPage />)}
            ></Route>

            {/* Produtos */}
            <Route
              path='/produto-cadastro'
              element={renderRouteIfPermission('produtos', <CadastrarProduto />)}
            />

            <Route
              path='/produto-listagem'
              element={renderRouteIfPermission(
                'produtos',
                <ListagemProdutoConsumoPage isConsumo={false} />
              )}
            />
            <Route
              path='/produto-edicao/:id'
              element={renderRouteIfPermission('produtos', <CadastrarProduto />)}
            />

            <Route
              path='/produto-consumo-cadastro'
              element={renderRouteIfPermission('produtosConsumo', <CadastroProdutoConsumoPage />)}
            />
            <Route
              path='/produto-consumo-edicao/:id'
              element={renderRouteIfPermission('produtosConsumo', <CadastroProdutoConsumoPage />)}
            />
            <Route
              path='/produto-consumo-listagem'
              element={renderRouteIfPermission(
                'produtosConsumo',
                <ListagemProdutoConsumoPage isConsumo={true} />
              )}
            />

            <Route
              path='/produto-categorizacao'
              element={renderRouteIfPermission('produtos', <CategorizacaoProdutosCadastroPage />)}
            />

            <Route
              path='/produto-categorizacao/:id'
              element={renderRouteIfPermission('produtos', <CategorizacaoProdutosCadastroPage />)}
            />

            <Route
              path='/produto-categorizacao-listagem'
              element={renderRouteIfPermission('produtos', <CategorizacaoProdutosListagemPage />)}
            />

            <Route
              path='/produto-unidade-medida'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaCadastroPage />)}
            />

            <Route
              path='/produto-unidade-medida/:id'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaCadastroPage />)}
            />
            <Route
              path='/produto-unidade-medida-listagem'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaListagemPage />)}
            />

            <Route
              path='/sistema/linha-produto'
              element={renderRouteIfPermission('produtos', <ListagemLinhaProdutos />)}
            />

            {/* Logística Expedição */}
            <Route
              path='/logistica-expedicao-listagem'
              element={renderRouteIfPermission('logisticaExpedicao', <LogisticaExpedicaoPage />)}
            />

            {/* Logística Expedição */}
            <Route
              path='/logistica-expedicao-sysled-listagem'
              element={renderRouteIfPermission('logisticaExpedicao', <SysledExpedicaoPage />)}
            />

            {/* Fluxo Logistico */}
            <Route
              path='/logistica-fluxo-listagem'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoPage />)}
            />

            <Route
              path='/logistica-fluxo-edicao/:id'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            <Route
              path='/logistica-fluxo-cadastro'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            {/* Configurações e Rotinas SQL */}
            <Route
              path='/configuracoes-rotinas-listagem'
              element={renderRouteIfPermission(
                'configRotinaSQL',
                <ConfiguracoesRotinasListagemPage />
              )}
            />

            {/* { Logistica Separação } */}
            <Route
              path='/logistica-separacao'
              element={renderRouteIfPermission(
                'separacaoLogistica',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao/:id'
              element={renderRouteIfPermission(
                'separacaoLogistica',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao-listagem'
              element={renderRouteIfPermission(
                'separacaoLogistica',
                <LogisticaSeparacaoListagemPage />
              )}
            />

            {/* { Logistica Endereço } */}
            <Route path='/logistica-endereco' element={<EnderecoPage />} />
            <Route path='/logistica-endereco:id' element={<EnderecoPage />} />

            <Route path='/logistica-endereco-listagem' element={<EnderecoListagemPage />} />

            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Propostas Comerciais*/}
            <Route
              path='/propostas-comerciais'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/editar-proposta-comercial/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='propostas' />
              )}
            />
            {/* Pedidos */}
            <Route
              path='/pedidos'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/editar-pedidos/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/listagem-pedidos'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='pedidos' />
              )}
            />
            {/* Promoções */}
            <Route
              path='/promocao-cadastro'
              element={renderRouteIfPermission('encartePromocional', <CadastroPromocao />)}
            />
            <Route
              path='/promocao-listagem'
              element={renderRouteIfPermission('encartePromocional', <ListagemPromocao />)}
            />
            <Route
              path='/promocao-edicao/:id'
              element={renderRouteIfPermission('encartePromocional', <EdicaoPromocao />)}
            />
            {/* Empresas */}
            <Route
              path='/cadastro-empresas'
              element={renderRouteIfPermission('empresas', <FormCadEmpresa />)}
            />
            <Route
              path='/listagem-empresas'
              element={renderRouteIfPermission('empresas', <ListagemEmpresa />)}
            />
            <Route
              path='/editar-empresa/:id'
              element={renderRouteIfPermission('empresas', <EditarEmpresas />)}
            />
            {/* Clientes */}
            <Route
              path='/cadastro-clientes'
              element={renderRouteIfPermission('clientes', <CadastroClientes />)}
            />
            <Route
              path='/listagem-clientes'
              element={renderRouteIfPermission('clientes', <ListagemClientes />)}
            />
            <Route
              path='/editar-cliente/:id'
              element={renderRouteIfPermission('clientes', <EditarClientes />)}
            />
            {/* Clientes Empresas */}
            <Route
              path='/listagem-clientes-empresa'
              element={renderRouteIfPermission('empresasClientes', <ListagemClientesEmpresas />)}
            />

            <Route
              path='/cadastro-clientes-empresa'
              element={renderRouteIfPermission('empresasClientes', <CadastroClientesEmpresas />)}
            />

            <Route
              path='/editar-cliente/:id'
              element={renderRouteIfPermission('empresasClientes', <EditarClientesEmpresas />)}
            />

            {/* Usuarios */}
            <Route
              path='/cadastro-usuarios'
              element={renderRouteIfPermission('usuarios', <CadastroUsuarios />)}
            />
            <Route
              path='/listagem-usuarios'
              element={renderRouteIfPermission('usuarios', <ListagemUsuariosTable />)}
            />
            <Route
              path='/editar-usuario/:id'
              element={renderRouteIfPermission('usuarios', <EditarUsuario />)}
            />
            {/*Parceiros*/}
            <Route
              path='/cadastro-parceiros'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/editar-parceiro/:id'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/listagem-parceiros'
              element={renderRouteIfPermission('parceiros', <ListagemParceiros />)}
            />

            {/* Perfis */}
            <Route path='/perfis' element={renderRouteIfPermission('perfis', <CadastroPerfis />)} />
            <Route
              path='/perfis/:id'
              element={renderRouteIfPermission('perfis', <CadastroPerfis />)}
            />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={renderRouteIfPermission('dashboardGerencial', <RelatorioVendasWrapper />)}
            />
            <Route
              path='consultas/estoques/consulta-estoque'
              element={renderRouteIfPermission('estoqueConsulta', <ConsultaEstoqueWMS />)}
            />

            {/* ESTOQUES */}
            <Route
              path='/consultas/estoques/listagem-estoques'
              element={renderRouteIfPermission(
                'estoques',
                <ListagemEstoquesPage></ListagemEstoquesPage>
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipos'
              element={renderRouteIfPermission('estoqueTipos', <ListagemEstoqueTiposPage />)}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-localizacao'
              element={renderRouteIfPermission(
                'estoqueLocalizacao',
                <ListagemEstoqueLocalizacaoPage />
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipo-localizacao'
              element={renderRouteIfPermission('tiposLocalizacao', <ListagemTipoLocalizacaoPage />)}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-tipo-movimento'
              element={renderRouteIfPermission('tiposMovimento', <ListagemTipoMovimentoPage />)}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-entradas'
              element={renderRouteIfPermission(
                'estoqueEntrada',
                <ListagemEstoqueEntradasPage></ListagemEstoqueEntradasPage>
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-entradas-produtos/:id'
              element={renderRouteIfPermission(
                'estoqueEntrada',
                <ListagemEstoqueEntradasProdutosPage></ListagemEstoqueEntradasProdutosPage>
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-transferencias'
              element={renderRouteIfPermission(
                'estoqueTransferencia',
                <ListagemEstoqueTransferenciasPage></ListagemEstoqueTransferenciasPage>
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-transferencias-produtos/:id'
              element={renderRouteIfPermission(
                'estoqueTransferencia',
                <ListagemEstoqueTransferenciasProdutoPage />
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-inventario'
              element={renderRouteIfPermission(
                'estoqueInventario',
                <ListagemEstoqueInventarioPage />
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-inventario-produtos/:id'
              element={renderRouteIfPermission(
                'estoqueInventario',
                <ListagemEstoqueInventarioProdutoPage></ListagemEstoqueInventarioProdutoPage>
              )}
            />
            <Route
              path='/consultas/estoques/listagem-estoque-motivo-inventario'
              element={renderRouteIfPermission(
                'inventarioMotivo',
                <ListagemEstoqueInventariMotivoPage />
              )}
            />

            {/* PROJETOS */}
            <Route
              path='projetos'
              element={renderRouteIfPermission('projetos', <ProjetosPage />)}
            />
            <Route
              path='cadastro-projeto/'
              element={renderRouteIfPermission('cadastroProjetos', <CadastroProjetosPage />)}
            />

            <Route
              path='editar-projeto/:id'
              element={renderRouteIfPermission('cadastroProjetos', <CadastroProjetosPage />)}
            />
            <Route
              path='status-do-projeto'
              element={renderRouteIfPermission('statusProjeto', <StatusProjetoPage />)}
            />
            <Route
              path='status-pagamento'
              element={renderRouteIfPermission('statusPagamento', <StatusPagamento />)}
            />
            <Route
              path='fatura-do-projeto'
              element={renderRouteIfPermission('faturaProjeto', <FaturaProjetoPage />)}
            />
            <Route
              path='orcamentos-do-projeto'
              element={renderRouteIfPermission('orcamentoProjeto', <OrcamentosProjetoPage />)}
            />
            <Route
              path='custos-do-projeto'
              element={renderRouteIfPermission('custosProjeto', <CustosProjetoPage />)}
            />
            <Route
              path='tipos-de-custo'
              element={renderRouteIfPermission('tiposCustosProjeto', <TipoCustosProjetoPage />)}
            />
            <Route
              path='arquivos-do-projeto/:id'
              element={renderRouteIfPermission(['arquivosProjetos'], <ArquivosDoProjetoPage />)}
            />

            {/* Empreendimentos */}

            <Route
              path='/empreendimentos'
              element={renderRouteIfPermission('listagemEmpreendimentos', <EmpreendimentosPage />)}
            />
            <Route
              path='/editar-empreendimentos/:id'
              element={renderRouteIfPermission(
                'cadastroEmpreendimentos',
                <CadastroEmpreendimentoPage />
              )}
            />
            <Route
              path='/empreendimentos-cadastro'
              element={renderRouteIfPermission(
                'cadastroEmpreendimentos',
                <CadastroEmpreendimentoPage />
              )}
            />
            <Route
              path='/segmentos-empreendimentos'
              element={renderRouteIfPermission(
                'segmentosEmpreendimento',
                <SegmentoEmpreendimentoPage />
              )}
            />

            <Route
              path='/segmentos-quantidade-empreendimentos/:id'
              element={renderRouteIfPermission(
                'quantidadeSegmentoEmpreendimento',
                <SegmentoQuantidadePage />
              )}
            />
            <Route
              path='/classificacao-empreendimentos'
              element={renderRouteIfPermission(
                'classificacaoEmpreendimento',
                <ClassificacaoEmpreendimentoPage />
              )}
            />
            <Route
              path='/porte-empreendimentos'
              element={renderRouteIfPermission('porteEmpreeendimento', <PorteEmpreendimentoPage />)}
            />
            <Route
              path='/clientes-empreendimento/:id'
              element={renderRouteIfPermission(
                'clientesEmpreendimento',
                <ClientesEmpreendimentoPage />
              )}
            />

            <Route
              path='/tipo-de-obra'
              element={renderRouteIfPermission('tipoDeObra', <TipoDeObraPage />)}
            />

            <Route
              path='/fases-da-obra'
              element={renderRouteIfPermission('fasesDaObra', <FasesDaObraPage />)}
            />

            <Route
              path='/segmentos-itens/:id'
              element={renderRouteIfPermission('itensSegmento', <SegmentosItensPage />)}
            />

            {/* Dominios */}

            <Route
              path='/dominios'
              element={renderRouteIfPermission('dominios', <DominiosPage />)}
            />

            {/* Unidade Tempo */}

            {/* Garantia do Produto */}

            <Route
              path='/garantia-produto'
              element={renderRouteIfPermission('produtos', <GarantiaProdutoPage />)}
            />

            {/* Pedido Compras */}

            <Route
              path='/pedido-compras-listagem'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasListagemPage />)}
            />

            <Route
              path='/pedido-compras'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasCadastroPage />)}
            />

            <Route
              path='/pedido-compras/:id'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasCadastroPage />)}
            />

            {/* Pendência de Compra */}

            <Route
              path='/listagem-pendencia-compra'
              element={renderRouteIfPermission('pendencia_venda', <ListagemPendenciaCompra />)}
            ></Route>

            <Route
              path='/pendencia-compra'
              element={renderRouteIfPermission('pendencia_venda', <PendenciaCompraPage />)}
            ></Route>

            {/* Suprimento Pedido Status Controler */}
            <Route
              path='/suprimentos-cadastro'
              element={renderRouteIfPermission('pedido_compras', <CadastroSuprimentoPage />)}
            />
            <Route
              path='/suprimentos-listagem'
              element={renderRouteIfPermission('pedido_compras', <SuprimentoListagemPage />)}
            />
            <Route
              path='/editar-suprimentos/:id'
              element={renderRouteIfPermission('pedido_compras', <CadastroSuprimentoPage />)}
            />

            {/* { Logistica Endereço } */}
            <Route path='/listagem-logs' element={<LogListagemPage></LogListagemPage>} />
            <Route
              path='/logistica-endereco'
              element={renderRouteIfPermission(['pedido_compras', 'logistica'], <EnderecoPage />)}
            />

            <Route
              path='/logistica-endereco/:id'
              element={renderRouteIfPermission(['pedido_compras', 'logistica'], <EnderecoPage />)}
            />

            <Route
              path='/logistica-endereco-listagem'
              element={renderRouteIfPermission(
                ['pedido_compras', 'logistica'],
                <EnderecoListagemPage />
              )}
            />

            {/* Financeiro */}

            <Route
              path='/listagem-contas-a-pagar'
              element={renderRouteIfPermission(
                'contasPagar',
                <ListagemContasAPagar></ListagemContasAPagar>
              )}
            />
            <Route
              path='/listagem-parcelas/:id'
              element={renderRouteIfPermission(
                'contasPagar',
                <ListagemParcelas></ListagemParcelas>
              )}
            />
            <Route
              path='/listagem-pagamentos/:id'
              element={renderRouteIfPermission(
                'contasPagar',
                <ListagemPagamentos></ListagemPagamentos>
              )}
            />
            <Route
              path='/listagem-grupo-despesas'
              element={renderRouteIfPermission(
                'grupoDespesas',
                <ListagemGrupoDespesas></ListagemGrupoDespesas>
              )}
            />
            <Route
              path='/listagem-tipos-despesas/:id'
              element={renderRouteIfPermission(
                'grupoDespesas',
                <ListagemTiposDespesas></ListagemTiposDespesas>
              )}
            />
            <Route
              path='/listagem-aprovadores'
              element={renderRouteIfPermission(
                'aprovadores',
                <ListagemAprovadores></ListagemAprovadores>
              )}
            />
            <Route
              path='/listagem-aprovadores-setores/:id'
              element={renderRouteIfPermission(
                'aprovadores',
                <ListagemAprovadoresSetores></ListagemAprovadoresSetores>
              )}
            />
            <Route
              path='/listagem-bancos'
              element={renderRouteIfPermission('bancos', <ListagemBancos></ListagemBancos>)}
            />
            <Route
              path='/listagem-conta-corrente/:id'
              element={renderRouteIfPermission(
                'bancos',
                <ListagemContaCorrente></ListagemContaCorrente>
              )}
            />

            {/* Financeiro/ Formas de Pagamento */}
            <Route
              path='/cadastro-formas-de-pagamento'
              element={renderRouteIfPermission('pagamento', <CadastroFormasdePagamento />)}
            />
            <Route
              path='/listagem-formas-de-pagamento'
              element={renderRouteIfPermission('pagamento', <ListagemFormasDePagamento />)}
            />
            <Route
              path='/cadastro-formas-de-pagamento/:id'
              element={renderRouteIfPermission('pagamento', <CadastroFormasdePagamento />)}
            />

            {/* Financeiro/ Condicoes de Pagamento */}
            <Route
              path='/cadastro-condicoes-de-pagamento'
              element={renderRouteIfPermission(['pagamento'], <CadastroCondicaoPagamento />)}
            />
            <Route
              path='/listagem-condicoes-de-pagamento'
              element={renderRouteIfPermission(['pagamento'], <ListagemCondicoesPagamento />)}
            />
            <Route
              path='/cadastro-condicoes-de-pagamento/:id'
              element={renderRouteIfPermission(['pagamento'], <CadastroCondicaoPagamento />)}
            />

            {/* Financeiro/ Contas a Receber */}
            <Route
              path='/contas-a-receber'
              element={renderRouteIfPermission(
                ['financeiro', 'contasReceber'],
                <ListagemContasReceber />
              )}
            />

            <Route
              path='/contas-a-receber/parcelas/:id'
              element={renderRouteIfPermission(
                ['financeiro', 'contasReceber'],
                <ListagemContasReceberParcelas />
              )}
            />
            <Route
              path='/contas-a-receber/parcelas/:idParcela/pagamentos/:id'
              element={renderRouteIfPermission(
                ['financeiro', 'contasReceber'],
                <ListagemContasReceberPagamentos />
              )}
            />

            {/* Financeiro/ Analise de crédito */}
            <Route
              path='/cobranca/status-analise'
              element={renderRouteIfPermission(
                ['financeiro', 'cobranca', 'status-analise'],
                <StatusAnalise />
              )}
            />
            <Route
              path='/cobranca/credito'
              element={renderRouteIfPermission(['financeiro', 'cobranca', 'credito'], <Credito />)}
            />
            <Route
              path='/cobranca/analise-credito'
              element={renderRouteIfPermission(
                ['financeiro', 'cobranca', 'analise-credito'],
                <AnaliseCredito />
              )}
            />

            {/* Financeiro/ Prazo de Pagamento */}
            <Route
              path='/prazo-de-pagamento'
              element={renderRouteIfPermission(
                ['prazoPagamento', 'financeiro'],
                <PrazoPagamento />
              )}
            />
            {/* Fornecedores */}

            <Route
              path='/cadastro-fornecedor'
              element={renderRouteIfPermission('fornecedores', <CadastroFornecedor />)}
            />
            <Route
              path='/listagem-fornecedor'
              element={renderRouteIfPermission('fornecedores', <ListagemFornecedor />)}
            />
            <Route
              path='/editar-fornecedor/:id'
              element={renderRouteIfPermission('fornecedores', <EditarFornecedor />)}
            />

            {/* Marcas */}

            <Route
              path='/sistema/marcas'
              element={renderRouteIfPermission('marcas', <ListagemMarcas />)}
            />

            {/* Colaborador */}

            <Route
              path='/cadastro-colaborador'
              element={renderRouteIfPermission('colaboradores', <CadastroColaborador />)}
            />
            <Route
              path='/listagem-colaborador'
              element={renderRouteIfPermission('colaboradores', <ListagemColaborador />)}
            />
            <Route
              path='/editar-colaborador/:id'
              element={renderRouteIfPermission('colaboradores', <EditarColaborador />)}
            />

            {/* Financeiro/ Analise de crédito */}
            <Route
              path='/cobranca/status-analise'
              element={renderRouteIfPermission('cobranca', <StatusAnalise />)}
            />
            <Route
              path='/cobranca/credito'
              element={renderRouteIfPermission('cobranca', <Credito />)}
            />
            <Route
              path='/cobranca/analise-credito'
              element={renderRouteIfPermission('cobranca', <AnaliseCredito />)}
            />

            {/* Compradores */}
            <Route
              path='/compradores'
              element={renderRouteIfPermission('compradores', <ListagemCompradores />)}
            />

            {/* Origem */}
            <Route
              path='/tipo-origem'
              element={renderRouteIfPermission('tipoOrigem', <ListagemOrigem />)}
            />
          </>
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
