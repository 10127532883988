import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import {ClienteOption} from '../../../pages/cadastros/proposta-pedido/types/interface';
import {ContainerOptionsAprovadores} from './async-select-aprovadores-styles';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectAprovadores: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [aprovadoresDetails, setAprovadoresDetails] = useState<any[]>();

  const [selectedAprovadores, setSelectedAprovadores] = useState<ClienteOption | null>(null);

  const loadOptionsAprovadores = async (id: string = '') => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores/listar?usuario=${id}`;

      const response = await axios.get(url);
      const data = await response.data.content;

      setAprovadoresDetails(data);

      return data.map((item) => ({
        value: item.id,
        label: item.descricao,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsAprovadoresById = async (id: string = '') => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setAprovadoresDetails([data]);

      return [data].map((item) => ({
        value: item.id,
        label: item.descricao,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const aprovadores = aprovadoresDetails?.find(
      (data) => Number(data.id) === Number(option.value)
    );

    if (aprovadores) {
      return (
        <ContainerOptionsAprovadores>
          <div className='container-message-tipo-entidade'>
            <div className='info-container-tipo-entidade'>
              <p>
                ID: <span>{aprovadores.id ? aprovadores.id : '-'}</span>
              </p>

              <p>
                Usuário: <span>{aprovadores.usuarioNome ? aprovadores.usuarioNome : ' - '}</span>
              </p>
              <p>
                Descrição: <span>{aprovadores.descricao ? aprovadores.descricao : ' - '}</span>
              </p>
            </div>
          </div>
        </ContainerOptionsAprovadores>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedAprovadores && handleOptions) {
      handleOptions(selectedAprovadores);
    }
  }, [selectedAprovadores]);

  useEffect(() => {
    if (inputOption) {
      if (!aprovadoresDetails) {
        loadOptionsAprovadoresById(String(inputOption.value));
      }
      setSelectedAprovadores(inputOption);
    } else if (inputOption == null) {
      setSelectedAprovadores(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Aprovador'
      loadOptions={loadOptionsAprovadores}
      value={selectedAprovadores}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectedAprovadores(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectAprovadores;
