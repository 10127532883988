import { Col, Row, } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { CrudHeader, ICrudBase, smoothScrollToBottom, TableListagem, useModal } from "../../../shared";
import ButtonEditListagem from "../../empreendimento/segmento-empreendimento/components/ButtonEditListagem";
import ModalDominios from "./components/ModalDominios";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const TipoDeObra = () => {

    // VARIAVEIS
    const [dominios, setDominios] = useState<ICrudBase[]>([])
    const [dataLine, setDataLine] = useState<ICrudBase[]>([])

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)

    const colums =['Id','Titulo', 'Descrição', 'Criado por', 'Data de criação', 'Alterado por', 'Data de alteração', 'Status', 'Ações']

    const [isDominiosModal, openDominiosModal, closeDominiosModal] = useModal();

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }
    const fetchDominios = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio/listar`,
                {
                    params: {
                        status: status,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                setDominios((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Tipos de Obra para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    }; 

    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setPage(0)
        setIsClear(true)
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchDominios(0);
    };

    const openModal = (isEdit=false, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openDominiosModal();
    }

    useEffect(() => {
        if (isClear) {
            fetchDominios(0)
        }
        setIsClear(false)
    }, [isClear])

    useEffect(() => {
        fetchDominios(page)
    }, [page]);

    return (
        <div>
            <CrudHeader  title='Dominios' titleCadastro='Dominio' clearFiltros={clearFiltros} handleSearch={handleSearch} isLoading={isLoading} openModal={openModal} >
                <Row className=" row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Titulo:</label>
                        <input type="text"
                            className="form-control"
                            value={titulo ? titulo : ""}
                            onChange={(e) => {
                                setTitulo(e.target.value)
                            }}
                            placeholder="Digite o titulo" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className=" row">
                    <label htmlFor="" className="form-label">Descrição:</label>
                    <Col className="">
                        <textarea name=""
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                            placeholder="Digite a descrição" className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>
            
            <TableListagem 
                    isLoading={isLoading}
                    coluns={colums}   
                    hasMore={hasMore} 
                    loadMore={loadMore}>
                            {dominios?.map((dominio) => (
                                <tr className="text-center">
                                    <td>{dominio.id}</td>
                                    <td>{dominio.titulo}</td>
                                    <td>{dominio.descricao ? dominio.descricao : "..."}</td>
                                    <td>{dominio.usuarioCriacaoNome}</td>
                                    <td>{dominio.dataCriacao}</td>
                                    <td>{dominio.usuarioAlteracaoNome? dominio.usuarioAlteracaoNome : "..."}</td>
                                    <td>{dominio.dataAlteracao}</td>
                                    <td>{dominio.status}</td>
                                    <td>
                                        <ButtonEditListagem handleEdit={() => { openModal(true,dominio.id) }}></ButtonEditListagem>
                                    </td>
                                </tr>
                            ))
                            }
            </TableListagem>

            <ModalDominios
            clearFiltros={clearFiltros} 
            dataLine={dataLine} 
            reload={fetchDominios} 
            isEdit={isEdit} 
            isOpen={isDominiosModal} 
            onHide={closeDominiosModal} ></ModalDominios>
        </div>
    )
}

export default TipoDeObra