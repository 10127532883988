import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import {AsyncSelectFornecedor, AsyncSelectProduct, AsyncSelectProductByIdSysled, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalEntradaProduto = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const {id} = useParams();
    
    const [selectedEntrada, setSelectedEntrada] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)
    const [quantidade, setQuantidade] = useState<number | null>(null)
    const [valor, setValor] = useState<string | null>(null)

    const cadastrarProdutoEntrada = async () => {
        try {
            if(selectedProduto && selectedEntrada && quantidade &&  ( valor !== '0' && valor !== null )){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas-itens`;

            const response = await axios.post(url, {
                idProduto: selectedProduto ? selectedProduto.value : null,
                idEntrada: id,
                quantidade: quantidade ? quantidade : null,
                valor: valor ? valor : null,
                status: status
            });

            Swal.fire({
                icon: 'success',
                title: `Produto da Entrada cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                onClose()
            })
        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const editarProdutoEntrada = async () => {
        try {
            if(selectedProduto && selectedEntrada && quantidade && ( valor === '0' || valor === null)){

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas-itens/${dataLine[0]}`;
            const response = await axios.put(url, {
                idProduto: selectedProduto ? selectedProduto.value : null,
                idEntrada: selectedEntrada ? selectedEntrada.value : null,
                quantidade: quantidade ? quantidade : null,
                valor: valor ? valor : null,
                status: status
            });
            Swal.fire({
                icon: 'success',
                title: `Produto da Entrada editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()

            });

        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const onClose = () => {
        clearForm()
        clearFiltros()
        reload!()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedProduto(null)
        setQuantidade(null)
        setValor(null)
    }
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarProdutoEntrada() : cadastrarProdutoEntrada()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        // Converte o inputDate (que pode ser no formato ISO ou no formato 'yyyy-mm-dd') para um objeto Date
        const date = new Date(inputDate);
      
        // Verifica se a data é válida
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        // Obtém o ano, mês e dia
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é zero-indexed, por isso somamos 1
        const day = date.getDate().toString().padStart(2, '0'); // Garante que o dia tenha 2 dígitos
      
        // Retorna a data no formato 'yyyy-mm-dd'
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        setSelectedEntrada({value:Number(id), label:`${id}`})
        if (isEdit) {
            setSelectedProduto({
                value: dataLine[1],
                label: `[${dataLine[1]}] ${dataLine[2]}`,
            })
            setQuantidade(dataLine[4])
            setValor(dataLine[5])
            setStatus(dataLine[10])
        }
    }, [dataLine])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Entrada do Produto
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form action="" >

                <Row className="mt-8">
                        <Col>
                            <label htmlFor="" className="form-label">Produto:</label>
                            <AsyncSelectProductByIdSysled onKeyDown={(e) => { setSelectedProduto(null) }} inputOption={selectedProduto} handleOptions={(e) => { setSelectedProduto(e) }}></AsyncSelectProductByIdSysled>
                            {( selectedProduto === null) && <span className={` form-label text-danger`}>*Por favor, informe o Produto</span>}
                        </Col>
                    </Row>
                    <Row className='my-4'>
                        <Col>
                            <label htmlFor="" className="form-label"  >ID da Entrada:</label>
                            <input className="form-control" value={id} disabled></input>

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade:</label>
                            <input value={quantidade ? quantidade : ""} onChange={(e) => { setQuantidade(Number(e.target.value)) }} className="form-control" type="number" placeholder="Digite a quantidade" />
                            {( quantidade === 0 || quantidade === null) && <span className={` form-label text-danger`}>*Por favor, informe a quantidade</span>}

                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col>
                            <label htmlFor="" className="form-label"  >Valor:</label>
                            <input type="text" value={
                                valor
                                    ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                            onChange={(event) => {
                                const rawValue = event.target.value;
                                const cleanedValue = rawValue.replace(/[^\d]/g, '');
                                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
                                setValor(numericValue.toString());
                              }} className="form-control" placeholder="Digite o valor" />
                            {( valor === '0' || valor === null) && <span className={` form-label text-danger`}>*Por favor, informe o Valor</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                </form>
            </div>
        </Modal >
    )
}

export default ModalEntradaProduto