import {useParams} from 'react-router-dom';
import PageTitulo from '../../components/Pagetitulo';
import {useEffect, useState} from 'react';
import {useProduto} from './hooks/useProduto';
import DadosGerais from './components/DadosGerais';
import DadosComplementares from './components/DadosComplementares';
import Precos from './components/Precos';
import Custos from './components/Custo';
import Outros from './components/Outros';
import CodigoBarras from './components/CodigoBarras';
import ProdutosSimilares from './components/ProdutosSimilares';
import KitProduto from './components/Kit';

const CadastrarProduto = () => {
  const {id} = useParams();

  const [activeSection, setActiveSection] = useState('geral');
  const {formik, loading, navigate, setIdDoFormulario,
    setTabelasDePrecosProduto
    ,setSelectedMarca
    ,setSelectedTipo_Embalagem
    ,setSelectedLinhaProduto
    ,setSelectedCategoria
    ,setSelectedEmbalagem
    ,setSelectedOrigem
    ,setSelectedTipo
    ,setBase64Image
    ,setIsSegmento
    ,setIsCodigoClassificacao
    ,setIsUnidadeMedidaVenda
    ,setIsFornecedor
    ,setIsEcommerce
    ,setIsMix
    ,setIsDivulgacao
    ,setIsDisponivelFabrica
    ,setIsEstoqueFabricacao
    ,setIsAguardandoComplemento
    ,setIsLinhaProduto,
    setSelectedGarantia,
    setIsUnidadeMedidaCompra,
  } = useProduto();

  const clearContext = () => {
    setTabelasDePrecosProduto([]);
    setSelectedMarca(null);
    setSelectedGarantia(null);
    setSelectedTipo_Embalagem({id: '', desc: ''});
    setSelectedLinhaProduto({id: '', desc: ''});
    setSelectedCategoria({id: '', desc: ''});
    setSelectedEmbalagem({id: '', desc: ''});
    setSelectedOrigem({id: '', desc: ''});
    setSelectedTipo({id: '', desc: ''});
    setBase64Image('');
    setIsSegmento(null);
    setIsCodigoClassificacao(0);
    setIsUnidadeMedidaVenda(null);
    setIsFornecedor(null);
    setIsEcommerce(false);
    setIsMix(false);
    setIsDivulgacao(false);
    setIsDisponivelFabrica(false);
    setIsEstoqueFabricacao(false);
    setIsAguardandoComplemento(false);
    setIsLinhaProduto(null);
    setIsUnidadeMedidaCompra(null);

    formik.setFieldValue('descricao', '');
    formik.setFieldValue('codigo_sku', '');
    formik.setFieldValue('ncm', '');
    formik.setFieldValue('gtin_ean', '');
    formik.setFieldValue('cest', '');
    formik.setFieldValue('preco_venda', '');
    formik.setFieldValue('preco_promocional', '');
    formik.setFieldValue('mark_up', '');
    formik.setFieldValue('preco_compra', '');
    formik.setFieldValue('unidade_conversao', '');
    formik.setFieldValue('unidade', '');
    formik.setFieldValue('peso_liq', '');
    formik.setFieldValue('peso_bruto', '');
    formik.setFieldValue('num_volumes', '');
    formik.setFieldValue('largura', '');
    formik.setFieldValue('altura', '');
    formik.setFieldValue('comprimento', '');
    formik.setFieldValue('controlar_estoque', '');
    formik.setFieldValue('estoque_inicial', '');
    formik.setFieldValue('estoque_min', '');
    formik.setFieldValue('estoque_max', '');
    formik.setFieldValue('sob_encomenda', '');
    formik.setFieldValue('controlar_lotes', '');
    formik.setFieldValue('localizacao', '');
    formik.setFieldValue('dias_preparacao', '');
    formik.setFieldValue('desc_complementar', '');
    formik.setFieldValue('img_produto_base64', '');
    formik.setFieldValue('img_url', '');
    formik.setFieldValue('link_video', '');
    formik.setFieldValue('slug', '');
    formik.setFieldValue('keywords', '');
    formik.setFieldValue('titulo_seo', '');
    formik.setFieldValue('custo_a_partir_de', '');
    formik.setFieldValue('custo_saldo_atual', '');
    formik.setFieldValue('custo_saldo_anterior', '');
    formik.setFieldValue('custo_impostos_rec', '');
    formik.setFieldValue('custo_preco', '');
    formik.setFieldValue('custo_medio', '');
    formik.setFieldValue('custo_preco_venda', '');
    formik.setFieldValue('fabricante', '');
    formik.setFieldValue('codigo_fabricante', '');
    formik.setFieldValue('uni_por_caixas', '');
    formik.setFieldValue('garantia', '');
    formik.setFieldValue('gtin_ean_tributavel', '');
    formik.setFieldValue('unidade_tributavel', '');
    formik.setFieldValue('fator_de_conversao', '');
    formik.setFieldValue('codigo_enquadramento_ipi', '');
    formik.setFieldValue('valor_ipi_fixo', '');
    formik.setFieldValue('obs_geral_produto', '');
    formik.setFieldValue('status', '');
    formik.setFieldValue('valorNegociacao', '');
    formik.setFieldValue('substituicaoTributaria', null);
    formik.setFieldValue('valorFrete', null);
    formik.setFieldValue('diferencialAliquota', null);
    formik.setFieldValue('isCompra', '');
    formik.setFieldValue('isRessuprimento', '');
    formik.setFieldValue('isVenda', '');
  }

  useEffect(() => {
    setIdDoFormulario(id);
  }, [id]);

  useEffect(() => {
    clearContext()
  },[])
  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className=' mb-10'>
        <PageTitulo id={id} tipoFormulario='Produto' />
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div className=' mt-8'>
          <div className='col-sm-12'>
            <div className='btn-group custom-nav-tabs' role='group' style={{width: '100%'}}>
              <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'geral' ? 'active' : ''}`}
                onClick={() => setActiveSection('geral')}
              >
                Dados gerais
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'complementares' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('complementares')}
              >
                Complementares
              </button>

              {id && (
                <button
                  type='button'
                  className={`btn btn-outline-secondary ${
                    activeSection === 'cod_barras' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('cod_barras')}
                >
                  Código de Barras
                </button>
              )}
              {id && (
                <button
                  type='button'
                  className={`btn btn-outline-secondary ${
                    activeSection === 'produtos-similares' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('produtos-similares')}
                >
                  Similares
                </button>
              )}
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'precos' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('precos')}
              >
                Preços
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'custos' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('custos')}
              >
                Custos
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'outros' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('outros')}
              >
                Outros
              </button>
              {id && (
                <button
                  type='button'
                  className={`btn btn-outline-secondary ${activeSection === 'kit' ? 'active' : ''}`}
                  onClick={() => setActiveSection('kit')}
                >
                  Kit
                </button>
              )}
            </div>
          </div>
        </div>
        {activeSection === 'geral' && <DadosGerais idParam={id} />}
        {activeSection === 'complementares' && <DadosComplementares />}
        {/* {activeSection === 'fichatecnica' && <FichaTecnica />} */}
        {/* {activeSection === 'anuncio' && <Anuncios />} */}
        {activeSection === 'precos' && <Precos />}
        {activeSection === 'produtos-similares' && <ProdutosSimilares />}
        {activeSection === 'custos' && <Custos />}
        {activeSection === 'outros' && <Outros />}
        {activeSection === 'cod_barras' && <CodigoBarras />}
        {activeSection === 'kit' && <KitProduto />}
      </div>
      <div>
        <div className='d-flex gap-8'>
          <button type='submit' className='btn-cadastro btn-bg-success' id='post_user_submit'>
            {id ? 'Atualizar' : 'Cadastrar'}

            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            className='btn-cadastro btn-danger'
            id='back'
            onClick={() => navigate('/produto-listagem')}
          >
            Voltar
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CadastrarProduto;
