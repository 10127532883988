import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {ICompradores, ISelectOption, Modal} from '../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {ProductOption} from '../../cadastros/proposta-pedido/types/interface';
import AsyncSelect from 'react-select/async';
import InputUserCompradores from './input-user-compradores/InputUserCompradores';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface ModalCompradoresProps {
  isOpen: boolean;
  onHide: () => void;
  data: ICompradores | null;
  isEdit: boolean;
  loadData: ({page}: {page: number}) => Promise<void>;
}

const ModalCompradores = ({isOpen, onHide, data, isEdit, loadData}: ModalCompradoresProps) => {
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUsuario, setSelectedUsuario] = useState<ISelectOption | null>(null);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setSelectedUsuario(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarCompradores = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores`;
      if (descricao && selectedUsuario?.value && status) {
        const response = await axios.post(url, {
          idUsuario: Number(selectedUsuario.value),
          descricao,
          status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Comprador cadastrado com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loadData({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarCompradores = async () => {
    setIsLoading(true);
    try {
      if (data) {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/${data.id}`;
        if (selectedUsuario && descricao && status) {
          const response = await axios.put(url, {
            idUsuario: Number(selectedUsuario.value),
            descricao,
            status,
          });

          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: `Comprador editado com sucesso`,
              timer: 3000,
              timerProgressBar: true,
            }).then(() => {
              onClose();
            });
            loadData({page: 0});
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data?.mensagem,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: `Você deve preencher todos os campos`,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Conteúdo não foi carregado!',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarCompradores() : cadastrarCompradores())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen && isEdit) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setDescricao(response.descricao);
          setSelectedUsuario({value: response.idUsuario, label: ''});
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Compradores
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id de Usuário:
                </label>
                {/* <input
                  type='number'
                  value={idUsuario ? idUsuario : ''}
                  className='form-control'
                  onChange={(e) => {
                    setIdUsuario(e.target.value);
                  }}
                  placeholder='Digite o ID de Usuário'
                /> */}
                <InputUserCompradores
                  handleOptions={(usuario) => {
                    setSelectedUsuario(usuario);
                  }}
                  inputOption={selectedUsuario}
                  onKeyDown={() => {
                    setSelectedUsuario(null);
                  }}
                />
                {(selectedUsuario === null || selectedUsuario === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Usuário</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Descrição:
                </label>
                <input
                  type='text'
                  value={descricao ? descricao : ''}
                  className='form-control'
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a Descrição'
                />
                {(descricao === null || descricao === '' || descricao === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a Descrição</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='3'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCompradores;
