import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import {ClienteOption} from '../../../pages/cadastros/proposta-pedido/types/interface';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectStatusAnalise: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedStatusAnalise, setSelectedStatusAnalise] = useState<ClienteOption | null>(null);
  const [isStatusAnaliseFiltered, setIsStatusAnaliseFiltered] = useState<ISelectOption[]>([]);
  const [defaultStatusAnaliseOptions, setDefaultStatusAnaliseOptions] = useState<ISelectOption[]>(
    []
  );

  const filteredOptionsSegment = (inputValue: string) => {
    return isStatusAnaliseFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptionsStatusAnalise = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsSegment(inputValue));
  };

  const loadOptionsStatusAnalise = async (titulo: string = '') => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-status-analise/listar?titulo=${titulo}`;

      const response = await axios.get(url);
      const data = await response.data.content;

      const options = data.map((item: any) => ({
        value: item.id,
        label: item.titulo,
      }));

      setIsStatusAnaliseFiltered(options);

      return options;
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsStatusAnaliseById = async (id: string | number = '') => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-status-analise/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      if (response.status === 200 && data) {
        setSelectedStatusAnalise({label: data.titulo, value: data.id});
      }

      return [data];
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchStatusAnaliseOptions = async () => {
      const options = (await loadOptionsStatusAnalise()) || [];
      setDefaultStatusAnaliseOptions(options);
    };

    fetchStatusAnaliseOptions();
  }, []);

  useEffect(() => {
    if (selectedStatusAnalise && handleOptions) {
      handleOptions(selectedStatusAnalise);
    }
  }, [selectedStatusAnalise]);

  useEffect(() => {
    if (inputOption && !selectedStatusAnalise) {
      loadOptionsStatusAnaliseById(inputOption.value);
    } else if (inputOption == null) {
      setSelectedStatusAnalise(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 99999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 99999,
        }),
      }}
      isClearable
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Status da Análise'
      loadOptions={loadFilteredOptionsStatusAnalise}
      value={selectedStatusAnalise}
      onChange={(selectedOption: ISelectOption | null) => {
        // Verifica se selectedOption não é null antes de definir o estado
        if (selectedOption) {
          setSelectedStatusAnalise(selectedOption);
        } else {
          setSelectedStatusAnalise(null); // Define como null caso a opção seja anulada
        }
      }}
      cacheOptions
      defaultOptions={defaultStatusAnaliseOptions}
    />
  );
};

export default AsyncSelectStatusAnalise;
