import * as Yup from 'yup';

interface ClienteOption {
  value: number;
  label: string;
}

interface AmbienteOption {
  value: any;
  label: string;
}
interface ProductOption {
  value: number;
  label: string;
}
interface UserOption {
  value: number;
  label: string;
}
interface ProdutoInfo {
  ambiente: string;
  ordem: number;
  idUnico: number;
  idProduto: string;
  produto: string;
  idloja: number | null;
  descloja: string;
  idprevenda: string;
  precoUnitario: string | null;
  precoPadrao: string | null;
  precoCusto: number | null;
  encarte: string;
  qtdProduto: string;
  imagem: string;
  dataprev: string;
  qtdComprado: string;
  estoqueTotal: number;
  date: string;
  hashDate: string;
  estoque: Estoque[];
  quantidade: number;
  total: number;
  ambienteDesc: string;
  ambienteId: number | null;
  ambienteComplemento: string;
  tipoEntregaDesc: string;
  tipoEntregaId: number | null;
  unidade: string;
  marca: string;
  tipoProduto: string;
  descontoProduto: number | null;
  obsproduto: string;
  obsStatusPrd: boolean;
  idTabelaDePrecoProduto: number | null;
  descTabelaDePrecoProduto: string;
  margem: number | null;
  ativaMargem: number;
  estoqueRestante: number;
}

interface FormaPagamentoInfo {
  idloja: number | null;
  idFormaPagamento: number;
  idCondicaoPagamento: number;
  descFormaPagamento: string;
  descCondicaoPagamento: string;
  valorRecebido: number;
  desconto: number;
  valorTotal: number;
  icone: string;
  pagamentoPrincipal: number;
}

interface Estoque {
  wms: string;
  CodigoProduto: number;
  EstoqueProduto: number;
}

interface EstoqueFiltro {
  wms: string;
  EstoqueProduto: number;
}

interface TabelaDadosItens {
  idProduto: string;
  produto: string;
  idloja: number | null;
  idprevenda: string;
  precoUnitario: string | null;
  precoCusto: string | null;
  encarte: string;
  qtdProduto: string;
  imagem: string;
  dataprev: string;
  qtdComprado: string;
  estoqueTotal: number;
  date: string;
  hashDate: string;
  estoque: Estoque[];
  quantidade: number;
  total: number;
  ambienteDesc: string;
  ambienteId: number | null;
  ambienteComplemento: string;
  tipoEntregaDesc: string;
  tipoEntregaId: number | null;
  unidade: string;
  marca: string;
  tipoProduto: string;
  descontoProduto: number | null;
  obsproduto: string;
  idTabelaDePrecoProduto: number | null;
}

interface TabelaDadosFormasPagamento {
  idloja: number | null;
  idFormaPagamento: number;
  idCondicaoPagamento: number;
  descFormaPagamento: string;
  descCondicaoPagamento: string;
  valorRecebido: number;
  desconto: number;
  valorTotal: number;
}

interface FormsPrevenda {
  valorFreteVisual: string | number | readonly string[] | undefined;
  dataemissao: any;
  vencimento: any;
  clienteid: null | string;
  almoxarifadoid: number;
  empresaid: null | string;
  consultorid: null | string;
  closerid: null | string;
  parceiroid: null | string;
  orcamentistaid: null | string;
  formapagamentoid: number;
  condicaoid: number;
  status: number;
  desconto: string;
  descontoporcentagem: string;
  tipoorigemid: number;
  tabelaprecoid: number;
  responsavel1Entrega: string;
  responsavel2Entrega: string;
  celular1Entrega: string;
  celular2Entrega: string;
  profissao1Entrega: string;
  profissao2Entrega: string;
  data1Entrega: string;
  data2Entrega: string;
  horaEntrega: string;
  cepEntrega: string;
  enderecoEntrega: string;
  bairroEntrega: string;
  cidadeEntrega: string;
  ufEntrega: string;
  numeroEntrega: string;
  pontoReferencia1Entrega: string;
  pontoReferencia2Entrega: string;
  usuariocriacao: string;
  obsprevenda: string;
  kinboxlink: string;
  valorFrete: string;
  cifFob: number;
  produtoNaoEncontrado: string;
  observacaoEntrega: string;
  isEntregaParcial: boolean;
  idTipoVenda: number;
  dataAcordadoCliente?: Date | string;
}

const hoje = new Date();
const timezoneOffset = hoje.getTimezoneOffset() * 60 * 1000;
const hojeLocal = new Date(hoje.getTime() - timezoneOffset);

const vencimento = new Date(hojeLocal);
vencimento.setMonth(hojeLocal.getMonth() + 1);

const dataEmissao = hojeLocal.toISOString().split('T')[0];
const dataVencimento = vencimento.toISOString().split('T')[0];

export const preVendaValidationSchema = Yup.object().shape({
  almoxarifadoid: Yup.number().required('Por favor, selecione o almoxarifado'),
});

export const formPreVenda: FormsPrevenda = {
  valorFreteVisual: '',
  dataemissao: dataEmissao,
  vencimento: dataVencimento,
  clienteid: '',
  almoxarifadoid: 0,
  empresaid: '',
  consultorid: '',
  closerid: '',
  parceiroid: '',
  orcamentistaid: '',
  formapagamentoid: -11,
  condicaoid: 0,
  status: 1,
  desconto: '',
  descontoporcentagem: '',
  tipoorigemid: 0,
  tabelaprecoid: 0,
  responsavel1Entrega: '',
  responsavel2Entrega: '',
  celular1Entrega: '',
  celular2Entrega: '',
  profissao1Entrega: '',
  profissao2Entrega: '',
  data1Entrega: '',
  data2Entrega: '',
  horaEntrega: '',
  cepEntrega: '',
  enderecoEntrega: '',
  bairroEntrega: '',
  cidadeEntrega: '',
  ufEntrega: '',
  numeroEntrega: '',
  pontoReferencia1Entrega: '',
  pontoReferencia2Entrega: '',
  usuariocriacao: '',
  obsprevenda: '',
  kinboxlink: '',
  valorFrete: '',
  cifFob: 0,
  produtoNaoEncontrado: '',
  observacaoEntrega: '',
  isEntregaParcial: false,
  idTipoVenda: 1,
};

export type {
  FormsPrevenda,
  ClienteOption,
  AmbienteOption,
  ProductOption,
  ProdutoInfo,
  FormaPagamentoInfo,
  Estoque,
  EstoqueFiltro,
  TabelaDadosItens,
  TabelaDadosFormasPagamento,
};
