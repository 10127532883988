import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  AsyncSelectAprovadores,
  AsyncSelectEmpresa,
  AsyncSelectStatusAnalise,
  AsyncSelectTipoEntidade,
  IMarcas,
  ISelectOption,
  Modal,
} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import {renderEntidade} from '../../credito/ListagemCredito';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type ModalCreditoProps = {
  isOpen: boolean;
  onHide: () => void;
  data: IMarcas | null;
  isEdit: boolean;
  loader: ({page}) => Promise<void>;
};

const ModalAnaliseCredito = ({isOpen, onHide, data, isEdit, loader}: ModalCreditoProps) => {
  const [valorSolicitado, setValorSolicitado] = useState<number | null>(null);
  const [valorAprovado, setValorAprovado] = useState<number | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataAprovacao, setIsDataAprovacao] = useState<Date | null>(null);

  const [isSelectAsyncTipoEntidade, setIsSelectAsyncTipoEntidade] = useState<ISelectOption | null>(
    null
  );
  const [isSelectAsyncEntidade, setIsSelectAsyncEntidade] = useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresa, setIsSelectAsyncEmpresa] = useState<ISelectOption | null>(null);
  const [isSelectAsyncStatusAnalise, setIsSelectAsyncStatusAnalise] =
    useState<ISelectOption | null>(null);
  const [isSelectAsyncAprovador, setIsSelectAsyncAprovador] = useState<ISelectOption | null>(null);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setValorSolicitado(null);
    setValorAprovado(null);
    setStatus('ATIVO');
    setIsDataAprovacao(null);
    setIsSelectAsyncTipoEntidade(null);
    setIsSelectAsyncEntidade(null);
    setIsSelectAsyncEmpresa(null);
    setIsSelectAsyncStatusAnalise(null);
    setIsSelectAsyncAprovador(null);
  };

  const cadastrarAnaliseCredito = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-analise-credito`;
      if (
        valorAprovado &&
        valorSolicitado &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEntidade &&
        isSelectAsyncEmpresa &&
        isSelectAsyncStatusAnalise &&
        isSelectAsyncAprovador &&
        isDataAprovacao &&
        status
      ) {
        const response = await axios.post(url, {
          valorAprovado: valorAprovado,
          valorSolicitado: valorSolicitado,
          idTipoEntidade: isSelectAsyncTipoEntidade ? isSelectAsyncTipoEntidade.value : '',
          idEntidade: isSelectAsyncEntidade ? isSelectAsyncEntidade.value : '',
          idEmpresa: isSelectAsyncEmpresa ? isSelectAsyncEmpresa.value : '',
          idStatusAnalise: isSelectAsyncStatusAnalise ? isSelectAsyncStatusAnalise.value : '',
          idAprovador: isSelectAsyncAprovador ? isSelectAsyncAprovador.value : '',
          dataAprovacao: isDataAprovacao ? isDataAprovacao : '',
          status: status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Análise de Crédito cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarAnaliseCredito = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-analise-credito/${data?.id}`;
      if (
        valorAprovado &&
        valorSolicitado &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEntidade &&
        isSelectAsyncEmpresa &&
        isSelectAsyncStatusAnalise &&
        isSelectAsyncAprovador &&
        isDataAprovacao &&
        status
      ) {
        const response = await axios.put(url, {
          valorAprovado: valorAprovado,
          valorSolicitado: valorSolicitado,
          idTipoEntidade: isSelectAsyncTipoEntidade ? isSelectAsyncTipoEntidade.value : '',
          idEntidade: isSelectAsyncEntidade ? isSelectAsyncEntidade.value : '',
          idEmpresa: isSelectAsyncEmpresa ? isSelectAsyncEmpresa.value : '',
          idStatusAnalise: isSelectAsyncStatusAnalise ? isSelectAsyncStatusAnalise.value : '',
          idAprovador: isSelectAsyncAprovador ? isSelectAsyncAprovador.value : '',
          dataAprovacao: isDataAprovacao ? isDataAprovacao : '',
          status: status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Análise de Crédito editada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarAnaliseCredito() : cadastrarAnaliseCredito())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-analise-credito/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setValorAprovado(response.valorAprovado);
          setValorSolicitado(response.valorSolicitado);
          setIsDataAprovacao(new Date(response.dataAprovacao));
          setIsSelectAsyncTipoEntidade({value: response.idTipoEntidade, label: ''});
          setIsSelectAsyncEntidade({value: response.idEntidade, label: ''});
          setIsSelectAsyncEmpresa({value: response.idEmpresa, label: ''});
          setIsSelectAsyncStatusAnalise({value: response.idStatusAnalise, label: ''});
          setIsSelectAsyncAprovador({value: response.idAprovador, label: ''});
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Análise de Crédito
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Tipo de Entidade:</label>
                <AsyncSelectTipoEntidade
                  handleOptions={(option) => setIsSelectAsyncTipoEntidade(option!)}
                  inputOption={isSelectAsyncTipoEntidade}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncTipoEntidade(null);
                    }
                  }}
                />
                {(isSelectAsyncTipoEntidade === null ||
                  isSelectAsyncTipoEntidade === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Tipo de Entidade
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Entidade:</label>
                {renderEntidade(
                  isSelectAsyncEntidade,
                  setIsSelectAsyncEntidade,
                  isSelectAsyncTipoEntidade
                )}
                {(isSelectAsyncEntidade === null || isSelectAsyncEntidade === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a entidade</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Status da Análise:</label>
                <AsyncSelectStatusAnalise
                  handleOptions={(option) => setIsSelectAsyncStatusAnalise(option!)}
                  inputOption={isSelectAsyncStatusAnalise}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncStatusAnalise(null);
                    }
                  }}
                />
                {(isSelectAsyncStatusAnalise === null ||
                  isSelectAsyncStatusAnalise === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Status da Análise
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Aprovador:</label>
                <AsyncSelectAprovadores
                  handleOptions={(option) => setIsSelectAsyncAprovador(option!)}
                  inputOption={isSelectAsyncAprovador}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncAprovador(null);
                    }
                  }}
                />
                {(isSelectAsyncAprovador === null || isSelectAsyncAprovador === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Aprovador</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Empresa:</label>
                <AsyncSelectEmpresa
                  handleOptions={(option) => setIsSelectAsyncEmpresa(option!)}
                  inputOption={isSelectAsyncEmpresa}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncEmpresa(null);
                    }
                  }}
                />
                {(isSelectAsyncEmpresa === null || isSelectAsyncEmpresa === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a Empresa</span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Data de Aprovação:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Aprovação'
                  onChange={(date) => setIsDataAprovacao(date)}
                  menuStyle={{zIndex: 100000}}
                  value={isDataAprovacao}
                />
                {(isDataAprovacao === null || isDataAprovacao === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a Data de Aprovação
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Valor Solicitado:</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Digite o valor solicitado'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(valorSolicitado) || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    setValorSolicitado(valorEmCentavos);
                  }}
                />
                {(valorSolicitado === null ||
                  valorSolicitado === 0 ||
                  valorSolicitado === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o valor solicitado
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Valor Aprovado:</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Digite o valor aprovado'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(valorAprovado) || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    setValorAprovado(valorEmCentavos);
                  }}
                />
                {(valorAprovado === null || valorAprovado === 0 || valorAprovado === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o valor aprovado
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAnaliseCredito;
