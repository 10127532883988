import axios from "axios";
import api from "../../../../service/api";
import {useFormik} from 'formik';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT


export const getParceiroById = async (id) => {
    try {
      const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/profissionais/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      throw error;
    }
  };


  export const getAllParceiros = async () => {
    try {
      const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      throw error;
    }
  };
