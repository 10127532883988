import {ReactNode} from 'react';
import {Spinner} from 'react-bootstrap';
import * as S from './search-styles';
import { useNavigate } from 'react-router-dom';

type IProps = {
  isLoadingSearch: boolean;
  clearFilters: () => void;
  handleFilters: () => void;
  children: ReactNode;
  isBack?: boolean;
  url?: string;
};

const SearchComponent: React.FC<IProps> = ({
  clearFilters,
  handleFilters,
  isLoadingSearch,
  children,
  isBack,
  url
}) => {

  const navigate = useNavigate()

  const redirectTo = () => {
    navigate(`${url}`)
  }

  return (
    <>
      <div className='mt-10'>
        <S.ConatinerFilters>
          <h4 className='text-dark'>FILTROS DE PESQUISA</h4>

          {children}

          <div className='col-12 my-10' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              {isBack ? 
                <button
                  type='button'
                  style={{width: '40%'}}
                  onClick={() => redirectTo()}
                  className='btn btn-info form-control'
                >
                  Voltar
                </button>
              : <></>}

              <button
                type='button'
                style={{width: '40%', marginLeft: '10px'}}
                onClick={() => clearFilters()}
                className='btn btn-danger form-control'
              >
                Limpar
              </button>
              <button
                onClick={() => handleFilters()}
                type='button'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </S.ConatinerFilters>
      </div>
    </>
  );
};

export default SearchComponent;
