import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {IEmpresa, IPrevenda, ISelectOption} from '../../domain-types';
import './async-select-empresa-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectEmpresa: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectEmpresaAsync, setSelectEmpresaAsync] = useState<ISelectOption | null>(null);
  const [empresaDetails, setEmpresaDetails] = useState<IEmpresa[]>();

  const loadOptionsEmpresa = async (inputValue: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas/optionsEmpresas?busca=${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      console.log(data);

      setEmpresaDetails(data);
      
      return data.map((item) => ({
        value: item.id,
        label: item.idEmpresa,
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const loadOptionsEmpresaById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setEmpresaDetails([data]);
      // console.log(data);

      return [
        {
          value: data.id,
          label:'11',
        },
      ];

    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const empresa = empresaDetails?.find((empresa) => empresa.id === Number(option.value));

    if (empresa) {
      return (
        <div className='container-message-empresa'>
          <div className='box-left-empresa'>
            <div className='info-container-empresa'>
              <p>
                ID: <span>{empresa.id ? empresa.id : '-'}</span>
              </p>
              <p>
                <span>{empresa.fantasia ? empresa.fantasia : '-'}</span>
              </p>

              <p>
                Razão Social:{' '}
                <span>
                  {empresa.razaosocial
                    ? empresa.razaosocial.length > 30
                      ? `${empresa.razaosocial.slice(0, 30)} ...`
                      : empresa.razaosocial
                    : ' - '}
                </span>
              </p>
              <p>
                CNPJ: <span>{empresa.cnpj ? empresa.cnpj : '-'}</span>
              </p>
            </div>
          </div>
          <div className='box-right-empresa'>
            <p>
              {' '}
              Cidade: <span> {empresa.cidade ? empresa.cidade : '-'} </span>{' '}
            </p>
            <p>
              {' '}
              CEP: <span> {empresa.cep ? empresa.cep : '-'} </span>{' '}
            </p>
            <p>
              {' '}
              Telefone: <span>{empresa.telefone ? empresa.telefone : '-'}</span>
            </p>
            <p>
              {' '}
              Email: <span> {empresa.email ? empresa.email : '-'} </span>{' '}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectEmpresaAsync && handleOptions) {
      handleOptions(selectEmpresaAsync);
      console.log(selectEmpresaAsync);
    }
  }, [selectEmpresaAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsEmpresaById(inputOption.value);
      setSelectEmpresaAsync(inputOption);
    } else if (inputOption == null) {
      setSelectEmpresaAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise a Empresa'
      loadOptions={loadOptionsEmpresa}
      value={selectEmpresaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectEmpresaAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectEmpresa;
