import styled from 'styled-components';

export const ContainerMessageCompradoresUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.3125rem 0rem;
  position: relative;
  z-index: 1;
  height: 100px;
  margin-left: 0.3125rem;
  height: 60px;

  span {
    font-weight: 600;
  }

  span,
  p {
    margin-bottom: 0.3125rem;
    font-size: 0.95rem;
    color: black !important;
  }

  .box-left-usuario {
    width: 50%;
    height: 100%;

    .info-container-usuario {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      margin-left: 0rem;
      padding: 0.3125rem 0rem;
      flex-direction: column;

      p {
        font-size: 0.95rem;
        color: black !important;
      }
    }

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .box-right-usuario {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.3125rem 0rem;

    height: 100%;

    @media (max-width: 1200px) {
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    height: 140px;
  }
`;

/* .options-input {
    width: 100%;
    height: 40px;
  }
  
  .container-message-marca {
    
  
    
  }
   */
