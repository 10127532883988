import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Badge, Col, Row, Spinner} from 'react-bootstrap';
import {useAuth} from '../../../modules/auth';
import {
  sincronizarFornecedor,
  buscaHistoricoCompras,
  getFornecedores,
} from '../listagem_requests/listagem_requests';
import {Modal, Button} from 'react-bootstrap';
import moment from 'moment';
import './styles/tableHistorico.css';
import InputMask from 'react-input-mask';
import {Formik, useFormik} from 'formik';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface Fornecedor {
  id: number;
  codigo: number;
  nome: string;
  razaosocial: string;
  fantasia: string;
  tipocliente: number;
  telefone: string;
  status: number;
  cpf: string;
  cnpj: string;
  cep: string;
  cidade: string;
  estado: string;
  endereco: string;
  bairro: string;
  numero: string;
}

export function ListagemFornecedor() {
  const [fornecedores, setFornecedores] = useState<Fornecedor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');
  const [modalShow, setModalShow] = useState(false); // Estado para controlar a exibição da modal
  const [historicoCompras, setHistoricoCompras] = useState([]);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState<Fornecedor | null>(null); // Estado para armazenar o cliente selecionado
  const {currentUser} = useAuth();
  const [filtroHistorico, setFiltroHistorico] = useState('');

  useEffect(() => {
    setSearchTerm('');
  }, [searchFiltro]);

  let initialValues = {
    razaoSocial: '',
    fantasia: '',
    cpf: '',
    cnpj: '',
  };

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>;
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>;
      case 9:
        return <Badge bg='warning'>SINCRONIZADO</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  const formatTipoCliente = (tipocliente) => {
    switch (tipocliente) {
      case 1:
        return <Badge bg='warning'>Pessoa Física</Badge>;
      case 0:
        return <Badge bg='primary'>Pessoa Jurídica</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  const handleClickCompras = async (fornecedor: Fornecedor) => {
    setFornecedorSelecionado(fornecedor);

    try {
      setLoading(true);
      const codigosEmpresas = currentUser?.empresasVinculadas?.map((empresa) => empresa.codigo);
      const codigosEmpresasString = codigosEmpresas?.join(',');

      if (fornecedor.codigo === null || fornecedor.codigo === 0) {
        setLoading(false);
        setHistoricoCompras([]);
        setModalShow(true);
      } else {
        const response = await buscaHistoricoCompras(
          fornecedor.codigo,
          codigosEmpresasString || ''
        );

        if (response.status === 200) {
          setLoading(false);
          setHistoricoCompras(response.data);
          setModalShow(true);
        } else {
          setLoading(false);
          console.error('Erro ao buscar histórico de compras');
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderizarHistoricoCompras = (historicoCompras) => {
    const filtrarHistoricoCompras = () => {
      return historicoCompras.filter((item) =>
        item.idProduto.toLowerCase().includes(filtroHistorico.toLowerCase())
      );
    };

    return (
      <div className='text-center table-container table-responsive'>
        <table className=' table-hover tablehist table-rounded table-row-bordered'>
          <thead className='thead-dark'>
            <tr className='fw-bold fs-6 text-gray-800'>
              <th className='text-center' style={{width: '5%'}}>
                Id. Prevenda
              </th>
              <th className='text-center' style={{width: '10%'}}>
                Consultor
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Id. Produto
              </th>
              <th className='text-center' style={{width: '40%'}}>
                Produto
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Quant.
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Preço Venda
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Tipo Produto
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Prod. Filho
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Grupo
              </th>
              <th className='text-center' style={{width: '5%', marginRight: '10px'}}>
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {historicoCompras.map((item, index) => (
              <tr key={index}>
                <td className='text-center'>{item.idPrevenda}</td>
                <td className='text-center' style={{width: '30px', height: '30px'}}>
                  {item.idConsultor} - {item.consultor}
                </td>
                <td className='text-center'>{item.idProduto}</td>
                <td className='text-center' style={{width: '50px', height: '50px'}}>
                  {item.produto}
                </td>
                <td className='text-center'>{item.produtoQuantidade}</td>
                <td className='text-center'>{`R$${parseFloat(item.precoVenda).toLocaleString(
                  'pt-BR',
                  {minimumFractionDigits: 2, maximumFractionDigits: 2}
                )}`}</td>
                <td className='text-center'>{item.tipoProduto}</td>
                <td className='text-center'>{item.produtoFilho}</td>
                <td className='text-center'>{item.grupo}</td>
                <td className='text-center'>
                  {moment(item.dataFinalizacaoPrevenda).format('DD/MM/YYYY')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (searchFiltro === 'id') {
      // Permite apenas números
      if (/^\d*$/.test(value)) {
        setSearchTerm(value);
      }
    } else {
      setSearchTerm(value);
    }
  };

  const [isLoadingFornecedor, setIsLoadingFornecedor] = useState<boolean>(false);

  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPage, setIsPage] = useState<number>(0);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingFornecedor(true);
      try {
        const response = await getFornecedores({
          cnpj: values.cnpj,
          cpf: values.cpf,
          fantasia: values.fantasia,
          razaoSocial: values.razaoSocial,
          page: 0,
        });

        const {content} = response.data;

        setIsLoadingFornecedor(false);

        setFornecedores(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoadingFornecedor(false);

        setSubmitting(false);
      }
    },
  });

  const getFornecedoresData = async (page = 0) => {
    try {
      const response = await getFornecedores({
        cnpj: '',
        cpf: '',
        fantasia: '',
        razaoSocial: '',
        page: page,
      });

      const {content, totalPages} = response.data;

      setFornecedores((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingFornecedor(false);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        if (status === 401 || 403) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: error,
            confirmButtonText: 'Ok',
          });
        }
      }

      setIsLoadingFornecedor(false);
    }
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const clearFilter = () => {
    formik.resetForm();
    getFornecedoresData();
  };

  useEffect(() => {
    getFornecedoresData(isPage);
  }, [isPage]);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Fornecedores</h1>
        <Link
          to='/cadastro-fornecedor'
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Novo Fornecedor
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA </h4>

        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <Row style={{marginTop:'20px'}}>
            <Col md='6' className='col-sm-6'>
              <label className='form-label'>Razão Social:</label>
              <input
                type='text'
                placeholder='Digite a razao social'
                {...formik.getFieldProps('razaoSocial')}
                className='form-control'
              />
            </Col>
            <Col md='6' className='col-sm-6'>
              <label className='form-label'>Fantasia:</label>
              <input
                type='text'
                placeholder='Digite a fantasia'
                {...formik.getFieldProps('fantasia')}
                className='form-control'
              />
            </Col>
          </Row>
          <Row style={{marginTop:'20px'}}>
            <Col md='6' className='col-sm-6'>
              <label className='form-label'>CPF:</label>
              <InputMask
                type='text'
                mask='999.999.999-99'
                placeholder='Digite o CPF'
                {...formik.getFieldProps('cpf')}
                className='form-control'
              />
            </Col>
            <Col md='6' className='col-sm-6'>
              <label className='form-label'>CNPJ:</label>
              <InputMask
                type='text'
                mask='99.999.999/9999-99'
                placeholder='Digite o CNPJ'
                {...formik.getFieldProps('cnpj')}
                className='form-control'
              />
            </Col>
          </Row>
          <div className='row mt-6 flex-end'>
            <div className='col-sm-2 mb-6'>
              <button
                  type='button'
                  onClick={() => clearFilter()}
                  className='btn btn-danger form-control'
                >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
            </div>
            <div className='col-sm-2 mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                {isLoadingFornecedor ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>Id</th>
                  <th className='text-center'>Razão Social</th>
                  <th className='text-center'>Nome/Fantasia</th>
                  <th className='text-center'>Tipo</th>
                  {/* <th className='text-center'>Histórico</th> */}
                  <th className='text-center'>Editar</th>
                  <th className='text-center'>Sincronizar</th>
                </tr>
              </thead>
              <tbody>
                {fornecedores.map((fornecedor, index) => (
                  <tr key={index}>
                    <td className='text-center'>{fornecedor.id}</td>
                    <td className='text-center'>
                      <div>{fornecedor.nome || 'N/A'}</div>
                      <div>{formatStatus(fornecedor.status)}</div>
                    </td>
                    <td className='text-center'>{fornecedor.fantasia || '-'}</td>
                    <td className='text-center'>
                      {formatTipoCliente(fornecedor.tipocliente) || 'N/A'}
                    </td>
                    <td className='text-center'>
                      <Link
                        to={`/editar-fornecedor/${fornecedor.id}`}
                        className='btn btn-success btn-sm'
                      >
                        Editar
                      </Link>
                    </td>
                    <td className='text-center'>
                      {fornecedor.status === 9 || fornecedor.status === 0 ? (
                        <button
                          className='btn btn-primary btn-sm disabled'
                          style={{display: 'none'}}
                        >
                          Sincronizar
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (
                              fornecedor.tipocliente === 0 &&
                              (fornecedor.cnpj === '' || !fornecedor.cnpj)
                            ) {
                              Swal.fire({
                                title:
                                  'Cliente(Pessoa Jurídica) sem CNPJ cadastrado. Por favor, atualize o cadastro do fornecedor e tente novamente.',
                                icon: 'info',
                                confirmButtonText: 'Ok',
                              });
                              return;
                            }

                            if (
                              fornecedor.tipocliente === 1 &&
                              (fornecedor.cpf === '' || !fornecedor.cpf)
                            ) {
                              Swal.fire({
                                title:
                                  'Cliente(Pessoa Física) sem CPF cadastrado. Por favor, atualize o cadastro do fornecedor e tente novamente.',
                                icon: 'info',
                                confirmButtonText: 'Ok',
                              });
                              return;
                            }

                            if (
                              fornecedor.cep === '' ||
                              fornecedor.cidade === '' ||
                              fornecedor.estado === '' ||
                              fornecedor.endereco === '' ||
                              fornecedor.bairro === '' ||
                              fornecedor.numero === ''
                            ) {
                              Swal.fire({
                                title:
                                  'Cliente com endereço incompleto. Por favor, complete os campos de ENDEREÇO do Cliente.',
                                text: '',
                                icon: 'info',
                                confirmButtonText: 'Ok',
                              });
                              return;
                            }

                            Swal.fire({
                              title:
                                'Você tem certeza que deseja sincronizar o fornecedor com id: ' +
                                fornecedor.id +
                                '?',
                              icon: 'info',
                              showCancelButton: true,
                              confirmButtonText: 'Sim',
                              cancelButtonText: 'Não',
                              reverseButtons: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setLoading(true);
                                sincronizarFornecedor(fornecedor.id)
                                  .then((response) => {
                                    let responseinfo = response.data;
                                    setLoading(false);
                                    if (response.status === 200) {
                                      Swal.fire({
                                        icon: 'success',
                                        title: 'Cliente sincronizada com sucesso!',
                                        confirmButtonText: 'Ok',
                                        reverseButtons: true,
                                      });
                                      getFornecedoresData(); // Recarrega a lista de prevendas após a clonagem
                                    } else if (response.status === 400) {
                                      setLoading(false);
                                      const errorMessage = responseinfo.message;
                                      Swal.fire({
                                        icon: 'info',
                                        title: 'Verifique as informações',
                                        text:
                                          errorMessage ||
                                          'Ocorreu um erro ao tentar sincronizar o fornecedor. Por favor, tente novamente mais tarde.',
                                        confirmButtonText: 'Ok',
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    setLoading(false);
                                    console.error(error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Erro ao sincronizar prevenda',
                                      text:
                                        error.response?.data?.message ||
                                        'Ocorreu um erro ao tentar sincronizar o fornecedor. Por favor, tente novamente mais tarde.',
                                      confirmButtonText: 'Ok',
                                    });
                                  });
                              }
                            });
                          }}
                          className='btn btn-success btn-sm'
                          style={{backgroundColor: '#3fd975', borderColor: '#0099ff'}}
                        >
                          Sincronizar
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {isHasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <Modal size={'xl'} show={modalShow} centered={true} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Histórico de Compras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fornecedorSelecionado && (
            <div className=''>
              {' '}
              {/* Defina a altura máxima e adicione rolagem vertical */}
              <h3>{fornecedorSelecionado.nome}</h3>
              <hr />
              <div>
                {historicoCompras.length > 0 ? (
                  renderizarHistoricoCompras(historicoCompras)
                ) : (
                  <p>Não há histórico de compras disponível para este fornecedor.</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalShow(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
