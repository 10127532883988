import {Col, Row, Spinner} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {EstoqueInventario, formatarData, smoothScrollToBottom, useModal} from '../../../../shared';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom';
import ModalEstoqueInventario from './components/ModalEstoqueInventario';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueInventario = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const [inventarios, setInventarios] = useState<EstoqueInventario[]>([]);
  const [dataLine, setDataLine] = useState<EstoqueInventario[]>([]);

  const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [dataMovimento, setDataMovimento] = useState<string | null>(null);

  const [isInventarioModal, openInventarioModal, closeInventarioModal] = useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };
  const clearFiltros = () => {
    setStatus('ATIVO');
    setSelectedMotivo(null);
    setDataMovimento(null);
    setPage(0);
  };

  const fetchEstoqueInventario = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario/listar`,
        {
          params: {
            status: status,
            idMotivo: selectedMotivo?.value,
            dataMovimento: dataMovimento ? dataMovimento : null,
            page: page,
          },
        }
      );
      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
          setInventarios([]);
        }
        setInventarios((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Inventário  para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const openModal = (isEdit, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openInventarioModal();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchEstoqueInventario(0);
  };

  const loadOptionsInventarioMotivo = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-motivos/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchEstoqueInventario(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Inventário
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Inventários
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form className='my-9' action=''>
          <Row className='my-4 mt-8'>
            <Col>
              <label htmlFor='' className='form-label'>
                Motivo:
              </label>
              <AsyncSelect
                defaultOptions
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedMotivo(null);
                  }
                }}
                value={selectedMotivo}
                placeholder='Selecione um motivo'
                onChange={(e) => {
                  setSelectedMotivo(e);
                }}
                loadOptions={loadOptionsInventarioMotivo}
              ></AsyncSelect>
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Data do Movimento:
              </label>
              {/* <AsyncSelectFornecedor  inputOption={selectedFornecedor} handleOptions={(e)=>{setSelectedFornecedor(e)}}></AsyncSelectFornecedor> */}
              <input
                value={dataMovimento ? dataMovimento : ''}
                onChange={(e) => {
                  setDataMovimento(e.target.value);
                }}
                className='form-control'
                type='date'
                placeholder='Digite a quantidade'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
            {/* Numero Documento */}
          </Row>
          <div className='col-12 my-10' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                Limpar
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={40} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Motivo</th>
                  <th className='text-center'>Data do Movimento</th>
                  <th className='text-center'>Criado Por</th>
                  <th className='text-center'>Data de Criação</th>
                  <th className='text-center'>Alterado Por</th>
                  <th className='text-center'>Data de Alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {inventarios?.map((inventario) => (
                  <tr className='text-center'>
                    <td>{inventario.id}</td>
                    <td>{inventario.motivo}</td>
                    <td>{formatarData(inventario.dataMovimento)}</td>
                    <td>{inventario.usuarioCriacaoNome}</td>
                    <td>{inventario.dataCriacao}</td>
                    <td>
                      {inventario.usuarioAlteracaoNome ? inventario.usuarioAlteracaoNome : '...'}
                    </td>
                    <td>{inventario.dataAlteracao}</td>
                    <td>{inventario.status}</td>
                    <td>
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-success btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          <i className=''>Opções</i>
                        </button>
                        <ul
                          className='dropdown-menu'
                          style={{
                            position: 'absolute',
                            zIndex: 1050,
                            top: '100%',
                            left: '0',
                          }}
                        >
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                openModal(
                                  true,
                                  inventario.id,
                                  inventario.idProduto,
                                  inventario.produto,
                                  inventario.idMotivo,
                                  inventario.motivo,
                                  inventario.dataMovimento,
                                  inventario.status
                                );
                              }}
                            >
                              <i className='bi-pencil'> </i>
                              <span className='mx-2'>EDITAR</span>
                            </button>
                          </li>

                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                          <li>
                            <Link
                              to={`/consultas/estoques/listagem-estoque-inventario-produtos/${inventario.id}`}
                              className='dropdown-item'
                              type='Button'
                            >
                              <i className='bi-box-arrow-up-right'></i>
                              <span className='mx-2'>GERENCIAR PRODUTOS</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalEstoqueInventario
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchEstoqueInventario}
        isEdit={isEdit}
        isOpen={isInventarioModal}
        onHide={closeInventarioModal}
      ></ModalEstoqueInventario>
    </div>
  );
};

export default ListagemEstoqueInventario;
