import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalAprovadores = ({isOpen, onHide, dataLine, isEdit, loadAprovadores}) => {
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [selectedUsuario, setSelectedUsuario] = useState<ISelectOption | null>(null);
  const [limiteCredito, setLimiteCredito] = useState<number | null>(null);
  const {id} = useParams();

  const handleChangeStatus = (value) => {
    formDataState.setStatus(value);
  };

  const formDataState = {
    setLimiteCredito: setLimiteCredito,
    setSelectedUsuario: setSelectedUsuario,
    setDescricao: setDescricao,
    setStatus: setStatus,
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const onClose = () => {
    onHide();
    setDescricao(null);
    setSelectedUsuario(null);
    setLimiteCredito(0);
    setStatus('ATIVO');
    onClear();
  };

  const onClear = () => {
    setDescricao(null);
    setSelectedUsuario(null);
    setLimiteCredito(null);
    setStatus('ATIVO');
  };

  const handleLimiteCreditoChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue !== '') {
      // inputValue = inputValue.replace(/^0+/, '');
      inputValue = inputValue || '0';

      const numericValue = parseFloat(inputValue) / 100;
      formDataState.setLimiteCredito(numericValue);
    } else {
      setLimiteCredito(0);
    }
    console.log('LimiteCredito', inputValue);
  };

  const cadastrarAprovador = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores`;
      if (selectedUsuario) {
        const response = await axios.post(url, {
          id: id,
          idUsuario: selectedUsuario.value,
          usuario: selectedUsuario.label,
          descricao: descricao || '',
          status: status,
          limiteCredito: limiteCredito || 0,
        });

        Swal.fire({
          icon: 'success',
          title: `Aprovador cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadAprovadores();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Informe um usuário',
          confirmButtonText: 'Ok',
        });
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarAprovador = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-aprovadores/${dataLine[0]}`;
      if (selectedUsuario && selectedUsuario.value) {
        const response = await axios.put(url, {
          id: id,
          idUsuario: selectedUsuario.value,
          usuario: selectedUsuario.label,
          descricao: descricao || '',
          status: status,
          limiteCredito: limiteCredito || 0,
        });
        Swal.fire({
          icon: 'success',
          title: `Aprovador editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadAprovadores();
        });
        setTimeout(() => {}, 3000);
      } else {
        console.log('Usuário não selecionado');
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOptionsUsuario = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/optionsUsers/${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.nome} (ID: ${item.id})`,
        }))
        .filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().includes(inputValue)
        );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      return [];
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>    
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarAprovador() : cadastrarAprovador())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setSelectedUsuario({
        value: dataLine[1],
        label: `${dataLine[2]} (ID: ${dataLine[1]})`,
      });
      setLimiteCredito(dataLine[3]);
      setStatus(dataLine[4]);
      setDescricao(dataLine[5]);
    }
  }, [dataLine]);
  return (
    <div className=''>
      <Modal {...modalConfigs} overflowBody='visible' backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Aprovador
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Usuário
                </label>
                <AsyncSelect
                  value={selectedUsuario}
                  placeholder='Selecione um usuário...'
                  onChange={(e) => {
                    setSelectedUsuario(e);
                  }}
                  loadOptions={loadOptionsUsuario}
                />
                {(!selectedUsuario) && (
                  <span className={`form-label text-danger`}>*Por favor, selecione o usuário</span>
                )}
              </Col>
              <Col>
                <label className='form-label'>Limite de Crédito:</label>
                <input
                  type='text'
                  placeholder='Limite de Crédito'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(limiteCredito || 0)}
                  className={`form-control`}
                  onChange={handleLimiteCreditoChange}
                />
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    formDataState.setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAprovadores;
