import axios from 'axios';
import {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {ProductOption} from '../../../../pages/cadastros/proposta-pedido/types/interface';
import {formatEndereco, truncateNome} from '../../../core/adapters';
import {IEmpreendimentos, ISelectOption} from '../../../domain-types';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  isClearable?: boolean;
};

const AsyncSelectTipoGarantia: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
  placeholder,
  isClearable,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_URL;

  const [selectTipoGarantiaAsync, setSelectTipoGarantiaAsync] = useState<ISelectOption | null>(
    null
  );
  const [empreendimentosDetails, setEmpreendimentosDetails] = useState<IEmpreendimentos[]>();
  const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>([]);

  const loadTipoGarantia = async (inputValue: string | number) => {
    try {
      let url = `${API_URL_SERVER}/prevenda/tipo-garantia/listar?page=${0}&status=ATIVO`;

      if (String(inputValue).length >= 3) {
        url += `&titulo=${inputValue}`;
      }

      const response = await axios.get(url);
      const content = response.data.content;

      const options = content.map((item) => ({
        value: item.id,
        label: `${item?.titulo} ${item?.descricao ? ` - ${item.descricao}` : ''}`,
        original: item,
      }));

      if (!inputValue) {
        setDefaultOptions(options);
      }

      return options;
    } catch (error) {
      console.error('Erro ao carregar opções do tipo de obra:', error);
      return defaultOptions;
    }
  };

  const loadTipoGarantiaById = async (id: string | number) => {
    try {
      const url = `${API_URL_SERVER}/prevenda/tipo-garantia/${id}`;
      const response = await axios.get(url);
      const data = response.data;

      setSelectTipoGarantiaAsync({
        value: data.id,
        label: `${data.titulo}${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções do tipo de obra:', error);
      return [];
    }
  };

  const loadOptionsTipoGarantiaDetails = async () => {
    try {
      const url = `${API_URL_SERVER}/prevenda/tipo-garantia/listar?page=0&size=10&status=ATIVO`;
      const response = await axios.get(url);
      setEmpreendimentosDetails(response.data.content);
    } catch (error) {
      console.error('Erro ao carregar detalhes dos empreendimentos:', error);
    }
  };

  useEffect(() => {
    loadOptionsTipoGarantiaDetails();
  }, []);

  useEffect(() => {
    if (inputOption) {
      loadTipoGarantiaById(inputOption.value);
    } else {
      setSelectTipoGarantiaAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder={placeholder ? placeholder : 'Pesquise o Tipo de Garantia'}
      loadOptions={loadTipoGarantia}
      defaultOptions={defaultOptions}
      value={selectTipoGarantiaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectTipoGarantiaAsync(selectedOption);
          handleOptions?.(selectedOption);
        } else {
          setSelectTipoGarantiaAsync(null);
        }
      }}
      isClearable={isClearable ? isClearable : false}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
      onMenuOpen={() => loadTipoGarantia('')}
      cacheOptions
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
    />
  );
};

export default AsyncSelectTipoGarantia;
