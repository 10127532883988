import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {
  AsyncSelectEmpresa,
  AsyncSelectFornecedor,
  DescriptionComponent,
  IPedidoCompra,
  ISelectOption,
  ISuprimentoStatus,
  IUnidadeMedida,
  PDFView,
} from '../../../shared';
import {useEffect, useMemo, useState} from 'react';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Modal, Spinner} from 'react-bootstrap';
import {
  getPedidoCompra,
  imprimirPDFPedidoCompra,
  visualizarPDFPedidoCompra,
} from '../listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {addDays, endOfMonth, format, startOfMonth, subDays} from 'date-fns';
import {formatarData, formatDateByFilter, formatDateTimeWithHours} from '../../../shared/core';
import {Row as RowBootstrap} from 'react-bootstrap';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {DatePicker, DateRangePicker} from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import * as S from './pedido-compra-styles';
import {DateRange} from 'rsuite/esm/DateRangePicker';


const PedidoComprasListagemPage: React.FC = () => {
  const navigate = useNavigate();

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);

  const [isIdPedidoCompra, setIsIdPedidoCompra] = useState<string | number>();
  const [isIdPedidoCompraFilter, setIsIdPedidoCompraFilter] = useState<number | null>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();

  const pedidoCompraColumns: Column<IPedidoCompra>[] = [
    {
      Header: 'Número do Pedido de Compra',
      accessor: 'id',
      Cell: ({value}) => (
        <div className='text-end' style={{display: 'flex', justifyContent: 'flex-end'}}>
          <S.BagdeId>
            <p>{value}</p>
          </S.BagdeId>
        </div>
      ),
    },
    {
      Header: 'Empresa De Estoque',
      accessor: 'empresaFantasia',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Empresa Solicitante',
      accessor: 'empresaFantasiaOrigem',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Empresa de Faturamento',
      accessor: 'empresaFantasiaFaturamento',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataEmissaoPedido',
      Cell: ({value}) => {
        return <div>{value ? formatarData(value.toString()) : '...'}</div>;
      },
    },

    {
      Header: 'Data de Liberação',
      accessor: 'dataLiberacao',
      Cell: ({value}) => {
        return <div>{value ? formatarData(value.toString()) : '...'}</div>;
      },
    },
    {
      Header: 'Data de Previsão Geral',
      accessor: 'dataPrevisao',
      Cell: ({value}) => {
        return <div>{value ? formatarData(value.toString()) : '...'}</div>;
      },
    },
    {
      Header: 'Data de Faturamento',
      accessor: 'dataFaturamento',
      Cell: ({value}) => {
        return <div>{value ? formatarData(value.toString()) : '...'}</div>;
      },
    },
    {
      Header: 'Data de cancelamento  ',
      accessor: 'dataCancelamento',
      Cell: ({value}) => {
        return <div>{value ? formatarData(value.toString()) : '...'}</div>;
      },
    },
    {
      Header: 'Fornecedor',
      accessor: 'fornecedorFantasia',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Volume Total',
      accessor: 'volumeTotal',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Valor do Pedido',
      accessor: 'valorPedido',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <>
          <div style={{display: 'flex'}}>
            <button
              onClick={() => handleOnEdit(row.original.id!)}
              className='btn btn-success btn-sm bi bi-pencil'
              data-toggle='tooltip'
              data-placement='top'
              title='Editar'
            />

            <div className='dropdown' style={{marginLeft: '7px'}}>
              <button className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
                <i className='bi bi-file-earmark-arrow-down'>PDF</i>
              </button>
              <ul className='dropdown-menu'>
                <li>
                  <button
                    className='dropdown-item'
                    style={{color: 'red'}}
                    type='button'
                    onClick={() =>
                      visualizarPDFPedidoCompra(
                        setModalShowPDFViewer,
                        row.original.id,
                        setIsLoading,
                        setIsPdfUrl,
                        setIsIdPedidoCompra
                      )
                    }
                  >
                    VISUALIZAR PDF
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [isSelectAsyncFornecedor, setIsSelectAsyncFornecedor] = useState<ISelectOption | null>(
    null
  );

  const [isSelectAsyncEmpresa, setIsSelectAsyncEmpresa] = useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaOrigem, setIsSelectAsyncEmpresaOrigem] =
    useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaFaturamento, setIsSelectAsyncEmpresaFaturamento] =
    useState<ISelectOption | null>(null);
  const [isStatusSuprimento, setIsStatusSuprimento] = useState<ISelectOption | null>(null);
  const [isDataEmissao, setIsDataEmissao] = useState<DateRange | null>([new Date(), new Date()]);
  const [isDataPrevisao, setIsDataPrevisao] = useState<DateRange | null>([new Date(), new Date()]);
  const [isDataFaturamento, setIsDataFaturamento] = useState<DateRange | null>([
    new Date(),
    new Date(),
  ]);

  const [isPedidoCompraData, setIsPedidoCompraData] = useState<IPedidoCompra[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isFitered, setIsFiltered] = useState<boolean>(false);
  const [isComprador, setIsComprador] = useState<ISelectOption | null>(null);
  const [isCompradorFiltered, setIsCompradorFiltered] = useState<ISelectOption[]>([]);
  const [defaultCompradorOptions, setDefaultCompradorOptions] = useState<ISelectOption[]>([]);
  const [isDataPrevisaoGeral, setIsDataPrevisaoGeral] = useState<Date | null>(null);

  const data = useMemo(() => isPedidoCompraData, [isPedidoCompraData]);
  const columns = useMemo(() => pedidoCompraColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const handleOnEdit = (id: string | number) => {
    navigate(`/pedido-compras/${id}`);
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleDateEmissao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataEmissao(null);
    } else {
      setIsDataEmissao([value[0], value[1]]);
    }
  };

  const handleDatePrevisao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataPrevisao(null);
    } else {
      setIsDataPrevisao([value[0], value[1]]);
    }
  };

  const handleDateFaturamento = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataFaturamento(null);
    } else {
      setIsDataFaturamento([value[0], value[1]]);
    }
  };

  const loadPedidosCompras = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await getPedidoCompra({
        page: page,
      });

      const {content, totalPages} = response.data;

      setIsPedidoCompraData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);

      setIsSelectAsyncEmpresa(null);
      setIsSelectAsyncEmpresaOrigem(null);
      setIsSelectAsyncEmpresaFaturamento(null);
      setIsSelectAsyncFornecedor(null);
      setIsStatusSuprimento(null);
      setIsDataEmissao(null);
      setIsDataFaturamento(null);
      setIsIdPedidoCompraFilter(null);
      setIsComprador(null)

      setIsDataPrevisao(null)

      setIsLoadingSearch(false);

      setIsFiltered(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (isFitered) {
      onSubmit(isPage);
    }
    loadPedidosCompras(isPage);
  }, [isPage]);

  const loadSuprimentoStatus = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ISuprimentoStatus) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setIsSelectAsyncEmpresa(option);
  };

  const handleAsyncSelectEmpresaOrigem = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaOrigem(option);
  };

  const handleAsyncEmpresaFaturamento = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaFaturamento(option);
  };

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsSelectAsyncFornecedor(option);
  };

  const onSubmit = async (page = 0) => {
    setIsLoadingSearch(true);
    const formatDateTime = (date: Date): string => date.toISOString().replace('Z', ''); // Remove o Z do final
    try {
      const response = await getPedidoCompra(
        {
          page: page,
        },
        isSelectAsyncFornecedor?.value,
        isSelectAsyncEmpresa?.value,
        isSelectAsyncEmpresaOrigem?.value,
        isSelectAsyncEmpresaFaturamento?.value,
        isStatusSuprimento?.value,
        isDataEmissao ? formatDateByFilter(isDataEmissao[0]) : null, // Formata para o formato esperado
        isDataEmissao ? formatDateByFilter(isDataEmissao[1]) : null, // Formata para o formato esperado
        isDataPrevisao ? formatDateByFilter(isDataPrevisao[0]) : null, // Formata para o formato esperado
        isDataPrevisao ? formatDateByFilter(isDataPrevisao[1]) : null, // Formata para o formato esperado
        isDataFaturamento ? formatDateTime(isDataFaturamento[0]) : null, // Formata para o formato esperado
        isDataFaturamento ? formatDateTime(isDataFaturamento[1]) : null, // Formata para o formato esperado
        isComprador?.value ? isComprador.value : null,
        isIdPedidoCompraFilter ? isIdPedidoCompraFilter : null,


      );

      console.log(response)

      const {content, totalPages} = response.data;

      setIsPedidoCompraData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsPedidoCompraData(content);

      setIsLoadingSearch(false);

      setIsFiltered(true);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingSearch(false);
    }
  };

  const loadComprador = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/listar?size=${100000}`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item) => ({
        value: item.id,
        label: `${item.nomeUsuario} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsCompradorFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };


  const filteredOptionsComprador = (inputValue: string) => {
    return isCompradorFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptionsComprador = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsComprador(inputValue));
  };

  useEffect(() => {
    const fetchCompradorOptions = async () => {
      const options = (await loadComprador()) || [];
      setDefaultCompradorOptions(options);
    };

    fetchCompradorOptions();
  }, []);


  useEffect(() => {
    console.log(isIdPedidoCompraFilter);
  }, [isIdPedidoCompraFilter]);

  const clearFilter = () => {
    loadPedidosCompras();
  };

  return (
    <>
      <DescriptionComponent
        description={'Listagem de Pedido de Compras'}
        withLink={true}
        buttonTitle='Cadastro de Pedido de Compras'
        redirectTo='/pedido-compras'
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-6'>
              <label htmlFor='' className='form-label'>
                Empresa de Estoque:
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
                inputOption={isSelectAsyncEmpresa}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncEmpresa(null);
                  }
                }}
              />
            </Col>
            <Col className='col-sm-6'>
              <label htmlFor='' className='form-label'>
                Empresa Solicitante:
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncSelectEmpresaOrigem(option!)}
                inputOption={isSelectAsyncEmpresaOrigem}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncEmpresaOrigem(null);
                  }
                }}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-sm-6'>
              <label htmlFor='' className='form-label'>
                Empresa de Faturamento:
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncEmpresaFaturamento(option!)}
                inputOption={isSelectAsyncEmpresaFaturamento}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncEmpresaFaturamento(null);
                  }
                }}
              />
            </Col>
            <Col className='col-sm-6'>
              <label htmlFor='' className='form-label'>
                Fornecedor:
              </label>
              <AsyncSelectFornecedor
                handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
                inputOption={isSelectAsyncFornecedor}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncFornecedor(null);
                  }
                }}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col md='6' className='col-12'>
              <label htmlFor='' className='form-label'>
                Selecione o Status:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione o Status'
                loadOptions={loadSuprimentoStatus}
                value={isStatusSuprimento}
                onChange={(selectedOption: ISelectOption | null) => {
                  // Verifica se selectedOption não é null antes de definir o estado
                  if (selectedOption) {
                    setIsStatusSuprimento(selectedOption);
                  } else {
                    setIsStatusSuprimento(null); // Define como null caso a opção seja anulada
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>
            <Col md='6' className='col-12'>
              <label className='form-label'>Id do Pedido de Compra:</label>
              <input
                type='number'
                className='form-control'
                value={isIdPedidoCompraFilter ?? ''}
                placeholder='Pesquise pelo Id do Pedido de Compra'
                onChange={(e) =>
                  setIsIdPedidoCompraFilter(e.target.value ? Number(e.target.value) : null)
                }
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col md='6' className='col-12'>
              <label htmlFor='' className='form-label'>
                Data de Emissão de Pedido:
              </label>

              {/* <DatePicker
                format='dd-MM-yyyy'
                placeholder='Data de emissão'
                className=' w-100'
                onChange={(date) => 
                  {
                    console.log(date);
                    setIsDataEmissao(date)}}
                value={isDataEmissao}
              /> */}
              <S.DateRangePickerComponent
                size='lg'
                appearance='default'
                onChange={handleDateEmissao}
                value={isDataEmissao}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de Emissão'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col md='6' className='col-12'>
              <label htmlFor='' className='form-label'>
                Data de Faturamento:
              </label>

              {/* <DatePicker
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='Data de Faturamento'
                onChange={(date) => setIsDataFaturamento(date)}
                value={isDataFaturamento}
              /> */}

              <S.DateRangePickerComponent
                size='lg'
                appearance='default'
                onChange={handleDateFaturamento}
                value={isDataFaturamento}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de Faturamento'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
          <Col md='6' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Comprador:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Comprador'
              loadOptions={loadFilteredOptionsComprador}
              value={isComprador}
              onChange={(selectedOption: ISelectOption | null) => {
                if (selectedOption) {
                  setIsComprador(selectedOption);
                } else {
                  setIsComprador(null);
                }
              }}
              cacheOptions
              defaultOptions={defaultCompradorOptions}
            />
          </Col>
          <Col md='6' className='col-12'>
              <label htmlFor='' className='form-label'>
                Data de Previsão Geral:
              </label>

              <S.DateRangePickerComponent
                size='lg'
                appearance='default'
                onChange={handleDatePrevisao}
                value={isDataPrevisao}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de Previsão Geral'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
          </RowBootstrap>
          <div className='col-sm-3' style={{marginTop: '26px'}}></div>
          <div className='col-12 mt-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFilter()}
                className='btn btn-success form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                type='button'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-success form-control'
                onClick={() => onSubmit()}
              >
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      {isLoadingSearch ? (
        <Skeleton count={20} height={25} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IPedidoCompra>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IPedidoCompra>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      )}
      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFPedidoCompra(isIdPedidoCompra, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default PedidoComprasListagemPage;
