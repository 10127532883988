import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import { DatePicker } from 'rsuite'
import '../styles/Parcelas.scss';
import { ptBR } from 'date-fns/locale';
const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalParcelas = ({isOpen, onHide, dataLine, isEdit, loadParcelas}) => {
  const [valor, setValor] = useState<number | null>(null);
  const [isDataVencimento, setIsDataVencimento] = useState<Date | null>(null)
  const [isDataEmissao, setIsDataEmissao] = useState<Date | null>(null)
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [idContasPagar, setIdContasPagar] = useState<string | null>(null);
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = () => {
    onHide();
    setValor(null)
    setIsDataEmissao(null)
    setIsDataVencimento(null)
    setDescricao(null);
    setStatus('ATIVO');
    onClear();
  };

  const onClear = () => {
    setValor(null)
    setIsDataEmissao(null)
    setIsDataVencimento(null)
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarParcelas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar-parcelas`;
      if (isDataVencimento && isDataEmissao && valor) {
        const response = await axios.post(url, {
          idContaPagar: id,
          valor: valor,
          dataVencimento: isDataVencimento,
          dataEmissao: isDataEmissao,
          descricao: descricao ? descricao : '',
          status: status,
        });

        Swal.fire({
          icon: 'success',
          title: `Parcela cadastrada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadParcelas();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Preencha os campos obrigatórios',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarParcelas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar-parcelas/${dataLine[0]}`;
        const response = await axios.put(url, {
          idContaPagar: id,
          valor: valor,
          dataVencimento: isDataVencimento,
          dataEmissao: isDataEmissao,
          descricao: descricao ? descricao : '',
          status: status,
        });
        Swal.fire({
          icon: 'success',
          title: `Parcela editada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadParcelas();
        });
        setTimeout(() => {}, 3000);
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateEmissao = (
    value: Date | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataEmissao(null);
    } else {
      setIsDataEmissao(value);
    }
  };

  const handleDateVencimento = (
    value: Date | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataVencimento(null);
    } else {
      setIsDataVencimento(value);
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarParcelas() : cadastrarParcelas())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setIdContasPagar(dataLine[1])
      console.log(dataLine[1]);
      setIsDataVencimento(dataLine[2] ? new Date(dataLine[2]) : null);
      setIsDataEmissao(dataLine[3] ? new Date(dataLine[3]) : null);
      setStatus(dataLine[4]);
      setValor(dataLine[5]);
      setDescricao(dataLine[6]);
    }
  }, [dataLine]);

  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Parcelas
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id contas a pagar
                </label>
                <input
                  value={id}
                  disabled
                  className='form-control'
                  type='number'
                  placeholder='Id Contas a Pagar'
                />
              </Col>
              <Col>
                <label className='form-label'>Data de Emissão:</label>
                <DatePicker
                  size='lg'
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Emissão'
                  onChange={handleDateEmissao}
                  value={isDataEmissao}
                />
                {!isDataEmissao && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a data de emissão
                  </span>
                )}
              </Col>
              <Col>
                <label className='form-label'>Data Vencimento:</label>
                <DatePicker
                  size='lg'
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Vencimento'
                  onChange={handleDateVencimento}
                  value={isDataVencimento}
                />
                {!isDataVencimento && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a data de vencimento
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label className='form-label'>Valor:</label>
                <input
                  type='text'
                  placeholder='Valor...'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valor || 0)}
                  className={`form-control`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue !== null) {
                      inputValue = inputValue.replace(/^0+/, '');
                      inputValue = inputValue || '0';

                      const numericValue = parseInt(inputValue, 10);
                      setValor(numericValue / 100);
                    } else {
                      setValor(null);
                    }
                  }}
                />
                {!valor && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o valor
                  </span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    // handleChangeStatus(e.target.value)
                    setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  rows={3}
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                    setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalParcelas;
