import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { sendUpdateEmpresa } from '../cadastro_requests/cadastro_requests';
import useBuscaCnpj from '../../useUtils/useBuscaCnpj';
import useBuscaCep from '../../useUtils/useBuscaCep';
import PageTitulo from '../../components/Pagetitulo';
import { set } from 'date-fns';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const editarValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(4, 'O nome da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o nome da empresa'),
  razaosocial: Yup.string()
    .min(4, 'A razão social da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe a razão social da empresa'),
  fantasia: Yup.string()
    .min(4, 'O nome fantasia da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o nome fantasia da empresa'),
  cnpj: Yup.string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido')
    .required('Por favor, informe o CNPJ da empresa'),
  codigo: Yup.number()
    .min(1, 'O código da empresa deve conter pelo menos 1 caracteres')
    .required('O código da empresa é obrigatório'),
  endereco: Yup.string()
    .min(4, 'O endereço da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe o endereço da empresa'),
  cidade: Yup.string()
    .min(2, 'A cidade da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe a cidade da empresa'),
  estado: Yup.string()
    .min(2, 'O estado da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe o estado da empresa'),
  pais: Yup.string()
    .min(2, 'O país da empresa deve conter pelo menos 2 caracteres')
    .required('Por favor, informe o país da empresa'),
  cep: Yup.string()
    .min(8, 'O cep deve conter 8 digítos')
    .required('Por favor, informe o CEP da empresa'),
  telefone: Yup.string()
    .matches(/^\(\d{2}\)\d{4,5}-\d{4}$/, 'Telefone inválido')
    .required('Por favor, informe o telefone da empresa'),
  email: Yup.string()
    .email('Endereço de email inválido')
    .min(1, 'O campo email não pode ser vazio')
    .required('Por favor, informe um email'),
});

const initialValues = {
  id: 0,
  nome: '',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  codigo: '',
  endereco: '',
  cidade: '',
  estado: '',
  pais: '',
  cep: '',
  telefone: '',
  email: '',
  status: 1,
  website: '',
  isProjetosGerador: 0,
};

export function EditarEmpresas() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isProjetosGerador, setIsProjetosGerador] = useState(false);
  

  const formik = useFormik({
    initialValues,
    validationSchema: editarValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await sendUpdateEmpresa(
          values.id,
          values.nome,
          values.razaosocial,
          values.fantasia,
          values.cnpj,
          values.codigo,
          values.endereco,
          values.cidade,
          values.estado,
          values.pais,
          values.cep,
          values.telefone,
          values.email,
          values.status,
          values.website,
          isProjetosGerador ? 1 : 0
        );
        if (response.status === 200) {
          setSubmitting(false);
          setLoading(false);
          await Swal.fire({
            icon: 'success',
            title: 'Empresa atualizada com sucesso!',
            confirmButtonText: 'Voltar a listagem',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/listagem-empresas');
            }
          });
        } else {
          // Exibir mensagem de erro se a resposta não for bem-sucedida
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao atualizar a empresa, verifique as informações preenchidas e tente novamente',
            text: 'Something went wrong!',
            confirmButtonText: 'Ok',
          });
          setStatus('Ocorreu um erro ao salvar. Por favor, tente novamente.');
        }
      } catch (error: any) {
        if (error.response) {
          const { data } = error.response;
          Swal.fire({
            icon: 'error',
            title: data.message,
            text: 'Por favor, verifique as informações',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar o usuário',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });

          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações da empresa corretamente');
      }
    },
  });

  useEffect(() => {
    setLoading(true);
    fetchEmpresa();
  }, []);

  useBuscaCnpj(formik.values.cnpj, formik);
  useBuscaCep(formik.values.cep, formik);

  const fetchEmpresa = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas/${id}`
      );

      if (response.status === 200) {
        const empresa = response.data;
        formik.setValues({
          ...formik.values,
          id: empresa.id,
          nome: empresa.nome,
          razaosocial: empresa.razaosocial,
          fantasia: empresa.fantasia,
          cnpj: empresa.cnpj,
          codigo: empresa.codigo,
          endereco: empresa.endereco,
          cidade: empresa.cidade,
          estado: empresa.estado,
          pais: empresa.pais,
          cep: empresa.cep,
          telefone: empresa.telefone,
          email: empresa.email,
          status: empresa.status,
          website: empresa.website,
        });
        setIsProjetosGerador(empresa.isProjetosGerador === 'SIM');
        console.log(empresa.isProjetosGerador === 'SIM', empresa.isProjetosGerador)
        setLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao carregar empresa',
          text: 'Ocorreu um erro ao carregar a empresa. Por favor, tente novamente mais tarde.',
          confirmButtonText: 'Ok',
        });
        throw new Error('Erro ao buscar empresa');
      }
    } catch (error) {
      console.error('Erro ao buscar informações de empresa:', error);
    }
  };

  const handleStatusEmpresaChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  return (
    <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-light-dark'>
        <PageTitulo id={id} tipoFormulario='Empresa' />
        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Informações Gerais</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Nome *</label>
            <input
              type='text'
              placeholder='Insira o nome da empresa'
              {...formik.getFieldProps('nome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-3'>
            <label className='form-label'>CNPJ *</label>
            <InputMask
              mask='99.999.999/9999-99' // Aplicando a máscara de CNPJ
              placeholder='Insira o CNPJ da empresa'
              {...formik.getFieldProps('cnpj')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
              )}
            />
            {formik.touched.cnpj && formik.errors.cnpj && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cnpj}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Status</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleStatusEmpresaChange}
              value={formik.values.status}
            >
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Razão Social</label>
            <input
              type='text'
              placeholder='Insira a razão social da empresa'
              {...formik.getFieldProps('razaosocial')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.razaosocial && formik.errors.razaosocial },
                {
                  'is-valid': formik.touched.razaosocial && !formik.errors.razaosocial,
                }
              )}
            />
            {formik.touched.razaosocial && formik.errors.razaosocial && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.razaosocial}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Fantasia</label>
            <input
              type='text'
              placeholder='Insira o nome fantasia da empresa'
              {...formik.getFieldProps('fantasia')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.fantasia && formik.errors.fantasia },
                {
                  'is-valid': formik.touched.fantasia && !formik.errors.fantasia,
                }
              )}
            />
            {formik.touched.fantasia && formik.errors.fantasia && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fantasia}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'>Codigo</label>
            <input
              type='text'
              placeholder='Insira o codigo da empresa'
              {...formik.getFieldProps('codigo')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.codigo && formik.errors.codigo },
                {
                  'is-valid': formik.touched.codigo && !formik.errors.codigo,
                }
              )}
              onChange={(event) => {
                formik.setFieldValue('codigo', event.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
              }}
            />
            {formik.touched.codigo && formik.errors.codigo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.codigo}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Telefone *</label>
            <InputMask
              mask='(99)9999-9999'
              placeholder='Insira o telefone da empresa'
              {...formik.getFieldProps('telefone')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.telefone && formik.errors.telefone },
                {
                  'is-valid': formik.touched.telefone && !formik.errors.telefone,
                }
              )}
            />
            {formik.touched.telefone && formik.errors.telefone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.telefone}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Email *</label>
            <input
              type='text'
              placeholder='Insira o email da empresa'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Website</label>
            <input
              type='text'
              placeholder='Insira o website da empresa'
              {...formik.getFieldProps('website')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.website && formik.errors.website },
                {
                  'is-valid': formik.touched.website && !formik.errors.website,
                }
              )}
            />
            {formik.touched.website && formik.errors.website && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.website}</span>
                </div>
              </div>
            )}
          </div>
           <div className='col-6 mt-5'>
            <label htmlFor='' className='form-label'>
            Participa da geração de projetos
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'

                checked={isProjetosGerador}
                onChange={() => setIsProjetosGerador((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isProjetosGerador === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </div>
        </div>

        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Localização</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'> CEP *</label>
            <InputMask
              mask='99.999-999'
              placeholder='Insira o CEP da empresa'
              {...formik.getFieldProps('cep')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cep && formik.errors.cep },
                { 'is-valid': formik.touched.cep && !formik.errors.cep }
              )}
            />
            {formik.touched.cep && formik.errors.cep && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cep}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'>
            <label className='form-label'> Endereço </label>
            <input
              type='text'
              placeholder='Endereço da empresa'
              {...formik.getFieldProps('endereco')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.endereco && formik.errors.endereco },
                {
                  'is-valid': formik.touched.endereco && !formik.errors.endereco,
                }
              )}
            />
            {formik.touched.endereco && formik.errors.endereco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.endereco}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'> Cidade </label>
            <input
              type='text'
              placeholder='Cidade da empresa'
              {...formik.getFieldProps('cidade')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                {
                  'is-valid': formik.touched.cidade && !formik.errors.cidade,
                }
              )}
            />
            {formik.touched.cidade && formik.errors.cidade && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cidade}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'></div>
          <div className='col-sm-3'>
            <label className='form-label'> Estado </label>
            <input
              type='text'
              placeholder='Estado da empresa'
              {...formik.getFieldProps('estado')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.estado && formik.errors.estado },
                {
                  'is-valid': formik.touched.estado && !formik.errors.estado,
                }
              )}
            />
            {formik.touched.estado && formik.errors.estado && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.estado}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-3'>
            <label className='form-label'> País </label>
            <input
              type='text'
              placeholder='País da empresa'
              {...formik.getFieldProps('pais')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.pais && formik.errors.pais },
                {
                  'is-valid': formik.touched.pais && !formik.errors.pais,
                }
              )}
            />
            {formik.touched.pais && formik.errors.pais && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.pais}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className='d-flex gap-8'>
            <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
              Atualizar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              className='btn-cadastro btn-danger'
              id='back'
              onClick={() => navigate('/listagem-empresas')}
            >
              Cancelar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
