import {useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import {DateRangePicker} from 'rsuite';
import {addDays, endOfMonth, startOfMonth, subDays} from 'date-fns';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {ColumnInstance, useTable, Row, Column} from 'react-table';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {
  buildQueryParams,
  DescriptionComponent,
  formatarData,
  formatDateByFilter,
  HttpClient,
  IComprador,
  ISelectOption,
  IStatusPrevendaGarantia,
  LoadMore,
  SearchComponent,
  showAlert,
  SysledTable,
  useModal,
} from '../../../shared';
import StatusGarantiaModal from './components/status-garantia-modal';

const StatusGarantiaPage: React.FC = () => {
  const http = new HttpClient();

  const statusGarantiaColumns: Column<IStatusPrevendaGarantia>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value ? value : value}</div>,
    },
    {
      Header: 'Título',
      accessor: 'titulo',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original.id!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const [isStatusGarantiaModal, openStatusGarantiaModal, closeStatusGarantiaModal] = useModal();
  const [isStatusGarantiaData, setIsStatusGarantiaData] = useState<IStatusPrevendaGarantia[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isAsyncSelectPedido, setIsAsyncSelectPedido] = useState<ISelectOption | null>(null);
  const [isAsyncSelectProduto, setIsAsyncSelectProduto] = useState<ISelectOption | null>(null);
  const [isAsyncSelectComprador, setIsAsyncSelectComprador] = useState<ISelectOption | null>(null);
  const [isDataPendencia, setIsDataPendencia] = useState<DateRange | null>(null);
  const [isAtivo, setIsAtivo] = useState<string>('ATIVO');
  const [isIdStatusGarantiaFilter, setIsIdStatusGarantiaFilter] = useState<number | string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [isTitulo, setIsTitulo] = useState<string>('');
  const [isDescricao, setIsDescricao] = useState<string>('');

  const [isEdit, setIsEdit] = useState<boolean>();
  const [isIdPendencia, setIsIdPendencia] = useState<number | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const data = useMemo(() => isStatusGarantiaData, [isStatusGarantiaData]);
  const columns = useMemo(() => statusGarantiaColumns, []);
  const {} = useTable({
    columns,
    data,
  });

  const handleRequest = () => {
    clearFilters();
  };

  const loadMore = () => {
    if (isHasMore) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    openStatusGarantiaModal();

    setIsEdit(true);
    setIsIdPendencia(Number(id));
  };

  const handleOnAdd = () => {
    openStatusGarantiaModal();

    setIsEdit(false);

    setIsIdPendencia(null);
  };

  const handleFilters = () => {
    setIsPage(0);
    setIsFiltered(true);
    getPendenciasDataFilter();
  };

  const clearFilters = () => {
    getStatusGarantiaClear();
    setIsTitulo('');
    setIsAtivo('ATIVO');
    setIsDescricao('');

    setIsStatusGarantiaData([]);
    setIsFiltered(false)

    setIsPage(0);
  };

  const getPendenciasDataFilter = async () => {
    setIsLoadingSearch(true);
    setIsLoadingData(true);

    try {
      const params = {
        titulo: isTitulo,
        descricao: isDescricao,
        status: isAtivo,
        page: isPage
      };

      const finalUrl = `prevenda/status-garantia/listar${buildQueryParams(params)}`;

      const response = await http.request({
        method: 'GET',
        url: finalUrl,
      });

      const {content, totalPages} = response.data as any;

      setIsStatusGarantiaData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    }
  };

  const getStatusGarantiaClear = async (page = 0) => {
    setIsLoadingData(true);
    try {
      const params = {
        page: isPage,
        status: 'ATIVO',
      };

      const finalUrl = `prevenda/status-garantia/listar${buildQueryParams(params)}`;

      const response = await http.request({
        method: 'GET',
        url: finalUrl,
      });

      const {content, totalPages} = response.data as any;
      setIsStatusGarantiaData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingData(false);
    }
  };

  useEffect(() => {
      getPendenciasDataFilter();
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Status de Garantia'
        buttonTitle='+ Novo Status de Garantia'
        handleAction={() => {
          handleOnAdd();
        }}
        isButtonAction
      />

      <SearchComponent
        clearFilters={() => clearFilters()}
        handleFilters={() => handleFilters()}
        isLoadingSearch={isLoadingSearch}
      >
        <form className='form-control-solid row mb-5'>
          <RowBootstrap className='mt-4'>
            <Col md='6' className='col-12'>
              <label className='form-label'>Título:</label>
              <input
                type='text'
                placeholder='Digite o Título do Status'
                className='form-control'
                value={isTitulo}
                onChange={(e) => {
                  const value = e.target.value;
                  setIsTitulo(value); // Mantém string vazia se não houver valor
                }}
              />
            </Col>
            <Col md='6' className='col-12'>
              <label className='form-label'>Status:</label>
              <select
                className={`form-control`}
                value={isAtivo ?? ''}
                onChange={(e) => setIsAtivo(e.target.value)}
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col className='col-12'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição do status de garantia'
                className='form-control'
                value={isDescricao}
                onChange={(e) => {
                  const value = e.target.value;
                  setIsDescricao(value);
                }}
              />
            </Col>
          </RowBootstrap>
        </form>
      </SearchComponent>

      {isLoadingData ? (
        <Skeleton count={20} height={30} />
      ) : (
        <>
          <SysledTable
            columns={statusGarantiaColumns}
            data={isStatusGarantiaData}
            message='SEM DADOS'
          />

          <LoadMore
            isHasMore={isHasMore}
            isLoadingSearch={isLoadingSearch}
            loadMore={() => loadMore()}
          />
        </>
      )}

      <StatusGarantiaModal
        isOpen={isStatusGarantiaModal}
        onHide={closeStatusGarantiaModal}
        isEdit={isEdit}
        idItem={isIdPendencia}
        handleRequest={handleRequest}
      />
    </>
  );
};

export default StatusGarantiaPage;
