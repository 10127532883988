import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {
  AsyncSelectCliente,
  AsyncSelectProduct,
  AsyncSelectProductByCodFornecedor,
  AsyncSelectProductByIdSysled,
  DescriptionComponent,
  FooterForm,
  formatarData,
  formatDateTimeWithHours,
  IFrete,
  IPedidoCompraItem,
  ISelectOption,
  ISuprimentoStatus,
  useModal,
} from '../../../../../shared';
import {useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {
  getItemsByPedidoCompraId,
  getPedidoItemCompraById,
} from '../../../../listagem/listagem_requests/listagem_requests';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {
  editPedidoCompraItem,
  sendPedidoCompraItem,
} from '../../../cadastro_requests/cadastro_requests';
import {Col, Form, OverlayTrigger, Row as RowForm, Tooltip} from 'react-bootstrap';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import FreteDetails from '../frete-details/frete-details';
import NotaFiscalDetails from '../nota-fiscal-details/nota-fiscal-details';
import NotaFiscalModal from '../nota-fiscal-details/components/nota-fiscal-modal';
import FreteModal from '../frete-details/components/frete-modal';
import ItemDetailsModal from './components/modal-details/modal-details';
import {DatePicker} from 'rsuite';
import Skeleton from 'react-loading-skeleton';

type IProps = {
  handleTapAction?: () => void;
};

const PedidoCompraDetails = ({handleTapAction}: IProps) => {
  const {id} = useParams();

  const renderCellValue = (value: any) => (value ? value : '...');

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedItems, setSelectedItems] = useState<number[]>([]); // Vetor para armazenar IDs dos itens selecionados.

  const handleCheckboxChange = (value: number) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(value)) {
        // Remove o valor se ele já estiver selecionado.
        return prevSelected.filter((item) => item !== value);
      } else {
        // Adiciona o valor se ele ainda não estiver selecionado.
        return [...prevSelected, value];
      }
    });
  };

  const pedidoCompraItemsColumns: Column<IPedidoCompraItem>[] = [
    {
      Header: 'Detalhes',
      accessor: 'observacao',
      Cell: ({row}) => (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-edit-${row.original.id}`}>
              Observe os detalhes do item como nota fiscal e frete
            </Tooltip>
          }
        >
          <button
            className='btn btn-primary btn-sm'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleModalDetails(row.original.id!)}
          >
            <i style={{padding: 0}} className='bi bi-eye'></i>
          </button>
        </OverlayTrigger>
      ),
    },
    {
      Header: 'Vinculação',
      accessor: 'id',
      Cell: ({value}) => (
        <div className='d-flex flex-column align-items-center form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            id={`checkbox-${value}`}
            onChange={() => handleCheckboxChange(Number(value))}
            checked={selectedItems.includes(Number(value))} // Verifica se o item está na lista de selecionados
          />
        </div>
      ),
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      Cell: ({row}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {row.original.idProduto
            ? `${row.original.sku ?? '...'} - ${
                row.original.produto ? row.original.produto : '...'
              }`
            : '...'}
        </div>
      ),
    },
    {
      Header: 'Quantidade Solicitada',
      accessor: 'quantidade',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Preço de Compra Negociado',
      accessor: 'precoCompra',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Valor Total do Item',
      accessor: 'valorTotalItem',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },

    {
      Header: 'Ressuprimento',
      accessor: 'isRessuprimento',
      Cell: ({value}) => <div className='text-end'>{value == 1 ? 'SIM' : 'NÃO'}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Numero da Nota Fiscal',
      accessor: 'numeroNotaFiscal',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Quantidade Recebida',
      accessor: 'quantidadeRecebida',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Usuário Criação',
      accessor: 'nomeUsuarioCriacao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de Criação',
      accessor: 'dataCriacao',
      Cell: ({value}) => (
        <div className='text-end'>{renderCellValue(formatDateTimeWithHours(value))}</div>
      ),
    },
    {
      Header: 'Usuário de Alteração',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de alteração',
      accessor: 'dataAlteracao',
      Cell: ({value}) => (
        <div className='text-end'>
          {renderCellValue(value ? formatDateTimeWithHours(value) : '...')}
        </div>
      ),
    },
    {
      Header: 'Cliente',
      accessor: 'cliente',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor do Desconto',
      accessor: 'desconto',
      Cell: ({value}) => <div className='text-start'>{`${value ? `${value}%` : '...'}`}</div>,
    },
    {
      Header: 'Valor do ICMS',
      accessor: 'itemValorICMS',
      Cell: ({value}) => <div className='text-start'>{`${value ? `${value}%` : '...'}`}</div>,
    },
    {
      Header: 'Valor do IPI',
      accessor: 'itemValorIPI',
      Cell: ({value}) => <div className='text-start'>{`${value ? `${value}%` : '...'}`}</div>,
    },

    {
      Header: 'Data de Faturamento',
      accessor: 'dataFaturamento',
      Cell: ({value}) => (
        <div className='text-end'>
          {value ? renderCellValue(formatarData(value.toString())) : '...'}
        </div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const [isPedidoCompraItemsData, setIsPedidoCompraItemsData] = useState<IPedidoCompraItem[]>([]);
  const [isLoadingItemsPedidoCompra, setIsLoadingItemsPedidoCompra] = useState<boolean>(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(false);
  const [isSelectAsyncProduct, setIsSelectAsyncProduct] = useState<ISelectOption | null>(null);
  const [isSelectAsyncClient, setIsSelectAsyncClient] = useState<ISelectOption | null>(null);
  const [isActionPedidoCompraItem, setIsActionPedidoCompraItem] = useState<'add' | 'edit' | null>(
    null
  );
  const [isStatusSuprimento, setIsStatusSuprimento] = useState<ISelectOption | null>(null);

  const [isIdPedidoItem, setIsIdPedidoItem] = useState<number | string>();

  const [isIdItem, setIsIdItem] = useState<number>();

  const data = useMemo(() => isPedidoCompraItemsData, [isPedidoCompraItemsData]);
  const columns = useMemo(() => pedidoCompraItemsColumns, [selectedItems]);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: IPedidoCompraItem = {
    id: undefined,
    idPedidoCompra: undefined,
    idFrete: undefined,
    idProduto: undefined,
    precoVenda: 0,
    precoCompra: 0,
    precoUltimo: 0,
    precoMedio: 0,
    precoMenor: 0,
    item: undefined,
    quantidade: undefined,
    quantidadeRecebida: undefined,
    preco: 0,
    itemValorDesconto: 0,
    itemValorICMS: 0,
    itemValorIPI: 0,
    observacao: '',
    idItemStatus: undefined,
    status: 'ATIVO',
    desconto: undefined,
    idCliente: undefined,
    isRessuprimento: undefined,
    idNotaFiscal: undefined,
  };

  const formik = useFormik({
    initialValues,
    // Validação condicional de schemas, add para a ação de adição, e edit para a ação de add
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingItemsPedidoCompra(true);
      if (isActionPedidoCompraItem === 'add') {
        if (!isStatusSuprimento || !isSelectAsyncProduct) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          let title;
          switch (true) {
            case !isSelectAsyncProduct:
              title = 'A inserção do produto é Obrigatóra';
              break;
            case !isStatusSuprimento:
              title = 'A inserção do Status do Suprimento é Obrigatória';
              break;

            default:
              title = 'Erro desconhecido';
          }

          Toast.fire({
            icon: 'info',
            title,
          });

          setSubmitting(false);
          setIsLoadingItemsPedidoCompra(false);
          return;
        }
        try {
          await sendPedidoCompraItem({
            ...values,
            idPedidoCompra: id,
            isRessuprimento: values.isRessuprimento == 1 ? true : false,
            idProduto:
              Number(isSelectAsyncProduct?.value) !== 0 ? isSelectAsyncProduct?.value : undefined,
            idCliente: Number(isSelectAsyncClient?.value),
            idItemStatus: isStatusSuprimento.value,
            dataFaturamento: isDataFaturamentoItem,
            itemValorICMS: parseFloat(String(values.itemValorICMS).replace(',', '.')) || 0,
            itemValorIPI: parseFloat(String(values.itemValorIPI).replace(',', '.')) || 0,
            desconto: parseFloat(String(values.desconto).replace(',', '.')) || 0,
          });

          Swal.fire({
            icon: 'success',
            title: 'Item cadastrado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: false,
          });

          if (handleTapAction) {
            handleTapAction();
          }

          loadItemsByPedidoCompraId();
          setIsActionPedidoCompraItem(null);

          setSubmitting(false);
          setIsLoadingItemsPedidoCompra(false);

          setIsSelectAsyncProduct(null);
        } catch (errors: any) {
          const {data, status} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoadingItemsPedidoCompra(false);
        }
      } else {
        try {
          if (!isStatusSuprimento || !isSelectAsyncProduct) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncProduct:
                title = 'A inserção do produto é Obrigatóra';
                break;
              case !isStatusSuprimento:
                title = 'A inserção do Status do Suprimento é Obrigatória';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoadingItemsPedidoCompra(false);
            return;
          }

          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar o item do pedido de compra?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await editPedidoCompraItem(isIdPedidoItem!, {
              ...values,
              idPedidoCompra: id,
              isRessuprimento: values.isRessuprimento == 1 ? true : false,
              idProduto:
                Number(isSelectAsyncProduct?.value) !== 0 ? isSelectAsyncProduct?.value : undefined,
              idCliente: Number(isSelectAsyncClient?.value),
              idItemStatus: isStatusSuprimento?.value,
              dataFaturamento: isDataFaturamentoItem,
              itemValorICMS: parseFloat(String(values.itemValorICMS).replace(',', '.')) || 0,
              itemValorIPI: parseFloat(String(values.itemValorIPI).replace(',', '.')) || 0,
              desconto: parseFloat(String(values.desconto).replace(',', '.')) || 0,
            });

            Swal.fire({
              icon: 'success',
              title: 'Item do pedido editado com sucesso!',
              showCancelButton: false,
              timer: 3000,
              timerProgressBar: false,
            });

            if (handleTapAction) {
              handleTapAction();
            }

            loadItemsByPedidoCompraId();

            setIsActionPedidoCompraItem(null);
          }

          setSubmitting(false);
          setIsLoadingItemsPedidoCompra(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoadingItemsPedidoCompra(false);
        }
      }
    },
  });

  const handleModalDetails = (id: number) => {
    openDetailsModal();

    setIsIdItem(id);
  };

  const handleOnEdit = (id: string | number) => {
    loadItemById(id);

    setIsIdPedidoItem(id);

    setIsActionPedidoCompraItem('edit');
  };

  const handleOnAdd = () => {
    setIsActionPedidoCompraItem('add');

    setIsSelectAsyncClient(null);
    setIsSelectAsyncProduct(null);

    setIsStatusSuprimento(null);
    setIsDataFaturamentoItem(null);

    formik.setValues({
      precoVenda: 0,
      precoUltimo: 0,
      precoMedio: 0,
      preco: 0,
      precoMenor: 0,
      quantidade: 0,
      quantidadeRecebida: 0,
      isRessuprimento: 0,
      itemValorICMS: 0,
      itemValorIPI: 0,
      precoCompra: 0,
      observacao: '',
      status: 'ATIVO',
    });
  };

  const handleAsyncSelect = (option: ISelectOption) => {
    const precoCompra = option.label.precoCusto;

    if (precoCompra !== 'null' && precoCompra !== null) {
      let precoFormatado = Number(precoCompra);

      formik.setValues({
        ...formik.values,
        precoCompra: Number(precoFormatado),
      });
    }

    setIsSelectAsyncProduct(option);
  };

  const handleAsyncSelectClient = (option: ISelectOption) => {
    setIsSelectAsyncClient(option);
  };

  const [isNotaFiscalModal, openNotaFiscalModal, closeNotaFiscalModal] = useModal();
  const [isFreteModal, openFreteModal, closeFreteModal] = useModal();
  const [isDetailsModal, openDetailsModal, closeDetailsModal] = useModal();
  const [isDataFaturamentoItem, setIsDataFaturamentoItem] = useState<Date | null>(null);

  const handleOpenNFeModal = () => {
    openNotaFiscalModal();
  };

  const handleOpenFreteModal = () => {
    openFreteModal();
  };

  const loadSuprimentoStatusById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsStatusSuprimento({
        value: data.id,
        label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadSuprimentoStatus = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ISuprimentoStatus) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadItemById = async (idPedidoCompra: string | number) => {
    setIsLoadingItemsPedidoCompra(true);
    if (id) {
      try {
        const response = await getPedidoItemCompraById(idPedidoCompra);

        const {data} = response;

        data.isRessuprimento === true
          ? formik.setValues({
              isRessuprimento: 1,
            })
          : formik.setValues({
              isRessuprimento: 0,
            });

        setIsSelectAsyncClient({
          label: '',
          value: data.idCliente,
        });

        setIsSelectAsyncProduct({
          label: '',
          value: data.idProduto,
        });

        loadSuprimentoStatusById(data.idItemStatus);

        setIsLoadingItemsPedidoCompra(false);

        formik.setValues({
          preco: data.preco,
          precoVenda: data.precoVenda,
          precoMedio: data.precoMedio,
          precoMenor: data.precoMenor,
          precoCompra: data.precoCompra,
          precoUltimo: data.precoUltimo,
          desconto: data.desconto,
          quantidade: data.quantidade,
          quantidadeRecebida: data.quantidadeRecebida,
          itemValorICMS: data.itemValorICMS,
          itemValorIPI: data.itemValorIPI,
          observacao: data.observacao,
          status: data.status,
        });

        setIsDataFaturamentoItem(data.dataFaturamento ? new Date(data.dataFaturamento) : null);
      } catch (errors: any) {
        if (errors.response) {
          const status = errors.response.status;

          const {data} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });
        }
      }
    }
  };

  const loadItemsByPedidoCompraId = async () => {
    setSelectedItems([]);
    setIsLoadingItemsPedidoCompra(true);
    setIsLoadingSkeleton(true);
    if (id) {
      try {
        const response = await getItemsByPedidoCompraId(id);

        const {content} = response.data;

        setIsPedidoCompraItemsData(content);

        setIsLoadingItemsPedidoCompra(false);
        setIsLoadingSkeleton(false);
      } catch (errors) {
        setIsLoadingItemsPedidoCompra(false);
        setIsLoadingSkeleton(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadItemsByPedidoCompraId();
    }
  }, []);

  return (
    <>
      {isActionPedidoCompraItem && (
        <>
          <DescriptionComponent
            description={
              isActionPedidoCompraItem === 'edit'
                ? 'Editar Item do Pedido'
                : 'Cadastrar Item do Pedido'
            }
            isButtonAction
            buttonTitle={
              isActionPedidoCompraItem === 'edit'
                ? 'Cancelar Edição de item do Pedido de Compra'
                : 'Cancelar Cadastro de item do Pedido de Compra'
            }
            variantButton='danger'
            handleAction={() => setIsActionPedidoCompraItem(null)}
          />

          <form action='form-control-solid' onSubmit={formik.handleSubmit}>
            <RowForm>
              <Col md='12' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Selecione o Produto:
                </label>
                <AsyncSelectProductByCodFornecedor
                  handleOptions={(option) => handleAsyncSelect(option!)}
                  inputOption={isSelectAsyncProduct}
                  isDisabled={isActionPedidoCompraItem === 'add' ? false : true}
                  onKeyDown={(e) => {
                    setIsSelectAsyncProduct(null);
                    formik.setFieldValue('precoCompra', 0);
                  }}
                />
              </Col>
            </RowForm>

            <RowForm className='mt-4'>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Preço de Compra Padrão:
                </label>
                <input
                  placeholder='Insira o preço médio:'
                  type='text'
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.precoVenda || 0)}
                  className={`form-control ${
                    formik.touched.precoVenda && formik.errors.precoVenda ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formik.setFieldValue('precoVenda', parseFloat(inputValue) / 100);
                  }}
                />
              </Col>
              <Col md='3' className='col-12 mt-5'>
                <label htmlFor='' className='form-label'>
                  Preço de Compra Negociado:
                </label>
                <input
                  placeholder='Insira o Desconto:'
                  type='text'
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.precoCompra || 0)}
                  className={`form-control ${
                    formik.touched.precoCompra && formik.errors.precoCompra ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formik.setFieldValue('precoCompra', parseFloat(inputValue) / 100);
                  }}
                />
              </Col>
              <Col md='3' className='col-12 mt-5'>
                <label htmlFor='' className='form-label'>
                  Quantidade Solicitada:
                </label>
                <input
                  placeholder='Quantidade Solicitada:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('quantidade')}
                  className={`form-control ${
                    formik.touched.quantidade && formik.errors.quantidade ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    formik.setFieldValue('quantidade', inputValue);
                  }}
                />
                {formik.touched.quantidade && formik.errors.quantidade ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.quantidade}
                  </div>
                ) : null}
              </Col>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Quantidade Recebida:
                </label>
                <input
                  placeholder='Quantidade Recebida:'
                  type='text'
                  id=''
                  {...formik.getFieldProps('quantidadeRecebida')}
                  className={`form-control ${
                    formik.touched.quantidadeRecebida && formik.errors.quantidadeRecebida
                      ? 'is-invalid'
                      : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    formik.setFieldValue('quantidadeRecebida', inputValue);
                  }}
                />
                {formik.touched.quantidadeRecebida && formik.errors.quantidadeRecebida ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.quantidadeRecebida}
                  </div>
                ) : null}
              </Col>
            </RowForm>
            <RowForm className='mb-6'>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Valor ICMS (%):
                </label>
                <input
                  placeholder='Insira o Valor do ICMS (%):'
                  type='text'
                  id=''
                  {...formik.getFieldProps('itemValorICMS')}
                  className={`form-control ${
                    formik.touched.itemValorICMS && formik.errors.itemValorICMS ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Formata para o padrão correto com duas casas decimais
                    if (inputValue.length > 2) {
                      const intPart = inputValue.slice(0, -2); // Parte inteira (todos menos os dois últimos)
                      const decimalPart = inputValue.slice(-2); // Últimos dois dígitos como parte decimal
                      inputValue = `${intPart},${decimalPart}`;
                    } else if (inputValue.length > 0) {
                      // Se menos de 2 dígitos, adiciona "0," e preenche
                      inputValue = `0,${inputValue.padStart(2, '0')}`;
                    }

                    // Remove zeros desnecessários no início (mas mantém 0 antes da vírgula)
                    inputValue = inputValue.replace(/^0+(?=\d)/, '');

                    // Atualiza o valor no formik
                    formik.setFieldValue('itemValorICMS', inputValue);
                  }}
                />
                {formik.touched.itemValorICMS && formik.errors.itemValorICMS ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.itemValorICMS}
                  </div>
                ) : null}
              </Col>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Valor IPI (%):
                </label>
                <input
                  placeholder='Insira o Valor do IP (%):'
                  type='text'
                  id=''
                  {...formik.getFieldProps('itemValorIPI')}
                  className={`form-control ${
                    formik.touched.itemValorIPI && formik.errors.itemValorIPI ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Formata para o padrão correto com duas casas decimais
                    if (inputValue.length > 2) {
                      const intPart = inputValue.slice(0, -2); // Parte inteira (todos menos os dois últimos)
                      const decimalPart = inputValue.slice(-2); // Últimos dois dígitos como parte decimal
                      inputValue = `${intPart},${decimalPart}`;
                    } else if (inputValue.length > 0) {
                      // Se menos de 2 dígitos, adiciona "0," e preenche
                      inputValue = `0,${inputValue.padStart(2, '0')}`;
                    }

                    // Remove zeros desnecessários no início (mas mantém 0 antes da vírgula)
                    inputValue = inputValue.replace(/^0+(?=\d)/, '');

                    // Atualiza o valor no formik
                    formik.setFieldValue('itemValorIPI', inputValue);
                  }}
                />
                {formik.touched.itemValorIPI && formik.errors.itemValorIPI ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.itemValorIPI}
                  </div>
                ) : null}
              </Col>

              <Col md='3' className='col-12 mt-4'>
                <div>
                  <label className='form-label'>É Ressuprimento:</label>
                  <select
                    {...formik.getFieldProps('isRessuprimento')}
                    className={`form-control ${
                      formik.touched.isRessuprimento && formik.errors.isRessuprimento
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <option value='0'>NÃO</option>
                    <option value='1'>SIM</option>
                  </select>
                </div>
              </Col>
              <Col md='3' className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Desconto (%):
                </label>
                <input
                  placeholder='Desconto (%):'
                  type='text'
                  id=''
                  {...formik.getFieldProps('desconto')}
                  className={`form-control ${
                    formik.touched.desconto && formik.errors.desconto ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    // Formata para o padrão correto com duas casas decimais
                    if (inputValue.length > 2) {
                      const intPart = inputValue.slice(0, -2); // Parte inteira (todos menos os dois últimos)
                      const decimalPart = inputValue.slice(-2); // Últimos dois dígitos como parte decimal
                      inputValue = `${intPart},${decimalPart}`;
                    } else if (inputValue.length > 0) {
                      // Se menos de 2 dígitos, adiciona "0," e preenche
                      inputValue = `0,${inputValue.padStart(2, '0')}`;
                    }

                    // Remove zeros desnecessários no início (mas mantém 0 antes da vírgula)
                    inputValue = inputValue.replace(/^0+(?=\d)/, '');

                    // Atualiza o valor no formik
                    formik.setFieldValue('desconto', inputValue);
                  }}
                />
                {formik.touched.desconto && formik.errors.desconto ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.desconto}
                  </div>
                ) : null}
              </Col>
            </RowForm>
            <RowForm>
              <Col md='3' className='col-12'>
                <label htmlFor='' className='form-label'>
                  Selecione o Cliente:
                </label>
                <AsyncSelectCliente
                  handleOptions={(option) => handleAsyncSelectClient(option!)}
                  inputOption={isSelectAsyncClient}
                  isSelectDOM={false}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncClient(null);
                    }
                  }}
                />
              </Col>
              <Col md='3' className='col-12'>
                <label htmlFor='' className='form-label'>
                  Selecione o Status:
                </label>
                <AsyncSelect
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder='Selecione o Status'
                  loadOptions={loadSuprimentoStatus}
                  value={isStatusSuprimento}
                  onChange={(selectedOption: ISelectOption | null) => {
                    // Verifica se selectedOption não é null antes de definir o estado
                    if (selectedOption) {
                      setIsStatusSuprimento(selectedOption);
                    } else {
                      setIsStatusSuprimento(null); // Define como null caso a opção seja anulada
                    }
                  }}
                  cacheOptions
                  defaultOptions
                />
              </Col>
              <Col md='3' className='col-12'>
                <label htmlFor='' className='form-label'>
                  Data de Faturamento:
                </label>

                <DatePicker
                  format='dd-MM-yyyy'
                  className='w-100'
                  placeholder='Data de faturamento'
                  onChange={(date) => setIsDataFaturamentoItem(date)}
                  value={isDataFaturamentoItem}
                />
              </Col>
              <Col md='3' className='col-12'>
                <label className='form-label'>Status ATIVO/INATIVO:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                <small id='' className='form-text text-muted'>
                  Ative ou Desative o Item
                </small>
              </Col>
            </RowForm>
            <RowForm className='mt-4'>
              <Col md='12' className='col-12-sm'>
                <label className='form-label'>Observação:</label>
                <textarea
                  placeholder='Digite a observação'
                  {...formik.getFieldProps('observacao')}
                  className={`form-control ${
                    formik.touched.observacao && formik.errors.observacao ? 'is-invalid' : ''
                  }`}
                />
                {formik.touched.observacao && formik.errors.observacao ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.observacao}
                  </div>
                ) : null}
              </Col>
            </RowForm>

            <FooterForm
              textActionSubmit={
                isActionPedidoCompraItem === 'edit'
                  ? 'Editar Item do Pedido'
                  : 'Cadastrar Item do Pedido'
              }
              isLoading={isLoadingItemsPedidoCompra}
              redirectTo='pedido-compras-listagem'
              isBack={false}
            />
          </form>
        </>
      )}

      {isActionPedidoCompraItem == null ? (
        <DescriptionComponent
          description='Itens'
          buttonTitle='+ Adicionar'
          buttonTitle2='Vincular NF-e'
          buttonTitle3='Vincular ao Frete'
          isButtonAction
          isButtonAction2
          isButtonAction3
          isDisabled2={selectedItems.length === 0}
          isDisabled3={selectedItems.length === 0}
          handleAction={() => handleOnAdd()}
          handleAction2={() => handleOpenNFeModal()}
          handleAction3={() => handleOpenFreteModal()}
          variantButton='warning'
          variantButton2='danger'
          variantButton3='primary'
          showTooltip
          tooltipText='Não é possível realizar esta ação porque os items foram separados para logística.'
        />
      ) : (
        <></>
      )}

      {isLoadingSkeleton ? (
        <Skeleton count={20} height={25} />
      ) : (
        <div className='table-responsive mt-4' style={{maxHeight: 500}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-5'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IPedidoCompraItem>, index: number) => (
                  <th key={index} className={index === 0 ? 'text-end' : 'text-start'}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IPedidoCompraItem>, i) => {
                  prepareRow(row);
                  const isAtendido = row.original.isAtendido; // Pegando a propriedade isAtendido
                  return (
                    <CustomRow
                      isAtendido={isAtendido}
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM ITENS PARA O PEDIDO DE COMPRA
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <NotaFiscalDetails />

      <FreteDetails />

      <NotaFiscalModal
        isOpen={isNotaFiscalModal}
        onHide={closeNotaFiscalModal}
        selectItens={selectedItems}
        handleRequest={() => loadItemsByPedidoCompraId()}
      />

      <FreteModal
        isOpen={isFreteModal}
        onHide={closeFreteModal}
        selectItens={selectedItems}
        handleRequest={loadItemsByPedidoCompraId}
      />

      <ItemDetailsModal isOpen={isDetailsModal} onHide={closeDetailsModal} idItem={isIdItem} />
    </>
  );
};

export default PedidoCompraDetails;
