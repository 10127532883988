import React, {useEffect, useState} from 'react';
import axios from 'axios';

interface OptionType {
  value: number;
  label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useOptionsPermissoes = () => {
  const [optionsPermissoes, setOptionsPermissoes] = useState<OptionType[]>([]);

  useEffect(() => {
    async function fetchOptions() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/perfis?status=ATIVO&size=10000`
        );
        const {data} = response;

        const {content: permissoes} = data;
        const optionsPermissoes = permissoes.map((permissao) => ({
          value: permissao.id,
          label: permissao.titulo,
        }));

        setOptionsPermissoes(optionsPermissoes);
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
      }
    }

    fetchOptions();
  }, []);

  return {optionsPermissoes};
};

export default useOptionsPermissoes;
