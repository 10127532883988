import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {ILinhaProduto, Modal} from '../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import {GET_LINHA_PRODUTO} from '../../listagem/listagem_requests/listagem_requests';

interface ModalLinhaProdutoProps {
  isOpen: boolean;
  onHide: () => void;
  data: ILinhaProduto | null;
  isEdit: boolean;
  loadData: ({page}: {page: number}) => Promise<void>;
}

const ModalLinhaProduto = ({isOpen, onHide, data, isEdit, loadData}: ModalLinhaProdutoProps) => {
  const [codigo, setCodigo] = useState<string | null>(null);
  const [linha, setLinha] = useState<string | null>(null);
  const [isDataImportacao, setIsDataImportacao] = useState<Date | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setCodigo(null);
    setLinha(null);
    setIsDataImportacao(null);
    setStatus('ATIVO');
  };

  const cadastrarLinhaProduto = async () => {
    setIsLoading(true);
    try {
      let url = `${GET_LINHA_PRODUTO}`;
      if (linha && codigo && isDataImportacao && status) {
        const response = await axios.post(url, {
          codigo: Number(codigo),
          linha: linha,
          dataImportacao: isDataImportacao,
          status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Linha de Produto cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loadData({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarLinhaProduto = async () => {
    setIsLoading(true);
    try {
      if (data) {
        let url = `${GET_LINHA_PRODUTO}/${data.id}`;
        if (codigo && linha && isDataImportacao && status) {
          const response = await axios.put(url, {
            codigo: Number(codigo),
            linha: linha,
            dataImportacao: isDataImportacao,
            status,
          });

          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: `Linha de Produto editada com sucesso`,
              timer: 3000,
              timerProgressBar: true,
            }).then(() => {
              onClose();
            });
            loadData({page: 0});
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data?.mensagem,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Conteúdo não foi carregado!',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarLinhaProduto() : cadastrarLinhaProduto())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  useEffect(() => {
    if (data && isEdit && isOpen) {
      (async () => {
        let url = `${GET_LINHA_PRODUTO}/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setLinha(response.linha);
          setCodigo(response.codigo);
          setIsDataImportacao(new Date(response.dataImportacao));
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Linha de Produto
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Codigo:
                </label>
                <input
                  type='text'
                  placeholder='Digite o Codigo'
                  className='form-control'
                  value={codigo ? codigo : ''}
                  onChange={(event) => {
                    const value = event.target.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                    setCodigo(value);
                  }}
                />
                {(codigo === null || codigo === '' || codigo === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Codigo</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Linha:
                </label>
                <input
                  type='text'
                  value={linha ? linha : ''}
                  className='form-control'
                  onChange={(e) => {
                    setLinha(e.target.value);
                  }}
                  placeholder='Digite o Linha'
                />
                {(linha === null || linha === '' || linha === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Linha</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Data de Importação:
                </label>
                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Importação'
                  onChange={(date) => setIsDataImportacao(date)}
                  value={isDataImportacao}
                  menuStyle={{zIndex: 1000000}}
                />
                {(isDataImportacao === null || isDataImportacao === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Data de Importação
                  </span>
                )}
              </Col>
              <Col sm='3'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalLinhaProduto;
