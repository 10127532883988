import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import { DescriptionComponent, ICondicoesPagamento } from "../../../shared";
import { Column, ColumnInstance, Row, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { getCondicoesPagamento} from '../listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import { CustomRow } from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import { smoothScrollToBottom } from '../../../shared/core';
import Skeleton from 'react-loading-skeleton';

const ListagemCondicoesPagamento: React.FC = () => {
    const formularioColumns: Column<ICondicoesPagamento>[] = [
        {
          Header: 'ID',
          accessor: 'id',
          Cell: ({value}) => <div className='text-end'>{value}</div>,
        },

        {
            Header: 'Condiçao de Pagamento',
            accessor: 'condicaoPagamento',
            Cell: ({value}) => <div className='text-start'>{value}</div>,
          },
        {
          Header: 'Ações',
          Cell: ({row}) => (
            <div className='text-start'>
              <button
                onClick={() => handleOnEdit(row.original.id!)}
                className='btn btn-success btn-sm bi bi-pencil'
                data-toggle='tooltip'
                data-placement='top'
                title='Editar'
              />
            </div>
          ),
        },
      ];
    
      const navigate = useNavigate();
    
      const [isCondicaoPagamentoData, setIsCondicaoPagamentoData] = useState<ICondicoesPagamento[]>([]);
      const [isPage, setIsPage] = useState<number>(0);
      const [isHasMore, setIsHasMore] = useState<boolean>();
      const [isLoading, setIsLoading] = useState<boolean>(false);
      const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
    
      const data = useMemo(() => isCondicaoPagamentoData, [isCondicaoPagamentoData]);
      const columns = useMemo(() => formularioColumns, []);
      const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
      });
    
      const initialValues = {
        condicaoPagamento: '',
      };
    
      const formik = useFormik({
        initialValues,
        onSubmit: async (values, {setSubmitting}) => {
          setIsLoadingSearch(true);
    
          try {
            const response = await getCondicoesPagamento({
              page:0,
              condicaoPagamento: values.condicaoPagamento,
            });
    
            const {content} = response.data;


            setIsLoadingSearch(false);
            setSubmitting(false);
    
            setIsCondicaoPagamentoData(content);
          } catch (errors: any) {
            const {error} = errors;
    
            if (error.response) {
              const id = error.response.id;
    
              if (id === 401 || 403) {
                Swal.fire({
                  icon: 'info',
                  title: 'Por questões de segurança, por favor faça login novamente',
                  confirmButtonText: 'Ok',
                }).then(() => {
                  window.open('/auth', '_blank');
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: error,
                  confirmButtonText: 'Ok',
                });
              }
            }
    
            setSubmitting(false);
            setIsLoadingSearch(false);
          }
        },
      });
    
      const loadMore = () => {
        if (isHasMore && !isLoading) {
          setIsPage((prevPage) => prevPage + 1);
         smoothScrollToBottom()

        }
      };
    
      const handleOnEdit = (id: string | number) => {
        navigate(`/cadastro-condicoes-de-pagamento/${id}`);
      };
    
      const getCondicaoPagamentoData = async (page = 0) => {
        setIsLoading(true);
        try {
          const response = await getCondicoesPagamento({
            condicaoPagamento: '',
            page,
          });
    
          const {content, totalPages} = response.data;
    
          setIsCondicaoPagamentoData((prev) => (isPage === 0 ? content : [...prev, ...content]));
    
          setIsHasMore(isPage < totalPages - 1);
    
          setIsLoading(false);
        } catch (errors: any) {
          const {error} = errors;
    
          const id = error.response.id;
    
          if (id === 401 || 403) {
            Swal.fire({
              icon: 'error',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            });
          }
    
          Swal.fire({
            icon: 'error',
            title: error,
            confirmButtonText: 'Ok',
          });
    
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        getCondicaoPagamentoData(isPage);
      }, [isPage]);
    
    return (
        <>
                {isLoading ? (
                  <Skeleton  count={40} height={25}/>
        ) : 
        (<>
      <DescriptionComponent
        description='Listagem de Condiçoes de Pagamento'
        withLink
        buttonTitle='+ Nova Condiçao de pagamento'
        redirectTo='/cadastro-condicoes-de-pagamento'
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form
         onSubmit={formik.handleSubmit}
          className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
          <Col className='col-sm-12'>
              <label className='form-label'>Condiçoes de Pagamento:</label>
              <input
                type='text'
                placeholder='Digite o título da Condiçao de pagamento'
                {...formik.getFieldProps('condicaoPagamento')}
              className={`form-control ${
                formik.touched.condicaoPagamento && formik.errors.condicaoPagamento ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.condicaoPagamento && formik.errors.condicaoPagamento ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.condicaoPagamento}
              </div>
            ) : null}
            </Col>

          </RowBootstrap>
          <div className='row mt-6'>
            <div className='col-sm-2 mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        <table
          id='kt_table_formularios'
          className='table table-hover table-striped table-rounded table-row-bordered border'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ICondicoesPagamento>, index: number) => (
                <th
                  key={column.id}
                  className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' 
          {...getTableBodyProps()}
          >
            {rows.length > 0 ? (
              rows.map((row: Row<ICondicoesPagamento>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    showEditButton
                    onEdit={(id) => handleOnEdit(id)}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>)}
          </tbody>
        </table>
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' 
            onClick={loadMore}
            >          
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>
      
      </>)}
    </>
    )
}
export default ListagemCondicoesPagamento