import {ChangeEvent, useEffect, useState} from 'react';
import {useProduto} from '../hooks/useProduto';
import Divider from './Divider';
import Select from 'react-select';
import fetchMarcaProductOptions from '../../../selectOptions/useOptionsMarcaProduto';
import {OptionType} from '../Interface';
import useOptionsCategoria from '../../../selectOptions/useOptionsCategoria';
import '../style/style.css';
import {base64Prefixes} from '../../../../shared/core';
import useOptionsEmbalagem from '../../../selectOptions/useOptionsEmbalagem';
import useOptionsTipoEmbalagem from '../../../selectOptions/useOptionsTipoEmbalagem';
import axios from 'axios';

const DadosComplementares = () => {
  const [inputs, setInputs] = useState<string[]>(['']);
  const {optionsEmbalagem} = useOptionsEmbalagem();
  const {optionsTipoEmbalagem} = useOptionsTipoEmbalagem();

  const {
    formik,
    handleChange,
    setSelectedMarca,
    selectedMarca,
    // optionsEmbalagem,
    base64Image,
    selectedEmbalagem,
    setSelectedEmbalagem,
    selectedTipo_Embalagem,
    setSelectedTipo_Embalagem,
    setBase64Image,
    selectedCategoria,
    setSelectedCategoria,
    handleSelectChange,
  } = useProduto();
  const [optionsMarcaProdutos, setOptionsMarcaProdutos] = useState<OptionType[]>([]);
  const {optionsCategoria} = useOptionsCategoria();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  useEffect(() => {
    console.log(base64Image)
  }, [base64Image])

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const handleSelectChangeEmbalagem = async (event) => {
    const embalagemId = event.target.value;
    const embalagemDesc = event.target.options[event.target.selectedIndex].text;

    if (embalagemId > 0) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/embalagens/${embalagemId}`
        );
        const embalagem = response.data;
        formik.setFieldValue('largura', embalagem.largura);
        formik.setFieldValue('altura', embalagem.altura);
        formik.setFieldValue('comprimento', embalagem.comprimento);
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
        return [];
      }
    } else {
      formik.setFieldValue('largura', 0);
      formik.setFieldValue('altura', 0);
      formik.setFieldValue('comprimento', 0);
    }

    if (embalagemDesc === 'Selecione') {
      setSelectedEmbalagem({id: '', desc: ''});
    } else {
      setSelectedEmbalagem({id: embalagemId, desc: embalagemDesc});
    }
  };

  // const addInput = () => {
  //   setInputs([...inputs, '']);
  // };

  // const handleInputChange = (value: string, index: number) => {
  //   const newInputs = [...inputs];
  //   newInputs[index] = value;
  //   setInputs(newInputs);
  // };

  const handleEstoque = (event) => {
    const novoValor = event.target.value;
    formik.setFieldValue('controlar_estoque', Number(novoValor));

    if (novoValor === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  };

  const startsWithSupportedPrefix = (image: string) => {
    return base64Prefixes.some((prefix) => image.startsWith(prefix));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        alert('Formato de arquivo inválido. Por favor, selecione um arquivo PNG, JPG ou JPEG.');
        event.target.value = '';
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert('O arquivo é muito grande. Por favor, selecione um arquivo menor que 2MB.');
        event.target.value = '';
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setBase64Image(base64data);
      };

      reader.readAsDataURL(file);
    }
  };

  // useEffect(() => {
  //   const loadOptions = async () => {
  //     // const options = await fetchMarcaProductOptions();
  //     // setOptionsMarcaProdutos(options);
  //   // };
  //   loadOptions();
  // }, []);

  return (
    <div className='mb-5 mt-8'>
      <div className='mb-5 row'>
        <div className='mb-form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h5 style={{color: '#3f3f3f'}}>Dimensões e Peso</h5>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <div className='mb-3 row'>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Peso Líquido:</label>
            <input
              type='number'
              placeholder='Em Kg'
              {...formik.getFieldProps('peso_liq')}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.peso_liq && formik.errors.peso_liq && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.peso_liq}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-3  mt-4'>
            <label className='form-label'>Peso Bruto:</label>
            <input
              type='number'
              placeholder='Em Kg'
              {...formik.getFieldProps('peso_bruto')}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.peso_bruto && formik.errors.peso_bruto && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.peso_bruto}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Nº de volumes:</label>
            <input
              type='number'
              placeholder='(Exemplo: 01003.00)'
              {...formik.getFieldProps('num_volumes')}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.num_volumes && formik.errors.num_volumes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.num_volumes}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-1'></div>
        </div>
        <div className='mb-3 row'>
          <div className='col-md-3  mt-4'>
            <label className='form-label'>Tipo da Embalagem:</label>
            <select
              defaultValue={''}
              required
              className='form-select bg-transparent'
              value={selectedTipo_Embalagem?.id}
              onChange={(e) => handleSelectChange(e, setSelectedTipo_Embalagem)}
              style={{marginTop: '0px'}}
            >
              <option value='' disabled selected>
                Selecione...
              </option>
              {optionsTipoEmbalagem.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {formik.touched.tipo_embalagem_id && formik.errors.tipo_embalagem_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.tipo_embalagem}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-6 mt-4'>
            <label className='form-label'>Embalagem:</label>
            <select
              defaultValue={''}
              required
              className='form-select bg-transparent'
              {...formik.getFieldProps('embalagem_id')}
              value={selectedEmbalagem?.id}
              onChange={handleSelectChangeEmbalagem}
              style={{marginTop: '0px'}}
            >
              <option value='' selected>
                Selecione...
              </option>
              <option value='0'>Embalagem Customizada:</option>
              {optionsEmbalagem.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {formik.touched.embalagem_id && formik.errors.embalagem_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.embalagem_id}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-1'></div>
        </div>
        <div className='mb-8 row'>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Largura:</label>
            <input
              disabled={selectedEmbalagem!.id === '0' ? false : true}
              type='number'
              placeholder='Em cm'
              {...formik.getFieldProps('largura')}
              value={formik.values.largura}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.largura && formik.errors.largura && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.largura}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-3  mt-4'>
            <label className='form-label'>Altura:</label>
            <input
              disabled={selectedEmbalagem!.id === '0' ? false : true}
              type='number'
              placeholder='Em cm'
              {...formik.getFieldProps('altura')}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.altura && formik.errors.altura && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.altura}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Comprimento:</label>
            <input
              disabled={selectedEmbalagem!.id === '0' ? false : true}
              type='number'
              placeholder='Em cm'
              {...formik.getFieldProps('comprimento')}
              className='form-control bg-transparent'
              onChange={handleChange}
            />

            {formik.touched.comprimento && formik.errors.comprimento && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.comprimento}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-1'></div>
        </div>
      </div>

      <div className='mb-form-label-container mt-5' style={{display: 'flex', alignItems: 'center'}}>
        <h5 style={{color: '#3f3f3f'}}>Estoque</h5>
        <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
      </div>
      <div className='mb-3 row'>
        <div
          className='col-md-2  mt-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Controlar Estoque:</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            {...formik.getFieldProps('controlar_estoque')}
            //value={}
            onChange={handleEstoque}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value={1}> SIM </option>
            <option value={2}> NÃO </option>
          </select>
          <small id='' className='form-text text-muted'>
            Acompanhamento da movimentação de estoque
          </small>

          {formik.touched.controlar_estoque && formik.errors.controlar_estoque && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.controlar_estoque}</span>
              </div>
            </div>
          )}
        </div>

        {formik.values.controlar_estoque !== 2 && (
          <>
            <div
              className='col-md-2 mt-4'
              style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
            >
              <label className='form-label'>Estoque Inicial (Geral):</label>
              <input
                type='number'
                placeholder='Estoque Inicial'
                {...formik.getFieldProps('estoque_inicial')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Informar se deseja lançar o estoque inicial do produto
              </small>

              {formik.touched.estoque_inicial && formik.errors.estoque_inicial && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_inicial}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2 mt-4'>
              <label className='form-label'>Estoque Mínimo:</label>
              <input
                type='number'
                placeholder='Estoque Mínimo'
                {...formik.getFieldProps('estoque_min')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade mínima do produto no estoque
              </small>

              {formik.touched.estoque_min && formik.errors.estoque_min && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_min}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2 mt-4'>
              <label className='form-label'>Estoque Máximo:</label>
              <input
                type='number'
                placeholder='Estoque Máximo'
                {...formik.getFieldProps('estoque_max')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade máxima do produto no estoque
              </small>

              {formik.touched.estoque_max && formik.errors.estoque_max && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_max}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2  mt-4'>
              <label className='form-label'>Sob Encomenda:</label>
              <select
                defaultValue={''}
                required
                className='form-select bg-transparent'
                {...formik.getFieldProps('sob_encomenda')}
                value={formik.values.sob_encomenda}
                //onChange={handleTipoCadastroChange}
                style={{marginTop: '0px'}}
              >
                <option value='' disabled selected>
                  Selecione...
                </option>
                <option value='1'> SIM </option>
                <option value='2'> NÃO </option>
              </select>

              {formik.touched.sob_encomenda && formik.errors.sob_encomenda && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.sob_encomenda}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2  mt-4'>
              <label className='form-label'>Controlar Lotes:</label>
              <select
                defaultValue={''}
                required
                className='form-select bg-transparent'
                {...formik.getFieldProps('controlar_lotes')}
                value={formik.values.controlar_lotes}
                //onChange={handleTipoCadastroChange}
                style={{marginTop: '0px'}}
              >
                <option value='' disabled selected>
                  Selecione...
                </option>
                <option value='1'> SIM </option>
                <option value='2'> NÃO </option>
              </select>

              {formik.touched.controlar_lotes && formik.errors.controlar_lotes && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.controlar_lotes}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className='mb-8 row'>
        <div className='col-md-4 mt-4'>
          <label className='form-label'>Localização:</label>
          <input
            type='text'
            placeholder='(Ex: corredor A)'
            {...formik.getFieldProps('localizacao')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted'>
            Localização física no estoque
          </small>

          {formik.touched.localizacao && formik.errors.localizacao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.localizacao}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className='col-md-2 mt-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Dias para Preparação:</label>
          <input
            type='number'
            placeholder='Dias para preparação'
            {...formik.getFieldProps('dias_preparacao')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />

          {formik.touched.dias_preparacao && formik.errors.dias_preparacao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.dias_preparacao}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Divider />
      <div className='mb-3 row'>
        <div className='col-md-8 mt-4 mb-4'>
          <label className='form-label'>Descrição Complementar</label>
          <textarea
            placeholder='Digite a descrição complementar:'
            className='form-control bg-transparent'
            {...formik.getFieldProps('desc_complementar')}
            onChange={(e) => {
              const value = e.target.value;
              formik.setFieldValue('desc_complementar', value);
            }}
          />

          {/* <div className='char-counter' style={{color: 'black'}}>
            {75 - formik.values.desc_complementar.length} caracteres restantes
          </div> */}
        </div>
        {formik.touched.desc_complementar && formik.errors.desc_complementar && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.desc_complementar}</span>
            </div>
          </div>
        )}
      </div>

      <Divider />

      <div className='col-md-8 mt-4 mb-6'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px', marginBottom: 16}}>Imagem:</h4>
        <div
          style={{
            width: '200px',
            height: '200px',
            backgroundColor: '#dbdfe9',
            borderRadius: '6px',
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.5)',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {base64Image ? (
            <img
              src={
                base64Image
                  ? startsWithSupportedPrefix(base64Image)
                    ? base64Image // Já contém um dos prefixos suportados
                    : `${base64Prefixes[1]},${base64Image}` // Adiciona o prefixo padrão (jpeg) se necessário
                  : ''
              }
              alt={formik.values.descricao}
              style={{width: '196px', height: '196px', borderRadius: '6px'}}
            />
          ) : (
            <label
              htmlFor='selecao-arquivo'
              style={{color: '#99a1b7', fontWeight: '600', fontSize: '18px'}}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 14,
                  cursor: 'pointer',
                  width: '200px',
                  height: '200px',
                }}
              >
                <i className='bi bi-lightbulb' style={{fontSize: '60px'}}></i>
                Selecionar Imagem
              </div>
            </label>
          )}
          <input
            id='selecao-arquivo'
            type='file'
            accept='.png,.jpg,.jpeg'
            className='form-personalizado choose'
            maxLength={2000000}
            onChange={handleFileChange}
          />
        </div>
        <small id='' className='form-text text-muted'>
          O tamanho do arquivo não deve ultrapassar 2 MB
        </small>
      </div>
      {base64Image && (
        <button type='button' className='btn btn-secondary' onClick={() => setBase64Image('')}>
          Remover Imagem
        </button>
      )}

      <Divider />

      <div className='col-md-8 mt-4 mb-8'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px'}}>Imagens externas</h4>
        <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
          As imagens externas são utilizadas apenas no envio de produtos para o e-commerce e somente
          sua URL ficará armazenada.
        </small>
        <h3 className='form-label mt-6'>Endereço da imagem URL: </h3>
        {/*aqui é feito o map do numero de inputs gerados para salvar as imagens url*/}
        {inputs.map((value, index) => (
          <>
            <div key={index} style={{display: 'flex', flexDirection: 'row', gap: 4}}>
              <input
                type='text'
                placeholder='https://exemplo.url.com.br'
                {...formik.getFieldProps('img_url')}
                className='form-control bg-transparent mt-4'
              />
              {/* <button
                type='button'
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#0050DC',
                  fontWeight: '500',
                  marginTop: '8px',
                  fontSize: '10pt',
                  textDecoration: 'underline',
                }}
              >
                salvar
              </button> */}
            </div>
          </>
        ))}
        {/* <button
          onClick={addInput}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#0050DC',
            fontWeight: '500',
            marginTop: '12px',
            fontSize: '10pt',
          }}
          type='button'
        >
          <i className='bi bi-plus-circle' style={{color: '#0050DC', fontSize: '10pt'}}></i>
          adicionar url
        </button> */}
      </div>

      <Divider />
      <div className='col-md-8 mt-4 mb-8'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px'}}>Campos adicionais</h4>
        <div>
          <label className='form-label mt-6'>Link do Vídeo:</label>
          <input
            type='text'
            placeholder='Link'
            {...formik.getFieldProps('link_video')}
            className='form-control bg-transparent'
          />
        </div>

        <div>
          <label className='form-label mt-4'>Slug:</label>
          <input
            type='text'
            placeholder='Slug'
            {...formik.getFieldProps('slug')}
            className='form-control bg-transparent'
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Ultilizado para identificação legível no link do produto no e-commerce
          </small>
          {formik.touched.slug && formik.errors.slug && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.slug}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <label className='form-label mt-4'>Keywords:</label>
          <input
            type='text'
            placeholder='Keywords'
            {...formik.getFieldProps('keywords')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Utilizado para SEO ou metadados que ajudam a descrever um produto. Informe os valores
            separados por vírgula.
          </small>
          {formik.touched.keywords && formik.errors.keywords && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.keywords}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <label className='form-label mt-4'>Título para SEO:</label>
          <input
            type='text'
            placeholder='SEO'
            {...formik.getFieldProps('titulo_seo')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Título do produto que será exibido nos resultados da busca no Google
          </small>
          {formik.touched.titulo_seo && formik.errors.titulo_seo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.titulo_seo}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DadosComplementares;
