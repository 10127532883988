import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {AsyncSelectEmpresa, IMarcas, ISelectOption, ISetor, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import AsyncSelectTipoEntidade from '../../../../shared/components/async-select-tipo-entidade/async-select-tipo-entidade';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {getSetores, getTipoDespesa, renderEntidadeContasReceber} from '../../ContasReceber';
import {set} from 'date-fns';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type ModalContasReceberProps = {
  isOpen: boolean;
  onHide: () => void;
  data: IMarcas | null;
  isEdit: boolean;
  loader: ({page}) => Promise<void>;
};

const ModalContasReceber = ({isOpen, onHide, data, isEdit, loader}: ModalContasReceberProps) => {
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [setor, setSetor] = useState<string | null>(null);
  const [despesa, setDespesa] = useState<string | null>(null);
  const [qtdParcelas, setQtdParcelas] = useState<string | null>(null);
  const [valor, setValor] = useState<number | null>();
  const [isSelectAsyncTipoEntidade, setIsSelectAsyncTipoEntidade] = useState<ISelectOption | null>(
    null
  );
  const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null);
  const [setores, setSetores] = useState<ISetor[] | null>(null);
  const [tipoDespesa, setTipoDespesa] = useState<ISetor[] | null>(null);

  const [isSelectAsyncEntidade, setIsSelectAsyncEntidade] = useState<ISelectOption | null>(null);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setSetor(null);
    setDespesa(null);
    setQtdParcelas(null);
    setValor(null);
    setIsSelectAsyncTipoEntidade(null);
    setSelectedEmpresa(null);
    setIsSelectAsyncEntidade(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarContasReceber = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber`;
      if (
        status &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEntidade &&
        setor &&
        despesa &&
        qtdParcelas &&
        valor &&
        selectedEmpresa
      ) {
        const response = await axios.post(url, {
          idTipoEntidade: isSelectAsyncTipoEntidade?.value,
          idEntidade: isSelectAsyncEntidade?.value,
          idEmpresa: selectedEmpresa?.value,
          idSetor: setor,
          idTipoDespesa: despesa,
          valor,
          qtdParcelas,
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Conta a Receber cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarContasReceber = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber/${data?.id}`;
      if (
        status &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEntidade &&
        setor &&
        despesa &&
        qtdParcelas &&
        valor &&
        selectedEmpresa
      ) {
        const response = await axios.put(url, {
          idTipoEntidade: isSelectAsyncTipoEntidade?.value,
          idEntidade: isSelectAsyncEntidade?.value,
          idEmpresa: selectedEmpresa?.value,
          idSetor: setor,
          idTipoDespesa: despesa,
          valor,
          qtdParcelas,
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Conta a Receber editada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const handleAsyncSelectTipoEntidade = (option: ISelectOption) => {
    setIsSelectAsyncTipoEntidade(option);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarContasReceber() : cadastrarContasReceber())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isEdit && isOpen) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setDespesa(response.idTipoDespesa);
          setQtdParcelas(response.qtdParcelas);
          setDescricao(response.descricao);
          setSetor(response.idSetor);
          setSelectedEmpresa({value: response.idEmpresa, label: ''});
          setIsSelectAsyncTipoEntidade({value: response.idTipoEntidade, label: ''});
          setIsSelectAsyncEntidade({value: response.idEntidade, label: ''});
          setValor(response.valor);
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isEdit, isOpen]);

  useEffect(() => {
    getSetores(setSetores);
    getTipoDespesa(setTipoDespesa);
  }, []);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Conta a Receber
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Tipo de Entidade:</label>
                <AsyncSelectTipoEntidade
                  handleOptions={(option) => handleAsyncSelectTipoEntidade(option!)}
                  inputOption={isSelectAsyncTipoEntidade}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncTipoEntidade(null);
                    }
                  }}
                />
                {(isSelectAsyncTipoEntidade === null ||
                  isSelectAsyncTipoEntidade === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Tipo de entidade
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Entidade:</label>
                {renderEntidadeContasReceber(
                  isSelectAsyncEntidade,
                  setIsSelectAsyncEntidade,
                  isSelectAsyncTipoEntidade
                )}
                {(isSelectAsyncTipoEntidade === null ||
                  isSelectAsyncTipoEntidade === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a Entidade</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Empresa:</label>
                <AsyncSelectEmpresa
                  onKeyDown={() => {
                    setSelectedEmpresa(null);
                  }}
                  inputOption={selectedEmpresa}
                  handleOptions={(e) => {
                    setSelectedEmpresa(e);
                  }}
                />
                {(selectedEmpresa === null || selectedEmpresa === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a Empresa</span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Setor:</label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  onChange={(event) => setSetor(event.target.value)}
                  value={setor || ''}
                >
                  <option value=''>Selecione o setor</option>
                  {setores &&
                    setores.map((setor) => (
                      <option value={setor.id} key={setor.id}>
                        {setor.titulo}
                      </option>
                    ))}
                </select>
                {(setor === null || setor === '' || setor === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Setor</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Tipo de Despesa:</label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  onChange={(event) => setDespesa(event.target.value)}
                  value={despesa || ''}
                >
                  <option value=''>Selecione o tipo de despesa</option>
                  {tipoDespesa &&
                    tipoDespesa.map((tD) => (
                      <option value={tD.id} key={tD.id}>
                        {tD.titulo}
                      </option>
                    ))}
                </select>
                {(despesa === null || despesa === '' || despesa === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Tipo de despesa
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Valor:</label>
                <input
                  type='text'
                  placeholder='Digite o Valor '
                  className='form-control'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valor || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');
                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;
                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    setValor(valorEmCentavos);
                  }}
                />
                {(valor === null || valor === 0 || valor === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Valor</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Quantidade de parcelas:</label>
                <input
                  type='text'
                  placeholder='Digite a quantidade de parcelas '
                  className='form-control'
                  value={qtdParcelas || ''}
                  onChange={(event) => {
                    const value = event.target.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                    setQtdParcelas(value);
                  }}
                  onKeyDown={(event) => {
                    const value = event.currentTarget.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                    setQtdParcelas(value);
                  }}
                />
                {(qtdParcelas === null || qtdParcelas === '' || qtdParcelas === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a Quantidade de parcelas
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Descrição:
                </label>
                <textarea
                  value={descricao ? descricao : ''}
                  className='form-control'
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                />
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalContasReceber;
