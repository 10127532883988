import {Link} from 'react-router-dom';

const 
ButtonOpcoesListagem = ({handleEdit, rota, titleLink}) => {
  return (
    <div className='dropdown position-static'>
      <button className='btn btn-success btn-sm dropdown-toggle' data-bs-toggle='dropdown'>
        <i className=''>Opções</i>
      </button>
      <ul
        className='dropdown-menu'
        style={{
          position: 'absolute',
          zIndex: 1050,
          top: '100%',
          left: '0',
        }}
      >
        <li>
          <button
            className='dropdown-item'
            type='button'
            onClick={() => {
              handleEdit();
            }}
          >
            <i className='bi-pencil'> </i>
            <span className='mx-2'>EDITAR</span>
          </button>
        </li>

        <li>
          <hr className='dropdown-divider' />
        </li>
        <li>
          <Link to={rota} className='dropdown-item' type='Button'>
            <i className='bi-box-arrow-up-right'></i>
            <span className='mx-2'>{titleLink}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default ButtonOpcoesListagem;
