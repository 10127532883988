import {useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../../../shared';


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectGarantia: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {


  const loadOptionsGarantia = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/garantia/listar`;

      const response = await axios.get(url, {params: {status: 'ATIVO'}});
      const data = await response.data.content;

      const options = data.map((item: any) => ({
        value: item.id,
        label: 
        <div 
          style={{width: '8%'}}
        className='d-flex  justify-content-between'>
        <span className=' px-1' >{item.quantidade}</span>
        {/* <span className='' >-</span> */}
        <span className=' px-1 text-start ' >{item.unidadeTempo}</span>
         {/* - ${item.unidadeTempo} */}
         </div>,
      }));


      return options;
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 99999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 99999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise a garantia'
      loadOptions={loadOptionsGarantia}
      value={inputOption}
      onChange={(selectedOption: ISelectOption | null) => {
        // Verifica se selectedOption não é null antes de definir o estado
        if (selectedOption) {
          handleOptions!(selectedOption);
        } else {
          handleOptions!(null);
        }
      }}
      onKeyDown={onKeyDown}
      cacheOptions
      defaultOptions
    />
  );
};

export default AsyncSelectGarantia;
