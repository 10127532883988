import {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {IPendenciaCompra, ISelectOption} from '../../../../../../shared';
import {getPendenciaCompra} from '../../../../listagem_requests/listagem_requests';

const usePendenciasManager = () => {
  const [pendencias, setPendencias] = useState<IPendenciaCompra[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [isFitered, setIsFiltered] = useState<boolean>(false);
  const [selectedPendencias, setSelectedPendencias] = useState<
    {idCliente: number | string; quantidadePendencia: number; produto: string}[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState<{key: string; direction: 'asc' | 'desc'} | null>(
    null
  );

  const [isSelectAsyncClient, setIsSelectAsyncClient] = useState<ISelectOption | null>(null);
  const [isSelectAsyncProduct, setIsSelectAsyncProduct] = useState<ISelectOption | null>(null);
  const [isSelectAsyncFornecedor, setIsSelectAsyncFornecedor] = useState<ISelectOption | null>(
    null
  );
  const [isDataPedido, setIsDataPedido] = useState<Date | null>(null);
  const [isDataPendencia, setIsDataPendencia] = useState<Date | null>(null);
  const [idPedidoG2, setIdPedidoG2] = useState(null);

  const loadPendencias = async () => {
    setLoading(true);
    setLoadingSearch(true);
    try {
      const response = await getPendenciaCompra();
      const content = response.data;
      setPendencias(content);
      setLoading(false);

      setIsSelectAsyncClient(null);
      setIsSelectAsyncProduct(null);
      setIdPedidoG2(null);
      setIsDataPedido(null);
      setIsDataPendencia(null);

      setLoadingSearch(false);
      setIsFiltered(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setLoading(false);
      setLoadingSearch(false);
    }
  };

  const loadPendenciasDia = async () => {
    setLoading(true);
    setLoadingSearch(true);
    try {
      const hoje = new Date();
      const response = await getPendenciaCompra(
        null,
        null,
        null,
        null,
        hoje.toISOString().split('T')[0],
        null
      );
      const content = response.data;
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Nenhuma pendência encontrada no dia de hoje',
          text: 'A busca não retornou nenhuma pendência. Por favor, tente outro filtro.',
        });
        setPendencias(content);
        setIsDataPendencia(hoje);
      } else {
        setPendencias(content);
        setIsDataPendencia(hoje);
        setIsFiltered(true);
      }
      setLoading(false);
      setLoadingSearch(false);
    } catch (error: any) {
      let errorMessage = 'Ocorreu um erro ao buscar as pendências.';

      if (error.response) {
        const {status, data} = error.response;
        errorMessage = `Erro ${status}: ${data.message || 'Falha na requisição'}`;
      } else if (error.request) {
        errorMessage = 'Não foi possível conectar ao servidor. Verifique sua conexão.';
      }

      Swal.fire({
        icon: 'error',
        title: 'Erro na busca',
        text: errorMessage,
      });

      setPendencias([]);
    }
  };

  const fetchPendencias = async () => {
    setLoading(true);
    setLoadingSearch(true);
    const formatDateTime = (date: Date): string => {
      return date.toISOString().split('T')[0];
    };

    try {
      const response = await getPendenciaCompra(
        isSelectAsyncClient?.value,
        isSelectAsyncProduct?.value,
        isSelectAsyncFornecedor?.value,
        isDataPedido ? formatDateTime(isDataPedido) : null,
        isDataPendencia ? formatDateTime(isDataPendencia) : null,
        idPedidoG2 ? idPedidoG2 : null
      );

      const content = response.data;

      if (Array.isArray(content) && content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Nenhum resultado encontrado',
          text: 'A busca não retornou nenhuma pendência. Por favor, tente com outros filtros.',
        });
        setPendencias([]);
      } else {
        setPendencias(content);
      }

      setIsFiltered(true);
    } catch (error: any) {
      let errorMessage = 'Ocorreu um erro ao buscar as pendências.';

      if (error.response) {
        const {status, data} = error.response;
        errorMessage = `Erro ${status}: ${data.message || 'Falha na requisição'}`;
      } else if (error.request) {
        errorMessage = 'Não foi possível conectar ao servidor. Verifique sua conexão.';
      }

      Swal.fire({
        icon: 'error',
        title: 'Erro na busca',
        text: errorMessage,
      });

      setPendencias([]);
    } finally {
      setLoading(false);
      setLoadingSearch(false);
    }
  };
  const clearFilter = () => {
    setIsSelectAsyncClient(null);
    setIsSelectAsyncProduct(null);
    setIsSelectAsyncFornecedor(null);
    setIdPedidoG2(null);
    setIsDataPedido(null);
    setIsDataPendencia(null);
    setIsFiltered(false);
    loadPendencias();
  };
  const handlePendenciaSelection = (pendencia: IPendenciaCompra) => {
    const updatedPendencias = pendencias.map((p) =>
      p.idCliente === pendencia.idCliente ? {...p, selected: !p.selected} : p
    );
    setPendencias(updatedPendencias);
    setSelectedPendencias((prev) => {
      if (pendencia.selected) {
        return prev.filter((p) => p.idCliente !== pendencia.idCliente);
      } else {
        return [
          ...prev,
          {
            idCliente: pendencia.idCliente,
            produto: pendencia.produto,
            quantidadePendencia: pendencia.quantidadePendencia,
          },
        ];
      }
    });
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setPendencias(pendencias.map((p) => ({...p, selected: newSelectAll})));
    setSelectedPendencias(
      newSelectAll
        ? pendencias.map((p) => ({
            idCliente: p.idCliente,
            produto: p.produto,
            quantidadePendencia: p.quantidadePendencia,
          }))
        : []
    );
  };

  const sortData = (key: string) => {
    if (key === 'selected') return;
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({key, direction});
    const sortedData = [...pendencias].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setPendencias(sortedData);
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    fetchPendencias();
  };

  const handleAsyncSelectClient = (option: ISelectOption) => {
    setIsSelectAsyncClient(option);
  };

  const handleAsyncSelect = (option: ISelectOption) => {
    setIsSelectAsyncProduct(option);
  };

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsSelectAsyncFornecedor(option);
  };

  useEffect(() => {
    if (isFitered) {
      fetchPendencias();
    }
    loadPendenciasDia();
  }, []);

  return {
    pendencias,
    loading,
    idPedidoG2,
    selectedPendencias,
    isSelectAsyncProduct,
    isSelectAsyncClient,
    isSelectAsyncFornecedor,
    isDataPedido,
    isDataPendencia,
    selectAll,
    sortConfig,
    loadingSearch,
    clearFilter,
    setIdPedidoG2,
    handlePendenciaSelection,
    handleSelectAll,
    setIsDataPedido,
    setIsDataPendencia,
    setIsSelectAsyncClient,
    handleAsyncSelectFornecedor,
    setIsSelectAsyncFornecedor,
    setIsSelectAsyncProduct,
    handleAsyncSelectClient,
    handleAsyncSelect,
    handleSearch,
    sortData,
  };
};

export default usePendenciasManager;
