import {Column, useTable} from 'react-table';
import {
  buildQueryParams,
  DropDownButtons,
  formatarData,
  formatDateTimeWithHours,
  formatDateToDDMMYYYY,
  HttpClient,
  IGarantiaDevolucao,
  IGerarGarantiaProdutos,
  IPrevendaGarantia,
  Modal,
  PDFView,
  showAlert,
  SysledTable,
  useModal,
} from '../../../../shared';
import {Badge, ModalProps, Modal as ModalBootstrap, Button} from 'react-bootstrap';
import AvatarConsultor from '../../../../shared/core/adapters/avatar-consultor/avatar-consultor';
import {useEffect, useMemo, useState} from 'react';
import ModalSolicitarGarantiaDevolucao from './modal-solicitar-garantia-devolucao/ModalSolicitarGarantiaDevolucao';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {formatStatus} from './formsPedido';
import {
  imprimirPDFGarantiaDevolucao,
  imprimirPrevenda,
  visualizarPDFGarantiaDevolucao,
  visualizarPDFSeparacao,
} from '../../listagem_requests/listagem_requests';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  idItem?: string | number;
};

const ModalSolicitarGarantia: React.FC<Props> = ({isOpen, onHide, idItem}) => {
  const http = new HttpClient();

  const resumoSkuColumns: Column<IGerarGarantiaProdutos>[] = [
    {
      Header: 'ID',
      accessor: 'idProduto',
      Cell: ({value}) => <div className='text-end'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      Cell: ({value, row}) => (
        <div
          className='text-start'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: '400px',
            minWidth: '200px',
          }}
        >
          {`${row.original.idPrevenda ? `${row.original.idProduto} -` : ''} ${
            row.original.produto
          }`}
        </div>
      ),
    },
    {
      Header: 'Status/Tipo da Garantia/Devolução',
      accessor: 'prevendaStatusGarantiaDto',
      Cell: ({value, row}) => (
        <div
          className='text-start'
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
          {row.original.prevendaStatusGarantiaDto ? (
            <>
              <Badge style={{color: 'white', marginTop: '5px'}} bg='success'>
                {row.original.prevendaStatusGarantiaDto.titulo}
                {row.original.prevendaStatusGarantiaDto.descricao
                  ? ` - ${row.original.prevendaStatusGarantiaDto.descricao}`
                  : ''}
              </Badge>

              <Badge style={{color: 'white', marginTop: '5px'}} bg='warning'>
                {row.original.prevendaTipoGarantiaDto?.titulo}
                {row.original.prevendaTipoGarantiaDto?.descricao
                  ? ` - ${row.original.prevendaTipoGarantiaDto.descricao}`
                  : ''}
              </Badge>
            </>
          ) : (
            '...'
          )}

          {row.original.tipoGarantiaDevolucao === 'DEVOLUCAO' ? (
            <Badge style={{color: 'white', marginTop: '5px'}} bg='info'>
              {'DEVOLUÇÃO'}
            </Badge>
          ) : null}

          {row.original.tipoGarantiaDevolucao === 'GARANTIA' ? (
            <Badge style={{color: 'white', marginTop: '5px'}} bg='info'>
              {'GARANTIA'}
            </Badge>
          ) : null}
        </div>
      ),
    },

    {
      Header: 'Quantidade Solicitada',
      accessor: 'qtdGarantiaDevolucao',
      Cell: ({value}) => (
        <div
          className='text-center d-flex justify-content-center'
          style={{marginRight: '10px', maxWidth: '20px'}}
        >
          {value ? value : '...'}
        </div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({value, row}) => {
        const buttons = [
          {
            classIcon: 'bi bi-shield-check',
            title: 'Solicitar Garantia',
            handleFunction: () =>
              handleOpenSolicitarGarantiaDevolucaoModal(
                0,
                row.original.tipoGarantiaDevolucao,
                Number(row.original.id),
                row.original.prevendaStatusGarantiaDto?.id,
                row.original.prevendaTipoGarantiaDto?.id,
                row.original.qtdSolicitada
              ),
          },
          {
            classIcon: 'bi bi-arrow-clockwise',
            title: 'Solicitar Devolução',
            handleFunction: () =>
              handleOpenSolicitarGarantiaDevolucaoModal(
                1,
                row.original.tipoGarantiaDevolucao,
                Number(row.original.id),
                row.original.prevendaStatusGarantiaDto?.id,
                row.original.prevendaTipoGarantiaDto?.id,
                row.original.qtdSolicitada
              ),
          },
        ];

        if (row.original.prevendaStatusGarantiaDto) {
          buttons.push({
            classIcon: 'bi bi-x-circle',
            title: 'Remover Solicitação',
            handleFunction: () =>
              handleOpenSolicitarGarantiaDevolucaoRemoveModal(
                row.original.id!,
                row.original.tipoGarantiaDevolucao!
              ),
          });
        }

        return (
          <div
            className='text-center d-flex justify-content-around align-items-center'
            style={{gap: '5px'}}
          >
            <DropDownButtons titleButton='Opções' buttons={buttons} />

            <DropDownButtons
              isDisabled={!row.original.prevendaStatusGarantiaDto ? true : false}
              isPdf
              buttons={[
                {
                  title: 'Visualizar PDF de Solicitação',
                  handleFunction: () => {
                    visualizarPDFGarantiaDevolucao(
                      setModalShowPDFViewer,
                      row.original.id,
                      setIsLoading,
                      setIsPdfUrl,
                      setIsIdSeparacao
                    );
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  const resumoPropostaPedidoColumns: Column<IPrevendaGarantia>[] = [
    {
      Header: 'Id do Pedido',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataemissao',
      Cell: ({value, row}) => (
        <div
          className='text-center'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {
            <>
              {formatDateTimeWithHours(value)}

              {row.original.isAmbientado && (
                <div className='mt-2'>
                  <Badge style={{color: 'white'}} bg='info'>
                    AMBIENTADO
                  </Badge>
                </div>
              )}
              {row.original.fretestatus !== 0 && (
                <div className='mt-2'>
                  <Badge style={{color: 'white'}} bg='warning'>
                    FRETE
                  </Badge>
                </div>
              )}
            </>
          }
        </div>
      ),
    },
    {
      Header: 'Cliente',
      accessor: 'nomeCliente',
      Cell: ({value, row}) => (
        <>
          {
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {row.original.clienteInfo.nome}

              {row.original.isSincronizado ?? (
                <Badge style={{color: 'white'}} bg='info'>
                  Sincronizado
                </Badge>
              )}
            </div>
          }
        </>
      ),
    },
    {
      Header: 'Total Geral',
      accessor: 'total',
      Cell: ({value, row}) => (
        <>
          {
            <div
              className='text-start'
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {value
                ? `R$ ${Number(value).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : 'R$ 0,00'}
              {<Badge style={{color: 'white'}}>Mg: {row.original.margemProposta}%</Badge>}
            </div>
          }
        </>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value, row}) => (
        <div className='text-center d-flex justify-content-start' style={{marginRight: '10px'}}>
          {formatStatus(row.original.status) || 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Consultor',
      accessor: 'idFuncionario',
      Cell: ({value, row}) => (
        <div className='text-center d-flex justify-content-center' style={{marginRight: '10px'}}>
          <AvatarConsultor
            idFuncionario={row.original.consultorid}
            funcionario={row.original.nomeConsultor}
          />
        </div>
      ),
    },
  ];
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();

  const [
    isSolicitarGarantiaDevolucaoModal,
    openSolicitarGarantiaDevolucaoModal,
    closeGerarGarantiaDevolucaoModal,
  ] = useModal();
  const [isQtdSolicitada, setIsQtdSolicitada] = useState<number | string | undefined>();
  const [isTipoSolicitacao, setIsTipoSolicitacao] = useState<number>(1);
  const [isTipoSolicitacaoRequest, setIsTipoSolicitacaoRequest] = useState<string | undefined>();
  const [isPrevendaData, setIsPrevendaData] = useState<IPrevendaGarantia[]>([]);
  const [isGarantiaProdutosData, setIsGarantiaProdutosData] = useState<IGerarGarantiaProdutos[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTableProducts, setIsLoadingTableProducts] = useState<boolean>(false);
  const [isLoadingTablePrevenda, setIsLoadingTablePrevenda] = useState<boolean>(false);
  const [isIdItem, setIsIdItem] = useState<number>();
  const [isIdStatusGarantiaDevolucao, setIsIdStatusGarantiaDevolucao] = useState<number>();
  const [isIdTipoGarantiaDevolucao, setIsIdTipoGarantiaDevolucao] = useState<number>();

  const handleOpenSolicitarGarantiaDevolucaoModal = (
    isTipoSolicitacao: number,
    isTipoSolicitacaoRequest?: string,
    id?: number,
    idStatusGarantiaDevolucao?: number,
    idTipoGarantiaDevolucao?: number,
    qtdSolcitada?: number
  ) => {
    openSolicitarGarantiaDevolucaoModal();
    setIsTipoSolicitacao(isTipoSolicitacao);
    setIsIdItem(id);

    setIsIdStatusGarantiaDevolucao(idStatusGarantiaDevolucao);
    setIsIdTipoGarantiaDevolucao(idTipoGarantiaDevolucao);
    setIsTipoSolicitacaoRequest(isTipoSolicitacaoRequest);
    setIsQtdSolicitada(qtdSolcitada);
  };

  const handleOpenSolicitarGarantiaDevolucaoRemoveModal = async (
    id: number,
    tipoSolicitacao: string
  ) => {
    const result = await Swal.fire({
      title: 'Confirmação:',
      text: `Deseja realmente cancelar a solcitação de ${tipoSolicitacao}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Cancelar Solicitação de ${tipoSolicitacao}`,
      cancelButtonText: `Fechar`,
    });

    setIsIdItem(id);

    if (result.isConfirmed) {
      handleSubmitRemove(tipoSolicitacao, id);
    }
  };

  const handleSubmitRemove = async (isTipoSolicitacao: string, id: number) => {
    setIsLoading(true);
    try {
      const urlBuild = `prevendas/produtos-prazos/atualizar-garantia-devolucao`;

      await http.request<IGarantiaDevolucao, IGarantiaDevolucao>({
        method: 'PUT',
        url: urlBuild,
        data: {
          idStatusGarantia: null,
          idTipoGarantia: null,
          tipoGarantiaDevolucao: null,
          qtdGarantiaDevolucao: null,
        },
        id: id,
      });

      showAlert(
        'success',
        isTipoSolicitacao === 'DEVOLUCAO'
          ? 'Devolução Removida com Sucesso'
          : 'Garantia Removida com Sucesso'
      );

      loadProdutosPrevenda();
      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);
      setIsLoading(false);
    }
  };

  const data = useMemo(() => isPrevendaData, [isPrevendaData]);
  const columns = useMemo(() => resumoPropostaPedidoColumns, []);

  const data2 = useMemo(() => isGarantiaProdutosData, [isGarantiaProdutosData]);
  const columns2 = useMemo(() => resumoSkuColumns, []);

  const {} = useTable({
    columns,
    data,
  });

  const {} = useTable({
    columns: columns2,
    data: data2,
  });

  const onClose = () => {
    onHide!();
  };

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Dados do Pedido',
    actions: [],
    onHide: onClose,
    size: 'xl',
  };

  const loadPrevenda = async () => {
    setIsLoadingTablePrevenda(true);

    try {
      const params = {
        isPedido: true,
      };

      const finalUrl = `prevendas/buscar/pedido/${idItem}${buildQueryParams(params)}`;

      const response = await http.request<IPrevendaGarantia>({
        method: 'GET',
        url: finalUrl,
      });

      const {data} = response;

      setIsPrevendaData([data as IPrevendaGarantia]);

      setIsLoadingTablePrevenda(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingTablePrevenda(false);
    }
  };

  const loadProdutosPrevenda = async () => {
    setIsLoading(true);
    setIsLoadingTableProducts(true);
    try {
      const params = {
        idPrevenda: idItem,
      };

      const finalUrl = `prevendas/produtos-prazos/listar-garantia-devolucao${buildQueryParams(
        params
      )}`;

      const response = await http.request<IGerarGarantiaProdutos>({
        method: 'GET',
        url: finalUrl,
      });

      const {content} = response.data as any;

      setIsGarantiaProdutosData(content);

      setIsLoadingTableProducts(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingTableProducts(false);
    }
  };

  useEffect(() => {
    if (idItem) {
      loadPrevenda();
      loadProdutosPrevenda();
    }
  }, [idItem, isOpen]);

  return (
    <>
      <Modal {...modalConfigs}>
        {isLoadingTablePrevenda ? (
          <Skeleton count={1} height={50} />
        ) : (
          <SysledTable
            columns={resumoPropostaPedidoColumns}
            data={isPrevendaData}
            message='SEM DADOS DO PEDIDO'
          />
        )}

        {isLoadingTableProducts ? (
          <Skeleton count={10} height={25} />
        ) : (
          <SysledTable
            columns={resumoSkuColumns}
            data={isGarantiaProdutosData}
            message='SEM PRODUTOS'
            maxHeight='350px'
          />
        )}
      </Modal>

      <ModalSolicitarGarantiaDevolucao
        isOpen={isSolicitarGarantiaDevolucaoModal}
        onHide={closeGerarGarantiaDevolucaoModal}
        tipoSolicitacaoHandle={isTipoSolicitacao}
        tipoSolicitacaoRequest={isTipoSolicitacaoRequest}
        qtdSolicitada={isQtdSolicitada}
        idItem={isIdItem}
        idStatusGarantiaDevolucao={isIdStatusGarantiaDevolucao}
        idTipoGarantiaDevolucao={isIdTipoGarantiaDevolucao}
        handleRequest={() => loadProdutosPrevenda()}
      />

      <ModalBootstrap
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <ModalBootstrap.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFGarantiaDevolucao(isIdSeparacao, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</ModalBootstrap.Body>
      </ModalBootstrap>
    </>
  );
};

export default ModalSolicitarGarantia;
