import {sub} from 'date-fns';

export const items = [
  {
    id: 1,
    name: 'Dashboards',
    icon: 'ki-element-7',
    permissions: ['dashboardGerencial'],
    subMenu: [
      {
        id: 1,
        name: 'Comercial',
        permissions: ['dashboardGerencial'],
        subMnu: [
          {
            id: 1,
            name: 'Relatório de Vendas',
            permissions: ['dashboardGerencial'],
            path: '/relatorios/dashboards/comercial/relatorio-vendas',
          },
        ],
      },
    ],
  },

  {
    id: 2,
    name: 'Estoques',
    icon: 'ki-cube-2',
    permissions: ['estoqueConsulta'],
    subMenu: [
      {
        id: 1,
        name: 'Estoque (WMS)',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/consulta-estoque',
      },

      {
        id: 2,
        name: 'Estoques',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoques',
      },
      {
        id: 3,
        name: 'Tipo de Estoque',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-tipos',
      },
      {
        id: 4,
        name: 'Estoque Localização',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-localizacao',
      },
      {
        id: 5,
        name: 'Tipos de Localização',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-tipo-localizacao',
      },
      {
        id: 6,
        name: 'Estoque Transferencia',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-transferencias',
      },
      {
        id: 7,
        name: 'Estoque Inventario',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-inventario',
      },
      {
        id: 8,
        name: 'Motivos do Inventario',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-motivo-inventario',
      },
      {
        id: 9,
        name: 'Estoque Entradas',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-entradas',
      },
      {
        id: 10,
        name: 'Tipos de Movimentos',
        permissions: ['estoqueConsulta'],
        path: '/consultas/estoques/listagem-estoque-tipo-movimento',
      },
    ],
  },

  {
    id: 3,
    name: 'Promoções/Encartes',
    icon: 'ki-discount',
    permissions: ['encartePromocional'],
    subMenu: [
      {
        id: 1,
        name: 'Criar Nova Promoção/Encarte',
        permissions: ['encartePromocional'],
        path: '/promocao-cadastro',
      },
      {
        id: 2,
        name: 'Pesquisar Promoção/Encarte',
        permissions: ['encartePromocional'],
        path: '/promocao-listagem',
      },
    ],
  },

  {
    id: 4,
    name: 'Vendas',
    icon: 'ki-shop',
    permissions: ['clientes', 'preVendas', 'parceiros', 'pendencia_venda'],
    subMenu: [
      {
        id: 1,
        name: 'Clientes',
        permissions: ['clientes'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['clientes'], path: '/cadastro-clientes'},
          {id: 2, name: 'Listar', permissions: ['clientes'], path: '/listagem-clientes'},
        ],
      },
      {
        id: 2,
        name: 'Propostas Comerciais',
        permissions: ['preVendas'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['preVendas'], path: '/propostas-comerciais'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['preVendas'],
            path: '/listagem-propostas-comerciais',
          },
        ],
      },
      {
        id: 3,
        name: 'Pedidos',
        permissions: ['preVendas'],
        subMnu: [{id: 1, name: 'Listar', permissions: ['preVendas'], path: '/listagem-pedidos'}],
      },
      {
        id: 5,
        name: 'Tipos de venda',
        path: '/vendas/tipos-venda',
        permissions: ['pendencia_venda'],
        subMnu: [
          {
            id: 1,
            name: 'Adicionar',
            permissions: ['pendencia_venda'],
            path: '/cadastro-tipo-venda',
          },
          {id: 2, name: 'Listar', permissions: ['pendencia_venda'], path: '/listagem-tipo-venda'},
        ],
      },
    ],
  },

  {
    id: 5,
    name: 'Logistica',
    icon: 'ki-delivery-2',
    permissions: ['logisticaExpedicao', 'logisticaFluxo', 'logisticaEndereco'],
    subMenu: [
      {
        id: 1,
        name: 'Expedição',
        permissions: ['logisticaExpedicao'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaExpedicao'],
            path: '/logistica-expedicao-listagem',
          },
        ],
      },
      {
        id: 2,
        name: 'Fluxo Logístico',
        permissions: ['logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-fluxo-listagem',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-fluxo-cadastro',
          },
        ],
      },
      {
        id: 3,
        name: 'Fluxo Separação',
        permissions: ['logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['logisticaFluxo'],
            path: '/logistica-separacao-listagem',
          },
          {id: 2, name: 'Adicionar', permissions: ['logisticaFluxo'], path: '/logistica-separacao'},
        ],
      },
      {
        id: 4,
        name: 'Endereços',
        permissions: ['pedido_compras', 'logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['pedido_compras', 'logisticaFluxo'],
            path: '/logistica-endereco-listagem',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['pedido_compras', 'logisticaFluxo'],
            path: '/logistica-endereco',
          },
        ],
      },
    ],
  },

  {
    id: 6,
    name: 'Workflow',
    icon: 'ki-element-1',
    subMenu: [
      {
        id: 1,
        name: 'Pendências',
        subMnu: [{id: 1, name: 'Listar', path: '/workflow-pendencia-listagem'}],
      },
    ],
  },

  {
    id: 7,
    name: 'Projetos',
    icon: 'bi-rocket-takeoff',
    permissions: ['projetos'],
    subMenu: [
          {
            id: 1,
            name: 'Projetos',
            permissions: ['projetos'],
            path: '/projetos',
          },
          {
            id: 2,
            name: 'Status do projeto',
            permissions: ['statusProjeto'],
            path: '/status-do-projeto',
          },
          {
            id: 3,
            name: 'Status do pagamento do financeiro',
            permissions: ['statusPagamento'],
            path: '/status-pagamento',
          },
          {
            id: 4,
            name: 'Fatura do projeto',
            permissions: ['faturaProjeto'],
            path: '/fatura-do-projeto',
          },
          
          {
            id: 5,
            name: 'Orcamento do projeto',
            permissions: ['orcamentoProjeto'],
            path: '/orcamentos-do-projeto',
          },
          
          {
            id: 6,
            name: 'Custos do projeto',
            permissions: ['custoProjeto'],
            path: '/custos-do-projeto',
          },
          
          {
            id: 7,
            name: 'Tipo de custo do projeto',
            permissions: ['tipoCusto'],
            path: '/tipos-de-custo',
          },
        ],
  },
  {
    id: 8,
    name: 'Suprimentos',
    icon: 'ki-delivery-2',
    permissions: ['produtos', 'suprimentos', 'pedido_compras', 'pendencia_venda', 'fornecedores'],
    subMenu: [
      {
        id: 1,
        name: 'Pedido de Compra',
        permissions: ['pedido_compras'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['pedido_compras'], path: '/pedido-compras'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['pedido_compras'],
            path: '/pedido-compras-listagem',
          },
        ],
      },
      {
        id: 2,
        name: 'Pendência de Compra',
        permissions: ['pendencia_venda'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['pendencia_venda'],
            path: '/listagem-pendencia-compra',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['pendencia_venda'],
            path: '/pendencia-compra',
          },
        ],
      },
      {id: 3, name: 'Ressuprimentos', permissions: ['suprimentos']},
      {
        id: 6,
        name: 'Marcas',
        permissions: ['marcas'],
        path: '/sistema/marcas',
      },
    ],
  },

  {
    id: 9,
    name: 'Produção',
    icon: 'ki-briefcase',
    permissions: ['producao'],
    subMenu: [
      {id: 1, name: 'Ordem de Produção', permissions: ['producao']},
      {id: 2, name: 'Chão de Fábrica', permissions: ['producao']},
      {id: 3, name: 'Etapas', permissions: ['producao']},
      {id: 4, name: 'Eficiência de Produção', permissions: ['producao']},
      {id: 5, name: 'Parque/Capacidade', permissions: ['producao']},
    ],
  },

  {
    id: 10,
    name: 'Financeiro',
    icon: 'ki-dollar',
    permissions: [
      'financeiro',
      'contasReceber',
      'aprovadores',
      'bancos',
      'cobranca',
      'credito',
      'pagamento',
      'contasPagar',
    ],
    subMenu: [
      {
        id: 1,
        name: 'Contas a Pagar',
        permissions: ['contasPagar'],
        path: '/listagem-contas-a-pagar',
      },
      // {id: 2, name: 'Contas a Receber', permissions: ['financeiro'], path: '/listagem-grupo-despesas'},
      {
        id: 2,
        name: 'Grupo de Despesas',
        permissions: ['grupo_despesas'],
        path: '/listagem-grupo-despesas',
      },
      {id: 3, name: 'Aprovadores', permissions: ['aprovadores'], path: '/listagem-aprovadores'},
      {id: 4, name: 'Bancos', permissions: ['bancos'], path: '/listagem-bancos'},
      {
        id: 5,
        name: 'Formas de Pagamento',
        permissions: ['financeiro', 'pagamento'],
        subMnu: [
          {
            id: 1,
            name: 'Adicionar',
            permissions: ['financeiro', 'pagamento'],
            path: '/cadastro-formas-de-pagamento',
          },
          {
            id: 2,
            name: 'Listar',
            permissions: ['financeiro', 'pagamento'],
            path: '/listagem-formas-de-pagamento',
          },
        ],
      },
      {
        id: 6,
        name: 'Condicoes de Pagamento',
        permissions: ['financeiro', 'pagamento'],
        subMnu: [
          {
            id: 1,
            name: 'Adicionar',
            permissions: ['financeiro', 'pagamento'],
            path: '/cadastro-Condicoes-de-pagamento',
          },
          {
            id: 2,
            name: 'Listar',
            permissions: ['financeiro', 'pagamento'],
            path: '/listagem-Condicoes-de-pagamento',
          },
        ],
      },
      {
        id: 7,
        name: 'Contas a Receber',
        permissions: ['financeiro', 'contasReceber'],
        path: '/contas-a-receber',
      },
      {
        id: 8,
        name: 'Análise de Crédito',
        permissions: ['financeiro', 'cobranca'],
        subMnu: [
          {
            id: 1,
            name: 'Status da Análise',
            permissions: ['financeiro', 'cobranca', 'status-analise'],
            path: '/cobranca/status-analise',
          },
          {
            id: 2,
            name: 'Crédito',
            permissions: ['financeiro', 'cobranca', 'credito'],
            path: '/cobranca/credito',
          },
          {
            id: 2,
            name: 'Análise de Crédito',
            permissions: ['financeiro', 'cobranca', 'analise-credito'],
            path: '/cobranca/analise-credito',
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: 'Cadastros',
    icon: 'bi bi-plus',
    permissions: [
      'empresas',
      'colaboradores',
      'clientes',
      'produtos',
      'fornecedores',
      'parceiros',
      'pedido_compras',
      'logisticaFluxo',
    ],
    subMenu: [
      {
        id: 1,
        name: 'Clientes',
        permissions: ['clientes'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['clientes'], path: '/cadastro-clientes'},
          {id: 2, name: 'Listar', permissions: ['clientes'], path: '/listagem-clientes'},
        ],
      },
      {
        id: 2,
        name: 'Produtos',
        permissions: ['produtos'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['produtos'], path: '/produto-cadastro'},
          {id: 2, name: 'Listar', permissions: ['produtos'], path: '/produto-listagem'},
        ],
      },
      {
        id: 3,
        name: 'Categorização produtos',
        permissions: ['produtos'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['produtos'], path: '/produto-categorizacao'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['produtos'],
            path: '/produto-categorizacao-listagem',
          },
        ],
      },
      {
        id: 4,
        name: 'Fornecedor',
        permissions: ['fornecedores'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['fornecedores'], path: '/cadastro-fornecedor'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['fornecedores'],
            path: '/listagem-fornecedor',
          },
        ],
      },
      {
        id: 5,
        name: 'Colaboradores',
        permissions: ['colaboradores'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['colaboradores'], path: '/cadastro-colaborador'},
          {
            id: 2,
            name: 'Listar',
            permissions: ['colaboradores'],
            path: '/listagem-colaborador',
          },
        ],
      },
      {
        id: 6,
        name: 'Parceiros',
        path: '/vendas/profissionais',
        permissions: ['parceiros'],
        subMnu: [
          {id: 1, name: 'Adicionar', permissions: ['parceiros'], path: '/cadastro-parceiros'},
          {id: 2, name: 'Listar', permissions: ['parceiros'], path: '/listagem-parceiros'},
        ],
      },
      {
        id: 7,
        name: 'Endereços',
        permissions: ['pedido_compras', 'logisticaFluxo'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['pedido_compras', 'logisticaFluxo'],
            path: '/logistica-endereco-listagem',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['pedido_compras', 'logisticaFluxo'],
            path: '/logistica-endereco',
          },
        ],
      },
      {
        id: 8,
        name: 'Empresas',
        permissions: ['empresas'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['empresas'],
            path: '/listagem-empresas',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['empresas'],
            path: '/cadastro-empresas',
          },
        ],
      },
      {
        id: 9,
        name: 'Compradores',
        permissions: ['compradores'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['compradores'],
            path: '/compradores',
          },
        ],
      },
      {
        id: 10,
        name: 'Tipo de Origem',
        permissions: ['tipoOrigem'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['tipoOrigem'],
            path: '/tipo-origem',
          },
        ],
      },
      {
        id: 10,
        name: 'Linha de Produto',
        permissions: ['produtos'],
        path: '/sistema/linha-produto',
      },
      {
        id: 11,
        name: 'Empreendimentos',
        permissions: ['listagemEmpreendimentos'],
        subMnu: [
          {
            id: 1,
            name: 'Listar',
            permissions: ['listagemEmpreendimentos'],
            path: '/empreendimentos',
          },
          {
            id: 2,
            name: 'Adicionar',
            permissions: ['cadastroEmpreendimentos'],
            path: '/empreendimentos-cadastro',
          },
        ],
      },
    ],
  },
  // { id: 11, name: 'Sistema', icon: 'ki-setting-4', path: '/sistema' }
];
