import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {IProdutoKitGet, smoothScrollToBottom, useModal} from '../../../../shared';
import Swal from 'sweetalert2';
import {useEffect, useMemo, useState} from 'react';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getKitProduto} from '../../../listagem/listagem_requests/listagem_requests';
import ModalKit from './ModalKit';
import {useParams} from 'react-router-dom';
import {format} from 'date-fns';

const KitProduto: React.FC = () => {
  const {id} = useParams();
  const kitProdutoColumns: Column<IProdutoKitGet>[] = [
    {
      Header: 'ID do Produto',
      accessor: 'idProdutoPrincipal',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Produto Kit',
      accessor: 'produtoKit',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Criado Por',
      accessor: 'nomeUsuarioCriacao',
    },
    {
      Header: 'Criado em',
      accessor: 'dataCriacao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = String(value).split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Editado Por',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <span>{value ? value : '...'}</span>,
    },
    {
      Header: 'Editado em',
      accessor: 'dataAlteracao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = String(value).split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original)}
          type='button'
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const [isKitProdutoData, setIsKitProdutoData] = useState<IProdutoKitGet[]>([]);

  const data = useMemo(() => isKitProdutoData, [isKitProdutoData]);
  const columns = useMemo(() => kitProdutoColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const [isLoading, setIsLoading] = useState<boolean>();
  const [kitProduto, setKitProduto] = useState<IProdutoKitGet | null>();
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const getKitProdutoData = async ({page = 0}: {page: number}) => {
    setIsLoading(true);
    try {
      const response = await getKitProduto(page, id || '');

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      setIsKitProdutoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getKitProdutoData({page: isPage});
  }, [isPage, id]);

  const [isKitProdutoModal, openMarcasModal, closeKitProdutoModal] = useModal();

  const openModal = (isEdit: boolean, data?: IProdutoKitGet) => {
    if (data) {
      setKitProduto(data);
    }
    setIsEdit(isEdit);
    openMarcasModal();
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (kit: IProdutoKitGet) => {
    openModal(true, kit);
  };

  return (
    <>
      <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
        <div
          className='col-sm-4 mx-5'
          style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
        >
          <button
            className='btn btn-success '
            type='button'
            style={{fontSize: '14px'}}
            onClick={() => {
              openModal(false);
            }}
          >
            Cadastrar Kit de Produto
          </button>
        </div>
      </div>

      <div className='card card-flush mb-4'>
        <div className='card-body' style={{padding: '0px', alignItems: 'stretch'}}>
          <div className='table-responsive'>
            <table
              id='kt_table_formularios'
              className='table table-row-dashed gs-0 gy-3 my-0'
              {...getTableProps()}
            >
              <thead>
                <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                  {headers.map((column: ColumnInstance<IProdutoKitGet>, index: number) => (
                    <th
                      key={column.id}
                      className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<IProdutoKitGet>, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        showEditButton
                        onEdit={(id) => handleOnEdit(id)}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Produto sem kits
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {isHasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button type='button' className='btn btn-primary m-5' onClick={loadMore}>
                  {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalKit
        isOpen={isKitProdutoModal}
        onHide={closeKitProdutoModal}
        isEdit={isEdit}
        data={kitProduto}
        loadMarcas={getKitProdutoData}
      ></ModalKit>
    </>
  );
};

export default KitProduto;
