import {Column, ColumnInstance, useTable} from 'react-table';
import {IExpedicaoSysled, ISeparacoes} from '../../../shared/domain-types/models/expedicao-sysled';
import {CardInfo} from './components';
import * as S from './expedicao-sysled-styles';
import {useEffect, useMemo, useState} from 'react';
import {CustomHeaderColumn} from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {
  getExpedicaoSysled,
  imprimirPDFExpedicaoAmbientadoByArray,
  imprimirPDFExpedicaoAmbientadoById,
  imprimirPDFExpedicaoRomaneioByArray,
  imprimirPDFExpedicaoRomaneioByIdPedido,
  syncExpedicaoSysledVuupt,
  visualizarPDFExpedicaoAmbientadoByArray,
  visualizarPDFExpedicaoAmbientadoByIdPedido,
  visualizarPDFExpedicaoRomaneioByArray,
  visualizarPDFExpedicaoRomaneioByIdPedido,
} from '../../listagem/listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import TableComponentExpedicao from './components/table-frete/table-frete';
import {Button, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip} from 'react-bootstrap';
import {
  AsyncSelectCliente,
  AsyncSelectConsultor,
  AsyncSelectPrevenda,
  DescriptionComponent,
  formatarData,
  formatDateByFilter,
  ILogisticaFluxo,
  ISelectOption,
  ITipoEntrega,
  PDFView,
  useModal,
} from '../../../shared';
import ItensDetailsModal from './components/separacao-itens-modal/separacao-itens-modal';
import {DatePicker, DateRangePicker} from 'rsuite';
import {useFormik} from 'formik';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import {addDays, set, subDays} from 'date-fns';
import {startOfMonth} from 'date-fns';
import {endOfMonth} from 'date-fns';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {LiaSitemapSolid} from 'react-icons/lia';

export const SysledExpedicao: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [modalShowPDFViewer2, setModalShowPDFViewer2] = useState(false);
  const [modalShowPDFViewer3, setModalShowPDFViewer3] = useState(false);
  const [modalShowPDFViewer4, setModalShowPDFViewer4] = useState(false);

  const separacoesColumn: Column<ISeparacoes>[] = [
    {
      Header: <LiaSitemapSolid size={20} style={{marginLeft: '5px'}} />,
      accessor: 'idTipoEntrega',
      Cell: ({row}) => (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-edit-${row.original.idTipoEntrega}`}>
              Observe os itens da separação
            </Tooltip>
          }
        >
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            <button
              className='btn btn-primary btn-sm'
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleModalDetails(row.original.seqEntrega!)}
            >
              <i style={{padding: 0}} className='bi bi-eye'></i>
            </button>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      Header: 'Sequência de Entrega',
      accessor: 'seqEntrega',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Tipo de Entrega',
      accessor: 'tipoEntrega',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Fluxo Logístico',
      accessor: 'fluxoLogistico',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Acões',
      accessor: 'bairroEntrega',
      Cell: ({value, row}) => (
        <div className='d-flex flex-column align-items-center form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            id={`checkbox-${row.original.seqEntrega}`}
            onChange={() =>
              handleCheckboxChange(
                Number(row.original.seqEntrega),
                Number(row.original.idPedido),
                row.original.fluxoLogistico
              )
            }
            checked={selectedItems.some(
              (item) =>
                item.seqEntrega === row.original.seqEntrega &&
                item.idPedido === row.original.idPedido &&
                item.fluxoLogistico === row.original.fluxoLogistico
            )}
          />
        </div>
      ),
    },
  ];

  const [isExpedicaoSysledData, setIsExpedicaoSysledData] = useState<IExpedicaoSysled[]>([]);

  type ICheck = {
    seqEntrega: number;
    idPedido: number;
    fluxoLogistico: string;
  };

  const [isHasMore, setIsHasMore] = useState<boolean>(false);
  const [isPage, setIsPage] = useState<number>(0);
  const [isItensModal, openItensModal, closeItensModal] = useModal();
  const [isIdSeqEntrega, setIsIdSeqEntrega] = useState<number | string>();
  const [isAsyncFluxoLogistico, setIsAsyncFluxoLogistico] = useState<ISelectOption | null>();
  const [isAsyncTipoEntrega, setIsAsyncTipoEntrega] = useState<ISelectOption | null>();
  const [selectedItems, setSelectedItems] = useState<ICheck[]>([]); // Vetor para armazenar IDs dos itens selecionados.
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingClearFilter, setIsLoadingClearFilter] = useState<boolean>(false);

  const initialValues = {
    seqEntrega: undefined,
  };

  const [isAsyncCliente, setIsAsyncClient] = useState<ISelectOption | null>();
  const [isAsyncConsultor, setIsAsyncConsultor] = useState<ISelectOption | null>();
  const [isAsyncPedido, setIsAsyncPedido] = useState<ISelectOption | null>();
  const [isIdPedido, setIsIdPedido] = useState<string | number>();
  const [isLoadingSyncVuupt, setIsLoadingSyncVuupt] = useState<boolean>(false);

  const [valueDateRangeEmissao, setValueDateRangeEmissao] = useState<DateRange | null>([
    new Date(),
    new Date(),
  ]);
  const [valueDateRangeAcordada, setValueDateRangeAcordada] = useState<DateRange | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      setIsLoadingSearch(true);
      try {
        console.log('sim');
        const response = await getExpedicaoSysled({
          ...values,
          idCliente: isAsyncCliente?.value,
          idConsultor: isAsyncConsultor?.value,
          idPedido: isAsyncPedido?.value,
          idFluxoLogistido: isAsyncFluxoLogistico?.value,
          idTipoEntrega: isAsyncTipoEntrega?.value,
          pedidoDataEmissaoInicio: valueDateRangeEmissao
            ? formatDateByFilter(valueDateRangeEmissao[0])
            : null,
          pedidoDataEmissaoFim: valueDateRangeEmissao
            ? formatDateByFilter(valueDateRangeEmissao[1])
            : null,
          pedidoDataAcordadoInicio: valueDateRangeAcordada
            ? formatDateByFilter(valueDateRangeAcordada[0])
            : null,
          pedidoDataAcordadoFim: valueDateRangeAcordada
            ? formatDateByFilter(valueDateRangeAcordada[1])
            : null,
        });

        const {data} = response;

        setIsExpedicaoSysledData(data);

        setSubmitting(false);
        setIsLoading(false);
        setIsLoadingSearch(false);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });

        setSubmitting(false);
        setIsLoading(false);
        setIsLoadingSearch(false);
      }
    },
  });

  const handleCheckboxChange = (seqEntrega: number, idPedido: number, fluxoLogistico: string) => {
    setSelectedItems((prevSelected) => {
      // Verifica se o idPedido do novo item é diferente dos já selecionados
      const isDifferentIdPedido = prevSelected.some((item) => item.idPedido !== idPedido);

      if (isDifferentIdPedido) {
        // Substitui todos os itens com o novo item
        return [{seqEntrega, idPedido, fluxoLogistico}];
      } else {
        // Alterna a seleção do seqEntrega no mesmo idPedido
        const alreadySelected = prevSelected.some((item) => item.seqEntrega === seqEntrega);

        if (alreadySelected) {
          // Remove o item se já estiver selecionado
          return prevSelected.filter((item) => item.seqEntrega !== seqEntrega);
        } else {
          // Adiciona o novo item
          return [...prevSelected, {seqEntrega, idPedido, fluxoLogistico}];
        }
      }
    });
  };

  const handleModalDetails = (id: number) => {
    openItensModal();

    setIsIdSeqEntrega(id);
  };

  const handleSyncSeparacoes = async () => {
    try {
      const confirmResult = await Swal.fire({
        title: 'Tem certeza?',
        text: 'Deseja sincronizar o pedido com o Vuupt?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, sincronizar!',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      if (confirmResult.isConfirmed) {
        setIsLoadingSyncVuupt(true);

        await syncExpedicaoSysledVuupt(selectedItems);

        setIsLoadingSyncVuupt(false);

        Swal.fire({
          icon: 'success',
          title: 'Pedido sincronizado com o Vuupt com sucesso!',
          timer: 3000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: 'Erro ao sincronizar',
        html: data.map((item) => `<p>${item.mensagem}</p>`).join(''),
        showCancelButton: false,
      });
    }
  };

  const loadSysledExpedicao = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingClearFilter(true);
    try {
      const response = await getExpedicaoSysled({
        pedidoDataEmissaoInicio: valueDateRangeEmissao
          ? formatDateByFilter(valueDateRangeEmissao[0])
          : null,
        pedidoDataEmissaoFim: valueDateRangeEmissao
          ? formatDateByFilter(valueDateRangeEmissao[1])
          : null,
      });

      const {data} = response;

      const updatedData = data.map((pedido) => ({
        ...pedido,
        separacoes: pedido.separacoes.map((separacao) => ({
          ...separacao,
          idPedido: pedido.idPedido, // Injetando idPedido em cada separação
        })),
      }));

      setIsExpedicaoSysledData(updatedData);

      setIsLoading(false);
      setIsLoadingClearFilter(false);
    } catch (errors: any) {
      const {data, status} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      setIsLoadingClearFilter(false);
    }
  };

  const loadLogisticaFluxo = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ILogisticaFluxo) => ({
        value: item.id,
        label: item.titulo,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadTipoEntrega = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: ITipoEntrega) => ({
        value: item.idTipoEntrega,
        label: item.tipoEntrega,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const clearFilter = () => {
    loadSysledExpedicao();

    setIsAsyncClient(null);
    setIsAsyncConsultor(null);
    setIsAsyncPedido(null);
    formik.setValues({
      seqEntrega: undefined,
    });
    setIsAsyncFluxoLogistico(null);
    setIsAsyncTipoEntrega(null);
    setValueDateRangeEmissao([new Date(), new Date()]);
    setValueDateRangeAcordada(null);
  };

  useEffect(() => {
    loadSysledExpedicao(isPage);
  }, [isPage]);

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const handleAsyncSelectCliente = (option: ISelectOption) => {
    setIsAsyncClient(option);
  };

  const handleAsyncSelectConsultor = (option: ISelectOption) => {
    setIsAsyncConsultor(option);
  };

  const handleAsyncSelectPedido = (option: ISelectOption) => {
    setIsAsyncPedido(option);
  };

  const handleDateRangeChangeEmissao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangeEmissao(null);
    } else {
      setValueDateRangeEmissao([value[0], value[1]]);
    }
  };

  const handleDateRangeChangeAcordada = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangeAcordada(null);
    } else {
      setValueDateRangeAcordada([value[0], value[1]]);
    }
  };

  const handlePdfRomaneio = (idPedido: number) => {
    if (selectedItems.length > 0) {
      visualizarPDFExpedicaoRomaneioByArray(
        setModalShowPDFViewer2,
        selectedItems,
        setIsLoading,
        setIsPdfUrl
      );
    } else {
      visualizarPDFExpedicaoRomaneioByIdPedido(
        setModalShowPDFViewer,
        idPedido,
        setIsLoading,
        setIsPdfUrl,
        setIsIdPedido
      );
    }
  };

  const handlePdfAmbientado = (idPedido: number) => {
    if (selectedItems.length > 0) {
      visualizarPDFExpedicaoAmbientadoByArray(
        setModalShowPDFViewer4,
        selectedItems,
        setIsLoading,
        setIsPdfUrl
      );
    } else {
      visualizarPDFExpedicaoAmbientadoByIdPedido(
        setModalShowPDFViewer3,
        idPedido,
        setIsLoading,
        setIsPdfUrl,
        setIsIdPedido
      );
    }
  };

  useEffect(() => {
    console.log('selected itens: ', selectedItems);
  }, [selectedItems]);

  return (
    <>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <Row className='col-12'>
            <Col className='col-sm-6'>
              <label htmlFor='' className='form-label'>
                Cliente:
              </label>
              <AsyncSelectCliente
                handleOptions={(option) => handleAsyncSelectCliente(option!)}
                inputOption={isAsyncCliente}
                isSelectDOM={false}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsAsyncClient(null);
                  }
                }}
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Consultor:
              </label>
              <AsyncSelectConsultor
                handleOptions={(option) => handleAsyncSelectConsultor(option!)}
                inputOption={isAsyncConsultor}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsAsyncConsultor(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <label htmlFor='' className='form-label'>
              Pedido:
            </label>
            <AsyncSelectPrevenda
              handleOptions={(option) => handleAsyncSelectPedido(option!)}
              inputOption={isAsyncPedido}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsAsyncPedido(null);
                }
              }}
            />
          </Row>
          <Row className='mt-4'>
            <Col md='4' className='col-12 mt-4'>
              <label className='form-label'>Sequencia de Entrega:</label>
              <input
                type='number'
                placeholder='Digite o arquivo do formulário'
                {...formik.getFieldProps('seqEntrega')}
                className={`form-control ${
                  formik.touched.seqEntrega && formik.errors.seqEntrega ? 'is-invalid' : ''
                }`}
              />
            </Col>
            <Col md='4' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Selecione o Fluxo Logístico:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione o Fluxo Logístico'
                loadOptions={loadLogisticaFluxo}
                value={isAsyncFluxoLogistico}
                isClearable
                onChange={(selectedOption: ISelectOption | null) => {
                  // Verifica se selectedOption não é null antes de definir o estado
                  if (selectedOption) {
                    setIsAsyncFluxoLogistico(selectedOption);
                  } else {
                    setIsAsyncFluxoLogistico(null); // Define como null caso a opção seja anulada
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>
            <Col md='4' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Selecione o Tipo de Entrega:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                isClearable
                classNamePrefix='react-select'
                placeholder='Selecione o tipo de Entrega'
                loadOptions={loadTipoEntrega}
                value={isAsyncTipoEntrega}
                onChange={(selectedOption: ISelectOption | null) => {
                  // Verifica se selectedOption não é null antes de definir o estado
                  if (selectedOption) {
                    setIsAsyncTipoEntrega(selectedOption);
                  } else {
                    setIsAsyncTipoEntrega(null); // Define como null caso a opção seja anulada
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col md='6' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Data de Emissão de Pedido:
              </label>

              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeEmissao}
                value={valueDateRangeEmissao}
                format='dd-MM-yyyy'
                ranges={[
                  {label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
                  {label: 'Hoje', value: [new Date(), new Date()]},
                  {label: 'Último 7 dias', value: [subDays(new Date(), 6), new Date()]},
                  {label: 'Este mês', value: [startOfMonth(new Date()), endOfMonth(new Date())]},
                ]}
                placeholder='Data'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '5px',
                  backgroundColor: '#fff',
                }}
              />
            </Col>
            <Col md='6' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Data Acordada com o Cliente:
              </label>

              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeAcordada}
                value={valueDateRangeAcordada}
                format='dd-MM-yyyy'
                ranges={[
                  {label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)]},
                  {label: 'Hoje', value: [new Date(), new Date()]},
                  {label: 'Último 7 dias', value: [subDays(new Date(), 6), new Date()]},
                  {label: 'Este mês', value: [startOfMonth(new Date()), endOfMonth(new Date())]},
                ]}
                placeholder='Data'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '5px',
                  backgroundColor: '#fff',
                }}
              />
            </Col>
          </Row>
          <div className='col-12 mt-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFilter()}
                className='btn btn-success form-control'
              >
                {isLoadingClearFilter ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-success form-control'
              >
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      {isExpedicaoSysledData.length > 0 ? (
        <>
          {isLoading ? (
            <Skeleton count={3} height={400} />
          ) : (
            <>
              <S.ContainerTitle>
                <div className='title-left'>
                  <h4>Resumo do Pedido</h4>
                </div>
                <div className='title-right'>
                  <h4>Detalhes do Pedido</h4>
                </div>
              </S.ContainerTitle>

              {isExpedicaoSysledData.map((item, index) => {
                return (
                  <S.ContainerExpedicaoInfo key={index}>
                    <div className='container-left'>
                      <CardInfo
                        clienteEmail={item.clienteInfo.clienteEmail ?? '-'}
                        clienteNumero={item.clienteInfo.clienteNumero ?? '-'}
                        clienteRazao={item.clienteInfo.clienteRazao ?? '-'}
                        consultor={item.consultorInfo.consultor ?? '-'}
                        consultorContato={item.consultorInfo.consultorContato ?? '-'}
                        idConsultor={item.consultorInfo.idConsultor ?? '-'}
                        bairroEntrega={item.bairroEntrega ?? '-'}
                        cepEntrega={item.cepEntrega ?? '-'}
                        cidadeEntrega={item.cidadeEntrega ?? '-'}
                        enderecoEntrega={item.cidadeEntrega ?? '-'}
                        numeroEntrega={item.numeroEntrega ?? '-'}
                        pontoReferencia1Entrega={item.pontoReferencia1Entrega ?? '-'}
                        pontoReferencia2Entrega={item.pontoReferencia2Entrega ?? '-'}
                        responsavel1Entrega={item.responsavel1Entrega ?? '-'}
                        responsavel2Entrega={item.responsavel2Entrega ?? '-'}
                        idG2={item.idG2 ?? '-'}
                        ufEntrega={item.ufEntrega ?? '-'}
                        clienteBairro={item.clienteInfo.clienteBairro ?? '-'}
                        clienteCidade={item.clienteInfo.clienteCidade ?? '-'}
                        clienteComplemento={item.clienteInfo.clienteComplemento ?? '-'}
                        clienteEndereco={item.clienteInfo.clienteEndereco ?? '-'}
                        clienteEstado={item.clienteInfo.clienteEstado ?? '-'}
                        clienteFantasia={item.clienteInfo.clienteFantasia ?? '-'}
                        clienteTelefone={item.clienteInfo.clienteTelefone ?? '-'}
                        observacaoEntrega={item.observacaoEntrega ?? '-'}
                        idPedido={item.idPedido ?? '-'}
                        pedidoDataEmissao={item.pedidoDataEmissao}
                        pedidoDataAcordado={item.pedidoDataAcordado}
                        isAmbientado={item.isAmbientado}
                        key={index}
                      />

                      <S.ContainerActions>
                        <div className='dropdown'>
                          <button
                            className='btn btn-sm btn-primary dropdown-toggle'
                            data-bs-toggle='dropdown'
                          >
                            <i
                              className='bi bi-file-earmark-arrow-down'
                              style={{fontSize: '14px'}}
                            ></i>
                            PDF
                          </button>
                          <ul className='dropdown-menu'>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => handlePdfRomaneio(item.idPedido)}
                              >
                                ROMANEIO
                              </button>
                            </li>
                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => handlePdfAmbientado(item.idPedido)}
                              >
                                AMBIENTADO
                              </button>
                            </li>
                          </ul>
                        </div>

                        <button
                          className='btn btn-success btn-sm'
                          aria-label='Sincronizar com Vuupt'
                          onClick={() => handleSyncSeparacoes()}
                          disabled={
                            !selectedItems.some(
                              (selectedItem) =>
                                selectedItem.idPedido === item.idPedido &&
                                selectedItems.every((item) => item.fluxoLogistico === 'Armazenado')
                            )
                          }
                        >
                          {isLoadingSyncVuupt ? (
                            <Spinner animation='border' size='sm' />
                          ) : (
                            <>
                              <i className='bi bi-arrow-down-up' style={{fontSize: '14px'}} />
                              &nbsp; Sincronizar
                            </>
                          )}
                        </button>
                      </S.ContainerActions>
                    </div>

                    <div className='container-right'>
                      <DescriptionComponent description='Separações' isSub />

                      <TableComponentExpedicao
                        data={item.separacoes} // Passe os dados específicos aqui
                        columns={separacoesColumn}
                      />
                    </div>
                  </S.ContainerExpedicaoInfo>
                );
              })}
            </>
          )}
        </>
      ) : (
        <S.ContainerNotFound>
          <h3>SEM DADOS</h3>
        </S.ContainerNotFound>
      )}

      <ItensDetailsModal
        isOpen={isItensModal}
        onHide={closeItensModal}
        idSeqEntrega={isIdSeqEntrega}
      />

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFExpedicaoRomaneioByIdPedido(isIdPedido, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer2}
        onHide={() => setModalShowPDFViewer2(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFExpedicaoRomaneioByArray(selectedItems, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer2(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer3}
        onHide={() => setModalShowPDFViewer3(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFExpedicaoAmbientadoById(isIdPedido, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer3(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer4}
        onHide={() => setModalShowPDFViewer4(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFExpedicaoAmbientadoByArray(selectedItems, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer4(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default SysledExpedicao;
