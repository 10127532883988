import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import {
  AsyncSelectContasReceber,
  IContasReceberParcelas,
  IContasReceberParcelasRequest,
  ISelectOption,
  smoothScrollToBottom,
  useModal,
} from '../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getContasReceberParcelasRequest} from '../../../listagem/listagem_requests/listagem_requests';
import ModalMarcas from './components/modal/ModalContasReceberParcelas';
import {Link, useParams} from 'react-router-dom';
import {addDays, endOfMonth, format, startOfMonth, subDays} from 'date-fns';
import DateRangePicker, {DateRange} from 'rsuite/esm/DateRangePicker';
import {formatInTimeZone, toZonedTime} from 'date-fns-tz';
import RangePriceBetween from '../../components/RangePriceBetween';

const ListagemContasReceberParcelas = () => {
  const {id} = useParams();

  const contasReceberParcelasColumns: Column<IContasReceberParcelas>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Conta a Receber',
      accessor: 'idContaReceber',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({value}) => (
        <div className='text-start'>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value)}
        </div>
      ),
    },
    {
      Header: 'Data de Vencimento',
      accessor: 'dataVencimento',
      Cell: ({value}) => {
        if (value) {
          try {
            const formattedDate = format(new Date(value), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataEmissao',
      Cell: ({value}) => {
        if (value) {
          try {
            const formattedDate = format(new Date(value), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => (
        <div className='text-start'>
          {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='dropdown position-static text-start'>
          <button className='btn btn-success btn-sm dropdown-toggle' data-bs-toggle='dropdown'>
            <i className=''>Opções</i>
          </button>
          <ul
            className='dropdown-menu'
            style={{
              position: 'absolute',
              zIndex: 1050,
              top: '100%',
              left: '0',
            }}
          >
            <li>
              <button
                className='dropdown-item'
                type='button'
                onClick={() => {
                  handleOnEdit(row.original!);
                }}
              >
                <i className='bi-pencil'> </i>
                <span className='mx-2'>Editar</span>
              </button>
            </li>

            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link
                to={`/contas-a-receber/parcelas/${id}/pagamentos/${row.original.id}`}
                className='dropdown-item'
                type='Button'
              >
                <i className='bi-box-arrow-up-right'></i>
                <span className='mx-2'>Gerenciar Pagamentos</span>
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const [contasReceberParcelasData, setContasReceberParcelasData] = useState<
    IContasReceberParcelas[]
  >([]);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [contasReceberParcelasSelected, setContasReceberParcelasSelected] =
    useState<IContasReceberParcelas | null>(null);
  const [isSelectAsyncContaReceber, setIsSelectAsyncContaReceber] = useState<ISelectOption | null>(
    null
  );
  const [valueDateRangeEmissao, setValueDateRangeEmissao] = useState<DateRange | null>(null);
  const [valueDateRangeVencimento, setValueDateRangeVencimento] = useState<DateRange | null>(null);

  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);

  const data = useMemo(() => contasReceberParcelasData, [contasReceberParcelasData]);
  const columns = useMemo(() => contasReceberParcelasColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: IContasReceberParcelasRequest = {
    dataEmissaoFim: '',
    dataEmissaoInicio: '',
    dataVencimentoFim: '',
    dataVencimentoInicio: '',
    idContaReceber: isSelectAsyncContaReceber ? isSelectAsyncContaReceber.value : id,
    valorFim: '',
    valorInicio: '',
    descricao: '',
    status: 'ATIVO',
  };

  function convertToLocalTimeZone(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd');
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      try {
        let dataEmissaoInicio = '';
        let dataEmissaoFim = '';
        let dataVencimentoInicio = '';
        let dataVencimentoFim = '';
        if (valueDateRangeEmissao && valueDateRangeEmissao.length > 0) {
          dataEmissaoInicio = convertToLocalTimeZone(valueDateRangeEmissao[0]);
          dataEmissaoFim = convertToLocalTimeZone(valueDateRangeEmissao[1]);
        }
        if (valueDateRangeVencimento && valueDateRangeVencimento.length > 0) {
          dataVencimentoInicio = convertToLocalTimeZone(valueDateRangeVencimento[0]);
          dataVencimentoFim = convertToLocalTimeZone(valueDateRangeVencimento[1]);
        }
        const response = await getContasReceberParcelasRequest(
          {
            dataEmissaoFim,
            dataEmissaoInicio,
            dataVencimentoFim,
            dataVencimentoInicio,
            idContaReceber: isSelectAsyncContaReceber ? isSelectAsyncContaReceber.value : '',
            valorFim: maxPrice ? maxPrice : '',
            valorInicio: minPrice ? minPrice : '',
            descricao: values.descricao,
            status: values.status,
          },
          0
        );

        const {content, totalPages} = response.data;
        setIsLoading(false);
        setSubmitting(false);

        if (response.status === 200) {
          setIsHasMore(isPage < totalPages - 1);

          setContasReceberParcelasData(content);
        } else if (response.status === 204) {
          setIsHasMore(false);
          Swal.fire({
            icon: 'info',
            title: `Não existe registros de parcelas para essa pesquisa`,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (data: IContasReceberParcelas) => {
    openModal(true, data);
  };

  //Get de categorias por modelo
  const getContasReceberParcelas = async ({
    page = 0,
    reset = false,
  }: {
    page: number;
    reset?: boolean;
  }) => {
    setIsLoading(true);

    try {
      const response = await getContasReceberParcelasRequest(initialValues, page);

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      if (reset) {
        setContasReceberParcelasData(content);
      } else {
        setContasReceberParcelasData((prev) => (isPage === 0 ? content : [...prev, ...content]));
      }

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        const {data} = errors.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  const [
    isContasReceberParcelasModal,
    openContasReceberParcelasModal,
    closeContasReceberParcelasModal,
  ] = useModal();

  const openModal = (isEdit: boolean, data?: IContasReceberParcelas) => {
    if (data) {
      setContasReceberParcelasSelected(data);
    }
    setIsEdit(isEdit);
    openContasReceberParcelasModal();
  };
  const clearFiltros = () => {
    formik.resetForm();
    setValueDateRangeEmissao(null);
    setValueDateRangeVencimento(null);
    setMinPrice(null);
    setMaxPrice(null);
    setIsPage(0);
    setIsHasMore(false);
    getContasReceberParcelas({page: 0, reset: true});
  };

  useEffect(() => {
    getContasReceberParcelas({page: isPage});
  }, [isPage]);

  useEffect(() => {
    if (id) {
      setIsSelectAsyncContaReceber({
        value: Number(id),
        label: '',
      });
    }
  }, [id]);

  const handleDateRangeChangeEmissao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangeEmissao(null);
    } else {
      setValueDateRangeEmissao([value[0], value[1]]);
    }
  };

  const handleDateRangeChangeVencimento = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangeVencimento(null);
    } else {
      setValueDateRangeVencimento([value[0], value[1]]);
    }
  };

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Contas a Receber Parcelas
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Conta a Receber Parcelas
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <div className='mt-10'>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='12' className='col-12'>
              <label className='form-label'>Conta a Receber:</label>
              <AsyncSelectContasReceber
                handleOptions={(option) => setIsSelectAsyncContaReceber(option!)}
                inputOption={isSelectAsyncContaReceber}
                isDisabled
                onKeyDown={(e: any) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncContaReceber(null);
                  }
                }}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Valor:</label>
              <RangePriceBetween
                setMaxPrice={setMaxPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                minPrice={minPrice}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Data de vencimento:</label>
              {/* <DatePicker
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='Data de vencimento'
                onChange={(date) => setIsDataVencimento(date)}
                value={isDataVencimento}
                menuStyle={{zIndex: 1000000}}
              /> */}
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeVencimento}
                value={valueDateRangeVencimento}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de vencimento'
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Data de emissão:</label>
              {/* <DatePicker
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='Data de emissão'
                onChange={(date) => setIsDataEmissao(date)}
                value={isDataEmissao}
                menuStyle={{zIndex: 1000000}}
              /> */}
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeEmissao}
                value={valueDateRangeEmissao}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de emissão'
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição'
                {...formik.getFieldProps('descricao')}
                className={`form-control`}
              />
            </Col>
          </RowBootstrap>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link className='btn mx-5 px-10 btn-info ' to='/contas-a-receber'>
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* TABELA */}
      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IContasReceberParcelas>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IContasReceberParcelas>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={() => handleOnEdit(row.original)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
      <ModalMarcas
        isOpen={isContasReceberParcelasModal}
        onHide={closeContasReceberParcelasModal}
        isEdit={isEdit}
        data={contasReceberParcelasSelected}
        loader={getContasReceberParcelas}
      ></ModalMarcas>
    </div>
  );
};

export default ListagemContasReceberParcelas;
