import React from 'react';
import Swal from 'sweetalert2';
import FilterComponent from './components/FilterComponent';
import TableComponent from './components/TableComponent';
import usePendenciasManager from './hooks/usePendenciasManager';
import { id } from 'date-fns/locale';

const ResumoCliente = () => {
  const {
    pendencias,
    loading,
    selectedPendencias,
    isDataPedido,
    isDataPendencia,
    isSelectAsyncProduct,
    isSelectAsyncClient,
    isSelectAsyncFornecedor,
    selectAll,
    sortConfig,
    idPedidoG2,
    loadingSearch,
    clearFilter,
    setIdPedidoG2,
    handlePendenciaSelection,
    handleAsyncSelectFornecedor,
    handleSelectAll,
    setIsDataPedido,
    setIsDataPendencia,
    setIsSelectAsyncProduct,
    setIsSelectAsyncClient,
    setIsSelectAsyncFornecedor,
    handleAsyncSelectClient,
    handleAsyncSelect,
    handleSearch,
    sortData,
  } = usePendenciasManager();

  const handleAdicionarPedidoCompra = () => {
    Swal.fire({
      title: 'Tem certeza que deseja adiconar um pedido de compra a essa pendência?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#47be7d',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Pendências selecionadas:', selectedPendencias);
        Swal.fire({
          title: 'Pedido de compra adicionado com sucesso!',
          text: 'Seu pedido de compra foi adicionado.',
          icon: 'success',
        });
      }
    });
  };

  const columns = [
    // {
    //   label: (
    //     <div className='d-flex flex-column align-items-center form-check'>
    //       <input
    //         style={{cursor: 'pointer'}}
    //         className='form-check-input'
    //         type='checkbox'
    //         checked={selectAll}
    //         onChange={handleSelectAll}
    //       />
    //     </div>
    //   ),
    //   accessor: 'selected',
    // },
    {label: 'ID Cliente', accessor: 'idCliente'},
    {label: 'Cliente', accessor: 'clienteRazao'},
    {label: 'Data Pedido', accessor: 'dataPedido'},
    {label: 'Data Pendência', accessor: 'dataPendencia'},
    {label: 'Quantidade Pendência', accessor: 'quantidadePendencia'},
    {label: 'Quantidade Compra', accessor: 'quantidadeCompra'},
    {label: 'Id Pedido', accessor: 'idPedidoG2'},
    {label: 'Quantidade Pedidos Compra', accessor: 'quantidadePedidoCompra'},
    {label: 'Id Produto', accessor: 'idProdutoG2'},
    {label: 'Produto', accessor: 'produto'},
    {label: 'Status', accessor: 'status'},
    {label: 'Tempo de espera', accessor: 'compraLeadTime'},
    {label: 'Fornecedor', accessor: 'fornecedorRazao'},
    {label: 'Comprador', accessor: 'comprador'},
    {label: 'Consultor', accessor: 'consultor'},
  ];

  return (
    <>
      <div>
        <FilterComponent
          isSelectAsyncProduct={isSelectAsyncProduct}
          isSelectAsyncClient={isSelectAsyncClient}
          isSelectAsyncFornecedor={isSelectAsyncFornecedor}
          setIsDataPedido={setIsDataPedido}
          setIsDataPendencia={setIsDataPendencia}
          setIsSelectAsyncProduct={setIsSelectAsyncProduct}
          setIsSelectAsyncClient={setIsSelectAsyncClient}
          setIsSelectAsyncFornecedor={setIsSelectAsyncFornecedor}
          handleAsyncSelectFornecedor={handleAsyncSelectFornecedor}
          handleAsyncSelectClient={handleAsyncSelectClient}
          handleAsyncSelect={handleAsyncSelect}
          handleSearch={handleSearch}
          idPedidoG2={idPedidoG2}
          setIdPedidoG2={setIdPedidoG2}
          isDataPedido={isDataPedido}
          isDataPendencia={isDataPendencia}
          loadingSearch={loadingSearch}
          loading={loading}
          clearFilter={clearFilter}
        />
        <div className='mb-6 mt-20' style={{height: '50px'}}>
          <div>
            <div className='d-grid d-md-flex justify-content-md-end'>
              <button
                style={{display:'none'}}
                type='button'
                className='btn btn-success'
                disabled={selectedPendencias.length === 0}
                onClick={handleAdicionarPedidoCompra}
              >
                <i className='bi bi-plus'></i> Criar Pedido de Compra
              </button>
            </div>
          </div>
        </div>
        <TableComponent
          columns={columns}
          pendencias={pendencias}
          loading={loading}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          handlePendenciaSelection={handlePendenciaSelection}
          sortConfig={sortConfig}
          sortData={sortData}
        />
        <div className='row mt-5 justify-content-end'>
          <h5 className='col-2'>Total de pendências: {pendencias.length}</h5>
        </div>
      </div>
    </>
  );
};

export default ResumoCliente;
