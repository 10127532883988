import { Col, Row as RowBootstrap, Spinner } from "react-bootstrap"
import { DescriptionComponent, IHeaders } from "../../../shared"
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, Column, Row } from 'react-table';

import { CustomRow } from "../../../modules/apps/user-management/users-list/table/columns/CustomRow";
import { getListagemSuprimentos, getUnidadesMedidas } from "../listagem_requests/listagem_requests";
import Swal from "sweetalert2";
import { useFormik } from "formik";

const ListagemSuprimento = () => {
    const formularioColumns: Column<IHeaders>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            Cell: ({ value }) => <div className='text-end'>{value}</div>,
        },

        {
            Header: 'Título',
            accessor: 'titulo',
            Cell: ({ value }) => <div className='text-start'>{value}</div>,
        },
        {
            Header: 'Descrição',
            accessor: 'descricao',
            Cell: ({ value }) => (
                <div className='text-start'>
                    {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
                </div>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => <div className='text-start'>{value}</div>,
        },
        {
            Header: 'Ações',

            Cell: ({ row }) => (
                <div className='text-start'>
                    <button
                        onClick={() => handleOnEdit(row.original.id!)}
                        className='btn btn-success btn-sm bi bi-pencil'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Editar'
                    />
                </div>
            ),
        },
    ];

    const navigate = useNavigate();

    const [isSuprimentoData, setIsSuprimentoData] = useState<IHeaders[]>([]);
    const [isHasMore, setIsHasMore] = useState<boolean>(true);
    const [isPage, setIsPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

    const data = useMemo(() => isSuprimentoData, [isSuprimentoData]);
    const columns = useMemo(() => formularioColumns, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const initialValues = {
        titulo: '',
        descricao: '',
        status: 'ATIVO',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setIsLoadingSearch(true);
            try {
                const response = await getListagemSuprimentos({
                    titulo: values.titulo,
                    descricao: values.descricao,
                    page: isPage,
                    status: values.status,
                });

                const { content } = response.data;
                setIsLoadingSearch(false);
                setSubmitting(false);

                setIsSuprimentoData(content);
            } catch (errors: any) {
                const { error } = errors;

                if (error.response) {
                    const status = error.response.status;

                    if (status === 401 || 403) {
                        Swal.fire({
                            icon: 'info',
                            title: 'Por questões de segurança, por favor faça login novamente',
                            confirmButtonText: 'Ok',
                        }).then(() => {
                            window.open('/auth', '_blank');
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: error,
                            confirmButtonText: 'Ok',
                        });
                    }
                }

                setSubmitting(false);
                setIsLoadingSearch(false);
            }
        },
    });

    const loadMore = () => {
        if (isHasMore && !isLoading) {
            setIsPage((prevPage) => prevPage + 1);
        }
    };

    const handleOnEdit = (id: string | number) => {
        navigate(`/editar-suprimentos/${id}`);
    };

    const getSuprementosData = async (page = 0) => {
        try {
            const response = await getListagemSuprimentos({
                titulo: '',
                descricao: '',
                page,
                status: 'ATIVO',
            });

            const { content, totalPages } = response.data;

            setIsSuprimentoData((prev) => (isPage === 0 ? content : [...prev, ...content]));
            setIsHasMore(isPage < totalPages - 1);


        } catch (errors: any) {
            const { error } = errors;

            const status = error.response.status;

            if (status === 401 || 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                });
            }

            Swal.fire({
                icon: 'error',
                title: error,
                confirmButtonText: 'Ok',
            });

            setIsLoading(false);
        }
    };

    useEffect(() => {
        getSuprementosData(isPage)
    }, [isPage]
    )
    return (
        <>
            <DescriptionComponent
                description='Listagem de Status'
                withLink
                buttonTitle='+ Novo Status'
                redirectTo='/suprimentos-cadastro'
            />
            <div className='mt-10'>
                <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
                <form
                    onSubmit={formik.handleSubmit}
                    className='form-control-solid row mb-5'>
                    <RowBootstrap className='col-12 mb-4'>
                        <Col className='col-sm-6'>
                            <label className='form-label'>Titulo:</label>
                            <input
                                type='text'
                                placeholder='Digite o titulo do status'
                                className='form-control'
                                {...formik.getFieldProps('titulo')}

                            />
                        </Col>
                        <Col className='col-sm-6'>
                            <div>
                                <label className='form-label'>Status:</label>
                                <select className='form-select' {...formik.getFieldProps('status')}>

                                    <option value='ATIVO'>ATIVO</option>
                                    <option value='INATIVO'>INATIVO</option>
                                </select>
                            </div>
                        </Col>
                    </RowBootstrap>
                    <RowBootstrap className='col-12'>
                        <Col className='col-6-sm'>
                            <label className='form-label'>Descrição:</label>
                            <textarea
                                placeholder='Digite a descrição do status'
                                {...formik.getFieldProps('descricao')}

                                className='form-control'
                            />
                        </Col>
                    </RowBootstrap>
                    <div className='row mt-6'>
                        <div className='col-sm-2 mb-6'>
                            <button type='submit' className='btn btn-success form-control'>
                                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='table-responsive'>
                <table
                    id='kt_table_formularios'
                    className='table table-hover table-striped table-rounded table-row-bordered border'
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {headerGroup.headers.map((column, index: number) => (
                                    <th {...column.getHeaderProps()


                                    } className={index === 0 ? 'text-end' : 'text-start'}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className='text-gray-600 fw-bold'
                        {...getTableBodyProps()}
                    >
                        {rows.length > 0 ? (
                            rows.map((row: Row<IHeaders>, i) => {
                                prepareRow(row);
                                return (

                                    <CustomRow
                                        row={row}
                                        key={`row-${i}-${row.id}`}
                                        showEditButton
                                        onEdit={(id) => handleOnEdit(id)}
                                    />
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        No matching records found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {isHasMore && (
                    <div className='d-flex justify-content-center align-items-center'>
                        <button className='btn btn-primary m-5' onClick={loadMore}>
                            {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default ListagemSuprimento

function setIsUnidadeMedidaData(content: any) {
    throw new Error("Function not implemented.");
}
