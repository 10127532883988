import axios from 'axios';
import {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {ProductOption} from '../../../../pages/cadastros/proposta-pedido/types/interface';
import {formatEndereco, truncateNome} from '../../../core/adapters';
import {IEmpreendimentos, ISelectOption} from '../../../domain-types';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  isClearable?: boolean;
  isDefault?: boolean;
};

const AsyncSelectStatusGarantia: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  isClearable,
  onKeyDown,
  placeholder,
  isDefault,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_URL;

  const [selectStatusGarantiaAsync, setSelectStatusGarantiaAsync] = useState<ISelectOption | null>(
    null
  );
  const [empreendimentosDetails, setEmpreendimentosDetails] = useState<IEmpreendimentos[]>();
  const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>([]);

  const loadStatusGarantia = async (inputValue: string | number) => {
    try {
      let url = `${API_URL_SERVER}/prevenda/status-garantia/listar?page=${0}&status=ATIVO&isDefault=${1}`;

      if (String(inputValue).length >= 3) {
        url += `&titulo=${inputValue}`;
      }

      const response = await axios.get(url);
      const content = response.data.content;

      const options = content.map((item) => ({
        value: item.id,
        label: `${item?.titulo} ${item?.descricao ? ` - ${item.descricao}` : ''}`,
        original: item,
      }));

      if (!inputValue) {
        setDefaultOptions(options);
      }

      return options;
    } catch (error) {
      console.error('Erro ao carregar opções do tipo de obra:', error);
      return defaultOptions;
    }
  };

  const loadStatusGarantiaById = async (id: string | number) => {
    try {
      const url = `${API_URL_SERVER}/prevenda/status-garantia/${id}`;
      const response = await axios.get(url);
      const data = response.data;

      setSelectStatusGarantiaAsync({
        value: data.id,
        label: `${data.titulo}${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções do tipo de obra:', error);
      return [];
    }
  };

  const loadOptionsStatusGarantiaDetails = async () => {
    try {
      const url = `${API_URL_SERVER}/prevenda/status-garantia/listar?page=0&size=10&status=ATIVO`;
      const response = await axios.get(url);
      setEmpreendimentosDetails(response.data.content);
    } catch (error) {
      console.error('Erro ao carregar detalhes dos empreendimentos:', error);
    }
  };

  const loadStatusGarantiaDetailsWithDefault = async () => {
    try {
      const url = `${API_URL_SERVER}/prevenda/status-garantia/listar?page=0&size=10&status=ATIVO&isDefault=${1}`;
      const response = await axios.get(url);
      const {content} = response.data;
      setEmpreendimentosDetails(response.data.content);

      if (handleOptions)
        handleOptions({
          value: content[0].id,
          label: `${content[0].titulo}  ${
            content[0].descricao ? ` - ${content[0].descricao}` : ''
          }`,
        });
    } catch (error) {
      console.error('Erro ao carregar detalhes dos empreendimentos:', error);
    }
  };

  useEffect(() => {
    if (isDefault) {
      loadStatusGarantiaDetailsWithDefault();
    } else {
      loadOptionsStatusGarantiaDetails();
    }
  }, []);

  useEffect(() => {
    if (inputOption) {
      loadStatusGarantiaById(inputOption.value);
    } else {
      setSelectStatusGarantiaAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder={placeholder ? placeholder : 'Pesquise o Status da Garantia'}
      loadOptions={loadStatusGarantia}
      defaultOptions={defaultOptions}
      isClearable={isClearable ? isClearable : false}
      value={selectStatusGarantiaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectStatusGarantiaAsync(selectedOption);
          handleOptions?.(selectedOption);
        } else {
          setSelectStatusGarantiaAsync(null);
        }
      }}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
      onMenuOpen={() => loadStatusGarantia('')}
      cacheOptions
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
    />
  );
};

export default AsyncSelectStatusGarantia;
