import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
// import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
// import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
// import { is } from "date-fns/locale";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { AsyncSelectCliente, AsyncSelectEmpresa, AsyncSelectFornecedor, AsyncSelectProjetos, Modal } from "../../../../shared";
import { da } from "date-fns/locale";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalCustosProjetos = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(false)

    const [valor, setValor] = useState<string | null>(null)
    const [valorAprovado, setValorAprovado] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [selectedTipoCusto, setSelectedTipoCusto] = useState<ClienteOption | null>(null)
    const [dataEmissao, setDataEmissao] = useState<string | null>(null);
    const [dataVencimento, setDataVencimento] = useState<string | null>(null);
    const [dataPagamento, setDataPagamento] = useState<string | null>(null);



    const cadastrarOrcamentoProjeto = async () => {
        try {
            setIsLoading(true)
            if( selectedProjeto && selectedTipoCusto && (dataEmissao !== null && dataEmissao !== '') && (valor !== null && valor !== '' && valor !== '0')) {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-custos`;

            const response = await axios.post(url, {
                status: status ? status : null,
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                idTipoCusto: selectedTipoCusto ? selectedTipoCusto.value : null,
                dataCusto: dataEmissao ?  `${dataEmissao}T17:00:52.864`  : null,
                valor: valor ? valor : null,   
                descricao: descricao ? descricao : null,
            });

            Swal.fire({
                icon: 'success',
                title: `Custo do Projeto cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                reload!()
                onClose()
            })

        }
    }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }

    const loadOptionsStatus = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamento-status/listar`;

            const response = await axios.get(url,{
                params: {status: 'ATIVO'}
            });
            const data = await response.data;
            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        }
    };

    const editarEstoqueEntrada = async () => {
        try {
            setIsLoading(true)
            if( selectedProjeto && selectedTipoCusto && (dataEmissao !== null && dataEmissao !== '') && (valor !== null && valor !== '' && valor !== '0')) {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-custos/${dataLine[0]}`;
            const response = await axios.put(url, {
                status: status ? status : null, 
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                idTipoCusto: selectedTipoCusto ? selectedTipoCusto.value : null,
                dataCusto: dataEmissao ?  `${dataEmissao}T17:00:52.864`  : null,
                valor: valor ? valor : null,   
                descricao: descricao ? descricao : null,
            });
            Swal.fire({
                icon: 'success',
                title: `Custo do Projeto editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                reload!()
                onClose()

            });

        }

    }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }

    const onClose = () => {
        clearForm()
        // clearFiltros()
        // reload!()
        onHide!();
    }
    
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedTipoCusto(null)
        setSelectedProjeto(null)
        setValor(null)
        setValorAprovado(null)
        setDataEmissao(null)
        setDataVencimento(null)
        setDataPagamento(null)
        setDescricao(null)
    }

    const submitButton = (
        <Button
            type='submit'
            variant='success'
            disabled={isConfirme===false}
            onClick={() => isEdit ? editarEstoqueEntrada() : cadastrarOrcamentoProjeto()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        const date = new Date(inputDate);
      
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0'); 
      
        return `${year}-${month}-${day}`;
      }

    // useEffect(() => {
    //     if (isEdit) {
    //         console.log(dataLine)
    //         setSelectedEmpresa({
    //             value: dataLine[1],
    //             label: '',
    //         })
    //         setSelectedFornecedor({
    //             value: dataLine[3],
    //             label: `[${dataLine[3]}] ${dataLine[4]}`,
    //         })
    //         setSelectedTipoEntidade({
    //             value: dataLine[5],
    //             label: `${dataLine[6]}`,
    //         })
    //         setNumeroDocumento(dataLine[7])
    //         setChaveNotaFiscal(dataLine[8])
    //         // setDataEntrada(formatDate(dataLine[9]))
    //         setStatus(dataLine[10])
    //     }
    // }, [dataLine])

    

    const fetchDataProjetosById = async (page = 0) => {

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-custos/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }
                console.log(response.data.content)
                setSelectedProjeto({value: data.idProjeto, label: ''})
                setValor(data.valor)
                setSelectedTipoCusto({value: data.idTipoCusto, label: data.tituloTipoCusto})
                setDataEmissao(formatDate(data.dataCusto))
                setStatus(data.status)
                setDescricao(data.descricao)
            }
        } 
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } 
    }

    useEffect(() => {
        if(isEdit){
        fetchDataProjetosById()
    }
    },[dataLine])


    useEffect(()=>{
            if( selectedProjeto && selectedTipoCusto && (dataEmissao !== null && dataEmissao !== '') && (valor !== null && valor !== '' && valor !== '0')) {
                setIsConfirme(true)}
            else{
                setIsConfirme(false)
            }
        
    },[
        selectedProjeto,valor,selectedTipoCusto,dataEmissao
    ])


    const loadOptionsProjetos= async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/listar`;

            const response = await axios.get(url,{
                params: {
                    size : 1000,
                }
            });
            const data = await response.data;
            console.log(data.id)

            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    } 
    const loadOptionsTipoCusto= async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-tipo-custo/listar`;

            const response = await axios.get(url,{
                params: {
                    size : 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data;
            console.log(data.id)

            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Custo do Projeto
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
            <form 
            // onSubmit={handleSearch}
             className='form-control-solid row gap-6 mb-5'>
                    <Row className='col-12 mt-5'>
                        <Col>
                            <label htmlFor="" className='form-label'>Projeto:</label>
                            {/* <AsyncSelect defaultOptions value={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} onChange={(option) => { setSelectedProjeto(option) }} loadOptions={loadOptionsProjetos} placeholder="Selecione o Projeto" ></AsyncSelect> */}
                            <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { setSelectedProjeto(option) }}  ></AsyncSelectProjetos>
                            {(selectedProjeto === null ) && <span className={` form-label text-danger`}>*Por favor, informe o Projeto</span>}
                        </Col>
                       
                    </Row>

                    <Row className='col-12 '>
                    <Col>
                            <label htmlFor="" className='form-label'>Valor:</label>
                                <input type="text" 
                                value={
                                    valor
                                        ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`
                                        : 'R$ 0,00'
                                }
                                    onChange={(event) => {
                                        const rawValue = event.target.value;
                                        const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                        const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                                        setValor(numericValue.toString());
                                    }} className="form-control" placeholder="Digite o valor Estimado" />
                            {(valor  === null || valor === '0' ) && <span className={` form-label text-danger`}>*Por favor, informe o valor </span>}

                    </Col> 
                    <Col>
                            <label htmlFor="" className='form-label'>Tipo de Custo:</label>
                            <AsyncSelect loadOptions={loadOptionsTipoCusto} placeholder="Selecione o Tipo de Custo" defaultOptions value={selectedTipoCusto} onKeyDown={() => { setSelectedTipoCusto(null) }} onChange={(option) => { setSelectedTipoCusto(option) }}  ></AsyncSelect>
                            {(selectedTipoCusto === null ) && <span className={` form-label text-danger`}>*Por favor, informe o Tipo de Custo</span>}
                    </Col>
                        </Row>
                        <Row>
                            
                        <Col>
                            <label className='form-label' htmlFor="">Data de Custo</label>
                            <input type="date" onChange={(e) => setDataEmissao(e.target.value)} value={dataEmissao ? dataEmissao : ""} name="" className='form-control' id="" />
                            {(dataEmissao  === null || dataEmissao === '' ) && <span className={` form-label text-danger`}>*Por favor, informe a data de custo</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className=''>
                        <Col>
                            <label className='form-label' htmlFor="">Descrição</label>
                            <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                        </Col>
                    </Row>
                    
                </form>
            </div>
        </Modal >
    )
}

export default ModalCustosProjetos