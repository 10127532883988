import Swal from 'sweetalert2';
import {send_Produto, update_Produto} from '../cadastros/cadastro_requests/cadastro_requests';

export const submitFormProduto = async (
  id,
  values,
  selectedOrigem,
  selectedMarca,
  selectedTipo,
  selectedTipo_Embalagem,
  selectedEmbalagem,
  selectedCategoria,
  selectedLinhaProduto,
  base64Image,
  tabelasDePrecosProduto,
  setTabelasDePrecosProduto,
  setSelectedMarca,
  setSelectedTipo_Embalagem,
  setSelectedLinhaProduto,
  setSelectedCategoria,
  setSelectedEmbalagem,
  setSelectedOrigem,
  setSelectedTipo,
  setBase64Image,
  setLoading,
  setSubmitting,
  setStatus,
  resetForm,
  navigate,
  isCategoria,
  isUnidadeMedida,
  isFornecedor,
  isEcommerce,
  isDisponivelFabrica,
  isDivulgacao,
  isEstoqueFabricacao,
  isMix,
  isAguardandoComplemento,
  isLinhaProduto,
  isCliente,
  isUnidadeMedidaCompra,
  selectedGarantia

) => {
  setLoading(true);
  try {
    if (!selectedMarca) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe uma marca para o produto',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!isUnidadeMedida) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe uma unidade de medida de venda',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!isUnidadeMedidaCompra) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe uma unidade de medida de compra',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!values.descricao) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe uma descrição para o produto',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!selectedOrigem.id) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, selecione uma origem do produto.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!values.custo_preco_venda || values.custo_preco_venda === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe o preço de venda.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }
    if (!values.status) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe o status do produto.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (selectedGarantia===null) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe a garantia.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (values.fatorConversaoInicial==="") {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe o fator de conversão inicial.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (values.fatorConversaoFinal==='') {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, informe o fator de conversão de final.',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    let response;

    if (id) {
      response = await update_Produto(
        id,
        {
          ...values,
          categoria_id: isCategoria,
          idUnidadeMedida: isUnidadeMedida,
          idFornecedor: isFornecedor,
          idUnidadeMedidaCompra	: isUnidadeMedidaCompra,
        },
        tabelasDePrecosProduto,
        selectedOrigem,
        selectedMarca,
        selectedTipo,
        selectedTipo_Embalagem,
        selectedEmbalagem,
        selectedCategoria,
        selectedLinhaProduto,
        base64Image,
        isEcommerce ? 1 : 0,
        isDisponivelFabrica ? 1 : 0,
        isDivulgacao ? 1 : 0,
        isEstoqueFabricacao ? 1 : 0,
        isMix ? 1 : 0,
        isAguardandoComplemento ? 1 : 0,
        isLinhaProduto,
        isCliente
      );
    } else {
      response = 
      await send_Produto(
        {
          ...values,
          categoria_id: isCategoria,
          idUnidadeMedida: isUnidadeMedida,
          idFornecedor: isFornecedor,
          idUnidadeMedidaCompra	: isUnidadeMedidaCompra,
          
        },
        tabelasDePrecosProduto,
        selectedOrigem,
        selectedMarca,
        selectedTipo,
        selectedTipo_Embalagem,
        selectedEmbalagem,
        selectedCategoria,
        selectedLinhaProduto,
        base64Image,
        isEcommerce ? 1 : 0,
        isDisponivelFabrica ? 1 : 0,
        isDivulgacao ? 1 : 0,
        isEstoqueFabricacao ? 1 : 0,
        isMix ? 1 : 0,
        isAguardandoComplemento ? 1 : 0,
        isLinhaProduto,
        isCliente
      );
    }

    if (response.status === 201 || response.status === 200) {
      setSubmitting(false);
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: id ? 'Produto atualizado com sucesso!' : 'Produto cadastrado com sucesso!',
        confirmButtonText: 'Voltar a listagem',
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didClose: () => {
          navigate('/produto-listagem');
        },
      });
      resetForm();
      setTabelasDePrecosProduto([]);
      setSelectedMarca({value: undefined, label: undefined});
      setSelectedTipo_Embalagem({id: '', desc: ''});
      setSelectedLinhaProduto({id: '', desc: ''});
      setSelectedCategoria({id: '', desc: ''});
      setSelectedEmbalagem({id: '', desc: ''});
      setSelectedOrigem({id: '', desc: ''});
      setSelectedTipo({id: '', desc: ''});
      setBase64Image('');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao criar o produto, verifique as informações preenchidas e tente novamente',
        confirmButtonText: 'Ok',
      });
      setStatus('Ocorreu um erro ao criar o produto. Por favor, tente novamente.');
    }
  } catch (error: any) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message === '401') {
      Swal.fire({
        icon: 'info',
        title: 'Por questões de segurança, por favor faça login novamente',
        confirmButtonText: 'Ok',
      }).then(() => {
        setSubmitting(false);
        setLoading(false);
        window.open('/auth', '_blank');
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao criar produto',
        text: 'Por favor, verifique sua conexão de internet e tente novamente.',
        confirmButtonText: 'Ok',
      });
      setSubmitting(false);
      setLoading(false);
    }
    setStatus('Por favor, preencha as informações de usuário corretamente');
  }
};
