const RangePriceBetweenContasReceber = ({minPrice, setMinPrice, maxPrice, setMaxPrice}) => {
  const handleCLear = (event) => {
    event.preventDefault();
    setMinPrice(null);
    setMaxPrice(null);
  };
  return (
    <>
      <div className=' w-100 d-flex form-control p-0 pt-1'>
        <div className='d-flex ms-2 w-100'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '75%',
            }}
          >
            <input
              type='text'
              // style={{width:`${Number(sizeMinPrice)}ch`, maxWidth: '16ch'}}
              style={{}}
              className='border-0 text-start px-2 py-0 form-control fs-5  align-text-bottom  d-flex justify-content-end align-items-end'
              placeholder='Valor mínimo'
              value={
                minPrice === null
                  ? ''
                  : `R$ ${parseFloat(String(minPrice)).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
              }
              onChange={(event) => {
                const rawValue = event.target.value;
                const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0; // Divide por 100 se houver valor
                console.log(cleanedValue);
                setMinPrice(null);
                setMinPrice(numericValue.toString());
              }}
            />
          </div>
          <span className='mx-0 px-0 text-black-50 fs-1 pb-1'>~</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '75%',
            }}
          >
            <input
              type='text'
              className='border-0  text-end py-0 form-control fs-5  align-text-bottom d-flex justify-content-end align-items-end'
              placeholder='Valor máximo'
              style={{paddingLeft: 0}}
              value={
                maxPrice !== null
                  ? `R$ ${parseFloat(String(maxPrice)).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : ''
              }
              onChange={(event) => {
                const rawValue = event.target.value;
                const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0; // Divide por 100 se houver valor
                console.log(cleanedValue);
                setMaxPrice(null);
                setMaxPrice(numericValue.toString());
              }}
            />
          </div>
        </div>
        {(minPrice !== null || maxPrice !== null) && (
          <button
            type='button'
            onClick={handleCLear}
            className='btn btn-close my-auto '
            style={{width: '3%'}}
          ></button>
        )}
      </div>
    </>
  );
};
export default RangePriceBetweenContasReceber;
