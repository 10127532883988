import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { IStatusProjetos, smoothScrollToBottom, useModal } from "../../../shared";
import ModalStatusDoProjeto from "./components/ModalStatusDoProjeto";
import CrudHeader from "../../../shared/components/crud-header/crud-header";
// import { smoothScrollToBottom, TipoLocalizacao, useModal } from "../../../../shared";
// import ModalTipoLocalizacao from "./components/ModalTipoLocalizacao";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const StatusDoProjeto = () => {
    const [statusProjeto, setStatusProjeto] = useState<IStatusProjetos[]>([])
    const [dataLine, setDataLine] = useState<IStatusProjetos[]>([])

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isCLear, setIsCLear] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)


    const [isStatusProjetoModal, openStatusProjetoModal, closeStatusProjetoModal] = useModal();

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }

    const fetchStatusProjeto = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-status/listar`,
                {
                    params: {
                        status: status,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                setStatusProjeto((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Status do Projeto para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    };

    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setPage(0)
        setIsCLear(true)
    }

    useEffect(() => {
        fetchStatusProjeto(page)
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchStatusProjeto(0);
    };
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        if (isEdit) {
            setIsEdit(isEdit)
        } else (
            setIsEdit(false))

        openStatusProjetoModal();
    }

    useEffect(() => {
        if (isCLear) {
            fetchStatusProjeto(0)
        }
        setIsCLear(false)
    }, [isCLear])

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <CrudHeader title='Status do Projeto' isLoading={isLoading} titleCadastro='Status do Projeto' clearFiltros={clearFiltros} handleSearch={handleSearch} openModal={openModal}>
                <Row className="col-12 row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Titulo:</label>
                        <input type="text"
                            className="form-control"
                            value={titulo ? titulo : ""}
                            onChange={(e) => {
                                setTitulo(e.target.value)
                            }}
                            placeholder="Digite o titulo" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className="col-12 mb-4 row">
                    <label htmlFor="" className="form-label">Descrição:</label>
                    <Col className="">
                        <textarea name=""
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                            placeholder="Digite a descrição" className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Id</th>
                                    <th className='text-center'>Titulo</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {statusProjeto?.map((tipo) => (
                                    <tr className="text-center">
                                        <td>{tipo.id}</td>
                                        <td>{tipo.titulo}</td>
                                        <td>{tipo.descricao ? tipo.descricao : "..."}</td>
                                        <td>{tipo.usuarioCriacaoNome}</td>
                                        <td>{tipo.dataCriacao}</td>
                                        <td>{tipo.usuarioAlteracaoNome ? tipo.usuarioAlteracaoNome : "..."}</td>
                                        <td>{tipo.dataAlteracao}</td>
                                        <td>{tipo.status}</td>
                                        <td>
                                            <div className='text-center'>
                                                <button
                                                    onClick={() => {
                                                        openModal(true, tipo.id)
                                                        setIsEdit(true)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ModalStatusDoProjeto clearFiltros={clearFiltros} dataLine={dataLine} reload={fetchStatusProjeto} isEdit={isEdit} isOpen={isStatusProjetoModal} onHide={closeStatusProjetoModal}></ModalStatusDoProjeto>
        </div>
    )
}

export default StatusDoProjeto