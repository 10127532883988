import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import {ContainerOptionsContasReceberParcela} from './async-select-contas-receber-parcelas-styles';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectContasReceberParcela: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectContaReceberParcelaAsync, setSelectContaReceberParcelaAsync] =
    useState<ISelectOption | null>(null);
  const [contaReceberParcelaDetails, setContaReceberParcelaDetails] = useState<any[]>();

  const loadOptionsContaReceberParcelaById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber-parcelas/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setContaReceberParcelaDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const contaReceber = contaReceberParcelaDetails?.find(
      (cR) => Number(cR.id) === Number(option.value)
    );

    if (contaReceber) {
      return (
        <ContainerOptionsContasReceberParcela>
          <div className='container-message-contas-receber'>
            <div className='info-container-contas-receber'>
              <p>
                ID: <span>{contaReceber.id ? contaReceber.id : '-'}</span>
              </p>

              <p>
                Conta a Receber:{' '}
                <span>{contaReceber.idContaReceber ? contaReceber.idContaReceber : ' - '}</span>
              </p>
              <p>
                Valor:{' '}
                <span>
                  {contaReceber.valor
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(contaReceber.valor)
                    : ' - '}
                </span>
              </p>
              <p>
                STATUS: <span>{contaReceber.status ? contaReceber.status : '-'}</span>
              </p>
            </div>
          </div>
        </ContainerOptionsContasReceberParcela>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectContaReceberParcelaAsync && handleOptions) {
      handleOptions(selectContaReceberParcelaAsync);
    }
  }, [selectContaReceberParcelaAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsContaReceberParcelaById(inputOption.value);
      setSelectContaReceberParcelaAsync(inputOption);
    } else if (inputOption == null) {
      setSelectContaReceberParcelaAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise a Parcela da Conta a Receber'
      loadOptions={loadOptionsContaReceberParcelaById}
      value={selectContaReceberParcelaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectContaReceberParcelaAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectContasReceberParcela;
