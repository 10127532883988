import axios from 'axios';
import { useEffect, useState } from 'react';
import { Badge, Button, Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { ClienteOption } from '../../cadastros/proposta-pedido/types/interface';
import { AsyncSelectProjetos, formatarData, IFaturaProjeto, IOrcamentoProjeto, IProjetos, smoothScrollToBottom, useModal } from '../../../shared';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import ModalFaturaProjeto from '../fatura-do-projeto/components/ModalFaturaProjeto';
import ModalOrcamentosProjetos from './components/ModalOrcamentosProjeto';
import CrudHeader from '../../../shared/components/crud-header/crud-header';
// import ModalFaturaProjeto from './components/ModalFaturaProjeto';


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const OrcamentosProjeto = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false)


    const [hasMore, setHasMore] = useState<boolean>(false)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [dataLine, setDataLine] = useState<IOrcamentoProjeto[]>([])
    const [page, setPage] = useState(0)
    const [countSkeleton, setCountSkeleton] = useState(40)

    const [valorEstimado, setValorEstimado] = useState<string | null>(null)
    const [valorAprovado, setValorAprovado] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<ClienteOption | null>(null)
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [dataEmissao, setDataEmissao] = useState<string | null>(null);
    const [dataAprovacao, setDataAprovacao] = useState<string | null>(null);
    const [dataPagamento, setDataPagamento] = useState<string | null>(null);

    const [projetos, setProjetos] = useState<IOrcamentoProjeto[]>([])

    const [isProjetosModal, openProjetosModal, closeProjetosModal] = useModal();


    const fetchOrcamentosProjeto = async (page = 0) => {

        if (page === 0) {
            setPage(0)
        }

        setLoading(true)
        setIsLoadingSearch(true)

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-orcamentos/listar`,
                {
                    params: {
                        page: page,
                        idProjeto: selectedProjeto ? selectedProjeto.value : null,
                        valorAprovado: valorAprovado ? valorAprovado==='0'?null:valorAprovado : null,
                        valorEstimado: valorEstimado ? valorEstimado==='0'?null:valorEstimado : null,
                        dataAprovacao: dataAprovacao ? dataAprovacao : null,
                        status: status,
                        descricao: descricao ? descricao : null,
                    },
                }

            );

            console.log(response.data.content)
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }

                // setProjetos(response.data.content)


                setProjetos((prev) =>
                    page === 0 ? response.data.content : [...prev, ...response.data.content]
                )
            }
            if (response.data.content.length === 0) {
                //   setPrevendas([]);
                setHasMore(false);
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Orçamentos dos projetos para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

            setHasMore(response.data.totalPages >= page + 1);
            // setHasMore(response.data.totalPages > page+1);

            if (response.data.last) {
                setHasMore(false);
            }
            // } else if (response.status === 204) {
            //   setPrevendas([]);
            //   setHasMore(false);
            //   Swal.fire({
            //     icon: 'info',
            //     //   title: `Não existe registros de ${tipo === 'propostas' ? 'proposta comercial' : 'pedido'
            //         // } para essa pesquisa`,
            //       timer: 3000,
            //       timerProgressBar: true,
            //     });
            //   }
        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setLoading(false);
            setIsLoadingSearch(false);

        }
    };

    const loadMore = () => {
        setLoading(true)
        // setTimeout(() => {
        setLoading(false)
        setPage((prevPage) => prevPage + 1);
        setCountSkeleton((count) => count + 40)
        smoothScrollToBottom()
        // }, 1000);
    }
    useEffect(() => {
        fetchOrcamentosProjeto(page)
        // if (page !== 0) {
        //   let dataInicial = '';
        //   let dataFinal = '';
        // if (valueDateRange && valueDateRange.length > 0) {
        //   dataInicial = convertToLocalTimeZone(valueDateRange[0]);
        //   dataFinal = convertToLocalTimeZone(valueDateRange[1]);
        // }
        //   setPrevendas(
        //     idPrevenda,
        //     idPrevendaG2,
        //     idCliente,
        //     statusOrcamento,
        //     nomeCliente,
        //     idConsultor,
        //     nomeConsultor,
        //     idOrcamentista,
        //     nomeOrcamentista,
        //     idParceiro,
        //     nomeParceiro,
        //     dataInicial,
        //     dataFinal,
        //     isPedido,
        //     page
        //   );

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        //     }
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        fetchOrcamentosProjeto()
    }

    const loadOptionsStatus = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamento-status/listar`;

            const response = await axios.get(url, {

                params: {
                    status: 'ATIVO',
                    size: 1000,
                }
            });
            const data = await response.data;
            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        }
    };

    const loadOptionsProjetos = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO',
                }
            });
            const data = await response.data;
            console.log(data.id)

            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openProjetosModal();
    }
    const clearFiltros = () => {
        setSelectedStatus(null)
        setSelectedProjeto(null)
        setValorAprovado(null)
        setValorEstimado(null)
        setDataEmissao(null)
        setDataAprovacao(null)
        setDataPagamento(null)
        setDescricao(null)
        setStatus('ATIVO')
        setIsClear(true)
    }

    useEffect(() => {
        if(isClear){
            fetchOrcamentosProjeto()
        }
        setIsClear(false)   
    },[isClear])

    return (
        <div>
            <CrudHeader title="Orçamentos dos Projetos" clearFiltros={clearFiltros} isLoading={isLoading} handleSearch={handleSearch} openModal={openModal} titleCadastro={'Orçamento'}>
                {/* <h1 className="fw-bolder">Orcamentos de Projetos</h1> */}
                <Row className='col-12 '>
                        <Col>
                            <label htmlFor="" className='form-label'>Projeto:</label>
                            {/* <AsyncSelect defaultOptions value={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} onChange={(option) => { setSelectedProjeto(option) }} loadOptions={loadOptionsProjetos} placeholder="Selecione o Projeto" ></AsyncSelect> */}
                            {/* <AsyncSelectProjetos></AsyncSelectProjetos> */}
                            <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { setSelectedProjeto(option) }}  ></AsyncSelectProjetos>
                        </Col>

                    </Row>

                    <Row className='col-12 '>
                        <Col>
                            <label htmlFor="" className='form-label'>Valor Estimado:</label>
                            <input type="text" value={
                                valorEstimado
                                    ? `R$ ${parseFloat(String(valorEstimado)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                                onChange={(event) => {
                                    const rawValue = event.target.value;
                                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valorEstimado
                                    setValorEstimado(numericValue.toString());
                                }} className="form-control" placeholder="Digite o valor Estimado" />
                        </Col>
                        <Col>
                            <label htmlFor="" className='form-label'>Valor Aprovado:</label>
                            <input type="text" value={
                                valorAprovado
                                    ? `R$ ${parseFloat(String(valorAprovado)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                                onChange={(event) => {
                                    const rawValue = event.target.value;
                                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valorAprovado
                                    setValorAprovado(numericValue.toString());
                                }} className="form-control" placeholder="Digite o valor Aprovado" />
                        </Col>
                    </Row>
                    <Row className='col-12 '>
                        <Col>
                            <label className='form-label' htmlFor="">Data de aprovação</label>
                            <input type="date" onChange={(e) => setDataAprovacao(e.target.value)} value={dataAprovacao ? dataAprovacao : ""} name="" className='form-control' id="" />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>


                    </Row>
                    <Row className='col-12'>
                        <Col>
                            <label className='form-label' htmlFor="">Descrição</label>
                            <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                        </Col>
                    </Row>
            </CrudHeader>
            {loading ? (
                <Skeleton count={countSkeleton} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800 overflow-visible'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Projetos</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Valor Estimado</th>
                                    <th className='text-center'>Valor Aprovado</th>
                                    <th className='text-center'>Data de Aprovação</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de Alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projetos.map((projeto) => (
                                    <tr>
                                        <td className='text-center'>{projeto.id}</td>
                                        <td className='text-center'>{projeto.tituloProjeto}</td>
                                        <td className='text-center'>{projeto.descricao ? projeto.descricao : "..."}</td>
                                        {/* <td className='text-center'>{projeto.descricao ? projeto.descricao : "..."}</td> */}
                                        <td className='text-center'>R$ {parseFloat(String(projeto.valorEstimado)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}</td>
                                        <td className='text-center'>R$ {parseFloat(String(projeto.valorAprovado)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}</td>
                                        <td className='text-center'>{formatarData(projeto.dataAprovacao)}</td>
                                        {/* <td className='text-center'>{formatarData(projeto.data)}</td>
                                        <td className='text-center'>{formatarData(projeto.dataPagamento)}</td>
                                        <td className='text-center'>{projeto.tituloStatusPagamento}</td> */}
                                        <td className='text-center'>{projeto.usuarioCriacaoNome}</td>
                                        <td className='text-center'>{projeto.dataCriacao}</td>
                                        <td className='text-center'>{projeto.usuarioAlteracaoNome ? projeto.usuarioAlteracaoNome : "..."}</td>
                                        <td className='text-center'>{projeto.dataAlteracao ? projeto.dataAlteracao : "..."}</td>
                                        <td className='text-center'>{projeto.status}</td>
                                        <td className='text-center'>
                                            <button
                                                onClick={() => openModal(true,
                                                    projeto.id,projeto.idProjeto,projeto.tituloProjeto,projeto.valorEstimado,projeto.valorAprovado,projeto.dataAprovacao,projeto.status,projeto.descricao
                                                )}
                                                className='btn btn-success btn-sm bi bi-pencil'
                                                data-toggle='tooltip'
                                                data-placement='top'
                                                title={'Editar'}
                                            ></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>

                </>
            )}
            <ModalOrcamentosProjetos
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchOrcamentosProjeto}
                isEdit={isEdit}
                isOpen={isProjetosModal}
                onHide={closeProjetosModal}
            ></ModalOrcamentosProjetos>
        </div>
    )
}
export default OrcamentosProjeto