import {Col, Row, Spinner} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {EstoqueLocalizacao, smoothScrollToBottom, useModal} from '../../../../shared';
import Skeleton from 'react-loading-skeleton';
import ModalEstoqueLocalizacao from './components/ModalEstoqueLocalizao';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueLocalizacao = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const [localizacao, setLocalizacao] = useState<EstoqueLocalizacao[]>([]);
  const [dataLine, setDataLine] = useState<EstoqueLocalizacao[]>([]);

  const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null);
  const [selectedTipoLocalizacao, setSelectedTipoLocalizacao] = useState<ClienteOption | null>(
    null
  );
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [codigo, setCodigo] = useState<string | null>(null);

  const [isEstoqueLocalizacaoModal, openEstoqueLocalizacaoModal, closeEstoqueLocalizacaoModal] =
    useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };

  const clearFiltros = () => {
    setTitulo(null);
    setCodigo(null);
    setDescricao(null);
    setStatus('ATIVO');
    setSelectedTipoEstoque(null);
    setSelectedTipoLocalizacao(null);
    setPage(0);
  };
  const fetchEstoqueLocalizacao = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/listar`,
        {
          params: {
            status: status,
            titulo: titulo ? titulo : null,
            descricao: descricao ? descricao : null,
            idEstoque: selectedTipoEstoque?.value ? selectedTipoEstoque?.value : null,
            idTipoLocalizacao: selectedTipoLocalizacao?.value
              ? selectedTipoLocalizacao?.value
              : null,
            codigo: codigo ? codigo : null,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
          setLocalizacao([]);
        }
        setLocalizacao((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Localização para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const loadOptionsEstoque = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsTipoLocalizacao = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-localizacao/listar`;

      const response = await axios.get(url, {
        params: {
          size: 100,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: ` ${item.titulo}`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const openModal = (isEdit, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openEstoqueLocalizacaoModal();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchEstoqueLocalizacao(0);
  };

  useEffect(() => {
    fetchEstoqueLocalizacao(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Localização
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Localização
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='col-12 mb-4 row mt-8'>
            <Col>
              <label htmlFor='' className='form-label'>
                Estoque:
              </label>
              <AsyncSelect
                defaultOptions
                value={selectedTipoEstoque}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedTipoEstoque(null);
                  }
                }}
                onChange={(selectedOption: ClienteOption | null) => {
                  if (selectedOption) {
                    const idValue = isNaN(Number(selectedOption.value))
                      ? selectedOption.value // Caso seja texto, mantêm como string.
                      : Number(selectedOption.value);
                    console.log(selectedOption.value);
                  }
                  setSelectedTipoEstoque(selectedOption);
                  console.log(selectedOption);
                }}
                loadOptions={loadOptionsEstoque}
                placeholder='Selecione o Estoque'
                className='mt-1'
              ></AsyncSelect>
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Titulo:
              </label>
              <input
                type='text'
                value={titulo ? titulo : ''}
                className='form-control'
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                placeholder='Digite o titulo'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Codigo:
              </label>
              <input
                type='text'
                value={codigo ? codigo : ''}
                className='form-control'
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
                placeholder='Digite o Codigo'
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Tipo de Localização:
              </label>
              <AsyncSelect
                defaultOptions
                value={selectedTipoLocalizacao}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedTipoLocalizacao(null);
                  }
                }}
                onChange={(selectedOption: ClienteOption | null) => {
                  if (selectedOption) {
                    const idValue = isNaN(Number(selectedOption.value))
                      ? selectedOption.value // Caso seja texto, mantêm como string.
                      : Number(selectedOption.value);
                    console.log(selectedOption.value);
                  }
                  setSelectedTipoLocalizacao(selectedOption);
                  console.log(selectedOption);
                }}
                loadOptions={loadOptionsTipoLocalizacao}
                placeholder='Selecione o tipo de Localização'
                className='mt-1'
              ></AsyncSelect>
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                Limpar
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={40} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Estoque</th>
                  <th className='text-center'>Codigo</th>
                  <th className='text-center'>Tipo de Localização</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Editar</th>
                </tr>
              </thead>
              <tbody>
                {localizacao?.map((log) => (
                  <tr className='text-center'>
                    <td>{log.id}</td>
                    <td>{log.titulo}</td>
                    <td>{log.estoque}</td>
                    <td>{log.codigo}</td>
                    <td>{log.tipoLocalizacao}</td>
                    <td>{log.descricao ? log.descricao : '...'}</td>
                    <td>{log.usuarioCriacaoNome}</td>
                    <td>{log.dataCriacao}</td>
                    <td>{log.usuarioAlteracaoNome ? log.usuarioAlteracaoNome : '...'}</td>
                    <td>{log.dataAlteracao}</td>
                    <td>{log.status}</td>
                    <td>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            openModal(
                              true,
                              log.titulo,
                              log.id,
                              log.idEstoque,
                              log.estoque,
                              log.tipoLocalizacao,
                              log.idTipoLocalizacao,
                              log.codigo,
                              log.status,
                              log.descricao
                            );
                          }}
                          className='btn btn-success btn-sm bi bi-pencil'
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Editar'
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <ModalEstoqueLocalizacao
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchEstoqueLocalizacao}
        isEdit={isEdit}
        isOpen={isEstoqueLocalizacaoModal}
        onHide={closeEstoqueLocalizacaoModal}
      />
    </div>
  );
};

export default ListagemEstoqueLocalizacao;
