import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalContaCorrente = ({isOpen, onHide, dataLine, isEdit, loadContaCorrente}) => {
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [numero, setNumero] = useState<string | null>(null);
  const [agencia, setAgencia] = useState<string | null>(null);
  const [operacao, setOperacao] = useState<string | null>(null);
  const [selectedBanco, setSelectedBanco] = useState<ISelectOption | null>(null);
  const [bancoNome, setBancoNome] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {id} = useParams();

  const onClose = () => {
    onHide();
    setTitulo(null);
    setNumero(null);
    setOperacao(null);
    setAgencia(null);
    setDescricao(null);
    setStatus('ATIVO');
    onClear();
  };

  const onClear = () => {
    setNumero(null);
    setOperacao(null);
    setAgencia(null);
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarContaCorrente = async () => {
    setIsLoading(true);
    const erros: string[] = [];

    if (!numero && !operacao && !agencia) erros.push('Preencha os campos obrigatórios.');
    if (!numero && operacao && !agencia) erros.push('Preencha os campos obrigatórios.');
    if (numero && !operacao && !agencia) erros.push('Preencha os campos obrigatórios.');
    if (!numero && !operacao && agencia) erros.push('Preencha os campos obrigatórios.');

    if (!numero && operacao && agencia) erros.push('Informe o número.');
    if (numero && !operacao && agencia) erros.push('Informe a operação.');
    if (numero && operacao && !agencia) erros.push('Informe a agência.');

    if (erros.length > 0) {
      Swal.fire({
        icon: 'info',
        title: erros.map((erro) => `<p>${erro}</p>`).join(''),
        confirmButtonText: 'Ok',
      });
      setIsLoading(false);
      return;
    }
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente`;
      if (operacao && numero && agencia) {
        const response = await axios.post(url, {
          operacao: operacao,
          agencia: agencia?.replace(/^0+|-/g, ''),
          numero: numero?.replace(/^0+|-/g, ''),
          banco: selectedBanco?.label,
          idBanco: id,
          descricao: descricao,
          status: status,
        });

        Swal.fire({
          icon: 'success',
          title: `Conta corrente cadastrada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          onClear();
          loadContaCorrente();
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarContaCorrente = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/${dataLine[0]}`;
      if (id) {
        const response = await axios.put(url, {
          operacao: operacao,
          agencia: agencia,
          numero: numero,
          banco: selectedBanco?.label,
          idBanco: id,
          descricao: descricao,
          status: status,
        });
        Swal.fire({
          icon: 'success',
          title: `Conta corrente editada com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadContaCorrente();
        });
        setTimeout(() => {}, 3000);
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchBancoDetails = async () => {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/${id}`
        );
        setBancoNome(response.data.titulo);
      } catch (error) {
        console.error('Erro ao buscar detalhes do banco:', error);
      }
    };

    if (id) {
      fetchBancoDetails();
    }
  }, [id]);

  const handleNumeroChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, '');
    inputValue = inputValue.padStart(9, '0').slice(-9);

    if (inputValue === '000000000') {
      setNumero(null);
    } else {
      setNumero(`${inputValue.slice(0, 8)}-${inputValue.slice(8)}`);
    }
  };

  const handleAgenciaChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    inputValue = inputValue.padStart(4, '0').slice(-4);

    if (inputValue === '0000') {
      setAgencia(null);
    } else {
      setAgencia(inputValue);
    }
  };

  const loadOptionsBancos = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/listar?titulo=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo} (ID: ${item.id})`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarContaCorrente() : cadastrarContaCorrente())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setSelectedBanco({
        value: dataLine[1],
        label: dataLine[1],
      });
      setOperacao(dataLine[2]);
      setNumero(dataLine[3]);
      setAgencia(dataLine[4]);
      setStatus(dataLine[5]);
      setDescricao(dataLine[6]);
    }
  }, [dataLine]);

  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Conta Corrente
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Banco
              </label>
              <AsyncSelect
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedBanco(null);
                  }
                }}
                value={selectedBanco?.label}
                placeholder={`${bancoNome} (ID: ${id})`}
                onChange={(e) => {
                  setSelectedBanco(e);
                  console.log(e);
                }}
                isDisabled
                loadOptions={loadOptionsBancos}
              />
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Operação:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    setOperacao(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={operacao ?? ''}
                >
                  <option value=''>Selecione uma operação...</option>
                  <option value='ENTRADA'>ENTRADA</option>
                  <option value='SAIDA'>SAIDA</option>
                </select>
                {!operacao && (
                  <span className={`form-label text-danger`}>*Por favor, selecione a operação</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Número:
                </label>
                <input
                  type='text'
                  value={numero ? numero : '00000000-0'}
                  className='form-control'
                  onChange={handleNumeroChange}
                  placeholder='Digite o Número'
                />
                {!numero && (
                  <span className={`form-label text-danger`}>
                    *Por favor, insira o número da conta
                  </span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Agência:
                </label>
                <input
                  type='text'
                  value={agencia ? agencia : '0000'}
                  className='form-control'
                  onChange={handleAgenciaChange}
                  placeholder='Digite a Agência'
                />
                {!agencia && (
                  <span className={`form-label text-danger`}>*Por favor, insira a agência</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    // handleChangeStatus(e.target.value)
                    setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  rows={3}
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                    setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalContaCorrente;
