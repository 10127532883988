import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Row} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { ClienteOption } from '../../cadastros/proposta-pedido/types/interface';
import { AsyncSelectProjetos, formatarData, ICustoProjeto, smoothScrollToBottom, useModal } from '../../../shared';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import ModalCustosProjetos from './components/ModalCustosProjetos';
import CrudHeader from '../../../shared/components/crud-header/crud-header';


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const OrcamentosProjeto = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)


    const [hasMore, setHasMore] = useState<boolean>(false)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [dataLine, setDataLine] = useState<ICustoProjeto[]>([])
    const [page, setPage] = useState(0)
    const [countSkeleton, setCountSkeleton] = useState(40)

    const [valor, setValor] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [selectedTipoCusto, setSelectedTipoCusto] = useState<ClienteOption | null>(null)
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [dataCusto, setDataCusto] = useState<string | null>(null);

    const [custos, setCustos] = useState<ICustoProjeto[]>([])

    const [isProjetosModal, openProjetosModal, closeProjetosModal] = useModal();


    const fetchCustosProjeto = async (page = 0) => {

        if (page === 0) {
            setPage(0)
        }

        setLoading(true)

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-custos/listar`,
                {
                    params: {
                        page: page,
                        idProjeto: selectedProjeto ? selectedProjeto.value : null,
                        idTipoCusto: selectedTipoCusto ? selectedTipoCusto.value : null,
                        valor: valor ? valor === '0' ? null : valor : null,
                        dataCusto: dataCusto ? dataCusto : null,
                        status: status,
                        descricao: descricao ? descricao : null,
                        size: 10,
                    },
                }
            );
            console.log(response.data.content)
            if (response.status === 200) {
                setCustos((prev) =>
                    page === 0 ? response.data.content : [...prev, ...response.data.content]
                )
            }
            if (response.data.content.length === 0) {
                setHasMore(false);
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Custo de projeto para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

            setHasMore(response.data.totalPages >= page + 1);

            if (response.data.last) {
                setHasMore(false);
            }
        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setLoading(false);

        }
    };

    const loadOptionsTipoCusto = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-tipo-custo/listar`;

            const response = await axios.get(url, {
                params: {
                    status: 'ATIVO',
                    size: 1000,
                }
            });
            const data = await response.data;
            console.log(data.id)

            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const loadMore = () => {
        setLoading(true)
        setLoading(false)
        setPage((prevPage) => prevPage + 1);
        setCountSkeleton((count) => count + 40)
        smoothScrollToBottom()
    }

    useEffect(() => {
        fetchCustosProjeto(page)
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        fetchCustosProjeto()
    }

    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openProjetosModal();
    }
    const clearFiltros = () => {
        setSelectedTipoCusto(null)
        setSelectedProjeto(null)
        setValor(null)
        setDataCusto(null)
        setDescricao(null)
        setStatus('ATIVO')
        setIsClear(true)
    }

    useEffect(() => {
        if (isClear) {
            fetchCustosProjeto()
        }
        setIsClear(false)
    }, [isClear])

    return (
        <div>
            <CrudHeader title="Custos dos Projetos" clearFiltros={clearFiltros} isLoading={loading} openModal={openModal} titleCadastro={'Custo'} handleSearch={handleSearch}  >
                <Row className='col-12'>
                    <Col>
                        <label htmlFor="" className='form-label'>Projeto:</label>
                        <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { setSelectedProjeto(option) }}  ></AsyncSelectProjetos>
                    </Col>

                </Row>

                <Row className='col-12 '>
                    <Col>
                        <label htmlFor="" className='form-label'>Valor:</label>
                        <input type="text"
                            value={
                                valor
                                    ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                            onChange={(event) => {
                                const rawValue = event.target.value;
                                const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                                setValor(numericValue.toString());
                            }} className="form-control" placeholder="Digite o valor Estimado" />
                    </Col>
                    <Col>
                        <label htmlFor="" className='form-label'>Tipo de Custo:</label>
                        <AsyncSelect loadOptions={loadOptionsTipoCusto} placeholder="Selecione o Tipo de Custo" defaultOptions value={selectedTipoCusto} onKeyDown={() => { setSelectedTipoCusto(null) }} onChange={(option) => { setSelectedTipoCusto(option) }}  ></AsyncSelect>
                    </Col>
                </Row>

                <Row className='col-12'>

                    <Col className=''>
                        <label className='form-label' htmlFor="">Data Custo</label>
                        <input type="date" onChange={(e) => setDataCusto(e.target.value)} value={dataCusto ? dataCusto : ""} name="" className='form-control' id="" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className='col-12'>
                    <Col>
                        <label className='form-label' htmlFor="">Descrição</label>
                        <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>
            {loading ? (
                <Skeleton count={countSkeleton} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800 overflow-visible'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Projetos</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Valor</th>
                                    <th className='text-center'>Tipo de Custo</th>
                                    <th className='text-center'>Data Custo</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de Alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {custos.map((custo) => (
                                    <tr>
                                        <td className='text-center'>{custo.id}</td>
                                        <td className='text-center'>{custo.tituloProjeto}</td>
                                        <td className='text-center'>{custo.descricao ? custo.descricao : "..."}</td>
                                        {/* <td className='text-center'>{custo.descricao ? custo.descricao : "..."}</td> */}
                                        <td className='text-center'>R$ {parseFloat(String(custo.valor)).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}</td>
                                        <td className='text-center'>    {custo.tituloTipoCusto}</td>
                                        <td className='text-center'>{formatarData(custo.dataCusto)}</td>
                                        {/* <td className='text-center'>{formatarData(custo.data)}</td>
                                        <td className='text-center'>{formatarData(custo.dataPagamento)}</td>
                                        <td className='text-center'>{custo.tituloStatusPagamento}</td> */}
                                        <td className='text-center'>{custo.usuarioCriacaoNome}</td>
                                        <td className='text-center'>{custo.dataCriacao}</td>
                                        <td className='text-center'>{custo.usuarioAlteracaoNome ? custo.usuarioAlteracaoNome : "..."}</td>
                                        <td className='text-center'>{custo.dataAlteracao ? custo.dataAlteracao : "..."}</td>
                                        <td className='text-center'>{custo.status}</td>
                                        <td className='text-center'>
                                            <button
                                                onClick={() => openModal(true,
                                                    custo.id)}
                                                className='btn btn-success btn-sm bi bi-pencil'
                                                data-toggle='tooltip'
                                                data-placement='top'
                                                title={'Editar'}
                                            ></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>

                </>
            )}
            <ModalCustosProjetos clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchCustosProjeto}
                isEdit={isEdit}
                isOpen={isProjetosModal}
                onHide={closeProjetosModal}
            ></ModalCustosProjetos>
        </div>
    )
}
export default OrcamentosProjeto