import * as Yup from 'yup';

export const statusGarantiaValidationSchema = Yup.object().shape({
  titulo: Yup.string()
    .min(4, 'O título do status deve conter pelo menos 4 caracteres')
    .max(255, 'O título do status deve conter no máximo 255 caracteres')
    .required('Por favor, informe um título'),
  descricao: Yup.string()
    .min(4, 'A descrição deve conter no mínimo 4 caracteres')
    .max(255, 'A descrição deve conter no máximo 255 caracteres'),
  status: Yup.string().required('Por favor, adicione um status'),
});
