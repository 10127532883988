import { FiFileText } from "react-icons/fi";
import { useEffect, useState } from "react";
import { formatarData, Modal } from "../../../../shared";
import AvatarConsultor from "./AvatarConsultor";
import { Badge, Button, ModalProps, Spinner } from "react-bootstrap";
import axios from "axios";
import { formataCliente, formataCnpjCpf, formatarHorario, formatarTotal, formatClone, formatStatus, formatStatusPagamento } from "./formsPedido";
import FormArquivoUpload from "../../../../shared/components/form-upload-pdf/FormArquivoUpload";
import Swal from "sweetalert2";
import { set } from "date-fns";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
    selectItens?: object;
};

const ModalGerarPedido: React.FC<Props> = ({ isOpen, onHide, selectItens }) => {
    const [arquivo, setArquivo] = useState();
    const [isOk, setIsOk] = useState<boolean>(false);
    const [isLoadingGerarPedido, setIsLoadingGerarPedido] = useState<boolean>(false);
    const [arquivoSalvo, setArquivoSalvo] = useState<string>();
    const [prevenda,setPrevenda] =useState({})
    
    const onClose = () => {
        onHide!();
    };



    const submitButton = (
        <Button
          type='submit'
          variant='success'
        //   disabled={!selectedRow ? true : false}
          onClick={() => fetchGerarPedido()}
        >
          {isLoadingGerarPedido ? <Spinner animation='border' size='sm' /> : 'Anexar Arquivo'}
        </Button>
      );
    const fetchGerarPedido = async () => {
        // const response = await axios.put(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${selectItens!['id']}/gerar-pedido`)
            isOk?Swal.fire({
                title: 'Pdf Anexado!',
                text: 'Seu pdf foi anexado com sucesso!',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then(() => {
                setIsOk(false)
                onClose()
              }):
              Swal.fire({
                icon: 'error',
                // showCancelButton: true,
                // confirmButtonText: 'Sim',
                // cancelButtonText: 'Não',
                title: 'Anexe um arquivo do tipo pdf',
                // text: `Anexe um arquivo do tipo pdf`,
                confirmButtonText: 'Ok',
              })
        
        }

    const modalConfigs: ModalProps = {
            isOpen,
            actions: [submitButton],
            onHide: onClose,
            size: 'xl',
        };

    useEffect(()=>{
            setPrevenda(selectItens!)
        },[isOpen])
    return (
        <>
        {isOpen ?

        (<>
                <Modal  {...modalConfigs}>
                    <div className=" mx-auto d-flex justify-content-center p-2 mb-5">
                        <FiFileText className="fs-1 rounded-4  " style={{'scale':"3",padding:"2px"  ,border: '2px solid #7239EA', 'color':"#7239EA"}} ></FiFileText>

                    </div>
            <h1 className=" mx-auto fw-bolder">Gerar Pedido</h1>
            <hr className=" mx-auto bg-black" style={{"height":'2px'}} />
            <table className='table  mx-auto table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                <thead className='thead-dark'>
                    <tr className='fw-bold fs-6 text-gray-800'>
                        <th className='text-center'>Id</th>
                        <th className='text-center'>Empresa/G2</th>
                        <th className='text-center'>Data Emissão</th>
                        <th className='text-center'>Cliente</th>
                        <th className='text-center'>Total Geral</th>
                        <th className='text-center'>Status</th>
                        <th className='text-center'>Consultor</th>
                    </tr>
                </thead>
                <tbody className=''>
                    {/* {prevendas.map((prevenda) => ( */}
                        <tr >
                            <td className='text-center'>{prevenda['id']}</td>
                            <td className='text-center'>
                                 {prevenda['empresa']}
                                <br></br>
                                <Badge bg='info' style={{ color: 'white' }}>
                                    {}
                                </Badge>
                            </td>
                            {(prevenda['dataemissao'])&&(<td className='text-center'>
                                <div>{formatarData(prevenda['dataemissao'])}</div>
                                <div>{formatarHorario(prevenda['dataemissao'])}</div>
                                {prevenda['isAmbientado'] && (
                                    <div className='mt-2'>
                                        <Badge style={{ color: 'white' }} bg='info'>
                                            AMBIENTADO
                                        </Badge>
                                    </div>
                                )}
                                {prevenda['fretestatus'] !== 0 && (
                                    <div className='mt-2'>
                                        <Badge style={{ color: 'white' }} bg='warning'>
                                            FRETE
                                        </Badge>
                                    </div>
                                )}
                            </td>)}
                            {(prevenda['clienteInfo'])&&(<td className='text-center'>
                                <div
                                    // onClick={() => handleClickCompras(prevenda.clienteInfo)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {prevenda['clienteInfo']['nome'] || ''}
                                </div>
                                <div>{formataCliente(prevenda['clienteInfo']['status']) || ''}</div>
                                <div className='mt-2'>
                                    {formataCnpjCpf(prevenda['clienteInfo']['cpf'], prevenda['clienteInfo']['cnpj'])}
                                </div>
                            </td>)}
                            <td className='text-center'>
                                {formatarTotal(prevenda['total'])}
                                {prevenda['margemProposta'] && (
                                    <div className='mt-2'>
                                        <Badge style={{ color: 'white' }}>Mg: {prevenda['margemProposta']}%</Badge>
                                    </div>
                                )}
                            </td>

                            <td className='text-center'>
                                <div>{formatStatus(prevenda['status']) || 'N/A'}</div>
                                <div style={{ marginTop: '5px' }}>
                                    {formatClone(prevenda['versao'], prevenda['idPrevendaOriginal'])}
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    {formatStatusPagamento(prevenda['statusPagamento'])}
                                    {prevenda['kanban'] && prevenda['kanban'].length > 0 && (
                                        <div style={{ marginTop: '5px' }}>
                                            {prevenda['kanban'].map((kanbanItem, index) => (
                                                <Badge
                                                    key={index}
                                                    style={{
                                                        backgroundColor: '#e0f7e8',
                                                        color: '#fff',
                                                        textTransform: 'uppercase',
                                                        marginRight: '5px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    Kanban: {kanbanItem.kanban || 'N/A'}
                                                </Badge>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </td>
                            {prevenda['consultorInfo']&&(<td className='text-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <AvatarConsultor
                                        idFuncionario={prevenda['consultorInfo']['idFuncionario']}
                                        funcionario={prevenda['consultorInfo']['funcionario']}
                                    />
                                </div>
                            </td>)}
                        </tr>
                
                </tbody>
            </table>
            <FormArquivoUpload setArquivo={setArquivo} setIsOk={setIsOk} isOk={isOk} ></FormArquivoUpload>
        </Modal>
        </>
        ):(<div></div>)}
        
        </>
    )
}

export default ModalGerarPedido