import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {AsyncSelectEmpresa, AsyncSelectFornecedor, IContasAPagar, smoothScrollToBottom, useModal} from '../../../shared';
import {ISelectOption} from '../../../shared';
import AsyncSelect from 'react-select/async';
import ModalContasAPagar from './components/ModalContasAPagar';
import { Link, useParams } from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemContasAPagar = () => {
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [contasAPagar, setContasAPagar] = useState<IContasAPagar[]>([]);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [selectedFornecedor, setSelectedFornecedor] = useState<ISelectOption | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState<ISelectOption | null>(null);
  const [selectedTipoDespesa, setSelectedTipoDespesa] = useState<ISelectOption | null>(null);
  const [selectedSetor, setSelectedSetor] = useState<ISelectOption | null>(null);
  const [selectedParcela, setSelectedParcela] = useState<ISelectOption | null>(null);
  const [valor, setValor] = useState<number | null>(null);
  const {id} = useParams();

  const formDataState = {
    setSelectedFornecedor: setSelectedFornecedor,
    setSelectedEmpresa: setSelectedEmpresa,
    setSelectedTipoDespesa: setSelectedTipoDespesa,
    setSelectedSetor: setSelectedSetor,
    setSelectedParcela: setSelectedParcela,
    setValor: setValor,
    setDescricao: setDescricao,
    setStatus: setStatus,
    setContasAPagar: setContasAPagar,
    setPage: setPage,
  };

  const [isContasAPagarModal, openContasAPagarModal, closeContasAPagarModal] = useModal();

  const fetchContasAPagar = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar/listar`,
        {
          params: {
            status: status,
            page: page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
        }
        const {content, totalPages} = response.data;
        setContasAPagar((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
      }
      if (response.data.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de contas a pagar para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsLoadingSearch(true);
    setPage(0);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar/listar`,
        {
          params: {
            id: id,
            idFornecedor: selectedFornecedor?.value,
            idEmpresa: selectedEmpresa?.value,
            idSetor: selectedSetor?.value,
            qtdParcelas: selectedParcela?.value,
            idTipoDespesa: selectedTipoDespesa?.value,
            status: status,
            valor: valor,
            descricao: descricao ? descricao : null,
            page: page,
          },
        }
      );
      if (response.status === 200) {
        if (page === 0) {
          setContasAPagar([]);
        }
        formDataState.setContasAPagar(response.data.content);
        const {content, totalPages} = response.data;
        setContasAPagar(content);
        setHasMore(page < totalPages - 1);
      }
      if (response.data.content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de contas a pagar para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const loadOptionsFornecedor = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedor/listar?razaoSocial=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.fantasia} (ID: ${item.id})`,
        }))
        .filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().includes(inputValue)
        );
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      setIsLoadingSearch(false);
      return [];
    }
  };

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setSelectedEmpresa(option);
  };
  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setSelectedFornecedor(option);
  };
  const loadOptionsSetores = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      setIsLoadingSearch(false);
      return [];
    }
  };
  const loadOptionsTiposDespesa = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
      setIsLoadingSearch(false);
      return [];
    }
  };

  const parcelas = Array.from({ length: 12 }, (_, i) => i + 1);

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openContasAPagarModal();
  };

  const clearFiltros = () => {
    setSelectedFornecedor(null);
    setSelectedEmpresa(null);
    setSelectedSetor(null);
    setSelectedTipoDespesa(null);
    setValor(null);
    setSelectedParcela(null);
    setStatus('ATIVO');
    setDescricao(null);
    setPage(0);
    fetchContasAPagar(page);
  };

  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  useEffect(() => {
    fetchContasAPagar(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Contas a Pagar
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Contas a Pagar
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Fornecedor
              </label>
              <AsyncSelectFornecedor
                handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
                inputOption={selectedFornecedor}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedFornecedor(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Empresa
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
                inputOption={selectedEmpresa}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedEmpresa(null);
                  }
                }}
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Setor
              </label>
              <AsyncSelect
                defaultOptions
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedSetor(null);
                  }
                }}
                value={selectedSetor}
                placeholder='Selecione o setor...'
                onChange={(e) => {
                  setSelectedSetor(e);
                }}
                loadOptions={loadOptionsSetores}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Tipo de Despesa
              </label>
              <AsyncSelect
                defaultOptions
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedTipoDespesa(null);
                  }
                }}
                value={selectedTipoDespesa}
                placeholder='Selecione o tipo de despesa...'
                onChange={(e) => {
                  setSelectedTipoDespesa(e);
                }}
                loadOptions={loadOptionsTiposDespesa}
              />
            </Col>
            <Col>
              <label className='form-label'>Valor:</label>
              <input
                type='text'
                placeholder='Valor...'
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valor || 0)}
                className={`form-control`}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  inputValue = inputValue.replace(/\D/g, '');

                  if (inputValue !== null) {
                    inputValue = inputValue.replace(/^0+/, '');
                    inputValue = inputValue || '0';

                    const numericValue = parseInt(inputValue, 10);
                    const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                    setValor(numericValue / 100);
                  } else {
                    setValor(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
          <Col>
              <label htmlFor='' className='form-label'>
                Quantidade de parcelas
              </label>
              <select 
                className='form-select'
                value={selectedParcela ? selectedParcela.value : ""}
                onChange={(e) => {
                  const inputValue = e.target.value; 
                  if (Number(inputValue) === 0) {
                    setSelectedParcela(null);
                  } else {
                  formDataState.setSelectedParcela({ value: Number(e.target.value), label: `${e.target.value}x` }); 
                  }
                }}
              >
                <option value="">Selecione uma parcela</option>
                <option value={1}>à vista</option>
                {parcelas.slice(1).map((parcela) => (
                  <option key={parcela} value={parcela}>
                    {`${parcela}x`}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  formDataState.setStatus
                    ? formDataState.setStatus(e.target.value)
                    : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  formDataState.setDescricao
                    ? formDataState.setDescricao(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Fornecedor</th>
                  <th className='text-center'>Empresa</th>
                  <th className='text-center'>Setor</th>
                  <th className='text-center'>Tipos de Despesa</th>
                  <th className='text-center'>Valor</th>
                  <th className='text-center'>Quant. de Parcelas</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {contasAPagar?.map((contaAPagar) => (
                  <tr className='text-center'>
                    <td>{contaAPagar.id}</td>
                    <td>{contaAPagar.fornecedor ? contaAPagar.fornecedor : '...'}</td>
                    <td>{contaAPagar.empresa ? contaAPagar.empresa : '...'}</td>
                    <td>{contaAPagar.setor ? contaAPagar.setor : '...'}</td>
                    <td>{contaAPagar.tipoDespesa ? contaAPagar.tipoDespesa : '...'}</td>
                    <td>
                      {contaAPagar.valor
                        ? `R$ ${Number(contaAPagar.valor).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : 'R$ 0,00'}
                    </td>
                    <td>{contaAPagar.qtdParcelas}</td>
                    <td>{contaAPagar.descricao ? contaAPagar.descricao : '...'}</td>
                    <td>{contaAPagar.usuarioCriacaoNome}</td>
                    <td>{contaAPagar.dataCriacao}</td>
                    <td>
                      {contaAPagar.usuarioAlteracaoNome ? contaAPagar.usuarioAlteracaoNome : '...'}
                    </td>
                    <td>{contaAPagar.dataAlteracao}</td>
                    <td>{contaAPagar.status}</td>
                    <td>
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-success btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          Opções
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <button
                              className='dropdown-item'
                              onClick={() => {
                                openModal(
                                  true,
                                  contaAPagar.id,
                                  contaAPagar.idFornecedor,
                                  contaAPagar.fornecedor,
                                  contaAPagar.idEmpresa,
                                  contaAPagar.empresa,
                                  contaAPagar.idSetor,
                                  contaAPagar.setor,
                                  contaAPagar.idTipoDespesa,
                                  contaAPagar.tipoDespesa,
                                  contaAPagar.valor,
                                  contaAPagar.qtdParcelas,
                                  contaAPagar.status,
                                  contaAPagar.descricao
                                );
                              }}
                            >
                              <i className='bi-pencil'> </i>
                              <span className='mx-1'>Editar</span>
                            </button>
                          </li>
                          <li>
                            <Link
                              to={`/listagem-parcelas/${contaAPagar.id}`}
                              className='dropdown-item'
                              type='Button'
                            >
                              <i className='bi-box-arrow-up-right'></i>
                              <span className='mx-2'>Gerenciar Parcelas</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalContasAPagar
        isOpen={isContasAPagarModal}
        onHide={closeContasAPagarModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadContasAPagar={fetchContasAPagar}
      />
    </div>
  );
};

export default ListagemContasAPagar;
