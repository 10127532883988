import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {IStatusAnalise, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type ModalStatusAnaliseProps = {
  isOpen: boolean;
  onHide: () => void;
  data: IStatusAnalise | null;
  isEdit: boolean;
  loader: ({page}) => Promise<void>;
};

const ModalStatusAnalise = ({isOpen, onHide, data, isEdit, loader}: ModalStatusAnaliseProps) => {
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarMarca = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-status-analise`;
      if (descricao && titulo && status) {
        const response = await axios.post(url, {
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Status da Análise cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarMarca = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-status-analise/${data?.id}`;
      if (titulo && descricao && status) {
        const response = await axios.put(url, {
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Status da Análise editada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarMarca() : cadastrarMarca())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-status-analise/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setDescricao(response.descricao);
          setTitulo(response.titulo);
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Status da Análise
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Titulo:
                </label>
                <input
                  type='text'
                  value={titulo ? titulo : ''}
                  className='form-control'
                  onChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                  placeholder='Digite o titulo'
                />
                {(titulo === null || titulo === '' || titulo === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Titulo</span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Descrição:
                </label>
                <textarea
                  value={descricao ? descricao : ''}
                  className='form-control'
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                />
                {(descricao === null || descricao === '' || descricao === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a Descrição</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalStatusAnalise;
