import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { CrudHeader, IQuantidadeEmpreendimentos, smoothScrollToBottom, TableListagem, TipoLocalizacao, useModal } from "../../../shared";
import ButtonEditListagem from "../segmento-empreendimento/components/ButtonEditListagem";
import AsyncSelect from "react-select/async";
import ModalSegmentoQuantidade from "./components/ModalSegmentoQuantidade";
import { ClienteOption } from "../../cadastros/proposta-pedido/types/interface";
import { useParams } from "react-router-dom";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const SegmentoEmpreendimento = () => {
    const { id } = useParams()

    const [segmentoQuantidade, setSegmentoQuantidade] = useState<IQuantidadeEmpreendimentos[]>([])
    const [dataLine, setDataLine] = useState<TipoLocalizacao[]>([])
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [selectedSegmento, setSelectedSegmento] = useState<ClienteOption | null>(null)
    const [empreendimentoTitle, setEmpreendimentoTitle] = useState<string | null>(null)
    const [quantidade, setQuantidade] = useState<number | null>(null)
    const [page, setPage] = useState(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)

    const colums = ['Id', 'Empreendimento', 'Segmento', 'Quantidade', 'Criado por', 'Data de criação', 'Alterado por', 'Data de alteração', 'Status', 'Ações']

    const [isSegmentoQuantidadeModal, openSegmentoQuantidadeModal, closeSegmentoQuantidadeModal] = useModal();

    
    const fetchSegmentoQuantidade = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos-quantidade/listar`,
                {
                    params: {
                        idEmpreendimento: id,
                        quantidade: quantidade ? quantidade : null,
                        idSegmento: selectedSegmento ? selectedSegmento.value : null,
                        observacao: descricao ? descricao : null,
                        status: status,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                setSegmentoQuantidade((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Segmento Quantidade para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    };
    const getEmpreendimentoById = async () => {
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/${id}`,

            );

            if (response.status === 200) {
                setEmpreendimentoTitle(response.data.titulo)
            }
        }
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        }
    };
    const loadOptionsSegmento = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const clearFiltros = () => {
        setDescricao(null)
        setStatus('ATIVO')
        setQuantidade(null)
        setSelectedSegmento(null)
        setIsClear(true)
        setPage(0)
    }
    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchSegmentoQuantidade(0);
    };
    const openModal = (isEdit = false, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openSegmentoQuantidadeModal();
    }


    useEffect(() => {
        fetchSegmentoQuantidade(page)
        getEmpreendimentoById()
    }, [page]);

    useEffect(() => {
        if (isClear) {
            fetchSegmentoQuantidade(0)
        }
        setIsClear(false)
    }, [isClear])

    return (
        <div>
            <CrudHeader to={'/empreendimentos'} title='Segmentos Quantidade' titleCadastro='Segmentos Quantidade' clearFiltros={clearFiltros} handleSearch={handleSearch} isLoading={isLoading} openModal={openModal} >
                <Row className="row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Empreendimento:</label>
                        <AsyncSelect isDisabled value={{ value: 1, label: empreendimentoTitle }}></AsyncSelect>
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label"  >Segmento:</label>
                        <AsyncSelect
                            value={selectedSegmento}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    setSelectedSegmento(null);
                                }
                            }}
                            onChange={(selectedOption: ClienteOption | null) => {
                                if (selectedOption) {
                                    const idValue = isNaN(Number(selectedOption.value))
                                        ? selectedOption.value // Caso seja texto, mantêm como string.
                                        : Number(selectedOption.value);
                                    console.log(selectedOption.value)
                                }
                                setSelectedSegmento(selectedOption);
                                console.log(selectedOption)
                            }}
                            loadOptions={loadOptionsSegmento}
                            cacheOptions 
                            defaultOptions 
                            placeholder="Selecione o segmento"
                        ></AsyncSelect>
                    </Col>
                </Row>
                <Row className="row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Quantidade:</label>
                        <input type="number"
                            className="form-control"
                            value={quantidade ? quantidade : ''}
                            onChange={(e) => {
                                setQuantidade(Number(e.target.value))
                            }}
                            placeholder="0" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row>
                    <Col className="">
                        <label htmlFor="" className="form-label">Observação:</label>
                        <textarea name=""
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                            placeholder="Digite a observação" className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>

            <TableListagem
                isLoading={isLoading}
                coluns={colums}
                hasMore={hasMore}
                loadMore={loadMore}>
                {segmentoQuantidade?.map((quantidade) => (
                    <tr className="text-center">
                        <td>{quantidade.id}</td>
                        <td>{quantidade.empreendimento}</td>
                        <td>{quantidade.segmento}</td>
                        <td>{quantidade.quantidade}</td>
                        <td>{quantidade.usuarioCriacaoNome}</td>
                        <td>{quantidade.dataCriacao}</td>
                        <td>{quantidade.usuarioAlteracaoNome ? quantidade.usuarioAlteracaoNome : "..."}</td>
                        <td>{quantidade.dataAlteracao}</td>
                        <td>{quantidade.status}</td>
                        <td>
                            <ButtonEditListagem handleEdit={() => { openModal(true, quantidade.id) }}></ButtonEditListagem>
                        </td>
                    </tr>
                ))
                }
            </TableListagem>

            <ModalSegmentoQuantidade
                clearFiltros={clearFiltros} 
                dataLine={dataLine} 
                reload={fetchSegmentoQuantidade} 
                isEdit={isEdit} 
                isOpen={isSegmentoQuantidadeModal} 
                onHide={closeSegmentoQuantidadeModal}
            ></ModalSegmentoQuantidade>
        </div>
    )
}

export default SegmentoEmpreendimento