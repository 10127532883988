import { Col, Row } from "react-bootstrap"
import { cadastroUnidadeMedidaValidationSchema, DescriptionComponent, FooterForm } from "../../../shared"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { postSuprimento, putSuprimento} from "../cadastro_requests/cadastro_requests";
import Swal from "sweetalert2";
import { getSuprimentoById } from "../../listagem/listagem_requests/listagem_requests";

const CadastroSuprimento = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    titulo: '',
    descricao: '',
    status: 'ATIVO',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: cadastroUnidadeMedidaValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true)
      if (id) {
        try {
          await putSuprimento(id, values)
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Suprimento editado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de Suprimento',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/suprimentos-listagem');
            },
          });
        }
        catch (error: any) {
          console.log(error)
        }


      }
      else {
        try {
          const response = await postSuprimento(values)

          if (response.status === 201) {
            // setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Suprimento cadastrado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de suprimentos',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/suprimentos-listagem');
              },
            });
          }
        }
        catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o suprimento, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o suprimento. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          // setSubmitting(false);
          setIsLoading(false);
        }
      }
    }
  })
  const getSuprimentoDataById = async () => {
    if (id) {
      try {
        const response = await getSuprimentoById(id);

        const { data } = response;

        formik.setValues({
          titulo: data.titulo || '',
          descricao: data.descricao || '',
          status: data.status || 'ATIVO',
        });

      } catch (errors) {
        console.log(errors);
      }
    }
  };

  useEffect(() => {
    getSuprimentoDataById()
  }, [])
  return (
    <>
      <DescriptionComponent
        description={id ? 'Edição de Status' : 'Cadastro de Status'}
        withLink={true}
        buttonTitle='Listar Status'
        redirectTo='/suprimentos-listagem'
      />
      <form onSubmit={formik.handleSubmit} className='form-control-solid' noValidate>
        <Row className='col-12 mb-4'>
          <Col className='col-sm-6'>
            <label className='form-label'>Titulo:</label>
            <input
              type='text'
              placeholder='Digite o título do status'
              {...formik.getFieldProps('titulo')}
              className={`form-control 
                
                ${formik.touched.titulo && formik.errors.titulo ? 'is-invalid' : ''}                `}
            />

            {formik.touched.titulo && formik.errors.titulo ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.titulo}
              </div>
            ) : null}
          </Col>
          <Col className='col-sm-6'>
            <div>
              <label className='form-label'>Status:</label>
              <select
                {...formik.getFieldProps('status')}
                className={`form-control 
                `}
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.status}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='col-12'>
          <Col className='col-6-sm'>
            <label className='form-label'>Descrição:</label>
            <textarea
              placeholder='Digite a descrição do status'
              {...formik.getFieldProps('descricao')}
              className={`form-control 
            `}
            />
            {formik.touched.descricao && formik.errors.descricao ? (
              <div className='invalid-feedback' style={{ fontWeight: 'bold' }}>
                {formik.errors.descricao}
              </div>
            ) : null}
          </Col>
        </Row>
        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Status' : 'Cadastrar Status'}
          redirectTo='suprimentos-listagem'
        />
      </form>
    </>
  )
}




export default CadastroSuprimento