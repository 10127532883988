import React from 'react';
import {formatarData} from '../../../../../../shared/core';

const TableComponent = ({
  columns,
  pendencias,
  loading,
  selectAll,
  handleSelectAll,
  handlePendenciaSelection,
  sortConfig,
  sortData,
}) => {
  return (
    <div
      className='text-center table-container table-responsive border border-secondary rounded'
      style={{overflowY: 'auto', overflowX: 'auto'}}
    >
      {loading ? (
        <div className='d-flex justify-content-center align-items-center' style={{height: '100%'}}>
          <div
            className='spinner-border text-success m-5'
            style={{width: '3rem', height: '3rem'}}
          ></div>
        </div>
      ) : (
        <table
          className='table-hover tablehist table-rounded table-row-bordered'
          style={{width: '180vw'}}
        >
          <thead className='thead-dark' style={{position: 'sticky', top: '0', zIndex: '0'}}>
            <tr>
              {columns.map(({label, accessor}) => (
                <th
                  className='text-center'
                  style={{
                    height: '60px',
                    paddingInline: '1em',
                    // cursor: accessor === 'selected' ? 'default' : 'pointer',
                  }}
                  key={accessor}
                  // onClick={accessor === 'selected' ? undefined : () => sortData(accessor)}
                >
                  {label}
                  {/* {accessor !== 'selected' && (
                    <span>
                      {sortConfig?.key === accessor
                        ? sortConfig.direction === 'asc'
                          ? ' ▴'
                          : ' ▾'
                        : ' ▾'}
                    </span>
                  )} */}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='fw-bold fs-6 text-gray-600'>
            {pendencias.map((data, index) => (
              <tr key={index} style={{height: '60px'}}>
                {columns.map(({accessor}) => {
                  let tData = data[accessor] ?? '—';
                  if (accessor === 'selected') {
                    return (
                      <td className='text-center' style={{width: '5em'}} key={accessor}>
                        <div className='d-flex flex-column align-items-center form-check'>
                          <input
                            className='form-check-input'
                            style={{cursor: 'pointer'}}
                            type='checkbox'
                            checked={data.selected || selectAll}
                            onChange={() => handlePendenciaSelection(data)}
                          />
                        </div>
                      </td>
                    );
                  } else if (accessor === 'dataPedido' || accessor === 'dataPendencia') {
                    if (typeof tData === 'string' && tData.match(/^\d{4}-\d{2}-\d{2}T/)) {
                      const date = new Date(tData);
                      tData = date.toLocaleDateString('pt-BR');
                    } else if (tData instanceof Date) {
                      tData = tData.toLocaleDateString('pt-BR');
                    }
                  }
                  return (
                    <td className='text-center' key={accessor}>
                      {tData}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableComponent;
