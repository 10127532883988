import {Badge, Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  CrudHeader,
  IGarantiaProduto,
  ISelectOption,
  smoothScrollToBottom,
  TableListagem,
  useModal,
} from '../../../shared';
import ButtonEditListagem from '../../empreendimento/segmento-empreendimento/components/ButtonEditListagem';
import ModalGarantiaProduto from './components/ModalGarantiaProduto';
import AsyncSelect from 'react-select/async';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const GarantiaProduto = () => {
  // VARIAVEIS
  const [garantias, setGarantias] = useState<IGarantiaProduto[]>([]);
  const [dataLine, setDataLine] = useState<IGarantiaProduto[]>([]);

  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [quantidade, setQuantidade] = useState<number>(0);
  const [idGarantia, setIdGarantia] = useState<string | null>(null);
  const [selectedUnidade, setSelectedUnidade] = useState<ISelectOption | null>(null);
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const colums = [
    'Id',
    'Quantidade',
    'Unidade de tempo',
    'Criado por',
    'Data de criação',
    'Alterado por',
    'Data de alteração',
    'Status',
    'Ações',
  ];

  const [isGarantiaProdutoModal, openGarantiaProdutoModal, closeGarantiaProdutoModal] = useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };

  const loadOptionsUnidade = async (inputValue: string, state: Function) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-tempo/listar`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      return data.content.map((item) => ({
        value: item.id,
        label: item.descricao,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const fetchGarantiaProduto = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/garantia/listar`,
        {
          params: {
            status: status,
            quantidade: quantidade ? quantidade : null,
            idUnidadeTempo: selectedUnidade ? selectedUnidade?.value : null,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
        }
        setGarantias((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Garantia de produto para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      } else {
        const {data} = error.response;
        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          timer: 3000,
          timerProgressBar: true,
          showCancelButton: false,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearFiltros = () => {
    setSelectedUnidade(null);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    setQuantidade(0);
    setIsClear(true);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchGarantiaProduto(0);
  };

  const openModal = (isEdit = false, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openGarantiaProdutoModal();
  };

  useEffect(() => {
    if (isClear) {
      fetchGarantiaProduto(0);
    }
    setIsClear(false);
  }, [isClear]);

  useEffect(() => {
    fetchGarantiaProduto(page);
  }, [page]);

  return (
    <div>
      <CrudHeader
        title='Garantia de Produto'
        titleCadastro='Garantia'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        isLoading={isLoading}
        openModal={openModal}
      >
        <Row className=' row'>
          <Col className='col-12 col-md-6 mt-6 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Quantidade:
            </label>
            <input
              type='text'
              className='form-control'
              value={quantidade ? quantidade : ''}
              onChange={(e) => {
                const rawValue = e.target.value;
                const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéric
                setQuantidade(Number(cleanedValue));
              }}
              placeholder='Digite o quantidade'
            />
          </Col>
          <Col className='col-12 col-md-6 mt-6 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Unidade de Tempo:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              isClearable
              className='py-2 react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione a Unidade de Tempo'
              loadOptions={loadOptionsUnidade}
              value={selectedUnidade}
              onChange={(selectedOption: ISelectOption | null) => {
                if (selectedOption) {
                  setSelectedUnidade(selectedOption);
                } else {
                  setSelectedUnidade(null);
                }
              }}
              cacheOptions
              defaultOptions
            />
          </Col>
          <Col className='col-12 col-md-6 mt-6 '>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </CrudHeader>

      <TableListagem isLoading={isLoading} coluns={colums} hasMore={hasMore} loadMore={loadMore}>
        {garantias?.map((garantia) => (
          <tr className='text-center'>
            <td>{garantia.id}</td>
            <td>{garantia.quantidade}</td>
            <td>{garantia.unidadeTempo}</td>
            <td>{garantia.usuarioCriacaoNome}</td>
            <td>{garantia.dataCriacao}</td>
            <td>{garantia.usuarioAlteracaoNome ? garantia.usuarioAlteracaoNome : '...'}</td>
            <td>{garantia.dataAlteracao}</td>
            <td>
              <Badge
                className='text-white p-3 px-4 '
                bg={`${garantia.status === 'ATIVO' ? 'success' : 'danger'}`}
              >
                {garantia.status}
              </Badge>
            </td>

            <td>
              <ButtonEditListagem
                handleEdit={() => {
                  openModal(true, garantia.id);
                }}
              ></ButtonEditListagem>
            </td>
          </tr>
        ))}
      </TableListagem>

      <ModalGarantiaProduto
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchGarantiaProduto}
        isEdit={isEdit}
        isOpen={isGarantiaProdutoModal}
        onHide={closeGarantiaProdutoModal}
      ></ModalGarantiaProduto>
    </div>
  );
};

export default GarantiaProduto;
