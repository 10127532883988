import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {AsyncSelectEmpresa, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalTiposDespesas = ({isOpen, onHide, dataLine, isEdit, loadTiposDespesas}) => {
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const {id} = useParams();

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onClose = () => {
    onHide();
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
    onClear();
  };
  const onClear = () => {
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarTiposDespesas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas`;
      if (titulo) {
        const response = await axios.post(url, {
          idGrupoDespesas: id ? id : '',
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status,
        });

        Swal.fire({
          icon: 'success',
          title: `Tipo despesa cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClear();
          onClose();
          loadTiposDespesas();
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Informe o título',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const editarTiposDespesas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/${dataLine[0]}`;
      if (titulo) {
        const response = await axios.put(url, {
          idGrupoDespesas: id ? id : '',
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status,
        });
        Swal.fire({
          icon: 'success',
          title: `Tipo despesa editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadTiposDespesas();
        });
        setTimeout(() => {}, 3000);
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarTiposDespesas() : cadastrarTiposDespesas())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setStatus(dataLine[5]);
      setDescricao(dataLine[2]);
      setTitulo(dataLine[1]);
    }
  }, [dataLine]);
  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Tipo de Despesas
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id Grupo:
                </label>
                <input
                  value={id}
                  disabled
                  className='form-control'
                  type='number'
                  placeholder='Id do Grupo'
                />
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Titulo:
                </label>
                <input
                  type='text'
                  value={titulo ? titulo : ''}
                  className='form-control'
                  onChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                  placeholder='Digite o titulo'
                />
                {(titulo === null || titulo === '' || titulo === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Titulo</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe a empresa</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalTiposDespesas;
