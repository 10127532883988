import {Spinner} from 'react-bootstrap';

type IProps = {
  isHasMore?: boolean;
  isLoadingSearch: boolean;
  loadMore: () => void;
};

const LoadMore: React.FC<IProps> = ({isHasMore, isLoadingSearch, loadMore}) => {
  return (
    <>
      {isHasMore && (
        <div className='d-flex justify-content-center align-items-center'>
          <button className='btn btn-primary m-5' onClick={loadMore}>
            {isLoadingSearch ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
          </button>
        </div>
      )}
    </>
  );
};

export default LoadMore;
