import axios from 'axios';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { ProductOption } from '../../../pages/cadastros/proposta-pedido/types/interface';
import { formatEndereco, truncateNome } from '../../core/adapters';
import { ICliente, ISelectOption } from '../../domain-types';
import './async-select-cliente-empresas-styles.scss';

import useBuscaClienteCredito from '../../../pages/useUtils/useBuscaClienteCredito';

type IProps = {
  handleOptions?: (product: ProductOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectClienteEmpresas: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedClienteAsync, setSelectClienteAsync] = useState<ISelectOption | null>(null);
  const [clienteDetails, setClienteDetails] = useState<ICliente[]>();

  const buscarSaldoCliente = useBuscaClienteCredito();

  const loadOptionCliente = async (inputValue: string | number) => {
    let ref = false;
    // FILTRAR POR NUM DE CARACTERS
    if (String(inputValue).length >= 3) {
      ref = true;
    }
    if (ref) {
      try {
        const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes?cnpj=true&filtro=nome&search=${inputValue}`;
        const response = await axios.get(url);
        const content = await response.data.content;

        console.log(response)

        setClienteDetails(content);

        return content.map((item) => ({
          value: item.id,
          label: item.clienteFantasia || 'SEM DADOS',
        }));
      } catch (error) {
        console.error('Erro ao carregar opções de cliente:', error);
        return [];
      }
    }
  };

  const loadOptionClienteById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/${id}`;
      const response = await axios.get(url);
      const data = await response.data;

      setClienteDetails([data]);

      setSelectClienteAsync({
        value: data.idCliente,
        label: data.idCliente,
      });

      return [
        {
          value: data.idCliente,
          label: data.clienteFantasia,
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de cliente:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const cliente = clienteDetails?.find((cliente) => Number(cliente.id) == option.value);

    if (cliente) {

      return (
        <div className='info-container cliente'   >
          <p>
            <span> {cliente.fantasia ? truncateNome(cliente.fantasia) : '-'}</span>
          </p>
          <div className='columns'>
            <div className='column left'>
              <p>
                CNPJ: <span>{cliente.cnpj || ' - '}</span>
              </p>
              <p>
                Endereço: <span>{formatEndereco(cliente)}</span>
              </p>
              <p>
                Telefone: <span>{cliente.telefone || ' - '}</span>
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    console.log('sim', clienteDetails);
  }, [clienteDetails]);

  useEffect(() => {
    if (inputOption) {
      loadOptionClienteById(inputOption.value);
    } else {
      setSelectClienteAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Cliente'
      loadOptions={loadOptionCliente}
      value={selectedClienteAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectClienteAsync(selectedOption);
          handleOptions?.(selectedOption)
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
    />
  );
};

export default AsyncSelectClienteEmpresas;
