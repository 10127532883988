import {useTable, Column, Row, ColumnInstance} from 'react-table';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';

type TableProps<T extends object> = {
  data: T[];
  columns: Column<T>[];
  message: string;
  maxHeight?: string
};

const TableSysled = <T extends object>({data, columns, message, maxHeight}: TableProps<T>) => {
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  return (
    <div className='table-responsive' style={{ maxHeight: maxHeight }}>
      <table
        id='kt_table_formularios'
        className='table table-hover table-striped table-rounded table-row-bordered border px-4'
        {...getTableProps()}
      >
        <thead className='thead-dark'>
          <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map((column: ColumnInstance<T>, index: number) => (
              <th key={column.id} className={index === 0 ? 'text-end' : 'text-start'}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<T>, i) => {
              prepareRow(row);
              return <CustomRow row={row} key={`row-${i}-${row.id}`} showEditButton />;
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  { message ? message : 'SEM DADOS' }
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableSysled;
