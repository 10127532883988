import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import {smoothScrollToBottom, useModal, ILinhaProdutoRequest, ILinhaProduto} from '../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomRow} from '../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getLinhaProdutoRequest} from '../listagem/listagem_requests/listagem_requests';
import ModalLinhaProduto from './components/ModalLinhaProdutos';
import {DateRangePicker} from 'rsuite';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {addDays, endOfMonth, format, startOfMonth, subDays} from 'date-fns';
import {formatInTimeZone, toZonedTime} from 'date-fns-tz';

const ListagemLinhaProdutos = () => {
  const linhaProdutoColumns: Column<ILinhaProduto>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{String(value)}</div>,
    },
    {
      Header: 'Codigo',
      accessor: 'codigo',
      Cell: ({value}) => <div className='text-start'>{value ? String(value) : '...'}</div>,
    },
    {
      Header: 'Linha',
      accessor: 'linha',
      Cell: ({value}) => <div className='text-start'>{value ? String(value) : '...'}</div>,
    },
    {
      Header: 'Data de Importação',
      accessor: 'dataImportacao',
      Cell: ({value}) => {
        if (value) {
          try {
            const formattedDate = format(new Date(value), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const [isLinhaProdutoData, setIsLinhaProdutoData] = useState<ILinhaProduto[]>([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLinhaProduto, setDataLinhaProduto] = useState<ILinhaProduto | null>(null);
  const [valueDateRangeImportacao, setValueDateRangeImportacao] = useState<DateRange | null>(null);

  const data = useMemo(() => isLinhaProdutoData, [isLinhaProdutoData]);
  const columns = useMemo(() => linhaProdutoColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: ILinhaProdutoRequest = {
    codigo: '',
    linha: '',
    dataImportacaoInicio: '',
    dataImportacaoFim: '',
    status: 'ATIVO',
  };

  function convertToLocalTimeZone(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd');
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);
      try {
        let dataImportacaoInicio = '';
        let dataImportacaoFim = '';
        if (valueDateRangeImportacao && valueDateRangeImportacao.length > 0) {
          dataImportacaoInicio = convertToLocalTimeZone(valueDateRangeImportacao[0]);
          dataImportacaoFim = convertToLocalTimeZone(valueDateRangeImportacao[1]);
        }
        const response = await getLinhaProdutoRequest(
          {
            codigo: values.codigo,
            linha: values.linha,
            dataImportacaoInicio,
            dataImportacaoFim,
            status: values.status,
          },
          0
        );

        const {content} = response.data;

        setIsLoadingSearch(false);
        setSubmitting(false);

        setIsLinhaProdutoData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoadingSearch(false);
        setSubmitting(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (marca: ILinhaProduto) => {
    openModal(true, marca);
  };

  //Get de categorias por modelo
  const getLinhaProduto = async ({page = 0}: {page: number}) => {
    setIsLoading(true);

    try {
      const response = await getLinhaProdutoRequest(initialValues, page);

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      setIsLinhaProdutoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsLoading(false);
    } catch (errors: any) {
      const {response} = errors;

      if (response) {
        const status = response.status;

        const {data} = errors?.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  const [isLinhaProdutoModal, openLinhaProdutoModal, closeLinhaProdutoModal] = useModal();

  const openModal = (isEdit: boolean, data?: ILinhaProduto) => {
    if (data) {
      setDataLinhaProduto(data);
    }
    setIsEdit(isEdit);
    openLinhaProdutoModal();
  };

  const clearFiltros = () => {
    formik.resetForm();
    setValueDateRangeImportacao(null);
    getLinhaProduto({page: 0});
  };

  const handleDateRangeChangeImportacao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangeImportacao(null);
    } else {
      setValueDateRangeImportacao([value[0], value[1]]);
    }
  };

  useEffect(() => {
    getLinhaProduto({page: isPage});
  }, [isPage]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Linha de Produto
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Linha de Produto
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Codigo:</label>
              <input
                type='text'
                placeholder='Digite o Codigo '
                {...formik.getFieldProps('codigo')}
                className='form-control'
                onChange={(event) => {
                  const value = event.target.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                  formik.setFieldValue('codigo', value);
                }}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Linha:</label>
              <input
                type='text'
                placeholder='Digite a Linha '
                {...formik.getFieldProps('linha')}
                className='form-control'
                value={formik.values.linha}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Data de Importação:</label>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeImportacao}
                value={valueDateRangeImportacao}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de vencimento'
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* TABELA */}
      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ILinhaProduto>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ILinhaProduto>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={() => handleOnEdit(row.original)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
      <ModalLinhaProduto
        isOpen={isLinhaProdutoModal}
        onHide={closeLinhaProdutoModal}
        isEdit={isEdit}
        data={dataLinhaProduto}
        loadData={getLinhaProduto}
      ></ModalLinhaProduto>
    </div>
  );
};

export default ListagemLinhaProdutos;
