import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

const RangePriceBetween = ({minPrice,setMinPrice,maxPrice,setMaxPrice,isClear=false}) => {

    const handleCLear=(event)=>{
      event.preventDefault()
      setMinPrice(0)
      setMaxPrice(0)
    }

    // const clear=()=>{
    //   // event.preventDefault()
    //   setMinPrice(0)
    //   setMaxPrice(0)
    // }

    // useEffect(()=>{
    //   console.log(minPrice)
    //   clear()
    // },[isClear])
    return <>
    <div className=' w-100 d-flex form-control p-0 pt-1'>
                  
                  <div className="d-flex ms-2">
                  <input
                    type='text'
                    // style={{width:`${Number(sizeMinPrice)}ch`, maxWidth: '16ch'}}
                    style={{width:'45%'}}
                    className='border-0 text-end ms-1 px-2 py-0 form-control fs-5  align-text-bottom  d-flex justify-content-end align-items-end' 
                    placeholder='Valor mínimo'
                    value={
                      (minPrice==='')? "R$ 0,00": 
                          `R$ ${parseFloat(String(minPrice)).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })}`
                          
                  }
                  onChange={(event) => {
                    const rawValue = event.target.value;
                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                    console.log(cleanedValue);
                    setMinPrice(null)
                      setMinPrice(numericValue.toString())
                }}
                  />
                  <span className="mx-0 px-0 text-black-50 fs-1 pb-1">~</span>
                  <input
                    type='text'
                    className='border-0   py-0 form-control fs-5  align-text-bottom d-flex justify-content-end align-items-end' 
                    placeholder='Valor máximo'
                    value={
                      maxPrice!==''
                          ? `R$ ${parseFloat(String(maxPrice)).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })}`
                          : "R$ 0,00"
                  }
                  onChange={(event) => {
                    const rawValue = event.target.value;
                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                    console.log(cleanedValue);
                    setMaxPrice(null)
                      setMaxPrice(numericValue.toString())
                }}
                  />
                  </div>
                  <button onClick={handleCLear} className="btn btn-close my-auto "  style={{width:'3%'}} ></button>
                  </div>
    </>;
};
export default RangePriceBetween