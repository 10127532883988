import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import {ClienteOption} from '../../../pages/cadastros/proposta-pedido/types/interface';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectTipoEntidade: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedTipoEntidade, setSelectedTipoEntidade] = useState<ClienteOption | null>(null);
  const [isTipoEntidadeFiltered, setIsTipoEntidadeFiltered] = useState<ISelectOption[]>([]);
  const [defaultTipoEntidadeOptions, setDefaultTipoEntidadeOptions] = useState<ISelectOption[]>([]);

  const filteredOptionsSegment = (inputValue: string) => {
    return isTipoEntidadeFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptionsTipoEntidade = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsSegment(inputValue));
  };

  const loadOptionsTipoEntidade = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logs/tipoEntidade/options`;

      const response = await axios.get(url);
      const data = await response.data;

      const options = data.map((item: any) => ({
        value: item.id,
        label: item.descricao,
      }));

      setIsTipoEntidadeFiltered(options);

      return options;
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsTipoEntidadeById = async (id: string | number = '') => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logs/tipoEntidade/options`;

      const response = await axios.get(url);
      const data = await response.data;

      const options = data.filter((item: any) => item.id === id);

      if (options.length > 0) {
        setSelectedTipoEntidade({label: options[0].descricao, value: options[0].id});
      }

      return options;
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchTipoEntidadeOptions = async () => {
      const options = (await loadOptionsTipoEntidade()) || [];
      setDefaultTipoEntidadeOptions(options);
    };

    fetchTipoEntidadeOptions();
  }, []);

  useEffect(() => {
    if (selectedTipoEntidade && handleOptions) {
      handleOptions(selectedTipoEntidade);
    }
  }, [selectedTipoEntidade]);

  useEffect(() => {
    if (inputOption && !selectedTipoEntidade) {
      loadOptionsTipoEntidadeById(inputOption.value);
    } else if (inputOption == null) {
      setSelectedTipoEntidade(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 99999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 99999,
        }),
      }}
      isClearable
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Tipo de Entidade'
      loadOptions={loadFilteredOptionsTipoEntidade}
      value={selectedTipoEntidade}
      onChange={(selectedOption: ISelectOption | null) => {
        // Verifica se selectedOption não é null antes de definir o estado
        if (selectedOption) {
          setSelectedTipoEntidade(selectedOption);
        } else {
          setSelectedTipoEntidade(null); // Define como null caso a opção seja anulada
        }
      }}
      cacheOptions
      defaultOptions={defaultTipoEntidadeOptions}
    />
  );
};

export default AsyncSelectTipoEntidade;
