import axios from "axios";
import Swal from "sweetalert2";
import { Estoque, EstoqueFiltro } from "../types/interface";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


// export const getPedidoById = async (id) => {
//     try {
//       const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${id}`);
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching user by ID:', error);
//       throw error;
//     }
//   };

//   export const getAllPedidos = async () => {
//     try {
//       const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas`);
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching user by ID:', error);
//       throw error;
//     }
//   };

//  export const fetchProductDescription = async (productId) => {
//     try {
//       const response = await axios.get(
//         `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/produtosLista/${productId}`
//       );
//       return response.data;
//     } catch (error) {
//       console.error('Erro ao buscar descrição do produto:', error);
//       return {}; 
//     }
//   };

 export const calcularEstoqueTotal = (estoque, setEstoqueTotal?) => {
    const somaEstoque = estoque.reduce((total, item) => {
      const estoqueProduto = parseFloat(item.EstoqueProduto);
      return total + (isNaN(estoqueProduto) ? 0 : estoqueProduto);
    }, 0);
    return somaEstoque;
  };

 export function formatarPrecoSemCifra(preco) {
    if (preco === undefined || preco === null) {
      return '0.00';
    }
    const precoArredondado = Number(preco);
    return `${precoArredondado}` || '0.00';
  }


  export function filtrarEstoqueDuplicado(estoque) {
    const estoqueFiltrado: EstoqueFiltro[] = [];
    const wmsExistentes = new Set<String>();

    estoque.forEach((item) => {
      if (!wmsExistentes.has(item.wms)) {
        estoqueFiltrado.push(item);
        wmsExistentes.add(item.wms);
      }
    });

    return estoqueFiltrado;
  }

  export const fetchInfoProduto = async (productValue, setLoading, setEstoque, setMarca, setUnidade, setTipoProduto,setPrecoCusto, setSelectedProductDetails, setPrecoPadrao, calculaTotal , quantidade, descontoProdutoSelecionado, setAmbienteComplemento, ambienteComplemento, setEstoqueTotal
    // setAtivaMargem
  ) => {
    
    try {
      setLoading(true);

      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${productValue}`
      );
      const produtos = response.data;

      if (produtos.length > 0) {
        const produtoInfo = produtos.find((produto) => produto.idProduto === productValue);

        if (produtoInfo) {
          setEstoque(produtoInfo.estoque)
          setMarca(produtoInfo.marca);
          setUnidade(produtoInfo.unidade);
          setTipoProduto(produtoInfo.tipoProduto);
          setPrecoCusto(produtoInfo.precoCusto);
          setSelectedProductDetails(produtoInfo); 
          setPrecoPadrao(produtoInfo.precoUnitario);
          calculaTotal(
            quantidade,
            produtoInfo.precoUnitario ?? undefined,
            descontoProdutoSelecionado
          );

          const estoqueFiltrado = filtrarEstoqueDuplicado(produtoInfo.estoque);
          const totalEstoque = calcularEstoqueTotal(estoqueFiltrado);
          
          setEstoqueTotal(totalEstoque)
          setAmbienteComplemento(ambienteComplemento || '');
          // setAtivaMargem(produtoInfo.ativaMargem);            
          

        } 
        // else {
        //   Swal.fire({
        //     icon: 'info',
        //     title: `Nenhum produto encontrado para o valor: ${productValue}`,
        //   });
        // }
      } 
      // else {
      //   Swal.fire({
      //     icon: 'info',
      //     title: `Nenhum produto encontrado para o valor: ${productValue}`,
      //   });
      // }
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
    } finally {
      setLoading(false);
    }
  };

// export const fetchProductStockInfoLocal = async (productId) => {
//   try {
//     const response = await axios.get<{estoque: Estoque[]}>(
//       `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaWmsEstoqueReservado/${productId}`
//     );
//     if (response.data.estoque.length > 0) {
//       return response.data.estoque;
//     }
//     return [];
//   } catch (error) {
//     console.error('Erro ao buscar informações do produto:', error);
//     return [];
//   }
// };

export const fetchProductAdditionalInfo = async (productId) => {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${productId}`
    );

    const produtos = response.data;
    let marca = '';
    let unidade = '';
    let tipoProduto = '';
    let precoCusto = '';
    let ativaMargem = 0;
    let precoPadrao = '';
    let estoque = [];

    if (produtos.length > 0) {
      const produtoInfo = produtos.find((produto) => produto.idProduto === productId);
      if (produtoInfo) {
        marca = produtoInfo.marca || '';
        unidade = produtoInfo.unidade || '';
        tipoProduto = produtoInfo.tipoProduto || '';
        precoCusto = produtoInfo.precoCusto || '';
        ativaMargem = produtoInfo.ativaMargem;
        precoPadrao = produtoInfo.precoUnitario ||  '';
        estoque = produtoInfo.estoque;
      }
    }

    return {marca, unidade, tipoProduto, precoCusto, ativaMargem, precoPadrao, estoque};
  } catch (error) {
    console.error('Erro ao buscar informações adicionais do produto:', error);
    return {marca: '', unidade: '', tipoProduto: '', precoCusto: '', ativaMargem: '', precoPadrao: '', estoque: []};
  }
};

