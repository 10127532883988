import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {formatarData, Modal} from '../../../../../shared';
import {useEffect, useState} from 'react';
import {ClienteOption} from '../../../../cadastros/proposta-pedido/types/interface';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Swal from 'sweetalert2';
import {addDays, endOfMonth, set, startOfMonth, subDays} from 'date-fns';
import {DateRangePicker} from 'rsuite';
import {id} from 'date-fns/locale';
import da from 'date-fns/esm/locale/da/index';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalEstoqueMovimento = ({isOpen, onHide, isEdit, dataLine, reload, clearFiltros}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null)
  const [selectedTipoMovimento, setSelectedTipoMovimento] = useState<ClienteOption | null>(null);
  const [dataMovimento, setDataMovimento] = useState<string | null>(null);
  const [selectedTipoEntidade, setSelectedTipoEntidade] = useState<ClienteOption | null>(null);
  const [selectedDocumento, setSelectedDocumento] = useState<ClienteOption | null>(null);
  const [selectedTipoLocalizacao, setSelectedTipoLocalizacao] = useState<ClienteOption | null>(
    null
  );
  const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [codigo, setCodigo] = useState<string | null>(null);

  const cadastrarEstoqueLocalizacao = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/movimento`;

      const response = await axios.post(url, {
        idDocumento: selectedDocumento?.value ? selectedDocumento?.value : null,
        idEntidadeDocumento: selectedTipoEntidade?.value ? selectedTipoEntidade?.value : null,
        idTipoMovimento: selectedTipoMovimento?.value ? selectedTipoMovimento?.value : null,
        dataMovimento: dataMovimento ? `${dataMovimento}T00:00:00.961Z` : null,
        status: status,
      });

      Swal.fire({
        icon: 'success',
        title: `Tipo de Estoque cadastrado com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        // clearForm()
      });
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
  };

  const editarEstoqueTipo = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/movimento/${dataLine[0]}`;
      const response = await axios.put(url, {
        idDocumento: selectedDocumento?.value ? selectedDocumento?.value : null,
        idEntidadeDocumento: selectedTipoEntidade?.value ? selectedTipoEntidade?.value : null,
        idTipoMovimento: selectedTipoMovimento?.value ? selectedTipoMovimento?.value : null,
        dataMovimento: dataMovimento ? `${dataMovimento}T00:00:00.961Z` : null,
        status: status,
      });
      Swal.fire({
        icon: 'success',
        title: `Tipo de Estoque editado com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        clearForm();
        onClose();
        reload();
      });
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
  };
  const onClose = () => {
    clearForm();
    clearFiltros();
    reload!();
    onHide!();
  };
  const loadOptionsDocumento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: `[${item.id}] ${item.titulo}`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsTipoEntidade = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/entidade-documentos/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: null,
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsTipoMovimento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-movimento/listar`;

      const response = await axios.get(url, {
        params: {
          size: 100,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}`,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const clearForm = () => {
    setStatus('ATIVO');
    setSelectedDocumento(null);
    setSelectedTipoMovimento(null);
    setSelectedTipoEntidade(null);
    setDataMovimento(null);
  };
  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarEstoqueTipo() : cadastrarEstoqueLocalizacao())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  function formatDate(inputDate: string): string {
    // Converte o inputDate (que pode ser no formato ISO ou no formato 'yyyy-mm-dd') para um objeto Date
    const date = new Date(inputDate);

    // Verifica se a data é válida
    if (isNaN(date.getTime())) {
      throw new Error('Data inválida');
    }

    // Obtém o ano, mês e dia
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é zero-indexed, por isso somamos 1
    const day = date.getDate().toString().padStart(2, '0'); // Garante que o dia tenha 2 dígitos

    // Retorna a data no formato 'yyyy-mm-dd'
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (isEdit) {
      setSelectedDocumento({
        value: dataLine[1],
        label: ` ${dataLine[2]}`,
      });
      setSelectedTipoEntidade({
        value: dataLine[3],
        label: ` ${dataLine[4]}`,
      });
      setSelectedTipoMovimento({
        value: dataLine[5],
        label: ` ${dataLine[6]}`,
      });
      // console.log(dataLine[7])
      setDataMovimento(formatDate(dataLine[7]));
      console.log(dataMovimento);
      setStatus(dataLine[8]);
    }
  }, [dataLine]);

  return (
    <Modal {...modalConfigs}>
      <div
        className='form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {isEdit ? 'Editar' : 'Cadastrar'} Movimento
        </h1>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      <div>
        {isEdit && (
          <h6 className='fw-bolder mt-4 ' style={{color: '#3f3f3f'}}>
            Documento:{' '}
            <span className='fw-bold text-primary me-5'>{dataLine[2] ? dataLine[2] : 'null'}</span>
            Tipo Entidade :{' '}
            <span className='fw-bold text-primary me-5'>{dataLine[4] ? dataLine[4] : 'null'}</span>
            Tipo Movimento :{' '}
            <span className='fw-bold text-primary'>{dataLine[6] ? dataLine[6] : 'null'}</span>{' '}
          </h6>
        )}
        <div>
          <form action=''>
            <Row className='col-12 mb-4 row mt-8'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Documento:
                </label>
                <AsyncSelect
                  value={selectedDocumento}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedDocumento(null);
                    }
                  }}
                  onChange={(selectedOption: ClienteOption | null) => {
                    if (selectedOption) {
                      const idValue = isNaN(Number(selectedOption.value))
                        ? selectedOption.value // Caso seja texto, mantêm como string.
                        : Number(selectedOption.value);
                      console.log(selectedOption.value);
                    }
                    setSelectedDocumento(selectedOption);
                    console.log(selectedOption);
                  }}
                  loadOptions={loadOptionsDocumento}
                  placeholder='Selecione o Documento'
                  className='mt-1'
                ></AsyncSelect>
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Tipo Entidade Documento :
                </label>
                <AsyncSelect
                  value={selectedTipoEntidade}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedTipoEntidade(null);
                    }
                  }}
                  onChange={(selectedOption: ClienteOption | null) => {
                    if (selectedOption) {
                      const idValue = isNaN(Number(selectedOption.value))
                        ? selectedOption.value // Caso seja texto, mantêm como string.
                        : Number(selectedOption.value);
                      console.log(selectedOption.value);
                    }
                    setSelectedTipoEntidade(selectedOption);
                    console.log(selectedOption);
                  }}
                  loadOptions={loadOptionsTipoEntidade}
                  placeholder='Selecione o Tipo da Entidade'
                  className='mt-1'
                ></AsyncSelect>
              </Col>
            </Row>
            <Row className='mt-5 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Tipo Movimento:
                </label>
                <AsyncSelect
                  value={selectedTipoMovimento}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setSelectedTipoMovimento(null);
                    }
                  }}
                  onChange={(selectedOption: ClienteOption | null) => {
                    if (selectedOption) {
                      const idValue = isNaN(Number(selectedOption.value))
                        ? selectedOption.value // Caso seja texto, mantêm como string.
                        : Number(selectedOption.value);
                      console.log(selectedOption.value);
                    }
                    setSelectedTipoMovimento(selectedOption);
                    console.log(selectedOption);
                  }}
                  loadOptions={loadOptionsTipoMovimento}
                  placeholder='Selecione o Tipo de Movimento'
                  className='mt-1'
                ></AsyncSelect>
              </Col>
              <Col className=''>
                {/* <d
                        iv className='col-sm-3' style={{ marginTop: '26px' }}> */}
                <label htmlFor='' className='form-label'>
                  Data do Movimento:
                </label>
                <input
                  type='date'
                  className='form-control'
                  onChange={(e) => {
                    setDataMovimento(e.target.value);
                  }}
                  value={dataMovimento ? dataMovimento : '111'}
                  placeholder='DATA EMISSAO'
                />
                {/* </div> */}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col className='col-6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEstoqueMovimento;
