import axios from 'axios';
import {useEffect, useState} from 'react';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
// import { AsyncSelectCliente, AsyncSelectClienteEmpresa, AsyncSelectEmpresa, Modal } from "../../../../shared";
// import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import {useNavigate, useParams} from 'react-router-dom';
import {ClienteOption} from '../../cadastros/proposta-pedido/types/interface';
import {AsyncSelectCliente, AsyncSelectClienteEmpresa, AsyncSelectEmpresa} from '../../../shared';
// import { formatDate } from 'date-fns';
// import { formatDate } from 'date-fns';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const CadastroProjetos = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null);
  const [selectedCliente, setSelectedCliente] = useState<ClienteOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirme, setIsConfirme] = useState<boolean>(false);
  const [selectedLightingDesigner, setSelectedLightingDesigner] = useState<ClienteOption | null>(
    null
  );
  const [selectedEmpresaArquitetura, setSelectedEmpresaArquitetura] =
    useState<ClienteOption | null>(null);
  const [selectedResponsavelInstalacao, setSelectedResponsavelInstalacao] =
    useState<ClienteOption | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [prioridade, setPrioridade] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<ClienteOption | null>(null);
  const [selectedSetor, setSelectedSetor] = useState<ClienteOption | null>(null);
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');

  const loadOptionsStatus = async (inputValue: string, state: Function) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-status/listar`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      return data.content.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsSetor = async (inputValue: string, state: Function) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      console.log(data.id);

      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const cadastrarProjeto = async () => {
    try {
      setIsLoading(true);
    //   if (
    //     selectedEmpresa &&
    //     selectedCliente &&
    //     selectedSetor &&
    //     dataInicio &&
    //     dataFim &&
    //     selectedStatus &&
    //     prioridade !== null &&
    //     prioridade !== '' &&
    //     titulo !== null &&
    //     titulo !== '' &&
    //     titulo !== ' '
    //   ) {
        setIsConfirme(true);
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos`;

        const response = await axios.post(url, {
          status: status,
          idEmpresa: selectedEmpresa ? selectedEmpresa.value : null,
          idSetor: selectedSetor ? selectedSetor.value : null,
          idCliente: selectedCliente ? Number(selectedCliente.value) : null,
          titulo: titulo ? titulo : null,
          descricao: descricao ? descricao : null,
          idProjetoStatus: selectedStatus ? selectedStatus.value : null,
          dataInicio: dataInicio ? `${dataInicio}T17:00:52.864` : null,
          dataFim: dataFim ? `${dataFim}T17:00:52.864` : null,
          prioridade: prioridade ? prioridade : null,
          idLightingDesigner: selectedLightingDesigner
            ? Number(selectedLightingDesigner.value)
            : null,
          idEmpresaArquitetura: selectedEmpresaArquitetura
            ? Number(selectedEmpresaArquitetura.value)
            : null,
          idResponsavelInstalacao: selectedResponsavelInstalacao
            ? Number(selectedResponsavelInstalacao.value)
            : null,
        });

        Swal.fire({
          icon: 'success',
          title: `Projeto cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          navigate('/projetos');
          clearForm();
        });
    //   } 
    //   else {
    //     setIsConfirme(false);
    //     console.log(
    //       selectedEmpresa,
    //       selectedCliente,
    //       selectedSetor,
    //       dataInicio,
    //       dataFim,
    //       selectedStatus,
    //       prioridade,
    //       titulo === null || titulo === '' || titulo === ' '
    //     );
    //   }
    }
    
    catch (errors: any) {
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data[0].mensagem,
        // title: `Erro no cadastro `,
        timer: 3000,
        timerProgressBar: true,
      });
      

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };
  function formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    /*************  ✨ Codeium Command 🌟  *************/
    // Check if the date is invalid

    if (isNaN(date.getTime())) {
      throw new Error('Data inválida');
    }

    // Extract year, month, and day from the date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    // Return the formatted date as a string
    return `${year}-${month}-${day}`;
  }
  const fetchDataProjetosById = async (page = 0) => {
    try {
      console.log(dataFim);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${id}`
      );

      const data = response.data;
      if (response.status === 200) {
        if (page === 0) {
          // setLogs([]);
        }
        console.log(response.data.content);
        setSelectedEmpresa({value: data.idEmpresa, label: ''});
        setSelectedCliente({value: data.idCliente, label: ''});
        setSelectedLightingDesigner(data.idLightingDesigner?{value: data.idLightingDesigner, label: ''}:null);
        setSelectedEmpresaArquitetura(data.idEmpresaArquitetura?{value: data.idEmpresaArquitetura, label: ''}:null);
        setSelectedResponsavelInstalacao(data.idResponsavelInstalacao?{value: data.idResponsavelInstalacao, label: ''}:null);
        setTitulo(data.titulo);
        setSelectedSetor({value: data.idSetor, label: data.setorTitulo});
        setSelectedStatus({value: data.idProjetoStatus, label: data.projetoStatusTitulo});
        setPrioridade(data.prioridade);
        setDataInicio(formatDate(data.dataInicio));
        setDataFim(formatDate(data.dataFim));
        setDescricao(data.descricao);
        setStatus(data.status);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    }
  };

  const editarProjeto = async () => {
    try {
      setIsLoading(true);
      // if (
      //   selectedEmpresa &&
      //   selectedCliente &&
      //   selectedSetor &&
      //   dataInicio &&
      //   dataFim &&
      //   selectedStatus &&
      //   prioridade !== null &&
      //   prioridade !== '' &&
      //   titulo !== null &&
      //   titulo !== '' &&
      //   titulo !== ' '
      // ) {
        // console.log(selectedStatus);
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${id}`;
        const response = await axios.put(url, {

          status: status,
          descricao: descricao ? descricao : '',
          idEmpresa: selectedEmpresa ? selectedEmpresa.value : null,
          idSetor: selectedSetor ? selectedSetor.value : null,
          idCliente: selectedCliente ? Number(selectedCliente.value) : null,
          titulo: titulo ? titulo : null,
          dataInicio: dataInicio ? `${dataInicio}T17:00:52.864` : null,
          dataFim: dataFim ? `${dataFim}T17:00:52.864` : null,
          prioridade: prioridade ? prioridade : null,
          idProjetoStatus: selectedStatus ? selectedStatus.value : null,
          idLightingDesigner: selectedLightingDesigner
            ? Number(selectedLightingDesigner.value)
            : null,
          idEmpresaArquitetura: selectedEmpresaArquitetura
            ? Number(selectedEmpresaArquitetura.value)
            : null,
          idResponsavelInstalacao: selectedResponsavelInstalacao
            ? Number(selectedResponsavelInstalacao.value)
            : null,
        });
        Swal.fire({
          icon: 'success',
          title: `Projeto editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          clearForm();
          navigate('/projetos');

          // reload!()
          // onClose()
        });
      } 
    //   else setIsConfirme(false);
    // }
     catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        // title: `Erro na edicao `,
        title: data[0].mensagem,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };
  const clearForm = () => {
    setSelectedEmpresa(null);
    setSelectedStatus(null);
    setSelectedSetor(null);
    setSelectedCliente(null);
    setSelectedLightingDesigner(null);
    setSelectedEmpresaArquitetura(null);
    setSelectedResponsavelInstalacao(null);
    setPrioridade(null);
    setTitulo(null);
    setDataFim(null);
    setDataInicio(null);
    setDescricao(null);
    setStatus('ATIVO');
  };
  useEffect(() => {
    if (id) {
      fetchDataProjetosById();
    }
  }, [id]);
  useEffect(() => {
    if (
      selectedEmpresa &&
      selectedCliente &&
      selectedLightingDesigner &&
      selectedEmpresaArquitetura &&
      selectedResponsavelInstalacao &&
    //   selectedSetor &&
    //   dataInicio &&
    //   dataFim &&
    //   selectedStatus &&
    //   prioridade !== null &&
    //   prioridade !== '' &&
      titulo !== null &&
      titulo !== '' &&
      titulo !== ' '
    ) {
      setIsConfirme(true);
    } else {
      setIsConfirme(false);
    }
  }, [
    selectedEmpresa,
    selectedSetor,
    selectedCliente,
    selectedLightingDesigner,
    selectedEmpresaArquitetura,
    selectedResponsavelInstalacao,
    titulo,
    // selectedStatus,
    // dataInicio,
    // dataFim,
    // prioridade,
  ]);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder ' style={{color: '#3f3f3f'}}>
          {id ? 'Editar' : 'Cadastrar'} Projeto
        </h1>
      </div>

      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid '}}></div>
      </div>

      <div className='mt-15 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Dados Gerais</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className='my-5 '>
        <Col>
            <label htmlFor='' className='form-label col-12 col-md-6 '>
              Titulo
            </label>
            <input
              type='text'
              onChange={(e) => setTitulo(e.target.value)}
              value={titulo ? titulo : ''}
              className='form-control'
              placeholder='Digite o Titulo'
            />
            {(titulo === null || titulo === '' || titulo === undefined) && (
              <span className={` form-label text-danger`}>*Por favor, informe o Titulo</span>
            )}
          </Col>
          <Col className='mt-5 mt-md-0 col-12 col-md-6 '>
            <label htmlFor='' className='form-label'>
              Empresa
            </label>
            <AsyncSelectEmpresa
              handleOptions={(option) => setSelectedEmpresa(option!)}
              inputOption={selectedEmpresa}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedEmpresa(null);
                }
              }}
            ></AsyncSelectEmpresa>
            {selectedEmpresa === null && (
              <span className={` form-label text-danger`}>*Por favor, informe a Empresa</span>
            )}
          </Col>
          
        </Row>

        <Row className=' my-5'>
          
          {/* <Col>
            <label htmlFor='' className='form-label'>
              Setor:
            </label>
            <AsyncSelect
              defaultOptions
              value={selectedSetor}
              onKeyDown={() => {
                setSelectedSetor(null);
              }}
              onChange={(option) => {
                setSelectedSetor(option);
              }}
              loadOptions={loadOptionsSetor}
              placeholder='Selecione o Setor'
            ></AsyncSelect>
            {selectedSetor === null && (
              <span className={` form-label text-danger`}>*Por favor, informe o Setor</span>
            )}
          </Col> */}
          {/* </Row> */}
          {/* <Row className=' my-8'>
          <Col>
            <label htmlFor='' className='form-label'>
              Prioridade:
            </label>
            <select
              name=''
              id=''
              onChange={(e) => setPrioridade(e.target.value)}
              value={prioridade ? prioridade : ''}
              className='form-select'
            >
              <option value=''>Selecione a prioridade</option>
              <option value='URGENTE'>URGENTE</option>
              <option value='IMPORTANTE'>IMPORTANTE</option>
              <option value='NAO_IMPORTANTE'>NAO IMPORTANTE</option>
            </select>
            {(prioridade === null || prioridade === '') && (
              <span className={` form-label text-danger`}>*Por favor, informe a prioridade</span>
            )}
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              Status do Projeto:
            </label>
            <AsyncSelect
              defaultOptions
              value={selectedStatus}
              onKeyDown={() => {
                setSelectedStatus(null);
              }}
              onChange={(option) => {
                setSelectedStatus(option);
              }}
              loadOptions={loadOptionsStatus}
              placeholder='Selecione o Status do Projeto'
            ></AsyncSelect>
            {selectedStatus === null && (
              <span className={` form-label text-danger`}>
                *Por favor, informe o Status do Projeto
              </span>
            )}
          </Col>
        </Row> */}
          {/* <Row className='my-8'>
          <Col>
            <label className='form-label' htmlFor=''>
              Data de fim
            </label>
            <input
              type='date'
              onChange={(e) => setDataFim(e.target.value)}
              value={dataFim ? dataFim : ''}
              name=''
              className='form-control'
              id=''
            />
            {(dataFim === null || dataFim === '') && (
              <span className={` form-label text-danger`}>*Por favor, informe a Data de fim</span>
            )}
          </Col>
          <Col>
            <label className='form-label' htmlFor=''>
              Data de inicio
            </label>
            <input
              type='date'
              onChange={(e) => setDataInicio(e.target.value)}
              value={dataInicio ? dataInicio : ''}
              name=''
              className='form-control'
              id=''
            />
            {(dataInicio === null || dataInicio === '') && (
              <span className={` form-label text-danger`}>
                *Por favor, informe a Data de inicio
              </span>
            )}
          </Col>
        </Row> */}
          {/* <Row className='my-8'> */}
          <Col className='col-12 col-md-6 mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Dados dos Profissionais e Clientes</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>

        <Row className='my-8'>
          <Col className='col-12 col-md-6 mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Cliente:
            </label>
            <AsyncSelectCliente
              handleOptions={(option) => setSelectedCliente(option!)}
              inputOption={selectedCliente}
              isSelectDOM={false}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedCliente(null);
                }
              }}
            />
            {selectedCliente === null && (
              <span className={` form-label text-danger`}>*Por favor, informe o Cliente</span>
            )}
          </Col>

          <Col className='col-12 col-md-6 mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Lighting Designer:
            </label>
            <AsyncSelectClienteEmpresa
              handleOptions={(option) => setSelectedLightingDesigner(option!)}
              inputOption={selectedLightingDesigner}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedLightingDesigner(null);
                }
              }}
            />
            {selectedLightingDesigner === null && (
              <span className={` form-label text-danger`}>
                *Por favor, informe o Lighting Designer
              </span>
            )}
          </Col>
        </Row>
        <Row className='my-8'>
          <Col className='col-12 col-md-6 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Empresa de Arquitetura:
            </label>
            <AsyncSelectClienteEmpresa
              handleOptions={(option) => setSelectedEmpresaArquitetura(option!)}
              inputOption={selectedEmpresaArquitetura}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedEmpresaArquitetura(null);
                }
              }}
            />
            {selectedEmpresaArquitetura === null && (
              <span className={` form-label text-danger`}>
                *Por favor, informe a Empresa  de Arquitetura
              </span>
            )}
          </Col> 

          <Col className='col-12 col-md-6 mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Responsável pela Instalação:
            </label>
            <AsyncSelectClienteEmpresa
              handleOptions={(option) => setSelectedResponsavelInstalacao(option!)}
              inputOption={selectedResponsavelInstalacao}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedResponsavelInstalacao(null);
                }
              }}
            />
            {selectedResponsavelInstalacao === null && (
              <span className={` form-label text-danger`}>
                *Por favor, informe o Responsável pela Instalação
              </span>
            )}
          </Col>
        </Row>
      </div>

      <div className='mt-15 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Informações adicionais</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className='my-4'>
          <Col>
            <label className='form-label' htmlFor=''>
              Descrição
            </label>
            <textarea
              name=''
              onChange={(e) => setDescricao(e.target.value)}
              value={descricao ? descricao : ''}
              placeholder='Digite a descrição do formularios'
              className='form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
      </div>

      <div className='d-flex justify-content-end mb-20 mt-10'>
        <div className=' d-flex justify-content-center'>
          <Button className='px-15 mx-9' onClick={() => navigate('/projetos')} variant='danger'>
            Voltar
          </Button>
          <Button
            className='px-15'
            disabled={!isConfirme}
            onClick={() => (id === undefined ? cadastrarProjeto() : editarProjeto())}
            variant='success'
          >
            {isLoading ? (
              <Spinner animation='border' size='sm' />
            ) : id === undefined ? (
              'Cadastrar'
            ) : (
              'Editar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CadastroProjetos;
