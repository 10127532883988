interface IButton {
  classIcon?: string;
  title: string;
  handleFunction: (data?: any) => void;
  isPdf?: boolean;
}

interface IDropDownProps {
  titleButton?: string;
  buttons: IButton[];
  isPdf?: boolean;
  isDisabled?: boolean
  
}

const DropDownButtons: React.FC<IDropDownProps> = ({titleButton, buttons, isPdf, isDisabled}) => {
  return (
    <>
      {isPdf ? (
        <div className='dropdown position-static'>
          <button disabled={isDisabled} className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
            <i className='bi bi-file-earmark-arrow-down'>PDF</i>
          </button>
          <ul
            className='dropdown-menu'
            style={{
              position: 'absolute',

              zIndex: 1050,
              top: '100%',
              left: '0',
            }}
          >
            {buttons.map((button, index) => (
              <li key={index}>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={() => button.handleFunction()}
                  style={{color: 'red'}}
                >
                  <span className='mx-2'>{button.title}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='dropdown position-static'>
          <button className='btn btn-success btn-sm dropdown-toggle' data-bs-toggle='dropdown'>
            {titleButton}
          </button>
          <ul
            className='dropdown-menu'
            style={{
              position: 'absolute',
              zIndex: 1050,
              top: '100%',
              left: '0',
            }}
          >
            {buttons.map((button, index) => (
              <li key={index}>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={() => button.handleFunction()}
                >
                  <i className={button.classIcon}></i>
                  <span className='mx-2'>{button.title}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default DropDownButtons;
