import {useEffect, useState} from 'react';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {FaEdit, FaPlus} from 'react-icons/fa';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {DatePicker} from 'rsuite';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {
  AsyncSelectPrevenda,
  AsyncSelectProduct,
  HttpClient,
  IComprador,
  IPendenciaCompra,
  IPendenciaCompraCrud,
  ISelectOption,
  IStatusGarantia,
  Modal,
  showAlert,
  statusGarantiaValidationSchema,
} from '../../../../shared';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  handleRequest?: () => void;
  idItem?: number | null;
  isEdit?: boolean;
};

const StatusGarantiaModal: React.FC<Props> = ({isOpen, handleRequest, idItem, onHide, isEdit}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [isDataPendencia, setIsDataPendencia] = useState<Date | null>(null);

  const initialValues = {
    titulo: '',
    status: 'ATIVO',
    descricao: '',
  };

  const http = new HttpClient();

  const formik = useFormik({
    initialValues,
    validationSchema: statusGarantiaValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true);
      if (isEdit) {
        try {
          await http.request<IStatusGarantia, IStatusGarantia>({
            method: 'PUT',
            url: `prevenda/status-garantia`,
            data: {
              ...values,
            },
            id: idItem!,
          });

          showAlert('success', 'Status de Garantia Editado com Sucesso');

          if (handleRequest) {
            handleRequest();
          }

          setSubmitting(false);
          setIsLoading(false);
          onClose();

          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          showAlert('error', data);

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          setIsLoading(true);

          await http.request<IStatusGarantia, IStatusGarantia>({
            method: 'POST',
            url: 'prevenda/status-garantia',
            data: {
              ...values,
            },
          });

          showAlert('success', 'Status de Garantia Cadastrado com Sucesso');

          if (handleRequest) {
            handleRequest();
          }

          setSubmitting(false);
          setIsLoading(false);
          setIsLoading(false);

          onClose();
        } catch (errors: any) {
          const {data} = errors.response;

          showAlert('error', data);

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const onClose = () => {
    onHide!();

    formik.setValues({
      titulo: '',
      status: 'ATIVO',
      descricao: '',
    });
    setIsDataPendencia(null);
  };

  const submitButton = (
    <Button type='submit' variant='success' onClick={() => formik.handleSubmit()}>
      {isLoading && <Spinner animation='border' size='sm' />}
      {!isLoading && (isEdit ? 'Editar' : 'Cadastrar')}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    title: isEdit ? 'Editar Status de Garantia' : 'Cadastrar Status de Garantia',
    onHide: onClose,
    size: 'xl',
  };

  const getStatusGarantiaById = async () => {
    setIsLoadingData(true);
    try {
      const response = await http.request<IStatusGarantia>({
        method: 'GET',
        url: 'prevenda/status-garantia',
        id: idItem!,
      });

      const {data} = response;

      formik.setValues({
        titulo: data.titulo!,
        status: data.status!,
        descricao: data.descricao!,
      });

      setIsLoadingData(false);
    } catch (errors: any) {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (isEdit && isOpen) {
      getStatusGarantiaById();
    }
  }, [idItem, isEdit, isOpen]);

  return (
    <>
      <Modal {...modalConfigs} icon={isEdit ? FaEdit : FaPlus}>
        <div>
          {isLoadingData ? (
            <Skeleton count={4} height={70} />
          ) : (
            <form className='form-control-solid' onSubmit={formik.handleSubmit}>
              <Row className='mt-4'>
                <Col md='6' className='col-12'>
                  <label className='form-label'>Título:</label>
                  <input
                    type='text'
                    placeholder='Digite o Título do Status'
                    className={`form-control ${
                      formik.touched.titulo && formik.errors.titulo ? 'is-invalid' : ''
                    }`}
                    {...formik.getFieldProps('titulo')}
                  />
                  {formik.touched.titulo && formik.errors.titulo ? (
                    <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                      {formik.errors.titulo}
                    </div>
                  ) : null}
                </Col>
                <Col md='6' className='col-12'>
                  <label className='form-label'>Status:</label>
                  <select
                    {...formik.getFieldProps('status')}
                    className={`form-control ${
                      formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                    }`}
                  >
                    <option value='1'>ATIVO</option>
                    <option value='0'>INATIVO</option>
                  </select>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col className='col-12'>
                  <label className='form-label'>Descrição:</label>
                  <textarea
                    placeholder='Digite a descrição do status de garantia'
                    {...formik.getFieldProps('descricao')}
                    className={`form-control ${
                      formik.touched.descricao && formik.errors.descricao ? 'is-invalid' : ''
                    }`}
                  />
                  {formik.touched.descricao && formik.errors.descricao ? (
                    <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                      {formik.errors.descricao}
                    </div>
                  ) : null}
                </Col>
              </Row>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default StatusGarantiaModal;
