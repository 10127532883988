import {Modal} from 'react-bootstrap';
import styled, {css} from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .modal-content {
    border: 0 none;
  }

  .modal-header {
    background-color: #fff;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .modal-title {
      display: flex;
      align-items: center;
      color: black;
      font-weight: bold;
      font-size: 1.3rem;

      gap: 10px;
    }

    .btn-close {
      background-size: 0.65rem;

      &:hover {
        filter: brightness(0.9);
        border-radius: 50%;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-body {
    color: black;
    background-color: #fff;
    padding: 1.5rem;
    max-height: 75vh;
    overflow-y: auto;
  }

  .modal-footer {
    text-align: right;
    background-color: #fff;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    & > * {
      margin: 0 0 0 0.85rem;
    }
  }
`;
