// @ts-nocheck
import clsx from 'clsx';
import {FC} from 'react';
import {Row} from 'react-table';

type Props<T extends object> = {
  row: Row<T>;
  onEdit: (id: number) => void;
  showEditButton: boolean;
  isAtendido: boolean;
};

const CustomRow: FC<Props> = ({row, isAtendido}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          style={{textAlign: 'center', verticalAlign: 'middle', height: '50px'}}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          style={{ color: isAtendido ? '#4fce89' : '' }}
        >
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

export {CustomRow};
