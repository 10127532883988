import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {AsyncSelectContasReceber, IMarcas, ISelectOption, Modal} from '../../../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import {useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type ModalContasReceberParcelasProps = {
  isOpen: boolean;
  onHide: () => void;
  data: IMarcas | null;
  isEdit: boolean;
  loader: ({page}) => Promise<void>;
};

const ModalContasReceberParcelas = ({
  isOpen,
  onHide,
  data,
  isEdit,
  loader,
}: ModalContasReceberParcelasProps) => {
  const {id} = useParams();
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valor, setValor] = useState<number | null>(null);
  const [isSelectAsyncContaReceber, setIsSelectAsyncContaReceber] = useState<ISelectOption | null>(
    null
  );
  const [isDataVencimento, setIsDataVencimento] = useState<Date | null>(null);
  const [isDataEmissao, setIsDataEmissao] = useState<Date | null>(null);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setDescricao(null);
    setStatus('ATIVO');
    setValor(null);
    setIsSelectAsyncContaReceber(null);
    setIsDataVencimento(null);
    setIsDataEmissao(null);
  };

  const cadastrarContasReceberParcelas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber-parcelas`;
      if (status && isSelectAsyncContaReceber && isDataVencimento && isDataEmissao && valor) {
        const response = await axios.post(url, {
          idContaReceber: isSelectAsyncContaReceber?.value,
          valor,
          dataVencimento: isDataVencimento,
          dataEmissao: isDataEmissao,
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Parcela de Conta a Raceber cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarContasReceberParcelas = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber-parcelas/${data?.id}`;
      if (status && isSelectAsyncContaReceber && isDataVencimento && isDataEmissao && valor) {
        const response = await axios.put(url, {
          idContaReceber: isSelectAsyncContaReceber?.value,
          valor,
          dataVencimento: isDataVencimento,
          dataEmissao: isDataEmissao,
          descricao: descricao ? descricao : '',
          status: status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Parcela de Conta a Raceber editada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarContasReceberParcelas() : cadastrarContasReceberParcelas())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen && isEdit) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-receber-parcelas/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setDescricao(response.descricao);
          setValor(response.valor);
          setIsDataVencimento(new Date(response.dataVencimento));
          setIsDataEmissao(new Date(response.dataEmissao));
          setIsSelectAsyncContaReceber({value: response.idContaReceber, label: ''});
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
    if (isOpen && isSelectAsyncContaReceber === null && id) {
      setIsSelectAsyncContaReceber({value: Number(id), label: ''});
    }
  }, [data, isOpen, isEdit, id]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Conta a Receber Parcelas
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='12'>
                <label className='form-label'>Conta a Receber:</label>
                <AsyncSelectContasReceber
                  handleOptions={(option) => setIsSelectAsyncContaReceber(option!)}
                  inputOption={isSelectAsyncContaReceber}
                  isDisabled
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncContaReceber(null);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Data de emissão:</label>
                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de emissão'
                  onChange={(date) => setIsDataEmissao(date)}
                  value={isDataEmissao}
                  menuStyle={{zIndex: 1000000}}
                />
                {(isDataEmissao === null || isDataEmissao === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a data de emissão
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label className='form-label'>Data de vencimento:</label>
                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de vencimento'
                  onChange={(date) => setIsDataVencimento(date)}
                  value={isDataVencimento}
                  menuStyle={{zIndex: 1000000}}
                />
                {(isDataVencimento === null || isDataVencimento === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a data de vencimento
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label className='form-label'>Valor:</label>
                <input
                  type='text'
                  placeholder='Digite o Valor '
                  className='form-control'
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(valor || 0)}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');
                    // Converte o valor para inteiro (centavos)
                    const valorEmCentavos = parseInt(inputValue, 10) / 100 || 0;
                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    setValor(valorEmCentavos);
                  }}
                />
                {(valor === null || valor === 0 || valor === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Valor</span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Descrição:
                </label>
                <textarea
                  value={descricao ? descricao : ''}
                  className='form-control'
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                />
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalContasReceberParcelas;
