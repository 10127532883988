


const ButtonEditListagem = ({handleEdit}) => {
    return (
        <div className='text-center'>
            <button
                onClick={handleEdit}
                className='btn btn-success btn-sm bi bi-pencil'
                data-toggle='tooltip'
                data-placement='top'
                title='Editar'
            />
        </div>
    );
}
export default ButtonEditListagem