import {useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import {Link, useNavigate} from 'react-router-dom';
import {Badge} from 'react-bootstrap';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {getPerfis} from '../listagem_requests/listagem_requests';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {IPerfil} from '../../../shared/domain-types/models/perfil';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import Skeleton from 'react-loading-skeleton';

export function ListagemPerfis() {
  const perfisColumns: Column<IPerfil>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Titulo',
      accessor: 'titulo',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => (
        <div className='text-start'>
          {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}: {value: string}) => formatStatus(value),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id)}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const navigate = useNavigate();

  const [isPerfisData, setIsPerfisData] = useState<IPerfil[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useMemo(() => isPerfisData, [isPerfisData]);
  const columns = useMemo(() => perfisColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    navigate(`/perfis/${id}`);
  };

  const formatStatus = (status: string) => {
    switch (status) {
      case 'ATIVO':
        return <Badge bg='success'>ATIVO</Badge>;
      case 'INATIVO':
        return <Badge bg='danger'>INATIVO</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  const initialValues = {
    id: '',
    titulo: '',
    descricao: '',
    status: 'ATIVO',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);

      try {
        const response = await getPerfis(
          {
            titulo: values.titulo,
            descricao: values.descricao,
            status: values.status,
          },
          0
        );

        const {content, totalPages} = response.data;

        if (response.status === 200) {
          setIsPerfisData(content);

          setIsHasMore(isPage < totalPages - 1);
        } else if (response.status === 204) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            iconColor: 'blue',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: 'Não existe registros de Perfil para essa pesquisa',
          });
          setIsPerfisData([]);
          setIsHasMore(false);
        }
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }
      }
      setIsLoading(false);
      setSubmitting(false);
    },
  });

  const getPerfisData = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await getPerfis(initialValues, page);

      const {content, totalPages} = response.data;

      setIsPerfisData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPerfisData(isPage);
  }, [isPage]);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Perfis</h1>
        <Link to='/perfis' className='btn btn-success btn-sm ' style={{fontSize: '14px'}}>
          + Novo Perfil
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-4'>
              <label className='form-label'>Titulo:</label>
              <input
                type='text'
                placeholder='Digite o titulo do perfil'
                {...formik.getFieldProps('titulo')}
                className='form-control'
              />
            </Col>
            <Col className='col-sm-4'>
              <label className='form-label'>Descrição:</label>
              <input
                type='text'
                placeholder='Digite a descrição do perfil'
                {...formik.getFieldProps('descricao')}
                className='form-control'
              />
            </Col>
            <Col className='col-sm-4'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>Ativo</option>
                  <option value='INATIVO'>Desativado</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => {
                  formik.resetForm();
                  getPerfisData(0);
                }}
                className='btn btn-danger form-control'
              >
                Limpar
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IPerfil>, index: number) => (
                  <th key={column.id} className={index === 0 ? 'text-end' : 'text-start'}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IPerfil>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id: any) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
    </div>
  );
}
