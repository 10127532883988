import {useEffect, useState} from 'react';
import axios from 'axios';
import {useProduto} from '../hooks/useProduto';
import useOptionsOrigemProduto from '../../../selectOptions/useOptionsOrigemProduto';
import useOptionsEmbalagem from '../../../selectOptions/useOptionsEmbalagem';
import useOptionsTipoEmbalagem from '../../../selectOptions/useOptionsTipoEmbalagem';
import {useOptionsTipoProduto} from '../../../selectOptions/useOptionsTipoProduto';
import {Col, Row} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import {AsyncSelectFornecedor, ISelectOption} from '../../../../shared';
// import Select from 'react-select/dist/declarations/src/Select';
import Select from 'react-select';
import {ActionMeta, InputActionMeta} from 'react-select';
import {OptionType} from '../Interface';
import fetchMarcaProductOptions from '../../../selectOptions/useOptionsMarcaProduto';
import fetchFornecedorProductOptions from '../../../selectOptions/useOptionsFornecedorProduto';
import AsyncSelectGarantia from './AsyncSelectGarantia';
import {useParams} from 'react-router-dom';

type IProps = {
  idParam?: string | number;
};

const DadosGerais = ({idParam}: IProps) => {
  const {id} = useParams();

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {
    formik,
    setSelectedOrigem,
    setSelectedTipo_Embalagem,
    setSelectedTipo,
    setSelectedEmbalagem,
    handleSelectChange,
    handleChange,
    handleChangeNumber,
    selectedEmbalagem,
    selectedOrigem,
    selectedTipo,
    selectedTipo_Embalagem,
    setIsCategoria,
    setIsSegmento,
    setIsGrupoCategoria,
    setIsTipoProduto,
    isCategoria,
    isGrupoCategoria,
    isSegmento,
    isTipoProduto,
    setIsCodigoClassificacao,
    isCodigoClassificacao,
    isUnidadeMedidaVenda,
    setIsUnidadeMedidaVenda,
    isUnidadeMedidaCompra,
    setIsUnidadeMedidaCompra,
    isFornecedor,
    setIsFornecedor,
    isEcommerce,
    setIsEcommerce,
    isMix,
    setIsMix,
    isDivulgacao,
    setIsDivulgacao,
    isDisponivelFabrica,
    setIsDisponivelFabrica,
    isEstoqueFabricacao,
    setIsEstoqueFabricacao,
    isAguardandoComplemento,
    setIsAguardandoComplemento,
    isLinhaProduto,
    setIsLinhaProduto,
    selectedGarantia,
    setSelectedGarantia,
  } = useProduto();

  const {
    setSelectedMarca,
    selectedMarca,
    base64Image,
    setBase64Image,
    selectedCategoria,
    setSelectedCategoria,
  } = useProduto();
  const [optionsMarcaProdutos, setOptionsMarcaProdutos] = useState<OptionType[]>([]);
  const [optionsFornecedorProdutos, setOptionsFornecedorProdutos] = useState<OptionType[]>([]);

  const {optionsOrigemProduto} = useOptionsOrigemProduto();
  const {optionsTipoEmbalagem} = useOptionsTipoEmbalagem();
  const {optionsTipoProduto} = useOptionsTipoProduto();
  const {optionsEmbalagem} = useOptionsEmbalagem();

  const handleSelectChangeOrigem = (event) => {
    const origemId = event.target.value;
    const origemDesc = event.target.options[event.target.selectedIndex].text;
    if (origemDesc === 'Selecione') {
      setSelectedOrigem({id: '', desc: ''});
    } else {
      setSelectedOrigem({id: origemId, desc: origemDesc});
    }
  };

  const handleSelectChangeEmbalagem = async (event) => {
    const embalagemId = event.target.value;
    const embalagemDesc = event.target.options[event.target.selectedIndex].text;

    if (embalagemId > 0) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/embalagens/${embalagemId}`
        );
        const embalagem = response.data;
        formik.setFieldValue('largura', embalagem.largura);
        formik.setFieldValue('altura', embalagem.altura);
        formik.setFieldValue('comprimento', embalagem.comprimento);
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
        return [];
      }
    } else {
      formik.setFieldValue('largura', 0);
      formik.setFieldValue('altura', 0);
      formik.setFieldValue('comprimento', 0);
    }

    if (embalagemDesc === 'Selecione') {
      setSelectedEmbalagem({id: '', desc: ''});
    } else {
      setSelectedEmbalagem({id: embalagemId, desc: embalagemDesc});
    }
  };

  const handleEstoque = (event) => {
    const novoValor = event.target.value;
    formik.setFieldValue('controlar_estoque', Number(novoValor));

    if (novoValor === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  };

  const handleSelectChangeMarca = (selectedMarca: ISelectOption | null) => {
    setSelectedMarca(selectedMarca);
  };

  useEffect(() => {
    if (formik.values.controlar_estoque === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  }, [
    formik.values.controlar_estoque,
    formik.values.controlar_lotes,
    formik.values.estoque_inicial,
    formik.values.estoque_min,
    formik.values.estoque_max,
    formik.values.sob_encomenda,
  ]);

  const [selectKey, setIsSelectKey] = useState<number>(0);

  const [keyTipoProduto, setKeyTipoProduto] = useState<number>(1);
  const [keyGrupoCategoria, setKeyGrupoCategoria] = useState<number>(2);
  const [keyCategoria, setKeyCategoria] = useState<number>(3);

  const [isSegmentosFiltered, setIsSegmentosFiltered] = useState<ISelectOption[]>([]);
  const [isTipoProdutoFiltered, setIsTipoProdutoFiltered] = useState<ISelectOption[]>([]);
  const [isTipoGrupoCategoriasFiltered, setIsTipoGrupoCategoriasFiltered] = useState<
    ISelectOption[]
  >([]);
  const [isCategoriaFiltered, setIsCategoriaFiltered] = useState<ISelectOption[]>([]);
  const [isUnidadeFiltered, setIsUnidadeFiltered] = useState<ISelectOption[]>([]);
  const [isLinhaFiltered, setIsLinhaFiltered] = useState<ISelectOption[]>([]);
  const [isMarcaFiltered, setIsMarcaFiltered] = useState<ISelectOption[]>([]);
  // const [selectedGarantia, setSelectedGarantia] = useState<ISelectOption | null>(null);

  const filteredOptionsSegment = (inputValue: string) => {
    return isSegmentosFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsTipoProduto = (inputValue: string) => {
    return isTipoProdutoFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsMarcas = (inputValue: string) => {
    return isMarcaFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  const filteredOptionsGrupoCategoria = (inputValue: string) => {
    return isTipoGrupoCategoriasFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsCategoria = (inputValue: string) => {
    return isCategoriaFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsUnidade = (inputValue: string) => {
    return isUnidadeFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    formik.setFieldValue('isConsumo', false);
    // setIsConsumo(true)
  }, []);

  const filteredOptionsLinhaProduto = (inputValue: string) => {
    return isLinhaFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptions = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsSegment(inputValue));
  };

  const loadFilteredOptionsMarcas = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsMarcas(inputValue));
  };

  const loadFilteredOptionsTipoProduto = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsTipoProduto(inputValue));
  };

  const loadFilteredOptionsGrupoCategoria = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsGrupoCategoria(inputValue));
  };

  const loadFilteredOptionsCategoria = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsCategoria(inputValue));
  };

  const loadFilteredOptionsUnidade = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsUnidade(inputValue));
  };

  const loadFilteredOptionsLinhaProduto = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsLinhaProduto(inputValue));
  };

  const [defaultMarcaOptions, setDefaultMarcaOptions] = useState<ISelectOption[]>([]);

  const [defaultSegmentOptions, setDefaultSegmentOptions] = useState<ISelectOption[]>([]);

  const [defaultTipoProdutosOptions, setDefaultTipoProdutosOptions] = useState<ISelectOption[]>([]);

  const [defaultGrupoDeCategoriasProdutosOptions, setDefaultGrupoDeCategoriasOptions] = useState<
    ISelectOption[]
  >([]);

  const [defaultCategoriaOptions, setDefaultCategoriaOptions] = useState<ISelectOption[]>([]);

  const [defaultUnidadeOptions, setDefaultUnidadeOptions] = useState<ISelectOption[]>([]);

  const [defaultLinhaOptions, setDefaultLinhaOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    const loadOptions = async () => {
      const options = (await loadMarca()) || []; // Carrega as opções.
      setDefaultMarcaOptions(options); // Define as opções padrão.
    };

    loadOptions();
  }, []);

  useEffect(() => {
    const fetchSegmentOptions = async () => {
      const options = (await loadOptionsSegmento()) || []; // Carrega as opções.
      setDefaultSegmentOptions(options); // Define as opções padrão.
    };

    fetchSegmentOptions();
  }, []);

  useEffect(() => {
    const fetchSegmentOptions = async () => {
      const options = (await loadOptionsUnidadeMedida()) || []; // Carrega as opções.
      setDefaultUnidadeOptions(options); // Define as opções padrão.
    };

    fetchSegmentOptions();
  }, []);

  useEffect(() => {
    const fetchLinhaProdutoOptions = async () => {
      const options = (await loadOptionsLinhaProduto()) || []; // Carrega as opções.
      setDefaultLinhaOptions(options); // Define as opções padrão.
    };

    fetchLinhaProdutoOptions();
  }, []);

  useEffect(() => {
    const loadOptions = async () => {
      const options = await fetchMarcaProductOptions();
      setOptionsMarcaProdutos(options);
    };
    loadOptions();
  }, []);

  useEffect(() => {
    const loadOptions = async () => {
      const options = await fetchFornecedorProductOptions();
      setOptionsFornecedorProdutos(options);
    };
    loadOptions();
  }, []);

  useEffect(() => {
    const fetchSegmentOptions = async () => {
      const options = (await loadOptionsTipoProduto()) || []; // Carrega as opções.
      setDefaultTipoProdutosOptions(options); // Define as opções padrão.
    };

    fetchSegmentOptions();
  }, [isSegmento]);

  const loadGarantiaById = async (id: any) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/garantia/${id}`;

      const response = await axios.get(url);
      const data = await response.data;
      console.log(data);
      setSelectedGarantia({
        value: data.id,
        label: `${data.quantidade} - ${data.unidadeTempo}`,
      });

      // setIsTipoEntidadeFiltered(options);

      // return options;
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  useEffect(() => {
    if (id && selectedGarantia?.label === '') {
      loadGarantiaById(selectedGarantia?.value);
    }
  }, [selectedGarantia]);

  useEffect(() => {
    const fetchSegmentOptions = async () => {
      const options = (await loadOptionsGrupoCategorias()) || []; // Carrega as opções.
      setDefaultGrupoDeCategoriasOptions(options); // Define as opções padrão.
    };

    fetchSegmentOptions();
  }, [isTipoProduto]);

  useEffect(() => {
    const fetchSegmentOptions = async () => {
      const options = (await loadOptionsCategoria()) || []; // Carrega as opções.
      setDefaultCategoriaOptions(options); // Define as opções padrão.
    };

    fetchSegmentOptions();
  }, [isGrupoCategoria]);

  const loadOptionsCodigoCategoria = async (inputValue: string | number) => {
    try {
      if (!/^\d+$/.test(inputValue.toString())) {
        return [];
      }

      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?codigoClassificacao=${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.id,
        label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsSegmento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/listar?modelo=${1}`;

      const response = await axios.get(url,{
        params: {
          status: 'ATIVO'
        }
      });
      const data = await response.data;

      const {content} = data;

      const options = content.map((item: any) => ({
        value: item.id,
        label: `${item.titulo ?? ''}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsSegmentosFiltered(options);
      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsUnidadeMedida = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-medida/listar?size=100000`

      const response = await axios.get(url, {
        params: {
          status: 'ATIVO',
        },
      });
      const data = await response.data;

      const {content} = data;

      const options = content.map((item) => ({
        value: item.id,
        label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsUnidadeFiltered(options);
      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsLinhaProduto = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/linha-produtos/listar?size=100000`;

      const response = await axios.get(url,{
        params: {
          status: 'ATIVO'
        }
      });
      
      const data = await response.data;

      const {content} = data;

      const options = content.map((item) => ({
        value: item.id,
        label: `${item.linha}`,
      }));

      setIsLinhaFiltered(options);
      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsTipoProduto = async () => {
    if (isSegmento)
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?idPai=${isSegmento?.value}`;

        const response = await axios.get(url,{
          params: {
            status: 'ATIVO'
          }
        });
        const data = await response.data;

        const options = data.map((item) => ({
          value: item.id,
          label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
        }));

        setIsTipoProdutoFiltered(options);

        return options;
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
        return [];
      }
  };

  const loadOptionsGrupoCategorias = async () => {
    if (isTipoProduto)
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?idPai=${isTipoProduto?.value}`;

        const response = await axios.get(url,{
          params: {
            status: 'ATIVO'
          }
        });
        const data = await response.data;

        const options = data.map((item) => ({
          value: item.id,
          label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
        }));

        setIsTipoGrupoCategoriasFiltered(options);

        return options;
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
        return [];
      }
  };

  const loadOptionsCategoria = async () => {
    if (isGrupoCategoria)
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?idPai=${isGrupoCategoria?.value}`;

        const response = await axios.get(url,{
          params: {
            status: 'ATIVO'
          }
        });
        const data = await response.data;

        console.log(data);

        const options = data.map((item) => ({
          value: item.id,
          label: `${item.codigoClassificacao ? `${item.codigoClassificacao} - ` : ''}${
            item.titulo
          }${item.descricao ? ` - ${item.descricao}` : ''}${
            item.isCustomizado === 'SIM' ? ' - Customizado' : ''
          }`,
          original: item, // <-- Armazena o item original
        }));

        setIsCategoriaFiltered(options);

        return options;
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
        return [];
      }
  };

  const handleChangeSegmento = (selectedOption: ISelectOption | null) => {
    setIsSegmento(selectedOption);
  };
  const handleGarantia = (option: ISelectOption | null) => {
    // setIsSegmento(selectedOption);
    if (option) {
      setSelectedGarantia(option);
      formik.setFieldValue('idGarantia', option.value);
    }
  };

  const handleUnidadeMedida = (selectedOption: ISelectOption | null) => {
    setIsUnidadeMedidaVenda(selectedOption);
  };

  const handleUnidadeMedidaCompra = (selectedOption: ISelectOption | null) => {
    setIsUnidadeMedidaCompra(selectedOption);
  };

  const handleLinhaProduto = (selectedOption: ISelectOption | null) => {
    setIsLinhaProduto(selectedOption);
  };

  const handleChangeTipoProduto = (selectedOption: ISelectOption | null) => {
    setIsTipoProduto(selectedOption);
  };

  const handleChangeGrupoCategoria = (selectedOption: ISelectOption | null) => {
    setIsGrupoCategoria(selectedOption);
  };

  const handleChangeCategoria = (selectedOption: ISelectOption | null) => {
    setIsCategoria(selectedOption);
  };

  useEffect(() => {
    if (isSegmento) {
      loadOptionsTipoProduto();
    } else {
      setIsTipoProduto(null);
    }

    setKeyTipoProduto((prevKey) => prevKey + 1);
  }, [isSegmento]);

  useEffect(() => {
    if (isTipoProduto) {
      loadOptionsGrupoCategorias();
    } else {
      setIsGrupoCategoria(null);
    }
    setKeyGrupoCategoria((prevKey) => prevKey + 1);
  }, [isTipoProduto]);

  useEffect(() => {
    if (isGrupoCategoria) {
      loadOptionsCategoria();
    } else if (!isCodigoClassificacao) {
      setIsCategoria(null);
    }

    setKeyCategoria((prevKey) => prevKey + 1);
  }, [isGrupoCategoria]);

  const handleIsClassificacao = () => {
    setIsCodigoClassificacao((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsFornecedor(option);
  };

  const loadMarca = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/marcasProdutos/optionsMarcaProdutos`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: any) => ({
        value: item.id,
        label: item.descricao,
      }));

      setIsMarcaFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  // Função onChange para o Status
  const handleStatusChange = (e) => {
    const status = e.target.value;
    formik.setFieldValue('status', status);

    console.log(status);

    // Se o status for INATIVO, desativa todos os campos relacionados
    if (status == '2') {
      // INATIVO
      formik.setFieldValue('isVenda', '0');
      formik.setFieldValue('isCompra', '0');
      formik.setFieldValue('isRessuprimento', '0');
      setIsEcommerce(false);
      setIsDivulgacao(false);
    }
    // Se o status for ATIVO, ativa os campos conforme a lógica
    else if (status == '1') {
      // ATIVO
      formik.setFieldValue('isVenda', '1');
      formik.setFieldValue('isCompra', '1');
      formik.setFieldValue('isRessuprimento', '1');
      setIsEcommerce(true);
      setIsDivulgacao(true);
    }
  };

  // Função onChange para o campo Venda
  const handleVendaChange = (e) => {
    const isVenda = e.target.value;
    formik.setFieldValue('isVenda', isVenda);

    console.log(isVenda);

    // Se "Venda" for inativo, desativa "Compra", "Ressuprimento" e "Ecommerce"
    if (isVenda === '0') {
      formik.setFieldValue('isCompra', '0');
      formik.setFieldValue('isRessuprimento', '0');
      setIsEcommerce(false);
      setIsDivulgacao(false);
    }
    // Se "Venda" for ativo, ativa "Compra", "Ecommerce" e "Marketing"
    else if (isVenda === '1') {
      formik.setFieldValue('isCompra', '1');
      setIsEcommerce(true);
      setIsDivulgacao(true);
    }
  };

  // Função onChange para o campo Compra
  const handleCompraChange = (e) => {
    const isCompra = e.target.value;
    formik.setFieldValue('isCompra', isCompra);

    // Se "Compra" for inativo, desativa "Ressuprimento"
    if (isCompra === '0') {
      formik.setFieldValue('isRessuprimento', '0');
    }
    // Se "Compra" for ativo, ativa "Ressuprimento"
    else if (isCompra === '1') {
      formik.setFieldValue('isRessuprimento', '1');
    }
  };

  return (
    <div className='mb-5 mt-8 '>
      <div className='mb-5 row'>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Descrição:</label>
          <input
            type='text'
            placeholder='Descrição completa do produto'
            {...formik.getFieldProps('descricao')}
            className='form-control bg-transparent'
            onChange={handleChange}
          />
          {formik.touched.descricao && formik.errors.descricao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.descricao}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6  mt-4'>
          <label className='form-label'>Código (SKU):</label>
          <input
            type='number'
            placeholder='Código (SKU) ou referência (opcional)'
            {...formik.getFieldProps('codigo_sku')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          {formik.touched.codigo_sku && formik.errors.codigo_sku && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.codigo_sku}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='mb-5 row'>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Origem:</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            onChange={handleSelectChangeOrigem}
            value={selectedOrigem?.id}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>

            {optionsOrigemProduto.map((option) => (
              <option key={option.value} value={option.value}>
                {option.desc}
              </option>
            ))}
          </select>
          <small className='form-text text-muted'>Origem do produto conforme ICMS</small>
          {formik.touched.origem_id && formik.errors.origem_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.origem_id}</span>
              </div>
            </div>
          )}
        </div>
        <Row className='mt-4 col-6'>
          <Col>
            <label className='form-label'>Compra:</label>
            <select
              {...formik.getFieldProps('isCompra')}
              className={`form-control`}
              onChange={handleCompraChange}
            >
              <option value=''>Selecione</option>
              <option value='1'>ATIVO</option>
              <option value='0'>INATIVO</option>
              <option value='2'>PENDENTE</option>
            </select>
          </Col>
          <Col>
            <label className='form-label'>Venda:</label>
            <select
              {...formik.getFieldProps('isVenda')}
              className={`form-control`}
              onChange={handleVendaChange}
            >
              <option value=''>Selecione</option>
              <option value='1'>ATIVO</option>
              <option value='0'>INATIVO</option>
              <option value='2'>PENDENTE</option>
            </select>
          </Col>
          <Col>
            <label className='form-label'>Ressuprimento:</label>
            <select {...formik.getFieldProps('isRessuprimento')} className={`form-control`}>
              <option value=''>Selecione</option>
              <option value='1'>ATIVO</option>
              <option value='0'>INATIVO</option>
              <option value='2'>PENDENTE</option>
            </select>
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              E-COMMERCE
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isEcommerce}
                onChange={() => setIsEcommerce((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isEcommerce === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
        </Row>
      </div>
      <div className='mb-5 row'>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>NCM:</label>
          <input
            type='text'
            placeholder='(Exemplo: 1001.10.10)'
            {...formik.getFieldProps('ncm')}
            className='form-control bg-transparent'
            onChange={handleChange}
          />
          <small id='' className='form-text text-muted'>
            Nomenclatura comum do Mercosul
          </small>
          {formik.touched.ncm && formik.errors.ncm && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.ncm}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-3 mt-4'>
          <label className='form-label'>Código CEST:</label>
          <input
            type='text'
            placeholder='(Exemplo: 1001.10.10)'
            {...formik.getFieldProps('cest')}
            className='form-control bg-transparent'
            onChange={handleChange}
          />
          <small id='' className='form-text ' style={{color: ' #0050DC'}}>
            Código Especificador da Substituição Tributária
          </small>
          {formik.touched.cest && formik.errors.cest && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cest}</span>
              </div>
            </div>
          )}
        </div>
        <Row className='col-12 col-md-6 mt-4'>
          <Col className='col-3'>
            <label htmlFor='' className='form-label'>
              MIX
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isMix}
                onChange={() => setIsMix((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isMix === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          <Col className='col-3 ms-15 ms-md-0'>
            <label htmlFor='' className='form-label'>
              Marketing
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isDivulgacao}
                onChange={() => setIsDivulgacao((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isDivulgacao === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          <Col className='col-3 ms-1 ms-md-0'>
            <label htmlFor='' className='form-label text-nowrap'>
              Disponível na Fabrica?
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isDisponivelFabrica}
                onChange={() => setIsDisponivelFabrica((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isDisponivelFabrica === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          {/* <Col>
            <label htmlFor='' className='form-label'>
              Produto de estoque ou fabricação?
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isEstoqueFabricacao}
                onChange={() => setIsEstoqueFabricacao((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isEstoqueFabricacao === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              Aguardando Complemento?
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isAguardandoComplemento}
                onChange={() => setIsAguardandoComplemento((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isAguardandoComplemento === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className='mb-8 row'>
        <div className='col-md-3 mt-4'>
          <label htmlFor='' className='form-label'>
            Unidade de Medida - Venda:
          </label>
          <AsyncSelect
            key={selectKey}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: 'black',
              }),
            }}
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder={'Pesquise a unidade de medida '}
            loadOptions={loadFilteredOptionsUnidade}
            isDisabled={false}
            value={isUnidadeMedidaVenda}
            isClearable
            onChange={handleUnidadeMedida}
            defaultOptions={defaultUnidadeOptions}
          />
        </div>
        <div className='col-md-3 mt-4'>
          <label htmlFor='' className='form-label'>
            Unidade de Medida - Compra:
          </label>
          <AsyncSelect
            key={selectKey}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: 'black',
              }),
            }}
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder={'Pesquise a unidade de medida '}
            loadOptions={loadFilteredOptionsUnidade}
            isDisabled={false}
            value={isUnidadeMedidaCompra}
            isClearable
            onChange={handleUnidadeMedidaCompra}
            defaultOptions={defaultUnidadeOptions}
          />
        </div>
        <div className='col-md-3 mt-4'>
          <label htmlFor='' className='form-label'>
            Linha do Produto:
          </label>
          <AsyncSelect
            key={selectKey}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: 'black',
              }),
            }}
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder={'Pesquise a Linha do Produto'}
            loadOptions={loadFilteredOptionsLinhaProduto}
            isDisabled={false}
            value={isLinhaProduto}
            isClearable
            onChange={handleLinhaProduto}
            defaultOptions={defaultLinhaOptions}
          />
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Status</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            value={formik.values.status}
            {...formik.getFieldProps('status')}
            onChange={handleStatusChange}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'> Ativo </option>
            <option value='2'> Inativo </option>
          </select>

          {formik.touched.status && formik.errors.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.status}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Fator de Conversão Inicial</label>
          <input type='number' className='form-control' placeholder='Fator de Conversão Inicial' 
            {...formik.getFieldProps('fatorConversaoInicial')}
          />
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label' >Fator de Conversão Final</label>
          <input type='number' className='form-control' placeholder='Fator de Conversão Final' 
            {...formik.getFieldProps('fatorConversaoFinal')}
           />
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center'}}
        id='section1'
      >
        <h5>Categorização de Produtos</h5>
        <div style={{flexGrow: 1, borderBottom: '2px solid #000', margin: '10px'}}></div>
        <>
          <h5 style={{marginRight: '10px'}}>Utilizar código de classificação?</h5>
          <div className='form-check form-switch d-flex align-items-center'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckDefault2'
              checked={isCodigoClassificacao === 1}
              onChange={handleIsClassificacao}
            />

            <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
              <b>{isCodigoClassificacao === 1 ? 'Sim' : 'Não'}</b>
            </label>
          </div>
        </>
      </div>

      {isCodigoClassificacao ? (
        <Row>
          <Col className='col-6 mb-12'>
            <label htmlFor='' className='form-label'>
              Categoria:
            </label>
            <AsyncSelect
              key={keyCategoria}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: 'black',
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder={'Insira o Código de Classificação:'}
              loadOptions={loadOptionsCodigoCategoria}
              isDisabled={false}
              value={isCategoria}
              isClearable
              onChange={handleChangeCategoria}
              onKeyDown={(e) => {
                // Verifica se a tecla pressionada é uma letra
                if (/^[a-zA-Z]$/.test(e.key)) {
                  e.preventDefault(); // Impede a entrada
                }
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row className='mb-8 mt-4'>
          <Col md='3' className='col-12 mb-4'>
            <label htmlFor='' className='form-label'>
              Segmento:
            </label>
            <AsyncSelect
              key={selectKey}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: 'black',
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder={'Pesquise o Segmento'}
              loadOptions={loadFilteredOptions}
              isDisabled={false}
              value={isSegmento}
              isClearable
              onChange={handleChangeSegmento}
              defaultOptions={defaultSegmentOptions}
            />
          </Col>
          <Col md='3' className='col-12 mb-4'>
            <label htmlFor='' className='form-label'>
              Tipo de Produto:
            </label>
            <AsyncSelect
              key={keyTipoProduto}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: 'black',
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder={'Pesquise o Tipo de Produto'}
              loadOptions={loadFilteredOptionsTipoProduto}
              isDisabled={isSegmento ? false : true}
              value={isTipoProduto}
              isClearable
              onChange={handleChangeTipoProduto}
              defaultOptions={defaultTipoProdutosOptions}
            />
          </Col>
          <Col md='3' className='col-12 mb-4'>
            <label htmlFor='' className='form-label'>
              Grupo de Categorias:
            </label>
            <AsyncSelect
              key={keyGrupoCategoria}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: 'black',
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder={'Pesquise o Grupo de Categorias'}
              loadOptions={loadFilteredOptionsGrupoCategoria}
              isDisabled={isTipoProduto && isSegmento ? false : true}
              value={isGrupoCategoria}
              isClearable
              onChange={handleChangeGrupoCategoria}
              defaultOptions={defaultGrupoDeCategoriasProdutosOptions}
            />
          </Col>
          <Col md='3' className='col-12 mb-4'>
            <label htmlFor='' className='form-label'>
              Categoria:
            </label>
            <AsyncSelect
              key={keyCategoria}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: 'black',
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder={'Pesquise a Categoria'}
              loadOptions={loadFilteredOptionsCategoria}
              isDisabled={isGrupoCategoria && isTipoProduto && isSegmento ? false : true}
              value={isCategoria}
              isClearable
              onChange={handleChangeCategoria}
              defaultOptions={defaultCategoriaOptions}
            />
          </Col>
        </Row>
      )}

      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center'}}
        id='section1'
      >
        <h5>Outras Informações</h5>
        <div style={{flexGrow: 1, borderBottom: '2px solid #000', margin: '10px'}}></div>
      </div>

      <Row>
        <label className='form-label'>Fabricante / Fornecedor:</label>

        <AsyncSelectFornecedor
          handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
          inputOption={isFornecedor}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              setIsFornecedor(null);
            }
          }}
        />
      </Row>

      <div className='row'>
        <div className='col-md-6  mt-4 mb-4'>
          <label className='form-label'>Marca:</label>
          <AsyncSelect
            value={selectedMarca}
            loadOptions={loadFilteredOptionsMarcas}
            onChange={handleSelectChangeMarca}
            isClearable
            defaultOptions={defaultMarcaOptions}
            placeholder='Selecione uma marca...'
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
          />
          {formik.touched.marca_id && formik.errors.marca_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.marca_id}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Garantia:</label>
          <AsyncSelectGarantia
            handleOptions={handleGarantia}
            inputOption={selectedGarantia}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setSelectedGarantia(null);
              }
            }}
          ></AsyncSelectGarantia>
        </div>
      </div>
      <div className='mb-5 row'>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Fator de Conversão (%):</label>
          <input
            className='form-control bg-transparent'
            type='number'
            placeholder='0,000'
            {...formik.getFieldProps('fator_de_conversao')}
          />
        </div>
        <div className='col-md-6 mt-4 '>
          <label className='form-label'>Código no Fabricante / Fornecedor:</label>
          <input
            className='form-control bg-transparent'
            type='text'
            placeholder='Código do produto pelo fabricante'
            {...formik.getFieldProps('codigo_fabricante')}
            onChange={handleChangeNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default DadosGerais;
