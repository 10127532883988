import { useNavigate } from 'react-router-dom';
import PaginaInicial from './components/pagina-inicial/PaginaInicial';
import ResumoCliente from './components/ResumoClientes';
import ResumoFornecedor from './components/ResumoFornecedores';
import ResumoComprador from './components/ResumoCompradores';
import ResumoProduto from './components/ResumoProdutos';
import { useState } from 'react';
const ListagemPendenciaCompra: React.FC = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('geral');
    
    return(
        <>
            <div className=' mt-6' style={{display:'none'}}>
                <div className='col-sm-12'>
                    <div className='btn-group' role='group' style={{width: '100%'}}>
                            <button
                                type='button'
                                className={`btn btn-secondary ${activeSection === 'geral' ? 'active' : ''}`}
                                onClick={() => setActiveSection('geral')}
                            >
                                Consulta pendências de compra
                            </button>
                            <button
                                type='button'
                                className={`btn btn-secondary ${activeSection === 'cliente' ? 'active' : ''}`}
                                onClick={() => setActiveSection('cliente')}
                            >
                                Pendências de compra por cliente
                            </button>
                            <button
                                type='button'
                                className={`btn btn-secondary ${activeSection === 'fornecedor' ? 'active' : ''}`}
                                onClick={() => setActiveSection('fornecedor')}
                            >
                                Pendências de compra por fornecedores
                            </button>
                            <button
                                type='button'
                                className={`btn btn-secondary ${activeSection === 'comprador' ? 'active' : ''}`}
                                onClick={() => setActiveSection('comprador')}
                            >
                                Pendências de compra por compradores
                            </button>
                            <button
                                type='button'
                                className={`btn btn-secondary ${activeSection === 'produto' ? 'active' : ''}`}
                                onClick={() => setActiveSection('produto')}
                            >
                                Pendências de compra por produtos
                            </button>
                    </div>
                </div>
            </div>
            {activeSection === 'geral' && <PaginaInicial/>}
            {activeSection === 'cliente' && <ResumoCliente/>}
            {activeSection === 'fornecedor' && <ResumoFornecedor/>}
            {activeSection === 'comprador' && <ResumoComprador/>}
            {activeSection === 'produto' && <ResumoProduto/>}
        </>
    );
};

export default ListagemPendenciaCompra;