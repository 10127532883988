import {Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  CrudHeader,
  IEmpreendimentos,
  smoothScrollToBottom,
  TableListagem,
  TipoLocalizacao,
  useModal,
} from '../../../shared';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../cadastros/proposta-pedido/types/interface';
import {Link} from 'react-router-dom';

import {useNavigate} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const Empreendimentos = () => {
  const navigate = useNavigate();
  const [empreendimentos, setEmpreendimentos] = useState<IEmpreendimentos[]>([]);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [titulo, setTitulo] = useState<string | null>(null);
  const [codigo, setCodigo] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [selectedSegmento, setSelectedSegmento] = useState<ClienteOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const colums = [
    'Id',
    'Titulo',
    'Codigo',
    'Segmento',
    'Responsável',
    'Criado por',
    'Data de criação',
    'Alterado por',
    'Data de alteração',
    'Status',
    'Ações',
  ];

  const fetchEmpreendimentos = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/listar`,
        {
          params: {
            status: status,
            titulo: titulo ? titulo : null,
            codigo: codigo ? codigo : null,
            idSegmentoEmpreendimento: selectedSegmento ? selectedSegmento.value : null,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
        }
        setEmpreendimentos((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Empreendimentos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };
  const loadOptionsSegmento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchEmpreendimentos(0);
  };
  const clearFiltros = () => {
    setTitulo(null);
    setCodigo(null);
    setStatus('ATIVO');
    setSelectedSegmento(null);
    setPage(0);
    setIsClear(true);
  };

  useEffect(() => {
    fetchEmpreendimentos(page);
  }, [page]);

  useEffect(() => {
    if (isClear) {
      fetchEmpreendimentos(0);
    }
    setIsClear(false);
  }, [isClear]);

  return (
    <div>
      <CrudHeader
        title='Empreendimentos'
        titleCadastro='Empreendimento'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        isLoading={isLoading}
        openModal={() => {
          navigate('/empreendimentos-cadastro');
        }}
      >
        <Row className=' row'>
          <Col>
            <label htmlFor='' className='form-label'>
              Titulo:
            </label>
            <input
              type='text'
              className='form-control'
              value={titulo ? titulo : ''}
              onChange={(e) => {
                setTitulo(e.target.value);
              }}
              placeholder='Digite o titulo'
            />
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              Codigo:
            </label>
            <input
              type='number'
              className='form-control'
              value={codigo ? codigo : ''}
              onChange={(e) => {
                setCodigo(Number(e.target.value));
              }}
              placeholder='Digite o codigo'
            />
          </Col>
        </Row>
        <Row className=''>
          <Col>
            <label htmlFor='' className='form-label'>
              Segmento:
            </label>
            <AsyncSelect
              className='py-1'
              value={selectedSegmento}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedSegmento(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedSegmento(selectedOption);
                console.log(selectedOption);
              }}
              placeholder='Selecione o segmento'
              defaultOptions
              loadOptions={loadOptionsSegmento}
            ></AsyncSelect>
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </CrudHeader>

      <TableListagem isLoading={isLoading} coluns={colums} hasMore={hasMore} loadMore={loadMore}>
        {empreendimentos?.map((empreendimento) => (
          <tr className='text-center'>
            <td>{empreendimento.id}</td>
            <td>{empreendimento.titulo}</td>
            <td>{empreendimento.codigo}</td>
            <td>{empreendimento.segmentoEmpreendimento}</td>
            <td>{empreendimento.responsavel}</td>
            <td>{empreendimento.usuarioCriacaoNome}</td>
            <td>{empreendimento.dataCriacao}</td>
            <td>
              {empreendimento.usuarioAlteracaoNome ? empreendimento.usuarioAlteracaoNome : '...'}
            </td>
            <td>{empreendimento.dataAlteracao}</td>
            <td>{empreendimento.status}</td>
            <td className=''>
              <div className='dropdown position-static'>
                <button
                  className='btn btn-success btn-sm dropdown-toggle'
                  data-bs-toggle='dropdown'
                >
                  <i className=''>Opções</i>
                </button>
                <ul
                  className='dropdown-menu'
                  style={{
                    position: 'absolute',
                    zIndex: 1050,
                    top: '100%',
                    left: '0',
                  }}
                >
                  <li>
                    <button
                      className='dropdown-item'
                      type='button'
                      onClick={() => {
                        navigate(`/editar-empreendimentos/${empreendimento.id}`);
                      }}
                    >
                      <i className='bi-pencil'> </i>
                      <span className='mx-2'>EDITAR</span>
                    </button>
                  </li>

                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <button className='dropdown-item' type='button' disabled>
                      <i className='bi-file-earmark-arrow-down'> </i>
                      <span className='mx-2'>GERAR PDF</span>
                    </button>
                  </li>

                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <Link
                      to={`/clientes-empreendimento/${empreendimento.id}`}
                      className='dropdown-item'
                      type='Button'
                    >
                      <i className='bi-box-arrow-up-right'></i>
                      <span className='mx-2'>VINCULAR CONSTRUTORAS</span>
                    </Link>
                  </li>
                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <Link
                      to={`/segmentos-quantidade-empreendimentos/${empreendimento.id}`}
                      className='dropdown-item'
                      type='Button'
                    >
                      <i className='bi-box-arrow-up-right'></i>
                      <span className='mx-2'>GERENCIAR QUANTIDADE DO SEGMENTO</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        ))}
      </TableListagem>
    </div>
  );
};

export default Empreendimentos;
