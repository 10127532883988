import { Col, Row, } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { CrudHeader, ICrudBase, smoothScrollToBottom, TableListagem, useModal } from "../../../shared";
import ButtonEditListagem from "../segmento-empreendimento/components/ButtonEditListagem";
import ModalSegmentosItens from "./components/ModalSegmentosItens";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const SegmentoItens = () => {
    const {id} = useParams()
    // VARIAVEIS
    const [FaseDaObra, setFaseDaObra] = useState<ICrudBase[]>([])
    const [dataLine, setDataLine] = useState<ICrudBase[]>([])

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [segmentoTitle, setSegmentoTitle] = useState<string | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)

    const colums =['Id','Titulo', 'Descrição', 'Criado por', 'Data de criação', 'Alterado por', 'Data de alteração', 'Status', 'Ações']

    const [isFaseDaObraModal, openFaseDaObraModal, closeFaseDaObraModal] = useModal();

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }
    const fetchFaseDaObra = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos-itens/listar`,
                {
                    params: {
                        idEmpreendimentosSegmento: id,
                        status: status,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                setFaseDaObra((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Itens desse Segmento para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    }; 
    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setPage(0)
        setIsClear(true)
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchFaseDaObra(0);
    };

    const getSegmentoById = async () => {
        try {
          const response = await axios.get(
            `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos/${id}`
          );
    
          if (response.status === 200) {
            setSegmentoTitle(response.data.titulo);
          }
        } catch (error: any) {
          console.error(error);
          if (error.response && error.response.data && error.response.data.message === '401') {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              // setLoading(false);
              window.open('/auth', '_blank');
            });
          }
        }
      };

    const openModal = (isEdit=false, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openFaseDaObraModal();
    }

    useEffect(() => {
        if (isClear) {
            fetchFaseDaObra(0)
        }
        setIsClear(false)
    }, [isClear])

    useEffect(() => {
        fetchFaseDaObra(page)
        getSegmentoById();
    }, [page]);

    return (
        <div>
            <CrudHeader  title='Itens do Segmento' titleCadastro='Item' to={`/segmentos-empreendimentos`} clearFiltros={clearFiltros} handleSearch={handleSearch} isLoading={isLoading} openModal={openModal} >
            <Row className="row">
                <label htmlFor="" className="form-label">Segmento do Empreendimento:</label>
                <AsyncSelect isDisabled value={{value: 1, label: segmentoTitle}}></AsyncSelect>
            </Row>
                <Row className=" row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Titulo:</label>
                        <input type="text"
                            className="form-control"
                            value={titulo ? titulo : ""}
                            onChange={(e) => {
                                setTitulo(e.target.value)
                            }}
                            placeholder="Digite o titulo" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className=" row">
                    <label htmlFor="" className="form-label">Descrição:</label>
                    <Col className="">
                        <textarea name=""
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                            placeholder="Digite a descrição" className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>
            
            <TableListagem 
                    isLoading={isLoading}
                    coluns={colums}   
                    hasMore={hasMore} 
                    loadMore={loadMore}>
                            {FaseDaObra?.map((porte) => (
                                <tr className="text-center">
                                    <td>{porte.id}</td>
                                    <td>{porte.titulo}</td>
                                    <td>{porte.descricao ? porte.descricao : "..."}</td>
                                    <td>{porte.usuarioCriacaoNome}</td>
                                    <td>{porte.dataCriacao}</td>
                                    <td>{porte.usuarioAlteracaoNome? porte.usuarioAlteracaoNome : "..."}</td>
                                    <td>{porte.dataAlteracao}</td>
                                    <td>{porte.status}</td>
                                    <td>
                                        <ButtonEditListagem handleEdit={() => { openModal(true,porte.id) }}></ButtonEditListagem>
                                    </td>
                                </tr>
                            ))
                            }
            </TableListagem>

            <ModalSegmentosItens
            clearFiltros={clearFiltros} 
            dataLine={dataLine} 
            reload={fetchFaseDaObra} 
            isEdit={isEdit} 
            isOpen={isFaseDaObraModal} 
            onHide={closeFaseDaObraModal}></ModalSegmentosItens>
        </div>
    )
}

export default SegmentoItens