import { useEffect, useState } from 'react';
import { Button, Col, ModalProps, Row, Spinner } from 'react-bootstrap';
import { HttpClient, IConfiguracoesItens, Modal, showAlert } from '../../../../../shared';
import { useParams } from 'react-router-dom';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  handleRequest?: () => void;
  idItem?: number | null;
  isEdit?: boolean;
};

const ModalAddConfiguracaoItens: React.FC<Props> = ({
  isOpen,
  onHide,
  handleRequest,
  isEdit,
  idItem,
}) => {
  const http = new HttpClient();
  const id = useParams<{ id: string }>().id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDescricao, setIsDescricao] = useState<string>('');
  const [isIntA, setIsIntA] = useState<number | null>(null);
  const [isStrA, setIsStrA] = useState<string>('');
  const [isTxtA, setIsTxtA] = useState<string>('');
  const [isStatus, setIsStatus] = useState<'ATIVO' | 'INATIVO'>('ATIVO');

  useEffect(() => {
    if (isEdit && idItem) {
      // Carregar os dados da configuração para edição
      const fetchConfiguracao = async () => {
        try {
          const response = await http.request<IConfiguracoesItens>({
            method: 'GET',
            url: `configuracoes/itens/${idItem}`,
          });
          setIsDescricao(response.data.descricao);
          setIsIntA(response.data.intA ?? null);
          setIsStrA(response.data.strA ?? '');
          setIsTxtA(response.data.txtA ?? '');
          setIsStatus(response.data.status);
        } catch (error) {
          showAlert('error', 'Erro ao carregar dados da configuração.');
        }
      };
      fetchConfiguracao();
    }
  }, [isEdit, idItem]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const url = isEdit && idItem ? `configuracoes/itens/${idItem}` : `configuracoes/itens`;
      const method = isEdit && idItem ? 'PUT' : 'POST';

      await http.request<
        IConfiguracoesItens,
        {
          idConfiguracao: number;
          descricao: string;
          intA: number | null;
          strA: string;
          txtA: string;
          status: 'ATIVO' | 'INATIVO';
        }
      >({
        method,
        url,
        data: {
          idConfiguracao: Number(id),
          descricao: isDescricao,
          intA: isIntA,
          strA: isStrA,
          txtA: isTxtA,
          status: isStatus,
        },
      });

      showAlert('success', 'Item da configuração salvo com sucesso.');

      if (handleRequest) handleRequest();
      onClose();
    } catch (error) {
      showAlert('error', 'Erro ao salvar item da configuração.');
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (onHide) {
      onHide(); // Fecha o modal
    }
    setIsDescricao('');
    setIsIntA(null);
    setIsStrA('');
    setIsTxtA('');
    setIsStatus('ATIVO');
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isEdit ? 'Editar Item da Configuração' : 'Cadastro do Item da Configuração'}
      icon={() => (
        <i className='bi bi-gear-wide-connected' style={{ color: '#000', fontSize: '15px' }}></i>
      )}
      actions={[
        <Button key='save' type='submit' variant='success' onClick={handleSubmit}>
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Salvar'}
        </Button>,
      ]}
      onHide={onClose}
      size='lg'
    >
      <form>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Descrição:</label>
            <input
              placeholder='Descrição do item'
              type='text'
              className='form-control'
              value={isDescricao}
              onChange={(e) => setIsDescricao(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-4'>
            <label className='form-label'>Int A:</label>
            <input
              placeholder='Inteiro A'
              type='Number'
              className='form-control'
              value={isIntA ?? ''}
              onChange={(e) => setIsIntA(e.target.value ? parseInt(e.target.value, 10) : null)}
            />
          </Col>
          <Col className='col-4'>
            <label className='form-label'>Str A:</label>
            <input
              placeholder='String A'
              type='text'
              className='form-control'
              value={isStrA}
              onChange={(e) => setIsStrA(e.target.value)}
            />
          </Col>
          <Col className='col-4'>
            <label className='form-label'>Txt A:</label>
            <input
              placeholder='Texto A'
              type='text'
              className='form-control'
              value={isTxtA}
              onChange={(e) => setIsTxtA(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Status:</label>
            <select
              className='form-control'
              value={isStatus}
              onChange={(e) => setIsStatus(e.target.value as 'ATIVO' | 'INATIVO')}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default ModalAddConfiguracaoItens;
