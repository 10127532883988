import styled from 'styled-components';

export const ContainerBadge = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BadgeEnviroment = styled.div`
  width: 270px;
  height: 35px;
  border-radius: 7px;
  background-color: rgb(0, 119, 255);

  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    padding: 0px;
    margin: 0px;

    font-size: small;

    span {
      padding: 4px;

      border-radius: 5px;
      background-color: rgb(58, 25, 205);
      font-weight: bold;
    }
  }
`;
