import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Modal} from '../../../../shared';
import FormArquivoUpload from '../../../../shared/components/form-upload-pdf/FormArquivoUpload';
import { id } from 'date-fns/locale';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';
// import { Modal } from "../../../../../shared";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ModalArquivosDoProjeto = ({
  isOpen,
  onHide,
  isEdit = false,
  reload,
  dataLine = {},
  clearFiltros,
}) => {
  const {id} = useParams();
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [isConfirme, setIsConfirme] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [arquivo, setArquivo] = useState<string>('');
  const [extensao, setExtensao] = useState<string>('');
  const [isOk, setIsOk] = useState<boolean>(false);

  const onClose = () => {
    clearForm();
    onHide!();
  };

  const clearForm = () => {
    setStatus('ATIVO');
    setDescricao(null);
    setArquivo('');
    setIsOk(false);
    setTitulo(null);
    setExtensao('');
    setArquivo('');
  };

  const cadastrarStatusProjeto = async () => {
    try {
      setisLoading(true);
      if (titulo !== null && titulo !== '' && titulo !== undefined && titulo !== ' ') {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-arquivos/salvar`;

        const response = await axios.post(url, {
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status ? status : 'ATIVO',
          idProjeto: id, 
          arquivo: arquivo,
          extensao: extensao,
        });

        Swal.fire({
          icon: 'success',
          title: `Arquivo cadastrado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          reload!();
          clearForm();
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setisLoading(false);
    }
  };

  const editarStatusProjeto = async () => {
    try {
      setisLoading(true);
      if (titulo !== null && titulo !== '' && titulo !== undefined && titulo !== ' ') {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-arquivos/${dataLine[0]}`;
        const response = await axios.put(url, {
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          status: status ? status : 'ATIVO',
          idProjeto: id, 
          extensao: extensao,
        });
        Swal.fire({
          icon: 'success',
          title: `Arquivo do Projeto editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          clearForm();
          reload!();
          onClose();
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setisLoading(false);
    }
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      disabled={isConfirme === false}
      onClick={() => (isEdit ? editarStatusProjeto() : cadastrarStatusProjeto())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  const fetchArquivosById = async (page = 0) => {
    console.log(dataLine);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-arquivos/${dataLine[0]}`
      );

      const data = response.data;
      if (response.status === 200) {
        if (page === 0) {
          // setLogs([]);
        }
        console.log(response.data.content);
        setStatus(data.status);
        setTitulo(data.titulo);
        // setArquivo(data.url);
        setDescricao(data.descricao);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchArquivosById();
      setArquivo('salvo')
    }
  }, [dataLine]);

  useEffect(() => {
    if (
      titulo !== null &&
      titulo !== '' &&
      titulo !== undefined &&
      titulo !== ' ' &&
      arquivo !== ''
    ) {
      setIsConfirme(true);
    } else {
      setIsConfirme(false);
    }
  }, [titulo, arquivo]);

  useEffect(() => {
    if (!isOk) {
      setArquivo('');
    }
    // console.log(arquivoSalvo)
  }, [isOk]);

  return (
    <div>
      <Modal {...modalConfigs}>
        {/* TITULO E CADASTRO */}
        <div
          className='mb-3 form-label-container'
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
            {isEdit ? 'Editar' : 'Cadastrar'} Arquivo do Projeto
          </h1>
        </div>
        {/* BARRA HORRIZONTAL */}
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        {/* FILTROS */}
        <div className='my-4'>
          <form action='' className='mt-10'>
            <Row className='mt-8  mb-5 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Titulo:
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={titulo ? titulo : ''}
                  onChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                  placeholder='Digite o titulo'
                />
                {(titulo === null || titulo === '' || titulo === undefined) && (
                  <span className={` form-label text-danger`}>*Por favor, informe o Titulo</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  value={status ? status : ''}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            {/* </Row> */}
            {/* <Row className='mb-6'> */}
              {/* {!isEdit && ( */}
                <Col className='col-12 col-md-4 mt-md-0 mt-5'>
                <label htmlFor="" className="form-label">Arquivo:</label>
                <FormArquivoUpload disabled={isEdit?true:false} setExtensao={(e)=>{setExtensao(e)}} setIsOk={setIsOk} isOk={isOk} setArquivo={setArquivo} />
                {(arquivo === null || arquivo === '') && (
                  <span className={` form-label text-danger`}>*Por favor, informe o Arquivo</span>
                )}
                {(isEdit && arquivo !== '') && (
                  <span className={` form-label text-black-50`}>*Arquivo ja anexado, não pode ser alterado</span>
                )}
              </Col>
              {/* )} */}
            </Row>
            <Row className='mt-4  mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalArquivosDoProjeto;
