import { Col, Row, Spinner } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {AsyncSelectProductByIdSysled, EstoqueInventarioProduto, EstoqueLocalizacao, formatarData, smoothScrollToBottom, useModal } from "../../../../shared";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
// import { EstoqueInventarioProduto } from "../../../../shared/domain-types/models/estoque-inventario-produto";
import ModalInventarioProduto from "./components/ModalnventarioProduto";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueInvetarioProduto = () => {

     const { id } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [page, setPage] = useState(0)

    const [produtos, setProdutos] = useState<EstoqueInventarioProduto[]>([])
    const [dataLine, setDataLine] = useState<EstoqueLocalizacao[]>([])

    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)
    const [selectedLocalizacao, setSelectedLocalizacao] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [dataMovimento, setDataMovimento] = useState<string | null>(null)
    const [quantidadeDisponivel, setQuantidadeDisponivel] = useState<number | null>(null)
    const [quantidadeReservada, setQuantidaReservada] = useState<number | null>(null)
    const [quantidadeProcesso, setQuantidadeProcesso] = useState<number | null>(null)

    const [isEstoqueLocalizacaoModal, openEstoqueLocalizacaoModal, closeEstoqueLocalizacaoModal] = useModal();


    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }


    const clearFiltros = () => {
        setStatus('ATIVO')
        setSelectedProduto(null)
        setSelectedLocalizacao(null)
        setQuantidadeDisponivel(null)
        setQuantidadeProcesso(null)
        setQuantidaReservada(null)
        setDataMovimento(null)
        setPage(0)
    }
    const fetchEstoqueInventarioProduto = async (page = 0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-itens/listar`,
                {
                    params: {
                        status: status,
                        idProduto: selectedProduto?.value,
                        quantidadeDisponivel: quantidadeDisponivel?quantidadeDisponivel:null ,
                        quantidadeProcesso: quantidadeProcesso?quantidadeProcesso:null,
                        quantidadeReservado: quantidadeReservada?quantidadeReservada:null,
                        dataMovimento: dataMovimento,
                        idLocalizacao: selectedLocalizacao?.value,
                        idInventario: id,
                        page: page
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                    setProdutos([]);
                }
                setProdutos((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Produtos do Inventario para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);

        }
    };
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueLocalizacaoModal();
    }

    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchEstoqueInventarioProduto(0);
    };

    const loadOptionsLocalizacao = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    useEffect(() => {
        fetchEstoqueInventarioProduto(page)
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Produtos do Inventário
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => { openModal(false) }}
                >
                    Cadastrar Produtos do Inventário
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form className="my-9" action="">

                    <Row >
                        <Col className="">
                            <label htmlFor="" className="form-label">Produto:</label>
                            <AsyncSelectProductByIdSysled onKeyDown={()=>setSelectedProduto(null)} inputOption={selectedProduto} handleOptions={(e) => { setSelectedProduto(e) }}></AsyncSelectProductByIdSysled>
                        </Col>
                    </Row>
                    <Row className='my-10   '>
                        <Col>
                            <label htmlFor="" className="form-label"  >Localização:</label>
                            <AsyncSelect
                                defaultOptions
                            className="my-auto p-1"
                            onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedLocalizacao(null);
                                    }
                                }} value={selectedLocalizacao} placeholder="Selecione a Localização" onChange={(e) => { setSelectedLocalizacao(e) }} loadOptions={loadOptionsLocalizacao}></AsyncSelect>
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade Disponível:</label>
                            <input value={quantidadeDisponivel ? quantidadeDisponivel : ""} onChange={(e) => { setQuantidadeDisponivel(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade Reservada :</label>
                            <input value={quantidadeReservada ? quantidadeReservada : ""} onChange={(e) => {setQuantidaReservada(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                        </Col>
                        <Col className="col-3">
                            <label htmlFor="" className="form-label"  >Quantidade Processo:</label>
                            <input value={quantidadeProcesso ? quantidadeProcesso : ""} onChange={(e) => { setQuantidadeProcesso(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col className="col-3">
                            <label htmlFor="" className="form-label"  >  Inventario:  </label>
                            <input className="form-control" type="text" placeholder={id} disabled />
                        </Col>
                        <Col className="col-3">
                            <label htmlFor="" className="form-label"  >Data do Movimento:</label>
                            <input value={dataMovimento ? dataMovimento : ""} onChange={(e) => { setDataMovimento((e.target.value)) }} className="form-control" type="date" placeholder="Digite a quantidade" />

                        </Col>
                        <Col className="col-3">
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <div className='col-12 my-10' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                        <Link className="btn mx-5 px-10 btn-info " to="/consultas/estoques/listagem-estoque-inventario">
                                    Voltar
                                </Link>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Produto</th>
                                    <th className='text-center'>Localização</th>
                                    <th className='text-center'>Quantidade Disponivel</th>
                                    <th className='text-center'>Quantidade Reservada</th>
                                    <th className='text-center'>Quantidade Processo</th>
                                    <th className='text-center'>Data do movimento</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data de Criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>

                                </tr>
                            </thead>
                            <tbody>
                                {produtos?.map((produto) => (
                                    <tr className="text-center">
                                        <td>{produto.produtoDescricao}</td>
                                        <td>{produto.localizacao}</td>
                                        <td>{produto.quantidadeDisponivel}</td>
                                        <td>{produto.quantidadeReservado}</td>
                                        <td>{produto.quantidadeProcesso}</td>
                                        <td>{formatarData(produto.dataMovimento )}</td>
                                        <td>{produto.usuarioCriacaoNome}</td>
                                        <td>{produto.dataCriacao}</td>
                                        <td>{produto.usuarioAlteracaoNome?produto.usuarioAlteracaoNome:"..."}</td>
                                        <td>{produto.dataAlteracao}</td>
                                        <td>{produto.status}</td>
                                        <td>
                                        <div className='text-center'>
                                                <button
                                                    onClick={() => { 
                                                        openModal(true,produto.id,produto.idProduto,produto.idInventario,produto.idLocalizacao,produto.localizacao, produto.quantidadeDisponivel,produto.quantidadeReservado,produto.quantidadeProcesso,produto.dataMovimento,produto.status)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>)}
            <ModalInventarioProduto
                 id={id}
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchEstoqueInventarioProduto}
                isEdit={isEdit}
                isOpen={isEstoqueLocalizacaoModal}
                onHide={closeEstoqueLocalizacaoModal}
            
            ></ModalInventarioProduto>
        </div>

    )
}

export default ListagemEstoqueInvetarioProduto