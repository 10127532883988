import { useEffect, useMemo, useState } from 'react';
import { Col, Row as RowBootstrap, Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import {
  buildQueryParams,
  DescriptionComponent,
  DropDownButtons,
  formatDateTimeWithHours,
  HttpClient,
  IConfiguracoes,
  showAlert,
  SysledTable,
} from '../../../shared';
import ModalAddConfiguracao from './components/add-configuracoes-modal/ModalAddConfiguracao';

const ConfiguracaoPage: React.FC = () => {
  const http = new HttpClient();
  const navigate = useNavigate();

  const [configuracoes, setConfiguracoes] = useState<IConfiguracoes[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [status, setStatus] = useState<'ATIVO' | 'INATIVO'>('ATIVO');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editConfigId, setEditConfigId] = useState<number | null>(null);

  const columns: Column<IConfiguracoes>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ value }) => <div className='text-end'>{value || '...'}</div>,
      },
      {
        Header: 'Descrição',
        accessor: 'descricao',
        Cell: ({ value }) => <div className='text-start'>{value || '...'}</div>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <div className='text-center'>{value || '...'}</div>,
      },
      {
        Header: 'Usuário Criação',
        accessor: 'usuarioCriacao',
        Cell: ({ row }) => (
          <div className='text-center'>
            {row.original.usuarioCriacao && row.original.nomeUsuarioCriacao
              ? `${row.original.usuarioCriacao} - ${row.original.nomeUsuarioCriacao}`
              : '...'}
          </div>
        ),
      },
      {
        Header: 'Data Criação',
        accessor: 'dataCriacao',
        Cell: ({ value }) => (
          <div className='text-center'>{value ? formatDateTimeWithHours(value) : '...'}</div>
        ),
      },
      {
        Header: 'Usuário Alteração',
        accessor: 'usuarioAlteracao',
        Cell: ({ row }) => (
          <div className='text-center'>
            {row.original.usuarioAlteracao && row.original.nomeUsuarioAlteracao
              ? `${row.original.usuarioAlteracao} - ${row.original.nomeUsuarioAlteracao}`
              : '...'}
          </div>
        ),
      },
      {
        Header: 'Data Alteração',
        accessor: 'dataAlteracao',
        Cell: ({ value }) => (
          <div className='text-center'>{value ? formatDateTimeWithHours(value) : '...'}</div>
        ),
      },
      {
        Header: 'Ações',
        Cell: ({ row }) => (
          <div className='text-center d-flex justify-content-around align-items-center'>
            <DropDownButtons
              titleButton='Opções'
              buttons={[
                {
                  classIcon: 'bi bi-gear-wide-connected',
                  title: 'Itens',
                  handleFunction: () => navigate(`/configuracoes-itens/${row.original.id}`),
                },
                {
                  classIcon: 'bi bi-pencil',
                  title: 'Editar',
                  handleFunction: () => openEditModal(Number(row.original.id)),
                },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );

  const fetchConfiguracoes = async (statusFilter: string = 'ATIVO') => {
    setIsLoading(true);
    try {
      const response = await http.request<{ content: IConfiguracoes[] }>({
        method: 'GET',
        url: `configuracoes/listar${buildQueryParams({ status: statusFilter })}`,
      });
      setConfiguracoes(response.data.content);
    } catch (error: any) {
      showAlert('error', error.response?.data || 'Erro ao carregar configurações.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchConfiguracoes();
  }, []);

  const handleSearch = () => fetchConfiguracoes(status);

  const clearFilters = () => {
    setDescricao('');
    setStatus('ATIVO');
    fetchConfiguracoes('ATIVO');
  };

  const openEditModal = (id: number) => {
    setEditConfigId(id);
    setIsModalOpen(true);
  };

  return (
    <>
      <DescriptionComponent
        description='Listagem de Configurações'
        withLink
        buttonTitle='+ Configuração'
        handleAction={() => setIsModalOpen(true)}
        isButtonAction
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-6'>
              <label className='form-label'>Descrição:</label>
              <input
                type='text'
                className='form-control'
                placeholder='Digite a descrição'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Col>
            <Col className='col-sm-6'>
              <label className='form-label'>Status:</label>
              <select
                className='form-control'
                value={status}
                onChange={(e) => setStatus(e.target.value as 'ATIVO' | 'INATIVO')}
              >
                <option value='ATIVO'>Ativo</option>
                <option value='INATIVO'>Inativo</option>
              </select>
            </Col>
          </RowBootstrap>
        </form>
      </div>

      <div className='col-12 mt-6 mb-2 d-flex justify-content-end gap-2'>
        <button type='button' className='btn btn-primary' onClick={clearFilters}>
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
        </button>
        <button type='button' className='btn btn-success' onClick={handleSearch}>
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
        </button>
      </div>

      {isLoading ? (
        <Skeleton count={1} height={50} />
      ) : (
        <SysledTable columns={columns} data={configuracoes} message='SEM DADOS DE CONFIGURAÇÕES' />
      )}

      <ModalAddConfiguracao
        isOpen={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
          setEditConfigId(null);
          fetchConfiguracoes();
        }}
        handleRequest={fetchConfiguracoes}
        isEdit={!!editConfigId}
        idItem={editConfigId}
      />
    </>
  );
};

export default ConfiguracaoPage;
