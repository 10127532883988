import { Modal as BsModal, Button } from 'react-bootstrap';

import * as S from './modal.styles';
import {IModalProps} from '../../domain-types';
import Position from 'rsuite/esm/internals/Overlay/Position';

const Modal: React.FC<IModalProps> = ({
  isOpen = false,
  icon: Icon,
  title,
  actions,
  size,
  children,
  overflowBody,
  ...rest
}) => {
  return (
    <S.ModalWrapper
      show={isOpen}
      backdrop='static'
      size={size === 'md' ? undefined : size}
      centered
      {...rest}
    >
      <BsModal.Header closeButton>
        <BsModal.Title>
          {title} {Icon && <Icon />}
        </BsModal.Title>
      </BsModal.Header>

      <BsModal.Body style={overflowBody ? {overflow: overflowBody} : {}} className=''>
        {children}
      </BsModal.Body>

      <BsModal.Footer className='overflow-visible'>
        <Button variant='danger' onClick={rest['onHide']}>
          Fechar
        </Button>
        {actions?.map((action, index) => (
          <div key={index}>{action}</div>
        ))}
      </BsModal.Footer>
    </S.ModalWrapper>
  );
};

export default Modal;
