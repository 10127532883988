import styled from "styled-components";
import {DatePicker, DateRangePicker} from 'rsuite';

export const BagdeId = styled.div`
    width: 50px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;

    background-color: rgb(0, 119, 255);

    p {
        margin: 0px;
        padding: 0px;

        font-size: x-small;
        color: #fff;
    }
`

export const DateRangePickerComponent = styled(DateRangePicker) `
    .rs-input {
    font-size: 14px !important;
    color: #a0a2b8 !important;
    height: 43px !important; 
}
`