import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Modal} from '../../../../shared';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalUnidadeTempo = ({
  isOpen,
  onHide,
  isEdit = false,
  reload,
  dataLine = {},
  clearFiltros,
}) => {
  const [status, setStatus] = useState<string>('ATIVO');
  const [descricao, setDescricao] = useState<string>('');
  const [observacao, setObservacao] = useState<string>('');
  const [segundos, setSegundos] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirme, setIsConfirme] = useState<boolean>(false);

  const uploadUnidadeTempo = async (id: number) => {
    try {
      setIsLoading(true);
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-tempo${
        id ? `/${id}` : ''
      }`;

      let method = id ? axios.put : axios.post;

      const response = await method(url, {
        observacao: observacao ? observacao : '',
        segundos: segundos ? segundos : 0,
        descricao: descricao ? descricao : '',
        status: status ? status : 'ATIVO',
      });

      Swal.fire({
        icon: 'success',
        title: `Unidade de Tempo ${id ? 'editada' : 'cadastrada'} com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        reload!();
        onClose();
      });
    } catch (errors: any) {
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnidadeTempoById = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-tempo/${dataLine[0]}`
      );

      const data = response.data;
      if (response.status === 200) {
        setObservacao(data.Observa);
        setSegundos(data.segundos);
        setStatus(data.status);
        setDescricao(data.descricao);
        setObservacao(data.observacao);
      }
    } catch (error: any) {
      console.error(error);
      const {data} = error.response;

      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      } else
        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
    }
  };

  const onClose = () => {
    clearForm();
    onHide!();
  };

  const clearForm = () => {
    setStatus('ATIVO');
    setDescricao('');
    setObservacao('');
    setSegundos(0);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      disabled={isConfirme === false}
      onClick={() => uploadUnidadeTempo(dataLine[0] === undefined ? 0 : dataLine[0])}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  useEffect(() => {
    if (isEdit) {
      fetchUnidadeTempoById();
    }
  }, [dataLine]);

  useEffect(() => {
    setIsConfirme(descricao !== '' && segundos !== 0 ? true : false);
  }, [descricao, , segundos]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <div
          className='mb-3 form-label-container'
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
            {isEdit ? 'Editar' : 'Cadastrar'} Unidade de Tempo
          </h1>
        </div>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div className='my-4'>
          <form action='' className='mt-4'>
            <Row className='mt-8  mb-4 row'>
              <Col className='col-md-6 col-12'>
                <label htmlFor='' className='form-label'>
                  Descrição:
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite o descrição:'
                />
                {descricao === '' && (
                  <span className={` form-label text-danger`}>*Por favor, informe a descrição</span>
                )}
              </Col>
              <Col className='col-md-6 col-12 mt-8 mt-md-0'>
                <label htmlFor='' className='form-label'>
                  Segundos:
                </label>
                <input
                  type='text'
                  className='form-control'
                  value={segundos ? segundos : ''}
                  onChange={(event) => {
                    // setsegundos(e.target.value);
                    const rawValue = event.target.value;
                    const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
                    setSegundos(Number(cleanedValue));
                  }}
                  placeholder='Digite os segundos'
                />
                {segundos === 0 && (
                  <span className={` form-label text-danger`}>*Por favor, informe os segundos</span>
                )}
              </Col>
              <Col className='col-md-6 col-12 mt-6 '>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  value={status ? status : ''}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
            <Row className='mt-4 mb-4 row mt-6'>
              <label htmlFor='' className='form-label'>
                Observação:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  value={observacao ? observacao : ''}
                  onChange={(e) => {
                    setObservacao(e.target.value);
                  }}
                  placeholder='Digite a Observação'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUnidadeTempo;
