import Swal, { SweetAlertOptions } from 'sweetalert2';

export const showAlert = (
  type: 'success' | 'error' | 'confirm',
  message: string | { mensagem: string }[]
) => {
  const formattedMessage = Array.isArray(message)
    ? message.map((item) => item.mensagem).join('<br>') // Converte array em string com quebras de linha
    : message;

  let config: SweetAlertOptions = {
    title: formattedMessage,
    icon: type === 'error' ? 'error' : type === 'success' ? 'success' : 'warning',
  };

  if (type === 'success') {
    config = {
      ...config,
      timer: 3000,
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    };
  } else if (type === 'confirm') {
    config = {
      ...config,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    };
  }

  return Swal.fire(config);
};
