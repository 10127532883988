import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ResponseDadosparceiro} from '../../cadastros/parceiros/Interface';
import {Badge, Col, Row, Spinner} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import {
  formatStatus,
  formatStatusWorkflow,
  formatTipoParceiro,
} from '../proposta-pedido/components/formsPedido';
import {sincronizarParceiro} from '../listagem_requests/listagem_requests';
import {buildQueryParams, HttpClient, LoadMore, showAlert} from '../../../shared';
import {useFormik} from 'formik';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemParceiros = () => {
  const [parceiros, setParceiros] = useState<ResponseDadosparceiro[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');
  const [loading, setLoading] = useState<boolean>(false);
  const [syncedParceiros, setSyncedParceiros] = useState<number[]>([]);

  interface parceiros {
    cnpj: string;
    contato: string;
    parceiro: string;
    tipopessoa: number;
    status: string;
  }

  const [isPage, setIsPage] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    formik.handleSubmit();
  }, [isPage]);

  useEffect(() => {
    setSearchTerm('');
  }, [searchFiltro]);

  const fetchParceiros = async (search = '', filtro = '') => {
    try {
      setLoading(true);
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/profissionais/listar`;

      const response = await axios.get(url, {
        params: {
          search: search,
          filtro: filtro,
        },
      });

      if (response.status === 200) {
        setParceiros(response.data.content);
        const syncedIds = response.data.content
          .filter((parceiro) => parceiro.status === 9)
          .map((parceiro) => parceiro.id);
        setSyncedParceiros(syncedIds);
      } else if (response.status === 204) {
        Swal.fire({
          icon: 'info',
          title: 'Não existe registros de Parceiros para essa pesquisa',
        });
        setParceiros([]);
      }
    } catch (error) {
      console.error('Error fetching parceiros:', error); // Log do erro para depuração
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar parceiros',
        text: 'Ocorreu um erro ao carregar os parceiros. Por favor, tente novamente mais tarde.',
      });
    } finally {
      setLoading(false);
    }
  };

  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const setParceirosClear = async (page = 0) => {
    try {
      const params = {
        page: isPage,
        size: 10,
      };

      const finalUrl = `profissionais/listar${buildQueryParams(params)}`;

      const response = await http.request({
        method: 'GET',
        url: finalUrl,
      });

      const {content, totalPages} = response.data as any;
      setParceiros((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingData(false);
    }
  };

  let initialValues = {
    id: '',
    idParceiro: '',
    cpf: '',
    cnpj: '',
    status: 'ATIVO',
    parceiro: '',
  };

  const loadMore = () => {
    if (isHasMore) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const http = new HttpClient();

  const [isLoadingParceiro, setIsLoadingParceiro] = useState<boolean>(false);

  const clearFilter = () => {
    formik.setValues({
      cnpj: '',
      cpf: '',
      id: '',
      idParceiro: '',
      status: 'ATIVO',
      parceiro: '',
    });

    setParceirosClear();
    setIsPage(0);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingParceiro(true);
      try {
        const params = {
          cpf: values.cpf === '' ? null : values.cpf,
          cnpj: values.cnpj === '' ? null : values.cnpj,
          id: values.id === '' ? null : values.id,
          parceiro: values.parceiro === '' ? null : values.parceiro,
          idParceiro: values.idParceiro === '' ? null : values.idParceiro,
          page: isPage,
        };

        const url = `profissionais/listar${buildQueryParams(params)}`;

        const response = await http.request<any>({
          method: 'GET',
          url: url,
        });

        const {content, totalPages} = response.data as any;
        setParceiros((prev) => (isPage === 0 ? content : [...prev, ...content]));

        setIsHasMore(isPage < totalPages - 1);

        setIsLoadingData(false);
        setIsLoadingParceiro(false);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoadingParceiro(false);

        setSubmitting(false);
      }
    },
  });

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>;
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>;
      case 2:
        return <Badge bg='warning'>SINCRONIZADO</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  return (
    <>
      <div className=''>
        <div
          className='mb-3 form-label-container'
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <h1 className='text-dark fw-bolder'>Listagem de Profissionais</h1>
          <Link
            to='/cadastro-parceiros'
            className='btn btn-success btn-sm '
            style={{fontSize: '14px'}}
          >
            + Novo Profissional
          </Link>
        </div>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #000'}}></div>
        </div>
        <div className='mt-10'>
          <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
          <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
            <Row style={{marginTop: '20px'}}>
              <Col md='6' className='col-sm-6'>
                <label className='form-label'>ID:</label>
                <input
                  type='number'
                  placeholder='Digite o id do Profissional'
                  {...formik.getFieldProps('id')}
                  className='form-control'
                />
              </Col>
              <Col md='6' className='col-sm-6'>
                <label className='form-label'>Id G2:</label>
                <input
                  type='number'
                  placeholder='Digite o id do G2'
                  {...formik.getFieldProps('idParceiro')}
                  className='form-control'
                />
              </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
              <Col md='4' className='col-sm-6'>
                <label className='form-label'>Parceiro:</label>
                <input
                  type='text'
                  placeholder='Digite o Nome do Parceiro'
                  {...formik.getFieldProps('parceiro')}
                  className='form-control'
                />
              </Col>
              <Col md='4' className='col-sm-6'>
                <label className='form-label'>CPF:</label>
                <InputMask
                  type='text'
                  mask='999.999.999-99'
                  placeholder='Digite o CPF'
                  {...formik.getFieldProps('cpf')}
                  className='form-control'
                />
              </Col>
              <Col md='4' className='col-sm-6'>
                <label className='form-label'>CNPJ:</label>
                <InputMask
                  type='text'
                  mask='99.999.999/9999-99'
                  placeholder='Digite o CNPJ'
                  {...formik.getFieldProps('cnpj')}
                  className='form-control'
                />
              </Col>
            </Row>
            <div className='row mt-6 flex-end'>
              <div className='col-sm-2 mb-6'>
                <button
                  type='button'
                  onClick={() => clearFilter()}
                  className='btn btn-danger form-control'
                >
                  {isLoadingParceiro ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
                </button>
              </div>
              <div className='col-sm-2 mb-6'>
                <button type='submit' className='btn btn-success form-control'>
                  {isLoadingParceiro ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div>
          <table className='table table-hover table-striped table-rounded table-row-bordered border '>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Nome</th>

                <th className='text-center'>Tipo</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>Editar</th>
              </tr>
            </thead>
            <tbody className='fw-bold fs-6 text-gray-700'>
              {parceiros.map((parceiros) => (
                <tr key={parceiros.id}>
                  <td className='text-center '>{parceiros.id}</td>
                  <td className='text-center'>
                    <div>{parceiros.parceiro || 'N/A'}</div>
                  </td>

                  <td className='text-center'> {formatTipoParceiro(parceiros.tipoPessoa)}</td>
                  <td className='text-center'>
                    {' '}
                    {parceiros.status == 'ATIVO' ? formatStatus(1) : formatStatus(0)}
                  </td>
                  <td className='text-center'>
                    <Link
                      to={`/editar-parceiro/${parceiros.id}`}
                      className='btn btn-success btn-sm'
                    >
                      Editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <LoadMore
            isHasMore={isHasMore}
            isLoadingSearch={isLoadingParceiro}
            loadMore={() => loadMore()}
          />
        </div>
      </div>
    </>
  );
};

export default ListagemParceiros;
