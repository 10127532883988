import {useState, useEffect, useMemo} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import './../styles/fornecedores.css';

import {OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap';
import {DescriptionComponent, smoothScrollToBottom, useModal} from '../../../../shared';
import Skeleton from 'react-loading-skeleton';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {IFornecedorDisponibilidade} from '../../../../shared/domain-types/models/fornecedor-disponibilidade';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import ItemDetailsDisponibilidadeModal from './modal/modalDetailsDisponibilidade';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export function DisponibilidadeFornecedor({formikFornc: formik}) {
  const [fornecDispData, setFornecDispData] = useState<IFornecedorDisponibilidade[]>([]);

  const [isLoadingDisponibilidade, setIsLoadingDisponibilidade] = useState<boolean>(false);
  const [isIdDisponibilidadeEdit, setIsIdDisponibilidadeEdit] = useState<number | string>();
  const [typeModal, setTypeModal] = useState<'add' | 'edit' | 'view' | null>(null);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);

  const formatToMoney = (value: number) =>
    new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value || 0);

  const renderCellValue = (value: any) => (value ? value : '...');

  const disponibilidadeItemsColumns: Column<IFornecedorDisponibilidade>[] = [
    {
      Header: 'Detalhes',
      accessor: 'id',
      Cell: ({row}) => (
        <div className='text-end'>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-edit-${row.original.id}`}>
                Observe os detalhes da disponibilidade
              </Tooltip>
            }
          >
            <button
              className='btn btn-primary btn-sm'
              style={{
                cursor: 'pointer',
              }}
              type='button'
              onClick={() => {
                handleModalDetails(row.original.id!);
              }}
            >
              <i style={{padding: 0}} className='bi bi-eye'></i>
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      Header: 'Marca',
      accessor: 'marca',
      Cell: ({row}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {row.original.marca}
        </div>
      ),
    },
    {
      Header: 'Valor Minimo de Frete',
      accessor: 'pedidoMinimoValor',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(formatToMoney(value))}</div>,
    },
    {
      Header: 'Valor Minimo de Frete CIF',
      accessor: 'pedidoMinimoValorCIF',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(formatToMoney(value))}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          type='button'
          onClick={() => {
            handleOnEdit(row.original.id!);
          }}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const data = useMemo(() => fornecDispData, [fornecDispData]);
  const columns = useMemo(() => disponibilidadeItemsColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const [isDetailsModal, openDetailsModal, closeDetailsModal] = useModal();
  const handleModalDetails = (id: number) => {
    setTypeModal('view');
    openDetailsModal();

    setIsIdDisponibilidadeEdit(id);
  };

  const fetchDisponibilidade = async ({page = 0}: {page?: number}) => {
    try {
      setIsLoadingDisponibilidade(true);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores-disponibilidade/listar?idFornecedor=${formik.values.id}&page=${page}`
      );

      if (response.status === 200) {
        const {content, totalPages} = response.data;
        setIsHasMore(isPage < totalPages - 1);

        setFornecDispData((prev) => (isPage === 0 ? content : [...prev, ...content]));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao carregar disponibilidade do fornecedor',
          text: response.data.mensagem,
          confirmButtonText: 'Ok',
        });
        throw new Error('Erro ao buscar disponibilidade do fornecedor');
      }
    } catch (error) {
      console.error('Erro ao buscar informações de fornecedor:', error);
    } finally {
      setIsLoadingDisponibilidade(false);
    }
  };

  const loadMore = () => {
    if (isHasMore && !isLoadingDisponibilidade) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  useEffect(() => {
    fetchDisponibilidade({page: isPage});
  }, [isPage]);

  const handleOnAdd = () => {
    setTypeModal('add');
    openDetailsModal();
  };

  const handleOnEdit = (id: string | number) => {
    setIsIdDisponibilidadeEdit(id);
    setTypeModal('edit');
    openDetailsModal();
  };

  return (
    <>
      <div className='mb-5 mt-8 row'>
        <DescriptionComponent
          description='Disponibilidade'
          buttonTitle='+ Adicionar'
          isButtonAction
          handleAction={() => handleOnAdd()}
          variantButton='warning'
          showTooltip
          tooltipText='Não é possível realizar esta ação.'
        />
        {isLoadingDisponibilidade ? (
          <Skeleton count={20} height={25} />
        ) : (
          <div className='table-responsive mt-4' style={{maxHeight: 500}}>
            <table
              id='kt_table_formularios'
              className='table table-hover table-striped table-rounded table-row-bordered border px-5'
              {...getTableProps()}
            >
              <thead className='thead-dark'>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map(
                    (column: ColumnInstance<IFornecedorDisponibilidade>, index: number) => (
                      <th key={index} className={index === 0 ? 'text-end' : 'text-start'}>
                        {column.render('Header')}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<IFornecedorDisponibilidade>, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        showEditButton
                        onEdit={(id) => {
                          handleOnEdit(id);
                        }}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        SEM DISPONIBILIDADE
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {isHasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' type='button' onClick={loadMore}>
                  {isLoadingDisponibilidade ? (
                    <Spinner size='sm' animation='border' />
                  ) : (
                    'Carregar Mais'
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <ItemDetailsDisponibilidadeModal
        isOpen={isDetailsModal}
        onHide={closeDetailsModal}
        idItem={isIdDisponibilidadeEdit}
        typeModal={typeModal}
        setTypeModal={setTypeModal}
        fetchDisponibilidade={fetchDisponibilidade}
        idFornecedor={formik.values.id}
      />
    </>
  );
}
