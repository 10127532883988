import { Col, Row, Spinner } from "react-bootstrap";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { AsyncSelectProductByIdSysled, EstoqueEntradasProduto, EstoqueLocalizacao, smoothScrollToBottom, useModal } from "../../../../shared";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import ModalEntradaProduto from "./components/ModalEntradasProduto";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueEntradasProduto = () => {

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [page, setPage] = useState(0)

    const [entradas, setEntradas] = useState<EstoqueEntradasProduto[]>([])
    const [dataLine, setDataLine] = useState<EstoqueLocalizacao[]>([])

    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [quantidade, setQuantidade] = useState<number | null>(null)
    const [valor, setValor] = useState<string | null>(null)

    const [isEstoqueEntradasProdutoModal, openEstoqueEntradasProdutoModal, closeEstoqueEntradasProdutoModal] = useModal();


    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }

    const clearFiltros = () => {
        setStatus('ATIVO')
        setSelectedProduto(null)
        setQuantidade(null)
        setValor(null)
        setPage(0)
    }
    const fetchEstoqueEntradaItens = async (page = 0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas-itens/listar`,
                {
                    params: {
                        status: status,
                        idProduto: selectedProduto?.value,
                        idEntrada: id,
                        quantidade: quantidade ? quantidade : null,
                        valor: valor ? valor : null,
                        page: page
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                    setEntradas([]);
                }
                setEntradas((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Produto da Entrada para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueEntradasProdutoModal();
    }

    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchEstoqueEntradaItens(0);
    };

    useEffect(() => {
        // handleSearch()
        fetchEstoqueEntradaItens(page)
        // setLoading(true)
        // setTimeout(() => {
        //     setLoading(false)
        // }, 2000);
        //     }
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Entrada do Produto
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => { openModal(false) }}
                >
                    Cadastrar Entrada do Produto
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form className="my-9" action="">

                    <Row >
                        <Col>
                            <label htmlFor="" className="form-label">Produto:</label>
                            <AsyncSelectProductByIdSysled inputOption={selectedProduto} onKeyDown={(e) => { setSelectedProduto(null) }} handleOptions={(e) => { setSelectedProduto(e) }}></AsyncSelectProductByIdSysled>
                        </Col>
                    </Row>
                    <Row className='mt-8'>
                        <Col>
                            <label htmlFor="" className="form-label"  >ID da entrada:</label>
                            <input value={id} disabled className="form-control" type="number" placeholder="Digite a quantidade" />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade:</label>
                            <input value={quantidade ? quantidade : ""} onChange={(e) => { setQuantidade(Number(e.target.value)) }} className="form-control" type="number" placeholder="Digite a quantidade" />
                        </Col>
                    </Row>
                    <Row className="mt-8">

                        <Col>
                            <label htmlFor="" className="form-label"  >Valor:</label>
                            <input type="text" value={
                                valor
                                    ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                                onChange={(event) => {
                                    const rawValue = event.target.value;
                                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                                    setValor(numericValue.toString());
                                }} className="form-control" placeholder="Digite o valor" />
                        </Col>
                        <Col>

                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <div className='col-12 my-10' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <Link className="btn mx-5 px-10 btn-info " to="/consultas/estoques/listagem-estoque-entradas">
                                Voltar
                            </Link>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Produto</th>
                                    <th className='text-center'>Valor</th>
                                    <th className='text-center'>Quantidade</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data Criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de Alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>

                                </tr>
                            </thead>
                            <tbody>
                                {entradas?.map((log) => (
                                    <tr className="text-center">
                                        <td>{log.produto}</td>
                                        <td>{log.valor ? `R$ ${log.valor}` : '...'}</td>
                                        <td>{log.quantidade ? log.quantidade : '...'}</td>
                                        <td>{log.usuarioCriacaoNome}</td>
                                        <td>{log.dataCriacao}</td>
                                        <td>{log.usuarioAlteracaoNome ? log.usuarioAlteracaoNome : "..."}</td>
                                        <td>{log.dataAlteracao}</td>
                                        <td>{log.status}</td>
                                        <td>
                                            <div className='text-center'>
                                                <button
                                                    onClick={() => {
                                                        openModal(true, log.id, log.idProduto, log.produto, log.idEntrada, log.quantidade, log.valor, log.status)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>)}

            <ModalEntradaProduto
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchEstoqueEntradaItens}
                isEdit={isEdit}
                isOpen={isEstoqueEntradasProdutoModal}
                onHide={closeEstoqueEntradasProdutoModal}

            ></ModalEntradaProduto>
        </div>

    )
}

export default ListagemEstoqueEntradasProduto