import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {IProjetos, ISelectOption} from '../../domain-types';
import { formatarData } from '../../core';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectProjetos: React.FC<IProps> = ({handleOptions, inputOption, isDisabled, onKeyDown}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectProjetoAsync, setSelectProjetoAsync] = useState<ISelectOption | null>(null);
  const [projetoDetails, setProjetoDetails] = useState<IProjetos[]>([]);

  const loadOptions = async (inputValue: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/listar`;

      const response = await axios.get(url, {
        params: {
          procedure: true,
          parametro: inputValue
        },
      });
      const data = await response.data.content;

      console.log(response);

      setProjetoDetails(data);

      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de projetos:', error);
      return [];
    }
  };

  const loadOptionsById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${id}?procedure=true`;

      const response = await axios.get(url);
      const data = await response.data;

      console.log(response.data);
      setProjetoDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de projetos:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    if (Array.isArray(projetoDetails) && projetoDetails.length > 0) {
        console.log(projetoDetails, option , inputOption);
        const projeto = projetoDetails.find((projeto) => projeto.id === +option.value);
        if (projeto) {
            return (
              <div className='container-message-empresa'>
                <div className='box-left-empresa'>
                  <div className='info-container-empresa'>
                    <p>
                      Titulo: <span>{projeto.titulo ? projeto.titulo : '-'}</span>
                    </p>
                    <p>
                      Empresa: <span>{projeto.empresaFantasia ? projeto.empresaFantasia : '-'}</span>
                    </p>
      
                    <p>
                      Setor:{' '}
                      <span>
                        {projeto.setorTitulo
                          ? projeto.setorTitulo.length > 30
                            ? `${projeto.setorTitulo.slice(0, 30)} ...`
                            : projeto.setorTitulo
                          : ' - '}
                      </span>
                    </p>
                    <p>
                     Cliente : <span>{projeto.clienteFantasia ? projeto.clienteFantasia : '-'}</span>
                    </p>
                  </div>
                </div>
                <div className='box-right-empresa'>
                  <p>
                    {' '}
                    Status do Projeto: <span> {projeto.projetoStatusTitulo ? projeto.projetoStatusTitulo : '-'} </span>{' '}
                  </p>
                  <p>
                    {' '}
                    Prioridade: <span> {projeto.prioridade ? projeto.prioridade : '-'} </span>{' '}
                  </p>
                  <p>
                    {' '}
                    Data Inicio: <span>{projeto.dataInicio ? formatarData(projeto.dataInicio) : '-'}</span>
                  </p>
                  <p>
                    {' '}
                    Data Fim: <span> {projeto.dataFim ? formatarData(projeto.dataFim) : '-'} </span>{' '}
                  </p>
                </div>
              </div>
            );
          }
      
    }    
    
    return null;
  };

  useEffect(() => {
    if (selectProjetoAsync && handleOptions) {
      handleOptions(selectProjetoAsync);
      console.log(selectProjetoAsync)
    }
  }, [selectProjetoAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsById(inputOption.value);
      setSelectProjetoAsync(inputOption);
    }
     else 
     if (inputOption == null) {
      setSelectProjetoAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
    styles={{
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
      option: (provided, state) => ({
        ...provided,
        zIndex: 9999,
      }),
    }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Projeto'
      loadOptions={loadOptions}

      value={selectProjetoAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectProjetoAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectProjetos;
