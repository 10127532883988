import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { Children, useEffect, useState } from "react";
// import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
// import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
// import { is } from "date-fns/locale";
import { da } from "date-fns/locale";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const CrudHeader = ({title, children, titleCadastro, clearFiltros, handleSearch, isLoading, openModal }) => {

    return (
        <div>
            <div
                className='mb-3 form-label-container'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <h1 className='fw-bolder ' style={{ color: '#3f3f3f' }}>
                    {title}
                </h1>
                <Button
                    onClick={() => openModal(false)} 
                    variant="success" >

                    Cadastrar {titleCadastro}
                </Button>
            </div>
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div className='mt-10'>
                <h4 className='text-dark'>FILTROS DE PESQUISA</h4>

                <form
                    onSubmit={handleSearch}
                    className='form-control-solid row  mb-5 justify-content-end d-flex align-items-end '>
                    <div className='col-12 mt-6 d-flex flex-column gap-8' >
                        {children}
                    </div>
                    <div className=" d-flex justify-content-end">
                        <div 
                        style={{ width: '30%' }}
                        className='col-12 my-8 d-flex  justify-content-around ' >
                            
                            <button
                                type='button'
                                style={{ width: '45%' }}
                                onClick={() => clearFiltros()}
                                className=' btn  btn-danger form-control'
                            >
                                Limpar
                                
                            </button>
                            

                            <button
                                type='submit'
                                style={{ width: '45%',}}
                                className='btn btn-primary '

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                <>
                                    <i className="bi bi-search mx-0"></i>
                                    <span>Pesquisar</span> 
                                </>
                            )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default CrudHeader