import { Col,  Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { EstoquesTipos, smoothScrollToBottom, useModal } from "../../../../shared";
import ModalEstoqueTipos from "./components/ModalEstoqueTipos";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ListagemEstoquesTipos = () => {
    const [estoquesTipos, setEstoquesTipos] = useState<EstoquesTipos[]>([])
    const [dataLine, setDataLine] = useState<EstoquesTipos[]>([])

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)


    const [isEstoqueTiposModal, openEstoqueTiposModal, closeEstoqueTiposModal] = useModal();
    
    const loadMore = () => {
            setPage((prevPage) => prevPage + 1);
            smoothScrollToBottom()
        }

    const fetchEstoquesTipos = async (page=0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipos/listar`,
                {
                    params: {
                        status: status,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const {content, totalPages} = response.data;

            if (response.status === 200) {
                setEstoquesTipos((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);
          
            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Tipos de estoque para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setPage(0)
    }



    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchEstoquesTipos(0);
    };

    const openModal = (...data) => {
        if (data) {
            setDataLine(data)
        }
        openEstoqueTiposModal();
    } 

    useEffect(()=>{
        if(status === 'ATIVO' && descricao === null && titulo === null)    {
           fetchEstoquesTipos(0)}
    },[status, descricao, titulo])

    useEffect(() => {
        fetchEstoquesTipos(page)
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Tipo de Estoque
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }} 
                onClick={() => {
                    setIsEdit(false)
                    openModal()}}
                >
                    Cadastrar Tipo de Estoque
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">
                    <Row className="mt-4 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Titulo:</label>
                            <input type="text" 
                             className="form-control"
                            value={titulo ? titulo : ""}
                                onChange={(e) => {
                                    setTitulo(e.target.value)
                                }}
                                 placeholder="Digite o titulo" />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => {setStatus(e.target.value)}}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name="" 
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                             placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row>
                    <div className='col-12 my-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                    Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>)}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Titulo</th>
                                    <th className='text-center'>Descrição:</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estoquesTipos?.map((estoque) => (
                                    <tr className="text-center">
                                        <td>{estoque.id}</td>
                                        <td>{estoque.titulo}</td>
                                        <td>{estoque.descricao?estoque.descricao:'...'}</td>
                                        <td>{estoque.usuarioCriacaoNome}</td>
                                        <td>{estoque.dataCriacao}</td>
                                        <td>{estoque.usuarioAlteracaoNome?estoque.usuarioAlteracaoNome:'...'}</td>
                                        <td>{estoque.dataAlteracao}</td>
                                        <td>{estoque.status}</td>
                                        <td>
                                            <div className='text-center'>
                                                <button
                                                    // onClick={() => { openModal(true, log.titulo, log.id, log.descricao, log.idEmpresa, log.empresaFantasia, log.status, log.idEstoqueTipos, log.estoqueTipos) }}
                                                    onClick={() => { 
                                                        openModal(estoque.id, estoque.titulo, estoque.descricao, estoque.status)
                                                        setIsEdit(true)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ModalEstoqueTipos clearFiltros={clearFiltros} dataLine={dataLine} reload={fetchEstoquesTipos} isEdit={isEdit} isOpen={isEstoqueTiposModal} onHide={closeEstoqueTiposModal}/>
        </div>
    )
}

export default ListagemEstoquesTipos