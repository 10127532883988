import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {ISelectOption, Modal} from '../../../../shared';
import AsyncSelect from 'react-select/async';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalGarantiaProduto = ({
  isOpen,
  onHide,
  isEdit = false,
  reload,
  dataLine = {},
  clearFiltros,
}) => {
  const [quantidade, setQuantidade] = useState<number>(0);
  const [selectedUnidade, setSelectedUnidade] = useState<ISelectOption | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirme, setIsConfirme] = useState<boolean>(false);

  const uploadGarantia = async (id: number) => {
    try {
      setIsLoading(true);

      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/garantia${
        id ? `/${id}` : ''
      }`;

      let method = id ? axios.put : axios.post;

      const response = await method(url, {
        quantidade: quantidade ? quantidade : 0,
        idUnidadeTempo: selectedUnidade?.value,
        status: status,
      });

      Swal.fire({
        icon: 'success',
        title: `Garantia ${id ? 'editada' : 'cadastrada'} com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        reload!();
        onClose();
      });
    } catch (errors: any) {
      // }
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchGarantiaById = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/garantia/${dataLine[0]}`
      );

      const data = response.data;

      if (response.status === 200) {
        setQuantidade(data.quantidade);
        setStatus(data.status);
        setSelectedUnidade({
          value: data.idUnidadeTempo,
          label: data.unidadeTempo,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    }
  };
  const onClose = () => {
    clearForm();
    onHide!();
  };
  const clearForm = () => {
    setStatus('ATIVO');
    setQuantidade(0);
    setSelectedUnidade(null);
  };
  const loadOptionsUnidade = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-tempo/listar`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      return data.content.map((item) => ({
        value: item.id,
        label: item.descricao,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      disabled={isConfirme === false}
      onClick={() => uploadGarantia(dataLine[0] !== undefined ? dataLine[0] : 0)}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );
  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  useEffect(() => {
    if (isEdit) {
      fetchGarantiaById();
    }
  }, [dataLine]);

  useEffect(() => {
    setIsConfirme(quantidade !== 0 && selectedUnidade !== null ? true : false);
  }, [quantidade, selectedUnidade]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <div
          className='mb-3 form-label-container'
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
            {isEdit ? 'Editar' : 'Cadastrar'} Garantia
          </h1>
        </div>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div className='my-4'>
          <form action='' className='mt-4'>
            <Row className='mt-8  mb-4 row'>
              <Col className='col-12 col-md-6 mt-6 mt-md-0'>
                <label htmlFor='' className='form-label'>
                  Quantidade:
                </label>
                <input
                  type='text'
                  className='form-control'
                  maxLength={9}
                  value={quantidade ? quantidade : ''}
                  onChange={(e) => {
                    const rawValue = e.target.value;
                    const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéric
                    setQuantidade(Number(cleanedValue));
                  }}
                  placeholder='Digite o quantidade'
                />
                {quantidade === 0 && (
                  <span className={` form-label text-danger`}>
                    *Por favor, informe o quantidade
                  </span>
                )}
              </Col>
              <Col className='col-12 col-md-6 mt-6 mt-md-0'>
                <label htmlFor='' className='form-label'>
                  Unidade de Tempo:
                </label>
                {/* <input type="text"
                            className="form-control"
                            value={quantidade ? quantidade : ""}
                            onChange={(e) => {
                                setquantidade(e.target.value)
                            }}
                            placeholder="Digite o quantidade" /> */}
                <AsyncSelect
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  isClearable
                  defaultOptions
                  className='py-2 react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder='Selecione a Unidade de Tempo'
                  loadOptions={loadOptionsUnidade}
                  value={selectedUnidade}
                  onChange={(selectedOption: ISelectOption | null) => {
                    if (selectedOption) {
                      setSelectedUnidade(selectedOption);
                    } else {
                      setSelectedUnidade(null);
                    }
                  }}
                  cacheOptions
                  //   defaultOptions={defaultCompradorOptions}
                />
                {selectedUnidade === null && (
                  <span className={` form-label text-danger`}>
                    *Por favor, informe a Unidade de tempo
                  </span>
                )}
              </Col>
              <Col className='col-12 col-md-6 mt-6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  value={status ? status : ''}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalGarantiaProduto;
