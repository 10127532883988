import {useEffect, useState} from 'react';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {FaEdit, FaPlus} from 'react-icons/fa';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {FormUploadPdf, Modal} from '../../../../../shared';
import {
  putFormularioArquivo,
  sendFormularioArquivo,
} from '../../../../cadastros/cadastro_requests/cadastro_requests';
import {getFormulariosArquivosById} from '../../../../listagem/listagem_requests/listagem_requests';
import {useParams} from 'react-router-dom';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  handleRequest?: () => void;
  idItem?: number | null;
  isEdit?: boolean;
};

const FormulariosArquivosModal: React.FC<Props> = ({
  isOpen,
  handleRequest,
  idItem,
  onHide,
  isEdit,
}) => {
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isOk, setIsOk] = useState<boolean>(false);
  const [isArquivo, setIsArquivo] = useState<string>('');
  const [isArquivoExistente, setIsArquivoExistente] = useState<boolean>(false);

  const initialValues = {
    status: 'ATIVO',
    titulo: '',
    descricao: '',
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true);

      if (isEdit) {
        if (formik.values.titulo == '') {
          let errorMessage = '';

          switch (true) {
            case formik.values.titulo == '':
              errorMessage = 'A inserção do Título é obrigatória';
              break;
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });

          Toast.fire({
            icon: 'info',
            title: errorMessage,
          });

          setSubmitting(false);
          setIsLoading(false);
          return;
        }
        try {
          await putFormularioArquivo(idItem!, {
            ...values,
            idFormulario: id,
          });
          setSubmitting(false);
          setIsLoading(false);

          Swal.fire({
            icon: 'success',
            title: 'Arquivo editado com sucesso!',
            showCancelButton: false,

            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          if (handleRequest) {
            handleRequest();
          }

          onClose();

          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          if (formik.values.titulo == '' || isArquivo == '') {
            let errorMessage = '';

            switch (true) {
              case formik.values.titulo == '':
                errorMessage = 'A inserção do Título é obrigatória';
                break;
              case isArquivo == '':
                errorMessage = 'A inserção do Arquivo é obrigatória';
                break;
            }

            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });

            Toast.fire({
              icon: 'info',
              title: errorMessage,
            });

            setSubmitting(false);
            setIsLoading(false);
            return;
          }
          setIsLoading(true);

          await sendFormularioArquivo({
            ...values,
            arquivo: isArquivo,
            idFormulario: id,
          });

          if (handleRequest) {
            handleRequest();
          }

          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Arquivo cadastrado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          setIsLoading(false);

          onClose();
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const onClose = () => {
    onHide!();

    formik.setValues({
      titulo: '',
      status: 'ATIVO',
      descricao: '',
    });

    setIsArquivoExistente(false);
  };

  const submitButton = (
    <Button type='submit' variant='success' onClick={() => formik.handleSubmit()}>
      {isLoading && <Spinner animation='border' size='sm' />}
      {!isLoading && (isEdit ? 'Editar' : 'Cadastrar')}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    title: isEdit ? 'Editar Arquivo' : 'Cadastrar Arquivo',
    onHide: onClose,
    size: 'xl',
  };

  const getArquivosByIdFormulario = async () => {
    setIsLoadingData(true);
    try {
      const response = await getFormulariosArquivosById(idItem!);

      const {data} = response;

      formik.setValues({
        titulo: data.titulo,
        status: data.status,
        descricao: data.descricao,
      });

      setIsArquivoExistente(true);

      setIsLoadingData(false);
    } catch (errors: any) {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (isEdit && isOpen) {
      getArquivosByIdFormulario();
    }
  }, [idItem, isEdit, isOpen]);

  return (
    <>
      <Modal {...modalConfigs} icon={isEdit ? FaEdit : FaPlus}>
        <div>
          {isLoadingData ? (
            <Skeleton count={4} height={70} />
          ) : (
            <form className='form-control-solid' onSubmit={formik.handleSubmit}>
              <Row className='mt-4'>
                <Col md='6' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Título
                  </label>

                  <input
                    type='text'
                    placeholder='Digite a quantidade de pendência'
                    {...formik.getFieldProps('titulo')}
                    className={`form-control ${
                      formik.touched.titulo && formik.errors.titulo ? 'is-invalid' : ''
                    }`}
                  />
                </Col>
                <Col md='6' className='col-12'>
                  <label className='form-label'>Status:</label>
                  <select
                    {...formik.getFieldProps('status')}
                    className={`form-control ${
                      formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                    }`}
                  >
                    <option value='ATIVO'>ATIVO</option>
                    <option value='INATIVO'>INATIVO</option>
                  </select>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col md='12' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Arquivo:
                  </label>
                  <FormUploadPdf
                    setIsOk={setIsOk}
                    isOk={isOk}
                    disabled={isArquivoExistente}
                    setArquivo={setIsArquivo}
                    isPdf
                  />
                  {(isArquivo === null || (isArquivo === '' && !isArquivoExistente)) && (
                    <span className={` form-label text-danger`}>*Por favor, informe o Arquivo</span>
                  )}
                  {isEdit && isArquivoExistente && (
                    <span className={` form-label text-black-50`}>
                      *Arquivo ja anexado, não pode ser alterado
                    </span>
                  )}
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col className='col-6-sm'>
                  <label className='form-label'>Descrição:</label>
                  <textarea
                    placeholder='Digite a descrição do formulário'
                    {...formik.getFieldProps('descricao')}
                    className={`form-control ${
                      formik.touched.descricao && formik.errors.descricao ? 'is-invalid' : ''
                    }`}
                  />
                </Col>
              </Row>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FormulariosArquivosModal;
