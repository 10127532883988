import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
// import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
// import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
import { is } from "date-fns/locale";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { AsyncSelectCliente, AsyncSelectEmpresa, AsyncSelectFornecedor, AsyncSelectProjetos, Modal } from "../../../../shared";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalOrcamentosProjetos = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(false)

    const [valorEstimado, setValorEstimado] = useState<string | null>(null)
    const [valorAprovado, setValorAprovado] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [dataEmissao, setDataEmissao] = useState<string | null>(null);
    const [dataVencimento, setDataVencimento] = useState<string | null>(null);
    const [dataPagamento, setDataPagamento] = useState<string | null>(null);



    const cadastrarOrcamentoProjeto = async () => {

        try {
            setIsLoading(true)
            if(selectedProjeto && (dataEmissao !== null && dataEmissao !== '') && (valorEstimado !== null && valorEstimado !== '' && valorEstimado !== '0') && (valorAprovado !== null && valorAprovado !== '' && valorAprovado !== '0')) {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-orcamentos`;

            const response = await axios.post(url, {
                status: status ? status : null,
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                dataAprovacao: dataEmissao ?  `${dataEmissao}T17:00:52.864`  : null,
                valorEstimado: valorEstimado ? valorEstimado : null,   
                valorAprovado: valorAprovado ? valorAprovado : null,   
                descricao: descricao ? descricao : null,
            });

            Swal.fire({
                icon: 'success',
                title: `Orçamento do Projeto cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                reload!()
                onClose()
            })

        }
    }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }

    const loadOptionsStatus = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamento-status/listar`;

            const response = await axios.get(url,{
                params: {status: 'ATIVO'}
            });
            const data = await response.data;
            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        }
    };

    const editarEstoqueEntrada = async () => {
        try {
            setIsLoading(true)
            if(selectedProjeto && (dataEmissao !== null && dataEmissao !== '') && (valorEstimado !== null && valorEstimado !== '' && valorEstimado !== '0') && (valorAprovado !== null && valorAprovado !== '' && valorAprovado !== '0')) {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-orcamentos/${dataLine[0]}`;
            const response = await axios.put(url, {
                status: status ? status : null,
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                dataAprovacao: dataEmissao ?  `${dataEmissao}T17:00:52.864`  : null,
                valorEstimado: valorEstimado ? valorEstimado : null,   
                valorAprovado: valorAprovado ? valorAprovado : null,   
                descricao: descricao ? descricao : '',
            });
            Swal.fire({
                icon: 'success',
                title: `Orçamento do Projeto editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                reload!()
                onClose()

            });

        }
    }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }

    const onClose = () => {
        clearForm()
        // clearFiltros()
        // reload!()
        onHide!();
    }
    
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedProjeto(null)
        setValorEstimado(null)
        setValorAprovado(null)
        setDataEmissao(null)
        setDataVencimento(null)
        setDataPagamento(null)
        setDescricao(null)
    }

    const submitButton = (
        <Button
            type='submit'
            variant='success'
            disabled={isConfirme===false}
            onClick={() => isEdit ? editarEstoqueEntrada() : cadastrarOrcamentoProjeto()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        const date = new Date(inputDate);
      
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0'); 
      
        return `${year}-${month}-${day}`;
      }


    const fetchDataProjetosById = async (page = 0) => {

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-orcamentos/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }
                console.log(response.data.content)
                setSelectedProjeto({value: data.idProjeto, label: ''})
                setValorEstimado(data.valorEstimado)
                setValorAprovado(data.valorAprovado)
                setDataEmissao(formatDate(data.dataAprovacao))
                setStatus(data.status)
                setDescricao(data.descricao)
            }
        } 
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } 
    }

    useEffect(() => {
        if(isEdit){
        fetchDataProjetosById()
    }
    },[dataLine])




    const loadOptionsProjetos= async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/listar`;

            const response = await axios.get(url,{
                params: {
                    size : 1000,
                }
            });
            const data = await response.data;
            console.log(data.id)

            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    useEffect(()=>{
        if (selectedProjeto && (dataEmissao !== null && dataEmissao !== '') && (valorEstimado !== null && valorEstimado !== '' && valorEstimado !== '0') && (valorAprovado !== null && valorAprovado !== '' && valorAprovado !== '0')){
                setIsConfirme(true)
            }
        else{
            setIsConfirme(false)
        }
    },[
        selectedProjeto,
        dataEmissao,
        valorEstimado,   
        valorAprovado,   
    ])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Orçamento do Projeto
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
            <form 
            // onSubmit={handleSearch}
             className='form-control-solid row gap-6 mb-5'>
                    <Row className='col-12 mt-5'>
                        <Col>
                            <label htmlFor="" className='form-label'>Projeto:</label>
                            {/* <AsyncSelect defaultOptions value={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} onChange={(option) => { setSelectedProjeto(option) }} loadOptions={loadOptionsProjetos} placeholder="Selecione o Projeto" ></AsyncSelect> */}
                            <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { setSelectedProjeto(option) }}  ></AsyncSelectProjetos>
                            {/* <AsyncSelectProjetos></AsyncSelectProjetos> */}
                            {(selectedProjeto === null) && <span className={` form-label text-danger`}>*Por favor, informe o Projeto</span>}
                        </Col>
                       
                    </Row>

                    <Row className='col-12 '>
                    <Col>
                            <label htmlFor="" className='form-label'>Valor estimado:</label>
                                <input type="text" 
                                value={
                                    valorEstimado
                                        ? `R$ ${parseFloat(String(valorEstimado)).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`
                                        : 'R$ 0,00'
                                }
                                    onChange={(event) => {
                                        const rawValue = event.target.value;
                                        const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                        const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valorEstimado
                                        setValorEstimado(numericValue.toString());
                                    }} className="form-control" placeholder="Digite o valor Estimado" />
                            {(valorEstimado === null || valorEstimado === '0') && <span className={` form-label text-danger`}>*Por favor, informe o Valor estimado</span>}

                            </Col> 
                    <Col>
                            <label htmlFor="" className='form-label'>Valor aprovado:</label>
                                <input type="text" 
                                value={
                                    valorAprovado
                                        ? `R$ ${parseFloat(String(valorAprovado)).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`
                                        : 'R$ 0,00'
                                }
                                    onChange={(event) => {
                                        const rawValue = event.target.value;
                                        const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                        const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valorAprovado
                                        setValorAprovado(numericValue.toString());
                                    }} className="form-control" placeholder="Digite o valor Aprovado" />
                            {(valorAprovado === null || valorAprovado === '0') && <span className={` form-label text-danger`}>*Por favor, informe o Valor aprovado</span>}

                            </Col>
                        </Row>
                        <Row>
                            
                        <Col>
                            <label className='form-label' htmlFor="">Data de aprovação</label>
                            <input type="date" onChange={(e) => setDataEmissao(e.target.value)} value={dataEmissao ? dataEmissao : ""} name="" className='form-control' id="" />
                            {(dataEmissao === null || dataEmissao === '') && <span className={` form-label text-danger`}>*Por favor, informe a Data de aprovação </span>}
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className=''>
                        <Col>
                            <label className='form-label' htmlFor="">Descrição</label>
                            <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                        </Col>
                    </Row>
                    
                </form>
            </div>
        </Modal >
    )
}

export default ModalOrcamentosProjetos