import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {Badge, Button, Col, Dropdown, DropdownButton, Modal, Row, Spinner} from 'react-bootstrap';
// import {
//   clonarPrevenda,
//   clonarProduto,
//   getProducts,
//   imprimirPDFProduto,
//   imprimirPDFSeparacao,
//   visualizarPDFProduto,
//   visualizarPDFSeparacao,
// } from '../listagem_requests/listagem_requests';
import {useProduto} from '../../cadastros/produto/hooks/useProduto';
import img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg';
import {useFormik, useFormikContext} from 'formik';
import {base64Prefixes, smoothScrollToBottom} from '../../../shared/core';
import {Link} from 'react-router-dom';
import {PDFView} from '../../../shared';
import {
  clonarProduto,
  getProducts,
  imprimirPDFProduto,
  visualizarPDFProduto,
} from '../../listagem/listagem_requests/listagem_requests';
import { is } from 'date-fns/locale';

interface Produto {
  id: number;
  img_produto_base64: string;
  codigo_sku: number;
  descricao: string;
  custo_preco_venda: number;
  unidade_conversao: number;
  status: number;
  marca_id: string;
  marca_desc: string;
  estoqueWms: string;
  ncm: string;
  tipoEstoque: string;
  unidadeMedida: string;
}

export default function ListagemProdutosConsumo({isConsumo}) {
  const {setIdDoFormulario, navigate} = useProduto();
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [isEcommerce, setIsEcommerce] = useState<boolean>(false);
  // const [isConsumo, setIsConsumo] = useState<boolean>(true);
  const [isDisponivelFabrica, setIsDisponivelFabrica] = useState<boolean>(false);
  const [isDivulgacao, setIsDivulgacao] = useState<boolean>(false);
  const [isEstoqueFabricacao, setIsEstoqueFabricacao] = useState<boolean>(false);
  const [isMix, setIsMix] = useState<boolean>(false);
  const [isAguardandoComplemento, setIsAguardandoComplemento] = useState<boolean>(false);

  const initialValues = {
    page: '',
    size: '',
    idProduto: '',
    sku: '',
    descricao: '',
    marca: '',
    isCompra: '',
    isVenda: '',
    isRessuprimento: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);
      // console.log()
      try {
        const response = await getProducts({
          descricao: values.descricao,
          idProduto: values.idProduto,
          marca: values.marca,
          sku: values.sku,
          page: 0,
          size: '10',
          isVenda: values.isVenda,
          isCompra: values.isCompra,
          isRessuprimento: values.isRessuprimento,
          isDivulgacao: isDivulgacao ? 'ATIVO' : undefined,
          isEcommerce: isEcommerce ? 'ATIVO' : undefined,
          isEstoqueFabricacao: isEstoqueFabricacao ? 'ATIVO' : undefined,
          isFabricacaoDisponivel: isDisponivelFabrica ? 'ATIVO' : undefined,
          isMix: isMix ? 'ATIVO' : undefined,
          isAguardandoComplemento: isAguardandoComplemento ? 'ATIVO' : undefined,
          isConsumo: isConsumo ?'SIM': undefined,
        });

        const {content, totalPages} = response.data;

        setIsHasMore(isPage < totalPages - 1);

        setIsLoadingSearch(false);
        setSubmitting(false);
        setProdutos(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setSubmitting(false);
        setIsLoadingSearch(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(400);
  };

  const getProductsData = async (page: number = 0) => {
    setIsLoading(true);
    try {
      setLoading(true);
      const response = await getProducts({
        descricao: formik.values.descricao,
        marca: formik.values.marca,
        size: '10',
        idProduto: '',
        sku: '',
        page: page,
        isConsumo:  isConsumo? 'SIM': undefined,
      });

      const {content, totalPages} = response.data;

      setProdutos((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar produtos',
        text: 'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductsData(isPage);
    // formik.handleSubmit()
  }, [isPage, isConsumo]);

  const formatStatus = (statusProduto) => {
    switch (statusProduto) {
      case 1:
        return (
          <Badge bg='success' text='light'>
            ATIVO
          </Badge>
        );
      case 2:
        return (
          <Badge bg='danger' text='light'>
            INATIVO
          </Badge>
        );
      case 9:
        return (
          <Badge bg='warning' text='light'>
            SINCRONIZADO
          </Badge>
        );
      default:
        return (
          <Badge bg='light' text='light'>
            N/A
          </Badge>
        );
    }
  };

  const handleEditProduto = (id) => {
    setIdDoFormulario(id);
    navigate(isConsumo ? `/produto-consumo-edicao/${id}` : `/produto-edicao/${id}`);
  };

  const onClickNovoCadastro = () => {
    setIdDoFormulario(null);
    navigate(isConsumo ? '/produto-consumo-cadastro' : '/produto-cadastro');
  };

  useEffect(() => {
    setIsPage(0);
    clearFilter();
  },[isConsumo])



  const clearFilter = () => {
    formik.setValues({
      descricao: '',
      idProduto: '',
      marca: '',
      sku: '',
      page: '',
      size: '',
      isCompra: '',
      isRessuprimento: '',
      isVenda: '',
      
    });

    setIsEcommerce(false);
    setIsMix(false);
    setIsDivulgacao(false);
    setIsDisponivelFabrica(false);
    setIsEstoqueFabricacao(false);
    setIsAguardandoComplemento(false);

    getProductsData(0);
  };

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Produtos {isConsumo ? 'de Consumo' : ''}
        </h1>
        <button
          onClick={onClickNovoCadastro}
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Novo Produto
        </button>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <Row className='col-12 mb-4'>
            {!isConsumo && (
              <Col md='4' className='col-12 mt-2'>
              <label className='form-label'>Código do Produto SKU:</label>
              <input
                type='number'
                placeholder='Pesquise pelo código de SKU'
                {...formik.getFieldProps('sku')}
                className='form-control'
              />
            </Col>
            )}
            <Col md='4' className='col-12 mt-2'>
              <label className='form-label'>Descrição do Produto:</label>
              <input
                type='text'
                placeholder='Pesquise pela descrição do produto'
                {...formik.getFieldProps('descricao')}
                className='form-control'
              />
            </Col>
            {!isConsumo && (
              <Col md='4' className='col-12 mt-2'>
                <label className='form-label'>Marca do Produto:</label>
                <input
                  type='text'
                  placeholder='Pesquise pela marca do produto'
                  {...formik.getFieldProps('marca')}
                  className='form-control'
                />
              </Col>
            )}
          </Row>
          {!isConsumo && (
            <>
              <Row className='mt-4 col-12'>
                <Col>
                  <label className='form-label'>Compra:</label>
                  <select {...formik.getFieldProps('isCompra')} className={`form-control`}>
                    <option value=''>Selecione</option>
                    <option value='ATIVO'>ATIVO</option>
                    <option value='INATIVO'>INATIVO</option>
                    <option value='PENDENTE'>PENDENTE</option>
                  </select>
                </Col>
                <Col>
                  <label className='form-label'>Venda:</label>
                  <select {...formik.getFieldProps('isVenda')} className={`form-control`}>
                    <option value=''>Selecione</option>
                    <option value='ATIVO'>ATIVO</option>
                    <option value='INATIVO '>INATIVO</option>
                    <option value='PENDENTE'>PENDENTE</option>
                  </select>
                </Col>
                <Col>
                  <label className='form-label'>Ressuprimento:</label>
                  <select {...formik.getFieldProps('isRessuprimento')} className={`form-control`}>
                    <option value=''>Selecione</option>
                    <option value='ATIVO'>ATIVO</option>
                    <option value='INATIVO'>INATIVO</option>
                    <option value='PENDENTE'>PENDENTE</option>
                  </select>
                </Col>
              </Row>
              <Row className='col-12 mt-4'>
                <Col className='col-2'>
                  <label htmlFor='' className='form-label'>
                    E-COMMERCE
                  </label>
                  <div className='form-check form-switch mt-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault2'
                      checked={isEcommerce}
                      onChange={() => setIsEcommerce((prev) => !prev)}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                      <b>{isEcommerce === true ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </Col>
                <Col className='col-2'>
                  <label htmlFor='' className='form-label'>
                    MIX
                  </label>
                  <div className='form-check form-switch mt-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault2'
                      checked={isMix}
                      onChange={() => setIsMix((prev) => !prev)}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                      <b>{isMix === true ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </Col>
                <Col className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Marketing
                  </label>
                  <div className='form-check form-switch mt-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault2'
                      checked={isDivulgacao}
                      onChange={() => setIsDivulgacao((prev) => !prev)}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                      <b>{isDivulgacao === true ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </Col>
                <Col className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Disponível na Fabrica?
                  </label>
                  <div className='form-check form-switch mt-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault2'
                      checked={isDisponivelFabrica}
                      onChange={() => setIsDisponivelFabrica((prev) => !prev)}
                    />
                    <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                      <b>{isDisponivelFabrica === true ? 'Sim' : 'Não'}</b>
                    </label>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className='col-12 mt-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFilter()}
                className='btn btn-success form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-success form-control'
              >
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                {/* <th className='text-center'>Id</th> */}
                <th className='text-center'>Imagem</th>
                <th className='text-center'>Descrição</th>
                <th className='text-center'>{isConsumo ? 'NCM' : 'Cod. Fornecedor(SKU)'}</th>
                <th className='text-center'>{isConsumo ?"Unidade de Medida":"Marca"}</th>
                <th className='text-center'>{isConsumo ? 'Tipo de estoque' : 'Sumarização Estoque'}</th>
                {!isConsumo && <th className='text-center'>Preço Venda</th>}
                <th className='text-center'>Clonar</th>
                <th className='text-center'>Ação</th>
                {!isConsumo && <th className='text-center'>Ver PDF</th>
                }
              </tr>
            </thead>
            <tbody>
              {produtos.map((produto) => (
                <tr key={produto.id}>
                  {/* <td className='text-center'>{produto.id}</td> */}
                  <td className='text-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <img
                        src={
                          produto.img_produto_base64
                            ? `${'data:image/jpg;base64'},${produto.img_produto_base64}`
                            : img_not_found
                        }
                        title={produto.descricao}
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '4px',
                          boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.4)',
                        }}
                      />
                    </div>
                  </td>
                  <td className='text-center'>
                    <div>{produto.descricao ? produto.descricao : 'nada'}</div>
                    <div>{formatStatus(produto.status)}</div>
                  </td>
                  <td className='text-center'>
                    {isConsumo
                      ? produto.ncm
                        ? `${produto.ncm}`
                        : '...'
                      : produto.codigo_sku
                      ? `${produto.codigo_sku}`
                      : '...'}
                  </td>
                  <td className='text-center'>{isConsumo?produto.unidadeMedida ? produto.unidadeMedida : '...':produto.marca_id ? produto.marca_desc : '...'}</td>
                  {/* {!isConsumo && (
                    
                  )} */}
                  <td className='text-center '>{isConsumo? produto.tipoEstoque ? produto.tipoEstoque : '...': produto.estoqueWms ? produto.estoqueWms : '...'}</td>
                  {!isConsumo && <td className='text-center'>R$ {produto.custo_preco_venda}</td>}
                  <td className='p-0 d-flex justify-content-center border-0 my-auto '>
                    <td className=''>
                      <div className='dropdown  position-static mx-auto'>
                        <button
                          className='my-0 btn btn-info btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                          disabled={isConsumo}

                        >
                          Clonar
                        </button>
                        <ul
                          className='dropdown-menu'
                          style={{
                            position: 'absolute',
                            zIndex: 1050,
                            top: '100%',
                            left: '0',
                          }}
                        >
                          <li>
                            <button
                              style={{fontWeight: 600, color: '#5014d0'}}
                              disabled={isConsumo}
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    `Você tem certeza que deseja clonar
                                        o produto com id: ${produto.id} ` + '?',
                                  icon: 'info',
                                  showCancelButton: true,
                                  confirmButtonText: 'Sim',
                                  cancelButtonText: 'Não',
                                  reverseButtons: true,
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    clonarProduto(produto.id)
                                      .then((response) => {
                                        if (response.status === 200) {
                                          Swal.fire({
                                            icon: 'success',
                                            title: 'Produto clonado com sucesso!',
                                            confirmButtonText: 'Ok',
                                            reverseButtons: true,
                                          });
                                          getProductsData();
                                        } else {
                                          Swal.fire({
                                            icon: 'error',
                                            title: 'Erro ao clonar produto',
                                            text: `Ocorreu um erro ao tentar clonar Por favor, tente novamente mais tarde.`,
                                            confirmButtonText: 'Ok',
                                          });
                                        }
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Erro ao clonar produto',
                                          text: `Ocorreu um erro ao tentar clonar. Por favor, tente novamente mais tarde.`,
                                          confirmButtonText: 'Ok',
                                        });
                                      });
                                  }
                                });
                              }}
                            >
                              CÓPIA SIMPLES
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </td>
                  <td className='text-center'>
                    <button
                      onClick={() => handleEditProduto(produto.id)}
                      className='btn btn-success btn-sm bi bi-pencil'
                      data-toggle='tooltip'
                      data-placement='top'
                      title={'Editar'}
                    ></button>
                  </td>
                  {!isConsumo && (
                    <td>
                    <div className='dropdown text-center' style={{marginLeft: '7px'}}>
                      <button
                        className='btn btn-sm btn-danger dropdown-toggle'
                        data-bs-toggle='dropdown'
                      >
                        <i className='bi bi-file-earmark-arrow-down'>PDF</i>
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <button
                            className='dropdown-item'
                            style={{color: 'red'}}
                            type='button'
                            onClick={() =>
                              visualizarPDFProduto(
                                setModalShowPDFViewer,
                                produto.id,
                                setIsLoading,
                                setIsPdfUrl,
                                setIsIdSeparacao
                              )
                            }
                          >
                            VISUALIZAR PDF
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button variant='primary' onClick={() => imprimirPDFProduto(isIdSeparacao, setIsLoading)}>
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </div>
  );
}
