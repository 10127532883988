import {AxiosResponse} from 'axios';
import {AuthModel} from './_models';
import Swal from 'sweetalert2';
const SYSLED_VERSION = process.env.REACT_APP_SYSLED_VERSION || '1.0.0'; // Define um valor padrão caso a variável não exista
console.log('Versão do Sysled:', SYSLED_VERSION);

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.clear();
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

interface SysledHeaders {
  'x-sysled-version'?: string; // O cabeçalho pode não existir em todas as respostas
  [key: string]: any; // Permite outros cabeçalhos que possam existir
}

interface SysledResponse extends AxiosResponse {
  headers: SysledHeaders; // Substitui a tipagem padrão dos cabeçalhos pelo formato esperado
}
export function setupAxios(axios: any) {
  let frontendVersion = process.env.REACT_APP_SYSLED_VERSION || '1.0.0';

  console.log('aaaaaaa');

  console.log(frontendVersion);

  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth();
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      const backendVersion = response.headers.get('x-sysled-version');

      if (backendVersion && backendVersion !== frontendVersion && frontendVersion !== 'homologacao') {
        console.warn(
          `Incompatibilidade de versão: Atual (${frontendVersion}) | Mais recente: (${backendVersion})`
        );

        Swal.close();

        return Swal.fire({
          title: 'Versão desatualizada',
          text: 'A versão do sistema está desatualizada. Por favor, recarregue a página para atualizar',
          icon: 'warning',
          confirmButtonText: 'Recarregar',
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCloseButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // Recarrega a página
            window.location.reload();
          }
          // Retorna um erro se o usuário não confirmar a ação
          return Promise.reject('Usuário não confirmou a atualização.');
        });
      }

      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY};
