import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import {
  AsyncSelectContasReceberParcela,
  IContaCorrenteProps,
  IFormaDePagamento,
  IContasReceberPagamentos,
  IContasReceberPagamentosRequest,
  ISelectOption,
  smoothScrollToBottom,
  useModal,
} from '../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getContasReceberPagamentosRequest} from '../../../listagem/listagem_requests/listagem_requests';
import ModalContasReceberPagamentos from './components/modal/ModalContasReceberPagamentos';
import {Link, useParams} from 'react-router-dom';
import {DateRangePicker} from 'rsuite';
import axios from 'axios';
import {addDays, endOfMonth, format, startOfMonth, subDays} from 'date-fns';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {formatInTimeZone, toZonedTime} from 'date-fns-tz';
import RangePriceBetweenContasReceber from '../../components/RangePriceBetween';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const getContaCorrente = async (setContasCorrentes: any) => {
  try {
    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/listar?page=0&size=10000&status=ATIVO`;
    const {data, status} = await axios.get(url);

    setContasCorrentes(data.content);
  } catch (errors: any) {
    const {error} = errors;

    if (error.response) {
      const status = error.response.status;

      const {data} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao buscar conta corrente, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  }
};

export const getFormasPagamento = async (setFormasPagamento: any) => {
  try {
    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/buscaFormasPagamento?page=0&size=10000`;
    const {data, status} = await axios.get(url);

    setFormasPagamento(data);
  } catch (errors: any) {
    const {error} = errors;

    if (error.response) {
      const status = error.response.status;

      const {data} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao buscar forma de pagamento, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  }
};

const ListagemContasReceberPagamentos = () => {
  const {id, idParcela} = useParams();

  const contasReceberPagamentosColumns: Column<IContasReceberPagamentos>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Conta a Receber Parcelas',
      accessor: 'idContaReceberParcela',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Conta Corrente',
      accessor: 'contaCorrenteNumero',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Valor pago',
      accessor: 'valorPago',
      Cell: ({value}) => (
        <div className='text-start'>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value)}
        </div>
      ),
    },
    {
      Header: 'Data do pagamento',
      accessor: 'dataPagamento',
      Cell: ({value}) => {
        if (value) {
          try {
            const formattedDate = format(new Date(value), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Forma de pagamento',
      accessor: 'formaPagamento',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => (
        <div className='text-start'>
          {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
        </div>
      ),
    },
    /* {
      Header: 'Criado Por',
      accessor: 'id',
    },
    {
      Header: 'Criado em',
      accessor: 'id',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = String(value).split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Editado Por',
      accessor: 'id',
      Cell: ({value}) => <span>{value ? value : '...'}</span>,
    },
    {
      Header: 'Editado em',
      accessor: 'id',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = String(value).split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    }, */
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const [contasReceberPagamentosData, setContasReceberPagamentosData] = useState<
    IContasReceberPagamentos[]
  >([]);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [contasReceberPagamentosSelected, setContasReceberPagamentosSelected] =
    useState<IContasReceberPagamentos | null>(null);
  const [isSelectAsyncContaReceberParcela, setIsSelectAsyncContaReceberParcela] =
    useState<ISelectOption | null>(null);
  const [contasCorrentes, setContasCorrentes] = useState<IContaCorrenteProps[] | null>(null);
  const [formasPagamento, setFormasPagamento] = useState<IFormaDePagamento[] | null>(null);

  const [valueDateRangePagamento, setValueDateRangePagamento] = useState<DateRange | null>(null);

  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);

  const data = useMemo(() => contasReceberPagamentosData, [contasReceberPagamentosData]);
  const columns = useMemo(() => contasReceberPagamentosColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: IContasReceberPagamentosRequest = {
    dataPagamentoFim: '',
    dataPagamentoInicio: '',
    idFormaPagamento: '',
    valoPagorFim: '',
    valorPagoInicio: '',
    descricao: '',
    idContaCorrente: '',
    idContaReceberParcela: isSelectAsyncContaReceberParcela
      ? String(isSelectAsyncContaReceberParcela.value)
      : id,
    status: 'ATIVO',
  };

  function convertToLocalTimeZone(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd');
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      try {
        let dataPagamentoInicio = '';
        let dataPagamentoFim = '';
        if (valueDateRangePagamento && valueDateRangePagamento.length > 0) {
          dataPagamentoInicio = convertToLocalTimeZone(valueDateRangePagamento[0]);
          dataPagamentoFim = convertToLocalTimeZone(valueDateRangePagamento[1]);
        }
        const response = await getContasReceberPagamentosRequest(
          {
            dataPagamentoFim,
            dataPagamentoInicio,
            idFormaPagamento: values.idFormaPagamento,
            valoPagorFim: maxPrice ? maxPrice : '',
            valorPagoInicio: minPrice ? minPrice : '',
            descricao: values.descricao,
            idContaCorrente: values.idContaCorrente,
            idContaReceberParcela: isSelectAsyncContaReceberParcela
              ? String(isSelectAsyncContaReceberParcela.value)
              : id,
            status: values.status,
          },
          0
        );

        const {content, totalPages} = response.data;

        setIsHasMore(isPage < totalPages - 1);

        setIsLoading(false);
        setSubmitting(false);

        setContasReceberPagamentosData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (data: IContasReceberPagamentos) => {
    openModal(true, data);
  };

  //Get de categorias por modelo
  const getContasReceberPagamentos = async ({page = 0}: {page: number}) => {
    setIsLoading(true);

    try {
      const response = await getContasReceberPagamentosRequest(initialValues, page);

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      setContasReceberPagamentosData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        const {data} = errors.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao buscar pagamentos, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  const [
    isContasReceberPagamentosModal,
    openContasReceberPagamentosModal,
    closeContasReceberPagamentosModal,
  ] = useModal();

  const openModal = (isEdit: boolean, data?: IContasReceberPagamentos) => {
    if (data) {
      setContasReceberPagamentosSelected(data);
    }
    setIsEdit(isEdit);
    openContasReceberPagamentosModal();
  };
  const clearFiltros = () => {
    formik.resetForm();
    setMaxPrice(null);
    setMinPrice(null);
    setIsPage(0);
    setIsHasMore(false);
    setValueDateRangePagamento(null);
    getContasReceberPagamentos({page: 0});
  };

  useEffect(() => {
    getContasReceberPagamentos({page: isPage});
  }, [isPage]);

  useEffect(() => {
    getContaCorrente(setContasCorrentes);
    getFormasPagamento(setFormasPagamento);
  }, []);

  useEffect(() => {
    if (id) {
      setIsSelectAsyncContaReceberParcela({
        value: Number(id),
        label: '',
      });
    }
  }, [id]);

  const handleSelectChange = (event, fieldName) => {
    formik.setFieldValue(fieldName, event.target.value);
  };

  const handleDateRangeChangeEmissao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRangePagamento(null);
    } else {
      setValueDateRangePagamento([value[0], value[1]]);
    }
  };

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Contas a Receber Pagamentos
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Conta a Receber Pagamentos
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <div className='mt-10'>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Conta a Receber Parcela:</label>
              <AsyncSelectContasReceberParcela
                handleOptions={(option) => setIsSelectAsyncContaReceberParcela(option!)}
                inputOption={isSelectAsyncContaReceberParcela}
                isDisabled
                onKeyDown={(e: any) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncContaReceberParcela(null);
                  }
                }}
              />
            </Col>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Conta Corrente:</label>
              <select
                className='form-select'
                aria-label='Select example'
                onChange={(event) => handleSelectChange(event, 'idContaCorrente')}
                value={formik.values.idContaCorrente}
              >
                <option value=''>Selecione a conta corrente</option>
                {contasCorrentes &&
                  contasCorrentes.map((conta) => (
                    <option value={conta.id} key={conta.id}>
                      {conta.descricao}
                    </option>
                  ))}
              </select>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Data de pagamento:</label>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChangeEmissao}
                value={valueDateRangePagamento}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='Data de pagamento'
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Forma de pagamento:</label>
              <select
                className='form-select'
                aria-label='Select example'
                onChange={(event) => handleSelectChange(event, 'idFormaPagamento')}
                value={formik.values.idFormaPagamento}
              >
                <option value=''>Selecione a forma de pagamento</option>
                {formasPagamento &&
                  formasPagamento.map((formas) => (
                    <option value={formas.id} key={formas.id}>
                      {formas.formaPagamento}
                    </option>
                  ))}
              </select>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Valor Pago:</label>
              <RangePriceBetweenContasReceber
                setMaxPrice={setMaxPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                minPrice={minPrice}
              />
            </Col>
            <Col sm='6' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição'
                {...formik.getFieldProps('descricao')}
                className={`form-control`}
              />
            </Col>
          </RowBootstrap>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link
                className='btn mx-5 px-10 btn-info '
                to={`/contas-a-receber/parcelas/${idParcela}`}
              >
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* TABELA */}
      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IContasReceberPagamentos>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IContasReceberPagamentos>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={() => handleOnEdit(row.original)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
      <ModalContasReceberPagamentos
        isOpen={isContasReceberPagamentosModal}
        onHide={closeContasReceberPagamentosModal}
        isEdit={isEdit}
        data={contasReceberPagamentosSelected}
        loader={getContasReceberPagamentos}
      ></ModalContasReceberPagamentos>
    </div>
  );
};

export default ListagemContasReceberPagamentos;
