import { Col, Row, Spinner } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { AsyncSelectEmpresa, AsyncSelectFornecedor, EstoqueEntradas, EstoqueLocalizacao, formatarData, smoothScrollToBottom, useModal } from "../../../../shared";
import Skeleton from "react-loading-skeleton";
import ModalEstoqueEntradas from "./components/ModalEstoqueEntradas";
import { Link } from "react-router-dom";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueLocalizacao = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [page, setPage] = useState(0)

    const [entradas, setEntradas] = useState<EstoqueEntradas[]>([])
    const [dataLine, setDataLine] = useState<EstoqueLocalizacao[]>([])

    const [selectedTipoEntidade, setSelectedTipoEntidade] = useState<ClienteOption | null>(null)
    const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null)
    const [selectedFornecedor, setSelectedFornecedor] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [dataEntrada, setDataEntrada] = useState<string | null>(null)
    const [notaFiscal, setNotaFiscal] = useState<number | null>(null)
    const [numeroDocumento, setNumeroDocumento] = useState<number | null>(null)

    const [isEstoqueLocalizacaoModal, openEstoqueLocalizacaoModal, closeEstoqueLocalizacaoModal] = useModal();


    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }

    const loadOptionsTipoEntidade = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/entidade-documentos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: null
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const clearFiltros = () => {
        setSelectedTipoEntidade(null)
        setSelectedFornecedor(null)
        setSelectedEmpresa(null)
        setNotaFiscal(null)
        setNumeroDocumento(null)
        setDataEntrada(null)
        setStatus('ATIVO')
        setPage(0)
    }
    const fetchEstoqueEntradas = async (page = 0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas/listar`,
                {
                    params: {
                        status: status,
                        'idEmpresa': selectedEmpresa?.value ? selectedEmpresa?.value : null,
                        'idFornecedor': selectedFornecedor?.value ? selectedFornecedor?.value : null,
                        'numeroDocumento': numeroDocumento ? numeroDocumento : null,
                        'chaveNotaFiscal': notaFiscal ? notaFiscal : null,
                        'idTipoEntidadeDocumento': selectedTipoEntidade?.value ? selectedTipoEntidade?.value : null,
                        'dataEntrada': dataEntrada ? dataEntrada : null,
                        page: page
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                    setEntradas([]);
                }
                setEntradas((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Entradas para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);

        }
    };
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueLocalizacaoModal();
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchEstoqueEntradas(0);
    };
    useEffect(() => {
        fetchEstoqueEntradas(page)
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Entradas
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => { openModal(false) }}
                >
                    Cadastrar Entrada
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">

                    <Row>
                        <Col>
                            <label htmlFor="" className="form-label">Empresa:</label>
                            <AsyncSelectEmpresa onKeyDown={()=>{setSelectedEmpresa(null)}} inputOption={selectedEmpresa} handleOptions={(e)=>{setSelectedEmpresa(e)}} ></AsyncSelectEmpresa>
                        </Col>
                    </Row>
                    <Row className='my-4'>
                        <Col>
                            <label htmlFor="" className="form-label"  >Fornecedor:</label>
                            <AsyncSelectFornecedor onKeyDown={()=>{setSelectedFornecedor(null)}} inputOption={selectedFornecedor} handleOptions={(e)=>{setSelectedFornecedor(e)}}></AsyncSelectFornecedor>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        {/* Tipo entidade */}
                        <Col>
                            <label htmlFor="" className="form-label">Tipo Entidade Documento :</label>
                            <AsyncSelect
                                defaultOptions
                                value={selectedTipoEntidade}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEntidade(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEntidade(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoEntidade}
                                placeholder="Selecione o Tipo da Entidade" className="mt-1"></AsyncSelect>

                        </Col>
                        {/* Status */}
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                        {/* Numero Documento */}
                        <Col>
                            <label htmlFor="" className="form-label"  >Numero do documento:</label>
                            <input type="number"
                                value={numeroDocumento ? numeroDocumento : ""}
                                className="form-control"
                                onChange={(e) => {
                                    setNumeroDocumento(Number(e.target.value))
                                }} placeholder="Digite o numero do documento" />
                        </Col>
                    </Row>
                    <Row className="my-5  mb-4 row">
                        {/* Chave Nota */}
                        <Col>
                            <label htmlFor="" className="form-label">Chave nota fiscal:</label>
                            <input type="number" value={notaFiscal ? notaFiscal : ""}
                                onChange={(e) => {
                                    setNotaFiscal(Number(e.target.value))
                                }} className="form-control" placeholder="Digite a chave da nota fiscal" />
                        </Col>
                        {/* Data Entrada */}
                        <Col>
                            <label htmlFor="" className="form-label">Data Entrada:</label>
                            <input type="date" value={dataEntrada ? dataEntrada : ""}
                                onChange={(e) => {
                                    setDataEntrada(e.target.value)}} className="form-control"></input>
                        </Col>
                    </Row>
                    <div className='col-12 my-10' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Empresa</th>
                                    <th className='text-center'>Fornecedor</th>
                                    <th className='text-center'>Tipo de Entidade Documento</th>
                                    <th className='text-center'>Numero do Documento</th>
                                    <th className='text-center'>Chave Nota Fiscal</th>
                                    <th className='text-center'>Data Entrada</th>
                                    <th className='text-center'>Criado Por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado Por</th>
                                    <th className='text-center'>Data de Alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Ações</th>

                                </tr>
                            </thead>
                            <tbody>
                                {entradas?.map((log) => (
                                    <tr className="text-center">
                                        <td>{log.id}</td>
                                        <td>{log.empresa}</td>
                                        <td>{log.fornecedor}</td>
                                        <td>{log.tipoEntidadeDocumento}</td>
                                        <td>{log.numeroDocumento}</td>
                                        <td>{log.chaveNotaFiscal}</td>
                                        <td>{formatarData(log.dataEntrada)}</td>
                                        <td>{log.usuarioCriacaoNome}</td>
                                        <td>{log.dataCriacao}</td>
                                        <td>{log.usuarioAlteracaoNome?log.usuarioAlteracaoNome:"..."}</td>
                                        <td>{log.dataAlteracao}</td>
                                        <td>{log.status}</td>
                                        <td>
                                            <div className='dropdown position-static'>
                                                <button
                                                    className='btn btn-success btn-sm dropdown-toggle'
                                                    data-bs-toggle='dropdown'
                                                >
                                                    <i className=''>Opções</i>
                                                </button>
                                                <ul className='dropdown-menu'
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 1050,
                                                        top: '100%',
                                                        left: '0',
                                                    }}>
                                                    <li>
                                                        <button
                                                            className='dropdown-item'
                                                            type='button'
                                                            onClick={() => { openModal(true, log.id,log.idEmpresa,log.empresa,log.idFornecedor,log.fornecedor,log.idTipoEntidadeDocumento,log.tipoEntidadeDocumento,log.numeroDocumento,log.chaveNotaFiscal,log.dataEntrada,log.status) }}

                                                        >
                                                            <i className='bi-pencil'> </i>
                                                            <span className="mx-2">
                                                                EDITAR

                                                            </span>

                                                        </button>
                                                    </li>

                                                    <li>
                                                        <hr className='dropdown-divider' />
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={`/consultas/estoques/listagem-estoque-entradas-produtos/${log.id}`}
                                                            className='dropdown-item'
                                                            type='Button'
                                                        // onClick={() => handleGerarPedidoSwal(prevenda.id)}
                                                        >
                                                            <i className='bi-box-arrow-up-right'></i>
                                                            <span className="mx-2">
                                                                GERENCIAR PRODUTOS

                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>)}

            <ModalEstoqueEntradas
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchEstoqueEntradas}
                isEdit={isEdit}
                isOpen={isEstoqueLocalizacaoModal}
                onHide={closeEstoqueLocalizacaoModal}
            ></ModalEstoqueEntradas>
        </div>

    )
}

export default ListagemEstoqueLocalizacao