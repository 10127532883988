import {Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  AsyncSelectConstrutora,
  CrudHeader,
  IClientesEmpreendimentos,
  ISelectOption,
  smoothScrollToBottom,
  TableListagem,
  useModal,
} from '../../../shared';
import {AsyncSelectCliente} from '../../../shared';
import ButtonEditListagem from '../segmento-empreendimento/components/ButtonEditListagem';
import AsyncSelect from 'react-select/async';
import {useParams} from 'react-router-dom';
import ModalClientesEmpreendimento from './components/ModalClientesEmpreendimento';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ClientesEmpreendimento = () => {
  const {id} = useParams();
  const [clientesEmpreendimento, setClientesEmpreendimento] = useState<IClientesEmpreendimentos[]>(
    []
  );
  const [dataLine, setDataLine] = useState<IClientesEmpreendimentos[]>([]);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [page, setPage] = useState(0);
  const [empreendimentoTitle, setEmpreendimentoTitle] = useState<string | null>(null);
  const [selectedCliente, setSelectedCliente] = useState<ISelectOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const colums = [
    'Id',
    'Empreendimento',
    'Construtora',
    'Criado por',
    'Data de criação',
    'Alterado por',
    'Data de alteração',
    'Status',
    'Ações',
  ];

  const [
    isClientesEmpreendimentoModal,
    openClientesEmpreendimentoModal,
    closeClientesEmpreendimentoModal,
  ] = useModal();

  const fetchClientesEmpreendimento = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/clientes/listar`,
        {
          params: {
            idEmpreendimento: id,
            status: status,
            idCliente: selectedCliente?.value,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
        }
        setClientesEmpreendimento((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Construtoras do empreendimento para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };
  const getEmpreendimentoById = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/${id}`
      );

      if (response.status === 200) {
        setEmpreendimentoTitle(response.data.titulo);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    }
  };
  const handleSelectClient = (selectOption: ISelectOption) => {
    setSelectedCliente(selectOption);
  };
  const clearFiltros = () => {
    setStatus('ATIVO');
    setSelectedCliente(null);
    setPage(0);
    setIsClear(true);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchClientesEmpreendimento(0);
  };
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };
  const openModal = (isEdit = false, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openClientesEmpreendimentoModal();
  };

  useEffect(() => {
    fetchClientesEmpreendimento(page);
    getEmpreendimentoById();
  }, [page]);

  useEffect(() => {
    if (isClear) {
      fetchClientesEmpreendimento(0);
    }
    setIsClear(false);
  }, [isClear]);

  return (
    <div>
      <CrudHeader
        title='Construtoras do Empreendimento'
        to={'/empreendimentos'}
        titleCadastro='Construtora'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        isLoading={isLoading}
        openModal={openModal}
      >
        <Row className='row'>
          <Col>
            <label htmlFor='' className='form-label'>
              Empreendimento:
            </label>
            <AsyncSelect isDisabled value={{value: 1, label: empreendimentoTitle}}></AsyncSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor='' className='form-label'>
              Construtora:
            </label>
            <AsyncSelectConstrutora
              handleOptions={(option) => handleSelectClient(option!)}
              inputOption={selectedCliente}
              isSelectDOM={false}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedCliente(null);
                }
              }}
            />
          </Col>

          <Col>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </CrudHeader>

      <TableListagem isLoading={isLoading} coluns={colums} hasMore={hasMore} loadMore={loadMore}>
        {clientesEmpreendimento?.map((cliente) => (
          <tr className='text-center'>
            <td>{cliente.id}</td>
            <td>{cliente.empreendimento}</td>
            <td>{cliente.cliente}</td>
            <td>{cliente.usuarioCriacaoNome}</td>
            <td>{cliente.dataCriacao}</td>
            <td>{cliente.usuarioAlteracaoNome ? cliente.usuarioAlteracaoNome : '...'}</td>
            <td>{cliente.dataAlteracao ? cliente.dataAlteracao : '...'}</td>
            <td>{cliente.status}</td>
            <td>
              <ButtonEditListagem
                handleEdit={() => {
                  openModal(true, cliente.id);
                }}
              ></ButtonEditListagem>
            </td>
          </tr>
        ))}
      </TableListagem>

      <ModalClientesEmpreendimento
        dataLine={dataLine}
        reload={fetchClientesEmpreendimento}
        isEdit={isEdit}
        isOpen={isClientesEmpreendimentoModal}
        onHide={closeClientesEmpreendimentoModal}
      ></ModalClientesEmpreendimento>
    </div>
  );
};

export default ClientesEmpreendimento;
