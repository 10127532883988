import {Col, Row, Spinner} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {
  EstoqueTransferencias,
  formatarData,
  smoothScrollToBottom,
  TipoLocalizacao,
  useModal,
} from '../../../../shared';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import ModalEstoqueTransferencia from './components/ModalEstoqueTransferencia';
import {Link} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueTransferencias = () => {
  const [transferencias, setTransferencias] = useState<EstoqueTransferencias[]>([]);
  const [dataLine, setDataLine] = useState<TipoLocalizacao[]>([]);

  const [selectedEstoque, setSelectedEstoque] = useState<ClienteOption | null>(null);
  const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [dataMovimento, setDataMovimento] = useState<string | null>(null);
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [
    isEstoqueTransferenciaModal,
    openEstoqueTransferenciaModal,
    closeEstoqueTransferenciaModal,
  ] = useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };

  const fetchTransferencias = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias/listar`,
        {
          params: {
            status: status,
            idEstoque: selectedEstoque ? selectedEstoque.value : null,
            idMotivo: selectedMotivo ? selectedMotivo.value : null,
            dataMovimento: dataMovimento ? `${dataMovimento}T14:55:35.403` : null,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
          // setLocalizacoes([]);
        }

        // const { content, totalPages } = response.data;
        // console.log(totalPages)
        // setLocalizacoes(response.data.content)
        setTransferencias((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        // setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de transferências para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };

  const loadOptionsMotivos = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-motivos/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsEstoque = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const clearFiltros = () => {
    setSelectedEstoque(null);
    setSelectedMotivo(null);
    setDataMovimento(null);
    setStatus('ATIVO');
    setPage(0);
    // fetchTransferencias(0)
  };

  useEffect(() => {
    fetchTransferencias(page);
  }, [page]);

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchTransferencias(0);
  };

  const openModal = (isEdit, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openEstoqueTransferenciaModal();
  };

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Transferências
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => {
                        setIsEdit(false)
                        openModal(false)
                    }}
                >
                    Cadastrar Transferência
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">
                    <Row className="mt-4 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Escolha o Motivo:</label>
                            <AsyncSelect
                                placeholder='Pesquise o Motivo'
                                // loadOptions={loadOptionsParceiro}
                                value={selectedMotivo}
                                defaultOptions
                                onChange={(selectedOption: ClienteOption | null) => {
                                    setSelectedMotivo(selectedOption);
                                    //   setIdParceiro(selectedOption ? selectedOption.value.toString() : '');
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedMotivo(null);
                                        // setIdParceiro('');
                                    }
                                }} loadOptions={loadOptionsMotivos} ></AsyncSelect>
                        </Col>

                        <Col>
                            <label htmlFor="" className="form-label">Estoque:</label>
                            <AsyncSelect placeholder='Pesquise o Estoque'
                                defaultOptions
                                // loadOptions={loadOptionsParceiro}
                                value={selectedEstoque}
                                onChange={(selectedOption: ClienteOption | null) => {
                                    setSelectedEstoque(selectedOption);
                                    //   setIdParceiro(selectedOption ? selectedOption.value.toString() : '');
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedEstoque(null);
                                        // setIdParceiro('');
                                    }
                                }}
                                loadOptions={loadOptionsEstoque} ></AsyncSelect>
                        </Col>
                    </Row>
                    <Row className="mt-9 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label">Data Movimento:</label>
                            <input type="date" value={dataMovimento ? dataMovimento : ""}
                                onChange={(e) => {
                                    setDataMovimento(e.target.value)
                                }} className="form-control"></input>
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <div className='col-12 my-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>)}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Id </th>
                                    <th className='text-center'>Motivo</th>
                                    <th className='text-center'>Estoque</th>
                                    <th className='text-center'>Data do Movimento</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transferencias?.map((transferencia) => (
                                    <tr className="text-center">
                                        <td>{transferencia.id}</td>
                                        <td>{transferencia.motivo}</td>
                                        <td>{transferencia.estoque}</td>
                                        <td>{formatarData(transferencia.dataMovimento)}</td>
                                        <td>{transferencia.usuarioCriacaoNome}</td>
                                        <td>{transferencia.dataCriacao}</td>
                                        <td>{transferencia.usuarioAlteracaoNome ? transferencia.usuarioAlteracaoNome : "..."}</td>
                                        <td>{transferencia.dataAlteracao}</td>
                                        <td>{transferencia.status}</td>
                                        <td>
                                            <div className='dropdown position-static'>
                                                <button
                                                    className='btn btn-success btn-sm dropdown-toggle'
                                                    data-bs-toggle='dropdown'
                                                >
                                                    <i className=''>Opções</i>
                                                </button>
                                                <ul className='dropdown-menu'
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 1050,
                                                        top: '100%',
                                                        left: '0',
                                                    }}>
                                                    <li>
                                                        <button
                                                            className='dropdown-item'
                                                            type='button'
                        onClick={() => { openModal(true, transferencia.id, transferencia.idMotivo, transferencia.motivo, transferencia.idEstoque, transferencia.estoque, transferencia.dataMovimento, transferencia.status) }}

                                                        >
                                                            <i className='bi-pencil'> </i>
                                                            <span className="mx-2">
                                                                EDITAR

                                                            </span>

                                                        </button>
                                                    </li>

                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                          <li>
                            <Link
                              to={`/consultas/estoques/listagem-estoque-transferencias-produtos/${transferencia.id}`}
                              className='dropdown-item'
                              type='Button'
                            >
                              <i className='bi-box-arrow-up-right'></i>
                              <span className='mx-2'>GERENCIAR PRODUTOS</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalEstoqueTransferencia
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchTransferencias}
        isEdit={isEdit}
        isOpen={isEstoqueTransferenciaModal}
        onHide={closeEstoqueTransferenciaModal}
      ></ModalEstoqueTransferencia>
    </div>
  );
};

export default ListagemEstoqueTransferencias;
