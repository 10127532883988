import {useTable, Column, Row, ColumnInstance} from 'react-table';
import {ISeparacoes} from '../../../../../shared/domain-types/models/expedicao-sysled';
import {useEffect} from 'react';
import {CustomHeaderColumn} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';

type TableProps = {
  data: ISeparacoes[];
  columns: Column<ISeparacoes>[];
};

const TableComponentExpedicaoEntrega: React.FC<TableProps> = ({data, columns}) => {
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  return (
    <div className='table-responsive' style={{ maxHeight: '600px' }}>
      <table
        style={{ height: '10px' }}
        id='kt_table_formularios'
        className='table table-hover table-striped table-rounded table-row-bordered border px-4'
        {...getTableProps()}
      >
        <thead className='thead-dark' style={{borderRadius: '5px', padding: '5px'}}>
          <tr
            className='text-muted fw-bolder fs-7 text-uppercase gs-0'
            style={{backgroundColor: '#3e97ff', color: '#fff', padding: '5px', borderRadius: '5px'}}
          >
            {headers.map((column: ColumnInstance<ISeparacoes>, index: number) => (
              <th
                style={{color: '#fff', padding: '10px'}}
                key={column.id}
                className={'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<ISeparacoes>, i) => {
              prepareRow(row);
              return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  SEM ITENS REFERENTE A SEPARAÇÃO
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponentExpedicaoEntrega;
