import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {ITiposDespesas, smoothScrollToBottom, useModal} from '../../../shared';
import {Link, useParams} from 'react-router-dom';
import ModalTiposDespesas from './components/ModalTiposDespesas';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemTiposDespesas = () => {

  const { id } = useParams();

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [tiposDespesas, setTiposDespesas] = useState<ITiposDespesas[]>([]);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);

  const [isTiposDespesasModal, openTiposDespesasModal, closeTiposDespesasModal] = useModal();

  const fetchTiposDespesas = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/listar`,
        {
          params: {
            idGrupoDespesas: id,
            status: status,
            page: page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setTiposDespesas((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Tipos de Despesas para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(0);
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/listar`,
        {
          params: {
            idGrupoDespesas: id,
            status: status,
            page: page,
            titulo: titulo ? titulo : null,
            descricao: descricao ? descricao : null,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setTiposDespesas((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Tipos de Despesas para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openTiposDespesasModal();
  };
  const clearFiltros = () => {
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    fetchTiposDespesas(page);
  };
  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  // useEffect(() => {
  //   if (descricao === null && titulo === null && status === 'ATIVO' && page === 0) {
  //     setPage(0);
  //     fetchTiposDespesas(0);
  //   }
  // }, [titulo, descricao, status, page]);

  useEffect(() => {
    fetchTiposDespesas(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Tipos de Despesas
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Tipo de Despesas
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Id Grupo:
              </label>
              <input value={Number(id)} disabled className="form-control" type="number" placeholder="Id do Grupo" />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Titulo:
              </label>
              <input
                type='text'
                value={titulo ? titulo : ''}
                className='form-control'
                onChange={(e) => {
                  setTitulo(e.target.value);
                  setTitulo
                    ? setTitulo(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite o titulo'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  setStatus
                    ? setStatus(e.target.value)
                    : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  setDescricao
                    ? setDescricao(e.target.value)
                    : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link className="btn mx-5 px-10 btn-info " to="/listagem-grupo-despesas">
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Grupo Despesas</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Editar</th>
                </tr>
              </thead>
              <tbody>
                {tiposDespesas?.map((despesa) => (
                  <tr className='text-center'>
                    <td>{despesa.id}</td>
                    <td>{despesa.titulo}</td>
                    <td>{despesa.idGrupoDespesas || '...'}</td>
                    <td>{despesa.descricao ? despesa.descricao : '...'}</td>
                    <td>{despesa.usuarioCriacaoNome}</td>
                    <td>{despesa.dataCriacao}</td>
                    <td>{despesa.usuarioAlteracaoNome ? despesa.usuarioAlteracaoNome : '...'}</td>
                    <td>{despesa.dataAlteracao}</td>
                    <td>{despesa.status}</td>
                    <td>
                      <div className='text-center'>
                        <button
                          className='btn btn-success btn-sm bi bi-pencil'
                          type='button'
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Editar'
                          onClick={() => {
                            openModal(
                              true,
                              despesa.id,
                              despesa.titulo,
                              despesa.descricao,
                              despesa.status
                            );
                          }}
                        >
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalTiposDespesas
        isOpen={isTiposDespesasModal}
        onHide={closeTiposDespesasModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadTiposDespesas={fetchTiposDespesas}
      />
    </div>
  );
};

export default ListagemTiposDespesas;
