import { useEffect, useState, useMemo } from 'react';

const FormArquivoUpload = ({ setIsOk, isOk, setArquivo, disabled = false, isPdf = false, setExtensao = (e) => {} }) => {
  const [arquivoSalvo, setArquivoSalvo] = useState<string>();
  const [pdfFiles, setPdfFiles] = useState<File[]>([]);

  const handleFileChange = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const base64String = reader.result.split(',')[1];
        setArquivo(base64String);
      } else {
        console.error('Erro: reader.result não é uma string.');
      }
    };

    reader.onerror = (error) => {
      console.error('Erro ao converter o arquivo:', error);
    };
  };

  const SalvarArquivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const arquivo = event.target.files?.[0];
      if (!arquivo) return;

      setExtensao(arquivo.name.split('.').pop());
      console.log(arquivo.name.split('.').pop());

      if (isPdf) {
        if (arquivo.type === 'application/pdf') {
          setPdfFiles([arquivo]);
          setArquivoSalvo(arquivo.name);
          setArquivo(arquivo);
          setIsOk(true);
          handleFileChange(arquivo);
        } else {
          setIsOk(false);
          setPdfFiles([]);
          setArquivoSalvo('Tipo de arquivo não suportado (somente PDF)');
        }
      } else {
        setArquivoSalvo(arquivo.name);
        setArquivo(arquivo);
        setIsOk(true);
        handleFileChange(arquivo);
      }
    } catch (error) {
      setArquivo('');
      setIsOk(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isOk) {
      setArquivo('');
    }
  }, []);

  useEffect(() => {
    if (!isOk) {
      setArquivoSalvo('');
    }
  }, [isOk]);

  const pdfUrls = useMemo(() => {
    return pdfFiles.map((file) => ({ file, url: URL.createObjectURL(file) }));
  }, [pdfFiles]);

  useEffect(() => {
    return () => {
      pdfUrls.forEach(({ url }) => URL.revokeObjectURL(url));
    };
  }, [pdfUrls]);

  return (
    <>
      <div>
        {pdfUrls.map(({ file, url }, index) => (
          <iframe
            className={isOk ? '' : 'd-none'}
            key={index}
            src={url}
            width='100%'
            height='400px'
            style={{ border: '1px solid black', marginBottom: '10px' }}
            title={`PDF-${index}`}
          ></iframe>
        ))}
      </div>
      <div className='position-relative mx-auto'>
        <input
          disabled={disabled}
          type='file'
          name='inputFile'
          accept={isPdf ? 'application/pdf' : ''}
          onChange={SalvarArquivo}
          id='inputFile'
          className='w-100 form-control'
        />
        <label htmlFor='inputFile' className='d-flex justify-content-between'>
          <label
            style={{ width: '80%', cursor: disabled ? 'not-allowed' : 'pointer' }}
            htmlFor='inputFile'
            className={`d-flex border-end-0 rounded-end-0 align-items-center ${
              arquivoSalvo ? (isOk ? 'text-success' : 'text-danger') : ''
            } form-control rounded-end-0`}
          >
            {arquivoSalvo ? (
              <>
                <i className={`bi bi-file-earmark-pdf-fill me-1 text-danger ${isOk ? 'd-none' : ''}`}></i>
                <span className='text-nowrap h-100 w-auto overflow-hidden'>{arquivoSalvo}</span>
                <i className={`bi bi-check fs-1 text-success ${isOk ? '' : 'd-none'}`}></i>
              </>
            ) : (
              <span className='text-nowrap overflow-hidden'>Anexe um arquivo</span>
            )}{' '}
          </label>
          <label
            htmlFor='inputFile'
            className='d-flex border-1 border h-100 border-secondary rounded-end-3 align-items-center justify-content-center text-center w-25 h-100 fs-2'
          >
            <i className='ki-solid ki-search-list fs-2 mx-md-3 mx-1 my-4 h-100'></i>
            {isPdf && <span className='text-black-50 d-md-flex d-none fs-md-2'>Upload</span>}
          </label>
        </label>
      </div>
    </>
  );
};

export default FormArquivoUpload;