import { Col, Row, Spinner } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { EstoqueLocalizacao, EstoqueMovimento, formatarData, smoothScrollToBottom, useModal } from "../../../../shared";
import Skeleton from "react-loading-skeleton";
import ModalEstoqueLocalizacao from "../estoque-localizacao/components/ModalEstoqueLocalizao";
import { DatePicker, DateRangePicker } from "rsuite";
import { addDays, endOfMonth, startOfMonth, subDays } from "date-fns";
import ModalEstoqueMovimwento from "./components/ModalEstoqueMovimento";
import ModalEstoqueMovimento from "./components/ModalEstoqueMovimento";
import da from "date-fns/esm/locale/da/index.js";
import { Link } from "react-router-dom";
// import ModalEstoqueLocalizacao from "./components/ModalEstoqueLocalizaao";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemEstoqueMovimento = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [page, setPage] = useState(0)

    const [localizacao, setLocalizacao] = useState<EstoqueMovimento[]>([])
    const [dataLine, setDataLine] = useState<EstoqueLocalizacao[]>([])


    const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null)
    const [selectedTipoMovimento, setSelectedTipoMovimento] = useState<ClienteOption | null>(null)
    const [selectedDocumento, setSelectedDocumento] = useState<ClienteOption | null>(null)
    const [selectedTipoLocalizacao, setSelectedTipoLocalizacao] = useState<ClienteOption | null>(null)
    const [dataMovimento, setDataMovimento] = useState<string | null>(null);
    
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [codigo, setCodigo] = useState<string | null>(null)

    const [isEstoqueLocalizacaoModal, openEstoqueLocalizacaoModal, closeEstoqueLocalizacaoModal] = useModal();


    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }

    const clearFiltros = () => {
        setSelectedTipoMovimento(null)
        setSelectedTipoEstoque(null)
        setSelectedDocumento(null)
        setStatus('ATIVO')
        setDataMovimento(null)	
        // setSelectedTipoLocalizacao(null)
        setPage(0)
        // fetchEstoqueLocalizacao(0)
    }
    const fetchEstoqueLocalizacao = async (page = 0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/movimento/listar`,
                {
                    params: {
                        status: status,
                        idTipoMovimento	: selectedTipoMovimento?.value ? selectedTipoMovimento?.value : null,
                        idEntidadeDocumento: selectedDocumento?.value ? selectedDocumento?.value : null,
                        'dataMovimento': dataMovimento ? `${(dataMovimento)}` : null,
                        // titulo: titulo ? titulo : null,
                        // descricao: descricao ? descricao : null,
                        // idEstoque: selectedTipoEstoque?.value ? selectedTipoEstoque?.value : null,
                        // idTipoLocalizacao: selectedTipoLocalizacao?.value ? selectedTipoLocalizacao?.value : null,
                        // codigo: codigo ? codigo : null,
                        page: page
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                    setLocalizacao([]);
                }

                // const { content, totalPages } = response.data;
                // console.log(totalPages)
                // setEstoques(response.data.content)
                setLocalizacao((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                // setHasMore(false);
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Movimento para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    };
    const loadOptionsEstoque = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `[${item.id}] ${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const loadOptionsTipoEntidade = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/entidade-documentos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: null
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `[${item.id}]${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const loadOptionsTipoMovimento = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-movimento/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 100,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueLocalizacaoModal();
    }

    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchEstoqueLocalizacao(0);
    };


    useEffect(() => {
        // handleSearch()
        fetchEstoqueLocalizacao(page)
        // setLoading(true)
        // setTimeout(() => {
        //     setLoading(false)
        // }, 2000);
        //     }
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Movimento
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => { openModal(false) }}
                >
                    Cadastrar Movimento
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">
                    <Row className='col-12 mb-4 row mt-8'>
                        
                        <Col>
                            <label htmlFor="" className="form-label">Documento:</label>
                            <AsyncSelect
                                value={selectedTipoEstoque}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEstoque(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEstoque(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsEstoque}
                                placeholder="Selecione o Estoque" className="mt-1"></AsyncSelect>

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Tipo Entidade Documento :</label>
                            <AsyncSelect
                                value={selectedDocumento}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedDocumento(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedDocumento(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoEntidade}
                                placeholder="Selecione o Estoque" className="mt-1"></AsyncSelect>

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Tipo Movimento:</label>
                            <AsyncSelect
                                value={selectedTipoMovimento}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoMovimento(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoMovimento(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoMovimento}
                                placeholder="Selecione o Tipo de Movimento" className="mt-1"></AsyncSelect>

                        </Col>
                        
                        
                    </Row>
                    <Row className="mt-5 col-12 mb-4 row">
                        {/* <Col>
                            <label htmlFor="" className="form-label">Tipo de Localização:</label>
                            <AsyncSelect
                                value={selectedTipoLocalizacao}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoLocalizacao(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoLocalizacao(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoLocalizacao}
                                placeholder="Selecione o tipo de Localização" className="mt-1"></AsyncSelect>

                        </Col> */}
                        <Col className="col-4">
                        
                        {/* <d
                        iv className='col-sm-3' style={{ marginTop: '26px' }}> */}
                        <label htmlFor="" className="form-label"  >Data do Movimento:</label>
                        {/* <DateRangePicker
                            size='lg'
                            appearance='default'
                            // onChange={handleDateRangeChange}
                            // value={valueDateRange}
                            format='dd-MM-yyyy'
                            ranges={[
                                {
                                    label: 'Ontem',
                                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                                },
                                {
                                    label: 'Hoje',
                                    value: [new Date(), new Date()],
                                },
                                {
                                    label: 'Último 7 dias',
                                    value: [subDays(new Date(), 6), new Date()],
                                },
                                {
                                    label: 'Este mês',
                                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                                },
                            ]}
                            placeholder='DATA EMISSAO'
                            defaultValue={[new Date(), new Date()]}
                            className={`w-100`}
                            style={{
                                backgroundColor: '#fff',
                            }} */}
                        {/* //locale={ptBR} */}
                        {/* /> */}
                        {/* </div> */}
                        <input type="date" value={dataMovimento ? dataMovimento : ""} onChange={(e) => { setDataMovimento(e.target.value) }} className="form-control" name="" id="" />
                    </Col>
                        <Col className="col-4">
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                        
                    </Row>
                    {/* <Row className="mt-4 col-12 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name=""
                                value={descricao ? descricao : ""}
                                onChange={(e) => {
                                    setDescricao(e.target.value)
                                }} placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row> */}
                    <div className='col-12 my-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Documento</th>
                                    <th className='text-center'>Entidade do Documento</th>
                                    <th className='text-center'>Tipo de Movimento</th>
                                    <th className='text-center'>Data do Movimento</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de criacao</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteracao</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {localizacao?.map((log) => (
                                    <tr className="text-center">
                                        <td>{log.tituloDocumento}</td>
                                        <td>{log.entidadeDocumento}</td>
                                        <td>{log.tipoMovimento}</td>
                                        <td>{formatarData(log.dataMovimento)}</td>
                                        <td>{log.status}</td>
                                        <td>{log.usuarioCriacaoNome}</td>
                                        <td>{(log.dataCriacao)}</td>
                                        <td>{log.usuarioAlteracaoNome}</td>
                                        <td>{(log.dataAlteracao)}</td>
                                        <td>
                                            {/* <div className='text-center'>
                                                <button
                                                    // onClick={openModal}
                                                    className='btn btn-success btn-sm '
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div> */}
                                            <div className='dropdown position-static'>
                                                <button
                                                    className='btn btn-success btn-sm dropdown-toggle'
                                                    data-bs-toggle='dropdown'
                                                >
                                                    <i className=''>Opções</i>
                                                </button>
                                                <ul className='dropdown-menu'
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 1050,
                                                        top: '100%',
                                                        left: '0',
                                                    }}>
                                                    <li>
                                                        <button
                                                            className='dropdown-item'
                                                            type='button'
                                                            onClick={() => { openModal(true,log.id,log.idDocumento,log.tituloDocumento,log.idEntidadeDocumento,log.entidadeDocumento,log.idTipoMovimento,log.tipoMovimento,log.dataMovimento,log.status)  }}

                                                        >
                                                            <i className='bi-pencil'> </i>
                                                            <span className="mx-2">
                                                                EDITAR

                                                            </span>

                                                        </button>
                                                    </li>

                                                    <li>
                                                        <hr className='dropdown-divider' />
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={'/listagem-estoque-tipo-movimento'}
                                                            className='dropdown-item'
                                                            type='Button'
                                                        // onClick={() => handleGerarPedidoSwal(prevenda.id)}
                                                        >
                                                            <i className='bi-box-arrow-up-right'></i>
                                                            <span className="mx-2">
                                                                PRODUTOS

                                                            </span>
                                                        </Link>
                                                    </li>
                                                    {/* 
                                                    <li>
                                                        <hr className='dropdown-divider' />
                                                    </li>
                                                    <li>
                                                        <button
                                                            className='dropdown-item'
                                                            type='button'
                                                            // onClick={() => { handleOpenAnexarPdfModal(prevenda) }}
                                                        >
                                                            ANEXAR PDF
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <hr className='dropdown-divider' />
                                                    </li> */}
                                                    {/* <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={()=>{handleGerarPrazo(prevenda)}}
                              >
                                GERAR PRAZOS
                              </button>
                            </li> */}
                                                    {/* <li>
                                                        <hr className='dropdown-divider' />
                                                    </li>

                                                    <li>
                                                        <button
                                                            className='dropdown-item'
                                                            type='button'
                                                            // onClick={() => handleReordenarProposta(prevenda.id)}
                                                        >
                                                            REORDENAR PROPOSTA (BUG)
                                                        </button>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>)}

            {/* <ModalEstoques
                isOpen={isEstoqueModal}
                onHide={closeEstoqueModal}
                isEdit={isEdit}
                dataLine={dataLine}
                loadEstoques={fetchEstoques}
            /> */}
            {/* <ModalEstoqueLocalizacao
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchEstoqueLocalizacao}
                isEdit={isEdit}
                isOpen={isEstoqueLocalizacaoModal}
                onHide={closeEstoqueLocalizacaoModal}
            /> */}
            <ModalEstoqueMovimento 
                clearFiltros={clearFiltros}
                dataLine={dataLine}
                reload={fetchEstoqueLocalizacao}
                isEdit={isEdit}
                isOpen={isEstoqueLocalizacaoModal}
                onHide={closeEstoqueLocalizacaoModal} />

        </div>

    )
}

export default ListagemEstoqueMovimento