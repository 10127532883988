import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import {
  AsyncSelectCliente,
  AsyncSelectEmpresa,
  AsyncSelectFornecedor,
  AsyncSelectPrevenda,
  IContasReceber,
  IContasReceberRequest,
  ISelectOption,
  ISetor,
  smoothScrollToBottom,
  useModal,
} from '../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomRow} from '../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getContasReceberRequest} from '../listagem/listagem_requests/listagem_requests';
import ModalMarcas from './components/modal/ModalContasReceber';
import AsyncSelectTipoEntidade from '../../shared/components/async-select-tipo-entidade/async-select-tipo-entidade';
import {ClienteOption} from '../cadastros/proposta-pedido/types/interface';
import {Link} from 'react-router-dom';
import axios from 'axios';
import RangePriceBetween from './components/RangePriceBetween';
import {set} from 'date-fns';

export const renderEntidadeContasReceber = (
  isSelectAsyncEntidade: ISelectOption | null,
  setIsSelectAsyncEntidade: any,
  isSelectAsyncTipoEntidade: ISelectOption | null
) => {
  if (isSelectAsyncTipoEntidade) {
    switch (isSelectAsyncTipoEntidade.value) {
      case 1:
        return (
          <AsyncSelectCliente
            handleOptions={(option) => setIsSelectAsyncEntidade(option!)}
            inputOption={isSelectAsyncEntidade}
            isSelectDOM={false}
            onKeyDown={(e: any) => {
              if (e.key === 'Backspace') {
                setIsSelectAsyncEntidade(null);
              }
            }}
          />
        );
      case 2:
        return (
          <AsyncSelectPrevenda
            handleOptions={(option) => setIsSelectAsyncEntidade(option!)}
            inputOption={isSelectAsyncEntidade}
            onKeyDown={(e: any) => {
              if (e.key === 'Backspace') {
                setIsSelectAsyncEntidade(null);
              }
            }}
          />
        );
      case 3:
        return (
          <AsyncSelectPrevenda
            handleOptions={(option) => setIsSelectAsyncEntidade(option!)}
            inputOption={isSelectAsyncEntidade}
            onKeyDown={(e: any) => {
              if (e.key === 'Backspace') {
                setIsSelectAsyncEntidade(null);
              }
            }}
          />
        );
      case 7:
        return (
          <AsyncSelectFornecedor
            handleOptions={(option) => setIsSelectAsyncEntidade(option!)}
            inputOption={isSelectAsyncEntidade}
            onKeyDown={(e: any) => {
              if (e.key === 'Backspace') {
                setIsSelectAsyncEntidade(null);
              }
            }}
          />
        );

      default:
        return (
          <input
            type='number'
            placeholder='Digite a Entidade '
            disabled={isSelectAsyncTipoEntidade == null}
            value={isSelectAsyncEntidade?.value}
            onChange={(e) => setIsSelectAsyncEntidade({value: Number(e.target.value), label: ''})}
            className='form-control'
          />
        );
    }
  } else {
    return (
      <input
        type='number'
        placeholder='Digite a Entidade '
        disabled={isSelectAsyncTipoEntidade == null}
        value={isSelectAsyncEntidade?.value}
        onChange={(e) => setIsSelectAsyncEntidade({value: Number(e.target.value), label: ''})}
        className='form-control'
      />
    );
  }
};

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const getSetores = async (setSetores: any) => {
  try {
    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;
    const {data, status} = await axios.get(url);

    setSetores(data);
  } catch (errors: any) {
    const {error} = errors;

    if (error.response) {
      const status = error.response.status;

      const {data} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao buscar setor, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  }
};

export const getTipoDespesa = async (setTipoDespesa: any) => {
  try {
    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-tipo-despesas/listar?page=0&size=10000&status=ATIVO`;
    const {data, status} = await axios.get(url);

    setTipoDespesa(data.content);
  } catch (errors: any) {
    const {error} = errors;

    if (error.response) {
      const status = error.response.status;

      const {data} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao buscar tipo de despesa, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  }
};

const ListagemContasReceber = () => {
  const contasReceberColumns: Column<IContasReceber>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Tipo de Entidade',
      accessor: 'tipoEntidade',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Entidade',
      accessor: 'entidadeNome',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Setor',
      accessor: 'setor',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Tipo de Despesa',
      accessor: 'tipoDespesa',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Empresa',
      accessor: 'empresa',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({value}) => (
        <div className='text-start'>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value)}
        </div>
      ),
    },
    {
      Header: 'Qntd. de Parcelas',
      accessor: 'qtdParcelas',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => (
        <div className='text-start'>
          {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='dropdown position-static text-start'>
          <button className='btn btn-success btn-sm dropdown-toggle' data-bs-toggle='dropdown'>
            <i className=''>Opções</i>
          </button>
          <ul
            className='dropdown-menu'
            style={{
              position: 'absolute',
              zIndex: 1050,
              top: '100%',
              left: '0',
            }}
          >
            <li>
              <button
                className='dropdown-item'
                type='button'
                onClick={() => {
                  handleOnEdit(row.original!);
                }}
              >
                <i className='bi-pencil'> </i>
                <span className='mx-2'>Editar</span>
              </button>
            </li>

            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link
                to={`/contas-a-receber/parcelas/${row.original.id}`}
                className='dropdown-item'
                type='Button'
              >
                <i className='bi-box-arrow-up-right'></i>
                <span className='mx-2'>Gerenciar Parcelas</span>
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const [contasReceberData, setContasReceberData] = useState<IContasReceber[]>([]);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [contasReceberSelected, setContasReceberSelected] = useState<IContasReceber | null>(null);
  const [isSelectAsyncTipoEntidade, setIsSelectAsyncTipoEntidade] = useState<ISelectOption | null>(
    null
  );
  const [isSelectAsyncEntidade, setIsSelectAsyncEntidade] = useState<ISelectOption | null>(null);

  const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null);
  const [setores, setSetores] = useState<ISetor[] | null>(null);
  const [tipoDespesa, setTipoDespesa] = useState<ISetor[] | null>(null);

  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [maxPrice, setMaxPrice] = useState<string | null>(null);

  const data = useMemo(() => contasReceberData, [contasReceberData]);
  const columns = useMemo(() => contasReceberColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: IContasReceberRequest = {
    idEmpresa: '',
    idEntidade: '',
    idSetor: '',
    idTipoDespesa: '',
    idTipoEntidade: '',
    descricao: '',
    qtdParcelas: '',
    valorFim: '',
    valorInicio: '',
    status: 'ATIVO',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      try {
        const response = await getContasReceberRequest(
          {
            idEmpresa: selectedEmpresa ? selectedEmpresa.value : '',
            idEntidade: isSelectAsyncEntidade ? isSelectAsyncEntidade.value : '',
            idSetor: values.idSetor,
            idTipoDespesa: values.idTipoDespesa,
            idTipoEntidade: isSelectAsyncTipoEntidade ? isSelectAsyncTipoEntidade.value : '',
            descricao: values.descricao,
            qtdParcelas: values.qtdParcelas,
            valorFim: maxPrice ? maxPrice : '',
            valorInicio: minPrice ? minPrice : '',
            status: values.status,
          },
          0
        );

        const {content, totalPages} = response.data;

        setIsHasMore(isPage < totalPages - 1);

        setIsLoading(false);
        setSubmitting(false);

        setContasReceberData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
    smoothScrollToBottom(300);
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (data: IContasReceber) => {
    openModal(true, data);
  };

  //Get de categorias por modelo
  const getContasReceber = async ({page = 0, reset = false}: {page: number; reset?: boolean}) => {
    setIsLoading(true);

    try {
      const response = await getContasReceberRequest(initialValues, page);

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      if (reset) {
        setContasReceberData(content);
      } else {
        setContasReceberData((prev) => (isPage === 0 ? content : [...prev, ...content]));
      }

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        const {data} = errors.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  const [isContasReceberModal, openContasReceberModal, closeContasReceberModal] = useModal();

  const openModal = (isEdit: boolean, data?: IContasReceber) => {
    if (data) {
      setContasReceberSelected(data);
    }
    setIsEdit(isEdit);
    openContasReceberModal();
  };
  const clearFiltros = () => {
    formik.resetForm();
    setIsPage(0);
    setIsHasMore(false);
    setIsSelectAsyncEntidade(null);
    setIsSelectAsyncTipoEntidade(null);
    setSelectedEmpresa(null);
    setMinPrice(null);
    setMaxPrice(null);
    getContasReceber({page: 0, reset: true});
  };

  const handleAsyncSelectTipoEntidade = (option: ISelectOption) => {
    setIsSelectAsyncTipoEntidade(option);
  };
  const handleSelectChange = (event, fieldName) => {
    formik.setFieldValue(fieldName, event.target.value);
  };

  useEffect(() => {
    getContasReceber({page: isPage});
  }, [isPage]);

  useEffect(() => {
    getSetores(setSetores);
    getTipoDespesa(setTipoDespesa);
  }, []);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Contas a Receber
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Conta a Receber
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <div className='mt-10'>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Tipo de Entidade:</label>
              <AsyncSelectTipoEntidade
                handleOptions={(option) => handleAsyncSelectTipoEntidade(option!)}
                inputOption={isSelectAsyncTipoEntidade}
                onKeyDown={(e: any) => {
                  if (e.key === 'Backspace') {
                    setIsSelectAsyncTipoEntidade(null);
                  }
                }}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Entidade:</label>
              {renderEntidadeContasReceber(
                isSelectAsyncEntidade,
                setIsSelectAsyncEntidade,
                isSelectAsyncTipoEntidade
              )}
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Empresa:</label>
              <AsyncSelectEmpresa
                onKeyDown={() => {
                  setSelectedEmpresa(null);
                }}
                inputOption={selectedEmpresa}
                handleOptions={(e) => {
                  setSelectedEmpresa(e);
                }}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Setor:</label>
              <select
                className='form-select'
                aria-label='Select example'
                onChange={(event) => handleSelectChange(event, 'idSetor')}
                value={formik.values.idSetor}
              >
                <option value=''>Selecione o setor</option>
                {setores &&
                  setores.map((setor) => (
                    <option value={setor.id} key={setor.id}>
                      {setor.titulo}
                    </option>
                  ))}
              </select>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Tipo de Despesa:</label>
              <select
                className='form-select'
                aria-label='Select example'
                onChange={(event) => handleSelectChange(event, 'idTipoDespesa')}
                value={formik.values.idTipoDespesa}
              >
                <option value=''>Selecione o tipo de despesa</option>
                {tipoDespesa &&
                  tipoDespesa.map((tD) => (
                    <option value={tD.id} key={tD.id}>
                      {tD.titulo}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Valor:</label>
              <RangePriceBetween
                setMaxPrice={setMaxPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                minPrice={minPrice}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <label className='form-label'>Quantidade de parcelas:</label>
              <input
                type='text'
                placeholder='Digite a quantidade de parcelas '
                {...formik.getFieldProps('qtdParcelas')}
                className='form-control'
                value={formik.values.qtdParcelas}
                onChange={(event) => {
                  const value = event.target.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                  formik.setFieldValue('qtdParcelas', value);
                }}
                onKeyDown={(event) => {
                  const value = event.currentTarget.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                  formik.setFieldValue('qtdParcelas', value);
                }}
              />
            </Col>
            <Col sm='3' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-4'></RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição'
                {...formik.getFieldProps('descricao')}
                className={`form-control`}
              />
            </Col>
          </RowBootstrap>
          {/* <RowBootstrap className='col-12 mb-4'>
            <Col md='4' className='col-sm-12'>
              <label htmlFor='' className='form-label'>
                Data de Importação:
              </label>

              <DatePicker
                className='w-100'
                format='dd-MM-yyyy'
                placeholder='Data de Saída'
                {...formik.getFieldProps('dataImportacao')}
              />
            </Col>
          </RowBootstrap> */}
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar'}
              </button>
              <button
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* TABELA */}
      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IContasReceber>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IContasReceber>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={() => handleOnEdit(row.original)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
      <ModalMarcas
        isOpen={isContasReceberModal}
        onHide={closeContasReceberModal}
        isEdit={isEdit}
        data={contasReceberSelected}
        loader={getContasReceber}
      ></ModalMarcas>
    </div>
  );
};

export default ListagemContasReceber;
