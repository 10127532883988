import { Button, ModalProps, Spinner } from "react-bootstrap";
import { Modal } from "../../../../shared";
import { ChangeEvent, useState } from "react";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import axios from "axios";


type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
    selectItens?: object;
    setDataTabela?: Function;
    getResumoSkuData?: Function;
    id?: number | null;
};


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalInputData: React.FC<Props> = ({ isOpen, onHide, selectItens, setDataTabela, id, getResumoSkuData }) => {
    const [isLoadingGerarPrazo, setIsLoadingGerarPrazo] = useState<boolean>(false);
    const [valueDateRange, setValueDateRange] = useState<DateRange | null>([new Date(), new Date()]);
    const [valueData, setValueData] = useState<string |number | null>(null)

    const onClose = () => {
        setValueData(null)
        onHide!();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Acessa o valor do input e define o estado
        setValueData(e.target.value);  // e.target.value é uma string
      }

    const handleSubmit = async () => {
        try {
            console.log(2,valueData)

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/produtos-prazos/inserir-data/${id}`;
            const response = await axios.put(url, {
                "dataPrazo": `${valueData}T00:00:00.000`
            });
        } 
        catch (errors: any) { }
        if (getResumoSkuData) {
            getResumoSkuData(id)
        }
        onClose()
    };

    const submitButton = (
        <Button
            // title=""
            type='submit'
            variant='success'
            onClick={() => handleSubmit()}
        >
            {isLoadingGerarPrazo ? <Spinner animation='border' size='sm' /> : 'Confirmar'}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        title: 'Adicionar data acordada com cliente',
        actions: [submitButton],
        onHide: onClose,
        size: 'sm',
    };
    return (
        <>
            <Modal  {...modalConfigs} >

                {/* <DatePicker
                    className='w-100 '
                    format='dd-MM-yyyy'
                    // style={{position:'fixed'}}
                    // dropdownClassName={'custom-datepicker-dropdown'}
                    // dropdownClassName="custom-datepicker-dropdown"
                /> */}
                    <input type="date" className="form-control"
                    onChange={handleChange}
                    placeholder='Data de emissão'
                    value={valueData?valueData:''}

                     />
                {/* <Da */}
            </Modal>
            <style>
  {`
    .custom-datepicker-dropdown * {
      z-index: 1050 !important;
    }
  `}
</style>
        </>
    )
}

export default ModalInputData

