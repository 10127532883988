import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {IEmpresa, IFornecedor, IPrevenda, ISelectOption} from '../../domain-types';
import './async-select-fornecedor-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placeholder?: string
};

const AsyncSelectFornecedor: React.FC<IProps> = ({handleOptions, inputOption, isDisabled, onKeyDown, placeholder}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectFornecedorAsync, setSelectFornecedorAsync] = useState<ISelectOption | null>(null);
  const [empresaDetails, setEmpresaDetails] = useState<IFornecedor[]>();

  const loadOptionsFornecedores = async (inputValue: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores/buscarFornecedor?busca=${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      console.log(response);

      setEmpresaDetails(data);

      return data.map((item) => ({
        value: item.id,
        label: '',
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const loadFornecedorById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setEmpresaDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções do Fornecedor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const fornecedor = empresaDetails?.find((fornecedor) => fornecedor.id === Number(option.value));

    if (fornecedor) {
      return (
        <div className='container-message-fornecedor'>
          <div className='box-left-fornecedor'>
            <div className='info-container-fornecedor'>
              <p>
                ID: <span>{fornecedor.id ? fornecedor.id : '-'}</span>
              </p>
              <p>
                <span>{fornecedor.fantasia ? fornecedor.fantasia : '-'}</span>
              </p>

              <p>
                Razão Social:{' '}
                <span>
                  {fornecedor.razaosocial
                    ? fornecedor.razaosocial.length > 30
                      ? `${fornecedor.razaosocial.slice(0, 30)} ...`
                      : fornecedor.razaosocial
                    : ' - '}
                </span>
              </p>
              <p>
                CNPJ: <span>{fornecedor.cnpj ? fornecedor.cnpj : '-'}</span>
              </p>
            </div>
          </div>
          <div className='box-right-fornecedor'>
            <p>
              {' '}
              Cidade: <span> {fornecedor.cidade ? fornecedor.cidade : '-'} </span>{' '}
            </p>
            <p>
              {' '}
              CEP: <span> {fornecedor.cep ? fornecedor.cep : '-'} </span>{' '}
            </p>
            <p>
              {' '}
              Telefone: <span>{fornecedor.telefone ? fornecedor.telefone : '-'}</span>
            </p>
            <p>
              {' '}
              Email: <span> {fornecedor.email ? fornecedor.email : '-'} </span>{' '}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectFornecedorAsync && handleOptions) {
      handleOptions(selectFornecedorAsync);
    }
  }, [selectFornecedorAsync]);

  useEffect(() => {
    if (inputOption) {
      loadFornecedorById(inputOption.value);
      setSelectFornecedorAsync(inputOption);
    } else if (inputOption == null) {
      setSelectFornecedorAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
    styles={{
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
      option: (provided, state) => ({
        ...provided,
        zIndex: 9999,
      }),
    }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder={placeholder ? placeholder : 'Fornecedor' }
      loadOptions={loadOptionsFornecedores}
      value={selectFornecedorAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectFornecedorAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectFornecedor;
