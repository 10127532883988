/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useAuth } from '../../../../../app/modules/auth';

const SidebarMenuMain = () => {
  const { currentUser } = useAuth();

  function organizaPermissoes(permissions) {
    const permissionMap = {};
    permissions.forEach((permission) => {
      permissionMap[permission.arquivo] = true;
    });
    return permissionMap;
  }

  function organizaPermissoesPerfil(permissions) {
    const permissionMap = {};
    permissions.forEach((permission) => {
      permissionMap[permission.perfil] = true;
    });
    return permissionMap;
  }

  const permissoes = currentUser?.usuariosPermissoes;
  const permissionMap = organizaPermissoes(permissoes);
  const permissionPerfilMap = organizaPermissoesPerfil(permissoes);

  // Função para verificar permissões, usada como exceção
  function hasAnyPermission(permissionKeys) {
    return permissionKeys.some((key) => permissionMap[key]);
  }

  // Função para verificar permissões de perfil
  function hasAnyPermissionPerfil(permissionKeys) {
    return permissionKeys.some((key) => permissionPerfilMap[key]);
  }
  return (
    <>
      <SidebarMenuItem
        to='/inicio'
        icon='bi-lightbulb-fill'
        fontIcon='bi-lightbulb-fill'
        title='Início'
        fontsizeIcon='16px'
      />

      {currentUser?.tipouser === 'ADMIN' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>relatórios</span>
            </div>
          </div>
          {
            <SidebarMenuItemWithSub
              to='/relatorios/dashboards'
              title='Dashboards'
              icon='element-7'
              fontIcon='bi-layers'
            >
              <SidebarMenuItemWithSub
                to='/relatorios/dashboards/comercial'
                title='Comercial'
                hasBullet={true}
              >
                <SidebarMenuItem
                  to='/relatorios/dashboards/comercial/relatorio-vendas'
                  title='Relatório de Vendas'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>
          }
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Consultas</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/consultas/estoques'
            title='Estoques'
            icon='bi-box-seam'
            fontIcon='bi-box-seam'
            fontsizeIcon='16px'
          >
            <SidebarMenuItem
              to='/consultas/estoques/consulta-estoque'
              title='Estoque (WMS)'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Campanha Promocional
              </span>
            </div>
          </div>

          <SidebarMenuItemWithSub
            to='/campanha-promocional/'
            title='Promoções/Encartes'
            icon='bi-percent'
            fontIcon='bi-percent'
            fontsizeIcon='16px'
          >
            <SidebarMenuItem
              to='/promocao-cadastro'
              title='Criar nova promoção/encarte'
              icon='bi-file-earmark-richtext'
              fontIcon='bi-file-earmark-richtext'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/promocao-listagem'
              title='Pesquisar promoção/encarte'
              icon='bi-search'
              fontIcon='bi-search'
              fontsizeIcon='16px'
            />
          </SidebarMenuItemWithSub>

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>sistema</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/sistema/Pessoal'
            title='Pessoal'
            fontIcon='bi-person-circle'
            icon='bi-person-circle'
            fontsizeIcon='18px'
          ></SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/cadastros'
            title='Cadastros'
            fontIcon='bi-plus'
            icon='bi-plus'
            fontsizeIcon='16px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/Clientes'
              title='Clientes'
              fontIcon='bi-archive'
              icon='bi-person-square'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-clientes' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-clientes' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/Clientes'
              title='Empresas'
              fontIcon='bi-buildings'
              icon='bi-buildings'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-clientes-empresa' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-clientes-empresa' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/produto'
              title='Produto'
              fontIcon='bi-archive'
              icon='bi-lamp'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/produto-cadastro' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/produto-listagem' title='Listar' hasBullet={true} />

              <SidebarMenuItemWithSub
                to='/sistema/produto'
                title='Categorização de Produto'
                fontIcon='bi-bookmark'
                icon='bi-bookmark'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem to='/produto-categorizacao' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/produto-categorizacao-listagem'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>

              <SidebarMenuItemWithSub
                to='/sistema/produto'
                title='Unidades de Medida'
                fontIcon='bi-rulers'
                icon='bi-rulers'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem to='/produto-unidade-medida' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/produto-unidade-medida-listagem'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItem
                to='/sistema/linha-produto'
                title='Linha de Produto'
                fontIcon='bi-grid-1x2'
                icon='bi-grid-1x2'
                fontsizeIcon='16px'
              />
              <SidebarMenuItem
                to='/garantia-produto'
                title='Garantia do Produto'
                fontIcon='bi'
                icon='bi-shield-check'
                fontsizeIcon='16px'
              />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/produto'
              title='Produto - Consumo'
              fontIcon='bi-archive'
              icon='bi-lamp'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/produto-consumo-cadastro' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/produto-consumo-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/fornecedor'
              title='Fornecedor'
              fontIcon='bi-person'
              icon='bi-person'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-fornecedor' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-fornecedor' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/colaborador'
              title='Colaborador'
              fontIcon='bi-briefcase'
              icon='bi-briefcase'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-colaborador' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-colaborador' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/Parceiros'
              title='Profissionais'
              fontIcon='bi-archive'
              icon='bi-people'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-parceiros' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-parceiros' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/logistica/endereco'
              title='Endereço'
              fontIcon='bi-geo-alt'
              icon='bi-geo-alt'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/logistica-endereco' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/logistica-endereco-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              title='Empreendimentos'
              icon='bi-building'
              fontIcon='bi-building'
              to='/segmentos-emprendimentos'
            >
              <SidebarMenuItem
                to='/empreendimentos'
                title='Empreendimentos'
                // hasBullet={true}
                fontIcon='bi-building'
                icon='bi-building'
                fontsizeIcon='16px'
              >
                {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
              </SidebarMenuItem>

              <SidebarMenuItem
                to='/segmentos-empreendimentos'
                title='Segmentos do Empreendimento'
                // hasBullet={true}
                fontIcon='bi-bank'
                icon='bi-house-door'
                fontsizeIcon='16px'
              >
                {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
              </SidebarMenuItem>

              <SidebarMenuItem
                to='/classificacao-empreendimentos'
                title='Classificação dos Empreendimentos'
                // hasBullet={true}
                fontIcon='arrow-up-down'
                icon='arrow-up-down'
                fontsizeIcon='16px'
              >
                {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
              </SidebarMenuItem>

              <SidebarMenuItem
                to='/porte-empreendimentos'
                title='Porte do Empreendimentos'
                // hasBullet={true}
                fontIcon='bi-bar-chart'
                icon='bi-bar-chart'
                fontsizeIcon='16px'
              ></SidebarMenuItem>

              <SidebarMenuItem
                to='/tipo-de-obra'
                title='Tipo de Obra'
                // hasBullet={true}
                fontIcon='bi-tags'
                // icon='bi-bar-chart'
                icon='bi-bricks'
                fontsizeIcon='14px'
              ></SidebarMenuItem>

              <SidebarMenuItem
                to='/fases-da-obra'
                title='Fases da Obra'
                // hasBullet={true}
                fontIcon='bi-tags'
                // icon='bi-bar-chart'
                icon='bi-hourglass'
                fontsizeIcon='16px'
              ></SidebarMenuItem>
            </SidebarMenuItemWithSub>

            <SidebarMenuItem
              to='/compradores'
              title='Compradores'
              fontIcon='bi'
              icon='bi-cart'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/tipo-origem'
              title='Tipo de Origem'
              fontIcon='bi'
              icon='bi-ui-checks'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/status-garantia'
              title='Status de Garantia'
              fontIcon='bi'
              icon='bi-shield-check'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/tipo-garantia'
              title='Tipo de Garantia'
              fontIcon='bi'
              icon='bi-shield-shaded'
              fontsizeIcon='16px'
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/Vendas'
            title='Vendas'
            fontIcon='bi-archive'
            icon='bi-cart2'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/PropostasComerciais'
              title='Propostas Comerciais'
              fontIcon='bi-archive'
              icon='bi-file-earmark-text'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/propostas-comerciais' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem
                to='/listagem-propostas-comerciais'
                title='Listar'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/Pedidos'
              title='Pedidos'
              fontIcon='bi-archive'
              icon='bi-file-earmark-text'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/pedidos' title='Adicionar' hasBullet={true} /> */}
              <SidebarMenuItem to='/listagem-pedidos' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/venda'
              title='Tipos de Venda'
              fontIcon='bi-coin'
              icon='bi-coin'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-tipo-venda' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-tipo-venda' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/logistica'
            title='Logistica'
            fontIcon='bi-box2'
            icon='bi-box2'
            fontsizeIcon='16px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/logistica/expedicao'
              title='Expedição'
              fontIcon='bi-airplane'
              icon='bi-airplane'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/logistica-expedicao-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/logistica/expedicao'
              title='Expedição Sysled'
              fontIcon='bi-airplane'
              icon='bi-airplane'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem
                to='/logistica-expedicao-sysled-listagem'
                title='Listar'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/logistica/FluxoLogistico'
              title='Fluxo Logistico'
              fontIcon='bi-columns-gap'
              icon='bi-columns-gap'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/logistica-fluxo-listagem' title='Listar' hasBullet={true} />
              <SidebarMenuItem to='/logistica-fluxo-cadastro' title='Adicionar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/logistica/expedicao'
              title='Separação Logistica'
              fontIcon='bi-arrows-fullscreen'
              icon='bi-arrows-fullscreen'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/logistica-separacao' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/logistica-separacao-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/workflow'
            title='Workflow'
            fontIcon='bi-archive'
            icon='bi-inboxes'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Pendências'
              fontIcon='bi-archive'
              icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/consultas/estoques'
            title='Estoques'
            icon='bi-box-seam'
            fontIcon='bi-box-seam'
            fontsizeIcon='16px'
          >
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoques'
              title='Estoques'
              icon='bi-box-seam'
              fontIcon='bi-box-seam'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-tipos'
              title='Tipos de Estoque '
              // hasBullet={true}
              icon='bi-collection'
              fontsizeIcon='16px'
            // fontIcon='bi-box-seam'
            // hasBullet={true}
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-localizacao'
              title='Estoque Localização'
              // hasBullet={true}
              fontsizeIcon='16px'
              icon='bi-geo-alt'
            // fontIcon='bi-box-seam'
            // fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-tipo-localizacao'
              title='Tipos de Localização'
              fontsizeIcon='16px'
              // hasBullet={true}
              icon='bi-pin-map'
            // fontIcon='bi-box-seam'
            // fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-transferencias'
              title='Estoque Transferência'
              // hasBullet={true}
              fontsizeIcon='16px'
              icon='bi-arrow-left-right'
            // fontIcon='bi-box-seam'
            // fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-inventario'
              title='Estoque Inventário'
              // hasBullet={true}
              icon='bi-archive'
              // fontIcon='bi-box-seam'
              fontsizeIcon='16px'

            // fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-motivo-inventario'
              title='Estoque Inventário Motivo'
              // hasBullet={true}
              icon='bi-journal-text'
              // fontIcon='bi-box-seam'
              // fontsizeIcon='16px'
              fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-entradas'
              title='Estoque Entradas'
              // hasBullet={true}
              icon='bi-clipboard-plus'
              fontsizeIcon='16px'

            // fontIcon='bi-box-seam'
            // fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/consultas/estoques/listagem-estoque-tipo-movimento'
              title='Tipos de Movimentos'
              // hasBullet={true}
              icon='bi-shuffle'
              // fontIcon='bi-box-seam'
              fontsizeIcon='16px'
            // fontsizeIcon='16px'
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/workflow'
            title='Projetos'
            fontIcon='bi-rocket'
            icon='bi-rocket'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              title='Parametros'
              to='/parametros'
              fontsizeIcon='16px'
              icon='bi-houses'
            >
              <SidebarMenuItem
                to='/projetos'
                title='Projetos'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-rocket'
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='status-do-projeto'
                title='Status do Projeto'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-clipboard-check'
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='status-pagamento'
                title='Status do Pagamento do Financeiro'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-clipboard-check'
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='fatura-do-projeto'
                title='Fatura do Projeto'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-receipt'
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='orcamentos-do-projeto'
                title='Orçamentos dos Projetos'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-pencil-square'
              ></SidebarMenuItem>

              <SidebarMenuItem
                to='custos-do-projeto'
                title='Custos dos Projetos'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-cash'
              ></SidebarMenuItem>

              <SidebarMenuItem
                to='tipos-de-custo'
                title='Tipos de Custos dos Projetos'
                fontsizeIcon='16px'
                // hasBullet
                icon='bi-tags'
              ></SidebarMenuItem>
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/workflow'
            title='Suprimentos'
            fontIcon='bi-boxes'
            icon='bi-boxes'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/ordem-de-compra'
              title='Pedido de Compra'
              fontIcon='bi-bag'
              icon='bi-bag'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/pedido-compras' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/pedido-compras-listagem' title='Listar' hasBullet={true} />

              <SidebarMenuItemWithSub
                to='/sistema/logistica/expedicao'
                title='Status de Pedido de Compra'
                fontIcon='bi-archive'
                icon='bi-archive'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem to='/suprimentos-cadastro ' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem to='/suprimentos-listagem' title='Listar' hasBullet={true} />
              </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/venda'
              title='Pendência'
              fontIcon='bi-cart-x'
              icon='bi-cart-x'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/listagem-pendencia-compra' title='Listar' hasBullet={true} />

              <SidebarMenuItemWithSub
                to='/sistema/venda'
                title='Pendencia de Compra'
                fontIcon='bi-plus'
                icon='bi-plus'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem
                  to='/pendencia-compra'
                  title='Adicionar Pendência de Compra'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/marca'
              title='Marcas'
              fontIcon=' bi-amd'
              icon='bi-amd'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/sistema/marcas' title='Marcas' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Ressuprimentos'
              // fontIcon='bi-archive'
              // icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/workflow'
            title='Produção'
            fontIcon='bi-buildings'
            icon='bi-buildings'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Chão de fábrica'
              // fontIcon='bi-archive'
              // icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Etapas'
              // fontIcon='bi-archive'
              // icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Eficiência de Produção'
              // fontIcon='bi-archive'
              // icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Parque/Capacidade'
              // fontIcon='bi-archive'
              // icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/financeiro'
            title='Financeiro'
            fontIcon='bi-cash-stack'
            icon='bi-cash-stack'
            fontsizeIcon='18px'
          >
            <SidebarMenuItem
              to='/listagem-contas-a-pagar'
              title='Contas a pagar'
              icon='bi-cash-coin'
              fontsizeIcon='16px'
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/contas-a-receber'
              title='Contas a receber'
              fontIcon='bi-wallet2'
              icon='bi-wallet2'
              fontsizeIcon='16px'
            />
            <SidebarMenuItem
              to='/listagem-grupo-despesas'
              title='Grupo de despesas'
              // hasBullet = {true}
              // fontIcon='bi-archive'
              icon='bi-graph-down-arrow'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItem>
            <SidebarMenuItem
              to='/listagem-aprovadores'
              title='Aprovadores'
              // hasBullet = {true}
              // fontIcon='bi-archive'
              icon='bi-person-check'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItem>
            <SidebarMenuItem
              to='/listagem-bancos'
              title='Bancos'
              // hasBullet = {true}
              // fontIcon='bi-archive'
              icon='bi-bank2'
              fontsizeIcon='16px'
            >
              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItem>
            <SidebarMenuItem
              to='/prazo-de-pagamento'
              title='Prazo de Pagamento'
              // hasBullet = {true}
              // fontIcon='bi-archive'
              icon='bi-hourglass-split'
              fontsizeIcon='16px'
            >
              <SidebarMenuItemWithSub
                to='/Financeiro/Cobranca'
                title='Cobrança'
                fontIcon='bi-safe'
                icon='bi-file-earmark-text-fill'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem
                  to='/cobranca/status-analise'
                  title='Status da Cobrança'
                  hasBullet={true}
                />
                <SidebarMenuItem to='/cobranca/credito' title='Crédito' hasBullet={true} />
                <SidebarMenuItem
                  to='/cobranca/analise-credito'
                  title='Análise de Crédito'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>

              {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItem>

            <SidebarMenuItemWithSub
              to='/Financeiro'
              title='Formas de pagamento'
              fontIcon='bi bi-credit-card'
              // icon='bi-credit-card'
              //   <i class="bi bi-credit-card"></i>

              fontsizeIcon='16px'
            >
              <SidebarMenuItem
                to='/cadastro-formas-de-pagamento'
                title='Adicionar'
                hasBullet={true}
              />
              <SidebarMenuItem to='/listagem-formas-de-pagamento' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/Financeiro'
              title='Condicoes de pagamento'
              fontIcon='bi-safe'
              // icon='bi-list-check'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem
                to='/cadastro-condicoes-de-pagamento'
                title='Adicionar'
                hasBullet={true}
              />
              <SidebarMenuItem
                to='/listagem-condicoes-de-pagamento'
                title='Listar'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sistema/sistema'
            title='Sistema'
            fontIcon='bi-archive'
            icon='bi-gear'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/empresas'
              title='Empresas'
              fontIcon='bi-archive'
              icon='bi-buildings'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-empresas' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-empresas' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/usuarios'
              title='Usuários'
              fontIcon='bi-archive'
              icon='bi-person-lines-fill'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/cadastro-usuarios' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/listagem-usuarios' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/permissoes'
              title='Permissões'
              fontIcon='bi-archive'
              icon='bi-sliders'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/perfis' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/perfis-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/consultor'
              title='Consultor'
              fontIcon='bi-archive'
              icon='bi-person-vcard'
              fontsizeIcon='16px'
            >
              <div className='mb-5'>
                <SidebarMenuItem to='/consultor' title='Adicionar' hasBullet={true} />
              </div>
              {/* <SidebarMenuItem to='/listagem-usuarios' title='Listar' hasBullet={true} /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='/sistema/produto'
              title='Produto'
              fontIcon='bi-archive'
              icon='bi-lamp'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/produto-cadastro' title='Adicionar' hasBullet={true} />
              <SidebarMenuItem to='/produto-listagem' title='Listar' hasBullet={true} />

              <SidebarMenuItemWithSub
                to='/sistema/produto'
                title='Categorização de Produto'
                fontIcon='bi-bookmark'
                icon='bi-bookmark'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem to='/produto-categorizacao' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/produto-categorizacao-listagem'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/logs'
              title='Logs'
              fontIcon='bi-archive'
              icon='bi-file-earmark-text'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/listagem-logs' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Pendências'
              fontIcon='bi-archive'
              icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
              to='/sistema/workflow'
              title='Workflows'
              fontIcon='bi-archive'
              icon='bi-stickies'
              fontsizeIcon='16px'
            >
              <SidebarMenuItemWithSub
                to='/sistema/workflow/aprovacao'
                title='Aprovação'
                hasBullet={true}
              >
                <SidebarMenuItem to='/workflow-aprovacao' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/listagem-workflow-aprovacao'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/sistema/workflow/componente'
                title='Componente'
                hasBullet={true}
              >
                <SidebarMenuItem to='/workflow-componente' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/listagem-workflow-componente'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
              <SidebarMenuItemWithSub
                to='/sistema/workflow/sequencia'
                title='Sequencia'
                hasBullet={true}
              >
                <SidebarMenuItem to='/workflow-sequencia' title='Adicionar' hasBullet={true} />
                <SidebarMenuItem
                  to='/listagem-workflow-sequencia'
                  title='Listar'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/formularios'
              title='Formularios'
              fontIcon='bi'
              icon='bi-journal-text'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/formularios-listagem' title='Listar' hasBullet={true} />
              <SidebarMenuItem to='/formularios-cadastro' title='Adicionar' hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/sistema/configuracoes-rotinas'
              title='Configurações do Sistema'
              fontIcon='bi'
              icon='bi-gear-wide-connected'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem
                to='/configuracoes'
                title='Configuraçoes'
                fontIcon='bi'
                icon='bi-sliders2'
              />

              <SidebarMenuItem
                to='/configuracoes-rotinas-listagem'
                title='Rotinas SQL'
                fontIcon='bi'
                icon='bi-database-fill-gear'
              />
            </SidebarMenuItemWithSub>

            <SidebarMenuItem
              to='/dominios'
              title='Dominios'
              fontIcon='bi'
              icon='bi-globe'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem
              to='/unidade-tempo'
              title='Unidade de Tempo'
              fontIcon='bi'
              icon='bi-clock'
              fontsizeIcon='16px'
            />

            <SidebarMenuItem to={''} title={''} />
            <SidebarMenuItem to={''} title={''} />
            <SidebarMenuItem to={''} title={''} />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem to={''} title={''} />
          <SidebarMenuItem to={''} title={''} />
          <SidebarMenuItem to={''} title={''} />
          <SidebarMenuItem to={''} title={''} />
        </>
      ) : (
        <>
          {hasAnyPermission(['dashboardGerencial']) && (
            <>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    relatórios
                  </span>
                </div>
              </div>

              <SidebarMenuItemWithSub
                to='/relatorios/dashboards'
                title='Dashboards'
                icon='element-7'
                fontIcon='bi-layers'
              >
                <SidebarMenuItemWithSub
                  to='/relatorios/dashboards/comercial'
                  title='Comercial'
                  hasBullet={true}
                >
                  <SidebarMenuItem
                    to='/relatorios/dashboards/comercial/relatorio-vendas'
                    title='Relatório de Vendas'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              </SidebarMenuItemWithSub>
            </>
          )}

          {hasAnyPermission(['estoqueConsulta']) && (
            <>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    Consultas
                  </span>
                </div>
              </div>
              <SidebarMenuItemWithSub
                to='/consultas/estoques'
                title='Estoques'
                icon='bi-box-seam'
                fontIcon='bi-box-seam'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem
                  to='/consultas/estoques/consulta-estoque'
                  title='Estoque (WMS)'
                  hasBullet={true}
                />
              </SidebarMenuItemWithSub>
            </>
          )}

          {hasAnyPermission(['encartePromocional']) && (
            <>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    Campanha Promocional
                  </span>
                </div>
              </div>
              <SidebarMenuItemWithSub
                to='/campanha-promocional/'
                title='Promoções/Encartes'
                icon='bi-percent'
                fontIcon='bi-percent'
                fontsizeIcon='16px'
              >
                <SidebarMenuItem
                  to='/promocao-cadastro'
                  title='Criar nova promoção/encarte'
                  icon='bi-file-earmark-richtext'
                  fontIcon='bi-file-earmark-richtext'
                  fontsizeIcon='16px'
                />
                <SidebarMenuItem
                  to='/promocao-listagem'
                  title='Pesquisar promoção/encarte'
                  icon='bi-search'
                  fontIcon='bi-search'
                  fontsizeIcon='16px'
                />
              </SidebarMenuItemWithSub>
            </>
          )}

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>sistema</span>
            </div>
          </div>

          <SidebarMenuItemWithSub
            to='/sistema/workflow'
            title='Workflow'
            fontIcon='bi-archive'
            icon='bi-inboxes'
            fontsizeIcon='18px'
          >
            <SidebarMenuItemWithSub
              to='/sistema/pendencias'
              title='Pendências'
              fontIcon='bi-archive'
              icon='bi-inboxes'
              fontsizeIcon='16px'
            >
              <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          {hasAnyPermission([
            'clientes',
            'produtos',
            'fornecedores',
            'colaboradores',
            'empresasClientes',
            'parceiros',
            'pedido_compras',
            'logistica',
            'compradores',
            'listagemEmpreendimentos',
            'segmentosEmpreendimento',
            'cadastroEmpreendimentos',
            'classificacaoEmpreendimento',
            'porteEmpreeendimento',
            'tipoDeObra',
            'fasesDaObra',
            'tipoOrigem',
            'dominios',
            'produtosConsumo',
            'garantiaProduto',
            'tipo-garantia-devolucao',
            'status-garantia-devolucao',
          ]) && (
              <SidebarMenuItemWithSub
                to='/sistema/cadastros'
                title='Cadastros'
                fontIcon='bi-plus'
                icon='bi-plus'
                fontsizeIcon='16px'
              >
                {hasAnyPermission(['clientes']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/Clientes'
                    title='Clientes'
                    fontIcon='bi-archive'
                    icon='bi-person-square'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/cadastro-clientes' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem to='/listagem-clientes' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['empresasClientes']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/Clientes'
                    title='Empresas'
                    fontIcon='bi-buildings'
                    icon='bi-buildings'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem
                      to='/cadastro-clientes-empresa'
                      title='Adicionar'
                      hasBullet={true}
                    />
                    <SidebarMenuItem
                      to='/listagem-clientes-empresa'
                      title='Listar'
                      hasBullet={true}
                    />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['produtos']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/produto'
                    title='Produto'
                    fontIcon='bi-archive'
                    icon='bi-lamp'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/produto-cadastro' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem to='/produto-listagem' title='Listar' hasBullet={true} />

                    <SidebarMenuItemWithSub
                      to='/sistema/produto'
                      title='Categorização de Produto'
                      fontIcon='bi-bookmark'
                      icon='bi-bookmark'
                      fontsizeIcon='16px'
                    >
                      <SidebarMenuItem
                        to='/produto-categorizacao'
                        title='Adicionar'
                        hasBullet={true}
                      />
                      <SidebarMenuItem
                        to='/produto-categorizacao-listagem'
                        title='Listar'
                        hasBullet={true}
                      />
                    </SidebarMenuItemWithSub>

                    <SidebarMenuItemWithSub
                      to='/sistema/produto'
                      title='Unidades de Medida'
                      fontIcon='bi-rulers'
                      icon='bi-rulers'
                      fontsizeIcon='16px'
                    >
                      <SidebarMenuItem
                        to='/produto-unidade-medida'
                        title='Adicionar'
                        hasBullet={true}
                      />
                      <SidebarMenuItem
                        to='/produto-unidade-medida-listagem'
                        title='Listar'
                        hasBullet={true}
                      />
                    </SidebarMenuItemWithSub>

                    <SidebarMenuItem
                      to='/sistema/linha-produto'
                      title='Linha de Produto'
                      fontIcon='bi-grid-1x2'
                      icon='bi-grid-1x2'
                      fontsizeIcon='16px'
                    />

                    <SidebarMenuItem
                      to='/garantia-produto'
                      title='Garantia do Produto'
                      fontIcon='bi'
                      icon='bi-globe'
                      fontsizeIcon='16px'
                    />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['produtosConsumo']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/produto'
                    title='Produto - Consumo'
                    fontIcon='bi-archive'
                    icon='bi-lamp'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem
                      to='/produto-consumo-cadastro'
                      title='Adicionar'
                      hasBullet={true}
                    />
                    <SidebarMenuItem to='/produto-consumo-listagem' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['fornecedores']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/fornecedor'
                    title='Fornecedor'
                    fontIcon='bi-person'
                    icon='bi-person'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/cadastro-fornecedor' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem to='/listagem-fornecedor' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['colaboradores']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/colaborador'
                    title='Colaboradores'
                    fontIcon='bi-archive'
                    icon='bi-person-square'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/cadastro-colaborador' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem to='/listagem-colaborador' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['parceiros']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/Parceiros'
                    title='Profissionais'
                    fontIcon='bi-archive'
                    icon='bi-people'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/cadastro-parceiros' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem to='/listagem-parceiros' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['pedido_compras', 'logistica']) && (
                  <SidebarMenuItemWithSub
                    to='/sistema/logistica/endereco'
                    title='Endereço'
                    fontIcon='bi-geo-alt'
                    icon='bi-geo-alt'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem to='/logistica-endereco' title='Adicionar' hasBullet={true} />
                    <SidebarMenuItem
                      to='/logistica-endereco-listagem'
                      title='Listar'
                      hasBullet={true}
                    />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission([
                  'listagemEmpreendimentos',
                  'segmentosEmpreendimento',
                  'cadastroEmpreendimentos',
                  'classificacaoEmpreendimento',
                  'porteEmpreeendimento',
                  'tipoDeObra',
                  'fasesDaObra',
                ]) && (
                    <SidebarMenuItemWithSub
                      title='Empreendimentos'
                      icon='bi-building'
                      fontIcon='bi-building'
                      to='/segmentos-emprendimentos'
                    >
                      {hasAnyPermission(['listagemEmpreendimentos', 'cadastroEmpreendimentos']) && (
                        <SidebarMenuItemWithSub
                          to='/empreendimentos'
                          title='Empreendimentos'
                          // hasBullet={true}
                          fontIcon='bi-building'
                          icon='bi-building'
                          fontsizeIcon='16px'
                        >
                          {hasAnyPermission(['listagemEmpreendimentos']) && (
                            <SidebarMenuItem to='/empreendimentos' title='Listar' hasBullet={true} />
                          )}
                          {hasAnyPermission(['cadastroEmpreendimentos']) && (
                            <SidebarMenuItem
                              to='/empreendimentos-cadastro'
                              title='Cadastrar'
                              hasBullet={true}
                            />
                          )}
                        </SidebarMenuItemWithSub>
                      )}

                      {hasAnyPermission(['segmentosEmpreendimento']) && (
                        <SidebarMenuItem
                          to='/segmentos-empreendimentos'
                          title='Segmentos do Empreendimento'
                          // hasBullet={true}
                          fontIcon='bi-bank'
                          icon='bi-house-door'
                          fontsizeIcon='16px'
                        >
                          {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
                        </SidebarMenuItem>
                      )}

                      {hasAnyPermission(['classificacaoEmpreendimento']) && (
                        <SidebarMenuItem
                          to='/classificacao-empreendimentos'
                          title='Classificação dos Empreendimentos'
                          // hasBullet={true}
                          fontIcon='arrow-up-down'
                          icon='arrow-up-down'
                          fontsizeIcon='16px'
                        >
                          {/* <SidebarMenuItem to='/workflow-pendencia-listagem' title='Listar' hasBullet={true} /> */}
                        </SidebarMenuItem>
                      )}

                      {hasAnyPermission(['porteEmpreeendimento']) && (
                        <SidebarMenuItem
                          to='/porte-empreendimentos'
                          title='Porte do Empreendimentos'
                          // hasBullet={true}
                          fontIcon='bi-bar-chart'
                          icon='bi-bar-chart'
                          fontsizeIcon='16px'
                        ></SidebarMenuItem>
                      )}

                      {hasAnyPermission(['tipoDeObra']) && (
                        <SidebarMenuItem
                          to='/tipo-de-obra'
                          title='Tipo de Obra'
                          // hasBullet={true}
                          fontIcon='bi-tags'
                          // icon='bi-bar-chart'
                          icon='bi-bricks'
                          fontsizeIcon='14px'
                        ></SidebarMenuItem>
                      )}

                      {hasAnyPermission(['fasesDaObra']) && (
                        <SidebarMenuItem
                          to='/fases-da-obra'
                          title='Fases da Obra'
                          // hasBullet={true}
                          fontIcon='bi-tags'
                          // icon='bi-bar-chart'
                          icon='bi-hourglass'
                          fontsizeIcon='16px'
                        ></SidebarMenuItem>
                      )}
                    </SidebarMenuItemWithSub>
                  )}

                {hasAnyPermission(['compradores']) && (
                  <SidebarMenuItem
                    to='/compradores'
                    title='Compradores'
                    fontIcon='bi'
                    icon='bi-cart'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['tipoOrigem']) && (
                  <SidebarMenuItem
                    to='/tipo-origem'
                    title='Tipo de Origem'
                    fontIcon='bi'
                    icon='bi-ui-checks'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['status-garantia-devolucao']) && (
                  <SidebarMenuItem
                    to='/status-garantia'
                    title='Status de Garantia'
                    fontIcon='bi'
                    icon='bi-shield-check'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['tipo-garantia-devolucao']) && (
                  <SidebarMenuItem
                    to='/tipo-garantia'
                    title='Tipo de Garantia'
                    fontIcon='bi'
                    icon='bi-shield-shaded'
                    fontsizeIcon='16px'
                  />
                )}
              </SidebarMenuItemWithSub>
            )}

          {hasAnyPermission(['preVendas', 'parceiros']) && (
            <SidebarMenuItemWithSub
              to='/sistema/cadastros'
              title='Vendas'
              fontIcon='bi-archive'
              icon='bi-cart2'
              fontsizeIcon='18px'
            >
              {hasAnyPermission(['preVendas']) && (
                <>
                  <SidebarMenuItemWithSub
                    to='/sistema/PropostasComerciais'
                    title='Propostas Comerciais'
                    fontIcon='bi-archive'
                    icon='bi-file-earmark-text'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem
                      to='/propostas-comerciais'
                      title='Adicionar'
                      hasBullet={true}
                    />
                    <SidebarMenuItem
                      to='/listagem-propostas-comerciais'
                      title='Listar'
                      hasBullet={true}
                    />
                  </SidebarMenuItemWithSub>

                  <SidebarMenuItemWithSub
                    to='/sistema/pedido'
                    title='Pedidos'
                    fontIcon='bi-archive'
                    icon='bi-file-earmark-text'
                    fontsizeIcon='16px'
                  >
                    {/* <SidebarMenuItem to='/pedidos' title='Adicionar' hasBullet={true} /> */}
                    <SidebarMenuItem to='/listagem-pedidos' title='Listar' hasBullet={true} />
                  </SidebarMenuItemWithSub>
                </>
              )}

              {hasAnyPermission(['parceiros']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/Parceiros'
                  title='Profissional'
                  fontIcon='bi-archive'
                  icon='bi-people'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/cadastro-parceiros' title='Adicionar' hasBullet={true} />
                  <SidebarMenuItem to='/listagem-parceiros' title='Listar' hasBullet={true} />
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['pedido_compras', 'pendencia_venda']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/venda'
                  title='Tipos de Venda'
                  fontIcon='bi-coin'
                  icon='bi-coin'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/cadastro-tipo-venda' title='Adicionar' hasBullet={true} />
                  <SidebarMenuItem to='/listagem-tipo-venda' title='Listar' hasBullet={true} />
                </SidebarMenuItemWithSub>
              )}
            </SidebarMenuItemWithSub>
          )}

          {hasAnyPermission(['logisticaExpedicao', 'logisticaFluxo', 'separacaoLogistica']) && (
            <SidebarMenuItemWithSub
              to='/sistema/logistica'
              title='Logistica'
              fontIcon='bi-box2'
              icon='bi-box2'
              fontsizeIcon='16px'
            >
              {hasAnyPermission(['logisticaExpedicao']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/logistica/expedicao'
                  title='Expedição'
                  fontIcon='bi-airplane'
                  icon='bi-airplane'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem
                    to='/logistica-expedicao-listagem'
                    title='Listar'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['logisticaExpedicao']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/logistica/expedicao'
                  title='Expedição Sysled'
                  fontIcon='bi-airplane'
                  icon='bi-airplane'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem
                    to='/logistica-expedicao-sysled-listagem'
                    title='Listar'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['separacaoLogistica']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/logistica/expedicao'
                  title='Separação Logistica'
                  fontIcon='bi-arrows-fullscreen'
                  icon='bi-arrows-fullscreen'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/logistica-separacao' title='Adicionar' hasBullet={true} />
                  <SidebarMenuItem
                    to='/logistica-separacao-listagem'
                    title='Listar'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['logisticaFluxo']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/logistica/FluxoLogistico'
                  title='Fluxo Logistico'
                  fontIcon='bi-columns-gap'
                  icon='bi-columns-gap'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/logistica-fluxo-listagem' title='Listar' hasBullet={true} />
                  <SidebarMenuItem
                    to='/logistica-fluxo-cadastro'
                    title='Adicionar'
                    hasBullet={true}
                  />
                </SidebarMenuItemWithSub>
              )}
            </SidebarMenuItemWithSub>
          )}

          {hasAnyPermission([
            'estoques',
            'estoqueTipos',
            'estoqueLocalizacao',
            'tiposLocalizacao',
            'estoqueTransferencia',
            'estoqueInventario',
            'inventarioMotivo',
            'estoqueEntrada',
            'tiposMovimento',
            'tiposCustosProjeto',
          ]) && (
              <SidebarMenuItemWithSub
                to='/consultas/estoques'
                title='Estoques'
                icon='bi-box-seam'
                fontIcon='bi-box-seam'
                fontsizeIcon='16px'
              >
                {hasAnyPermission(['estoques']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoques'
                    title='Estoques'
                    icon='bi-box-seam'
                    fontIcon='bi-box-seam'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['estoqueTipos']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-tipos'
                    title='Tipos de Estoque '
                    icon='bi-collection'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['estoqueLocalizacao']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-localizacao'
                    title='Estoque Localização'
                    fontsizeIcon='16px'
                    icon='bi-geo-alt'
                  />
                )}

                {hasAnyPermission(['tiposLocalizacao']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-tipo-localizacao'
                    title='Tipos de Localização'
                    fontsizeIcon='16px'
                    // hasBullet={true}
                    icon='bi-pin-map'
                  />
                )}

                {hasAnyPermission(['estoqueTransferencia']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-transferencias'
                    title='Estoque Transferência'
                    fontsizeIcon='16px'
                    icon='bi-arrow-left-right'
                  // fontIcon='bi-box-seam'
                  // fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['estoqueInventario']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-inventario'
                    title='Estoque Inventário'
                    // hasBullet={true}
                    icon='bi-archive'
                    // fontIcon='bi-box-seam'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['inventarioMotivo']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-motivo-inventario'
                    title='Estoque Inventário Motivo'
                    icon='bi-journal-text'
                    // fontIcon='bi-box-seam'
                    // fontsizeIcon='16px'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['estoqueEntrada']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-entradas'
                    title='Estoque Entradas'
                    icon='bi-clipboard-plus'
                    fontsizeIcon='16px'
                  />
                )}

                {hasAnyPermission(['tiposMovimento']) && (
                  <SidebarMenuItem
                    to='/consultas/estoques/listagem-estoque-tipo-movimento'
                    title='Tipos de Movimentos'
                    icon='bi-shuffle'
                    // fontIcon='bi-box-seam'
                    fontsizeIcon='16px'
                  />
                )}
              </SidebarMenuItemWithSub>
            )}

          {hasAnyPermission([
            'projetos',
            'emprendimentos',
            'statusProjeto',
            'statusPagamento',
            'faturaProjeto',
            'orcamentoProjeto',
            'custosProjeto',
            'tiposCustosProjeto',
            'arquivosProjetos',
          ]) && (
              <SidebarMenuItemWithSub
                to='/sistema/workflow'
                title='Projetos'
                fontIcon='bi-houses'
                icon='bi-houses'
                fontsizeIcon='18px'
              >
                <SidebarMenuItemWithSub
                  title='Parametros'
                  to='/parametros'
                  fontsizeIcon='16px'
                  icon='bi-houses'
                >
                  {hasAnyPermission(['projetos']) && (
                    <SidebarMenuItem
                      to='/projetos'
                      title='Projetos'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-rocket'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['statusProjeto']) && (
                    <SidebarMenuItem
                      to='status-do-projeto'
                      title='Status do Projeto'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-clipboard-check'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['statusPagamento']) && (
                    <SidebarMenuItem
                      to='status-pagamento'
                      title='Status do Pagamento do Financeiro'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-clipboard-check'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['faturaProjeto']) && (
                    <SidebarMenuItem
                      to='fatura-do-projeto'
                      title='Fatura do Projeto'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-receipt'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['orcamentoProjeto']) && (
                    <SidebarMenuItem
                      to='orcamentos-do-projeto'
                      title='Orçamentos dos Projetos'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-pencil-square'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['custosProjeto']) && (
                    <SidebarMenuItem
                      to='custos-do-projeto'
                      title='Custos dos Projetos'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-cash'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['tiposCustosProjeto']) && (
                    <SidebarMenuItem
                      to='tipos-de-custo'
                      title='Tipos de Custos dos Projetos'
                      fontsizeIcon='16px'
                      // hasBullet
                      icon='bi-tags'
                    ></SidebarMenuItem>
                  )}
                </SidebarMenuItemWithSub>
              </SidebarMenuItemWithSub>
            )}

          {hasAnyPermission([
            'financeiro',
            'contasPagar',
            'contasReceber',
            'grupoDespesas',
            'aprovadores',
            'bancos',
            'cobranca',
            'prazoPagamento',
            'credito',
            'pagamento',
            'status-analise',
            'analise-credito',
          ]) && (
              <SidebarMenuItemWithSub
                to='/sistema/workflow'
                title='Financeiro'
                fontIcon='bi-cash-stack'
                icon='bi-cash-stack'
                fontsizeIcon='18px'
              >
                <>
                  {hasAnyPermission(['contasPagar']) && (
                    <SidebarMenuItem
                      to='/contas-a-pagar'
                      title='Contas a pagar'
                      // fontIcon='bi-archive'
                      icon='bi-cash-coin'
                      fontsizeIcon='16px'
                    ></SidebarMenuItem>
                  )}
                  {/* {hasAnyPermission(['contas_a_receber']) && (
                  <SidebarMenuItem
                    to='/sistema/pendencias'
                    title='Contas a receber'
                    // fontIcon='bi-archive'
                    // icon='bi-inboxes'
                    hasBullet = {true}
                    fontsizeIcon='16px'
                  >
                  </SidebarMenuItem>
                )} */}
                  {hasAnyPermission(['contasReceber']) && (
                    <SidebarMenuItem
                      to='/contas-a-receber'
                      title='Contas a receber'
                      fontIcon='bi-wallet2'
                      icon='bi-wallet2'
                      fontsizeIcon='16px'
                    />
                  )}
                  {hasAnyPermission(['grupoDespesas']) && (
                    <SidebarMenuItem
                      to='/listagem-grupo-despesas'
                      title='Grupo de despesas'
                      // fontIcon='bi-archive'
                      icon='bi-graph-down-arrow'
                      // hasBullet = {true}
                      fontsizeIcon='16px'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['aprovadores']) && (
                    <SidebarMenuItem
                      to='/listagem-aprovadores'
                      title='Aprovadores'
                      // fontIcon='bi-archive'
                      // icon='bi-inboxes'
                      hasBullet={true}
                      fontsizeIcon='16px'
                    ></SidebarMenuItem>
                  )}
                  {hasAnyPermission(['bancos']) && (
                    <SidebarMenuItem
                      to='/listagem-bancos'
                      title='Bancos'
                      // fontIcon='bi-archive'
                      icon='bi-bank2'
                      // hasBullet = {true}
                      fontsizeIcon='16px'
                    ></SidebarMenuItem>
                  )}
                </>

                {hasAnyPermission(['cobranca', 'credito', 'status-analise', 'analise-credito']) && (
                  <SidebarMenuItemWithSub
                    to='/Financeiro/Cobranca'
                    title='Cobrança'
                    fontIcon='bi-safe'
                    icon='bi-file-earmark-text-fill'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem
                      to='/cobranca/status-analise'
                      title='Status da Cobrança'
                      hasBullet={true}
                    />
                    <SidebarMenuItem to='/cobranca/credito' title='Crédito' hasBullet={true} />
                    <SidebarMenuItem
                      to='/cobranca/analise-credito'
                      title='Análise de Crédito'
                      hasBullet={true}
                    />
                  </SidebarMenuItemWithSub>
                )}

                {hasAnyPermission(['pagamento']) && (
                  <>
                    <SidebarMenuItemWithSub
                      to='/Financeiro'
                      title='Formas de pagamento'
                      // fontIcon='bi-archive'
                      // icon='bi-inboxes'
                      fontsizeIcon='16px'
                    >
                      <SidebarMenuItem
                        to='/cadastro-formas-de-pagamento'
                        title='Adicionar'
                        hasBullet={true}
                      />
                      <SidebarMenuItem
                        to='/listagem-formas-de-pagamento'
                        title='Listar'
                        hasBullet={true}
                      />
                    </SidebarMenuItemWithSub>

                    <SidebarMenuItemWithSub
                      to='/Financeiro'
                      title='Condicoes de pagamento'
                      // fontIcon='bi-archive'
                      // icon='bi-inboxes'
                      fontsizeIcon='16px'
                    >
                      <SidebarMenuItem
                        to='/cadastro-condicoes-de-pagamento'
                        title='Adicionar'
                        hasBullet={true}
                      />
                      <SidebarMenuItem
                        to='/listagem-condicoes-de-pagamento'
                        title='Listar'
                        hasBullet={true}
                      />
                    </SidebarMenuItemWithSub>
                  </>
                )}

                {hasAnyPermission(['prazoPagamento']) && (
                  <SidebarMenuItem
                    to='/prazo-de-pagamento'
                    title='Prazo de Pagamento'
                    // hasBullet = {true}
                    // fontIcon='bi-archive'
                    icon='bi-hourglass-split'
                    fontsizeIcon='16px'
                  />
                )}
              </SidebarMenuItemWithSub>
            )}

          {hasAnyPermission(['produtos', 'pedido_compras', 'pendencia_venda', 'marcas']) && (
            <SidebarMenuItemWithSub
              to='/sistema/workflow'
              title='Suprimentos'
              fontIcon='bi-boxes'
              icon='bi-boxes'
              fontsizeIcon='18px'
            >
              {hasAnyPermission(['pedido_compras']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/ordem-de-compra'
                  title='Pedido de Compra'
                  fontIcon='bi-bag'
                  icon='bi-bag'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/pedido-compras' title='Adicionar' hasBullet={true} />
                  <SidebarMenuItem to='/pedido-compras-listagem' title='Listar' hasBullet={true} />

                  {hasAnyPermission(['pedido_compras']) && (
                    <SidebarMenuItemWithSub
                      to='/sistema/logistica/expedicao'
                      title='Status de Pedido de Compra'
                      fontIcon='bi-archive'
                      icon='bi-archive'
                      fontsizeIcon='16px'
                    >
                      <SidebarMenuItem
                        to='/suprimentos-cadastro'
                        title='Adicionar'
                        hasBullet={true}
                      />
                      <SidebarMenuItem to='/suprimentos-listagem' title='Listar' hasBullet={true} />
                    </SidebarMenuItemWithSub>
                  )}
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['pendencia_venda']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/venda'
                  title='Pendência de Compra'
                  fontIcon='bi-cart-x'
                  icon='bi-cart-x'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem
                    to='/listagem-pendencia-compra'
                    title='Listar'
                    hasBullet={true}
                  />

                  <SidebarMenuItemWithSub
                    to='/sistema/venda'
                    title='Adicionar Pendência de Compra'
                    fontIcon='bi-plus'
                    icon='bi-plus'
                    fontsizeIcon='16px'
                  >
                    <SidebarMenuItem
                      to='/pendencia-compra'
                      title='Pendência de Compra'
                      hasBullet={true}
                    />
                  </SidebarMenuItemWithSub>
                </SidebarMenuItemWithSub>
              )}

              {hasAnyPermission(['marcas', 'pedido_compras']) && (
                <SidebarMenuItemWithSub
                  to='/sistema/marca'
                  title='Marcas'
                  fontIcon=' bi-amd'
                  icon='bi-amd'
                  fontsizeIcon='16px'
                >
                  <SidebarMenuItem to='/sistema/marcas' title='Marcas' hasBullet={true} />
                </SidebarMenuItemWithSub>
              )}
            </SidebarMenuItemWithSub>
          )}
        </>
      )}
    </>
  );
};

export { SidebarMenuMain };
