import {Badge, Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  CrudHeader,
  ICrudBase,
  IUnidadeTempo,
  smoothScrollToBottom,
  TableListagem,
  useModal,
} from '../../../shared';
import ButtonEditListagem from '../../empreendimento/segmento-empreendimento/components/ButtonEditListagem';
import ModalUnidadeTempo from './components/ModalUnidadeTempo';
// import ModalUnidadeTempo from "./components/ModalUnidadeTempo";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const TipoDeObra = () => {
  // VARIAVEIS
  const [unidadeTempo, setUnidadeTempo] = useState<IUnidadeTempo[]>([]);
  const [dataLine, setDataLine] = useState<IUnidadeTempo[]>([]);

  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [observacao, setObservacao] = useState<string | null>(null);
  const [segundos, setSegundos] = useState<number>(0);
  const [idUnidade, setIdUnidade] = useState<number>(0);
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const colums = [
    'Id',
    'Descrição',
    'Segundos',
    'Observação',
    'Criado por',
    'Data de criação',
    'Alterado por',
    'Data de alteração',
    'Status',
    'Editar',
  ];

  const [isUnidadeTempoModal, openUnidadeTempoModal, closeUnidadeTempoModal] = useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };
  const fetchUnidadeTempo = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-tempo/listar`,
        {
          params: {
            status: status,
            observacao: observacao ? observacao : null,
            descricao: descricao ? descricao : null,
            segundos: segundos ? segundos : null,
            page: page,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
        }
        setUnidadeTempo((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Unidade de tempo para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      } else {
        const {data} = error.response;
        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };

  const clearFiltros = () => {
    setObservacao(null);
    setSegundos(0);
    setIdUnidade(0);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    setIsClear(true);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchUnidadeTempo(0);
  };

  const openModal = (isEdit = false, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openUnidadeTempoModal();
  };

  useEffect(() => {
    if (isClear) {
      fetchUnidadeTempo(0);
    }
    setIsClear(false);
  }, [isClear]);

  useEffect(() => {
    fetchUnidadeTempo(page);
  }, [page]);

  return (
    <div>
      <CrudHeader
        title='Unidade de Tempo'
        titleCadastro='Unidade de Tempo'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        isLoading={isLoading}
        openModal={openModal}
      >
        <Row className='row'>
          <Col className='col-md-6 col-12 mt-4 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <input
              type='text'
              className='form-control'
              value={descricao ? descricao : ''}
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
              placeholder='Digite a descrição'
            />
          </Col>
          <Col className='col-md-6 col-12 mt-4 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Segundos:
            </label>
            <input
              type='text'
              className='form-control'
              value={segundos ? segundos : ''}
              onChange={(event) => {
                // setsegundos(e.target.value);
                const rawValue = event.target.value;
                const cleanedValue = rawValue.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
                setSegundos(Number(cleanedValue));
              }}
              placeholder='Digite a quantidade de segundos'
            />
          </Col>
          <Col className='col-md-6 mt-6'>
            <label htmlFor='' className='form-label '>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
        <Row className='row'>
          <label htmlFor='' className='form-label'>
            Observação:
          </label>
          <Col className=''>
            <textarea
              name=''
              value={observacao ? observacao : ''}
              onChange={(e) => {
                setObservacao(e.target.value);
              }}
              placeholder='Digite a observação:'
              className='form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
      </CrudHeader>

      <TableListagem isLoading={isLoading} coluns={colums} hasMore={hasMore} loadMore={loadMore}>
        {unidadeTempo?.map((unidade) => (
          <tr className='text-center'>
            <td>{unidade.id}</td>
            <td>{unidade.descricao ? unidade.descricao : '...'}</td>
            <td>{unidade.segundos}</td>
            <td>{unidade.observacao ? unidade.observacao : '...'}</td>
            <td>{unidade.usuarioCriacaoNome}</td>
            <td>{unidade.dataCriacao}</td>
            <td>{unidade.usuarioAlteracaoNome ? unidade.usuarioAlteracaoNome : '...'}</td>
            <td>{unidade.dataAlteracao}</td>
            <td>
              <Badge
                className='text-white p-3 px-4 '
                bg={`${unidade.status === 'ATIVO' ? 'success' : 'danger'}`}
              >
                {unidade.status}
              </Badge>
            </td>
            <td>
              <ButtonEditListagem
                handleEdit={() => {
                  openModal(true, unidade.id);
                }}
              ></ButtonEditListagem>
            </td>
          </tr>
        ))}
      </TableListagem>

      <ModalUnidadeTempo
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchUnidadeTempo}
        isEdit={isEdit}
        isOpen={isUnidadeTempoModal}
        onHide={closeUnidadeTempoModal}
      ></ModalUnidadeTempo>
    </div>
  );
};

export default TipoDeObra;
