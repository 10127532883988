import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {IEndereco, ISelectOption} from '../../domain-types';
import './async-select-endereco-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
};

const AsyncSelectEndereco: React.FC<IProps> = ({handleOptions, inputOption, isDisabled}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectAsyncEndereco, setSelectAsyncEndereco] = useState<ISelectOption | null>(null);
  const [enderecoDetails, setEnderecoDetails] = useState<IEndereco[]>();

  const loadOptionsEndereco = async (inputValue: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos/buscarEndereco?busca=${inputValue}`;

      const response = await axios.get(url);
      const {data} = await response;

      console.log(response);

      setEnderecoDetails(data);

      return data.map((item) => ({
        value: item.id,
        label: '',
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const loadEnderecoById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setEnderecoDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções do Fornecedor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const endereco = enderecoDetails?.find((endereco) => endereco.id === Number(option.value));

    if (endereco) {
      return (
        <div className='container-message-endereco'>
          <div className='box-left-endereco'>
            <div className='info-container-endereco'>
              <p>
                UF: <span>{endereco.uf ? endereco.uf : '-'}</span>
              </p>

              <p>
                Cidade: <span>{endereco.cidade ? endereco.cidade : '-'}</span>
              </p>

              <p>
                Bairro:{' '}
                <span>
                  {endereco.bairro
                    ? endereco.bairro.length > 30
                      ? `${endereco.bairro.slice(0, 30)} ...`
                      : endereco.bairro
                    : ' - '}
                </span>
              </p>
              <p>
                Rua:{' '}
                <span>{` ${endereco.rua}${endereco.numero ? `, ${endereco.numero}` : ''} ${
                  endereco.complemento ? `- ${endereco.complemento}` : ''
                }  `}</span>
              </p>
            </div>
          </div>
          <div className='box-right-endereco'>
            <p>
              {' '}
              CEP: <span> {endereco.cep ? endereco.cep : '-'} </span>{' '}
            </p>
            <p>
              {' '}
              Referência: <span> {endereco.referencia ? endereco.referencia : '-'} </span>{' '}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectAsyncEndereco && handleOptions) {
      handleOptions(selectAsyncEndereco);
    }
  }, [selectAsyncEndereco]);

  useEffect(() => {
    if (inputOption) {
      loadEnderecoById(inputOption.value);
      setSelectAsyncEndereco(inputOption);
    } else if (inputOption == null) {
      setSelectAsyncEndereco(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Endereço'
      loadOptions={loadOptionsEndereco}
      value={selectAsyncEndereco}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectAsyncEndereco(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
    />
  );
};

export default AsyncSelectEndereco;
