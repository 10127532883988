import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { AsyncSelectProductByIdSysled, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { set } from "date-fns";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalInventarioProduto = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros,id }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)

    const [selectedLocalizacao, setSelectedLocalizacao] = useState<ClienteOption | null>(null)
    const [dataMovimento, setDataMovimento] = useState<string | null>(null)
    const [quantidadeDisponivel, setQuantidadeDisponivel] = useState<number | null>(null)
    const [quantidadeReservada, setQuantidaReservada] = useState<number | null>(null)
    const [quantidadeProcesso, setQuantidadeProcesso] = useState<number | null>(null)

    const cadastrarProdutoInventraio = async () => {
        try {
            if(selectedProduto  && selectedLocalizacao && dataMovimento && quantidadeDisponivel && quantidadeProcesso && quantidadeReservada){
                
            
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-itens`;

            const response = await axios.post(url, {
                idProduto: selectedProduto ? selectedProduto.value : null,
                idInventario: id,
                idLocalizacao: selectedLocalizacao ? selectedLocalizacao.value : null,
                quantidadeDisponivel: quantidadeDisponivel ? quantidadeDisponivel : null,
                quantidadeProcesso: quantidadeProcesso ? quantidadeProcesso : null,
                quantidadeReservado: quantidadeReservada ? quantidadeReservada : null,
                dataMovimento: dataMovimento ? `${dataMovimento}T21:14:41.462Z` : null,
                status: status
            });

            Swal.fire({
                icon: 'success',
                title: `Produto do Inventario cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                onClose()
            })
        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const editarProdutoInventraio = async () => {
        try {
            if(selectedProduto  && selectedLocalizacao && dataMovimento && quantidadeDisponivel && quantidadeProcesso && quantidadeReservada){

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-itens/${dataLine[0]}`;

            const response = await axios.put(url, {
                idProduto: selectedProduto ? selectedProduto.value : null,
                idLocalizacao: selectedLocalizacao ? selectedLocalizacao.value : null,
                quantidadeDisponivel: quantidadeDisponivel ? quantidadeDisponivel : null,
                quantidadeProcesso: quantidadeProcesso ? quantidadeProcesso : null,
                quantidadeReservado: quantidadeReservada ? quantidadeReservada : null,
                dataMovimento: dataMovimento ? `${dataMovimento}T21:14:41.462Z` : null,
                status: status,
                idInventario: id
            });
            Swal.fire({
                icon: 'success',
                title: `Produto do Inventario editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()
            });

        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const onClose = () => {
        clearForm()
        clearFiltros()
        reload!()
        onHide!();
    }
    const loadOptionsLocalizacao = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedProduto(null)
        setSelectedLocalizacao(null)
        setQuantidadeDisponivel(null)
        setQuantidadeProcesso(null)
        setQuantidaReservada(null)
        setDataMovimento(null)
    }
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarProdutoInventraio() : cadastrarProdutoInventraio()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        // Converte o inputDate (que pode ser no formato ISO ou no formato 'yyyy-mm-dd') para um objeto Date
        const date = new Date(inputDate);
      
        // Verifica se a data é válida
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        // Obtém o ano, mês e dia
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é zero-indexed, por isso somamos 1
        const day = date.getDate().toString().padStart(2, '0'); // Garante que o dia tenha 2 dígitos
      
        // Retorna a data no formato 'yyyy-mm-dd'
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        if (isEdit) {
            setSelectedProduto({
                value: dataLine[1],
                label: dataLine[1],
            })
            setSelectedLocalizacao({
                value: dataLine[3],
                label: dataLine[4],
            })
            setQuantidadeDisponivel(dataLine[5])
            setQuantidadeProcesso(dataLine[7])            
            setQuantidaReservada(dataLine[6])
            setDataMovimento(formatDate(dataLine[8]))
            setStatus(dataLine[9])
        }
    }, [dataLine])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Produto do Inventario
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form action="" >
                <Row className="mt-9" >
                        <Col>
                            <label htmlFor="" className="form-label">Produto:</label>
                            <AsyncSelectProductByIdSysled onKeyDown={()=>{setSelectedProduto(null)}} inputOption={selectedProduto} handleOptions={(e) => { setSelectedProduto(e) }}></AsyncSelectProductByIdSysled>
                            {( selectedProduto === null ) && <span className={` form-label text-danger`}>*Por favor, informe o Produto</span>}
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Col>
                            <label htmlFor="" className="form-label"  >  Inventario:  </label>
                            <input type="text" className="form-control" disabled value={id} />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Localização:</label>
                            <AsyncSelect
                                defaultOptions
                            className="my-auto py-1"
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    setSelectedLocalizacao(null);
                                }
                            }} value={selectedLocalizacao} placeholder="Selecione a Localização" onChange={(e) => { setSelectedLocalizacao(e) }} loadOptions={loadOptionsLocalizacao}></AsyncSelect>
                            {( selectedLocalizacao === null ) && <span className={` form-label text-danger`}>*Por favor, informe a Localização</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade Disponível:</label>
                            <input value={quantidadeDisponivel ? quantidadeDisponivel : ""} onChange={(e) => { setQuantidadeDisponivel(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                            {( quantidadeDisponivel === null || quantidadeDisponivel === 0 ) && <span className={` form-label text-danger`}>*Por favor, informe a Quantidade disponível</span>}
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Quantidade Reservada :</label>
                            <input value={quantidadeReservada ? quantidadeReservada : ""} onChange={(e) => {setQuantidaReservada(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                            {( quantidadeReservada === null || quantidadeReservada === 0 ) && <span className={` form-label text-danger`}>*Por favor, informe a Quantidade Reservada</span>}

                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col className="    ">
                            <label htmlFor="" className="form-label"  >Quantidade Processo:</label>
                            <input value={quantidadeProcesso ? quantidadeProcesso : ""} onChange={(e) => { setQuantidadeProcesso(Number(e.target.value)) }} className="form-control" type="number" placeholder="0" />
                            {( quantidadeProcesso === null || quantidadeProcesso === 0) && <span className={` form-label text-danger`}>*Por favor, informe a Quantidade Processo</span>}
                        </Col>
                        <Col className="">
                            <label htmlFor="" className="form-label"  >Data do Movimento:</label>
                            <input value={dataMovimento ? dataMovimento : ""} onChange={(e) => { setDataMovimento((e.target.value)) }} className="form-control" type="date" placeholder="Digite a quantidade" />
                            {( dataMovimento === null || dataMovimento === "" ) && <span className={` form-label text-danger`}>*Por favor, informe a Data do Movimento</span>}
                        </Col>
                    </Row>
                    <Row>
                    <Col className="col-6">
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}
                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                </form>
            </div>
        </Modal >
    )
}

export default ModalInventarioProduto