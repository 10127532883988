import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {ISelectOption} from '../../domain-types';
import './async-select-marcas-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectMarcas: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isDisabled,
  onKeyDown,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectMarcaAsync, setSelectMarcaAsync] = useState<ISelectOption | null>(null);
  const [marcaDetails, setMarcaDetails] = useState<any[]>();

  const loadOptionsMarcasById = async (id: number | string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/marcas/${id}`;

      const response = await axios.get(url);
      const data = await response.data;

      setMarcaDetails([data]);

      return [
        {
          value: data.id,
          label: '',
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const marca = marcaDetails?.find((marca) => Number(marca.id) === Number(option.value));

    if (marca) {
      return (
        <div className='container-message-marca'>
          <div className='info-container-marca'>
            <p>
              ID: <span>{marca.id ? marca.id : '-'}</span>
            </p>

            <p>
              Marca: <span>{marca.marca ? marca.marca : ' - '}</span>
            </p>
            <p>
              STATUS: <span>{marca.status ? marca.status : '-'}</span>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectMarcaAsync && handleOptions) {
      handleOptions(selectMarcaAsync);
    }
  }, [selectMarcaAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsMarcasById(inputOption.value);
      setSelectMarcaAsync(inputOption);
    } else if (inputOption == null) {
      setSelectMarcaAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise a Marca'
      loadOptions={loadOptionsMarcasById}
      value={selectMarcaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectMarcaAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectMarcas;
