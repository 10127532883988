import {FiFileText} from 'react-icons/fi';
import {useEffect, useState} from 'react';
import {Badge, Button, ModalProps, Spinner} from 'react-bootstrap';
import {base64Prefixes, FormUploadFiles, Modal} from '../../../../shared';
import axios from 'axios';
import Swal from 'sweetalert2';
import FormArquivoUpload from '../../../../shared/components/form-upload-pdf/FormArquivoUpload';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalAnexarPDf = ({ reload, isOpen, onHide, idProjeto }) => {
  const [isOk, setIsOk] = useState<boolean>(false);
  const [isLoadingGerarPedido, setIsLoadingGerarPedido] = useState<boolean>(false);
  const [projeto, setProjeto] = useState({});
  const [arquivo, setArquivo] = useState<string>('');

  const onClose = () => {
    onHide!();
  };
  const anexarPdf = async () => {
    if (isOk) {
      try {
        setIsLoadingGerarPedido(true);
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${idProjeto}/upload-pdf`;
        const response = await axios.post(url, {
          base64: arquivo,
        });
        Swal.fire({
          title: 'Pdf Anexado com sucesso!',
          // text: 'Seu pdf foi anexado com sucesso!',
          icon: 'success',
          timer: 3000,
          timerProgressBar: true,
          // showConfirmButton: false,
        }).then(() => {
          reload!();
          console.log(arquivo);
          setIsOk(false);
          onClose();
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro na requisicao de envio do pdf',
          confirmButtonText: 'Ok',
        });
      } finally {
        setIsLoadingGerarPedido(false);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Anexe um arquivo do tipo pdf',
        confirmButtonText: 'Ok',
      });
    }
  };
  const getDadosTabela = async () => {
    try {
      let response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${idProjeto}`
      );
      const { data } = response;
      console.log(data);
      setProjeto(data);
    } catch (errors: any) {
      console.log(errors);
    }
  };

  const submitButton = (
    <Button type='submit' variant='success' disabled={!isOk} onClick={() => anexarPdf()}>
      {isLoadingGerarPedido ? <Spinner animation='border' size='sm' /> : 'Anexar Arquivo'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  useEffect(() => {
    if (isOpen) {
      getDadosTabela();
    }
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <>
          <Modal {...modalConfigs}>
            <div className=' mx-auto d-flex justify-content-center p-2 mb-5'>
              <FiFileText
                className='fs-1 rounded-4  mb-5'
                style={{scale: '3', padding: '3px', border: '2px solid #7239EA', color: '#7239EA'}}
              ></FiFileText>
            </div>
            <h1 className=' mx-auto fw-bolder'>Anexar Pdf do projeto</h1>
            <hr className=' mx-auto bg-black' style={{ height: '2px' }} />
            <div className='table-responsive'>
              <table className='table  mx-auto my-10 table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                <thead className='thead-dark'>
                  <tr className='fw-bold fs-6 text-gray-800'>
                    <th className='text-center'>Id</th>
                    <th className='text-center'>Titulo</th>
                    <th className='text-center'>Empresa</th>
                    {/* <th className='text-center'>Setor</th> */}
                    <th className='text-center'>Cliente</th>
                  <th className='text-center'>Descrição</th>
                    {/* <th className='text-center'>Status do projeto</th> */}
                    {/* <th className='text-center'>Prioridade</th> */}
                    <th className='text-center'>Status</th>
                  </tr>
                </thead>
                <tbody className=''>
                  <tr>
                    <td className='text-center'>{projeto!['id']}</td>
                    <td className='text-center'>{projeto!['titulo']}</td>
                    <td className='text-center'>{projeto!['empresaFantasia']}</td>
                    {/* <td className='text-center'>{projeto!['setorTitulo']}</td> */}
                    <td className='text-center'>{projeto!['clienteFantasia']}</td>
                    {/* <td className='text-center'>{projeto!['projetoStatusTitulo']}</td> */}
                    <td className='text-center'>{projeto!['descricao']===''?'...':projeto!['descricao']}</td>
                    {/* <td className='text-center'>{projeto!['status']}</td> */}
                    <td className='text-center'><Badge className='text-white p-3 px-4 ' bg={`${projeto!['status'] === 'ATIVO' ? 'success' : 'danger'}`}>{projeto!['status']}</Badge></td>

                  </tr>
                </tbody>
              </table>
            </div>
           {/* <div className='table-responsive'>
           <table className='table  mx-auto my-10 table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>Id</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Empresa</th>
                  <th className='text-center'>Setor</th>
                  <th className='text-center'>Cliente</th>
                  <th className='text-center'>Status do projeto</th>
                  <th className='text-center'>Prioridade</th>
                  <th className='text-center'>Status</th>
                </tr>
              </thead>
              <tbody className=''>
                <tr>
                  <td className='text-center'>{projeto!['id']}</td>
                  <td className='text-center'>{projeto!['titulo']}</td>
                  <td className='text-center'>{projeto!['empresaFantasia']}</td>
                  <td className='text-center'>{projeto!['setorTitulo']}</td>
                  <td className='text-center'>{projeto!['clienteFantasia']}</td>
                  <td className='text-center'>{projeto!['projetoStatusTitulo']}</td>
                  <td className='text-center'>{projeto!['prioridade']}</td>
                  <td className='text-center'>{projeto!['status']}</td>
                </tr>
              </tbody>
            </table>
           </div> */}

            <div className='mt-5'>
            <FormArquivoUpload
              isPdf
              setArquivo={setArquivo}
              setIsOk={setIsOk}
              isOk={isOk}
            ></FormArquivoUpload>
            </div>

            {/* <FormUploadFiles setArquivo={setArquivo}
              setIsOk={setIsOk}
              isOk={isOk}></FormUploadFiles> */}

          </Modal>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ModalAnexarPDf;
