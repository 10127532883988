import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { Children, useEffect, useState } from "react";
// import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
// import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
// import { is } from "date-fns/locale";
import { da } from "date-fns/locale";
import Skeleton from "react-loading-skeleton";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const TableListagem = ({ coluns, hasMore, loadMore,children,isLoading }) => {

    return (
        <>
        {isLoading ? (
            <Skeleton count={40} height={25} />
        ) : (
            <>
            <div className="table-responsive">
                <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                    <thead className='thead-dark'>

                        <tr className='fw-bold fs-6 text-gray-800'>
                            {coluns.map((coluna) => (
                                <th className='text-center'>{coluna}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </table>
                {hasMore && (
                    <div className='d-flex justify-content-center align-items-center'>
                        <button className='btn btn-primary m-5' onClick={loadMore} >
                            Carregar Mais
                        </button>
                    </div>
                )}
            </div>
            </>
            )}
        </>
    )
}

export default TableListagem