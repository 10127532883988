import { Button, Col, ModalProps, Row } from "react-bootstrap";
// import { Modal } from "../../../../shared"
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { da, id } from "date-fns/locale";
import { AsyncSelectProduct, AsyncSelectProductByIdSysled, Modal } from "../../../../../shared";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router-dom";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ModalEstoqueTransferenciaProduto = ({ isOpen, onHide, isEdit = false, reload, dataLine = {}, clearFiltros }) => {
    const { id } = useParams()
    const [operacao, setOperacao] = useState<string | null>(null)
    // const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [dataMovimento, setDataMovimento] = useState<string | null>(null)
    // const [selectedEstoque, setSelectedEstoque] = useState<ClienteOption | null>(null)
    // const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null)
    const [selectedLocalOrigem, setSelectedLocalOrigem] = useState<ClienteOption | null>(null)
    const [selectedLocalDestino, setSelectedLocalDestino] = useState<ClienteOption | null>(null)
    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)


    const [status, setStatus] = useState<string | null>('ATIVO')
    const [quantidade, setQuantidade] = useState<number | null>(null)
    const [selectedEstoque, setSelectedEstoque] = useState<ClienteOption | null>(null)
    const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null)
    const onClose = () => {
        // clearFiltros()
        clearForm()
        onHide!();
        reload()
    }

    const clearForm = () => {
        setSelectedLocalDestino(null)
        setSelectedLocalOrigem(null)
        setQuantidade(null)
        setSelectedEstoque(null)
        setSelectedProduto(null)
        setStatus('ATIVO')
    }

    const cadastrarTransferenciaItens = async () => {
        try {
            if (selectedProduto !== null && selectedLocalOrigem && selectedLocalDestino && quantidade && selectedEstoque) {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias-itens`;
                const response = await axios.post(url, {
                    "idEstoqueTransferencias": Number(id),
                    "idEstoque": selectedEstoque?selectedEstoque.value:null,
                    "idProduto": selectedProduto?.value,
                    "idLocalizacaoOrigem": selectedLocalOrigem?.value,
                    "idLocalizacaoDestino": selectedLocalDestino?.value,
                    "quantidade": quantidade,
                    "status": status ? status : 'ATIVO'

                    
                });

                Swal.fire({
                    icon: 'success',
                    title: `Produto da Transferencia cadastrado com sucesso`,
                    timer: 3000,
                    timerProgressBar: true,
                }).then(() => {
                    clearForm()
                })
            }
        }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }

    const editarTransferenciaItens = async () => {
        try {
            console.log(dataLine)
            if (selectedProduto && selectedLocalOrigem && selectedLocalDestino && quantidade && selectedEstoque) {

                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias-itens/${dataLine[0]}`;
                const response = await axios.put(url, {
                    "idEstoqueTransferencias": Number(id),
                    "idEstoque": selectedEstoque?selectedEstoque.value:null,
                    "idProduto": selectedProduto?.value,
                    "idLocalizacaoOrigem": selectedLocalOrigem?.value,
                    "idLocalizacaoDestino": selectedLocalDestino?.value,
                    "quantidade": quantidade,
                    "status": status ? status : 'ATIVO'
                });
                Swal.fire({
                    icon: 'success',
                    title: `Produto da Transferencia editado com sucesso`,
                    timer: 3000,
                    timerProgressBar: true,

                }).then(() => {
                    clearForm()
                    onClose()
                    reload()

                });

            }
        }
        catch (errors: any) {
            Swal.fire({
                icon: 'info',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }

    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarTransferenciaItens() : cadastrarTransferenciaItens()}
        >
            {isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };

    function formatDate(inputDate: string): string {
        const date = new Date(inputDate);

        if (isNaN(date.getTime())) {
            throw new Error("Data inválida");
        }
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`;
    }

    const loadOptionsEstoques = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const loadOptionsLocalizacao = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    useEffect(() => {
        setStatus(dataLine[10])
        setQuantidade(dataLine[9])
        setSelectedLocalOrigem({ value: dataLine[5], label: dataLine[6] })
        setSelectedLocalDestino({ value: dataLine[7], label: dataLine[8] })
        setSelectedProduto({ value: dataLine[1], label: dataLine[1] })
        setSelectedEstoque({ value: dataLine[3], label: dataLine[4] })
        
        if (dataLine[0] === undefined) {
            setSelectedProduto(null)
            setSelectedEstoque(null)
            setSelectedMotivo(null)
            setSelectedLocalOrigem(null)
            setSelectedLocalDestino(null)
        }
    }, [dataLine])

    return (
        <div>
            <Modal   {...modalConfigs}>
                {/* TITULO E CADASTRO */}
                <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                        {isEdit ? "Editar" : "Cadastrar"} Transferencia do Produto
                    </h1>
                </div>
                {/* BARRA HORRIZONTAL */}
                <div style={{ marginBottom: '8px' }}>
                    <div style={{ borderBottom: '2px solid #878787' }}></div>
                </div>
                {/* INPUTS */}
                <div className="my-4">
                    <form action="" className="mt-4">
                        <Row className="mt-4 col-12 mb-4 row">
                            <Col>
                                <label htmlFor="" className="form-label"  >Produto:</label>
                                <AsyncSelectProductByIdSysled onKeyDown={()=>{setSelectedProduto(null)}} inputOption={selectedProduto} handleOptions={(product) => setSelectedProduto(product)}  ></AsyncSelectProductByIdSysled>
                                {(selectedProduto === null || selectedProduto === undefined  )  && <span className={` form-label text-danger`}>*Por favor, informe o Produto </span>}
                            </Col>
                        </Row>
                        <Row className="mt-5 row col-12">
                            <Col className="">
                                <label htmlFor="" className="form-label">Transferência:</label>
                                <input type="number" value={Number(id)} disabled className="form-control" placeholder={`${id}`} />

                            </Col>
                            <Col>
                                <label htmlFor="" className="form-label">Estoque de Transferencia:</label>
                                <AsyncSelect placeholder='Selecione o Estoque de Transferencia'
                                    loadOptions={loadOptionsEstoques}
                                defaultOptions

                                    value={selectedEstoque}
                                    onChange={(selectedOption: ClienteOption | null) => {
                                        setSelectedEstoque(selectedOption);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            setSelectedEstoque(null);
                                        }
                                    }}
                                ></AsyncSelect>
                                 {(selectedEstoque === null || selectedEstoque === undefined )  && <span className={` form-label text-danger`}>*Por favor, informe o Estoque de Transferencia </span>}
                            </Col>


                        </Row>
                        <Row className="mt-5 col-12 mb-4 row">
                            <Col>
                                <label htmlFor="" className="form-label">Localização de Origem:</label>
                                <AsyncSelect placeholder='Selecione a localização de origem'
                                defaultOptions

                                    value={selectedLocalOrigem}
                                    onChange={(selectedOption: ClienteOption | null) => {
                                        setSelectedLocalOrigem(selectedOption);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            setSelectedLocalOrigem(null);
                                        }
                                    }}
                                    loadOptions={loadOptionsLocalizacao} ></AsyncSelect>
                                     {(selectedLocalOrigem === null || selectedLocalOrigem === undefined )  && <span className={` form-label text-danger`}>*Por favor, informe a Localização de Origem</span>}
                            </Col>
                            <Col>
                                <label htmlFor="" className="form-label">Localização de Destino:</label>
                                <AsyncSelect placeholder='Selecione a localização de destino'
                                    value={selectedLocalDestino ? selectedLocalDestino : null}
                                    onChange={(selectedOption: ClienteOption | null) => {
                                        setSelectedLocalDestino(selectedOption);
                                    }}
                                    defaultOptions
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            setSelectedLocalDestino(null);
                                        }
                                    }}
                                    loadOptions={loadOptionsLocalizacao} ></AsyncSelect>
                                     {(selectedLocalDestino === null || selectedLocalDestino === undefined )  && <span className={` form-label text-danger`}>*Por favor, informe a Localização de Destino</span>}
                            </Col>

                        </Row>
                        <Row className="col-12">
                            <Col className="">
                                <label htmlFor="" className="form-label">Quantidade:</label>
                                <input type="number" value={quantidade ? quantidade : ""} onChange={(e) => { setQuantidade(Number(e.target.value)) }} className="form-control" placeholder="0" />
                                {(quantidade === null || quantidade === undefined || quantidade === 0 )  && <span className={` form-label text-danger`}>*Por favor, informe a quantidade</span>}

                            </Col>
                            <Col className="col-6">
                                <label htmlFor="" className="form-label">Status:</label>
                                <select className="form-select" value={status ? status : ""}
                                    onChange={(e) => { setStatus(e.target.value) }}>
                                    <option value="ATIVO" >ATIVO</option>
                                    <option value="INATIVO">INATIVO</option>
                                </select>

                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalEstoqueTransferenciaProduto