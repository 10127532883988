import { useEffect, useMemo, useState } from 'react';
import { Col, Row as RowBootstrap, Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import {
  buildQueryParams,
  DescriptionComponent,
  DropDownButtons,
  formatDateTimeWithHours,
  HttpClient,
  IConfiguracoesItens,
  showAlert,
  SysledTable,
} from '../../../shared';
import ModalAddConfiguracaoItens from './components/add-configuracoes-itens-modal/ModalAddConfiguracaoItens';

const ConfiguracaoItensPage: React.FC = () => {
  const http = new HttpClient();
  const navigate = useNavigate();
  const id = useParams<{ id: string }>().id;

  const [configuracoes, setConfiguracoes] = useState<IConfiguracoesItens[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editConfigId, setEditConfigId] = useState<number | null>(null);

  const columns = useMemo<Column<IConfiguracoesItens>[]>(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({ value }) => <div className='text-end'>{value || '...'}</div>,
      },
      {
        Header: 'Descrição',
        accessor: 'descricao',
        Cell: ({ value }) => <div className='text-start'>{value || '...'}</div>,
      },
      {
        Header: 'Int A',
        accessor: 'intA',
        Cell: ({ value }) => <div className='text-center'>{value === undefined || value === null ? '...' : value}</div>,
      },
      {
        Header: 'Str A',
        accessor: 'strA',
        Cell: ({ value }) => <div className='text-center'>{value || '...'}</div>,
      },
      {
        Header: 'Txt A',
        accessor: 'txtA',
        Cell: ({ value }) => <div className='text-center'>{value || '...'}</div>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <div className='text-center'>{value || '...'}</div>,
      },
      {
        Header: 'Usuário de Criação',
        accessor: 'usuarioCriacao',
        Cell: ({ row }) => (
          <div className='text-center'>
            {row.original.usuarioCriacao
              ? `${row.original.usuarioCriacao} - ${row.original.nomeUsuarioCriacao}`
              : '...'}
          </div>
        ),
      },
      {
        Header: 'Data de Criação',
        accessor: 'dataCriacao',
        Cell: ({ value }) => (
          <div className='text-center'>{value ? formatDateTimeWithHours(value) : '...'}</div>
        ),
      },
      {
        Header: 'Usuário de Alteração',
        accessor: 'usuarioAlteracao',
        Cell: ({ row }) => (
          <div className='text-center'>
            {row.original.usuarioAlteracao
              ? `${row.original.usuarioAlteracao} - ${row.original.nomeUsuarioAlteracao}`
              : '...'}
          </div>
        ),
      },
      {
        Header: 'Data de Alteração',
        accessor: 'dataAlteracao',
        Cell: ({ value }) => (
          <div className='text-center'>{value ? formatDateTimeWithHours(value) : '...'}</div>
        ),
      },
      {
        Header: 'Ações',
        Cell: ({ row }) => (
          <div className='text-center d-flex justify-content-around align-items-center'>
            <DropDownButtons
              titleButton='Opções'
              buttons={[

                {
                  classIcon: 'bi bi-pencil',
                  title: 'Editar',
                  handleFunction: () => openEditModal(Number(row.original.id)),
                },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );

  const fetchConfiguracoes = async (params = {}) => {
    setIsLoading(true);
    try {
      const query = buildQueryParams(params);
      const response = await http.request<{ content: IConfiguracoesItens[] }>({
        method: 'GET',
        url: `configuracoes/itens/listar${query}`,
      });
      setConfiguracoes(response.data.content);
    } catch (error) {
      if (error instanceof Error && 'response' in error) {
        showAlert('error', (error as any).response?.data || 'Erro ao carregar itens da configurações.');
      } else {
        showAlert('error', 'Erro ao carregar itens da configurações.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchConfiguracoes({ idConfiguracao: id });
  }, [id]);

  const openEditModal = (id: number) => {
    setEditConfigId(id);
    setIsModalOpen(true);
  };

  return (
    <>
      <DescriptionComponent
        description={`Listagem de Itens da Configuração: ${id}`}
        withLink
        buttonTitle={`+ Item da Configuração:  ${id}`}
        handleAction={() => setIsModalOpen(true)}
        isButtonAction
      />
      <div className='col-12 mt-6 mb-2 d-flex justify-content-end'>
        <div className='col-sm-1 d-flex align-items-end justify-content-end gap-2'>
          <button
            type='button'
            className='btn btn-danger form-control'
            onClick={() => navigate('/configuracoes')}
          >
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Voltar'}
          </button>
        </div>
      </div>

      {isLoading ? (
        <Skeleton count={1} height={50} />
      ) : (
        <SysledTable
          columns={columns}
          data={configuracoes}
          message='SEM DADOS DOS ITENS DA CONFIGURAÇÃO'
        />
      )}

      <ModalAddConfiguracaoItens
        isOpen={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
          setEditConfigId(null);
          fetchConfiguracoes({ idConfiguracao: id });
        }}
        handleRequest={() => fetchConfiguracoes({ idConfiguracao: id })}
        isEdit={!!editConfigId}
        idItem={editConfigId}
      />
    </>
  );
};

export default ConfiguracaoItensPage;
