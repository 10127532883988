import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {ReactNode, createContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {
  cadastroValidationSchema,
  formProduto,
  ListPrecoInfo,
  OptionType,
  ProdutoOption,
} from '../Interface';
import {submitFormProduto} from '../../../useUtils/useSubmitFormProduto';
import axios from 'axios';
import dayjs from 'dayjs';
import {ISelectOption} from '../../../../shared';
import Swal from 'sweetalert2';
import { submitFormProdutoConsumo } from '../../produtos-consumo/components/useSubmitFormProdutoConsumo';

type ProdutoProviderProps = {
  children: ReactNode;
};

type ProdutoContextType = {
  formik: any;
  loading: boolean;
  navigate: any;
  handleSelectChange: any;
  handleChange: any;
  handleChangeNumber: any;
  selectedEmbalagem: ProdutoOption | undefined;
  setTabelasDePrecosProduto: React.Dispatch<React.SetStateAction<ListPrecoInfo[]>>;
  setSelectedOrigem: React.Dispatch<SetStateAction<ProdutoOption | null>>;
  setSelectedMarca: React.Dispatch<SetStateAction<ISelectOption | null>>;
  setSelectedTipo: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedTipo_Embalagem: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedEmbalagem: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedCategoria: React.Dispatch<SetStateAction<ProdutoOption>>;
  setBase64Image: React.Dispatch<SetStateAction<string>>;
  setSelectedLinhaProduto: React.Dispatch<SetStateAction<ProdutoOption>>;
  tabelasDePrecosProduto: ListPrecoInfo[];
  selectedOrigem: ProdutoOption | undefined | null;
  selectedMarca: ISelectOption | null;
  selectedTipo: ProdutoOption | undefined;
  selectedTipo_Embalagem: ProdutoOption | undefined;
  selectedCategoria: ProdutoOption | undefined;
  selectedLinhaProduto: ProdutoOption | undefined;
  base64Image: string;
  setIdDoFormulario: any;
  setIsConsumo: any;
  isSegmento: ISelectOption | null;
  setIsSegmento: React.Dispatch<SetStateAction<ISelectOption | null>>;
  isTipoProduto: ISelectOption | null;
  isLinhaProduto: ISelectOption | null;
  setIsLinhaProduto: React.Dispatch<SetStateAction<ISelectOption | null>>;
  setIsTipoProduto: React.Dispatch<SetStateAction<ISelectOption | null>>;
  isGrupoCategoria: ISelectOption | null;
  setIsGrupoCategoria: React.Dispatch<SetStateAction<ISelectOption | null>>;
  isCategoria: ISelectOption | null;
  setIsCategoria: React.Dispatch<SetStateAction<ISelectOption | null>>;
  isCodigoClassificacao: number;
  setIsCodigoClassificacao: Dispatch<SetStateAction<number>>;
  isUnidadeMedidaVenda: ISelectOption | null;
  setIsUnidadeMedidaVenda: Dispatch<SetStateAction<ISelectOption | null>>;
  isUnidadeMedidaCompra: ISelectOption | null;
  setIsUnidadeMedidaCompra: Dispatch<SetStateAction<ISelectOption | null>>;
  setIsCliente: Dispatch<SetStateAction<ISelectOption | null>>;
  isCliente: ISelectOption | null;
  isFornecedor: ISelectOption | null;
  setIsFornecedor: Dispatch<SetStateAction<ISelectOption | null>>;
  isEcommerce: boolean;
  isCustomizado: boolean
  setIsEcommerce: React.Dispatch<React.SetStateAction<boolean>>;
  isDisponivelFabrica: boolean;
  setIsDisponivelFabrica: React.Dispatch<React.SetStateAction<boolean>>;
  isDivulgacao: boolean;
  setIsDivulgacao: React.Dispatch<React.SetStateAction<boolean>>;
  isEstoqueFabricacao: boolean;
  setIsEstoqueFabricacao: React.Dispatch<React.SetStateAction<boolean>>;
  isMix: boolean;
  setIsMix: React.Dispatch<React.SetStateAction<boolean>>;
  isAguardandoComplemento: boolean;
  setIsAguardandoComplemento: React.Dispatch<React.SetStateAction<boolean>>;
  isTipoEstoque: ISelectOption | null;
  setIsTipoEstoque: Dispatch<SetStateAction<ISelectOption | null>>;
  setSelectedGarantia: React.Dispatch<SetStateAction<ISelectOption | null>>;
  selectedGarantia: ISelectOption | null;

};
const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const ProdutoContext = createContext({} as ProdutoContextType);

export const ProdutoContextProvider = ({children}: ProdutoProviderProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [idDoFormulario, setIdDoFormulario] = useState(undefined);
  const [isConsumo, setIsConsumo] = useState(false);
  const [base64Image, setBase64Image] = useState<string>('');
  const [tabelasDePrecosProduto, setTabelasDePrecosProduto] = useState<ListPrecoInfo[]>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<ProdutoOption>({id: '', desc: ''});
  const [selectedOrigem, setSelectedOrigem] = useState<ProdutoOption | null>({id: '', desc: ''});
  const [selectedLinhaProduto, setSelectedLinhaProduto] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });
  const [selectedTipo, setSelectedTipo] = useState<ProdutoOption>({id: '', desc: ''});
  const [selectedMarca, setSelectedMarca] = useState<ISelectOption | null>(null);
  const [selectedGarantia, setSelectedGarantia] = useState<ISelectOption | null>(null);
  const [selectedTipo_Embalagem, setSelectedTipo_Embalagem] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });
  const [selectedEmbalagem, setSelectedEmbalagem] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });
  const [isSegmento, setIsSegmento] = useState<ISelectOption | null>(null);
  const [isTipoProduto, setIsTipoProduto] = useState<ISelectOption | null>(null);
  const [isGrupoCategoria, setIsGrupoCategoria] = useState<ISelectOption | null>(null);
  const [isCliente, setIsCliente] = useState<ISelectOption | null>(null);
  const [isCategoria, setIsCategoria] = useState<ISelectOption | null>(null);
  const [isUnidadeMedidaVenda, setIsUnidadeMedidaVenda] = useState<ISelectOption | null>(null);
  const [isUnidadeMedidaCompra, setIsUnidadeMedidaCompra] = useState<ISelectOption | null>(null);
  const [isTipoEstoque, setIsTipoEstoque] = useState<ISelectOption | null>(null);
  const [isLinhaProduto, setIsLinhaProduto] = useState<ISelectOption | null>(null);
  const [isCodigoClassificacao, setIsCodigoClassificacao] = useState<number>(0);
  const [isFornecedor, setIsFornecedor] = useState<ISelectOption | null>(null);
  const [isEcommerce, setIsEcommerce] = useState<boolean>(false);
  const [isDisponivelFabrica, setIsDisponivelFabrica] = useState<boolean>(false);
  const [isDivulgacao, setIsDivulgacao] = useState<boolean>(false);
  const [isEstoqueFabricacao, setIsEstoqueFabricacao] = useState<boolean>(false);
  const [isAguardandoComplemento, setIsAguardandoComplemento] = useState<boolean>(false);
  const [isMix, setIsMix] = useState<boolean>(false);
  const [isCustomizado, setIsCustomizado] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: formProduto,
    validationSchema: cadastroValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      // alert(values.isConsumo)
    // console.log(isTipoEstoque)
      if(values.isConsumo){
        submitFormProdutoConsumo(
          idDoFormulario,
          values,
          selectedOrigem,
          selectedMarca,
          selectedTipo,
          selectedTipo_Embalagem,
          selectedEmbalagem,
          selectedCategoria,
          selectedLinhaProduto,
          base64Image,
          tabelasDePrecosProduto,
          setTabelasDePrecosProduto,
          setSelectedMarca,
          setSelectedTipo_Embalagem,
          setSelectedLinhaProduto,
          setSelectedCategoria,
          setSelectedEmbalagem,
          setSelectedOrigem,
          setSelectedTipo,
          setBase64Image,
          setLoading,
          setSubmitting,
          setStatus,
          resetForm,
          navigate,
          isCategoria?.value,
          isUnidadeMedidaVenda?.value,
          isFornecedor?.value,
          isEcommerce,
          isDisponivelFabrica,
          isDivulgacao,
          isEstoqueFabricacao,
          isMix,
          isAguardandoComplemento,
          isLinhaProduto,
          isTipoEstoque,
          // isUnidadeMedidaCompra?.value,
        );
      }
      else{
        submitFormProduto(
          idDoFormulario,
          values,
          selectedOrigem,
          selectedMarca,
          selectedTipo,
          selectedTipo_Embalagem,
          selectedEmbalagem,
          selectedCategoria,
          selectedLinhaProduto,
          base64Image,
          tabelasDePrecosProduto,
          setTabelasDePrecosProduto,
          setSelectedMarca,
          setSelectedTipo_Embalagem,
          setSelectedLinhaProduto,
          setSelectedCategoria,
          setSelectedEmbalagem,
          setSelectedOrigem,
          setSelectedTipo,
          setBase64Image,
          setLoading,
          setSubmitting,
          setStatus,
          resetForm,
          navigate,
          isCategoria?.value,
          isUnidadeMedidaVenda?.value,
          isFornecedor?.value,
          isEcommerce,
          isDisponivelFabrica,
          isDivulgacao,
          isEstoqueFabricacao,
          isMix,
          isAguardandoComplemento,
          isLinhaProduto,
          isCliente,
          isUnidadeMedidaCompra?.value,
          selectedGarantia
        );
      }
    },
  });

  const handleSelectChange = (event, setSelected) => {
    const id = event.target.value;
    const desc = event.target.options[event.target.selectedIndex].text;

    if (desc === 'Selecione') {
      setSelected({id: '', desc: ''});
    } else {
      setSelected({id: id, desc: desc});
    }
  };

  const handleChange = (event) => {
    const {name, value} = event.target;
    // console.log(name, value);
    formik.setFieldValue(name, value);
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>)=> {
    const {name, value} = event.target;

    formik.setFieldValue('codigo_fabricante', value);
  };

  const fetchProdutoData = async (idDoFormulario: string) => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/${idDoFormulario}`
    );
    return response.data;
  };

  const loadLinhaProdutoByid = async (id: string) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/linha-produtos/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsLinhaProduto({label: data.linha, value: data.id});

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const fetchProduto = async () => {
    try {
      const produto = await fetchProdutoData(idDoFormulario!);

      if (produto.produtosStatusParametros && produto.produtosStatusParametros.isEcommerce == 'ATIVO') {
        setIsEcommerce(true);
      } else {
        setIsEcommerce(false);
      }

      if (produto.produtosStatusParametros && produto.produtosStatusParametros.isMix == 'ATIVO') {
        setIsMix(true);
      } else {
        setIsMix(false);
      }

      if (produto.produtosStatusParametros &&  produto.produtosStatusParametros.isDivulgacao == 'ATIVO') {
        setIsDivulgacao(true);
      } else {
        setIsDivulgacao(false);
      }

      if (produto.produtosStatusParametros && produto.produtosStatusParametros.isFabricacaoDisponivel == 'ATIVO') {
        setIsDisponivelFabrica(true);
      } else {
        setIsDisponivelFabrica(false);
      }

      if (produto.produtosStatusParametros && produto.produtosStatusParametros.isEstoqueFabricacao == 'ATIVO') {
        setIsEstoqueFabricacao(true);
      } else {
        setIsEstoqueFabricacao(false);
      }

      if (produto.produtosStatusParametros && produto.produtosStatusParametros.isAguardandoComplemento == 'ATIVO') {
        setIsAguardandoComplemento(true);
      } else {
        setIsAguardandoComplemento(false);
      }

      if (produto.idCliente) {
        setIsCliente({
          label: '',
          value: produto.idCliente,
        });
      } else {
        setIsCliente(null);
      }

      if (produto.marca_id) {
        setIsFornecedor({
          label: '',
          value: produto.marca_id,
        });
      } else {
        setIsFornecedor(null);
      }

      if (produto.idFornecedor) {
        setIsFornecedor({
          label: '',
          value: produto.idFornecedor,
        });
      } else {
        setIsFornecedor(null);
      }

      if (produto.idUnidadeMedida) {
        setIsUnidadeMedidaVenda({
          value: produto.idUnidadeMedida,
          label: `${produto.unidadeMedidaTitulo}${
            produto.unidadeMedidaDesc ? ` - ${produto.unidadeMedidaDesc}` : ''
          }`,
        });
      } else {
        setIsUnidadeMedidaVenda(null);
      }

      if (produto.idUnidadeMedidaCompra) {
        setIsUnidadeMedidaCompra({
          value: produto.idUnidadeMedidaCompra,
          label: `${produto.unidadeMedidaCompraTitulo}${
            produto.unidadeMedidaCompraDesc ? ` - ${produto.unidadeMedidaCompraDesc}` : ''
          }`,
        });
      } else {
        setIsUnidadeMedidaCompra(null);
      }


      if (produto.origem_id) {
        setSelectedOrigem({
          id: produto.origem_id,
          desc: produto.origem_desc,
        });
      } else {
        setSelectedOrigem({
          id: '',
          desc: '',
        });
      }

      if (produto.categoria_id) {
        setIsCategoria({
          value: produto.categoria_id,
          label: `

          ${produto.codigoClassificacao ? `${produto.codigoClassificacao} - ` : ''}
          ${produto.categoriaTitulo}${
            produto.categoria_desc ? ` - ${produto.categoria_desc}` : ''
          }`,
        });
      } else {
        setIsCategoria(null);
      }

      if (produto.idLinhaProduto) {
        loadLinhaProdutoByid(produto.idLinhaProduto);
      } else {
        setIsLinhaProduto(null);
      }

      if (produto.grupoCategoriaId) {
        setIsGrupoCategoria({
          value: produto.grupoCategoriaId,
          label: `${produto.grupoCategoriaTitulo}${
            produto.grupoCategoriaDescricao ? ` - ${produto.grupoCategoriaDescricao}` : ''
          }`,
        });
      } else {
        setIsGrupoCategoria(null);
      }

      if (produto.tipoProdutoId) {
        setIsTipoProduto({
          value: produto.tipoProdutoId,
          label: `${produto.tipoProdutoTitulo}${
            produto.tipoProdutoDescricao ? ` - ${produto.tipoProdutoDescricao}` : ''
          }`,
        });
      } else {
        setIsTipoProduto(null);
      }

      if (produto.segmentoId) {
        setIsSegmento({
          value: produto.segmentoId,
          label: `${produto.segmentoTitulo}${
            produto.segmentoDescricao ? ` - ${produto.segmentoDescricao}` : ''
          }`,
        });
      } else {
        setIsSegmento(null);
      }

      if (produto.idTipoEstoque) {
        setIsTipoEstoque({
          value: produto.idTipoEstoque,
          label: produto.idTipoEstoque,
        });
      } else {
        setIsTipoEstoque(null);
      }

      if (produto.tipo_id) {
        setSelectedTipo({
          id: produto.tipo_id,
          desc: produto.tipo_desc,
        });
      } else {
        setSelectedTipo({
          id: '',
          desc: '',
        });
      }

      setSelectedTipo_Embalagem({
        id: produto.tipo_embalagem_id || '',
        desc: produto.tipo_embalagem_desc || '',
      });

      if(produto.isCustomizado){
        setIsCustomizado(true)
      }else {
        setIsCustomizado(false)
      }

      setSelectedMarca({value: produto.marca_id || '', label: produto.marca_desc || ''});

      setSelectedGarantia({value: produto.idGarantia || '', label: ''});

      setSelectedCategoria({id: produto.categoria_id || '', desc: produto.categoria_desc || ''});
      setSelectedEmbalagem({id: produto.embalagem_id || '', desc: produto.embalagem_desc || ''});
      setBase64Image(produto.img_produto_base64 || '');

      let detalhesPrecoAjustados = [];

      if (produto.tabelasDePrecoProduto.length > 0) {
        detalhesPrecoAjustados = produto.tabelasDePrecoProduto.map((detalhe) => ({
          id: detalhe.id,
          idtabeladepreco: detalhe.idtabeladepreco,
          descricao: detalhe.descricao,
          precovenda: detalhe.precovenda,
          precopromocional: detalhe.precopromocional,
          status: detalhe.status,
        }));
      }

      const statusMap: Record<string, string> = {
        ATIVO: '1',
        INATIVO: '0',
        PENDENTE: '2',
      };

      formik.setValues({
        descricao: produto.descricao || '',
        isConsumo: produto.isConsumo || false,
        codigo_sku: produto.codigo_sku || '',
        origem_id: selectedOrigem?.id || '',
        origem_desc: selectedOrigem?.desc || '',
        tipo_id: selectedTipo.id,
        tipo_desc: selectedTipo.desc,
        idUnidadeMedida: produto.unidadeMedidId,
        ncm: produto.ncm || '',
        gtin_ean: produto.gtin_ean || '',
        cest: produto.cest || '',
        preco_promocional: produto.preco_promocional || '',
        unidade: produto.unidade || '',
        peso_liq: produto.peso_liq || '',
        peso_bruto: produto.peso_bruto || '',
        num_volumes: produto.num_volumes || '',
        tipo_embalagem_id: selectedTipo_Embalagem.id,
        tipo_embalagem_desc: selectedTipo_Embalagem.desc,
        embalagem_id: selectedEmbalagem.id,
        embalagem_desc: selectedEmbalagem.desc,
        largura: produto.largura || '',
        altura: produto.altura || '',
        comprimento: produto.comprimento || '',
        controlar_estoque: produto.controlar_estoque || null,
        estoque_inicial: produto.estoque_inicial || '',
        estoque_min: produto.estoque_min || '',
        estoque_max: produto.estoque_max || '',
        sob_encomenda: produto.sob_encomenda || '',
        controlar_lotes: produto.controlar_lotes || '',
        localizacao: produto.localizacao || '',
        dias_preparacao: produto.dias_preparacao || '',
        categoria_id: selectedCategoria.id,
        categoria_desc: selectedCategoria.desc,
        marca_id: selectedMarca?.value,
        marca_desc: selectedMarca?.label,
        desc_complementar: produto.desc_complementar || '',
        img_produto_base64: base64Image,
        img_url: produto.img_url || '',
        link_video: produto.link_video || '',
        slug: produto.slug || '',
        keywords: produto.keywords || '',
        titulo_seo: produto.titulo_seo || '',
        custo_a_partir_de:
          produto.custo_a_partir_de !== null
            ? dayjs(produto.custo_a_partir_de).format('YYYY-MM-DD')
            : '',
        custo_saldo_atual: produto.custo_saldo_atual || '',
        custo_saldo_anterior: produto.custo_saldo_anterior || '',
        custo_impostos_rec: produto.custo_impostos_rec || '',
        custo_preco: produto.custo_preco || '',
        custo_medio: produto.custo_medio || '',
        custo_preco_venda: produto.custo_preco_venda || '',
        preco_compra: produto.preco_compra || '',
        unidade_conversao: produto.unidade_conversao || '',
        mark_up: produto.mark_up || '',
        fabricante: produto.fabricante || '',
        codigo_fabricante: produto.codigo_fabricante || '',
        uni_por_caixas: produto.uni_por_caixas || '',
        linha_de_produto_id: selectedLinhaProduto.id,
        linha_de_produto_desc: selectedLinhaProduto.desc,
        garantia: produto.garantia || '',
        gtin_ean_tributavel: produto.gtin_ean_tributavel || '',
        unidade_tributavel: produto.unidade_tributavel || '',
        fator_de_conversao: produto.fator_de_conversao || '',
        codigo_enquadramento_ipi: produto.codigo_enquadramento_ipi || '',
        valor_ipi_fixo: produto.valor_ipi_fixo || '',
        obs_geral_produto: produto.obs_geral_produto || '',
        status: produto.status || '',
        diferencialAliquota: produto.diferencialAliquota || '',
        substituicaoTributaria: produto.substituicaoTributaria,
        valorFrete: produto.valorFrete,
        valorNegociacao: produto.valorNegociacao || 0,
        isCompra: statusMap[produto.produtosStatusParametros?.isCompra] || '',
        isRessuprimento: statusMap[produto.produtosStatusParametros?.isRessuprimento] || '',
        isVenda: statusMap[produto.produtosStatusParametros?.isVenda] || '',
        idTipoEstoque: produto.idTipoEstoque ,
        idUnidadeMedidaCompra: produto.idUnidadeMedidaCompra,
        fatorConversaoInicial: produto.fatorConversaoInicial || '',
        fatorConversaoFinal: produto.fatorConversaoFinal || '',
        idGarantia: produto.idGarantia,
      });
      

      setTabelasDePrecosProduto(detalhesPrecoAjustados);

      setIsTipoEstoque(produto.idTipoEstoque?produto.idTipoEstoque:null);
      // if (produto.idTipoEstoque!==null) {
      //   setIsTipoEstoque(produto.idTipoEstoque);
      //   console.log(isTipoEstoque)
      // } 
      // else {
      //   // alert(8)
      //   console.log(isTipoEstoque)
      //   setIsTipoEstoque(null);
      // }
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    if (isCategoria?.original?.isCustomizado === 'NAO' || !isCategoria) {
      setIsCliente(null);
      setIsCustomizado(false)
    }
    console.log()
  }, [isCategoria]);

  useEffect(() => {
    if (!idDoFormulario) {
      setTabelasDePrecosProduto([]);
      setSelectedMarca(null);
      setSelectedTipo_Embalagem({id: '', desc: ''});
      setSelectedLinhaProduto({id: '', desc: ''});
      setSelectedCategoria({id: '', desc: ''});
      setSelectedEmbalagem({id: '', desc: ''});
      setSelectedOrigem({id: '', desc: ''});
      setSelectedTipo({id: '', desc: ''});
      setBase64Image('');
      setIsSegmento(null);
      setIsCodigoClassificacao(0);
      setIsUnidadeMedidaVenda(null);
      setIsUnidadeMedidaCompra(null);
      setIsFornecedor(null);
      setIsEcommerce(false);
      setIsMix(false);
      setIsDivulgacao(false);
      setIsDisponivelFabrica(false);
      setIsEstoqueFabricacao(false);
      setIsAguardandoComplemento(false);
      setIsLinhaProduto(null);
      setIsCliente(null);
      setSelectedGarantia(null);

      formik.setFieldValue('descricao', '');
      formik.setFieldValue('codigo_sku', '');
      formik.setFieldValue('ncm', '');
      formik.setFieldValue('gtin_ean', '');
      formik.setFieldValue('cest', '');
      formik.setFieldValue('preco_venda', '');
      formik.setFieldValue('preco_promocional', '');
      formik.setFieldValue('mark_up', '');
      formik.setFieldValue('preco_compra', '');
      formik.setFieldValue('unidade_conversao', '');
      formik.setFieldValue('unidade', '');
      formik.setFieldValue('peso_liq', '');
      formik.setFieldValue('peso_bruto', '');
      formik.setFieldValue('num_volumes', '');
      formik.setFieldValue('largura', '');
      formik.setFieldValue('altura', '');
      formik.setFieldValue('comprimento', '');
      formik.setFieldValue('controlar_estoque', '');
      formik.setFieldValue('estoque_inicial', '');
      formik.setFieldValue('estoque_min', '');
      formik.setFieldValue('estoque_max', '');
      formik.setFieldValue('sob_encomenda', '');
      formik.setFieldValue('controlar_lotes', '');
      formik.setFieldValue('localizacao', '');
      formik.setFieldValue('dias_preparacao', '');
      formik.setFieldValue('desc_complementar', '');
      formik.setFieldValue('img_produto_base64', '');
      formik.setFieldValue('img_url', '');
      formik.setFieldValue('link_video', '');
      formik.setFieldValue('slug', '');
      formik.setFieldValue('keywords', '');
      formik.setFieldValue('titulo_seo', '');
      formik.setFieldValue('custo_a_partir_de', '');
      formik.setFieldValue('custo_saldo_atual', '');
      formik.setFieldValue('custo_saldo_anterior', '');
      formik.setFieldValue('custo_impostos_rec', '');
      formik.setFieldValue('custo_preco', '');
      formik.setFieldValue('custo_medio', '');
      formik.setFieldValue('custo_preco_venda', '');
      formik.setFieldValue('fabricante', '');
      formik.setFieldValue('codigo_fabricante', '');
      formik.setFieldValue('uni_por_caixas', '');
      formik.setFieldValue('garantia', '');
      formik.setFieldValue('gtin_ean_tributavel', '');
      formik.setFieldValue('unidade_tributavel', '');
      formik.setFieldValue('fator_de_conversao', '');
      formik.setFieldValue('codigo_enquadramento_ipi', '');
      formik.setFieldValue('valor_ipi_fixo', '');
      formik.setFieldValue('obs_geral_produto', '');
      formik.setFieldValue('status', '');
      formik.setFieldValue('valorNegociacao', '');
      formik.setFieldValue('substituicaoTributaria', null);
      formik.setFieldValue('valorFrete', null);
      formik.setFieldValue('diferencialAliquota', null);
      formik.setFieldValue('isCompra', '');
      formik.setFieldValue('isRessuprimento', '');
      formik.setFieldValue('isVenda', '');
      formik.setFieldValue('fatorConversaoInicial', '');
      formik.setFieldValue('fatorConversaoFinal', '');

    }
  }, [idDoFormulario]);

  useEffect(() => {
    if (idDoFormulario) fetchProduto();
  }, [idDoFormulario]);

  const ProviderValues = {
    formik,
    loading,
    navigate,
    handleSelectChange,
    handleChange,
    handleChangeNumber,
    setBase64Image,
    setTabelasDePrecosProduto,
    setSelectedOrigem,
    setSelectedMarca,
    setSelectedTipo,
    setSelectedTipo_Embalagem,
    setSelectedEmbalagem,
    setSelectedCategoria,
    setIdDoFormulario,
    setIsConsumo,
    setSelectedLinhaProduto,
    tabelasDePrecosProduto,
    selectedOrigem,
    selectedMarca,
    selectedTipo,
    selectedTipo_Embalagem,
    selectedEmbalagem,
    selectedCategoria,
    selectedLinhaProduto,
    base64Image,
    isSegmento,
    setIsSegmento,
    isTipoProduto,
    setIsTipoProduto,
    isGrupoCategoria,
    setIsGrupoCategoria,
    isCategoria,
    setIsCategoria,
    isCodigoClassificacao,
    setIsCodigoClassificacao,
    isUnidadeMedidaVenda,
    setIsUnidadeMedidaVenda,
    isUnidadeMedidaCompra,
    setIsUnidadeMedidaCompra,
    setIsFornecedor,
    isFornecedor,
    isDisponivelFabrica,
    setIsDisponivelFabrica,
    isEcommerce,
    setIsEcommerce,
    isDivulgacao,
    setIsDivulgacao,
    isEstoqueFabricacao,
    setIsEstoqueFabricacao,
    isMix,
    setIsMix,
    isAguardandoComplemento,
    setIsAguardandoComplemento,
    isLinhaProduto,
    setIsLinhaProduto,
    isCliente,
    setIsCliente,
    isTipoEstoque,
    setIsTipoEstoque,
    isCustomizado,
    selectedGarantia,
    setSelectedGarantia
  };

  return <ProdutoContext.Provider value={ProviderValues}>{children}</ProdutoContext.Provider>;
};
