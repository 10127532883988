import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "../../../../shared";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalDominios = ({isOpen, onHide,isEdit=false,reload,dataLine={},clearFiltros}) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(false)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>('')
    const [titulo, setTitulo] = useState<string | null>('')
    

    const cadastrarDominio = async () => {
        try {
            setIsLoading(true)
            if(titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' '){

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio`;
            
                const response = await axios.post(url, {
                    "titulo": titulo?titulo:'',
                    "descricao": descricao?descricao:'',
                    "status": status?status:'ATIVO'
                });   
                
            Swal.fire({
                icon: 'success',
                  title: `Dominio cadastrado com sucesso`,
                  timer: 3000,
                  timerProgressBar: true,
                }).then(() => {
                    reload!()
                    clearForm()
                    onClose()
                })
        } }
        catch (errors: any) {
            const {data} = errors.response;
            Swal.fire({
                    icon: 'error',
                    title: data.map((item) => item.mensagem),
                    showCancelButton: false,
                  });
                              
            console.log(errors)}
            finally{
                setIsLoading(false)
            }
    }
    const editarDominio = async () => {
        try {
            setIsLoading(true)
            if(titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' '){

                    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio/${dataLine[0]}`;
                    const response = await axios.put(url, {
                        "titulo": titulo?titulo:'',
                        "descricao": descricao?descricao:'',
                        "status": status
                    });
                    Swal.fire({
                        icon: 'success',
                          title: `Dominio editado com sucesso`,
                          timer: 3000,
                          timerProgressBar: true,

                        }).then(() => {
                            clearForm()
                            onClose()
                            reload()
                        });
    
                } }
                catch (errors: any) {
                    const {data} = errors.response;
                      Swal.fire({
                              icon: 'error',
                              title: data.map((item) => item.mensagem),
                              showCancelButton: false,
                            });
                                      
                    console.log(errors)}
                finally{
                    setIsLoading(false)
                }
    }
    const fetchDominiosId = async () => {
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                setTitulo(data.titulo)
                setStatus(data.status)
                setDescricao(data.descricao)
            }
        } 
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } 
    }
    const onClose = () => {
        clearForm()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setDescricao(null)
        setTitulo('')
    } 
    const submitButton = (
        <Button
          type='submit'
          variant='success'
          disabled={isConfirme===false}
          onClick={() => isEdit?editarDominio():cadastrarDominio()}
        >
           {isLoading ? <Spinner animation='border' size='sm' /> : isEdit?"Editar":"Cadastrar"}
          
        </Button>
    )
    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };

    
    useEffect(() => {
        if(isEdit){
         fetchDominiosId()
    }
    },[dataLine])
    
    useEffect(()=>{
        setIsConfirme(titulo !== ''?true:false)
    },[titulo])

    return (
        <div>
            <Modal {...modalConfigs}>
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit?"Editar":"Cadastrar"} Dominios
                </h1>
            </div>
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div className="my-4">
                <form action="" className="mt-4">
                    <Row className="mt-8  mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Titulo:</label>
                            <input type="text" 
                             className="form-control"
                            value={titulo ? titulo : ""}
                                onChange={(e) => {
                                    setTitulo(e.target.value)
                                }}
                                 placeholder="Digite o titulo" />
                            {(titulo === '' )  && <span className={` form-label text-danger`}>*Por favor, informe o Titulo</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => {setStatus(e.target.value)}}>
                                <option  value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name="" 
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                             placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row>
                </form>
            </div>
            </Modal>
        </div>
    )
}  

export default ModalDominios