import {useEffect, useState} from 'react';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {FaEdit, FaPlus} from 'react-icons/fa';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {DatePicker} from 'rsuite';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {
  AsyncSelectPrevenda,
  AsyncSelectProduct,
  HttpClient,
  IComprador,
  IPendenciaCompra,
  IPendenciaCompraCrud,
  ISelectOption,
  Modal,
  showAlert,
} from '../../../../shared';
import {
  putPendenciaVenda,
  sendPendenciaVenda,
} from '../../../cadastros/cadastro_requests/cadastro_requests';
import {getPendenciaCompraCrudById} from '../../../listagem/listagem_requests/listagem_requests';
import './pendencia-compra.scss';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  handleRequest?: () => void;
  idItem?: number | null;
  isEdit?: boolean;
};

const PendenciaCompraModal: React.FC<Props> = ({isOpen, handleRequest, idItem, onHide, isEdit}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isAsyncSelectPedido, setIsAsyncSelectPedido] = useState<ISelectOption | null>(null);
  const [isAsyncSelectProduto, setIsAsyncSelectProduto] = useState<ISelectOption | null>(null);
  const [isAsyncSelectComprador, setIsAsyncSelectComprador] = useState<ISelectOption | null>(null);
  const [isDataPendencia, setIsDataPendencia] = useState<Date | null>(null);
  const [isCompradorFiltered, setIsCompradorFiltered] = useState<ISelectOption[]>([]);
  const [defaultCompradorOptions, setDefaultCompradorOptions] = useState<ISelectOption[]>([]);

  const initialValues = {
    status: 'ATIVO',
    quantidadePendencia: 0,
  };

  const http = new HttpClient();

  const formik = useFormik({
    initialValues,

    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true);
      if (
        !isAsyncSelectProduto ||
        !isAsyncSelectPedido ||
        !isDataPendencia ||
        !isAsyncSelectComprador
      ) {
        let errorMessage = '';

        switch (true) {
          case !isAsyncSelectProduto:
            errorMessage = 'A inserção do Produto é obrigatória';
            break;
          case !isAsyncSelectPedido:
            errorMessage = 'A inserção do Pedido é obrigatória';
            break;
          case !isDataPendencia:
            errorMessage = 'A inserção da Data de Pendência é obrigatória';
            break;
          case !isAsyncSelectComprador:
            errorMessage = 'A inserção da Data de Pendência é obrigatória';
            break;
        }

        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });

        Toast.fire({
          icon: 'info',
          title: errorMessage,
        });

        setSubmitting(false);
        setIsLoading(false);
        return;
      }
      if (isEdit) {
        try {
          await http.request<IPendenciaCompraCrud, IPendenciaCompraCrud>({
            method: 'PUT',
            url: `suprimento/pendencia-compra`,
            data: {
              ...values,
              idComprador: isAsyncSelectComprador?.value,
              idProduto: isAsyncSelectProduto?.value,
              idPedido: isAsyncSelectPedido?.value,
              dataPendencia: isDataPendencia,
            },
            id: idItem!,
          });

          showAlert('success', 'Pendência de Compra Editada com sucesso');

          if (handleRequest) {
            handleRequest();
          }

          setSubmitting(false);
          setIsLoading(false);
          onClose();

          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          showAlert('error', data);

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          setIsLoading(true);

          await http.request<IPendenciaCompraCrud, IPendenciaCompraCrud>({
            method: 'POST',
            url: 'suprimento/pendencia-compra',
            data: {
              ...values,
              idComprador: isAsyncSelectComprador?.value,
              idProduto: isAsyncSelectProduto?.value,
              idPedido: isAsyncSelectPedido?.value,
              dataPendencia: isDataPendencia,
            },
          });

          showAlert('success', 'Pendência de Compra cadastrada com Sucesso');

          if (handleRequest) {
            handleRequest();
          }

          setSubmitting(false);
          setIsLoading(false);
          setIsLoading(false);

          onClose();
        } catch (errors: any) {
          const {data} = errors.response;

          showAlert('error', data);

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const onClose = () => {
    onHide!();

    setIsAsyncSelectPedido(null);
    setIsAsyncSelectProduto(null);
    setIsAsyncSelectComprador(null);
    formik.setValues({
      quantidadePendencia: 0,
      status: 'ATIVO',
    });
    setIsDataPendencia(null);
  };

  const submitButton = (
    <Button type='submit' variant='success' onClick={() => formik.handleSubmit()}>
      {isLoading && <Spinner animation='border' size='sm' />}
      {!isLoading && (isEdit ? 'Editar' : 'Cadastrar')}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    title: isEdit ? 'Editar Pendência' : 'Cadastrar Pendência',
    onHide: onClose,
    size: 'xl',
  };

  const handleSelectAsyncPrevenda = (option: ISelectOption) => {
    setIsAsyncSelectPedido(option);
  };

  const handleSelectAsyncProduto = (option: ISelectOption) => {
    setIsAsyncSelectProduto(option);
  };

  const getPendenciaById = async () => {
    setIsLoadingData(true);
    try {
      const response = await http.request<IPendenciaCompraCrud>({
        method: 'GET',
        url: 'suprimento/pendencia-compra',
        id: idItem!,
      });

      const {data} = response;

      setIsAsyncSelectPedido({label: '', value: data.idPedido!});
      setIsAsyncSelectProduto({label: '', value: data.idProduto!});
      setIsDataPendencia(data.dataPendencia ? new Date(data.dataPendencia) : null);

      loadCompradorById(data.idComprador!);

      formik.setValues({
        quantidadePendencia: data.quantidadePendencia!,
        status: data.status!,
      });

      setIsLoadingData(false);
    } catch (errors: any) {
      setIsLoadingData(false);
    }
  };

  const filteredOptionsSegment = (inputValue: string) => {
    return isCompradorFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptionsComprador = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsSegment(inputValue));
  };

  const loadComprador = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/listar?size=100000`;

      const response = await axios.get(url);
      const {content} = response.data;

      const options = content.map((item: IComprador) => ({
        value: item.id,
        label: item.nomeUsuario,
      }));

      setIsCompradorFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadCompradorById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsAsyncSelectComprador({label: data.nomeUsuario, value: data.id});

      return [
        {
          value: data.id,
          label: data.titulo,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  useEffect(() => {
    const fetchCompradorOptions = async () => {
      const options = (await loadComprador()) || [];
      setDefaultCompradorOptions(options);
    };

    fetchCompradorOptions();
  }, []);

  useEffect(() => {
    if (isEdit && isOpen) {
      getPendenciaById();
    }
  }, [idItem, isEdit, isOpen]);

  return (
    <>
      <Modal {...modalConfigs} icon={isEdit ? FaEdit : FaPlus}>
        <div>
          {isLoadingData ? (
            <Skeleton count={4} height={70} />
          ) : (
            <form className='form-control-solid' onSubmit={formik.handleSubmit}>
              <Row className='mt-4'>
                <Col md='4' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Data de Pendência:
                  </label>

                  <DatePicker
                    className='w-100'
                    format='dd-MM-yyyy'
                    placeholder='Data de Faturamento'
                    onChange={(date) => setIsDataPendencia(date)}
                    value={isDataPendencia}
                  />
                </Col>
                <Col md='4' className='col-12'>
                  <label className='form-label'>Status:</label>
                  <select
                    {...formik.getFieldProps('status')}
                    className={`form-control ${
                      formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                    }`}
                  >
                    <option value='1'>ATIVO</option>
                    <option value='0'>INATIVO</option>
                  </select>
                </Col>
                <Col md='4' className='col-12'>
                  <label className='form-label'>Quantidade de Pendência:</label>
                  <input
                    type='text'
                    placeholder='Digite a quantidade de pendência'
                    {...formik.getFieldProps('quantidadePendencia')}
                    className={`form-control ${
                      formik.touched.quantidadePendencia && formik.errors.quantidadePendencia
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col md='12' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Pedido:
                  </label>

                  <AsyncSelectPrevenda
                    handleOptions={(option) => handleSelectAsyncPrevenda(option!)}
                    inputOption={isAsyncSelectPedido}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        setIsAsyncSelectPedido(null);
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col md='12' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Produto:
                  </label>

                  <AsyncSelectProduct
                    handleOptions={(option) => handleSelectAsyncProduto(option!)}
                    inputOption={isAsyncSelectProduto}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        setIsAsyncSelectProduto(null);
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col md='12' className='col-12'>
                  <label htmlFor='' className='form-label'>
                    Selecione o Comprador:
                  </label>
                  <AsyncSelect
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 99999,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        zIndex: 99999,
                      }),
                    }}
                    isClearable
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    placeholder='Selecione o Comprador'
                    loadOptions={loadFilteredOptionsComprador}
                    value={isAsyncSelectComprador}
                    onChange={(selectedOption: ISelectOption | null) => {
                      // Verifica se selectedOption não é null antes de definir o estado
                      if (selectedOption) {
                        setIsAsyncSelectComprador(selectedOption);
                      } else {
                        setIsAsyncSelectComprador(null); // Define como null caso a opção seja anulada
                      }
                    }}
                    cacheOptions
                    defaultOptions={defaultCompradorOptions}
                  />
                </Col>
              </Row>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PendenciaCompraModal;
