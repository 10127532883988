import React, {createContext, ReactNode, useState} from 'react';

// Definindo o tipo do contexto
interface PedidoCompraContextType {
  actionTap: () => void;
  setActionTap: (callback: () => void) => void;
}

// Valor inicial do contexto
export const PedidoCompraHookContext = createContext<PedidoCompraContextType | undefined>(
  undefined
);

// Provedor do contexto
interface TabProviderProps {
  children: ReactNode;
}

export const PedidoCompraProvider: React.FC<TabProviderProps> = ({children}) => {
  const [actionTap, setActionTap] = useState<() => void>(() => () => {});
  

  return (
    <PedidoCompraHookContext.Provider value={{actionTap, setActionTap}}>
      {children}
    </PedidoCompraHookContext.Provider>
  );
};

export default PedidoCompraHookContext;
