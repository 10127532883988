import axios, {Axios} from 'axios';
import {CadastroUsuarioModel} from '../cadastro_models/cadastro_models';
import {InfosDadosparceiro, InfosPessoaContato} from '../parceiros/Interface';
import {InfosDadosProduto, ListPrecoInfo, ProdutoOption} from '../produto/Interface';
import Swal from 'sweetalert2';
import {
  ICategorizacaoProduto,
  ICododigoBarras,
  ICondicoesPagamento,
  IExecRotinaSQL,
  IFluxoLogistico,
  IFormasPagamento,
  IFormulario,
  IFrete,
  IHeaders,
  IItemsSeparacao,
  ILogisticaSeparacao,
  INotaFiscal,
  IParametroRotinaSQL,
  IPedidoCompra,
  IPedidoCompraItem,
  IProdutoSimilar,
  IRotinasSQL,
  IUnidadeMedida,
  IEndereco,
  ITipoVenda,
  ISelectOption,
  IMeiosPagamento,
  IFornecedorDisponibilidadeCreate,
  IPendenciaCompraCrud,
  IFormularioArquivo,
} from '../../../shared';
import {IpcNetConnectOpts} from 'net';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const API_USUARIO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios`;
export const API_EMPRESAS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas`;
export const API_CLIENTE = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes`;
export const API_FORNECEDOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedor`;
export const API_COLABORADOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/colaboradores`;
export const API_PREVENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas`;
export const API_TIPO_VENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipoVenda`;
export const API_PERFIS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/perfis`;
export const API_CONSULTORES = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/consultores`;
export const API_PARCEIROS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`;
export const API_PROFISSIONAIS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/profissionais`;
export const API_W_APROVACAO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowaprovacao`;
export const API_PRODUTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos`;
export const API_PRODUTO_CONSUMO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-consumo`;
export const API_W_COMPONENTE = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowcomponentes`;
export const API_W_SEQUENCIA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowsequencia`;
export const API_W_PENDENCIA_APROV = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowpendencias/aprovaPendencia`;
export const API_W_PENDENCIA_REJEI = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowpendencias/rejeitaPendencia`;
export const API_FORMULARIOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios`;
export const API_FORMULARIOS_ARQUIVOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios-arquivos`;
export const API_FLUXO_LOGISTICO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo`;
export const API_LOGISTICA_SEPARACAO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao`;
export const GET_LOGISTICA_SEPARACAO_ITEMS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao-itens`;
export const API_CATEGORIZACAO_PRODUTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto`;
export const API_CONFIGURACAO_ROTINAS_SQL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/configuracao-rotinas-sql`;
export const API_CONFIGURACAO_ROTINAS_SQL_PARAMETROS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/configuracao-rotinas-sql-parametros`;
export const API_UNIDADE_MEDIDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-medida`;
export const API_SUPRIMENTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status`;
export const API_FORMAS_PAGAMENTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento`;
export const API_CONDICAO_PAGAMENTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/condicoespagamento`;
export const API_EAN = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-ean`;
export const API_PEDIDO_COMPRAS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra`;
export const API_FORNECEDOR_DISPONIBILIDADE = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores-disponibilidade`;
export const API_PEDIDO_ITENS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra-itens`;
export const API_PEDIDO_COMPRA_FRETE = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete`;
export const API_PEDIDO_COMPRA_NOTA_FISCAL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fiscal/notas-fiscais`;
export const API_ENDERECO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos`;
export const API_PRODUTO_SIMILAR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-similares`;
export const API_PEDIDO_COMPRA_PAGAMENTOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra-pagamentos`;
export const API_PENDENCIA_VENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pendencia-compra`;

//USERS
export function sendUser(
  nome: string,
  sobrenome: string,
  email: string,
  tipousuario: number,
  idPerfil: number,
  password: string,
  login: string,
  status: number,
  isfinanceiro: number,
  idempresas?: number[],
  ips?: any[],
  ipAddressForce?: any,
  idConsultorPadrao?: string
) {
  return axios.post<CadastroUsuarioModel>(API_USUARIO, {
    nome,
    sobrenome,
    email,
    tipousuario,
    idPerfil,
    password,
    login,
    status,
    isfinanceiro,
    idempresas,
    ips,
    ipAddressForce,
    idConsultorPadrao,
  });
}

export function sendUpdateUser(
  id: number,
  nome: string,
  sobrenome: string,
  email: string,
  tipousuario: number,
  idPerfil: number,
  status: number,
  isfinanceiro: number,
  idempresas?: number[],
  ips?: any[],
  password?: string,
  ipAddressForce?: any,
  empresaPadraoId?: any,
  idConsultorPadrao?: string
) {
  return axios.put<CadastroUsuarioModel>(API_USUARIO, {
    id,
    nome,
    sobrenome,
    email,
    tipousuario,
    idPerfil,
    status,
    isfinanceiro,
    idempresas,
    ips,
    password,
    ipAddressForce,
    empresaPadraoId,
    idConsultorPadrao,
  });
}

//EMPRESAS
export function sendEmpresa(
  nome: string,
  razaosocial: string,
  fantasia: string,
  cnpj: string,
  codigo: string,
  endereco: string,
  cidade: string,
  estado: string,
  pais: string,
  cep: string,
  telefone: string,
  email: string,
  status: number,
  website?: string,
  isProdutosGerador?: number
) {
  return axios.post(API_EMPRESAS, {
    nome,
    razaosocial,
    fantasia,
    cnpj,
    codigo,
    endereco,
    cidade,
    estado,
    pais,
    cep,
    telefone,
    email,
    status,
    website,
    isProjetosGerador: isProdutosGerador,
  });
}

export function sendUpdateEmpresa(
  id: number,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cnpj: string,
  codigo: string,
  endereco: string,
  cidade: string,
  estado: string,
  pais: string,
  cep: string,
  telefone: string,
  email: string,
  status: number,
  website?: string,
  isProdutosGerador?: number
) {
  return axios.put(API_EMPRESAS, {
    id,
    nome,
    razaosocial,
    fantasia,
    cnpj,
    codigo,
    endereco,
    cidade,
    estado,
    pais,
    cep,
    telefone,
    email,
    status,
    website,
    isProjetosGerador: isProdutosGerador,
  });
}

//CLIENTES
export function sendCliente(
  tipocliente: string,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cpf: string,
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  emailComercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok
  creationdatecliente: string, //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  usercreation?: string,
  emailnfe?: string,
  obscontato?: string,
  estadocivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaopagamentopadrao?: string,
  codregimetributario?: number,
  limitecredito?: number,
  profissao?: string,
  sexocliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteicms?: number,
  telefoneadicional?: string,
  tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string,
  obrigaOrdemCompra?: number
) {
  return axios.post(API_CLIENTE, {
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    emailComercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    creationdatecliente,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    usercreation,
    emailnfe,
    obscontato,
    estadocivil,
    statuscrm,
    vendedorPadrao,
    condicaopagamentopadrao,
    codregimetributario,
    limitecredito,
    profissao,
    sexocliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteicms,
    telefoneadicional,
    tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
    obrigaOrdemCompra,
  });
}

export function updateCliente(
  id: number,
  tipocliente: string, //ok
  nome: string, //ok
  razaosocial: string, //ok
  fantasia: string, //ok
  cpf: string, //ok
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  emailComercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string,
  creationdatecliente: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  userupdate?: string,
  emailnfe?: string,
  obscontato?: string,
  estadocivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaopagamentopadrao?: string,
  codregimetributario?: number,
  limitecredito?: number,
  profissao?: string,
  sexocliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteicms?: number,
  telefoneadicional?: string,
  tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string,
  obrigaOrdemCompra?: number
) {
  return axios.put(API_CLIENTE, {
    id,
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    emailComercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    creationdatecliente,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    userupdate,
    emailnfe,
    obscontato,
    estadocivil,
    statuscrm,
    vendedorPadrao,
    condicaopagamentopadrao,
    codregimetributario,
    limitecredito,
    profissao,
    sexocliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteicms,
    telefoneadicional,
    tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
    obrigaOrdemCompra,
  });
}

//Colaboradores

export function sendColaborador(
  tipocliente: string,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cpf: string,
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  email_comercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok
  creationdatecliente: string, //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  usercreation?: string,
  emailnfe?: string,
  obscontato?: string,
  estadoCivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaoPagamentoPadrao?: string,
  codregimetributario?: number,
  limiteCredito?: number,
  profissao?: string,
  sexoCliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteIcms?: number,
  telefoneadicional?: string,
  tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string
) {
  return axios.post(API_COLABORADOR, {
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    email_comercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    creationdatecliente,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    usercreation,
    emailnfe,
    obscontato,
    estadoCivil,
    statuscrm,
    vendedorPadrao,
    condicaoPagamentoPadrao,
    codregimetributario,
    limiteCredito,
    profissao,
    sexoCliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteIcms,
    telefoneadicional,
    tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
  });
}

export function updateColaborador(
  id: number,
  tipocliente: string,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cpf: string,
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  email_comercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok
  creationdatecliente: string, //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  usercreation?: string,
  emailnfe?: string,
  obscontato?: string,
  estadoCivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaoPagamentoPadrao?: string,
  codregimetributario?: number,
  limiteCredito?: number,
  profissao?: string,
  sexoCliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteIcms?: number,
  telefoneadicional?: string,
  // tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string
) {
  return axios.put(`${API_COLABORADOR}/${id}`, {
    id,
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    email_comercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    creationdatecliente,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    usercreation,
    emailnfe,
    obscontato,
    estadoCivil,
    statuscrm,
    vendedorPadrao,
    condicaoPagamentoPadrao,
    codregimetributario,
    limiteCredito,
    profissao,
    sexoCliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteIcms,
    telefoneadicional,
    // tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
  });
}

//Fornecedores

export function sendFornecedor(
  tipocliente: string,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cpf: string,
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  email_comercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok
  creationdatecliente: string, //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  usercreation?: string,
  emailnfe?: string,
  obscontato?: string,
  estadoCivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaoPagamentoPadrao?: string,
  codregimetributario?: number,
  limiteCredito?: number,
  profissao?: string,
  sexoCliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteIcms?: number,
  telefoneadicional?: string,
  tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string
) {
  return axios.post(API_FORNECEDOR, {
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    email_comercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    creationdatecliente,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    usercreation,
    emailnfe,
    obscontato,
    estadoCivil,
    statuscrm,
    vendedorPadrao,
    condicaoPagamentoPadrao,
    codregimetributario,
    limiteCredito,
    profissao,
    sexoCliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteIcms,
    telefoneadicional,
    tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
  });
}

export function updateFornecedor(
  id: number,
  tipocliente: string,
  nome: string,
  razaosocial: string,
  fantasia: string,
  cpf: string,
  cnpj: string, //ok
  codigo: string, //ok
  endereco: string, //ok
  numero: string, //ok
  bairro: string, //ok
  pontoreferencia: string, //ok
  complemento: string, //ok
  cidade: string, //ok
  cidadeid: string, //ok
  estado: string, //ok
  estadoid: string, //ok
  pais: string, //ok
  paisid: string, //ok
  cep: string, //ok
  celular: string, //ok
  telefone: string, //ok
  email: string,
  email_comercial: string, //ok
  status: number, //ok
  contacorrente: number, //ok
  indicadorie: number, //ok
  inscricaoestadual: string, //ok
  inscricaosuframa: string, //ok
  inscricaomunicipal: string,
  vendedorid: number, //ok
  nascimento: string, //ok
  contato: string, //ok
  segmentoid: number, //ok
  profissaoid: number, //ok
  responsavel: string, //ok
  creationdatecliente: string, //ok
  responsavelid: string, //ok
  tabelaprecos1: number, //ok
  regiaotributaria: number, //ok
  piscofins: number, //ok
  desconto: string, //ok
  descontoboleto: string, //ok
  obsnotafiscal: string, //ok
  obscliente: string, //ok
  podecomprar: number, //ok
  podeprotestar: number, //ok
  podereter: number, //ok
  website?: string, //ok
  usercreation?: string,
  emailnfe?: string,
  obscontato?: string,
  estadoCivil?: number,
  statuscrm?: number,
  vendedorPadrao?: string,
  condicaoPagamentoPadrao?: string,
  codregimetributario?: number,
  limiteCredito?: number,
  profissao?: string,
  sexoCliente?: number,
  naturalidade?: string,
  nomePai?: string,
  cpfPai?: string,
  nomeMae?: string,
  cpfMae?: string,
  documentoidentificacao?: string,
  rg?: string,
  contribuinteIcms?: number,
  telefoneadicional?: string,
  // tipopessoa?: string,
  tipoorigemid?: number,
  pessoasContato?: any,
  vendedorpadraoid?: string
) {
  return axios.put(`${API_FORNECEDOR}/${id}`, {
    id,
    tipocliente,
    nome,
    razaosocial,
    fantasia,
    cpf,
    cnpj,
    codigo,
    endereco,
    numero,
    bairro,
    pontoreferencia,
    complemento,
    cidade,
    cidadeid,
    estado,
    estadoid,
    pais,
    paisid,
    cep,
    celular,
    telefone,
    email,
    email_comercial,
    status,
    contacorrente,
    indicadorie,
    inscricaoestadual,
    inscricaosuframa,
    inscricaomunicipal,
    vendedorid,
    nascimento,
    contato,
    segmentoid,
    profissaoid,
    responsavel,
    creationdatecliente,
    responsavelid,
    tabelaprecos1,
    regiaotributaria,
    piscofins,
    desconto,
    descontoboleto,
    obsnotafiscal,
    obscliente,
    podecomprar,
    podeprotestar,
    podereter,
    website,
    usercreation,
    emailnfe,
    obscontato,
    estadoCivil,
    statuscrm,
    vendedorPadrao,
    condicaoPagamentoPadrao,
    codregimetributario,
    limiteCredito,
    profissao,
    sexoCliente,
    naturalidade,
    nomePai,
    cpfPai,
    nomeMae,
    cpfMae,
    documentoidentificacao,
    rg,
    contribuinteIcms,
    telefoneadicional,
    // tipopessoa,
    tipoorigemid,
    pessoasContato,
    vendedorpadraoid,
  });
}

export const createDisponibilidade = (data: IFornecedorDisponibilidadeCreate) => {
  return axios.post(`${API_FORNECEDOR_DISPONIBILIDADE}`, data);
};

export const updateDisponibilidade = (data: IFornecedorDisponibilidadeCreate, id: number) => {
  return axios.put(`${API_FORNECEDOR_DISPONIBILIDADE}/${id}`, data);
};

//PREVENDAS

export function sendPrevenda(
  values,
  nomecliente: string,
  nomeparceiro: string,
  nomecloser: string,
  nomeorcamentista: string,
  nomeconsultor: string,
  total: number,
  produtosPrevenda: any,
  pagamentosPrevenda?: any,
  usuarioUpdate?: string,
  projetostatus?: number,
  fretestatus?: number,
  obsstatus?: number,
  produtoNaoEncontradoStatus?: number,
  ordenacao?: number,
  margemProposta?: number,
  fluxoLogistico?: boolean,
  isEntregaParcial?: boolean,
  idTipoVenda?: number,
  dataAcordadoCliente?: Date | string,
  idProjeto?: number
) {
  return axios.post(API_PREVENDA, {
    dataAcordadoCliente: dataAcordadoCliente,
    dataemissao: values.dataEmissao,
    vencimento: values.dataVencimento,
    clienteid: values.clienteid,
    parceiroid: values.parceiroid,
    closerid: values.closerid,
    orcamentistaid: values.orcamentistaid,
    consultorid: values.consultorid,
    almoxarifadoid: values.almoxarifadoid,
    empresaid: values.empresaid,
    formapagamentoid: values.formapagamentoid,
    condicaoid: values.condicaoid,
    status: values.status,
    desconto: values.desconto,
    descontoporcentagem: values.descontoporcentagem,
    tipoorigemid: values.tipoorigemid,
    tabelaprecoid: values.tabelaprecoid,
    responsavel1Entrega: values.responsavel1Entrega,
    responsavel2Entrega: values.responsavel2Entrega,
    celular1Entrega: values.celular1Entrega,
    celular2Entrega: values.celular2Entrega,
    profissao1Entrega: values.profissao1Entrega,
    profissao2Entrega: values.profissao2Entrega,
    data1Entrega: values.data1Entrega,
    data2Entrega: values.data2Entrega,
    horaEntrega: values.horaEntrega,
    cepEntrega: values.cepEntrega,
    enderecoEntrega: values.enderecoEntrega,
    bairroEntrega: values.bairroEntrega,
    cidadeEntrega: values.cidadeEntrega,
    ufEntrega: values.ufEntrega,
    numeroEntrega: values.numeroEntrega,
    pontoReferencia1Entrega: values.pontoReferencia1Entrega,
    pontoReferencia2Entrega: values.pontoReferencia2Entrega,
    usuariocriacao: values.usuariocriacao,
    obsprevenda: values.obsprevenda,
    kinboxlink: values.kinboxlink,
    valorFrete: values.valorFrete,
    cifFob: values.cifFob,
    produtoNaoEncontrado: values.produtoNaoEncontrado,
    observacaoEntrega: values.observacaoEntrega,
    nomecliente,
    nomecloser,
    nomeconsultor,
    nomeparceiro,
    nomeorcamentista,
    total,
    produtosPrevenda,
    pagamentosPrevenda,
    usuarioUpdate,
    projetostatus,
    fretestatus,
    obsstatus,
    produtoNaoEncontradoStatus,
    ordenacao,
    margemProposta,
    fluxoLogistico,
    isEntregaParcial,
    idTipoVenda,
    idProjeto,
  });
}

export function updatePrevenda(
  id: any,
  values,
  nomecliente: string,
  nomeparceiro: string,
  nomeorcamentista: string,
  nomeconsultor: string,
  total: number,
  produtosPrevenda: any,
  pagamentosPrevenda?: any,
  usuarioUpdate?: string,
  projetostatus?: number,
  fretestatus?: number,
  obsstatus?: number,
  produtoNaoEncontradoStatus?: number,
  ordenacao?: number,
  margemProposta?: number,
  updateProduto?: boolean,
  fluxoLogistico?: boolean,
  isEntregaParcial?: boolean,
  idTipoVenda?: number,
  dataAcordadoCliente?: Date | string,
  idProjeto?: number
) {
  return axios.put(API_PREVENDA, {
    id,
    dataAcordadoCliente: dataAcordadoCliente,
    dataemissao: values.dataEmissao,
    vencimento: values.dataVencimento,
    clienteid: values.clienteid,
    parceiroid: values.parceiroid,
    closerid: values.closerid,
    orcamentistaid: values.orcamentistaid,
    consultorid: values.consultorid,
    almoxarifadoid: values.almoxarifadoid,
    empresaid: values.empresaid,
    formapagamentoid: values.formapagamentoid,
    condicaoid: values.condicaoid,
    status: values.status,
    desconto: values.desconto,
    descontoporcentagem: values.descontoporcentagem,
    tipoorigemid: values.tipoorigemid,
    tabelaprecoid: values.tabelaprecoid,
    responsavel1Entrega: values.responsavel1Entrega,
    responsavel2Entrega: values.responsavel2Entrega,
    celular1Entrega: values.celular1Entrega,
    celular2Entrega: values.celular2Entrega,
    profissao1Entrega: values.profissao1Entrega,
    profissao2Entrega: values.profissao2Entrega,
    data1Entrega: values.data1Entrega,
    data2Entrega: values.data2Entrega,
    horaEntrega: values.horaEntrega,
    cepEntrega: values.cepEntrega,
    enderecoEntrega: values.enderecoEntrega,
    bairroEntrega: values.bairroEntrega,
    cidadeEntrega: values.cidadeEntrega,
    ufEntrega: values.ufEntrega,
    numeroEntrega: values.numeroEntrega,
    pontoReferencia1Entrega: values.pontoReferencia1Entrega,
    pontoReferencia2Entrega: values.pontoReferencia2Entrega,
    usuariocriacao: values.usuariocriacao,
    obsprevenda: values.obsprevenda,
    kinboxlink: values.kinboxlink,
    valorFrete: values.valorFrete,
    cifFob: values.cifFob,
    produtoNaoEncontrado: values.produtoNaoEncontrado,
    observacaoEntrega: values.observacaoEntrega,
    nomecliente,
    nomeparceiro,
    nomeorcamentista,
    nomeconsultor,
    total,
    produtosPrevenda: updateProduto ? produtosPrevenda : null,
    pagamentosPrevenda,
    usuarioUpdate,
    projetostatus,
    fretestatus,
    obsstatus,
    produtoNaoEncontradoStatus,
    ordenacao,
    margemProposta,
    fluxoLogistico,
    isEntregaParcial,
    idTipoVenda,
    idProjeto,
  });
}

export function sendPerfis(
  titulo: string,
  descricao: string,
  status: number,
  perfilFormularios: any
) {
  return axios.post(API_PERFIS, {
    titulo,
    descricao,
    status,
    perfilFormularios,
  });
}

export function updatePerfis(
  id: any,
  titulo: string,
  descricao: string,
  status: number,
  perfilFormularios: any
) {
  return axios.put(API_PERFIS, {
    id,
    titulo,
    descricao,
    status,
    perfilFormularios,
  });
}

export function sendConsultor(
  consultor: string,
  cpf: string,
  contato: string,
  idConsultor: number
) {
  return axios.post(API_CONSULTORES, {
    consultor,
    cpf,
    contato,
    idConsultor,
  });
}

export function updateConsultor(
  id: any,
  consultor: string,
  cpf: string,
  contato: string,
  idConsultor: number
) {
  return axios.put(API_CONSULTORES, {
    consultor,
    cpf,
    contato,
    idConsultor,
  });
}

//Pendência de Venda

export const sendPendenciaVenda = (data: IPendenciaCompraCrud) => {
  return axios.post(`${API_PENDENCIA_VENDA}`, data);
};

export const putPendenciaVenda = (id: number, data: IPendenciaCompraCrud) => {
  return axios.put(`${API_PENDENCIA_VENDA}/${id}`, data);
};

//Pedido de Compras

export const sendPedidoCompra = (data: IPedidoCompra) => {
  return axios.post(API_PEDIDO_COMPRAS, data);
};

export const editPedidoCompra = (id: string | number, data: IPedidoCompra) => {
  return axios.put(`${API_PEDIDO_COMPRAS}/${id}`, data);
};

export const sendPedidoCompraItem = (data: IPedidoCompraItem) => {
  return axios.post(`${API_PEDIDO_ITENS}`, data);
};

export const editPedidoCompraItem = (id: string | number, data: IPedidoCompraItem) => {
  return axios.put(`${API_PEDIDO_ITENS}/${id}`, data);
};

export const sendPedidoCompraFrete = (data: IFrete) => {
  return axios.post(API_PEDIDO_COMPRA_FRETE, data);
};

export const editPedidoCompraFrete = (id: string | number, data: IFrete) => {
  return axios.put(`${API_PEDIDO_COMPRA_FRETE}/${id}`, data);
};

export const sendPedidoCompraNotaFiscal = (data: INotaFiscal) => {
  return axios.post(API_PEDIDO_COMPRA_NOTA_FISCAL, data);
};

export const editPedidoCompraNotaFiscal = (id: string | number, data: INotaFiscal) => {
  return axios.put(`${API_PEDIDO_COMPRA_NOTA_FISCAL}/${id}`, data);
};

export const vincularNotaFiscal = (
  idNotaFiscal: number,
  data: number[],
  idPedidoCompra: number | string
) => {
  const params = new URLSearchParams();
  params.append('idNotaFiscal', idNotaFiscal.toString());
  params.append('idPedidoCompra', idPedidoCompra.toString());
  data.forEach((item) => params.append('idPedidoCompraItem', item.toString()));

  return axios.put(`${API_PEDIDO_COMPRA_NOTA_FISCAL}/vincular-nota-fiscal?${params}`);
};

export const vincularFrete = (idNotaFiscal: number, data: number[]) => {
  const params = new URLSearchParams();
  params.append('idFrete', idNotaFiscal.toString());
  data.forEach((item) => params.append('idPedidoCompraItem', item.toString()));

  return axios.put(`${API_PEDIDO_ITENS}/vincular-frete?${params}`);
};

export const importarNotaFiscal = (id: string | number) => {
  return axios.put(
    `${API_PEDIDO_COMPRA_NOTA_FISCAL}/importar-nfe-entrada-produto?idNotaFiscal=${id}`
  );
};

//Produtos
//Tipo de Venda
export const sendTipoVenda = (data: ITipoVenda) => {
  console.log('sendTipoVenda: ', data);
  return axios.post(API_TIPO_VENDA, data);
};
export const editTipoVenda = (id: string | number, data: ITipoVenda) => {
  return axios.put(`${API_TIPO_VENDA}/${id}`, data);
};

export const sendEan = (data: ICododigoBarras) => {
  return axios.post(API_EAN, data);
};

export const sendProdutoSimilar = (data: IProdutoSimilar) => {
  return axios.post(API_PRODUTO_SIMILAR, data);
};

export const editEan = (id: string | number, data: ICododigoBarras) => {
  return axios.put(`${API_EAN}/${id}`, data);
};

export const editProdutoSimilar = (id: string | number, data: IProdutoSimilar) => {
  return axios.put(`${API_PRODUTO_SIMILAR}/${id}`, data);
};

export const sendUnidadeMedida = (data: IUnidadeMedida) => {
  return axios.post(API_UNIDADE_MEDIDA, data);
};

export const postSuprimento = (data: IUnidadeMedida) => {
  return axios.post(API_SUPRIMENTO, data);
};

export const putUnidadeMedida = (id: string | number, data: IUnidadeMedida) => {
  return axios.put(`${API_UNIDADE_MEDIDA}/${id}`, data);
};

export const putCondicoesPagamento = (id: string | number, data: ICondicoesPagamento) => {
  return axios.put(`${API_CONDICAO_PAGAMENTO}/${id}`, data);
};

// Pedido de Compras

export function sendPedidoCompraPagamentos(id: string | number, data: IMeiosPagamento[]) {
  return axios.post(`${API_PEDIDO_COMPRA_PAGAMENTOS}?idPedidoCompra=${id}`, data);
}

export const sendFormasDePagamento = (data: IFormasPagamento) => {
  return axios.post(API_FORMAS_PAGAMENTO, data);
};

export const sendCondicoesDePagamento = (data: IFormasPagamento) => {
  return axios.post(API_CONDICAO_PAGAMENTO, data);
};

export const editFormasDePagamento = (id: string | number, data: IFormasPagamento) => {
  return axios.post(`${API_FORMAS_PAGAMENTO}/${id}`, data);
};
export const putFormasPagamento = (id: string | number, data: IFormasPagamento) => {
  return axios.put(`${API_FORMAS_PAGAMENTO}/${id}`, data);
};

export const putCondicaoPagamento = (id: string | number, data: IFormasPagamento) => {
  return axios.put(`${API_CONDICAO_PAGAMENTO}/${id}`, data);
};

export const cadastrarFormasDePagamento = (id: string | number, data: IFormasPagamento) => {
  return axios.post(`${API_FORMAS_PAGAMENTO}/${id}`, data);
};

export const putSuprimento = (id: string | number, data: IUnidadeMedida) => {
  return axios.put(`${API_SUPRIMENTO}/${id}`, data);
};
export const sendCategorizacaoProduto = (data: ICategorizacaoProduto) => {
  return axios.post(API_CATEGORIZACAO_PRODUTO, data);
};

export const putCategorizacaoProduto = (id: string | number, data: ICategorizacaoProduto) => {
  return axios.put(`${API_CATEGORIZACAO_PRODUTO}/${id}`, data);
};

export const sendSeparacaoLogistica = ({idPrevenda, idLogisticaFluxo, status, tipoEntregaId}) => {
  return axios.post(API_LOGISTICA_SEPARACAO, {
    idPrevenda,
    idLogisticaFluxo,
    status,
    tipoEntregaId,
  });
};

export const editSeparacaoLogistica = async (
  id: number | string,
  data: ILogisticaSeparacao | null
) => {
  return axios.put(`${API_LOGISTICA_SEPARACAO}/${id}`, data);
};

export const addLogisticaSeparacaoItem = async (data: IItemsSeparacao) => {
  return axios.post(`${GET_LOGISTICA_SEPARACAO_ITEMS}`, data);
};

export const getSeparacaoLogisticaById = (id: string | number) => {
  return axios.get(`${API_LOGISTICA_SEPARACAO}/${id}`);
};

export const addParametroRotinaSQL = ({
  idConfigRotinaSQL,
  titulo,
  descricao,
  parameter,
  status,
}: IParametroRotinaSQL) => {
  return axios.post(API_CONFIGURACAO_ROTINAS_SQL_PARAMETROS, {
    idConfigRotinaSQL,
    titulo,
    descricao,
    parameter,
    status,
  });
};

export const editParametroRotinaSQL = (id: string | number, data: IParametroRotinaSQL) => {
  return axios.put(`${API_CONFIGURACAO_ROTINAS_SQL_PARAMETROS}/${id}`, data);
};

export const createRotinaSQL = ({
  titulo,
  descricao,
  storedProcedure,
  status,
  hasReturn,
}: IRotinasSQL) => {
  return axios.post(API_CONFIGURACAO_ROTINAS_SQL, {
    titulo,
    descricao,
    storedProcedure,
    status,
    hasReturn,
  });
};

export const executarRotinaSQL = (id: string | number, data: IExecRotinaSQL) => {
  return axios.put(`${API_CONFIGURACAO_ROTINAS_SQL}/executar/${id}?parametros=${data.parametros}`);
};

export const editRotinaSQL = (id: string | number, data: IRotinasSQL) => {
  return axios.put(`${API_CONFIGURACAO_ROTINAS_SQL}/${id}`, data);
};

export const sendFormulario = ({arquivo, descricao, status}: IFormulario) => {
  return axios.post(API_FORMULARIOS, {
    arquivo,
    descricao,
    status,
  });
};

export const sendFormularioArquivo = (data: IFormularioArquivo) => {
  return axios.post(`${API_FORMULARIOS_ARQUIVOS}/salvar`, data);
};

export const putFormularioArquivo = (id: string | number, data: IFormularioArquivo) => {
  return axios.put(`${API_FORMULARIOS_ARQUIVOS}/${id}`, data);
};

export const cadastarSuprimento = ({id, descricao, status}: IHeaders) => {
  return axios.post(API_FORMULARIOS, {
    id,
    descricao,
    status,
  });
};

export const getFormularios = (id: string | number) => {
  return axios.get(`${API_FORMULARIOS}/${id}`, {});
};

export const editFormulario = (id: string | number, data: IFormulario) => {
  return axios.put(`${API_FORMULARIOS}/${id}`, data);
};

export const sendFluxoLogistico = ({titulo, descricao, status}: IFluxoLogistico) => {
  console.log('sendFluxoLogistico', titulo, descricao, status);
  return axios.post(API_FLUXO_LOGISTICO, {
    titulo,
    descricao,
    status,
  });
};

export const getFluxoLogistico = (id: string | number) => {
  return axios.get(`${API_FLUXO_LOGISTICO}/${id}`, {});
};

export const editFluxoLogistico = (id: string | number, data: IFluxoLogistico) => {
  console.log('data', data);
  return axios.put(`${API_FLUXO_LOGISTICO}/${id}`, data);
};
export const sendEndereco = ({
  rua,
  complemento,
  bairro,
  cidade,
  uf,
  cep,
  numero,
  referencia,
}: IEndereco) => {
  return axios.post(API_ENDERECO, {
    rua,
    complemento,
    bairro,
    cidade,
    uf,
    cep,
    numero,
    referencia,
  });
};
export const editEndereco = (id: string | number, data: IEndereco) => {
  // console.log('data', data);
  return axios.put(`${API_ENDERECO}/${id}`, data);
};

export function sendParceiro(
  values: InfosDadosparceiro,
  pessoasContato: InfosPessoaContato,
  base64Image: string
) {
  return axios.post(API_PROFISSIONAIS, {
    parceiro: values.nome_razaosocial,
    cnpj: values.cnpj_cpf,
    contato: values.telefone,
    fantasia: values.nome_fantasia,
    tipoPessoa: values.tipo_cadastro,
    inscricaoestadual: values.inscricao_estudal_id,
    comissao: values.comissao,
    vendedorid: values.vendedor,
    idParceiro: values.codigo,
    observacao: values.observacoes,
    endereco: values.endereco,
    numero: values.numero,
    codigo: values.codigo,
    complemento: values.complemento,
    bairro: values.bairro,
    cep: values.cep,
    telefone: values.telefone,
    celular: values.celular,
    cidade: values.cidade,
    uf: values.estado,
    idParceiroClassificao: values.idParceiroClassificacao,
    email: values.email,
    website: values.website,
    status: values.status,
    nascimento: values.data_nasc,
    tipoParceiro: values.tipo_parceiro,
    tipoPix: values.tipoDeChave,
    pessoasContato,
    imgParceiroBase64: base64Image,
    idBanco: values.banco,
    agencia: values.agencia,
    conta: values.conta,
    observacoes: values.observacoes,
    tipoChavePix: values.tipoDeChave,
    chavePix: values.chavePix,
    obsStatus: values.obsStatus,
    isParceiro: values.is_parceiro,
    createdat: values.createdat,
    idParceiroClassificacao: values.idParceiroClassificacao,
    parceiroClassificacaoTitulo: values.parceiroClassificacaoTitulo,
    descricaoTipoProfissional: values.descricaoTipoProfissional,
  });
}

export function updateParceiro(
  id: any,
  values: InfosDadosparceiro,
  pessoasContato: InfosPessoaContato,
  base64Image: string
) {
  return axios.put(`${API_PROFISSIONAIS}/${id}`, {
    id,
    parceiro: values.nome_razaosocial,
    cnpj: values.cnpj_cpf,
    contato: values.telefone,
    fantasia: values.nome_fantasia,
    tipoPessoa: values.tipo_cadastro,
    inscricaoestadual: values.inscricao_estudal_id,
    comissao: values.comissao,
    vendedorid: values.vendedor,
    idParceiro: values.codigo,
    observacao: values.observacoes,
    endereco: values.endereco,
    numero: values.numero,
    complemento: values.complemento,
    bairro: values.bairro,
    cep: values.cep,
    telefone: values.telefone,
    celular: values.celular,
    cidade: values.cidade,
    uf: values.estado,
    idParceiroClassificao: values.idParceiroClassificacao,
    email: values.email,
    website: values.website,
    status: values.status,
    nascimento: values.data_nasc,
    tipoParceiro: values.tipo_parceiro,
    tipoPix: values.tipoDeChave,
    pessoasContato,
    imgParceiroBase64: base64Image,
    idBanco: values.banco,
    agencia: values.agencia,
    conta: values.conta,
    tipoChavePix: values.tipoDeChave,
    chavePix: values.chavePix,
    obsStatus: values.obsStatus,
    isParceiro: values.is_parceiro,
    createdat: values.createdat,
    idParceiroClassificacao: values.idParceiroClassificacao,
    parceiroClassificacaoTitulo: values.parceiroClassificacaoTitulo,
    descricaoTipoProfissional: values.descricaoTipoProfissional,
  });
}

export function send_W_Aprovacao(titulo: string, id_formulario: string, status: number) {
  return axios.post(API_W_APROVACAO, {
    titulo,
    idFormulario: id_formulario,
    status,
  });
}

export function update_W_Aprovacao(id: any, titulo: string, id_formulario: string, status: number) {
  return axios.put(API_W_APROVACAO, {
    id,
    titulo,
    idFormulario: id_formulario,
    status,
  });
}

export function sendWorkFlowRejei(id, formData) {
  const url =
    `${API_W_PENDENCIA_REJEI}?idPendencia=${id}&` + new URLSearchParams(formData).toString();

  return axios
    .put(url, formData)
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Ação realizada com sucesso!',
          timer: 800,
          showConfirmButton: false,
          timerProgressBar: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao atualizar a aprovação',
          text: 'Verifique as informações preenchidas e tente novamente.',
          confirmButtonText: 'Ok',
        });
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Erro ao rejeitar a pendência:', error);
      throw error;
    });
}

export function sendWorkFlowAprov(id, formData) {
  const url =
    `${API_W_PENDENCIA_APROV}?idPendencia=${id}&` + new URLSearchParams(formData).toString();

  return axios
    .put(url, formData)
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Ação realizada com sucesso!',
          timer: 800,
          showConfirmButton: false,
          timerProgressBar: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao atualizar a aprovação',
          text: 'Verifique as informações preenchidas e tente novamente.',
          confirmButtonText: 'Ok',
        });
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function send_W_Componente(
  idWorkflowAprovacao: string,
  codigoComponente: string,
  status: number
) {
  return axios.post(API_W_COMPONENTE, {
    idWorkflowAprovacao,
    codigoComponente,
    status,
  });
}

export function update_W_Componente(
  id: any,
  idWorkflowAprovacao: string,
  codigoComponente: string,
  status: number
) {
  return axios.put(API_W_COMPONENTE, {
    id,
    idWorkflowAprovacao,
    codigoComponente,
    status,
  });
}

export function send_W_Sequencia(
  idWorkflowComponente: number,
  sequencia: number,
  isFinal: number | undefined,
  idUsuarioAprovador: number,
  status: number | undefined
) {
  return axios.post(API_W_SEQUENCIA, {
    idWorkflowComponente,
    sequencia,
    isFinal,
    idUsuarioAprovador,
    status,
  });
}

export function update_W_Sequencia(
  id: any,
  idWorkflowComponente: number,
  sequencia: number,
  isFinal: number | undefined,
  idUsuarioAprovador: number,
  status: number | undefined
) {
  return axios.put(API_W_SEQUENCIA, {
    id,
    idWorkflowComponente,
    sequencia,
    isFinal,
    idUsuarioAprovador,
    status,
  });
}

interface OptionType {
  value: number;
  label: string;
}
export function send_Produto(
  values: InfosDadosProduto,
  tabelasDePrecosProduto: ListPrecoInfo[],
  selectedOrigem: ProdutoOption,
  selectedMarca: ISelectOption | null,
  selectedTipo: ProdutoOption,
  selectedTipo_Embalagem: ProdutoOption,
  selectedEmbalagem: ProdutoOption,
  selectedCategoria: ProdutoOption,
  selectedLinhaProduto: ProdutoOption,
  base64Image: String | null,
  isEcommerce: number,
  isDisponivelFabrica: number,
  isDivulgacao: number,
  isEstoqueFabricacao: number,
  isMix: number,
  isAguardandoComplemento: number,
  isLinhaProduto: ISelectOption | null,
  isCliente: ISelectOption | null
) {
  return axios.post(API_PRODUTO, {
    produtosStatusParametrosForm: {
      isCompra: values.isCompra !== '' ? values.isCompra : null,
      isVenda: values.isVenda !== '' ? values.isVenda : null,
      isRessuprimento: values.isRessuprimento !== '' ? values.isRessuprimento : null,
      isEcommerce: isEcommerce,
      isFabricacaoDisponivel: isDisponivelFabrica,
      isDivulgacao: isDivulgacao,
      isEstoqueFabricacao: isEstoqueFabricacao,
      isMix: isMix,
      isAguardandoComplemento: isAguardandoComplemento,
    },
    idLinhaProduto: isLinhaProduto?.value,
    idCliente: isCliente ? isCliente.value : null,
    isCompra: values.isCompra,
    isVenda: values.isVenda,
    isRessuprimento: values.isRessuprimento,
    isEcommerce: isEcommerce,
    isFabricacaoDisponivel: isDisponivelFabrica,
    isDivulgacao: isDivulgacao,
    isEstoqueFabricacao: isEstoqueFabricacao,
    isMix: isMix,
    descricao: values.descricao,
    codigo_sku: values.codigo_sku,
    origem_id: selectedOrigem.id,
    origem_desc: selectedOrigem.desc,
    tipo_id: selectedTipo.id,
    tipo_desc: selectedTipo.desc,
    ncm: values.ncm,
    gtin_ean: values.gtin_ean,
    cest: values.cest,
    // preco_venda: values.preco_venda,
    preco_promocional: values.preco_promocional,
    unidade: values.unidade,
    idUnidadeMedida: values.idUnidadeMedida,
    idFornecedor: values.idFornecedor,
    peso_liq: values.peso_liq,
    peso_bruto: values.peso_bruto,
    num_volumes: values.num_volumes,
    tipo_embalagem_id: selectedTipo_Embalagem.id,
    tipo_embalagem_desc: selectedTipo_Embalagem.desc,
    embalagem_id: selectedEmbalagem.id,
    embalagem_desc: selectedEmbalagem.desc,
    largura: values.largura,
    altura: values.altura,
    comprimento: values.comprimento,
    controlar_estoque: values.controlar_estoque,
    estoque_inicial: values.estoque_inicial,
    estoque_min: values.estoque_min,
    estoque_max: values.estoque_max,
    sob_encomenda: values.sob_encomenda,
    controlar_lotes: values.controlar_lotes,
    localizacao: values.localizacao,
    dias_preparacao: values.dias_preparacao,
    categoria_id: values.categoria_id ? values.categoria_id : null,
    categoria_desc: selectedCategoria.desc,
    marca_id: selectedMarca?.value,
    marca_desc: selectedMarca?.label,
    desc_complementar: values.desc_complementar,
    img_produto_base64: base64Image,
    img_url: values.img_url,
    link_video: values.link_video,
    slug: values.slug,
    keywords: values.keywords,
    titulo_seo: values.titulo_seo,
    custo_a_partir_de: values.custo_a_partir_de,
    custo_saldo_atual: values.custo_saldo_atual,
    custo_saldo_anterior: values.custo_saldo_anterior,
    custo_impostos_rec: values.custo_impostos_rec,
    custo_preco: values.custo_preco,
    custo_medio: values.custo_medio,
    unidade_conversao: values.unidade_conversao,
    mark_up: values.mark_up,
    preco_compra: values.preco_compra,
    custo_preco_venda: values.custo_preco_venda,
    fabricante: values.fabricante,
    codigo_fabricante: values.codigo_fabricante,
    uni_por_caixas: values.uni_por_caixas,
    linha_de_produto_id: selectedLinhaProduto.id,
    linha_de_produto_desc: selectedLinhaProduto.desc,
    garantia: values.garantia,
    gtin_ean_tributavel: values.gtin_ean_tributavel,
    unidade_tributavel: values.unidade_tributavel,
    fator_de_conversao: values.fator_de_conversao,
    codigo_enquadramento_ipi: values.codigo_enquadramento_ipi,
    valor_ipi_fixo: values.valor_ipi_fixo,
    obs_geral_produto: values.obs_geral_produto,
    status: values.status,
    tabelasDePrecosProduto: tabelasDePrecosProduto,
    valorNegociacao: values.valorNegociacao,
    substituicaoTributaria: values.substituicaoTributaria,
    valorFrete: values.valorFrete,
    diferencialAliquota: values.diferencialAliquota,
    idGarantia: values.idGarantia,
    idUnidadeMedidaCompra: values.idUnidadeMedidaCompra,
    fatorConversaoInicial: values.fatorConversaoInicial,
    fatorConversaoFinal: values.fatorConversaoFinal,
  });
}

export function update_Produto(
  id: any,
  values: InfosDadosProduto,
  tabelasDePrecosProduto: ListPrecoInfo[],
  selectedOrigem: ProdutoOption,
  selectedMarca: OptionType | null,
  selectedTipo: ProdutoOption,
  selectedTipo_Embalagem: ProdutoOption,
  selectedEmbalagem: ProdutoOption,
  selectedCategoria: ProdutoOption,
  selectedLinhaProduto: ProdutoOption,
  base64Image: String | null,
  isEcommerce: number,
  isDisponivelFabrica: number,
  isDivulgacao: number,
  isEstoqueFabricacao: number,
  isMix: number,
  isAguardandoComplemento: number,
  isLinhaProduto: ISelectOption | null,
  isCliente: ISelectOption | null
) {
  return axios.put(API_PRODUTO, {
    id,
    produtosStatusParametrosForm: {
      isCompra: values.isCompra !== '' ? values.isCompra : null,
      isVenda: values.isVenda !== '' ? values.isVenda : null,
      isRessuprimento: values.isRessuprimento !== '' ? values.isRessuprimento : null,
      isEcommerce: isEcommerce,
      isFabricacaoDisponivel: isDisponivelFabrica,
      isDivulgacao: isDivulgacao,
      isEstoqueFabricacao: isEstoqueFabricacao,
      isMix: isMix,
      isAguardandoComplemento: isAguardandoComplemento,
    },
    idLinhaProduto: isLinhaProduto?.value,
    idCliente: isCliente ? isCliente.value : null,
    descricao: values.descricao,
    codigo_sku: values.codigo_sku,
    origem_id: selectedOrigem.id,
    origem_desc: selectedOrigem.desc,
    tipo_id: selectedTipo.id,
    tipo_desc: selectedTipo.desc,
    ncm: values.ncm,
    gtin_ean: values.gtin_ean,
    cest: values.cest,
    // preco_venda: values.preco_venda,
    preco_promocional: values.preco_promocional,
    unidade: values.unidade,
    idUnidadeMedida: values.idUnidadeMedida,
    idFornecedor: values.idFornecedor,
    peso_liq: values.peso_liq,
    peso_bruto: values.peso_bruto,
    num_volumes: values.num_volumes,
    tipo_embalagem_id: selectedTipo_Embalagem.id,
    tipo_embalagem_desc: selectedTipo_Embalagem.desc,
    embalagem_id: selectedEmbalagem.id,
    embalagem_desc: selectedEmbalagem.desc,
    largura: values.largura,
    altura: values.altura,
    comprimento: values.comprimento,
    controlar_estoque: values.controlar_estoque,
    estoque_inicial: values.estoque_inicial,
    estoque_min: values.estoque_min,
    estoque_max: values.estoque_max,
    sob_encomenda: values.sob_encomenda,
    controlar_lotes: values.controlar_lotes,
    localizacao: values.localizacao,
    dias_preparacao: values.dias_preparacao,
    categoria_id: values.categoria_id ? values.categoria_id : null,
    categoria_desc: selectedCategoria.desc,
    marca_id: selectedMarca?.value,
    marca_desc: selectedMarca?.label,
    desc_complementar: values.desc_complementar,
    img_produto_base64: base64Image,
    img_url: values.img_url,
    link_video: values.link_video,
    slug: values.slug,
    keywords: values.keywords,
    titulo_seo: values.titulo_seo,
    custo_a_partir_de: values.custo_a_partir_de,
    custo_saldo_atual: values.custo_saldo_atual,
    custo_saldo_anterior: values.custo_saldo_anterior,
    custo_impostos_rec: values.custo_impostos_rec,
    custo_preco: values.custo_preco,
    custo_medio: values.custo_medio,
    unidade_conversao: values.unidade_conversao,
    mark_up: values.mark_up,
    preco_compra: values.preco_compra,
    custo_preco_venda: values.custo_preco_venda,
    fabricante: values.fabricante,
    codigo_fabricante: values.codigo_fabricante,
    uni_por_caixas: values.uni_por_caixas,
    linha_de_produto_id: selectedLinhaProduto.id,
    linha_de_produto_desc: selectedLinhaProduto.desc,
    garantia: values.garantia,
    gtin_ean_tributavel: values.gtin_ean_tributavel,
    unidade_tributavel: values.unidade_tributavel,
    fator_de_conversao: values.fator_de_conversao,
    codigo_enquadramento_ipi: values.codigo_enquadramento_ipi,
    valor_ipi_fixo: values.valor_ipi_fixo,
    obs_geral_produto: values.obs_geral_produto,
    status: values.status,
    tabelasDePrecosProduto: tabelasDePrecosProduto,
    valorNegociacao: values.valorNegociacao,
    substituicaoTributaria: values.substituicaoTributaria,
    valorFrete: values.valorFrete,
    diferencialAliquota: values.diferencialAliquota,
    idGarantia: values.idGarantia,

    idUnidadeMedidaCompra: values.idUnidadeMedidaCompra,
    fatorConversaoInicial: values.fatorConversaoInicial,
    fatorConversaoFinal: values.fatorConversaoFinal,
  });
}

export function send_Produto_Consumo(
  values: InfosDadosProduto
  // tabelasDePrecosProduto: ListPrecoInfo[],
  // selectedOrigem: ProdutoOption,
  // selectedMarca: ISelectOption | null,
  // selectedTipo: ProdutoOption,
  // selectedTipo_Embalagem: ProdutoOption,
  // selectedEmbalagem: ProdutoOption,
  // selectedCategoria: ProdutoOption,
  // selectedLinhaProduto: ProdutoOption,
  // base64Image: String | null,
  // isEcommerce: number,
  // isDisponivelFabrica: number,
  // isDivulgacao: number,
  // isEstoqueFabricacao: number,
  // isMix: number,
  // isAguardandoComplemento: number,
  // isLinhaProduto: ISelectOption | null
) {
  console.log('estou aqui', values.idTipoEstoque);
  // const id=values.idTipoEstoque
  return axios.post(API_PRODUTO_CONSUMO, {
    // produtosStatusParametrosForm: {
    //   isCompra: values.isCompra !== '' ? values.isCompra : null,
    //   isVenda: values.isVenda !== '' ? values.isVenda : null,
    //   isRessuprimento: values.isRessuprimento !== '' ? values.isRessuprimento : null,
    //   isEcommerce: isEcommerce,
    //   isFabricacaoDisponivel: isDisponivelFabrica,
    //   isDivulgacao: isDivulgacao,
    //   isEstoqueFabricacao: isEstoqueFabricacao,
    //   isMix: isMix,
    //   isAguardandoComplemento: isAguardandoComplemento,
    // },
    descricao: values.descricao,
    idTipoEstoque: values.idTipoEstoque,
    ncm: values.ncm,
    idUnidadeMedida: values.idUnidadeMedida,
    status: values.status,
    controlar_estoque: values.controlar_estoque,
    // estoque_inicial: values.estoque_inicial,
    estoque_min: values.estoque_min,
    estoque_max: values.estoque_max,
  });
}

export function update_Produto_Consumo(id: any, values: InfosDadosProduto) {
  console.log('tipo estoque', values);
  return axios.put(`${API_PRODUTO_CONSUMO}/${id}`, {
    id,
    // produtosStatusParametrosForm: {
    //   isCompra: values.isCompra !== '' ? values.isCompra : null,
    //   isVenda: values.isVenda !== '' ? values.isVenda : null,
    //   isRessuprimento: values.isRessuprimento !== '' ? values.isRessuprimento : null,
    //   isEcommerce: isEcommerce,
    //   isFabricacaoDisponivel: isDisponivelFabrica,
    //   isDivulgacao: isDivulgacao,
    //   isEstoqueFabricacao: isEstoqueFabricacao,
    //   isMix: isMix,
    //   isAguardandoComplemento: isAguardandoComplemento,
    // },
    // idLinhaProduto: isLinhaProduto?.value,
    descricao: values.descricao,
    idTipoEstoque: values.idTipoEstoque,
    ncm: values.ncm,
    idUnidadeMedida: values.idUnidadeMedida,
    status: values.status,
    controlar_estoque: values.controlar_estoque,
    // estoque_inicial: values.estoque_inicial,
    estoque_min: values.estoque_min,
    estoque_max: values.estoque_max,
    // codigo_sku: values.codigo_sku,
    // origem_id: selectedOrigem.id,
    // origem_desc: selectedOrigem.desc,
    // tipo_id: selectedTipo.id,
    // tipo_desc: selectedTipo.desc,
    // gtin_ean: values.gtin_ean,
    // cest: values.cest,
    // preco_venda: values.preco_venda,
    // preco_promocional: values.preco_promocional,
    // unidade: values.unidade,
    // idFornecedor: values.idFornecedo/r,
    // peso_liq: values.peso_liq,
    // peso_bruto: values.peso_bruto,
    // num_volumes: values.num_volumes,
    // tipo_embalagem_id: selectedTipo_Embalagem.id,
    // tipo_embalagem_desc: selectedTipo_Embalagem.desc,
    // embalagem_id: selectedEmbalagem.id,
    // embalagem_desc: selectedEmbalagem.desc,
    // largura: values.largura,
    // altura: values.altura,
    // comprimento: values.comprimento,

    // descricao: string,
    // idTipoEstoque: 0,
    // ncm: string,
    // idUnidadeMedida: 0,
    // status: 0,
    // controlar_estoque: 0,
    // estoque_min: 0,
    // estoque_max: 0,
    // tabelasDePrecosProduto: tabelasDePrecosProduto,
    // valorNegociacao: values.valorNegociacao,
    // substituicaoTributaria: values.substituicaoTributaria,
    // valorFrete: values.valorFrete,
    // diferencialAliquota: values.diferencialAliquota,
  });
}
