import {Button, Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../cadastros/proposta-pedido/types/interface';
import {validarCep} from '../../useUtils/validarCep';
import {useNavigate, useParams} from 'react-router-dom';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const Empreendimentos = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [codigo, setCodigo] = useState<number | null>(0);
  const [quantidade, setQuantidade] = useState<number | null>(0);
  const [titulo, setTitulo] = useState<string | null>('');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [observacoes, setObservacoes] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);
  const [endereco, setEndereco] = useState<string | null>('');
  const [numero, setNumero] = useState<string>('');
  const [bairro, setBairro] = useState<string | null>('');
  const [pontoReferencia, setPontoReferencia] = useState<string | null>('');
  const [complemento, setComplemento] = useState<string | null>('');
  const [cidade, setCidade] = useState<string | null>('');
  const [estado, setEstado] = useState<string | null>('');
  const [pais, setPais] = useState<string | null>('');
  const [cep, setCep] = useState<string | null>(null);
  const [celular, setCelular] = useState<string | null>('');
  const [telefone, setTelefone] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [contato, setContato] = useState<string | null>('');
  const [selectedSegmento, setSelectedSegmento] = useState<ClienteOption | null>(null);
  const [selectedClassificacao, setSelectedClassificacao] = useState<ClienteOption | null>(null);
  const [selectedFaseDaObra, setSelectedFaseDaObra] = useState<ClienteOption | null>(null);
  const [selectedTipoDeObra, setSelectedTipoDeObra] = useState<ClienteOption | null>(null);
  const [selectedPorte, setSelectedPorte] = useState<ClienteOption | null>(null);
  const [selectedUnidadeMedida, setSelectedUnidadeMedida] = useState<ClienteOption | null>(null);
  const [responsavel, setResponsavel] = useState<string | null>('');
  const [tamanhoObra, setTamanhoObra] = useState<string | null>('');
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const uploadEmprendimento = async (id) => {
    try {
      setIsLoading(true);

      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos${id ? `/${id}` : ''}`;

      let method=id? axios.put : axios.post;

      const response = await method(url, {
        idTipoObra: selectedTipoDeObra?.value,
        idFaseObra: selectedFaseDaObra?.value,
        tamanhoObra: tamanhoObra,
        codigo: codigo,
        titulo: titulo,
        descricao: descricao,
        observacoes: observacoes,
        website: website,
        endereco: endereco,
        numero: /^\d+$/.test(numero) ? numero : 'SN',
        quantidade: quantidade,
        bairro: bairro,
        pontoReferencia: pontoReferencia,
        complemento: complemento,
        cidade: cidade ? cidade : null,
        estado: estado ? estado : null,
        pais: pais ? pais : null,
        cep: cep,
        celular: celular ? celular : null,
        telefone: telefone ? telefone : null,
        email: email ? email : null,
        contato: contato ? contato : null,
        idSegmentoEmpreendimento: selectedSegmento?.value,
        idClassificacao: selectedClassificacao?.value,
        idPorte: selectedPorte?.value,
        responsavel: responsavel,
        status: status,
      });

      Swal.fire({
        icon: 'success',
        title: `Empreendimento ${id ? 'editado' : 'cadastrado'} com sucesso`,
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        navigate('/empreendimentos');
        clearFiltros();
      });
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data[0].mensagem,
        showCancelButton: false,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };
  const buscarCep = async (cep) => {
    console.log(cep);
    //   const buscaCep = async () => {
    const cepValido = await validarCep(cep);
    if (!cepValido) {
      Swal.fire({
        icon: 'error',
        title: 'Não foi possível localizar este CEP',
        timer: 3000,
        timerProgressBar: true,
      });
      setCep(cep);
      return;
    } else {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      // const {data} = response
      console.log(data);
      setComplemento(data.complemento);
      setEndereco(data.logradouro);
      setBairro(data.bairro);
      setCep(data.cep);
      setCidade(data.localidade);
      setEstado(data.estado);
      setPais('BRASIL');
    }
  };
  const fetchEmpreendimentoById = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/${id}`
      );

      console.log(response.data);
      const {content, data, totalPages} = response.data;

      setCodigo(Number(response.data.codigo));
      setTitulo(response.data.titulo);
      setDescricao(response.data.descricao);
      setObservacoes(response.data.observacoes);
      setWebsite(response.data.website);
      setEndereco(response.data.endereco);
      setNumero(response.data.numero);
      setQuantidade(response.data.quantidade);
      setBairro(response.data.bairro);
      setPontoReferencia(response.data.pontoReferencia);
      setComplemento(response.data.complemento);
      setCidade(response.data.cidade);
      setEstado(response.data.estado);
      setPais(response.data.pais);
      setCep(response.data.cep);
      setCelular(response.data.celular);
      setTelefone(response.data.telefone);
      setEmail(response.data.email);
      setContato(response.data.contato);
      setSelectedSegmento({
        value: response.data.idSegmentoEmpreendimento,
        label: response.data.segmentoEmpreendimento,
      });
      setSelectedTipoDeObra({value: response.data.idTipoObra, label: response.data.tipoObra});
      setSelectedFaseDaObra({value: response.data.idFaseObra, label: response.data.faseObra});
      setSelectedUnidadeMedida({
        value: response.data.idUnidadeMedida,
        label: response.data.unidadeMedida,
      });
      setSelectedClassificacao({
        value: response.data.idClassificacao,
        label: response.data.classificacao,
      });
      setSelectedPorte({value: response.data.idPorte, label: response.data.porte});
      setResponsavel(response.data.responsavel);
      setTamanhoObra(response.data.tamanhoObra);
      setStatus(response.data.status);
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Empreendimentos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };
  const loadOptionsSegmento = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsTipoDeObra = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/tipo-obra/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsClassificacao = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/classificacao/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsFaseDaObra = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/fase-obra/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsPorte = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/porte/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const loadOptionsUnidadeMedida = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-medida/listar`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptions = async (endpoint) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/${endpoint}/listar`;

      const response = await axios.get(url, {
        params: {
          size: 100000000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      console.log(data);
      // return data.map((item) => ({ value: item.id, label: item.titulo }));
      // alert(data[0].titulo);
      return { value: data[0].id, label: data[0].titulo }
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };
  const clearFiltros = () => {
    setCodigo(0);
    setTitulo('');
    setDescricao('');
    setObservacoes('');
    setWebsite('');
    setEndereco(null);
    setNumero('');
    setQuantidade(null);
    setBairro(null);
    setPontoReferencia('');
    setComplemento('');
    setCidade('');
    setEstado('');
    setPais('');
    setCep(null);
    setCelular('');
    setTelefone('');
    setEmail('');
    setContato('');
    setSelectedSegmento(null);
    setSelectedClassificacao(null);
    setSelectedPorte(null);
    setResponsavel('');
    setTamanhoObra('');
    setSelectedUnidadeMedida(null);
    setStatus('ATIVO');
  };

  useEffect(() => {
    if (id) {
      fetchEmpreendimentoById();
    }
  }, [id]);

  useEffect(() => {
    if (
      titulo !== '' &&
      codigo !== 0 &&
      !cep?.includes('_') &&
      cep !== null &&
      cep!.length !== 0 &&
      numero !== '' &&
      quantidade !== 0 &&
      pontoReferencia !== '' &&
      endereco !== '' &&
      bairro !== '' &&
      complemento !== '' &&
      pais !== '' &&
      estado !== '' &&
      cidade !== '' &&
      selectedSegmento &&
      selectedFaseDaObra &&
      selectedTipoDeObra &&
      selectedClassificacao &&
      selectedPorte &&
      // selectedUnidadeMedida &&

      tamanhoObra !== null &&
      tamanhoObra !== '0' &&
      tamanhoObra !== ''
    ) {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  }, [
    endereco,
    bairro,
    complemento,
    pais,
    estado,
    cidade,
    selectedSegmento,
    selectedClassificacao,
    selectedFaseDaObra,
    selectedTipoDeObra,
    selectedPorte,
    tamanhoObra,
    selectedUnidadeMedida,
    quantidade,
    responsavel,
    cep,
    titulo,
    codigo,
    numero,
    pontoReferencia,
    complemento,
    status,
  ]);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder ' style={{color: '#3f3f3f'}}>
          {id ? 'Editar' : 'Cadastrar'} Empreendimento
        </h1>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid '}}></div>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Dados Gerais</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className=' row my-8'>
          <Col className='mt-5 mt-md-0 col-md-4 col-12'>
            <label htmlFor='' className='form-label'>
              Titulo
            </label>
            <input
              type='text'
              value={titulo ? titulo : ''}
              onChange={(e) => setTitulo(e.target.value)}
              className='form-control'
              placeholder='Digite o titulo'
            />
            {titulo === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o titulo</span>
            )}
          </Col>
          <Col className='mt-5 mt-md-0 col-md-4 col-12'>
            <label htmlFor='' className='form-label'>
              Codigo
            </label>
            <input
              type='number'
              value={codigo ? codigo : ''}
              onChange={(e) => {
                if (e.target.value.length <= 9) {
                  // Limite de 10 caracteres
                  setCodigo(Number(e.target.value));
                }
              }}
              className='form-control'
              placeholder='Digite o codigo'
              maxLength={9}
            />
            {codigo === 0 && (
              <span className={` form-label text-danger`}>*Por favor, informe o codigo</span>
            )}
          </Col>
          <Col className='mt-5 mt-md-0 col-md-4 col-12'>
            <label htmlFor='' className='form-label'>
              Status
            </label>
            <select
              className='form-select'
              aria-label='Default select example'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>

        <Row className=' row my-5'>
          <Col className=' col-12'>
            <label htmlFor='' className='form-label'>
              Website:
            </label>
            <input
              type='text'
              value={website ? website : ''}
              onChange={(e) => setWebsite(e.target.value)}
              className='form-control'
              placeholder='Digite o website'
            />
          </Col>
        </Row>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Endereço</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className=' row my-5'>
          <Col className='mt-md-0 mt-6 col-md-4 col-12'>
            <label htmlFor='' className='form-label'>
              CEP
            </label>
            <InputMask
              value={cep}
              mask='99999-999'
              type='text'
              onChange={(e) => {
                setCep(e.target.value);
                if (!e.target.value.includes('_') && e.target.value.length !== 0) {
                  buscarCep(e.target.value);
                }
              }}
              className='form-control'
              placeholder='Digite o CEP'
            />
            {(cep?.includes('_') || cep === null || cep!.length === 0) && (
              <span className={` form-label text-danger`}>*Por favor, informe o CEP</span>
            )}
          </Col>
          <Col className='mt-md-0 mt-6 col-md-7 col-12'>
            <label htmlFor='' className='form-label'>
              Endereço
            </label>
            <input
              type='text'
              value={endereco ? endereco : ''}
              onChange={(e) => setEndereco(e.target.value)}
              className='form-control'
              placeholder='Digite o Endereço'
            />
            {endereco === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o endereço</span>
            )}
          </Col>
          <Col className='mt-md-0 mt-6 col-md-1'>
            <label htmlFor='' className='form-label'>
              Número:
            </label>
            <input
              type='text'
              value={numero ? numero : ''}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setNumero(e.target.value);
                  setNumero(e.target.value.replace(/[^0-9snSN]/g, ''));
                }
              }}
              className='form-control'
              placeholder='N*'
            />
            {/* {(numero === "") && <span className={` form-label text-danger`}>*Por favor, informe o número</span>} */}
            <small className='fw-bold text-danger'>Se não houver número, insira "SN"</small>
          </Col>
        </Row>

        <Row className=' row my-10'>
          <Col className='mb-md-0 col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              Complemento:
            </label>
            <input
              type='text'
              value={complemento ? complemento : ''}
              onChange={(e) => setComplemento(e.target.value)}
              className='form-control'
              placeholder='Digite o complemento'
            />
            {complemento === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o complemento</span>
            )}
          </Col>
          <Col className='mb-md-0 col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              Bairro:
            </label>
            <input
              type='text'
              value={bairro ? bairro : ''}
              onChange={(e) => setBairro(e.target.value)}
              className='form-control'
              placeholder='Digite o bairro'
            />
            {bairro === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o bairro</span>
            )}
          </Col>
          <Col className='mb-md-0 col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              Ponto de referência:
            </label>
            <input
              type='text'
              value={pontoReferencia ? pontoReferencia : ''}
              onChange={(e) => setPontoReferencia(e.target.value)}
              className='form-control'
              placeholder='Informe o Ponto de referência'
            />
            {pontoReferencia === '' && (
              <span className={` form-label text-danger`}>
                *Por favor, informe o ponto de referência
              </span>
            )}
          </Col>
        </Row>

        <Row className=' row my-5'>
          <Col className='col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              País:
            </label>
            <input
              type='text'
              value={pais ? pais : ''}
              onChange={(e) => setPais(e.target.value)}
              className='form-control'
              placeholder='Digite o pais'
            />
            {pais === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o pais</span>
            )}
          </Col>
          <Col className='col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              Estado:
            </label>
            <input
              type='text'
              value={estado ? estado : ''}
              onChange={(e) => setEstado(e.target.value)}
              className='form-control'
              placeholder='Digite o estado'
            />
            {estado === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o estado</span>
            )}
          </Col>
          <Col className='col-md-4 col-12 mb-8'>
            <label htmlFor='' className='form-label'>
              Cidade:
            </label>
            <input
              type='text'
              value={cidade ? cidade : ''}
              onChange={(e) => setCidade(e.target.value)}
              className='form-control'
              placeholder='Digite a cidade'
            />
            {cidade === '' && (
              <span className={` form-label text-danger`}>*Por favor, informe o cidade</span>
            )}
          </Col>
        </Row>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Contato</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className=' row my-5'>
          <Col className='col-md-6 col-12'>
            <label htmlFor='' className='form-label'>
              Celular:
            </label>
            <InputMask
              value={celular ? celular : ''}
              mask='(99) 99999-9999'
              type='text'
              onChange={
                (e) =>
                  // {if(!e.target.value.includes('_') ) {
                  setCelular(e.target.value)
                // }
              }
              // }
              className='form-control'
              placeholder='Digite o celular'
            />
            {/* {(celular === "" || celular?.includes('_')) && <span className={` form-label text-danger`}>*Por favor, informe o celular</span>} */}
          </Col>
          <Col className='mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Telefone
            </label>
            <InputMask
              value={telefone ? telefone : ''}
              mask='(99) 9999-9999'
              type='text'
              onChange={
                (e) =>
                  // {if(!e.target.value.includes('_')) {
                  setTelefone(e.target.value)
                // }}
              }
              className='form-control'
              placeholder='Digite o telefone'
            />
            {/* {(telefone === "" || telefone?.includes('_')) && <span className={` form-label text-danger`}>*Por favor, informe o telefone</span>} */}
          </Col>
        </Row>
        <Row className='my-8'>
          <Col className='col-md-6 col-12'>
            <label htmlFor='' className='form-label'>
              Email:
            </label>
            <input
              type='email'
              value={email ? email : ''}
              onChange={(e) => setEmail(e.target.value)}
              className='form-control'
              placeholder='Digite o email'
            />
            {/* {(email === ""|| !email?.includes('@') || !email?.includes('.')) && <span className={` form-label text-danger`}>*Por favor, informe um emaill</span>} */}
          </Col>
          {/* <Col className="">
                        <label htmlFor="" className="form-label">Contato:</label>
                        <input type="text" value={contato ? contato : ""} onChange={(e) => setContato(e.target.value)} className="form-control" placeholder="Digite o contato" />
                    </Col> */}

          <Col className='mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Quantidade:
            </label>
            <input
              type='text'
              value={quantidade ? quantidade : ''}
              onChange={(e) => setQuantidade(Number(e.target.value.replace(/[^\d]/g, '')))}
              className='form-control'
              placeholder='Digite a quantidade'
            />
            {(quantidade === 0 || quantidade === undefined) && (
              <span className={` form-label text-danger`}>*Por favor, informe a quantidade</span>
            )}
          </Col>
        </Row>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Segmento</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className=' row my-5'>
          <Col className='col-md-6 col-12'>
            <label htmlFor='' className='form-label'>
              Segmento:
            </label>
            <AsyncSelect
              value={selectedSegmento}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedSegmento(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedSegmento(selectedOption);
                console.log(selectedOption);
              }}
              loadOptions={loadOptionsSegmento}
              placeholder='Selecione o segmento'
              defaultOptions
            ></AsyncSelect>
            {selectedSegmento === null && (
              <span className={` form-label text-danger`}>*Por favor, informe o segmento</span>
            )}
          </Col>
          <Col className='mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Classificação:
            </label>
            <AsyncSelect
              value={selectedClassificacao}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedClassificacao(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedClassificacao(selectedOption);
                console.log(selectedOption);
              }}
              loadOptions={loadOptionsClassificacao}
              placeholder='Selecione a classificação'
              defaultOptions
            ></AsyncSelect>
            {selectedClassificacao === null && (
              <span className={` form-label text-danger`}>*Por favor, informe o classificação</span>
            )}
          </Col>
        </Row>
        <Row className='my-8'>
          <Col className='col-md-6 col-12'>
            <label htmlFor='' className='form-label'>
              Porte:
            </label>
            <AsyncSelect
              value={selectedPorte}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedPorte(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedPorte(selectedOption);
                console.log(selectedOption);
              }}
              loadOptions={loadOptionsPorte}
              placeholder='Selecione o porte'
              className='py-1'
              defaultOptions
            ></AsyncSelect>
            {selectedPorte === null && (
              <span className={` form-label text-danger`}>*Por favor, informe o porte</span>
            )}
          </Col>
          <Col className='mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Responsável:
            </label>
            <input
              type='text'
              value={responsavel ? responsavel : ''}
              onChange={(e) => setResponsavel(e.target.value)}
              className='form-control'
              placeholder='Informe o responsável'
            />
          </Col>
        </Row>
        <Row className=' row my-5'>
          <Col>
            <label htmlFor='' className='form-label'>
              Tipo De Obra:
            </label>
            <AsyncSelect
              value={selectedTipoDeObra}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedTipoDeObra(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedTipoDeObra(selectedOption);
                console.log(selectedOption);
              }}
              loadOptions={loadOptionsTipoDeObra}
              placeholder='Selecione a tipo de obra'
              defaultOptions
            ></AsyncSelect>
            {(selectedTipoDeObra === null || selectedTipoDeObra.label === null) && (
              <span className={` form-label text-danger`}>*Por favor, informe o tipo de obra</span>
            )}
          </Col>
          <Col className='col-md-6 col-12 mt-5 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Fase Da Obra:
            </label>
            <AsyncSelect
              value={selectedFaseDaObra}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedFaseDaObra(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {
                if (selectedOption) {
                  const idValue = isNaN(Number(selectedOption.value))
                    ? selectedOption.value // Caso seja texto, mantêm como string.
                    : Number(selectedOption.value);
                  console.log(selectedOption.value);
                }
                setSelectedFaseDaObra(selectedOption);
                console.log(selectedOption);
              }}
              loadOptions={loadOptionsFaseDaObra}
              placeholder='Selecione a fase da obra'
              defaultOptions
            ></AsyncSelect>
            {(selectedFaseDaObra === null || selectedFaseDaObra.label === null) && (
              <span className={` form-label text-danger`}>*Por favor, informe a Fase Da Obra</span>
            )}
          </Col>
        </Row>
        <Row className='my-8'>
          {/* <Col>
                        <label htmlFor="" className="form-label">Unidade de Medida:</label>
                        <AsyncSelect
                            value={selectedUnidadeMedida}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    setSelectedUnidadeMedida(null);
                                }
                            }} onChange={(selectedOption: ClienteOption | null) => {
                                if (selectedOption) {
                                    const idValue = isNaN(Number(selectedOption.value))
                                        ? selectedOption.value // Caso seja texto, mantêm como string.
                                        : Number(selectedOption.value);
                                    console.log(selectedOption.value)
                                }
                                setSelectedUnidadeMedida(selectedOption);
                                console.log(selectedOption)
                            }}
                            loadOptions={loadOptionsUnidadeMedida} placeholder="Selecione a unidade de medida" className="py-1" defaultOptions ></AsyncSelect>
                        {(selectedUnidadeMedida === null || selectedUnidadeMedida.label === null) && <span className={` form-label text-danger`}>*Por favor, informe a unidade de medida</span>}

                    </Col> */}
          <Col className='col-md-6 col-12'>
            <label htmlFor='' className='form-label '>
              Tamanho da obra:
            </label>
            <input
              type='text'
              value={
                tamanhoObra
                  ? `${parseFloat(String(tamanhoObra)).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : '0,00'
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const cleanedValue = newValue.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
                const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
                setTamanhoObra(numericValue.toString());
              }}
              className='form-control'
              placeholder='Informe o tamanho da obra'
            />
            {/* {(tamanhoObra === "") && <span className={` form-label text-danger`}>*Por favor, informe o tamanho da obra</span>} */}
            {(tamanhoObra === null || tamanhoObra === '0' || tamanhoObra === '') && (
              <span className={` form-label text-danger`}>
                *Por favor, informe o tamanho da obra
              </span>
            )}
          </Col>
        </Row>
      </div>

      <div className='mt-10 '>
        <div className=' mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4>Informações adicionais</h4>
          <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
        </div>
        <Row className=' row my-5'>
          <Col className=''>
            <label htmlFor='' className='form-label'>
              Observações:
            </label>
            <textarea
              name=''
              value={observacoes ? observacoes : ''}
              onChange={(e) => setObservacoes(e.target.value)}
              placeholder='Digite a observação do formulario'
              className='form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
        <Row className=' row my-5'>
          <Col className=''>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <textarea
              name=''
              value={descricao ? descricao : ''}
              onChange={(e) => setDescricao(e.target.value)}
              placeholder='Digite a descrição do formulario'
              className=' form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
      </div>

      <div className='d-flex justify-content-end mb-20 mt-10'>
        <div className=' d-flex justify-content-center'>
          <Button
            className='px-15 mx-9'
            onClick={() => navigate('/empreendimentos')}
            variant='danger'
          >
            Voltar
          </Button>
          <Button
            className='px-15'
            disabled={!isConfirmed}
            onClick={() => uploadEmprendimento(id === undefined ? undefined : id)}
            variant='success'
          >
            {isLoading ? (
              <Spinner animation='border' size='sm' />
            ) : id === undefined ? (
              'Cadastrar'
            ) : (
              'Editar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Empreendimentos;
