import axios from 'axios';
import {useEffect, useState} from 'react';
import {Badge, Button, Col, Dropdown, DropdownButton, Modal, Row, Spinner} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../cadastros/proposta-pedido/types/interface';
import {
  AsyncSelectCliente,
  AsyncSelectConsultor,
  AsyncSelectEmpresa,
  AsyncSelectUsuario,
  CrudHeader,
  formatarData,
  IProjetos,
  PDFView,
  smoothScrollToBottom,
  useModal,
} from '../../../shared';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import ModalProjetos from './components/ModalProjetos';
// import CrudHeader from '../../../shared/components/crud-header/crud-header';
import {Link, useNavigate} from 'react-router-dom';
import ModalAnexarPdf from './components/ModalAnexarPdf';
import {
  imprimirPDFProjeto,
  visualizarPDFProduto,
  visualizarPDFProjeto,
} from '../../listagem/listagem_requests/listagem_requests';
import ButtonOpcoesListagem from '../../empreendimento/segmento-empreendimento/components/ButtonOpcoesListagem';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export function visualizarPDFProjetoto(
  setModalShowPDFViewer,
  idSeparacao,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = ``;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idSeparacao);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

const TelaProjetos = () => {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<IProjetos[]>([]);
  const [page, setPage] = useState(0);
  const [countSkeleton, setCountSkeleton] = useState(40);

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();

  const [idProjeto, setIdProjeto] = useState<number | null>(null);

  const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null);
  const [selectedCliente, setSelectedCliente] = useState<ClienteOption | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [prioridade, setPrioridade] = useState<string | null>('');
  const [selectedStatus, setSelectedStatus] = useState<ClienteOption | null>(null);
  const [selectedSetor, setSelectedSetor] = useState<ClienteOption | null>(null);
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>('ATIVO');

  const [projetos, setProjetos] = useState<IProjetos[]>([]);

  const [isProjetosModal, openProjetosModal, closeProjetosModal] = useModal();

  const [isAnexarPdfModal, openAnexarPdfModal, closeAnexarPdfModal] = useModal();

  const fetchProjetos = async (page = 0) => {
    if (page === 0) {
      setPage(0);
    }

    setLoading(true);
    setIsLoadingSearch(true);

    try {
      console.log(dataFim);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/listar`,
        {
          params: {
            page: page,
            status: status,
            idEmpresa: selectedEmpresa ? selectedEmpresa.value : null,
            idSetor: selectedSetor ? selectedSetor.value : null,
            idCliente: selectedCliente ? selectedCliente.value : null,
            titulo: titulo ? titulo : null,
            descricao: descricao ? descricao : null,
            idProjetoStatus: selectedStatus ? selectedStatus.value : null,
            dataInicio: dataInicio ? dataInicio : null,
            dataFim: dataFim ? dataFim : null,
            prioridade: prioridade ? prioridade : null,
          },
        }
      );

      console.log(response.data.content);
      if (response.status === 200) {
        setProjetos((prev) =>
          page === 0 ? response.data.content : [...prev, ...response.data.content]
        );
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de projetos para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }

      setHasMore(response.data.totalPages >= page + 1);

      if (response.data.last) {
        setHasMore(false);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const loadMore = () => {
    setLoading(true);
    setLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };
  const handleSearch = (event) => {
    event.preventDefault();
    fetchProjetos();
  };

  const loadOptionsStatus = async (inputValue: string, state: Function) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-status/listar`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      return data.content.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const loadOptionsSetor = async (inputValue: string, state: Function) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/setores/getAllSetoresOptions`;

      const response = await axios.get(url, {
        params: {status: 'ATIVO'},
      });
      const data = await response.data;
      console.log(data.id);

      return data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  function imprimirPDFSeparacao(idSeparacao, setLoading) {
    const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${idProjeto}/download-pdf`;

    axios
      .get(url, {responseType: 'blob'})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Erro ao baixar o PDF');
        }

        const blob = response.data;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `separacao_${idSeparacao}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Erro ao baixar o PDF:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao baixar o PDF',
          text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
          confirmButtonText: 'Ok',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const openModal = (isEdit, ...data) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openProjetosModal();
  };

  const openModalAnexarPdf = (isEdit, ...data) => {
    // if (data) {
    //   setDataLine(data);
    // }
    // setIsEdit(isEdit);
    setIdProjeto(data[0]);
    openAnexarPdfModal();
  };
  const clearFiltros = () => {
    setSelectedEmpresa(null);
    setSelectedStatus(null);
    setSelectedSetor(null);
    setSelectedCliente(null);
    setPrioridade(null);
    setTitulo(null);
    setDataFim(null);
    setDataInicio(null);
    setDescricao(null);
    setStatus('ATIVO');
    setIsClear(true);
  };

  useEffect(() => {
    if (isClear) {
      fetchProjetos();
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    fetchProjetos(page);
  }, [page]);

  return (
    <div>
      <CrudHeader
      openModal={() => {
        navigate('/cadastro-projetos');
      }}
        title='Projetos'
        titleCadastro='Projetos'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        isLoading={isLoadingSearch}
      >
        <Row className=''>
          <Col className='col-md-6 col-12 mt-md-0 '>
            <label htmlFor='' className='form-label'>
              Empresa
            </label>
            {/* <input type="text" onChange={handleChangeEntida} className='form-control'  placeholder="Digite o Titulo" /> */}
            <AsyncSelectEmpresa
              //   handleOptions={(option) => setSelectedCliente(option!)}
              handleOptions={(option) => setSelectedEmpresa(option!)}
              inputOption={selectedEmpresa}
              //   isSelectDOM={false}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedEmpresa(null);
                }
              }}
            ></AsyncSelectEmpresa>
          </Col>
          <Col className='col-md-6 col-12 mt-8 mt-md-0 '>
            <label htmlFor='' className='form-label '>
              Cliente:
            </label>
            <AsyncSelectCliente
              handleOptions={(option) => setSelectedCliente(option!)}
              inputOption={selectedCliente}
              isSelectDOM={false}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedCliente(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className=' '>
          <Col className='col-md-6 col-12 mt-md-0 '>
            <label htmlFor='' className='form-label  '>
              Titulo
            </label>
            <input
              type='text'
              onChange={(e) => setTitulo(e.target.value)}
              value={titulo ? titulo : ''}
              className='form-control'
              placeholder='Digite o Titulo'
            />
          </Col>
          {/* <Col className='col-md-4 col-12 mt-8 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Setor:
            </label>
            <AsyncSelect
              defaultOptions
              value={selectedSetor}
              onKeyDown={() => {
                setSelectedSetor(null);
              }}
              onChange={(option) => {
                setSelectedSetor(option);
              }}
              loadOptions={loadOptionsSetor}
              placeholder='Selecione o Setor'
            ></AsyncSelect>
          </Col> */}
          
          {/* <Col className='col-md-3 col-12 mt-8 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Status do Projeto:
            </label>
            <AsyncSelect
              defaultOptions
              value={selectedStatus}
              onKeyDown={() => {
                setSelectedStatus(null);
              }}
              onChange={(option) => {
                setSelectedStatus(option);
              }}
              loadOptions={loadOptionsStatus}
              placeholder='Selecione o Status do Projeto'
            ></AsyncSelect>
          </Col> */}
        {/* </Row>
        <Row className=' '> */}
          {/* <Col className='col-md-3 col-12 mt-8 mt-md-0'>
            <label htmlFor='' className='form-label'>
              Prioridade:
            </label>
            <select
              name=''
              id=''
              onChange={(e) => setPrioridade(e.target.value)}
              value={prioridade ? prioridade : ''}
              className='form-select'
            >
              <option value=''>Selecione a prioridade</option>
              <option value='URGENTE'>URGENTE</option>
              <option value='IMPORTANTE'>IMPORTANTE</option>
              <option value='NAO_IMPORTANTE'>NAO IMPORTANTE</option>
            </select>
          </Col> */}

          {/* </Row>
<Row> */}
          {/* <Col className='col-md-3 col-12 mt-8 mt-md-0 '>
            <label className='form-label' htmlFor=''>
              Data de início
            </label>
            <input
              type='date'
              onChange={(e) => setDataInicio(e.target.value)}
              value={dataInicio ? dataInicio : ''}
              name=''
              className='form-control'
              id=''
            />
          </Col> */}

          {/* <Col className='col-md-3 col-12 mt-8 mt-md-0 '>
            <label className='form-label' htmlFor=''>
              Data de fim
            </label>
            <input
              type='date'
              onChange={(e) => setDataFim(e.target.value)}
              value={dataFim ? dataFim : ''}
              name=''
              className='form-control'
              id=''
            />
          </Col> */}

          <Col className='col-md-6 col-12 mt-8 mt-md-0 '>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
        <Row className=''>
          <Col>
            <label className='form-label' htmlFor=''>
              Descrição
            </label>
            <textarea
              name=''
              onChange={(e) => setDescricao(e.target.value)}
              value={descricao ? descricao : ''}
              placeholder='Digite a descrição do formularios'
              className='form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
      </CrudHeader>
      {loading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div className='table-responsive'>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800 overflow-visible'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Empresa</th>
                  {/* <th className='text-center'>Setor</th> */}
                  <th className='text-center'>Cliente</th>
                  {/* <th className='text-center'>Status do Projeto</th> */}
                  {/* <th className='text-center'>Prioridade</th> */}
                  {/* <th className='text-center'>Data de início</th> */}
                  {/* <th className='text-center'>Data de fim</th> */}
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado Por</th>
                  <th className='text-center'>Data de Criação</th>
                  <th className='text-center'>Alterado Por</th>
                  <th className='text-center'>Data de Alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {projetos.map((projeto) => (
                  <tr>
                    <td className='text-center'>{projeto.id}</td>
                    <td className='text-center'>{projeto.titulo}</td>
                    <td className='text-center'>{projeto.empresaFantasia}</td>
                    {/* <td className='text-center'>{projeto.setorTitulo}</td> */}
                    <td className='text-center'>{projeto.clienteFantasia}</td>
                    {/* <td className='text-center'>{projeto.projetoStatusTitulo}</td> */}
                    {/* <td className='text-center'>{projeto.prioridade}</td> */}
                    {/* <td className='text-center'>{formatarData(projeto.dataInicio)}</td> */}
                    {/* <td className='text-center'>{formatarData(projeto.dataFim)}</td> */}
                    <td className='text-center'>{projeto.descricao ? projeto.descricao : '...'}</td>
                    <td className='text-center'>{projeto.usuarioCriacaoNome}</td>
                    <td className='text-center'>{projeto.dataCriacao}</td>
                    <td className='text-center'>
                      {projeto.usuarioAlteracaoNome ? projeto.usuarioAlteracaoNome : '...'}
                    </td>
                    <td className='text-center'>
                      {projeto.dataAlteracao ? projeto.dataAlteracao : '...'}
                    </td>
                    <td><Badge className='text-white p-3 px-4 ' bg={`${projeto.status === 'ATIVO' ? 'success' : 'danger'}`}>{projeto.status}</Badge></td>
                    {/* <td className='text-center'>{projeto.status}</td> */}

                    <td className='d-flex'>
                      <div className='text-center'>
                        <ButtonOpcoesListagem
                          handleEdit={() => {
                            openModal(()=>{ navigate(`/editar-projeto/${projeto.id}`);
                            });
                          }}
                          rota={`/arquivos-do-projeto/${projeto.id}`}
                          titleLink={'GERENCIAR ARQUIVOS'}
                        ></ButtonOpcoesListagem>
                      </div>
                      <div className='dropdown' style={{marginLeft: '7px'}}>
                        <button
                          className='btn btn-sm btn-danger dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          <i className='bi bi-file-earmark-arrow-down'>PDF</i>
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <button
                              className='dropdown-item'
                              style={{color: 'red'}}
                              type='button'
                              onClick={() => openModalAnexarPdf(true, projeto.id)}
                            >
                              ANEXAR PDF
                            </button>
                          </li>
                          <li>
                            <button
                              className={`dropdown-item ${
                                projeto.pdf_base64 === null ? 'd-none' : ''
                              }`}
                              style={{color: 'red'}}
                              type='button'
                              // disabled={projeto.pdf_base64 === null}
                              onClick={() =>
                                visualizarPDFProjeto(
                                  setModalShowPDFViewer,
                                  projeto.id,
                                  setIsLoading,
                                  setIsPdfUrl,
                                  setIsIdSeparacao
                                )
                              }
                            >
                              VISUALIZAR PDF
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button variant='primary' onClick={() => imprimirPDFProjeto(isIdSeparacao, setIsLoading)}>
            Download
          </Button>
          <Button
            variant='secondary'
            onClick={(event) => {
              setModalShowPDFViewer(false);
            }}
          >
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>

      <ModalAnexarPdf
        reload={fetchProjetos}
        isOpen={isAnexarPdfModal}
        onHide={closeAnexarPdfModal}
        idProjeto={idProjeto}
      ></ModalAnexarPdf>

      <ModalProjetos
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchProjetos}
        isEdit={isEdit}
        isOpen={isProjetosModal}
        onHide={closeProjetosModal}
      ></ModalProjetos>
    </div>
  );
};
export default TelaProjetos;
