import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {ITipoOrigem, Modal} from '../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface ModalOrigemProps {
  isOpen: boolean;
  onHide: () => void;
  data: ITipoOrigem | null;
  isEdit: boolean;
  loadData: ({page}: {page: number}) => Promise<void>;
}

const ModalOrigem = ({isOpen, onHide, data, isEdit, loadData}: ModalOrigemProps) => {
  const [idTipoOrigem, setIdTipoOrigem] = useState<string | null>(null);
  const [tipoOrigem, setTipoOrigem] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setIdTipoOrigem(null);
    setTipoOrigem(null);
    setStatus('ATIVO');
  };

  const cadastrarOrigem = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipo-origem`;
      if (tipoOrigem && idTipoOrigem && status) {
        const response = await axios.post(url, {
          idTipoServico: Number(idTipoOrigem),
          tipoServico: tipoOrigem,
          status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Tipo de Origem cadastrada com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loadData({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarOrigem = async () => {
    setIsLoading(true);
    try {
      if (data) {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipo-origem/${data.id}`;
        if (idTipoOrigem && tipoOrigem && status) {
          const response = await axios.put(url, {
            idTipoServico: Number(idTipoOrigem),
            tipoServico: tipoOrigem,
            status,
          });

          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: `Tipo de Origem editada com sucesso`,
              timer: 3000,
              timerProgressBar: true,
            }).then(() => {
              onClose();
            });
            loadData({page: 0});
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data?.mensagem,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Conteúdo não foi carregado!',
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarOrigem() : cadastrarOrigem())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isEdit && isOpen) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipo-origem/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setTipoOrigem(response.tipoServico);
          setIdTipoOrigem(response.idTipoServico);
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Tipo de Origem
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id Tipo de Origem:
                </label>
                <input
                  type='text'
                  placeholder='Digite o ID'
                  className='form-control'
                  value={idTipoOrigem ? idTipoOrigem : ''}
                  onChange={(event) => {
                    const value = event.target.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                    setIdTipoOrigem(value);
                  }}
                  onKeyDown={(event) => {
                    const value = event.currentTarget.value.replaceAll('e', '').replace(/\D/g, ''); // Convertendo para upperCase
                    setIdTipoOrigem(value);
                  }}
                />
                {(idTipoOrigem === null || idTipoOrigem === '' || idTipoOrigem === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o Id</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Tipo de Origem:
                </label>
                <input
                  type='text'
                  value={tipoOrigem ? tipoOrigem : ''}
                  className='form-control'
                  onChange={(e) => {
                    setTipoOrigem(e.target.value);
                  }}
                  placeholder='Digite o Tipo de Origem'
                />
                {(tipoOrigem === null || tipoOrigem === '' || tipoOrigem === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Tipo de Origem
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='3'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalOrigem;
