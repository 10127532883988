import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import {AsyncSelectFornecedor, ISelectOption} from '../../../../shared';
import { useProduto } from '../../produto/hooks/useProduto';
import { ClienteOption } from '../../proposta-pedido/types/interface';
import { useParams } from 'react-router-dom';

type IProps = {
  idParam?: string | number;
};

const DadosGerais = ({idParam}: IProps) => {
  const {id}= useParams();

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {
    formik,
    setSelectedOrigem,
    setSelectedTipo_Embalagem,
    setSelectedTipo,
    setSelectedEmbalagem,
    handleSelectChange,
    handleChange,
    handleChangeNumber,
    selectedEmbalagem,
    selectedOrigem,
    selectedTipo,
    selectedTipo_Embalagem,
    setIsCategoria,
    setIsSegmento,
    setIsGrupoCategoria,
    setIsTipoProduto,
    isCategoria,
    isGrupoCategoria,
    isSegmento,
    isTipoProduto,
    setIsCodigoClassificacao,
    isCodigoClassificacao,
    isUnidadeMedidaVenda,
    setIsUnidadeMedidaVenda,
    isFornecedor,
    setIsFornecedor,
    // setIsConsumo,
    isEcommerce,
    setIsEcommerce,
    isMix,
    setIsMix,
    isDivulgacao,
    setIsDivulgacao,
    isDisponivelFabrica,
    setIsDisponivelFabrica,
    isEstoqueFabricacao,
    setIsEstoqueFabricacao,
    isAguardandoComplemento,
    setIsAguardandoComplemento,
    isLinhaProduto,
    setIsLinhaProduto,
    isTipoEstoque,
    setIsTipoEstoque,

  } = useProduto();

  const {
    setIsConsumo,
    setSelectedMarca,
    selectedMarca,
    base64Image,
    setBase64Image,
    selectedCategoria,
    setSelectedCategoria,
  } = useProduto();

  const [selectKey, setIsSelectKey] = useState<number>(0);

  const [keyTipoProduto, setKeyTipoProduto] = useState<number>(1);
  const [keyGrupoCategoria, setKeyGrupoCategoria] = useState<number>(2);
  const [keyCategoria, setKeyCategoria] = useState<number>(3);

  const [isSegmentosFiltered, setIsSegmentosFiltered] = useState<ISelectOption[]>([]);
  const [isTipoProdutoFiltered, setIsTipoProdutoFiltered] = useState<ISelectOption[]>([]);
  const [isTipoGrupoCategoriasFiltered, setIsTipoGrupoCategoriasFiltered] = useState<
    ISelectOption[]
  >([]);
  const [isCategoriaFiltered, setIsCategoriaFiltered] = useState<ISelectOption[]>([]);
  const [isUnidadeFiltered, setIsUnidadeFiltered] = useState<ISelectOption[]>([]);
  const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null);

  const [defaultUnidadeOptions, setDefaultUnidadeOptions] = useState<ISelectOption[]>([]);


  const handleEstoque = (event) => {
    const novoValor = event.target.value;
    formik.setFieldValue('controlar_estoque', Number(novoValor));

    if (novoValor === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  };
  const filteredOptionsUnidade = (inputValue: string) => {
    return isUnidadeFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };



  const loadFilteredOptionsUnidade = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsUnidade(inputValue));
  };
  const loadOptionsTipoEstoque = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipos/listar?modelo=${1}`;

      const response = await axios.get(url);
      const data = await response.data;

      const {content} = data;

      const options = content.map((item: any) => ({
        value: item.id,
        label: `${item.titulo ?? ''}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsSegmentosFiltered(options);
      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadOptionsTipoEstoqueById = async (id) => {
    console.log(typeof(id))
    if(id === null || typeof(id) !== 'number')  return
    try {
      console.log()
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipos/${id}`;

      const response = await axios.get(url);
      const data = await response.data;


      // const options = content.map((item: any) => ({
      //   value: item.id,
      //   label: `${item.titulo ?? ''}${item.descricao ? ` - ${item.descricao}` : ''}`,
      // }));

      setSelectedTipoEstoque({
        value: Number(data.id),
        label: `${data.titulo ?? ''}${data.descricao ? ` - ${data.descricao}` : ''}`,
      });
      setIsTipoEstoque(data.id)
      console.log(data.id)
      // return options;

      // isTipoEstoque(content);
    } catch (errors: any) {
      // const {data} = errors.response;
      console.log(errors)
      Swal.fire({
        icon: 'error',
        title: errors,
        showCancelButton: false,
      });
      return [];
    }
  };
  const loadOptionsGrupoCategorias = async () => {
    if (isTipoProduto)
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?idPai=${isTipoProduto?.value}`;

        const response = await axios.get(url);
        const data = await response.data;

        const options = data.map((item) => ({
          value: item.id,
          label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
        }));

        setIsTipoGrupoCategoriasFiltered(options);

        return options;
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
        return [];
      }
  };
  const loadOptionsCategoria = async () => {
    if (isGrupoCategoria)
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto/buscar?idPai=${isGrupoCategoria?.value}`;

        const response = await axios.get(url);
        const data = await response.data;

        const options = data.map((item) => ({
          value: item.id,
          label: `${item.codigoClassificacao ? `${item.codigoClassificacao} - ` : ''}${
            item.titulo
          }${item.descricao ? ` - ${item.descricao}` : ''}`,
        }));

        setIsCategoriaFiltered(options);

        return options;
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
        return [];
      }
  };
  const handleUnidadeMedida = (selectedOption: ISelectOption | null) => {
    setIsUnidadeMedidaVenda(selectedOption);
    console.log(selectedOption, isUnidadeMedidaVenda)
  };
  const handleTipoEstoque = (selectedOption: ISelectOption | null) => {
    setIsTipoEstoque(selectedOption);
    console.log(selectedOption, isTipoEstoque)
  };
  const loadOptionsUnidadeMedida = async () => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-medida/listar?size=100000`;

      const response = await axios.get(url);
      const data = await response.data;

      const {content} = data;

      const options = content.map((item) => ({
        value: item.id,
        label: `${item.titulo}${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsUnidadeFiltered(options);
      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };


  useEffect(() => {
    if (isTipoProduto) {
      loadOptionsGrupoCategorias();
    } else {
      setIsGrupoCategoria(null);
    }
    setKeyGrupoCategoria((prevKey) => prevKey + 1);
  }, [isTipoProduto]);

  useEffect(() => {
    if(isTipoEstoque && id){
      loadOptionsTipoEstoqueById(isTipoEstoque)
    }
  }, [isTipoEstoque]);

  useEffect(() => {
    if (isGrupoCategoria) {
      loadOptionsCategoria();
    } else if (!isCodigoClassificacao) {
      setIsCategoria(null);
    }

    setKeyCategoria((prevKey) => prevKey + 1);
  }, [isGrupoCategoria]);


  useEffect(() => {
    formik.setFieldValue('isConsumo', true);
    // setIsConsumo(true)
  },[])

  return (
    <div className='mb-5 mt-8 '>
      <div className='mb-5 row'>
        <div className='col-md-12 mt-4'>
          <label className='form-label'>Descrição:</label>
          <textarea name="" id="" placeholder='Descrição completa do produto'
            {...formik.getFieldProps('descricao')}
            className='form-control bg-transparent'
            onChange={handleChange}
          ></textarea>
          {formik.touched.descricao && formik.errors.descricao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.descricao}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-12 mt-8'>
          <label className='form-label'>Tipo de estoque:</label>
          <AsyncSelect
              className='py-1'
              value={selectedTipoEstoque}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedTipoEstoque(null);
                }
              }}
              onChange={(selectedOption: ClienteOption | null) => {handleTipoEstoque(selectedOption); setSelectedTipoEstoque(selectedOption);}}
              placeholder='Selecione o Tipo de estoque'
              defaultOptions
              loadOptions={loadOptionsTipoEstoque}
            ></AsyncSelect>
        </div>
      </div>


      <div className='mb-5 row '>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>NCM:</label>
          <input
            type='text'
            placeholder='(Exemplo: 1001.10.10)'
            {...formik.getFieldProps('ncm')}
            className='form-control bg-transparent'
            onChange={handleChange}
          />
          <small id='' className='form-text text-muted'>
            Nomenclatura comum do Mercosul
          </small>
          {formik.touched.ncm && formik.errors.ncm && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.ncm}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 mt-4'>
          <label htmlFor='' className='mb-4 form-label'>
            Unidade:
          </label>
          <AsyncSelect
            key={selectKey}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: 'black',
              }),
            }}
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder={'Pesquise a unidade de medida '}
            loadOptions={loadOptionsUnidadeMedida}
            isDisabled={false}
            value={isUnidadeMedidaVenda}
            isClearable
            onChange={handleUnidadeMedida}
            defaultOptions
          />
        </div>
        <div className='mb-8 col-md-6 '>
        
        <div className=' mt-4'>
          <label className='form-label'>Status</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent w-100'
            onChange={handleChange}
            {...formik.getFieldProps('status')}
            value={formik.values.status}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'> Ativo </option>
            <option value='2'> Inativo </option>
          </select>

          {formik.touched.status && formik.errors.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.status}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
      </div>
      </div>
      
      <div className='mb-form-label-container mt-5' style={{display: 'flex', alignItems: 'center'}}>
        <h5 style={{color: '#3f3f3f'}}>Estoque</h5>
        <div style={{flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px'}}></div>
      </div>
      <div className='mb-3 row'>
        <div
          className='col-md-4  mt-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Controlar Estoque:</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            {...formik.getFieldProps('controlar_estoque')}
            //value={}
            onChange={handleEstoque}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value={1}> SIM </option>
            <option value={2}> NÃO </option>
          </select>
          <small id='' className='form-text text-muted'>
            Acompanhamento da movimentação de estoque
          </small>

          {formik.touched.controlar_estoque && formik.errors.controlar_estoque && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.controlar_estoque}</span>
              </div>
            </div>
          )}
        </div>

        {formik.values.controlar_estoque !== 2 && (
          <>
            <div className='col-md-4 mt-4'>
              <label className='form-label'>Estoque Mínimo:</label>
              <input
                type='number'
                placeholder='Estoque Mínimo'
                {...formik.getFieldProps('estoque_min')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade mínima do produto no estoque
              </small>

              {formik.touched.estoque_min && formik.errors.estoque_min && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_min}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-4 mt-4'>
              <label className='form-label'>Estoque Máximo:</label>
              <input
                type='number'
                placeholder='Estoque Máximo'
                {...formik.getFieldProps('estoque_max')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade máxima do produto no estoque
              </small>

              {formik.touched.estoque_max && formik.errors.estoque_max && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_max}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

    </div>
  );
};

export default DadosGerais;
