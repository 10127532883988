import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../../cadastros/proposta-pedido/types/interface';
import {AsyncSelectEmpresa, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalBancos = ({isOpen, onHide, dataLine, isEdit, loadBancos}) => {
  const [titulo, setTitulo] = useState<string | null>(null);
  const [codigo, setCodigo] = useState<number | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const onClose = () => {
    onHide();
    setTitulo(null);
    setCodigo(null);
    setDescricao(null);
    setStatus('ATIVO');
    onClear();
  };

  const onClear = () => {
    setTitulo(null);
    setCodigo(null);
    setDescricao(null);
    setStatus('ATIVO');
  };

  const cadastrarBanco = async () => {
    setIsLoading(true);

    const erros: string[] = [];
    if (!titulo && codigo) erros.push("Informe o título.");
    if (!codigo && titulo) erros.push("Informe o código.");
    if (!codigo && !titulo) erros.push("Preencha os campos obrigatórios.");

    if (erros.length > 0) {
        Swal.fire({
            icon: 'info',
            title: erros.map((erro) => `<p>${erro}</p>`).join(''),
            confirmButtonText: 'Ok',
        });
        setIsLoading(false);
        return;
    }

    try {
        const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos`;
        const response = await axios.post(url, {
            titulo,
            descricao: descricao || '',
            codigo,
            status,
        });

        Swal.fire({
            icon: 'success',
            title: 'Banco cadastrado com sucesso!',
            timer: 3000,
            timerProgressBar: true,
        }).then(() => {
            onClear();
            onClose();
            loadBancos();
        });
    } catch (error: any) {
        console.error(error);

        Swal.fire({
            icon: 'error',
            title: 'Erro na requisição',
            text: error.response?.data?.message || 'Tente novamente mais tarde.',
            confirmButtonText: 'Ok',
        });
    } finally {
        setIsLoading(false);
    }
};


  const editarBanco = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/${dataLine[0]}`;
      if (titulo) {
        const response = await axios.put(url, {
          titulo: titulo ? titulo : '',
          descricao: descricao ? descricao : '',
          codigo: codigo ? codigo : '',
          status: status,
        });
        Swal.fire({
          icon: 'success',
          title: `Banco editado com sucesso`,
          timer: 3000,
          timerProgressBar: true,
        }).then(() => {
          onClose();
          loadBancos();
        });
        setTimeout(() => {}, 3000);
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const submitButton = isLoading ? (
    <Button>
      <Spinner animation='border' size='sm' />
    </Button>    
  ) : (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarBanco() : cadastrarBanco())}
    >
      {isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );


  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (dataLine && dataLine.length > 0) {
      setStatus(dataLine[5]);
      setDescricao(dataLine[3]);
      setTitulo(dataLine[1]);
      setCodigo(dataLine[2]);
    }
  }, [dataLine]);
  return (
    <div className=''>
      <Modal {...modalConfigs} backdrop='static' keyboard={false}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Banco
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Título:
                </label>
                <input
                  type='text'
                  value={titulo ? titulo : ''}
                  className='form-control'
                  onChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                  placeholder='Digite o título'
                />
                {(!titulo) && (
                  <span className={`form-label text-danger`}>*Por favor, informe o título</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Código do banco:
                </label>
                <input
                  type='text'
                  value={codigo ? codigo : ''}
                  className='form-control'
                  maxLength={3}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\D/g, '');
                    inputValue = inputValue || '0';
                    const numericValue = parseInt(inputValue, 10);
                    setCodigo(numericValue);
                  }}
                  placeholder='Digite o código do banco'
                />
                {(!codigo) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o código do banco
                  </span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                  id=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe a empresa</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <label htmlFor='' className='form-label'>
                Descrição:
              </label>
              <Col className=''>
                <textarea
                  name=''
                  value={descricao ? descricao : ''}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  placeholder='Digite a descrição'
                  className='form-control'
                  id=''
                ></textarea>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBancos;
