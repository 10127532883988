import {Column, ColumnInstance, useTable} from 'react-table';
import {
  buildQueryParams,
  formatarData,
  HttpClient,
  IGarantiaDevolucao,
  IGerarGarantiaProdutos,
  IPrevendaGarantia,
  ISelectOption,
  IStatusPrevendaGarantia,
  Modal,
  showAlert,
  SysledTable,
} from '../../../../../shared';
import {Badge, Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import AvatarConsultor from '../../../../../shared/core/adapters/avatar-consultor/avatar-consultor';
import {useEffect, useMemo, useState} from 'react';
import AsyncSelectTipoGarantia from '../../../../../shared/components/inputs/async-select-tipo-garantia/async-select-tipo-garantia';
import AsyncSelectStatusGarantia from '../../../../../shared/components/inputs/async-select-status-garantia/async-select-status-garantia';
import {ImSpinner11} from 'react-icons/im';
import {BsShieldCheck} from 'react-icons/bs';
import Swal from 'sweetalert2';
import * as S from './modal-solicitar-garantia-devolucao-styles';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  idStatusGarantiaDevolucao?: string | number;
  idTipoGarantiaDevolucao?;
  idItem?: number | string;
  tipoSolicitacaoHandle?: string | number;
  tipoSolicitacaoRequest?: string;
  qtdSolicitada?: number | string | null;
  handleRequest?: () => void;
};

const ModalSolicitarGarantiaDevolucao: React.FC<Props> = ({
  isOpen,
  onHide,
  idItem,
  idTipoGarantiaDevolucao,
  idStatusGarantiaDevolucao,
  tipoSolicitacaoHandle,
  tipoSolicitacaoRequest,
  qtdSolicitada,
  handleRequest,
}) => {
  const http = new HttpClient();

  const [isAsyncTipoGarantiaSelect, setIsAsyncTipoGarantiaSelect] = useState<ISelectOption | null>(
    null
  );
  const [isAsyncStatusGarantiaSelect, setIsAsyncStatusGarantiaSelect] =
    useState<ISelectOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQuantidadeProduto, setIsQuantidadeProduto] = useState<string | number>(''); // Permite edição livre no input
  const [isDescricao, setIsDescricao] = useState<string>('');

  <input
    placeholder='Insira a quantidade solicitada:'
    type='number'
    className='form-control'
    value={isQuantidadeProduto}
    onChange={(e) => {
      const inputValue = e.target.value;
      setIsQuantidadeProduto(inputValue); // Mantém como string para evitar remoção de zeros à esquerda
    }}
    onBlur={() => {
      setIsQuantidadeProduto((prev) => (prev === '' ? '' : Number(prev))); // Converte para número apenas ao perder o foco
    }}
  />;

  const submitButton = (
    <Button type='submit' variant='success' onClick={() => handleSubmit()}>
      {isLoading && <Spinner animation='border' size='sm' />}
      {!isLoading && 'Salvar'}
    </Button>
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!isAsyncStatusGarantiaSelect || !isAsyncTipoGarantiaSelect) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      let title;
      switch (true) {
        case !isAsyncTipoGarantiaSelect:
          title =
            tipoSolicitacaoHandle == 0
              ? 'A inserção do Tipo de Garantia é obrigatória'
              : 'A inserção do Tipo de Devolução é obrigatória';
          break;
        case !isAsyncStatusGarantiaSelect:
          title =
            tipoSolicitacaoHandle == 0
              ? 'A inserção do Status de Garantia é obrigatória'
              : 'A inserção do Status de Devolução é obrigatória';
          break;

        default:
          title = 'Erro desconhecido';
      }

      Toast.fire({
        icon: 'info',
        title,
      });

      setIsLoading(false);
      return;
    }

    const mapTipoSolicitacao = (tipo: number) => (tipo == 0 ? 'GARANTIA' : 'DEVOLUCAO');

    if (
      tipoSolicitacaoRequest &&
      mapTipoSolicitacao(Number(tipoSolicitacaoHandle)) !== tipoSolicitacaoRequest
    ) {
      const result = await Swal.fire({
        title: 'Confirmação:',
        text: `Atenção: o produto possui o tipo de solicitação ${tipoSolicitacaoRequest}. Ao realizar uma nova solicitação de ${
          mapTipoSolicitacao(Number(tipoSolicitacaoHandle)) == 'DEVOLUCAO'
            ? 'DEVOLUÇÃO'
            : 'GARANTIA'
        }, a solicitação anterior será desfeita.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar`,
        cancelButtonText: 'Cancelar',
      });

      if (!result.isConfirmed) {
        setIsLoading(false);
        return;
      }
    }

    try {
      const urlBuild = `prevendas/produtos-prazos/atualizar-garantia-devolucao`;

      await http.request<IGarantiaDevolucao, IGarantiaDevolucao>({
        method: 'PUT',
        url: urlBuild,
        data: {
          idStatusGarantia: isAsyncStatusGarantiaSelect?.value!,
          idTipoGarantia: isAsyncTipoGarantiaSelect?.value!,
          tipoGarantiaDevolucao: tipoSolicitacaoHandle === 0 ? 'GARANTIA' : 'DEVOLUCAO',
          qtdGarantiaDevolucao: Number(isQuantidadeProduto) ? Number(isQuantidadeProduto) : null,
          descricao: isDescricao ? isDescricao : '',
        },
        id: idItem,
      });

      showAlert(
        'success',
        tipoSolicitacaoHandle == 1
          ? 'Devolução Solicitada com Sucesso'
          : 'Tipo de Garantia Solicitada com Sucesso'
      );

      if (handleRequest) {
        handleRequest();
      }

      onClose();

      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);
      setIsLoading(false);
    }
  };

  const getGarantiaDevolucaoById = async () => {
    setIsLoading(true);
    try {
      const params = {
        id: idItem,
      };

      const buildUrl = `prevendas/produtos-prazos/listar-garantia-devolucao${buildQueryParams(
        params
      )}`;

      const response = await http.request<IStatusPrevendaGarantia, IStatusPrevendaGarantia>({
        method: 'GET',
        url: buildUrl,
      });

      const {content} = response.data as any;

      setIsAsyncTipoGarantiaSelect({
        value: content[0].prevendaTipoGarantiaDto.id,
        label: '',
      });

      setIsAsyncStatusGarantiaSelect({
        value: content[0].prevendaStatusGarantiaDto.id,
        label: '',
      });

      setIsQuantidadeProduto(content[0].qtdGarantiaDevolucao);

      setIsDescricao(content[0].descricao);

      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);
      setIsLoading(false);
    }
  };

  const onClose = () => {
    onHide!();

    setIsAsyncTipoGarantiaSelect(null);
    setIsAsyncStatusGarantiaSelect(null);
    setIsQuantidadeProduto('');
    setIsDescricao('');
  };

  useEffect(() => {
    console.log(
      'asyncSelectTipoGarantia',
      isAsyncTipoGarantiaSelect,
      'asyncSelectStatusGarantia',
      isAsyncStatusGarantiaSelect
    );
  }, [isAsyncTipoGarantiaSelect, isAsyncStatusGarantiaSelect]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: tipoSolicitacaoHandle == 0 ? 'Garantia' : 'Devolução',
    actions: [submitButton],
    onHide: onClose,
    size: 'lg',
  };

  const mapTipoSolicitacao = (tipo: number) => (tipo === 0 ? 'GARANTIA' : 'DEVOLUCAO');

  useEffect(() => {
    const tipoHandle = Number(tipoSolicitacaoHandle);

    if (
      idStatusGarantiaDevolucao &&
      idTipoGarantiaDevolucao &&
      isOpen &&
      !isNaN(tipoHandle) &&
      mapTipoSolicitacao(tipoHandle) === tipoSolicitacaoRequest
    ) {
      getGarantiaDevolucaoById();
    }
  }, [
    idStatusGarantiaDevolucao,
    idTipoGarantiaDevolucao,
    isOpen,
    tipoSolicitacaoHandle,
    tipoSolicitacaoRequest,
  ]);

  return (
    <>
      <Modal
        {...modalConfigs}
        overflowBody=''
        icon={tipoSolicitacaoHandle === 0 ? BsShieldCheck : ImSpinner11}
      >
        <S.ContainerBadge>
          <S.BadgeEnviroment>
            <p>
              Quantidade de Produtos no Pedido: <span>{qtdSolicitada}</span>
            </p>
          </S.BadgeEnviroment>
        </S.ContainerBadge>

        <form action=''>
          <Row className='mt-4'>
            <Col className='col-12'>
              <label htmlFor='' className='form-label'>
                {tipoSolicitacaoHandle == 0 ? 'Tipo de Garantia' : 'Tipo de Devolução'}
              </label>
              <AsyncSelectTipoGarantia
                handleOptions={(option) => setIsAsyncTipoGarantiaSelect(option)}
                inputOption={isAsyncTipoGarantiaSelect}
                placeholder={tipoSolicitacaoHandle == 0 ? 'Tipo de Garantia' : 'Tipo de Devolução'}
                onKeyDown={(e) => {
                  setIsAsyncTipoGarantiaSelect(null);
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <label htmlFor='' className='form-label'>
              {tipoSolicitacaoHandle == 0 ? 'Status de Garantia' : 'Status de Devolução'}
            </label>
            <Col className='col-12'>
              <AsyncSelectStatusGarantia
                handleOptions={(option) => setIsAsyncStatusGarantiaSelect(option)}
                isDisabled
                isDefault
                inputOption={isAsyncStatusGarantiaSelect}
                placeholder={
                  tipoSolicitacaoHandle == 0 ? 'Status de Garantia' : 'Status de Devolução'
                }
                onKeyDown={(e) => {
                  setIsAsyncStatusGarantiaSelect(null);
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <label htmlFor='' className='form-label'>
              Quantidade de Produtos:
            </label>
            <Col className='col-12'>
              <input
                placeholder='Insira a Quantidade Solicitada:'
                type='number'
                className={`form-control`}
                value={isQuantidadeProduto ?? ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setIsQuantidadeProduto(inputValue);
                }}
                onBlur={() => {
                  setIsQuantidadeProduto((prev) => (prev === '' ? '' : Number(prev)));
                }}
              />
              <small id='' className='form-text text-muted'>
                (Atenção) se a quantidade de produtos não for informada, a quantidade solicitada
                será a quantidade total no pedido
              </small>
            </Col>
          </Row>
          <Row>
            <Col className='col-12 mt-2'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder={
                  tipoSolicitacaoHandle == '0'
                    ? 'Digite a descrição da solicitação de garantia'
                    : 'Digite a descrição da solicitação de devolução'
                }
                className='form-control'
                value={isDescricao}
                onChange={(e) => {
                  const value = e.target.value;
                  setIsDescricao(value);
                }}
              />
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default ModalSolicitarGarantiaDevolucao;
