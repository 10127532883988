import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {Table, TabContainer} from 'react-bootstrap';
import {useFormik} from 'formik';
import options from '../../selectOptions/useOptionsFormularios';
import {sendPerfis, updatePerfis} from '../cadastro_requests/cadastro_requests';
import {TablePermissoes} from './components/TablePermissoes';
import useOptionsFormularios from '../../selectOptions/useOptionsFormularios';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import PageTitulo from '../../components/Pagetitulo';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const cadastroValidationSchema = Yup.object().shape({
  titulo: Yup.string().required('Por favor, informe um título para o Perfil'),
  descricao: Yup.string().required('Por favor, infome a descrição do Perfil'),
});

interface Perfil {
  id: number;
  status: number;
  titulo: string;
  descricao: string;
  perfilFormularios: PerfilFormularios[];
}

interface PerfilFormularios {
  idFormulario: number;
  descFormulario: string;
  status: number;
  ler: number;
  escrever: number;
  excluir: number;
  sincronizar: number;
}

const initialValues = {
  titulo: '',
  descricao: '',
  status: '-1',
  perfilFormularios: [],
};

export function CadastroPerfis() {
  const {id} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [permissoesFormulario, setPermissoesFormulario] = useState<PerfilFormularios[]>([]);
  const {options} = useOptionsFormularios();
  const [selectedPerfil, setSelectedPerfil] = useState<string>('');
  const [statusForm, setStatusForm] = useState<number>(-1);
  const [ler, setLer] = useState<number>(-1);
  const [escrever, setEscrever] = useState<number>(-1);
  const [excluir, setExcluir] = useState<number>(-1);
  const [sincronizar, setSincronizar] = useState<number>(-1);

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        if (values.status === '-1') {
          Swal.fire({
            icon: 'info',
            title: 'Por favro, selecione o status do Perfil',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          return;
        }

        if (permissoesFormulario.length === 0) {
          Swal.fire({
            icon: 'info',
            title: 'Por favor, adicione pelo menos um formulário de acesso.',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          return;
        }

        const permissaoFormulario = permissoesFormulario;

        if (id) {
          const response = await updatePerfis(
            id,
            values.titulo,
            values.descricao,
            Number(values.status),
            permissaoFormulario
          );
          if (response.status === 201) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Perfil atualizado com sucesso!',
              reverseButtons: true,
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/perfis-listagem');
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao atualizar o Perfil, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o Perfil. Por favor, tente novamente.');
          }
          //atualiza Perfil
        } else {
          //cria novo Perfil
          const response = await sendPerfis(
            values.titulo,
            values.descricao,
            Number(values.status),
            permissaoFormulario
          );
          if (response.status === 201) {
            setSubmitting(false);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Perfil cadastrado com sucesso!',
              reverseButtons: true,
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/perfis-listagem');
              },
            });
          } else {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o Perfil, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o Perfil. Por favor, tente novamente.');
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar a Permissão',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        }
      }
    },
  });

  const handleStatusUsuarioChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  const handleRemovePerfil = (index: number) => {
    setPermissoesFormulario((prevPermissoesFormulario) =>
      prevPermissoesFormulario.filter((_, i) => i !== index)
    );
  };

  const showToast = (title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: 'info',
      title: title,
    });
  };

  useEffect(() => {
    async function fetchPerfis() {
      if (id) {
        try {
          const perfilResponse = await axios.get(
            `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/perfis/${id}`
          );
          if (perfilResponse.status === 200) {
            const perfil = perfilResponse.data;

            formik.setValues({
              ...formik.values,
              titulo: perfil.titulo,
              descricao: perfil.descricao,
              status: perfil.status,
            });

            const responsePerfilForm = await axios.get(
              `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios/optionsFormularios`
            );
            const optionsPerfilForm = await responsePerfilForm.data;

            setPermissoesFormulario(
              perfil.perfilFormularios.map((perfilFormulario) => {
                const descPerfilForm = optionsPerfilForm.find(
                  (option) => option.id === perfilFormulario.idFormulario
                );

                return {
                  idFormulario: perfilFormulario.idFormulario,
                  descFormulario: descPerfilForm
                    ? `${descPerfilForm.arquivo}: ${descPerfilForm.descricao}`
                    : '',
                  status: perfilFormulario.status,
                  ler: perfilFormulario.ler,
                  escrever: perfilFormulario.escrever,
                  excluir: perfilFormulario.excluir,
                  sincronizar: perfilFormulario.sincronizar,
                };
              })
            );
          }
        } catch (error: any) {
          console.error('Error fetching:', error);
        }
      }
    }
    fetchPerfis();
  }, [id]);

  const handleAddPerfil = () => {
    if (selectedPerfil) {
      const validations = [
        {condition: !selectedPerfil, message: 'Por favor, selecione um formulário.'},
        {
          condition: permissoesFormulario.some(
            (perfil) => perfil.idFormulario === Number(selectedPerfil)
          ),
          message:
            'Formulário selecionado já adicionado. Por favor, selecione outro ou complete o cadastro do perfil.',
        },
        {
          condition: ler === -1,
          message: 'Por favor, selecione a opção de leitura do formulário vinculado ao perfil.',
        },
        {
          condition: escrever === -1,
          message: 'Por favor, selecione a opção de escrita do formulário vinculado ao perfil.',
        },
        {
          condition: excluir === -1,
          message: 'Por favor, selecione a opção de exclusão do formulário vinculado ao perfil.',
        },
        {
          condition: sincronizar === -1,
          message:
            'Por favor, selecione a opção de sincronização do formulário vinculado ao perfil.',
        },
        {
          condition: statusForm === -1,
          message: 'Por favor, selecione o status formulário vinculado ao perfil.',
        },
      ];

      for (const validation of validations) {
        if (validation.condition) {
          showToast(validation.message);
          return;
        }
      }

      setPermissoesFormulario([
        ...permissoesFormulario,
        {
          idFormulario: Number(selectedPerfil),
          descFormulario:
            options.find((option) => option.value === Number(selectedPerfil))?.label ??
            'default label',
          status: statusForm,
          ler: ler,
          escrever: escrever,
          excluir: excluir,
          sincronizar: sincronizar,
        },
      ]);
      setSelectedPerfil('-1');
      setStatusForm(-1);
      setLer(-1);
      setEscrever(-1);
      setExcluir(-1);
      setSincronizar(-1);
    }
  };

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className='text-light-dark'>
        <div className='row'>
          <PageTitulo id={id} tipoFormulario='Permissão' />
        </div>
        <div
          className='mb-3 form-label-container'
          style={{display: 'flex', alignItems: 'center'}}
        >
          <h5>Informações Gerais</h5>
          <div style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-3'>
            <label className='form-label'>Titulo *</label>
            <input
              type='text'
              placeholder='Insira o título'
              {...formik.getFieldProps('titulo')}
              className={clsx(
                'form-control form-control-sm -smbg-transparent',
                {'is-invalid': formik.touched.titulo && formik.errors.titulo},
                {
                  'is-valid': formik.touched.titulo && !formik.errors.titulo,
                }
              )}
              onChange={(event) => {
                //const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('titulo', event.target.value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.titulo && formik.errors.titulo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.titulo}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Descricao *</label>
            <input
              type='text'
              placeholder='Insira a descrição'
              {...formik.getFieldProps('descricao')}
              className={clsx(
                'form-control form-control-sm bg-transparent',
                {'is-invalid': formik.touched.descricao && formik.errors.descricao},
                {
                  'is-valid': formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              onChange={(event) => {
                //const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('descricao', event.target.value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.descricao && formik.errors.descricao && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.descricao}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-4'></div>
          <div className='col-sm-2'>
            <label className='form-label'>Status</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={handleStatusUsuarioChange}
              value={formik.values.status}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
        </div>
        <div className='mb-3 form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h5>Seleção os formulários de acesso</h5>
          <div style={{flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px'}}></div>
        </div>
        <div className='row mb-8'>
          <div className='col-sm-3 mt-1'>
            <label className='form-label'>Selecione o formulário que o perfil terá acesso</label>
            <select
              className='form-select form-select-sm'
              placeholder='Selecione a página que a Permissão terá acesso'
              onChange={(e) => setSelectedPerfil(e.target.value)}
              value={selectedPerfil}
            >
              <option value=''>Selecione</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className='col-sm-1 mt-1'>
            <label className='form-label'>Status Form.</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={(e) => setStatusForm(Number(e.target.value))}
              value={statusForm}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
          <div className='col-sm-2 mt-1'>
            <label className='form-label'>Ler</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={(e) => setLer(Number(e.target.value))}
              value={ler}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
          <div className='col-sm-2 mt-1'>
            <label className='form-label'>Escrever</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={(e) => setEscrever(Number(e.target.value))}
              value={escrever}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
          <div className='col-sm-2 mt-1'>
            <label className='form-label'>Excluir</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={(e) => setExcluir(Number(e.target.value))}
              value={excluir}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
          <div className='col-sm-2 mt-1'>
            <label className='form-label'>Sincronizar</label>
            <select
              className='form-select form-select-sm'
              aria-label='Select example'
              onChange={(e) => setSincronizar(Number(e.target.value))}
              value={sincronizar}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-sm-2'>
            <button
              type='button'
              className='btn btn-success btn-sm'
              onClick={handleAddPerfil}
              disabled={!selectedPerfil}
            >
              Adicionar Formulário de Acesso
            </button>
          </div>
        </div>
        <div className='row'>
          <Table>
            <TabContainer>
              <TablePermissoes
                className='card-flush h-md-100'
                infosPerfis={permissoesFormulario}
                //onEditPagamento={}
                onRemovePerfil={handleRemovePerfil}
              />
            </TabContainer>
          </Table>
        </div>
      </div>
      <div className='d-flex gap-8'>
        <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
          {id ? 'Atualizar' : 'Cadastrar'}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn-cadastro btn-danger'
          id='back'
          onClick={() => navigate('/perfis-listagem')}
        >
          Voltar
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
