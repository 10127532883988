import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "../../../../shared";
import AsyncSelect from "react-select/async";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { useParams } from "react-router-dom";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalSegmentoQuantidade = ({ isOpen, onHide, isEdit = false, reload, dataLine = {}, clearFiltros }) => {

    const { id } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(false)
    const [selectedSegmento, setSelectedSegmento] = useState<ClienteOption | null>(null)
    const [segmentoTitle, setSegmentoTitle] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [quantidade, setQuantidade] = useState<number | null>(0)

    const cadastrarSegmentoQuantidade = async () => {
        try {
            setIsLoading(true)

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos-quantidade`;

            const response = await axios.post(url, {
                idEmpreendimento: Number(id),
                idSegmento: selectedSegmento!.value,
                quantidade: quantidade,
                status: status,
                observacao: descricao
            });

            Swal.fire({
                icon: 'success',
                title: `Quantidade do Segmento cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                reload()
                clearForm()
                onClose()
            })
        }
        // }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally {
            setIsLoading(false)
        }
    }
    const editarSegmentoQuantidade = async () => {
        try {
            setIsLoading(true)
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos-quantidade/${dataLine[0]}`;
            const response = await axios.put(url, {
                idEmpreendimento: Number(id),
                idSegmento: selectedSegmento!.value,
                quantidade: quantidade,
                status: status,
                observacao: descricao
            });
            Swal.fire({
                icon: 'success',
                title: `Quantidade do Segmento editada com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()
            });

        }
        catch (errors: any) {
            Swal.fire({
                icon: 'info',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally {
            setIsLoading(false)
        }
    }
    const getEmpreendimentoById = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/${id}`,

            );

            console.log(response.data)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                // setTiposLocalicazacao((prev) => (page === 0 ? content : [...prev, ...content]));
                // setSelectedEmpreendimento(content)
                setSegmentoTitle(response.data.titulo)
                alert(content.titulo)
                console.log(content)
                // setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Segmento Quantidade para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    };
    const fetchSegmentoQuantidadeById = async (page = 0) => {
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos-quantidade/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }
                console.log(response.data.content)
                setSelectedSegmento({ value: data.idSegmento, label: data.segmento })
                setStatus(data.status)
                setQuantidade(data.quantidade)
                setDescricao(data.observacao)
            }
        }
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        }
    }
    const loadOptionsSegmento = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/segmentos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const onClose = () => {
        // clearFiltros()
        // clearFiltros()
        clearForm()
        // reload()
        // reload()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setQuantidade(0)
        setSelectedSegmento(null)
        setDescricao(null)
    }

    const submitButton = (
        <Button
            type='submit'
            variant='success'
            disabled={isConfirme === false}
            onClick={() => isEdit ? editarSegmentoQuantidade() : cadastrarSegmentoQuantidade()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );
    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };


    useEffect(() => {
        if (isEdit) {
            fetchSegmentoQuantidadeById()
        }
    }, [dataLine])

    useEffect(() => {
        getEmpreendimentoById()
    }, [])

    useEffect(() => {
        if (selectedSegmento !== null && (quantidade !== 0)) {
            setIsConfirme(true)
        }
        else (
            setIsConfirme(false)
        )
    }
        , [selectedSegmento, quantidade])

    return (
        <div>
            <Modal {...modalConfigs}>
                {/* TITULO E CADASTRO */}
                <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                        {isEdit ? "Editar" : "Cadastrar"} Segmento Quantidade
                    </h1>
                </div>
                {/* BARRA HORRIZONTAL */}
                <div style={{ marginBottom: '8px' }}>
                    <div style={{ borderBottom: '2px solid #878787' }}></div>
                </div>
                {/* FILTROS */}
                <div className="my-4">
                    <form action="" className="mt-4" >
                        <Row className="row my-8">
                            <Col>
                                <label htmlFor="" className="form-label"  >Empreendimento:</label>
                                <AsyncSelect isDisabled value={{ value: 1, label: segmentoTitle }}></AsyncSelect>
                            </Col>
                            <Col>
                                <label htmlFor="" className="form-label"  >Segmento:</label>
                                <AsyncSelect
                                    value={selectedSegmento}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            setSelectedSegmento(null);
                                        }
                                    }}
                                    onChange={(selectedOption: ClienteOption | null) => {
                                        if (selectedOption) {
                                            const idValue = isNaN(Number(selectedOption.value))
                                                ? selectedOption.value // Caso seja texto, mantêm como string.
                                                : Number(selectedOption.value);
                                            console.log(selectedOption.value)
                                        }
                                        setSelectedSegmento(selectedOption);
                                        console.log(selectedOption)
                                    }}
                                    loadOptions={loadOptionsSegmento}
                                    cacheOptions
                                    defaultOptions
                                    placeholder="Selecione o segmento"
                                ></AsyncSelect>
                                {(!selectedSegmento)  && <span className={` form-label text-danger`}>*Por favor, informe o segmento</span>}

                            </Col>
                        </Row>
                        <Row className="row">
                            <Col>
                                <label htmlFor="" className="form-label"  >Quantidade:</label>
                                <input type="number"
                                    className="form-control"
                                    value={quantidade ? quantidade : ''}
                                    onChange={(e) => {
                                        setQuantidade(Number(e.target.value))
                                    }}
                                    placeholder="0" />
                                    {(!quantidade)  && <span className={` form-label text-danger`}>*Por favor, informe a quantidade</span>}

                            </Col>
                            <Col>
                                <label htmlFor="" className="form-label">Status:</label>
                                <select className="form-select" value={status ? status : ""}
                                    onChange={(e) => { setStatus(e.target.value) }}>
                                    <option value="ATIVO" >ATIVO</option>
                                    <option value="INATIVO">INATIVO</option>
                                </select>

                            </Col>
                        </Row>
                        <Row className="my-8">
                            <Col className="">
                                <label htmlFor="" className="form-label">Observação:</label>
                                <textarea name=""
                                    value={descricao ? descricao : ""} onChange={(e) => {
                                        setDescricao(e.target.value)
                                    }}
                                    placeholder="Digite a observação" className="form-control" id=""></textarea>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalSegmentoQuantidade