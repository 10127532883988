import {Badge, Button, Col, Modal, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {
  CrudHeader,
  IStatusProjetos,
  PDFView,
  smoothScrollToBottom,
  useModal,
} from '../../../shared';
// import ModalStatusDoProjeto from "./components/ModalStatusDoProjeto";
// import CrudHeader from "../../../shared/components/crud-header/crud-header";
import ModalArquivosDoProjeto from './components/ModalArquivosDoProjeto';
import AsyncSelect from 'react-select/async';
import {useParams} from 'react-router-dom';
import {get} from 'http';
import ButtonOpcoesListagem from '../../empreendimento/segmento-empreendimento/components/ButtonOpcoesListagem';
import {
  imprimirArquivoProjeto,
  visualizarArquivoProjeto,
} from '../../listagem/listagem_requests/listagem_requests';
import VisualizarArquivo from './components/VisualizarArquivo';
// import { smoothScrollToBottom, TipoLocalizacao, useModal } from "../../../../shared";
// import ModalTipoLocalizacao from "./components/ModalTipoLocalizacao";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const StatusDoProjeto = () => {
  const {id} = useParams();

  const [statusProjeto, setStatusProjeto] = useState<IStatusProjetos[]>([]);
  const [dataLine, setDataLine] = useState<IStatusProjetos[]>([]);

  const [status, setStatus] = useState<string | null>('ATIVO');
  const [descricao, setDescricao] = useState<string | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [page, setPage] = useState(0);

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCLear, setIsCLear] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [segmentoTitle, setSegmentoTitle] = useState<string | null>(null);

  const [isArquivosProjetoModal, openArquivosProjetoModal, closeArquivosProjetoModal] = useModal();

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    smoothScrollToBottom();
  };

  const fetchArquivosProjeto = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-arquivos/listar`,
        {
          params: {
            status: status,
            titulo: titulo ? titulo : null,
            descricao: descricao ? descricao : null,
            page: page,
            idProjeto: id,
          },
        }
      );

      console.log(response.data.content);
      const {content, totalPages} = response.data;

      if (response.status === 200) {
        if (page === 0) {
        }
        setStatusProjeto((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de Arquivos do Projeto para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      // setIsLoadingSearch(false);
    }
  };

  const getProjetoById = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos/${id}`
      );

      if (response.status === 200) {
        setSegmentoTitle(response.data.titulo);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          // setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    }
  };

  const clearFiltros = () => {
    setTitulo(null);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    setIsCLear(true);
  };

  useEffect(() => {
    fetchArquivosProjeto(page);
    getProjetoById();
  }, [page]);

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(0);
    fetchArquivosProjeto(0);
  };
  const openModal = (isEdit, ...data) => {
    if (data) {
      setDataLine(data);
    }
    if (isEdit) {
      setIsEdit(isEdit);
    } else setIsEdit(false);

    openArquivosProjetoModal();
  };

  useEffect(() => {
    if (isCLear) {
      fetchArquivosProjeto(0);
    }
    setIsCLear(false);
  }, [isCLear]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <CrudHeader
        to={'/projetos'}
        title='Arquivos do Projeto'
        isLoading={isLoading}
        titleCadastro='Arquivo'
        clearFiltros={clearFiltros}
        handleSearch={handleSearch}
        openModal={openModal}
      >
        <Row className='row'>
          <label htmlFor='' className='form-label'>
            Projeto:
          </label>
          <AsyncSelect isDisabled value={{value: 1, label: segmentoTitle}}></AsyncSelect>
        </Row>
        <Row className=' row'>
          <Col>
            <label htmlFor='' className='form-label'>
              Titulo:
            </label>
            <input
              type='text'
              className='form-control'
              value={titulo ? titulo : ''}
              onChange={(e) => {
                setTitulo(e.target.value);
              }}
              placeholder='Digite o titulo'
            />
          </Col>
          <Col>
            <label htmlFor='' className='form-label'>
              Status:
            </label>
            <select
              className='form-select'
              value={status ? status : ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
        <Row className=' mb-4 row'>
          <label htmlFor='' className='form-label'>
            Descrição:
          </label>
          <Col className=''>
            <textarea
              name=''
              value={descricao ? descricao : ''}
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
              placeholder='Digite a descrição'
              className='form-control'
              id=''
            ></textarea>
          </Col>
        </Row>
      </CrudHeader>
      {isLoading ? (
        <Skeleton count={40} height={25} />
      ) : (
        <>
          <div className='table-responsive'>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>Id</th>
                  <th className='text-center'>Titulo</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {statusProjeto?.map((tipo) => (
                  <tr className='text-center'>
                    <td>{tipo.id}</td>
                    <td>{tipo.titulo}</td>
                    <td>{tipo.descricao ? tipo.descricao : '...'}</td>
                    <td>{tipo.usuarioCriacaoNome}</td>
                    <td>{tipo.dataCriacao}</td>
                    <td>{tipo.usuarioAlteracaoNome ? tipo.usuarioAlteracaoNome : '...'}</td>
                    <td>{tipo.dataAlteracao}</td>
                    <td><Badge className='text-white p-2 px-' bg={`${tipo.status === 'ATIVO' ? 'success' : 'danger'}`}>{tipo.status}</Badge></td>
                    <td>
                      {/* <ButtonOpcoesListagem></ButtonOpcoesListagem> */}
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-success btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          <i className=''>Opções</i>
                        </button>
                        <ul
                          className='dropdown-menu'
                          style={{
                            position: 'absolute',
                            zIndex: 1050,
                            top: '100%',
                            left: '0',
                          }}
                        >
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              // onClick={() => prevendaId(prevenda.id)}
                              onClick={() => {
                                openModal(true, tipo.id);
                                setIsEdit(true);
                              }}
                            >
                              <i className='bi-pencil mx-1'> </i>
                              EDITAR
                            </button>
                          </li>

                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              // onClick={() => handleGerarPedidoSwal(prevenda.id)}
                              onClick={() => imprimirArquivoProjeto(tipo.id, setIsLoading)}
                            >
                              <i className='bi-download mx-1'> </i>
                              BAIXAR ARQUIVO
                            </button>
                          </li>

                          {/* <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={()=>{handleGerarPrazo(prevenda)}}
                              >
                                GERAR PRAZOS
                              </button>
                            </li> */}
                        </ul>
                      </div>
                      {/* <div className='text-center'>
                                                <button
                                                    onClick={() => {
                                                        openModal(true, tipo.id)
                                                        setIsEdit(true)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {/* <ModalStatusDoProjeto clearFiltros={clearFiltros} dataLine={dataLine} reload={fetchArquivosProjeto} isEdit={isEdit} isOpen={isStatusProjetoModal} onHide={closeStatusProjetoModal}></ModalStatusDoProjeto> */}
      <ModalArquivosDoProjeto
        clearFiltros={clearFiltros}
        dataLine={dataLine}
        reload={fetchArquivosProjeto}
        isEdit={isEdit}
        isOpen={isArquivosProjetoModal}
        onHide={closeArquivosProjetoModal}
      ></ModalArquivosDoProjeto>

    </div>
  );
};

export default StatusDoProjeto;
