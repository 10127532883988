import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {IParcelas, smoothScrollToBottom, useModal} from '../../../shared';
import {Link, useParams} from 'react-router-dom';
import {addDays, endOfMonth, format, startOfMonth, subDays} from 'date-fns';
import {DateRangePicker} from 'rsuite';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import ModalParcelas from './components/ModalParcelas';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemParcelas = () => {
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [parcelas, setParcelas] = useState<IParcelas[]>([]);
  const [valor, setValor] = useState<number | null>(null);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [isDataVencimento, setIsDataVencimento] = useState<DateRange | null>(null);
  const [isDataEmissao, setIsDataEmissao] = useState<DateRange | null>(null);  

  const [isParcelasModal, openParcelasModal, closeParcelasModal] = useModal();

  const fetchParcelas = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar-parcelas/listar?idContaPagar=${id}`,
        {
          params: {
            status: status,
            page: page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setParcelas((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de parcelas para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleDateEmissao = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataEmissao(null);
    } else {
      setIsDataEmissao([value[0], value[1]]);
    }
  };

  const handleDateVencimento = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataVencimento(null);
    } else {
      setIsDataVencimento([value[0], value[1]]);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(0);
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const formatDate = (date) => {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - (offset*60*1000));
        return adjustedDate.toISOString().split('T')[0];
      }
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-contas-pagar-parcelas/listar?idContaPagar=${id}`,
        {
          params: {
            dataVencimentoInicio: isDataVencimento ? formatDate(isDataVencimento[0]) : null,
            dataVencimentoFim: isDataVencimento ? formatDate(isDataVencimento[1]) : null,
            dataEmissaoInicio: isDataEmissao ? formatDate(isDataEmissao[0]) : null,
            dataEmissaoFim: isDataEmissao ? formatDate(isDataEmissao[1]) : null,
            valor: valor,
            status: status,
            page: page,
            descricao: descricao ? descricao : null,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        const {content, totalPages} = response.data;
        setParcelas((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
      }
      if (response.data.content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de parcelas para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openParcelasModal();
  };

  const clearFiltros = () => {
    setIsDataVencimento(null);
    setIsDataEmissao(null);
    setDescricao(null);
    setStatus('ATIVO');
    setValor(null);
    setPage(0);
    fetchParcelas(page);
  };

  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  useEffect(() => {
    fetchParcelas(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Parcelas
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Parcelas
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Id contas a pagar
              </label>
              <input
                value={id}
                disabled
                className='form-control'
                type='number'
                placeholder='Id Contas a Pagar'
              />
            </Col>
            <Col>
              <label className='form-label'>Data Vencimento:</label>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateVencimento}
                value={isDataVencimento}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='DATA EMISSAO'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col>
              <label className='form-label'>Data de Emissão:</label>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateEmissao}
                value={isDataEmissao}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='DATA EMISSAO'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
            <Col>
              <label className='form-label'>Valor:</label>
              <input
                type='text'
                placeholder='Valor...'
                value={new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valor || 0)}
                className={`form-control`}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  inputValue = inputValue.replace(/\D/g, '');

                  if (inputValue !== null) {
                    inputValue = inputValue.replace(/^0+/, '');
                    inputValue = inputValue || '0';

                    const numericValue = parseInt(inputValue, 10);
                    setValor(numericValue / 100);
                  } else {
                    setValor(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link className='btn mx-5 px-10 btn-info ' to='/listagem-contas-a-pagar'>
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Id Contas a Pagar</th>
                  <th className='text-center'>Valor</th>
                  <th className='text-center'>Data de Emissão</th>
                  <th className='text-center'>Data de Vencimento</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {parcelas?.map((parcela) => (
                  <tr className='text-center'>
                    <td>{parcela.id}</td>
                    <td>{parcela.idContaPagar}</td>
                    <td>
                      {parcela.valor
                        ? `R$ ${Number(parcela.valor).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : 'R$ 0,00'}
                    </td>
                    <td>
                      {parcela.dataEmissao
                        ? new Date(parcela.dataEmissao)?.toLocaleDateString('pt-BR')
                        : '...'}
                    </td>
                    <td>
                      {parcela.dataVencimento
                        ? new Date(parcela.dataVencimento)?.toLocaleDateString('pt-BR')
                        : '...'}
                    </td>
                    <td>{parcela.descricao ? parcela.descricao : '...'}</td>
                    <td>{parcela.usuarioCriacaoNome}</td>
                    <td>{parcela.dataCriacao}</td>
                    <td>{parcela.usuarioAlteracaoNome ? parcela.usuarioAlteracaoNome : '...'}</td>
                    <td>{parcela.dataAlteracao}</td>
                    <td>{parcela.status}</td>
                    <td>
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-success btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          Opções
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <button
                              className='dropdown-item'
                              onClick={() => {
                                openModal(
                                  true, 
                                  parcela.id,
                                  parcela.idContaPagar, 
                                  parcela.dataVencimento ? new Date(parcela.dataVencimento)?.toISOString().replace('Z', '') : '', 
                                  parcela.dataEmissao ? new Date(parcela.dataEmissao)?.toISOString().replace('Z', '') : '', 
                                  parcela.status,
                                  parcela.valor, 
                                  parcela.descricao
                                );
                              }}
                            >
                              <i className='bi-pencil'> </i>
                              <span className='mx-1'>Editar</span>
                            </button>
                          </li>
                          <li>
                            <Link
                              to={`/listagem-pagamentos/${parcela.id}`}
                              className='dropdown-item'
                              type='Button'
                            >
                              <i className='bi-box-arrow-up-right'></i>
                              <span className='mx-2'>Gerenciar Pagamentos</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalParcelas
        isOpen={isParcelasModal}
        onHide={closeParcelasModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadParcelas={fetchParcelas}
      />
    </div>
  );
};

export default ListagemParcelas;
