import * as Yup from 'yup';

export const cadastroFormaPagamento = Yup.object().shape({
  formaPagamento: Yup.string()
    .min(1, 'A forma de Pagamento deve conter pelo menos 3 caracteres')
    .max(255, 'O nome da forma de pagamento deve conter no máximo 255 caracteres')
    .required('Por favor, informe uma forma de pagamento'),
  idFormaPagamento: Yup.number()
  .required('Por favor, informe o id da Forma de pagamento'),
});

export const cadastroCondicaoPagamento = Yup.object().shape({
  condicaoPagamento: Yup.string()
  .min(1, 'A condicao de Pagamento deve conter pelo menos 3 caracteres')
    .max(255, 'O nome do arquivo deve conter no máximo 255 caracteres')
    .required('Por favor, informe uma condicao de pagamento'),
});

// export const codigoBarrasValidatorSchema = Yup.object().shape({
//   codigo: Yup.string()
//     .max(255, 'O código EAN deve conter no máximo 255 caracteres')
//     .required('Por favor, informe o código EAN'),
//   status: Yup.string().required('Por favor, informe o status do código EAN'),
// });
