import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { AsyncSelectCliente, AsyncSelectConstrutora, ISelectOption, Modal } from "../../../../shared";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router-dom";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalClientesEmpreendimento = ({ isOpen, onHide, isEdit = false, reload, dataLine = {} }) => {

    const { id } = useParams();
    const [empreendimentoTitle, setEmpreendimentoTitle] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(true)
    const [selectedCliente, setSelectedCliente] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')

    const cadastrarClienteEmpreendimento = async () => {
        try {
            setIsLoading(true)
            // if (titulo !== null && titulo !== '' && titulo !== undefined && titulo !== ' ') {

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/clientes`;

            console.log(selectedCliente?.value)
            const response = await axios.post(url, {
                idEmpreendimento: id,
                idCliente: selectedCliente?.value,
                "status": status ? status : 'ATIVO'
            });

            Swal.fire({
                icon: 'success',
                title: `Construtora cadastrada com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                reload!()
                clearForm()
            })
        }
        // }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally {
            setIsLoading(false)
        }
    }   
    const editarClienteEmpreendimento = async () => {
        try {
            setIsLoading(true)
            // if (titulo !== null && titulo !== '' && titulo !== undefined && titulo !== ' ') {

            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/clientes/${dataLine[0]}`;
            const response = await axios.put(url, {
                idEmpreendimento: id,
                idCliente: selectedCliente?.value,
                "status": status
            });
            Swal.fire({
                icon: 'success',
                title: `Construtora editada com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()
            });

        }
        // }
        catch (errors: any) {
            Swal.fire({
                icon: 'info',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally {
            setIsLoading(false)
        }
    }
    const getEmpreendimentoById = async () => {
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/${id}`,

            );

            if (response.status === 200) {
                setEmpreendimentoTitle(response.data.titulo)
            }
        }
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        }
    };
    const fetchClienteById = async (page = 0) => {
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empreendimentos/clientes/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }
                console.log(response.data)
                setSelectedCliente({ value: response.data.idCliente, label: response.data.clienteFantasia })
                setStatus(data.status)
            }
        }
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        }
    }
    const onClose = () => {
        clearForm()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedCliente(null)
    }
    const handleSelectClient = (selectOption: ISelectOption) => {
        setSelectedCliente(selectOption);
    };
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            disabled={isConfirme === false}
            onClick={() => isEdit ? editarClienteEmpreendimento() : cadastrarClienteEmpreendimento()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}

        </Button>
    )
    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };


    useEffect(() => {
        if (isEdit) {
            fetchClienteById()
        }
        getEmpreendimentoById()
    }, [dataLine])

    useEffect(() => {
        setIsConfirme(selectedCliente ? true : false)
    }, [selectedCliente])

    return (
        <div>
            <Modal {...modalConfigs}>
                <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                        {isEdit ? "Editar" : "Cadastrar"} Construtora do Empreendimento
                    </h1>
                </div>
                <div style={{ marginBottom: '8px' }}>
                    <div style={{ borderBottom: '2px solid #878787' }}></div>
                </div>
                <div className="my-4">
                    <form action="" className="mt-4">
                        <Row className="row my-9">
                            <Col>
                                <label htmlFor="" className="form-label"  >Empreendimento:</label>
                                <AsyncSelect isDisabled value={{ value: 1, label: empreendimentoTitle }}></AsyncSelect>
                            </Col>
                        </Row>
                        <Row>


                            <Col>
                                <label htmlFor="" className="form-label"  >Construtora:</label>
                                <AsyncSelectConstrutora
                                    handleOptions={(option) => handleSelectClient(option!)}
                                    inputOption={selectedCliente}
                                    isSelectDOM={false}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            setSelectedCliente(null);
                                        }
                                    }}
                                />
                            </Col>

                            <Col>
                                <label htmlFor="" className="form-label">Status:</label>
                                <select className="form-select" value={status ? status : ""}
                                    onChange={(e) => { setStatus(e.target.value) }}>
                                    <option value="ATIVO" >ATIVO</option>
                                    <option value="INATIVO">INATIVO</option>
                                </select>

                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalClientesEmpreendimento