import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {codigoBarrasValidatorSchema, ICododigoBarras} from '../../../../shared';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import {useEffect, useMemo, useState} from 'react';
import {editEan, sendEan} from '../../cadastro_requests/cadastro_requests';
import {Badge, Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomHeaderColumn} from '../../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {
  getEan,
  getEanById,
  getEanByIdProduto,
} from '../../../listagem/listagem_requests/listagem_requests';
import {useParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {format} from 'date-fns';

const CodigoBarras: React.FC = () => {
  const codBarrasColumns: Column<ICododigoBarras>[] = [
    {
      Header: 'ID do Produto',
      accessor: 'idProduto',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Criado Por',
      accessor: 'nomeUsuarioCriacao',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Criado em',
      accessor: 'dataCriacao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = value.split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Editado Por',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <span>{value ? value : '...'}</span>,
    },
    {
      Header: 'Editado em',
      accessor: 'dataAlteracao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = value.split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Código EAN',
      accessor: 'codigo',
      Cell: ({value, row}) => (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          {value}
          {row.original.isPrincipal && (
            <Badge style={{color: '#fff', marginTop: '5px'}} bg='info'>
              Código Principal
            </Badge>
          )}
        </div>
      ),
    },
    {
      Header: 'Código no Fabricante / Fornecedor:',
      accessor: 'codProdFornecedor',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <span>{value}</span>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          type='button'
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const [isCodigoBarrasData, setIsCodigoBarrasData] = useState<ICododigoBarras[]>([]);
  const [isPrincipal, setIsPrincipal] = useState(false);

  const data = useMemo(() => isCodigoBarrasData, [isCodigoBarrasData]);
  const columns = useMemo(() => codBarrasColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [idCodigoDeBarras, setIdCodigoDeBarras] = useState('');

  const initialValues = {
    codigo: '',
    codProdFornecedor: '',
    status: 'ATIVO',
  };

  const {id} = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema: codigoBarrasValidatorSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      console.log(values);

      if (isEditing) {
        setIsLoading(true);
        try {
          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar o código EAN?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await editEan(idCodigoDeBarras, {
              ...values,
              isPrincipal: isPrincipal,
            });
            setSubmitting(false);

            Swal.fire({
              icon: 'success',
              title: 'EAN editado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });

            getEanData(id!);

            handleCancelEdit();

            formik.resetForm();
            setIsPrincipal(false);
          }
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          await sendEan({
            ...values,
            idProduto: id,
            isPrincipal: isPrincipal,
          });
          Swal.fire({
            icon: 'success',
            title: 'EAN cadastrado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          getEanData(id!);

          formik.setValues({
            codigo: '',
            codProdFornecedor: '',
            status: 'ATIVO',
          });

          formik.resetForm();
          setIsPrincipal(false);

          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const handleOnEdit = (id: string | number) => {
    setIsEditing(true);

    getEanDataById(id);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);

    formik.setValues({
      codigo: '',
      codProdFornecedor: '',
      status: 'ATIVO',
    });

    formik.resetForm();
    setIsPrincipal(false);
  };

  const getEanDataById = async (idCodigoDeBarras: string | number) => {
    setIsLoadingEdit(true);
    try {
      const response = await getEanById(idCodigoDeBarras);

      const data = response.data;
      formik.setValues({
        codProdFornecedor: data.codProdFornecedor,
        codigo: data.codigo,
        status: data.status,
      });

      setIsPrincipal(data.isPrincipal);

      setIdCodigoDeBarras(data.id);

      setIsLoadingEdit(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingEdit(false);
    }
  };

  const getEanData = async (idProduto: string | number) => {
    setIsLoading(true);
    try {
      const response = await getEanByIdProduto(idProduto);

      const {content} = response.data;

      setIsCodigoBarrasData(content);

      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getEanData(id);
    }
  }, []);

  return (
    <>
      {isLoadingEdit ? (
        <Skeleton className='mb-4 mt-4' count={1} height={'40px'} />
      ) : (
        <div className='form-control-solid mt-6'>
          <RowBootstrap className='col-12 mb-6'>
            <Col md='3' className='col-12'>
              <label className='form-label'>Código EAN:</label>
              <input
                type='text'
                placeholder='Digite o código de barras'
                {...formik.getFieldProps('codigo')}
                className={`form-control ${
                  formik.touched.codigo && formik.errors.codigo ? 'is-invalid' : ''
                }`}
              />
              {formik.touched.codigo && formik.errors.codigo ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.codigo}
                </div>
              ) : null}
            </Col>
            <Col md='3' className='col-12'>
              <label className='form-label'>Código no Fabricante / Fornecedor:</label>
              <input
                type='text'
                placeholder='Código no Fabricante / Fornecedor:'
                {...formik.getFieldProps('codProdFornecedor')}
                className={`form-control ${
                  formik.touched.codProdFornecedor && formik.errors.codProdFornecedor
                    ? 'is-invalid'
                    : ''
                }`}
              />
              {formik.touched.codProdFornecedor && formik.errors.codProdFornecedor ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.codProdFornecedor}
                </div>
              ) : null}
            </Col>
            {/* <Col className='col-sm-2'>
              <div>
                <label className='form-label'>Codigo Fornecedor:</label>
                <select
                  {...formik.getFieldProps('codProdFornecedor')}
                  className={`form-control ${
                    formik.touched.codProdFornecedor && formik.errors.codProdFornecedor ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
              {formik.touched.status && formik.errors.status ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.status}
                </div>
              ) : null}
            </Col> */}
            <Col md='2' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
              {formik.touched.status && formik.errors.status ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.status}
                </div>
              ) : null}
            </Col>
            <Col md='1' className='col-12'>
              <label htmlFor='' className='form-label'>
                Código Principal?
              </label>
              <div className='form-check form-switch mt-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault2'
                  checked={isPrincipal}
                  onChange={() => setIsPrincipal((prev) => !prev)}
                />
                <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                  <b>{isPrincipal === true ? 'Sim' : 'Não'}</b>
                </label>
              </div>
            </Col>
            <Col
            md='3'
              className='col-12 mt-4'
              style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start'}}
            >
              <button
                className='btn btn-success btn-sm'
                type='button'
                onClick={() => formik.handleSubmit()}
                style={{height: '42px'}}
              >
                {isEditing ? (
                  isLoading ? (
                    <Spinner animation='border' size='sm' />
                  ) : (
                    <i
                      className='bi bi-pencil'
                      style={{margin: 0, padding: 0, fontSize: '18px'}}
                    ></i>
                  )
                ) : isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <i className='bi bi-plus' style={{margin: 0, padding: 0, fontSize: '18px'}}></i>
                )}
              </button>

              {isEditing ? (
                <button
                  className='btn btn-danger btn sm'
                  type='button'
                  style={{marginLeft: '5px', height: '42px'}}
                  onClick={handleCancelEdit}
                >
                  <i className='bi bi-x-lg' style={{padding: '0px'}}></i>
                </button>
              ) : (
                <></>
              )}
            </Col>
          </RowBootstrap>
        </div>
      )}

      <div className='card card-flush mb-4'>
        <div className='card-body' style={{padding: '0px', alignItems: 'stretch'}}>
          <div className='table-responsive'>
            <table
              id='kt_table_formularios'
              className='table table-row-dashed gs-0 gy-3 my-0'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<ICododigoBarras>, index: number) => (
                    <th
                      key={column.id}
                      className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<ICododigoBarras>, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        showEditButton
                        onEdit={(id) => handleOnEdit(id)}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Produto sem Código EAN
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodigoBarras;
