import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {ColumnInstance, useTable, Row, Column} from 'react-table';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {DescriptionComponent} from '../../../shared/components';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getUnidadesMedidas} from '../listagem_requests/listagem_requests';
import {IUnidadeMedida} from '../../../shared';

const UnidadeMedidasListagem: React.FC = () => {
  const formularioColumns: Column<IUnidadeMedida>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Título',
      accessor: 'titulo',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => (
        <div className='text-start'>
          {value ? (value.length > 40 ? `${value.slice(0, 40)}...` : value || '') : '...'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original.id!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  const [isUnidadeMedidaData, setIsUnidadeMedidaData] = useState<IUnidadeMedida[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const data = useMemo(() => isUnidadeMedidaData, [isUnidadeMedidaData]);
  const columns = useMemo(() => formularioColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  // aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
  const initialValues = {
    titulo: '',
    descricao: '',
    status: 'ATIVO',
  };
//aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);

      try {
        const response = await getUnidadesMedidas({
          titulo: values.titulo,
          descricao: values.descricao,
          page: isPage,
          status: values.status,
        });

        const {content} = response.data;

        setIsLoadingSearch(false);
        setSubmitting(false);

        setIsUnidadeMedidaData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setSubmitting(false);
        setIsLoadingSearch(false);
      }
    },
  });   
  
  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    navigate(`/produto-unidade-medida/${id}`);
  };

  const getUnidadesMedidasData = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await getUnidadesMedidas({
        titulo: '',
        descricao: '',
        page,
        status: 'ATIVO',
      });

      const {content, totalPages} = response.data;
      setIsUnidadeMedidaData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUnidadesMedidasData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Unidades de Medida'
        withLink
        buttonTitle='+ Nova Unidade de Medida'
        redirectTo='/produto-unidade-medida'
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-6'>
              <label className='form-label'>Titulo:</label>
              <input
                type='text'
                placeholder='Digite o titulo da unidade de medida'
                {...formik.getFieldProps('titulo')}
                className='form-control'
              />
            </Col>
            <Col className='col-sm-6'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' 
                {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO
                  '>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição da unidade de medida'
                {...formik.getFieldProps('descricao')}
                className='form-control'
              />
            </Col>
          </RowBootstrap>
          <div className='row mt-6'>
            <div className='col-sm-2 mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        <table
          id='kt_table_formularios'
          className='table table-hover table-striped table-rounded table-row-bordered border'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IUnidadeMedida>, index: number) => (
                <th
                  key={column.id}
                  className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IUnidadeMedida>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    showEditButton
                    onEdit={(id) => handleOnEdit(id)}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UnidadeMedidasListagem;
