import {Col, Row, Spinner} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import axios from 'axios';
import {IContaCorrente, ISelectOption, smoothScrollToBottom, useModal} from '../../../shared';
import {Link, useParams} from 'react-router-dom';
import ModalContaCorrente from './components/ModalContaCorrente';
import AsyncSelect from 'react-select/async';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemContaCorrente = () => {
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [countSkeleton, setCountSkeleton] = useState(40);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null);

  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<string | null>('ATIVO');
  const [contasCorrentes, setContasCorrentes] = useState<IContaCorrente[]>([]);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [numero, setNumero] = useState< string | null>(null);
  const [agencia, setAgencia] = useState<string | null>(null);
  const [operacao, setOperacao] = useState<string | null>(null);
  const [selectedBanco, setSelectedBanco] = useState<ISelectOption | null>(null);
  const [bancoNome, setBancoNome] = useState('');

  const [isContaCorrenteModal, openContaCorrenteModal, closeContaCorrenteModal] = useModal();

  const fetchContaCorrente = async (page = 0) => {
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/listar?idBanco=${id}`,
        {
          params: {
            status: status,
            page: page,
          },
        }
      );
      const {content, totalPages} = response.data;
      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        setContasCorrentes((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de conta corrente para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    const fetchBancoDetails = async () => {
      try {
        const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/${id}`);
        setBancoNome(response.data.titulo);
      } catch (error) {
        console.error('Erro ao buscar detalhes do banco:', error);
      }
    };

    if (id) {
      fetchBancoDetails();
    }
  }, [id]);

  const loadOptionsBancos = async (inputValue) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-bancos/listar?titulo=${inputValue}`;

      const response = await axios.get(url, {
        params: {
          size: 1000,
          status: 'ATIVO',
        },
      });
      const data = await response.data.content;

      return data
        .map((item) => ({
          value: item.id,
          label: `${item.titulo} (ID: ${item.id})`,
        }))
        // .filter(
        //   (option) =>
        //     option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        //     option.value.toString().includes(inputValue)
        // );
    } catch (error) {
      console.error('Erro ao buscar opções:', error);
      return [];
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(0);
    setIsLoading(true);
    setIsLoadingSearch(true);
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-conta-corrente/listar`,
        {
          params: {
            idBanco: id,
            banco: selectedBanco?.label,
            numero: numero?.replace(/^0+|-/g, ''),
            agencia: agencia?.replace(/^0+|-/g, ''),
            operacao: operacao,
            status: status,
            page: page,
            descricao: descricao ? descricao : null,
          },
        }
      );
      const {content, totalPages} = response.data;
      if (response.status === 200) {
        if (page === 0) {
          setDataLine(response.data[0]);
        }
        setContasCorrentes((prev) => (page === 0 ? content : [...prev, ...content]));
        setHasMore(page < totalPages - 1);
        setIsLoading(false);
      }
      if (content.length === 0) {
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de conta corrente para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setIsLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  const handleNumeroChange = (e) => {
    
    let inputValue = e.target.value.replace(/\D/g, '');
    inputValue = inputValue.padStart(9, '0').slice(-9);

    if (inputValue === '000000000') {
      setNumero(null);
    } else {
        setNumero(`${inputValue.slice(0, 8)}-${inputValue.slice(8)}`);
    }
  };

  const handleAgenciaChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    inputValue = inputValue.padStart(4, '0').slice(-4);

    if (inputValue === '0000') {
        setAgencia(null);
    } else {
        setAgencia(inputValue);
    }
  };

  const openModal = (isEdit, ...data: (string | number)[]) => {
    if (data) {
      setDataLine(data);
    }
    setIsEdit(isEdit);
    openContaCorrenteModal();
  };

  const clearFiltros = () => {
    setNumero(null);
    setOperacao(null);
    setAgencia(null);
    setDescricao(null);
    setStatus('ATIVO');
    setPage(0);
    fetchContaCorrente(page);
  };

  const loadMore = () => {
    setIsLoading(true);
    setIsLoading(false);
    setPage((prevPage) => prevPage + 1);
    setCountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };

  useEffect(() => {
    fetchContaCorrente(page);
  }, [page]);

  return (
    <div>
      {/* TITULO E CADASTRO */}
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Conta Corrente
        </h1>
        <button
          className='btn btn-success '
          style={{fontSize: '14px'}}
          onClick={() => {
            openModal(false);
          }}
        >
          Cadastrar Conta Corrente
        </button>
      </div>
      {/* BARRA HORRIZONTAL */}
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      {/* FILTROS */}
      <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
      <div>
        <form action=''>
          <Row className='mt-4 col-12 mb-4 row'>
          <Col>
            <label htmlFor='' className='form-label'>
              Banco
            </label>
            <AsyncSelect
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectedBanco(null);
                }
              }}
              value={selectedBanco?.label}
              placeholder={`${bancoNome} (ID: ${id})`}
              onChange={(e) => {
                setSelectedBanco(e);
                console.log(e);
              }}
              isDisabled
              loadOptions={loadOptionsBancos}
            />
          </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <Col>
              <label htmlFor='' className='form-label'>
                Operação:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  setOperacao(e.target.value)
                  console.log(e.target.value)
                }}
                value={operacao ?? ''}
              >
                <option value=''>Selecione uma operação...</option>
                <option value='ENTRADA'>ENTRADA</option>
                <option value='SAIDA'>SAIDA</option>
              </select>
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Número:
              </label>
              <input
                type='text'
                value={numero ? numero : '00000000-0'}
                className='form-control'
                onChange={handleNumeroChange}
                placeholder='Digite o Número'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Agência:
              </label>
              <input
                type='text'
                value={agencia ? agencia : '0000'}
                className='form-control'
                onChange={handleAgenciaChange}
                placeholder='Digite a Agência'
              />
            </Col>
            <Col>
              <label htmlFor='' className='form-label'>
                Status:
              </label>
              <select
                className='form-select'
                onChange={(e) => {
                  // handleChangeStatus(e.target.value)
                  setStatus ? setStatus(e.target.value) : console.log(e.target.value);
                }}
                value={status ? status : ''}
                name=''
                id=''
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
          </Row>
          <Row className='mt-4 col-12 mb-4 row'>
            <label htmlFor='' className='form-label'>
              Descrição:
            </label>
            <Col className=''>
              <textarea
                name=''
                rows={3}
                value={descricao ? descricao : ''}
                onChange={(e) => {
                  setDescricao(e.target.value);
                  setDescricao ? setDescricao(e.target.value) : console.log(e.target.value);
                }}
                placeholder='Digite a descrição'
                className='form-control'
                id=''
              ></textarea>
            </Col>
          </Row>
          <div className='col-12 my-6' style={{display: 'flex', justifyContent: 'end'}}>
            <div
              className='col-sm-4 mx-5'
              style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
            >
              <Link className='btn mx-5 px-10 btn-info ' to='/listagem-bancos'>
                Voltar
              </Link>
              <button
                type='button'
                style={{width: '40%'}}
                onClick={() => clearFiltros()}
                className='btn btn-danger form-control'
              >
                {isLoading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
              </button>
              <button
                onClick={handleSearch}
                type='submit'
                style={{width: '40%', marginLeft: '10px'}}
                className='btn btn-primary d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search mx-0'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? (
        <Skeleton count={countSkeleton} height={25} />
      ) : (
        <>
          <div>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>ID</th>
                  <th className='text-center'>Banco</th>
                  <th className='text-center'>Operação</th>
                  <th className='text-center'>Número</th>
                  <th className='text-center'>Agência</th>
                  <th className='text-center'>Descrição</th>
                  <th className='text-center'>Criado por</th>
                  <th className='text-center'>Data de criação</th>
                  <th className='text-center'>Alterado por</th>
                  <th className='text-center'>Data de alteração</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Editar</th>
                </tr>
              </thead>
              <tbody>
                {contasCorrentes?.map((contaCorrente) => (
                  <tr className='text-center'>
                    <td>{contaCorrente.id}</td>
                    <td>{contaCorrente.banco || '...'}</td>
                    <td>{contaCorrente.operacao ? contaCorrente.operacao : '...'}</td>
                    <td>{contaCorrente.numero ? contaCorrente.numero : '...'}</td>
                    <td>{contaCorrente.agencia ? contaCorrente.agencia : '...'}</td>
                    <td>{contaCorrente.descricao ? contaCorrente.descricao : '...'}</td>
                    <td>{contaCorrente.usuarioCriacaoNome}</td>
                    <td>{contaCorrente.dataCriacao}</td>
                    <td>
                      {contaCorrente.usuarioAlteracaoNome
                        ? contaCorrente.usuarioAlteracaoNome
                        : '...'}
                    </td>
                    <td>{contaCorrente.dataAlteracao}</td>
                    <td>{contaCorrente.status}</td>
                    <td>
                      <div className='text-center'>
                        <button
                          className='btn btn-success btn-sm bi bi-pencil'
                          type='button'
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Editar'
                          onClick={() => {
                            openModal(
                              true,
                              contaCorrente.id,
                              contaCorrente?.banco || '',
                              contaCorrente.operacao || '',
                              contaCorrente.numero,
                              contaCorrente.agencia,
                              contaCorrente.status,
                              contaCorrente.descricao
                            );
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <ModalContaCorrente
        isOpen={isContaCorrenteModal}
        onHide={closeContaCorrenteModal}
        isEdit={isEdit}
        dataLine={dataLine}
        loadContaCorrente={fetchContaCorrente}
      />
    </div>
  );
};

export default ListagemContaCorrente;
