import { Col, Row, } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { CrudHeader, ICrudBase, smoothScrollToBottom, TableListagem, useModal } from "../../../shared";
import ButtonEditListagem from "../../empreendimento/segmento-empreendimento/components/ButtonEditListagem";
import ModalPrazoPagamento from "./components/ModalPrazoPagamento";
// import ButtonEditListagem from "../../empreendimento/segmento-empreendimento/components/ButtonEditListagem";
// import ModalPrazo from "./components/ModalPrazo";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const PrazoPagamento = () => {

    // VARIAVEIS
    const [prazos, setPrazo] = useState<ICrudBase[]>([])
    const [dataLine, setDataLine] = useState<ICrudBase[]>([])

    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isClear, setIsClear] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)

    const colums =['Id','Titulo', 'Descrição', 'Criado por', 'Data de criação', 'Alterado por', 'Data de alteração', 'Status', 'Ações']

    const [isPrazoModal, openPrazoModal, closePrazoModal] = useModal();

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }
    const fetchPrazo = async (page = 0) => {
        setIsLoading(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/prazo-pagamento/listar`,
                {
                    params: {
                        status: status,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                if (page === 0) {
                }
                setPrazo((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Tipos de Obra para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);
            // setIsLoadingSearch(false);

        }
    }; 

    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setPage(0)
        setIsClear(true)
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchPrazo(0);
    };

    const openModal = (isEdit=false, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openPrazoModal();
    }

    useEffect(() => {
        if (isClear) {
            fetchPrazo(0)
        }
        setIsClear(false)
    }, [isClear])

    useEffect(() => {
        fetchPrazo(page)
    }, [page]);

    return (
        <div>
            <CrudHeader  title='Prazos de Pagamento' titleCadastro='Prazo' clearFiltros={clearFiltros} handleSearch={handleSearch} isLoading={isLoading} openModal={openModal} >
                <Row className=" row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Titulo:</label>
                        <input type="text"
                            className="form-control"
                            value={titulo ? titulo : ""}
                            onChange={(e) => {
                                setTitulo(e.target.value)
                            }}
                            placeholder="Digite o titulo" />
                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>
                        <select className="form-select" value={status ? status : ""}
                            onChange={(e) => { setStatus(e.target.value) }}>
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>

                    </Col>
                </Row>
                <Row className=" row">
                    <label htmlFor="" className="form-label">Descrição:</label>
                    <Col className="">
                        <textarea name=""
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                            placeholder="Digite a descrição" className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </CrudHeader>
            
            <TableListagem 
                    isLoading={isLoading}
                    coluns={colums}   
                    hasMore={hasMore} 
                    loadMore={loadMore}>
                            {prazos?.map((prazo) => (
                                <tr className="text-center">
                                    <td>{prazo.id}</td>
                                    <td>{prazo.titulo}</td>
                                    <td>{prazo.descricao ? prazo.descricao : "..."}</td>
                                    <td>{prazo.usuarioCriacaoNome}</td>
                                    <td>{prazo.dataCriacao}</td>
                                    <td>{prazo.usuarioAlteracaoNome? prazo.usuarioAlteracaoNome : "..."}</td>
                                    <td>{prazo.dataAlteracao}</td>
                                    <td>{prazo.status}</td>
                                    <td>
                                        <ButtonEditListagem handleEdit={() => { openModal(true,prazo.id) }}></ButtonEditListagem>
                                    </td>
                                </tr>
                            ))
                            }
            </TableListagem>

            <ModalPrazoPagamento
            clearFiltros={clearFiltros} 
            dataLine={dataLine} 
            reload={fetchPrazo} 
            isEdit={isEdit} 
            isOpen={isPrazoModal} 
            onHide={closePrazoModal} ></ModalPrazoPagamento>
        </div>
    )
}

export default PrazoPagamento