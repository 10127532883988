import {useEffect, useState} from 'react';
import {Form, InputGroup, Dropdown, Row, Col, Modal, Button} from 'react-bootstrap';
import {items} from './components/itemsHomeMenu';
import './homePage.scss';
import {useAuth} from '../../../app/modules/auth';
import {useNavigate} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {AsyncSelectArquivo, ISelectOption, PDFView} from '../../shared';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  imprimirPDFArquivo,
  visualizarPDFArquivo,
} from '../listagem/listagem_requests/listagem_requests';

// Função para organizar permissões por 'arquivo'
function organizaPermissoes(permissions) {
  const permissionMap = {};
  permissions.forEach((permission) => {
    permissionMap[permission.arquivo] = true;
  });
  return permissionMap;
}

export function HomePage() {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const navigate = useNavigate();
  const {currentUser} = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const [openSubMnu, setOpenSubMnu] = useState<{itemId: number; subItemId: number} | null>(null);
  const [isArquivoFiltered, setIsArquivoFiltered] = useState<ISelectOption[]>([]);
  const [isArquivo, setIsArquivo] = useState<ISelectOption | null>(null);
  const [isDefaultOptionsArquivos, setDefaultOptionsArquivos] = useState<ISelectOption[]>([]);

  // Filtrando as permissões
  const permissoes = currentUser?.usuariosPermissoes || [];
  const permissionMap = organizaPermissoes(permissoes);

  // Função para renderizar itens com base na permissão
  const renderMenuItemIfPermission = (permissions, menuItem) => {
    // Se o usuário for ADMIN, retornar o item sem verificar permissões
    if (currentUser?.tipouser === 'ADMIN') {
      return menuItem;
    }

    // Se não houver permissões para o item, permitir que todos vejam
    if (!permissions || permissions.length === 0) {
      return menuItem; // Todos podem ver
    }

    // Verificar se o usuário tem permissão
    const hasPermission = permissions.some((permission) => permissionMap[permission]);
    return hasPermission ? menuItem : null;
  };

  // Filtrando itens com base no termo de busca
  const filteredItems = items.filter((item) => {
    const matchesMainItem = item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSubMenu =
      item.subMenu &&
      item.subMenu.some((subItem) => subItem.name.toLowerCase().includes(searchTerm.toLowerCase()));
    return matchesMainItem || matchesSubMenu;
  });

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [isIdArquivo, setIsIdArquivo] = useState<string | number>();

  const handleOptionArquivo = (option: ISelectOption) => {
    visualizarPDFArquivo(
      setModalShowPDFViewer,
      option.value,
      setIsLoading,
      setIsPdfUrl,
      setIsIdArquivo
    );

    setIsArquivo(option);
  };

  return (
    <div className='home-page-container'>
      <Row>
        <Col md='6' className='col-12'>
          <InputGroup className='mb-3 search-bar'>
            <label htmlFor='' className='form-label w-100'>
              Selecione o Atalho:
            </label>
            <Form.Control
              placeholder='Pesquisar atalho...'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              aria-label='Pesquisar'
              aria-describedby='basic-addon1'
            />
            <span className='input-group-text' id='basic-addon1'>
              <i className='ki-solid ki-search-list fs-1' />
            </span>
          </InputGroup>
        </Col>
        <Col md='4' className='col-12'>
          <label htmlFor='' className='form-label'>
            Selecione o Arquivo:
          </label>
          <AsyncSelectArquivo
            handleOptions={(option) => handleOptionArquivo(option!)}
            inputOption={isArquivo}
            onKeyDown={(e) => {
              setIsArquivo(null);
            }}
          />
          <small id='' className='form-text text-muted'>
            (Ajuda) Pesquise pelo documento referente ao módulo que deseja acessar
          </small>
        </Col>
      </Row>

      <div className='card-grid mt-20'>
        {filteredItems.map((item) =>
          renderMenuItemIfPermission(
            item.permissions,
            <a
              href={item.path || '#'}
              key={item.id}
              className='card hover-elevate-up shadow-sm parent-hover quick-access-card'
              onMouseEnter={() => {
                setOpenSubMenu(item.id);
              }}
              onMouseLeave={() => {
                setOpenSubMenu(null);
                setOpenSubMnu(null);
              }}
            >
              <div className='card-body d-flex align-items'>
                <span className={`svg-icon fs-1 ki-solid ${item.icon}`} />
                <span className='text-gray-700 parent-hover-primary fs-6 fw-bold'>{item.name}</span>
                {item.subMenu && (
                  <Dropdown show={openSubMenu === item.id}>
                    <Dropdown.Menu
                      style={{
                        position: 'absolute',
                        zIndex: 1050,
                        transform: 'translateY(20px) translateX(-50%)',
                      }}
                    >
                      {item.subMenu.map((subItem) =>
                        renderMenuItemIfPermission(
                          subItem.permissions || [],
                          <div
                            key={subItem.id}
                            style={{position: 'relative'}}
                            onMouseEnter={() =>
                              setOpenSubMnu({itemId: item.id, subItemId: subItem.id})
                            }
                            onMouseLeave={() => setOpenSubMnu(null)}
                          >
                            <Dropdown.Item
                              onClick={() => {
                                navigate(subItem.path || '#');
                              }}
                            >
                              {subItem.name}
                            </Dropdown.Item>

                            {subItem.subMnu && (
                              <Dropdown
                                show={
                                  openSubMnu?.itemId === item.id &&
                                  openSubMnu?.subItemId === subItem.id
                                }
                                align='end'
                              >
                                <Dropdown.Menu
                                  style={{
                                    position: 'absolute',
                                    left: '80%',
                                    top: '0',
                                    zIndex: 1050,
                                    transform: 'translateY(-40px)',
                                  }}
                                >
                                  {subItem.subMnu.map((subMnuItem) =>
                                    renderMenuItemIfPermission(
                                      subMnuItem.permissions,
                                      <Dropdown.Item
                                        key={subMnuItem.id}
                                        onClick={() => {
                                          navigate(subMnuItem.path);
                                        }}
                                      >
                                        {subMnuItem.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </a>
          )
        )}
      </div>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() =>
              imprimirPDFArquivo(
                isIdArquivo,
                setIsLoading,
                `${isArquivo?.original.titulo} - ${isArquivo?.original.descricao ?? ''}`
              )
            }
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </div>
  );
}
