import {useNavigate, useParams} from 'react-router-dom';
import {
  AsyncSelectEmpresa,
  AsyncSelectFornecedor,
  DescriptionComponent,
  FooterForm,
  ICondicoesPagamento,
  IFormaDePagamento,
  IMarca,
  ISelectOption,
  ISuprimentoStatus,
  pedidoCompraValidationSchema,
  smoothScrollToBottom,
} from '../../../shared';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import {editPedidoCompra, sendPedidoCompra} from '../cadastro_requests/cadastro_requests';
import {Col, Row} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {
  getPedidoCompraById,
  getSumarizacao,
} from '../../listagem/listagem_requests/listagem_requests';
import PedidoCompraDetails from './components/pedido-compra-details/pedido-de-compra-details';
import TableFormasPagamento from './components/table-formas-pagamento/table-formas-pagamento';
import * as S from './cadastro-pedido-compras-styles';

const PedidoComprasCadastroPage: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {id} = useParams();

  const navigate = useNavigate();

  const initialValues = {
    valorPedido: 0,
    volumeTotal: 0,
    pedidoFornecedor: '',
    acordoPercentual: 0,
    status: 'ATIVO',
    observacoes: '',
    descontoGeral: '',
    tipoFrete: '',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSelectAsyncEmpresa, setIsSelectAsyncEmpresa] = useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaOrigem, setIsSelectAsyncEmpresaOrigem] =
    useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaFaturamento, setIsSelectAsyncEmpresaFaturamento] =
    useState<ISelectOption | null>(null);

  const [isStatusSuprimento, setIsStatusSuprimento] = useState<ISelectOption | null>(null);
  const [isComprador, setIsComprador] = useState<ISelectOption | null>(null);
  const [isDominio, setIsDominio] = useState<ISelectOption | null>(null);
  const [isPrazoPagamento, setIsPrazoPagamento] = useState<ISelectOption | null>(null);
  const [isCompradorFiltered, setIsCompradorFiltered] = useState<ISelectOption[]>([]);
  const [isDominioFiltered, setIsDominioFiltered] = useState<ISelectOption[]>([]);
  const [isPrazoFiltered, setIsPrazoFiltered] = useState<ISelectOption[]>([]);
  const [defaultCompradorOptions, setDefaultCompradorOptions] = useState<ISelectOption[]>([]);
  const [defaultDominioOptions, setDefaultDominioOptions] = useState<ISelectOption[]>([]);
  const [defaultPrazoPagamentoOptions, setDefaultPrazoPagamentoOptions] = useState<ISelectOption[]>(
    []
  );

  const [isDataEmissao, setIsDataEmissao] = useState<Date | null>(null);
  const [isDataCancelamento, setIsDataCancelamento] = useState<Date | null>(null);
  const [isDataLiberacao, setIsDataLiberacao] = useState<Date | null>(null);
  const [isDataPrevisaoGeral, setIsDataPrevisaoGeral] = useState<Date | null>(null);
  const [isDataFaturamento, setIsDataFaturamento] = useState<Date | null>(null);

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setIsSelectAsyncEmpresa(option);
  };

  const handleAsyncSelectEmpresaOrigem = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaOrigem(option);
  };

  const handleAsyncEmpresaFaturamento = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaFaturamento(option);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true);
      if (id) {
        try {
          if (
            !isSelectAsyncEmpresa ||
            !isSelectAsyncEmpresaOrigem ||
            !isSelectAsyncEmpresaFaturamento ||
            !isSelectAsyncFornecedor ||
            !isComprador
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncEmpresa:
                title = 'A inserção da Empresa é obrigatória';
                break;
              case !isSelectAsyncEmpresaOrigem:
                title = 'A inserção da Empresa Origem é obrigatória';
                break;
              case !isSelectAsyncEmpresaFaturamento:
                title = 'A inserção da Empresa Faturamento é obrigatória';
                break;

              case !isSelectAsyncFornecedor:
                title = 'A Inserção do Fornecedor é obrigatória';
                break;
              case !isComprador:
                title = 'A Inserção do Comprador é obrigatório';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoading(false);
            return;
          }
          await editPedidoCompra(id, {
            ...values,
            tipoFrete: values.tipoFrete == '' ? null : values.tipoFrete,
            descontoGeral: parseFloat(String(values.descontoGeral).replace(',', '.')) || 0,
            dataCancelamento: isDataCancelamento,
            dataFaturamento: isDataFaturamento,
            dataLiberacao: isDataLiberacao,
            idEmpresa: isSelectAsyncEmpresa?.value,
            idEmpresaOrigem: isSelectAsyncEmpresaOrigem?.value,
            idEmpresaFaturamento: isSelectAsyncEmpresaFaturamento?.value,
            idFornecedor: isSelectAsyncFornecedor?.value,
            idStatus: isStatusSuprimento?.value,
            dataPrevisao: isDataPrevisaoGeral,
            idComprador: isComprador?.value,
            idDominio: isDominio?.value,
            idPrazoPagamento: isPrazoPagamento?.value,
          });
          Swal.fire({
            icon: 'success',
            title: 'Pedido de compra editado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de pedido de compra',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/pedido-compras-listagem');
            },
          });
          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          if (
            !isSelectAsyncEmpresa ||
            !isSelectAsyncEmpresaOrigem ||
            !isSelectAsyncEmpresaFaturamento ||
            !isSelectAsyncFornecedor ||
            !isComprador
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncEmpresa:
                title = 'A inserção da Empresa é obrigatória';
                break;
              case !isSelectAsyncEmpresaOrigem:
                title = 'A inserção da Empresa Origem é obrigatória';
                break;
              case !isSelectAsyncEmpresaFaturamento:
                title = 'A inserção da Empresa Faturamento é obrigatória';
                break;

              case !isSelectAsyncFornecedor:
                title = 'A Inserção do Fornecedor é obrigatória';
                break;
              case !isComprador:
                title = 'A Inserção do Comprador é obrigatório';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoading(false);
            return;
          }
          const response = await sendPedidoCompra({
            ...values,
            tipoFrete: values.tipoFrete == '' ? null : values.tipoFrete,
            descontoGeral: parseFloat(String(values.descontoGeral).replace(',', '.')) || 0,
            dataCancelamento: isDataCancelamento,
            dataLiberacao: isDataLiberacao,
            dataFaturamento: isDataFaturamento,
            dataPrevisao: isDataPrevisaoGeral,
            idEmpresa: isSelectAsyncEmpresa?.value,
            idEmpresaOrigem: isSelectAsyncEmpresaOrigem?.value,
            idEmpresaFaturamento: isSelectAsyncEmpresaFaturamento?.value,
            idFornecedor: isSelectAsyncFornecedor?.value,
            idStatus: isStatusSuprimento?.value,
            idComprador: isComprador?.value,
            idDominio: isDominio?.value,
            idPrazoPagamento: isPrazoPagamento?.value,
          });

          const {data} = response;

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Pedido de compra cadastrado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ir para o pedido de compra',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate(`/pedido-compras/${data.id}`);
              },
            });
          }
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    console.log(formik.values.tipoFrete);
  }, [formik.values.tipoFrete]);

  const loadSuprimentoStatus = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ISuprimentoStatus) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadComprador = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/listar?size=${1000}`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item) => ({
        value: item.id,
        label: `${item.nomeUsuario} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsCompradorFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadCompradorById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsComprador({
        value: data.id,
        label: `${data.nomeUsuario} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadDominio = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio/listar?size=${1000}`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsDominioFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadDominioById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dominio/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsDominio({
        value: data.id,
        label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadPrazoPagamento = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/prazo-pagamento/listar?size=${1000}`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      setIsPrazoFiltered(options);

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadPrazoPagamentoById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/prazo-pagamento/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsPrazoPagamento({
        value: data.id,
        label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadSuprimentoStatusById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsStatusSuprimento({
        value: data.id,
        label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const getPedidoCompraDataById = async () => {
    if (id) {
      try {
        const response = await getPedidoCompraById(id);

        const {data} = response;

        formik.setValues({
          volumeTotal: data.volumeTotal || '',
          valorPedido: data.valorPedido || '',
          pedidoFornecedor: data.pedidoFornecedor || '',
          acordoPercentual: data.acordoPercentual || '',
          status: data.status,
          observacoes: data.observacoes,
          descontoGeral: data.descontoGeral || '',
          tipoFrete: data.tipoFrete || '',
        });

        if (data.idStatus) {
          loadSuprimentoStatusById(data.idStatus);
        }

        if (data.idDominio) {
          loadDominioById(data.idDominio);
        }

        if (data.idPrazoEntrega) {
          loadPrazoPagamentoById(data.idPrazoEntrega);
        }

        if (data.idComprador) {
          loadCompradorById(data.idComprador);
        }

        if (data.idPrazoPagamento) {
          loadPrazoPagamentoById(data.idPrazoPagamento);
        }

        setIsSelectAsyncFornecedor({
          label: '',
          value: data.idFornecedor,
        });

        setIsSelectAsyncEmpresa({
          label: '',
          value: data.idEmpresa,
        });
        setIsSelectAsyncEmpresaOrigem({
          label: '',
          value: data.idEmpresaOrigem,
        });
        setIsSelectAsyncEmpresaFaturamento({
          label: '',
          value: data.idEmpresaFaturamento,
        });

        setIsDataEmissao(data.dataEmissaoPedido ? new Date(data.dataEmissaoPedido) : null);
        setIsDataCancelamento(data.dataCancelamento ? new Date(data.dataCancelamento) : null);
        setIsDataLiberacao(data.dataLiberacao ? new Date(data.dataLiberacao) : null);
        setIsDataFaturamento(data.dataFaturamento ? new Date(data.dataFaturamento) : null);
        setIsDataPrevisaoGeral(data.dataPrevisao ? new Date(data.dataPrevisao) : null);

        console.log(response);
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  const getSumarizacaoData = async () => {
    try {
      const response = await getSumarizacao(id!);

      const {data} = response;

      formik.setFieldValue('valorPedido', data.valorPedido);
      formik.setFieldValue('volumeTotal', data.volumeTotal);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  };

  const handleTapPedidoCompra = () => {
    getSumarizacaoData();
  };

  useEffect(() => {
    getPedidoCompraDataById();

    if (id) {
      setTimeout(() => {
        smoothScrollToBottom(700);
      }, 300);
    }
  }, [id]);

  const [isSelectAsyncFornecedor, setIsSelectAsyncFornecedor] = useState<ISelectOption | null>(
    null
  );

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsSelectAsyncFornecedor(option);
  };

  const filteredOptionsComprador = (inputValue: string) => {
    return isCompradorFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsDominio = (inputValue: string) => {
    return isDominioFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filteredOptionsPrazo = (inputValue: string) => {
    return isPrazoFiltered.filter((option) =>
      option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadFilteredOptionsComprador = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsComprador(inputValue));
  };

  const loadFilteredOptionsDominio = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsDominio(inputValue));
  };

  const loadFilteredOptionsPrazoPagamento = (
    inputValue: string,
    callback: (options: ISelectOption[]) => void
  ) => {
    callback(filteredOptionsPrazo(inputValue));
  };

  useEffect(() => {
    const fetchCompradorOptions = async () => {
      const options = (await loadComprador()) || [];
      setDefaultCompradorOptions(options);
    };

    fetchCompradorOptions();
  }, []);

  useEffect(() => {
    const fetchDominio = async () => {
      const options = (await loadDominio()) || [];
      setDefaultDominioOptions(options);
    };

    fetchDominio();
  }, []);

  useEffect(() => {
    const fetchDominio = async () => {
      const options = (await loadPrazoPagamento()) || [];
      setDefaultPrazoPagamentoOptions(options);
    };

    fetchDominio();
  }, []);

  useEffect(() => {
    console.log('desconto geral:', formik.values.descontoGeral);
  }, [formik.values.descontoGeral]);

  return (
    <>
      {id && (
        <S.ContainerBadge>
          <S.BadgeEnviroment>
            <p>
              Número do Pedido de Compra: <span>{id}</span>
            </p>
          </S.BadgeEnviroment>
        </S.ContainerBadge>
      )}

      <DescriptionComponent
        description={id ? 'Edição de Pedido de Compra' : 'Cadastro de Pedido de Compra'}
        withLink={true}
        buttonTitle='Listar Pedido de Compras'
        redirectTo='/pedido-compras-listagem'
      />

      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row>
          <Col className='col-sm-12'>
            <label htmlFor='' className='form-label'>
              Empresa Estoque:
            </label>

            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
              inputOption={isSelectAsyncEmpresa}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresa(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Empresa de Solicitante:
            </label>
            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncSelectEmpresaOrigem(option!)}
              inputOption={isSelectAsyncEmpresaOrigem}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresaOrigem(null);
                }
              }}
            />
          </Col>
          <Col className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Empresa Faturamento:
            </label>

            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncEmpresaFaturamento(option!)}
              inputOption={isSelectAsyncEmpresaFaturamento}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresaFaturamento(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-6'>
          <Col className='col-sm-3'>
            <label className='form-label'>Código do Pedido do Fornecedor:</label>
            <input
              type='text'
              className='form-control'
              placeholder='Digite o Código do Pedido do Fornecedor'
              {...formik.getFieldProps('pedidoFornecedor')}
            />
          </Col>
          <Col className='col-3'>
            <label className='form-label'>Acordo Percentual (%):</label>
            <input
              type='number'
              className='form-control'
              placeholder='Digite o Código do Pedido do Fornecedor'
              {...formik.getFieldProps('acordoPercentual')}
            />
          </Col>
          <Col className='col-sm-3'>
            <label className='form-label'>Volume Total:</label>
            <input
              type='number'
              readOnly
              disabled
              placeholder='Digite o Volume Total'
              {...formik.getFieldProps('volumeTotal')}
              className={`form-control`}
            />

            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
          <Col className='col-sm-3'>
            <label className='form-label'>Valor do Pedido:</label>
            <input
              type='text'
              placeholder='Valor do Pedido'
              readOnly
              disabled
              value={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(formik.values.valorPedido || 0)}
              className={`form-control`}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Remove caracteres não numéricos
                inputValue = inputValue.replace(/\D/g, '');

                if (inputValue === '') {
                  inputValue = '0,00';
                }

                // Converte o valor em número e atualiza o Formik com o valor numérico
                formik.setFieldValue('valorPedido', parseFloat(inputValue) / 100);
              }}
            />
            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Emissão de Pedido:
            </label>

            <DatePicker
              style={{filter: 'brightness(0.95)'}}
              format='dd-MM-yyyy'
              placeholder='Data de emissão'
              readOnly
              className=' w-100'
              onChange={(date) => setIsDataEmissao(date)}
              value={isDataEmissao}
            />

            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Cancelamento:
            </label>

            <DatePicker
              format='dd-MM-yyyy'
              className='w-100'
              placeholder='Data cancelamento'
              onChange={(date) => setIsDataCancelamento(date)}
              value={isDataCancelamento}
            />
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Liberação:
            </label>

            <DatePicker
              className='w-100'
              format='dd-MM-yyyy'
              placeholder='Data Liberação'
              onChange={(date) => setIsDataLiberacao(date)}
              value={isDataLiberacao}
            />
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Faturamento:
            </label>

            <DatePicker
              className='w-100'
              format='dd-MM-yyyy'
              placeholder='Data de Faturamento'
              onChange={(date) => setIsDataFaturamento(date)}
              value={isDataFaturamento}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md='6' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Status:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Status'
              loadOptions={loadSuprimentoStatus}
              value={isStatusSuprimento}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setIsStatusSuprimento(selectedOption);
                } else {
                  setIsStatusSuprimento(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
          </Col>
          <Col md='6' className='col-12'>
            <label className='form-label'>Status ATIVO/INATIVO:</label>
            <select
              {...formik.getFieldProps('status')}
              className={`form-control ${
                formik.touched.status && formik.errors.status ? 'is-invalid' : ''
              }`}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
            <small id='' className='form-text text-muted'>
              Ative ou Desative o Pedido de Compra
            </small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md='3' className='col-12'>
            <label htmlFor='' className='form-label'>
              Data de Previsão Geral:
            </label>

            <DatePicker
              className='w-100'
              format='dd-MM-yyyy'
              placeholder='Data Previsão'
              onChange={(date) => setIsDataPrevisaoGeral(date)}
              value={isDataPrevisaoGeral}
            />
          </Col>
          <Col md='3' className='col-12'>
            <label htmlFor='' className='form-label'>
              Desconto (%):
            </label>
            <input
              placeholder='Insira o Valor do Desconto (%):'
              type='text'
              id=''
              {...formik.getFieldProps('descontoGeral')}
              className={`form-control ${
                formik.touched.descontoGeral && formik.errors.descontoGeral ? 'is-invalid' : ''
              }`}
              onChange={(e) => {
                let inputValue = e.target.value;

                inputValue = inputValue.replace(/\D/g, '');

                if (inputValue.length > 2) {
                  const intPart = inputValue.slice(0, -2);
                  const decimalPart = inputValue.slice(-2);
                  inputValue = `${intPart},${decimalPart}`;
                } else if (inputValue.length > 0) {
                  inputValue = `0,${inputValue.padStart(2, '0')}`;
                }

                inputValue = inputValue.replace(/^0+(?=\d)/, '');

                formik.setFieldValue('descontoGeral', inputValue);
              }}
            />
          </Col>
          <Col md={3} className='col-12'>
            <label className='form-label'>Tipo de Frete Acordado:</label>
            <select
              {...formik.getFieldProps('tipoFrete')}
              className={`form-control ${
                formik.touched.tipoFrete && formik.errors.tipoFrete ? 'is-invalid' : ''
              }`}
            >
              <option value=''>Selecione</option>
              <option value='CIF'>CIF</option>
              <option value='FOB'>FOB</option>
            </select>

            {formik.touched.tipoFrete && formik.errors.tipoFrete ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.tipoFrete}
              </div>
            ) : null}
          </Col>
          <Col md='3' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Prazo de Pagamento:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              isClearable
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Prazo de Pagamento'
              loadOptions={loadFilteredOptionsPrazoPagamento}
              value={isPrazoPagamento}
              onChange={(selectedOption: ISelectOption | null) => {
                if (selectedOption) {
                  setIsPrazoPagamento(selectedOption);
                } else {
                  setIsPrazoPagamento(null);
                }
              }}
              cacheOptions
              defaultOptions={defaultPrazoPagamentoOptions}
            />
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col md='6' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Comprador:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              isClearable
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Comprador'
              loadOptions={loadFilteredOptionsComprador}
              value={isComprador}
              onChange={(selectedOption: ISelectOption | null) => {
                if (selectedOption) {
                  setIsComprador(selectedOption);
                } else {
                  setIsComprador(null);
                }
              }}
              cacheOptions
              defaultOptions={defaultCompradorOptions}
            />
          </Col>
          <Col md='6' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Domínio:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              isClearable
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Domínio'
              loadOptions={loadFilteredOptionsDominio}
              value={isDominio}
              onChange={(selectedOption: ISelectOption | null) => {
                if (selectedOption) {
                  setIsDominio(selectedOption);
                } else {
                  setIsDominio(null);
                }
              }}
              cacheOptions
              defaultOptions={defaultDominioOptions}
            />
          </Col>
          <Col md='6' className='col-12'></Col>
        </Row>
        <Row className='mt-4'>
          <Col md='12' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Fornecedor:
            </label>

            <AsyncSelectFornecedor
              handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
              inputOption={isSelectAsyncFornecedor}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncFornecedor(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Observação:</label>
            <textarea
              style={{height: '100px'}}
              placeholder='Digite uma Observação'
              {...formik.getFieldProps('observacoes')}
              className={`form-control`}
            />
          </Col>
        </Row>

        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Pedido de Compra' : 'Cadastrar Pedido de Compra'}
          redirectTo='pedido-compras-listagem'
        />
      </form>

      {id && <TableFormasPagamento />}

      {id ? <PedidoCompraDetails handleTapAction={() => handleTapPedidoCompra()} /> : <></>}
    </>
  );
};

export default PedidoComprasCadastroPage;
