import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {AsyncSelectProduct, ISelectOption, Modal} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import AsyncSelectProductIsSysled from '../../../../shared/components/async-select-produto-sysled-id/async-select-produto-sysled-id';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalKit = ({isOpen, onHide, data, isEdit, loadMarcas}) => {
  const {id} = useParams();
  const [idProdutoPrincipal, setIdProdutoPrincipal] = useState<string | null>(id || null);
  const [quantidade, setQuantidade] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectAsyncProduct, setIsSelectAsyncProduct] = useState<ISelectOption | null>(null);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setIsSelectAsyncProduct(null);
    setQuantidade(null);
    setStatus('ATIVO');
  };

  const cadastrarKitProduto = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-kit`;
      if (isSelectAsyncProduct && idProdutoPrincipal && quantidade && status) {
        const response = await axios.post(url, {
          idProdutoPrincipal,
          idProdutoDoKit: isSelectAsyncProduct.value,
          quantidade,
          status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Kit do Produto cadastrado com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loadMarcas({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarKitProduto = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-kit/${data.id}`;
      if (idProdutoPrincipal && isSelectAsyncProduct && quantidade && status) {
        const response = await axios.put(url, {
          idProdutoPrincipal,
          idProdutoDoKit: isSelectAsyncProduct.value,
          quantidade,
          status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Kit do Produto editado com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loadMarcas({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarKitProduto() : cadastrarKitProduto())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen && isEdit) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-kit/${data.id}`;
        const {data: content, status} = await axios.get(url);

        if (status === 200) {
          setStatus(content.status);
          setIsSelectAsyncProduct({value: content.idProdutoDoKit, label: ''});
          setIdProdutoPrincipal(content.idProdutoPrincipal);
          setQuantidade(content.quantidade);
        } else {
          Swal.fire({
            icon: 'error',
            title: content?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
    if (!idProdutoPrincipal) {
      setIdProdutoPrincipal(id || null);
    }
  }, [data, isOpen, isEdit]);

  const handleAsyncSelect = (option: ISelectOption) => {
    setIsSelectAsyncProduct(option);
  };

  return (
    <div>
      <Modal {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Kit do Produto
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div style={{ height: '300px' }}>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id do Produto:
                </label>
                <input
                  type='number'
                  value={idProdutoPrincipal ? idProdutoPrincipal : ''}
                  className='form-control'
                  disabled
                  placeholder='Digite o Id do Produto'
                />
                {(idProdutoPrincipal === null ||
                  idProdutoPrincipal === '' ||
                  idProdutoPrincipal === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Id do produto
                  </span>
                )}
              </Col>

              <Col>
                <label htmlFor='' className='form-label'>
                  Quantidade:
                </label>
                <input
                  type='text'
                  value={quantidade ? quantidade : ''}
                  className='form-control'
                  onChange={(e) => {
                    setQuantidade(e.target.value.replaceAll(/\D/g, ''));
                  }}
                  placeholder='Digite o Quantidade'
                />
                {(quantidade === null || quantidade === '' || quantidade === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a quantidade</span>
                )}
              </Col>
              <Col>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col>
                <label htmlFor='' className='form-label'>
                  Id do Kit do Produto:
                </label>
                <AsyncSelectProductIsSysled
                  handleOptions={(option) => handleAsyncSelect(option!)}
                  inputOption={isSelectAsyncProduct}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncProduct(null);
                    }
                  }}
                />
                {(isSelectAsyncProduct === null || isSelectAsyncProduct === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Id do Kit do Produto
                  </span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalKit;
