import { useEffect, useState } from 'react';
import { Button, Col, ModalProps, Row, Spinner } from 'react-bootstrap';
import { HttpClient, IConfiguracoes, Modal, showAlert } from '../../../../../shared';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  handleRequest?: () => void;
  idItem?: number | null;
  isEdit?: boolean;
};

const ModalAddConfiguracao: React.FC<Props> = ({ isOpen, onHide, handleRequest, isEdit, idItem }) => {
  const http = new HttpClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDescricao, setIsDescricao] = useState<string>('');
  const [isStatus, setIsStatus] = useState<'ATIVO' | 'INATIVO'>('ATIVO');

  useEffect(() => {
    if (isEdit && idItem) {
      const fetchConfiguracao = async () => {
        try {
          const response = await http.request<IConfiguracoes>({
            method: 'GET',
            url: `configuracoes/${idItem}`,
          });
          setIsDescricao(response.data.descricao);
          setIsStatus(response.data.status);
        } catch (error) {
          showAlert('error', 'Erro ao carregar dados da configuração.');
        }
      };
      fetchConfiguracao();
    }
  }, [isEdit, idItem]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const url = isEdit && idItem ? `configuracoes/${idItem}` : `configuracoes`;
      const method = isEdit && idItem ? 'PUT' : 'POST';

      await http.request<IConfiguracoes, { descricao: string; status: 'ATIVO' | 'INATIVO' }>({
        method,
        url,
        data: {
          descricao: isDescricao,
          status: isStatus,
        },
      });

      showAlert('success', 'Configuração salva com sucesso.');

      if (handleRequest) handleRequest();
      onClose();
    } catch (error) {
      showAlert('error', 'Erro ao salvar configuração.');
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (onHide) {
      onHide();
    }
    setIsDescricao('');
    setIsStatus('ATIVO');
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isEdit ? 'Editar Configuração' : 'Cadastro de Configuração'}
      icon={() => <i className='bi bi-gear-fill' style={{ color: '#000', fontSize: '15px' }}></i>}
      actions={[
        <Button key='save' type='submit' variant='success' onClick={handleSubmit}>
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Salvar'}
        </Button>,
      ]}
      onHide={onClose}
      size='lg'
    >
      <form>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Descrição:</label>
            <input
              placeholder='Descrição da configuração'
              type='text'
              className='form-control'
              value={isDescricao}
              onChange={(e) => setIsDescricao(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Status:</label>
            <select
              className='form-control'
              value={isStatus}
              onChange={(e) => setIsStatus(e.target.value as 'ATIVO' | 'INATIVO')}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default ModalAddConfiguracao;
