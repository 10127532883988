import {useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  AsyncSelectEmpresa,
  AsyncSelectTipoEntidade,
  IMarcas,
  ISelectOption,
  Modal,
} from '../../../../shared';
import {Button, Col, ModalProps, Row, Spinner} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import {renderEntidade} from '../ListagemCredito';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

type ModalCreditoProps = {
  isOpen: boolean;
  onHide: () => void;
  data: IMarcas | null;
  isEdit: boolean;
  loader: ({page}) => Promise<void>;
};

const ModalCredito = ({isOpen, onHide, data, isEdit, loader}: ModalCreditoProps) => {
  const [limiteDisponivel, setLimiteDisponivel] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('ATIVO');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataUltimaSync, setIsDataUltimaSync] = useState<Date | null>(null);
  const [isSelectAsyncTipoEntidade, setIsSelectAsyncTipoEntidade] = useState<ISelectOption | null>(
    null
  );
  const [isSelectAsyncEntidade, setIsSelectAsyncEntidade] = useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresa, setIsSelectAsyncEmpresa] = useState<ISelectOption | null>(null);

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const onClose = () => {
    onHide!();
    setLimiteDisponivel(null);
    setStatus('ATIVO');
    setIsDataUltimaSync(null);
    setIsSelectAsyncTipoEntidade(null);
    setIsSelectAsyncEntidade(null);
    setIsSelectAsyncEmpresa(null);
  };

  const cadastrarCredito = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-credito`;
      if (
        isSelectAsyncEntidade &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEmpresa &&
        limiteDisponivel &&
        isDataUltimaSync &&
        status
      ) {
        const response = await axios.post(url, {
          idEntidade: isSelectAsyncEntidade ? isSelectAsyncEntidade?.value : '',
          idTipoEntidade: isSelectAsyncTipoEntidade ? isSelectAsyncTipoEntidade?.value : '',
          idEmpresa: isSelectAsyncEmpresa ? isSelectAsyncEmpresa?.value : '',
          limiteDisponivel: limiteDisponivel ? limiteDisponivel : '',
          dataUltimaSincronia: isDataUltimaSync,
          status: status,
        });

        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: `Crédito cadastrado com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: `Você deve preencher todos os campos`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'error',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const editarCredito = async () => {
    setIsLoading(true);
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-credito/${data?.id}`;
      if (
        isSelectAsyncEntidade &&
        isSelectAsyncTipoEntidade &&
        isSelectAsyncEmpresa &&
        limiteDisponivel &&
        isDataUltimaSync &&
        status
      ) {
        const response = await axios.put(url, {
          idEntidade: isSelectAsyncEntidade ? isSelectAsyncEntidade?.value : '',
          idTipoEntidade: isSelectAsyncTipoEntidade ? isSelectAsyncTipoEntidade?.value : '',
          idEmpresa: isSelectAsyncEmpresa ? isSelectAsyncEmpresa?.value : '',
          limiteDisponivel: limiteDisponivel ? limiteDisponivel : '',
          dataUltimaSincronia: isDataUltimaSync,
          status: status,
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `Crédito editado com sucesso`,
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            onClose();
          });
          loader({page: 0});
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    } catch (errors: any) {
      Swal.fire({
        icon: 'info',
        title: `Erro na requisicao`,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log(errors);
    }
    setIsLoading(false);
  };

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      onClick={() => (isEdit ? editarCredito() : cadastrarCredito())}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? 'Editar' : 'Cadastrar'}
    </Button>
  );

  const modalConfigs: ModalProps = {
    isOpen,
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };
  useEffect(() => {
    if (data && isOpen) {
      (async () => {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/cobranca-credito/${data.id}`;
        const {data: response, status} = await axios.get(url);

        if (status === 200) {
          setStatus(response.status);
          setLimiteDisponivel(response.limiteDisponivel);
          setIsDataUltimaSync(new Date(response.dataUltimaSincronia));
          setIsSelectAsyncEntidade({value: response.idEntidade, label: ''});
          setIsSelectAsyncTipoEntidade({value: response.idTipoEntidade, label: ''});
          setIsSelectAsyncEmpresa({value: response.idEmpresa, label: ''});
        } else {
          Swal.fire({
            icon: 'error',
            title: response?.mensagem,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })();
    }
  }, [data, isOpen]);

  return (
    <div>
      <Modal overflowBody='visible' {...modalConfigs}>
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          {' '}
          {isEdit ? 'Editar' : 'Cadastrar'} Crédito
        </h1>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div>
          <form action=''>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6' className='col-12'>
                <label className='form-label'>Tipo de Entidade:</label>
                <AsyncSelectTipoEntidade
                  handleOptions={(option) => setIsSelectAsyncTipoEntidade(option!)}
                  inputOption={isSelectAsyncTipoEntidade}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Backspace') {
                      setIsSelectAsyncTipoEntidade(null);
                    }
                  }}
                />
                {(isSelectAsyncTipoEntidade === null ||
                  isSelectAsyncTipoEntidade === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o tipo de entidade
                  </span>
                )}
              </Col>
              <Col sm='6' className='col-12'>
                <label className='form-label'>Entidade:</label>
                {renderEntidade(
                  isSelectAsyncEntidade,
                  setIsSelectAsyncEntidade,
                  isSelectAsyncTipoEntidade
                )}
                {(isSelectAsyncEntidade === null || isSelectAsyncEntidade === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a entidade</span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Limite Disponível:
                </label>
                <input
                  type='text'
                  value={limiteDisponivel ? limiteDisponivel : ''}
                  className='form-control'
                  onChange={(e) => {
                    setLimiteDisponivel(e.target.value);
                  }}
                  placeholder='Digite o limite disponível'
                />
                {(limiteDisponivel === null ||
                  limiteDisponivel === '' ||
                  limiteDisponivel === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe o Limite Disponivel
                  </span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Data da Última Sincronia:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data da Última Sincronia'
                  onChange={(date) => setIsDataUltimaSync(date)}
                  menuStyle={{zIndex: 100000}}
                  value={isDataUltimaSync}
                />
                {(isDataUltimaSync === null || isDataUltimaSync === undefined) && (
                  <span className={`form-label text-danger`}>
                    *Por favor, informe a data da última sincronia
                  </span>
                )}
              </Col>
            </Row>
            <Row className='mt-4 col-12 mb-4 row'>
              <Col sm='6' className='col-12'>
                <label className='form-label'>Empresa:</label>
                <div style={{zIndex: 100000}}>
                  <AsyncSelectEmpresa
                    handleOptions={(option) => setIsSelectAsyncEmpresa(option!)}
                    inputOption={isSelectAsyncEmpresa}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Backspace') {
                        setIsSelectAsyncEmpresa(null);
                      }
                    }}
                  />
                </div>
                {(isSelectAsyncEmpresa === null || isSelectAsyncEmpresa === undefined) && (
                  <span className={`form-label text-danger`}>*Por favor, informe a empresa</span>
                )}
              </Col>
              <Col sm='6'>
                <label htmlFor='' className='form-label'>
                  Status:
                </label>
                <select
                  className='form-select'
                  onChange={(e) => {
                    handleChangeStatus(e.target.value);
                  }}
                  value={status ? status : ''}
                  name=''
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
                {status === null && (
                  <span className={`form-label text-danger`}>*Por favor, informe o status</span>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCredito;
