import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {
  AsyncSelectCliente,
  AsyncSelectPrevenda,
  DescriptionComponent,
  ILogisticaFluxo,
  ILogisticaSeparacao,
  ISelectOption,
  ITipoEntrega,
  PDFView,
} from '../../../shared';
import {useNavigate} from 'react-router-dom';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {useEffect, useMemo, useState} from 'react';
import {
  getLogisticaSeparacao,
  imprimirPDFSeparacao,
  imprimirPrevenda,
  postSeparacaoItemSync,
  visualizarPDFPrevenda,
  visualizarPDFSeparacao,
} from '../listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row as RowBootstrap,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import {is} from 'date-fns/locale';
import {get} from 'http';

const LogisticaSeparacaoListagemPage: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectPrevenda, setSelectPrevenda] = useState<ISelectOption | null>(null);
  const [isSelectClient, setIsSelectClient] = useState<ISelectOption | null>(null);
  const [selectFluxo, setSelectFluxo] = useState<ISelectOption | null>(null);
  const [isTipoEntrega, setIsTipoEntrega] = useState<ISelectOption | null>(null);
  const [sequencia, setSequencia] = useState<number | null>(null);
  const [isIdG2, setIsIdG2] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [tipoPDF, setTipoPDF] = useState('');
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const navigate = useNavigate();

  const [isLoadingSyncSeparacao, setIsLoadingSyncSeparacao] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isLogisticaSeparacaoData, setIsLogisticaSeparacaoData] = useState<ILogisticaSeparacao[]>(
    []
  );

  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isIdPedido, setIsIdPedido] = useState();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [modalShowPDFViewer2, setModalShowPDFViewer2] = useState(false);

  const separacaoLogisticaColumns: Column<ILogisticaSeparacao>[] = [
    {
      Header: 'Id Pedido',
      accessor: 'idPrevenda',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Id G2',
      accessor: 'idG2',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Sequência',
      accessor: 'seqEntrega',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Cliente',
      accessor: 'idCliente',
      Cell: ({value, row}) => (
        <div className='text-start'>
          {value ? `${row.original.idCliente} - ${row.original.nomeCliente}` : '...'}
        </div>
      ),
    },
    {
      Header: 'Fluxo',
      accessor: 'fluxoDescricao',
      Cell: ({value, row}) => (
        <div
          className='text-start'
          style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}
        >
          {value}

          {row.original.isAmbientado && (
            <Badge style={{color: '#fff', marginTop: '5px'}} bg='info'>
              Ambientado
            </Badge>
          )}
        </div>
      ),
    },
    {
      Header: 'Tipo de Entrega',
      accessor: 'tipoEntrega',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Quantidade de itens',
      accessor: 'qtdItens',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({row, value}) => {
        let statusText;
        switch (value) {
          case 'CANCELADO':
            statusText = 'CANCELADO';
            break;
          case 'ABERTO':
            statusText = 'ABERTO';
            break;
          case 'CONCLUIDO':
            statusText = 'CONCLUÍDO';
            break;
          default:
            statusText = 'DESCONHECIDO';
        }

        return (
          <div
            style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}
            className='text-start'
          >
            {statusText}

            {(row.original.seqEntrega != null && row.original.isSincronizado == null) ||
            row.original.isSincronizado == 0 ? (
              <Badge style={{color: '#fff', marginTop: '5px'}} bg='info'>
                Aguardando Integração com WMS
              </Badge>
            ) : (
              <></>
            )}

            {row.original.isSincronizado == 1 ? (
              <Badge style={{color: '#fff', marginTop: '5px'}} bg='info'>
                Integrado com WMS
              </Badge>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div
          className='text-end'
          style={{display: 'flex', gap: '1', alignItems: 'center', justifyContent: 'flex-start'}}
        >
          {row.original.seqEntrega == null ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>
                  {row.original.seqEntrega
                    ? 'Não é possível realizar esta ação porque os items foram separados para logística.'
                    : 'Editar'}
                </Tooltip>
              }
            >
              <button
                onClick={() => handleOnEdit(row.original.id!)}
                className='btn btn-success btn-sm bi bi-pencil'
                data-toggle='tooltip'
                data-placement='top'
                title='Editar'
              />
            </OverlayTrigger>
          ) : (
            <></>
          )}

          {row.original.seqEntrega ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>Visualizar Separação</Tooltip>
              }
            >
              <button
                className='btn btn-primary btn-sm'
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleOnEdit(row.original.id!)}
              >
                {isLoadingSyncSeparacao ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  <i style={{padding: 0}} className='bi bi-eye'></i>
                )}
              </button>
            </OverlayTrigger>
          ) : (
            <></>
          )}

          {row.original.seqEntrega == null ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>Separar para logística</Tooltip>
              }
            >
              <button
                className='btn btn-info btn-sm'
                style={{
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={() => onHandleModal(row.original.id!)}
              >
                {isLoadingSyncSeparacao ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  <i style={{padding: 0}} className='bi bi-arrow-bar-up'></i>
                )}
              </button>
            </OverlayTrigger>
          ) : (
            <></>
          )}

          <div className='dropdown' style={{marginLeft: '7px'}}>
            <button className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
              <i className='bi bi-file-earmark-arrow-down'>PDF</i>
            </button>
            <ul className='dropdown-menu'>
              <li>
                <button
                  className='dropdown-item'
                  style={{color: 'red'}}
                  type='button'
                  onClick={() =>
                    visualizarPDFSeparacao(
                      setModalShowPDFViewer,
                      row.original.id,
                      setIsLoading,
                      setIsPdfUrl,
                      setIsIdSeparacao
                    )
                  }
                >
                  PDF DE SEPARAÇÃO
                </button>
              </li>
              <li>
                <button
                  className='dropdown-item'
                  style={{color: 'red'}}
                  type='button'
                  onClick={() =>
                    visualizarPDFPrevenda(
                      setModalShowPDFViewer2,
                      row.original.idPrevenda,
                      setIsLoading,
                      setIsPdfUrl,
                      setIsIdPedido,
                      setTipoPDF,
                      'padrao'
                    )
                  }
                >
                  PDF DO PEDIDO
                </button>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useMemo(() => isLogisticaSeparacaoData, [isLogisticaSeparacaoData]);
  const columns = useMemo(() => separacaoLogisticaColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const handleOnEdit = (id: number) => {
    navigate(`/logistica-separacao/${id}`);
  };

  const onHandleModal = (id: number) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja realizar a separação para logística?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, confirmar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleSyncSeparacao(Number(id));
      }
    });
  };

  const handleSyncSeparacao = async (id: number) => {
    setIsLoadingSyncSeparacao(true);

    try {
      await postSeparacaoItemSync(id);

      Swal.fire({
        icon: 'success',
        title: 'Separação em Logística com sucesso!',
        showCancelButton: false,
        timer: 3000,
        timerProgressBar: false,
      });

      setIsLoadingSyncSeparacao(false);
      getExpedicaoSeparacaoData(isPage);
    } catch (errors: any) {
      const {data, status} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingSyncSeparacao(false);
    }
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getExpedicaoSeparacaoData(0);
    setIsFiltered(true);
  };

  const getExpedicaoSeparacaoData = async (page = 0) => {
    setIsLoading(true);

    try {
      const response = await getLogisticaSeparacao(
        page,
        selectPrevenda ? selectPrevenda.value : undefined,
        selectFluxo ? selectFluxo.value : undefined,
        status ? status : undefined,
        sequencia ? sequencia : undefined,
        isTipoEntrega ? isTipoEntrega.value : undefined,
        isAmbientado ? isAmbientado : undefined,
        isExportacaoIntegracao ? isExportacaoIntegracao : undefined,
        isPendencia ? isPendencia : undefined,
        isIdG2 ? isIdG2 : undefined,
        isSelectClient ? isSelectClient.value : undefined
      );

      const {content, totalPages} = response.data;

      setIsLogisticaSeparacaoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });
    }
  };

  const clearFiltros = () => {
    setIsPage(0);
    setSelectPrevenda(null);
    setSequencia(null);
    setSelectFluxo(null);
    setIsTipoEntrega(null);
    setStatus('');
    setIsClear(true);
    setIsAmbientado(false);
    setIsExportacaoIntegracao(false);
    setIsPendencia(false);
    setIsSelectClient(null);
    setIsIdG2(null);
  };

  useEffect(() => {
    if (isClear) {
      getExpedicaoSeparacaoData(0);
      setIsClear(false);
    }
  }, [isClear]);

  const loadLogisticaFluxo = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ILogisticaFluxo) => ({
        value: item.id,
        label: item.titulo,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };
  const loadTipoEntrega = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: ITipoEntrega) => ({
        value: item.idTipoEntrega,
        label: item.tipoEntrega,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const [isAmbientado, setIsAmbientado] = useState(false);
  const [isPendencia, setIsPendencia] = useState(false);
  const [isExportacaoIntegracao, setIsExportacaoIntegracao] = useState(false);

  useEffect(() => {
    getExpedicaoSeparacaoData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description={'Listagem de Separação Logística'}
        buttonTitle='+ Cadastro de Logística de Seperação'
        redirectTo='/logistica-separacao'
        withLink
      />
      <h4 className='text-dark mt-5 '>FILTROS DE PESQUISA</h4>

      <form
        action='form-control-solid '
        className='mb-12'
        //  onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='row'>
          <Col className=' mt-4'>
            <label htmlFor='' className='form-label'>
              ID do Pedido:
            </label>
            <AsyncSelectPrevenda
              onKeyDown={(e) => {
                setSelectPrevenda(null);
              }}
              handleOptions={(option) => setSelectPrevenda(option)}
              inputOption={selectPrevenda}
            />
          </Col>
        </div>

        <RowBootstrap className='mt-4'>
          <Col>
            <label htmlFor='' className='form-label'>
              Cliente:
            </label>
            <AsyncSelectCliente
              handleOptions={(option) => setIsSelectClient(option)}
              inputOption={isSelectClient}
              onKeyDown={(e) => {
                setIsSelectClient(null);
              }}
              isSelectDOM={false}
            />
          </Col>
        </RowBootstrap>

        <div className='row my-3'>
          <Col className=' mt-4'>
            <label className='form-label'>Sequência:</label>
            <input
              value={sequencia ? sequencia : ''}
              className='form-control'
              onChange={(e) => {
                setSequencia(Number(e.target.value));
              }}
              type='number'
              placeholder='Digite a Sequência'
            />
          </Col>
          <Col className=' mt-4'>
            <label htmlFor='' className='form-label'>
              Fluxo Logístico:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setSelectFluxo(null);
                }
              }}
              className='react-select-styled react-select-solid py-1'
              classNamePrefix='react-select'
              placeholder='Selecione o Fluxo Logístico'
              loadOptions={loadLogisticaFluxo}
              value={selectFluxo}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setSelectFluxo(selectedOption);
                } else {
                  setSelectFluxo(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
            {/* {formik.touched.idLogisticaFluxo && formik.errors.idLogisticaFluxo ? (
              <div className='invalid-feedback'>{formik.errors.idLogisticaFluxo}</div>
            ) : null} */}
          </Col>
        </div>

        <div className='row'>
          <Col className='mt-4'>
            <label htmlFor='' className='form-label'>
              Tipo de Entrega:
            </label>
            <AsyncSelect
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsTipoEntrega(null);
                }
              }}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid py-1'
              classNamePrefix='react-select'
              placeholder='Selecione o tipo de Entrega'
              loadOptions={loadTipoEntrega}
              value={isTipoEntrega}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setIsTipoEntrega(selectedOption);
                } else {
                  setIsTipoEntrega(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
          </Col>
          <Col className=' mt-4'>
            <label className='form-label'>Status:</label>
            <select
              className='form-select'
              onChange={(e) => {
                setStatus(e.target.value);
                // formDataState.setStatus ? (formDataState.setStatus(e.target.value)) : console.log(e.target.value)
              }}
              value={status ? status : ''}
            >
              <option value='' selected>
                {' '}
                TODOS{' '}
              </option>
              <option value='CANCELADO'> CANCELADO </option>
              <option value='ABERTO'> ABERTO </option>
              <option value='CONCLUIDO'> CONCLUÍDO </option>
            </select>
          </Col>
        </div>

        <RowBootstrap className='mt-4'>
          <Col className=''>
            <label className='form-label'>ID do G2:</label>
            <input
              value={isIdG2 ? isIdG2 : ''}
              className='form-control'
              onChange={(e) => {
                setIsIdG2(Number(e.target.value));
              }}
              type='number'
              placeholder='Digite o ID do G2'
            />
          </Col>
          <Col className='col-2'>
            <label htmlFor='' className='form-label'>
              Ambientado
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isAmbientado}
                onChange={() => setIsAmbientado((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isAmbientado === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          <Col className='col-2'>
            <label htmlFor='' className='form-label'>
              Integrado
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isExportacaoIntegracao}
                onChange={() => setIsExportacaoIntegracao((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isExportacaoIntegracao === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
          <Col className='col-2'>
            <label htmlFor='' className='form-label'>
              Pendência
            </label>
            <div className='form-check form-switch mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault2'
                checked={isPendencia}
                onChange={() => setIsPendencia((prev) => !prev)}
              />
              <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                <b>{isPendencia === true ? 'Sim' : 'Não'}</b>
              </label>
            </div>
          </Col>
        </RowBootstrap>

        <div className='col-12 my-8' style={{display: 'flex', justifyContent: 'end'}}>
          <div
            className='col-sm-4 mx-5'
            style={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10'}}
          >
            <button
              type='button'
              style={{width: '40%'}}
              onClick={() => clearFiltros()}
              className='btn btn-danger form-control'
            >
              Limpar
            </button>
            <button
              onClick={(event) => {
                handleSearch(event);
              }}
              type='submit'
              style={{width: '40%', marginLeft: '10px'}}
              className='btn btn-primary d-flex align-items-center justify-content-center'
            >
              {isLoading ? (
                <Spinner animation='border' size='sm' />
              ) : (
                <>
                  <i className='bi bi-search mx-0'></i>
                  Pesquisar
                </>
              )}
            </button>
          </div>
        </div>
      </form>
      {isLoading ? (
        <Skeleton height={30} count={15} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ILogisticaSeparacao>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ILogisticaSeparacao>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      )}

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFSeparacao(isIdSeparacao, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer2}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPrevenda(isIdPedido, setIsLoading, 'padrão')}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer2(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default LogisticaSeparacaoListagemPage;
