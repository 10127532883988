import { Column, ColumnInstance, Row, useTable } from 'react-table';
import { DescriptionComponent, IEndereco } from '../../../../shared';
import { useNavigate } from 'react-router-dom';
import { CustomRow } from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import { useEffect, useMemo, useState } from 'react';
import {
  getEndereco
} from '../../listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const EnderecoListagemPage: React.FC = () => {

  const EnderecoColumns: Column<IEndereco>[] = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }) => <div className='text-end'>{value ? value : '...'}</div>,
    },
    {
      Header: 'rua',
      accessor: 'rua',
      Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
        Header: 'bairro',
        accessor: 'bairro',
        Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
      },
    {
      Header: 'cidade',
      accessor: 'cidade',
      Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'uf',
      accessor: 'uf',
      Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'numero',
      accessor: 'numero',
      Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({ row }) => (
        <div
          className='text-end'
          style={{ display: 'flex', gap: '1', alignItems: 'center', justifyContent: 'flex-start' }}
        >
            <button
                onClick={() => handleOnEdit(row.original.id!)}
                className='btn btn-success btn-sm bi bi-pencil'
                data-toggle='tooltip'
                data-placement='top'
                title='Editar'
            />
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const [isEnderecoData, setIsEnderecoData] = useState<IEndereco[]>(
    []
  );

  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useMemo(() => isEnderecoData, [isEnderecoData]);
  const columns = useMemo(() => EnderecoColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleOnEdit = (id: string | number) => {
    navigate(`/logistica-endereco/${id}`);
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const getEnderecoData = async (page = 0) => {
    setIsLoading(true);

    try {
      const response = await getEndereco(page);
      // console.log(response);

      const { content, totalPages } = response.data;

      setIsEnderecoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status.code;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });
      
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEnderecoData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description={'Listagem de Endereços'}
        buttonTitle='+ Cadastrar Endereço'
        redirectTo='/logistica-endereco'
        withLink
      />

      {isLoading ? (
        <Skeleton height={30} count={15} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IEndereco>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IEndereco>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EnderecoListagemPage;
