import {useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import {DateRangePicker} from 'rsuite';
import {addDays, endOfMonth, startOfMonth, subDays} from 'date-fns';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import {ColumnInstance, useTable, Row, Column} from 'react-table';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {
  AsyncSelectPrevenda,
  AsyncSelectProduct,
  buildQueryParams,
  DescriptionComponent,
  formatarData,
  formatDateByFilter,
  HttpClient,
  IComprador,
  IPendenciaCompraCrud,
  ISelectOption,
  LoadMore,
  SearchComponent,
  showAlert,
  useModal,
} from '../../../shared';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getPendenciaCompraCrud} from '../../listagem/listagem_requests/listagem_requests';
import PendenciaCompraModal from './components/pendencia-compra-modal';

const PendenciaCompraPage: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const http = new HttpClient();

  const pendenciaCompraForms: Column<IPendenciaCompraCrud>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value ? value : value}</div>,
    },
    {
      Header: 'Comprador',
      accessor: 'idComprador',
      Cell: ({value, row}) => (
        <div className='text-start'>
          {row.original.idComprador
            ? `${row.original.idComprador} - ${row.original.comprador}`
            : `${row.original.comprador}`}
        </div>
      ),
    },
    {
      Header: 'Id do Pedido',
      accessor: 'idPedido',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Quantidade de Pendência',
      accessor: 'quantidadePendencia',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'idProduto',
      Cell: ({row}) => (
        <div className='text-start' style={{width: '400px'}}>
          {row.original.idProduto
            ? `${row.original.idProduto} - ${row.original.produto}`
            : `${row.original.produto}`}
        </div>
      ),
    },
    {
      Header: 'Data de Pendência',
      accessor: 'dataPendencia',
      Cell: ({value, row}) => (
        <div className='text-start'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original.id!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const [isPendenciaModal, openPendenciaModal, closePendenciaModal] = useModal();
  const [isPendenciaCompraData, setIsPendenciaCompraData] = useState<IPendenciaCompraCrud[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isAsyncSelectPedido, setIsAsyncSelectPedido] = useState<ISelectOption | null>(null);
  const [isAsyncSelectProduto, setIsAsyncSelectProduto] = useState<ISelectOption | null>(null);
  const [isAsyncSelectComprador, setIsAsyncSelectComprador] = useState<ISelectOption | null>(null);
  const [isDataPendencia, setIsDataPendencia] = useState<DateRange | null>(null);
  const [isAtivo, setIsAtivo] = useState<string>('ATIVO');
  const [isIdPendenciaFilter, setIsIdPendenciaFilter] = useState<number | string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>();
  const [isIdPendencia, setIsIdPendencia] = useState<number | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const data = useMemo(() => isPendenciaCompraData, [isPendenciaCompraData]);
  const columns = useMemo(() => pendenciaCompraForms, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const handleRequest = () => {
    getPendenciasData();
  };

  const loadMore = () => {
    if (isHasMore) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    openPendenciaModal();

    setIsEdit(true);
    setIsIdPendencia(Number(id));
  };

  const handleOnAdd = () => {
    openPendenciaModal();

    setIsEdit(false);

    setIsIdPendencia(null);
  };

  const handleFilters = () => {
    setIsPage(0);
    setIsFiltered(true);
    getPendenciasDataFilter();
  };

  const clearFilters = () => {
    getPendenciasData();

    setIsFiltered(false);

    setIsAsyncSelectComprador(null);
    setIsAsyncSelectPedido(null);
    setIsAsyncSelectProduto(null);
    setIsDataPendencia(null);
    setIsIdPendenciaFilter('');
    setIsAtivo('ATIVO');

    setIsPendenciaCompraData([]);

    setIsPage(0);
  };

  const getPendenciasDataFilter = async () => {
    setIsLoadingSearch(true);
    setIsLoadingData(true);

    try {
      const params = {
        idComprador: isAsyncSelectComprador?.value,
        idPedido: isAsyncSelectPedido?.value,
        idProduto: isAsyncSelectProduto?.value,
        dataPendenciaInicio: isDataPendencia ? formatDateByFilter(isDataPendencia[0]) : null,
        dataPendenciaFim: isDataPendencia ? formatDateByFilter(isDataPendencia[1]) : null,
        status: isAtivo,
        id: isIdPendenciaFilter ?? null,
        page: isPage,
      };

      const finalUrl = `suprimento/pendencia-compra/listar${buildQueryParams(params)}`;

      const response = await http.request({
        method: 'GET',
        url: finalUrl,
      });

      const {content, totalPages} = response.data as any;

      setIsPendenciaCompraData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingData(false);
      setIsLoadingSearch(false);
    }
  };

  const getPendenciasData = async (page = 0) => {
    setIsLoadingData(true);
    try {
      const params = {
        page: isPage,
        status: 'ATIVO',
      };

      const finalUrl = `suprimento/pendencia-compra/listar${buildQueryParams(params)}`;

      const response = await http.request({
        method: 'GET',
        url: finalUrl,
      });

      const {content, totalPages} = response.data as any;
      setIsPendenciaCompraData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoadingData(false);
    } catch (errors: any) {
      const {data} = errors.response;

      showAlert('error', data);

      setIsLoadingData(false);
    }
  };

  const handleSelectAsyncPrevenda = (option: ISelectOption) => {
    setIsAsyncSelectPedido(option);
  };

  const handleSelectAsyncProduto = (option: ISelectOption) => {
    setIsAsyncSelectProduto(option);
  };

  const loadComprador = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/compradores/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      const options = content.map((item: IComprador) => ({
        value: item.id,
        label: item.nomeUsuario,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const handleDatePendencia = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setIsDataPendencia(null);
    } else {
      setIsDataPendencia([value[0], value[1]]);
    }
  };

  useEffect(() => {
    if (isFiltered) {
      getPendenciasDataFilter();
    }
    getPendenciasData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Pendência de Compra'
        buttonTitle='+ Nova Pendência de Compra'
        handleAction={() => {
          handleOnAdd();
        }}
        isButtonAction
      />

      <SearchComponent
        clearFilters={() => clearFilters()}
        handleFilters={() => handleFilters()}
        isLoadingSearch={isLoadingSearch}
      >
        <form className='form-control-solid row mb-5'>
          <RowBootstrap className='mt-4'>
            <Col md='12' className='col-12'>
              <label htmlFor='' className='form-label'>
                Pedido:
              </label>

              <AsyncSelectPrevenda
                handleOptions={(option) => handleSelectAsyncPrevenda(option!)}
                inputOption={isAsyncSelectPedido}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsAsyncSelectPedido(null);
                  }
                }}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col md='12' className='col-12'>
              <label htmlFor='' className='form-label'>
                Produto:
              </label>

              <AsyncSelectProduct
                handleOptions={(option) => handleSelectAsyncProduto(option!)}
                inputOption={isAsyncSelectProduto}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setIsAsyncSelectProduto(null);
                  }
                }}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col md='12' className='col-12'>
              <label htmlFor='' className='form-label'>
                Selecione o Comprador:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione o Comprador'
                loadOptions={loadComprador}
                value={isAsyncSelectComprador}
                onChange={(selectedOption: ISelectOption | null) => {
                  // Verifica se selectedOption não é null antes de definir o estado
                  if (selectedOption) {
                    setIsAsyncSelectComprador(selectedOption);
                  } else {
                    setIsAsyncSelectComprador(null); // Define como null caso a opção seja anulada
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='mt-4'>
            <Col md='4' className='col-12'>
              <label className='form-label'>Data de Pendência:</label>

              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDatePendencia}
                value={isDataPendencia}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='DATA EMISSAO'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </Col>
            <Col md='4' className='col-12'>
              <label className='form-label'>Status:</label>
              <select
                className={`form-control`}
                value={isAtivo ?? ''}
                onChange={(e) => setIsAtivo(e.target.value)}
              >
                <option value='ATIVO'>ATIVO</option>
                <option value='INATIVO'>INATIVO</option>
              </select>
            </Col>
            <Col md='4' className='col-12'>
              <label className='form-label'>Id da Pendência:</label>
              <input
                type='number'
                placeholder='Digite o Id da Pendência'
                className='form-control'
                value={isIdPendenciaFilter}
                onChange={(e) => {
                  const value = e.target.value;
                  setIsIdPendenciaFilter(value === '' ? '' : Number(value)); // Mantém string vazia se não houver valor
                }}
              />
            </Col>
          </RowBootstrap>
        </form>
      </SearchComponent>

      {isLoadingData ? (
        <Skeleton count={20} height={30} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IPendenciaCompraCrud>, index: number) => (
                  <th key={column.id} className={index === 0 ? 'text-end' : 'text-start'}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IPendenciaCompraCrud>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} showEditButton />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <LoadMore
            isHasMore={isHasMore}
            isLoadingSearch={isLoadingSearch}
            loadMore={() => loadMore()}
          />
        </div>
      )}

      <PendenciaCompraModal
        isOpen={isPendenciaModal}
        onHide={closePendenciaModal}
        isEdit={isEdit}
        idItem={isIdPendencia}
        handleRequest={handleRequest}
      />
    </>
  );
};

export default PendenciaCompraPage;
