import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Badge, Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { DateRangePicker } from 'rsuite';
import { ClienteOption } from '../../cadastros/proposta-pedido/types/interface';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { AsyncSelectConsultor, AsyncSelectUsuario, formatarData, smoothScrollToBottom } from '../../../shared';
import { string } from 'yup';
import addDays from 'date-fns/addDays';
import { endOfMonth, startOfMonth, subDays } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import { Logs } from './Interface';


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const LogListagem = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false)

    const [hasMore, setHasMore] = useState<boolean>(false)
    const [page, setPage] = useState(0)
    const [countSkeleton, setCountSkeleton] = useState(40)

    // const [selectedConsultor, setSelectedConsultor] = useState<ClienteOption | null>(null);




    const [selectedTipoEvento, setSelectedTipoEvento] = useState<ClienteOption | null>(null)
    const [selectedUsuario, setSelectedUsuario] = useState<ClienteOption|null>(null)
    const [selectedTipoEntidade, setSelectedTipoEntidade] = useState<ClienteOption | null>(null)
    const [selectedIdEntidade, setSelectedIdEntidade] = useState<number|null>(null)
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
    const [selectedDate, setSelectedDate] = useState<DateRange | null>([new Date(), new Date()]);

    const [logs, setLogs] = useState<Logs[]>([])


    
    // const handleOptionsAsyncConsultor = (selectedOption) => {
    //     if (selectedOption) {
    //       setSelectedConsultor(selectedOption);
    //     //   setIdConsultor(selectedOption.value);
    //     }
    //   };

    const handleOptionsAsyncUsuario = (selectedOption) => {
        if (selectedOption) {
            //   setSelectedConsultor(selectedOption);
            //   setIdConsultor(selectedOption.value);
            // setSelectedUsuario(selectedOption)
            console.log(selectedUsuario)
        }
    };

    const changeUsuario=(event)=>{
        const value = event.target.value
        console.log(value)
        setSelectedUsuario(value)
        console.log(selectedUsuario)
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é zero-indexado
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const fetchLogs = async () => {
        setLoading(true)
        setIsLoadingSearch(true)
        let dataInical = ''
        let dataFinal = ''
        if(selectedDate){
            dataInical = formatDate(selectedDate![0])
            dataFinal = formatDate(selectedDate![1])
        }// console.log(dataInical)
            // console.log((selectedDate![0]))
            
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logs/listar`,
                {
                  params: {
                    idLogEvento:selectedTipoEvento?selectedTipoEvento.value:null,
                    idUsuario:selectedUsuario? selectedUsuario.value:null,
                    dataInicial:selectedDate?dataInical:null,
                    dataFinal:selectedDate?dataFinal:null,
                    status:selectedStatus?selectedStatus:null,
                    idEntidade:selectedIdEntidade?selectedIdEntidade:null,
                    idLogTipoEntidade:selectedTipoEntidade?selectedTipoEntidade.value:null,
                  },
                }
                
              );

            console.log(response.data)
            if (response.status === 200) {
                if (page === 0) {
                    setLogs([]);
                }

                setLogs(response.data)


                //     setPrevendas((prev) =>
                //       page === 0 ? response.data.content : [...prev, ...response.data.content]
                //   )
            }
            if  (response.data.length===0) {
                //   setPrevendas([]);
                  setHasMore(false);
                  Swal.fire({
                    icon: 'info',
                      title: `Não existe registros de logs para essa pesquisa`,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                  }

            //   setHasMore(response.data.totalPages > page);

            //   if (response.data.last) {
            //     setHasMore(false);
            //   }
            // } else if (response.status === 204) {
            //   setPrevendas([]);
            //   setHasMore(false);
            //   Swal.fire({
            //     icon: 'info',
            //     //   title: `Não existe registros de ${tipo === 'propostas' ? 'proposta comercial' : 'pedido'
            //         // } para essa pesquisa`,
            //       timer: 3000,
            //       timerProgressBar: true,
            //     });
            //   }
        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setLoading(false);
            setIsLoadingSearch(false);

        }
    };

    const loadMore = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setPage((prevPage) => prevPage + 1);
            setCountSkeleton((count) => count + 40)
            smoothScrollToBottom()
        }, 1000);
    }
    useEffect(() => {
        fetchLogs()
        // if (page !== 0) {
        //   let dataInicial = '';
        //   let dataFinal = '';
        // if (valueDateRange && valueDateRange.length > 0) {
        //   dataInicial = convertToLocalTimeZone(valueDateRange[0]);
        //   dataFinal = convertToLocalTimeZone(valueDateRange[1]);
        // }
        //   setPrevendas(
        //     idPrevenda,
        //     idPrevendaG2,
        //     idCliente,
        //     statusOrcamento,
        //     nomeCliente,
        //     idConsultor,
        //     nomeConsultor,
        //     idOrcamentista,
        //     nomeOrcamentista,
        //     idParceiro,
        //     nomeParceiro,
        //     dataInicial,
        //     dataFinal,
        //     isPedido,
        //     page
        //   );

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        //     }
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        // setIsLoadingSearch(true);
        // setLoading(true)
        fetchLogs()
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000);

        // setTimeout(() => {
        // setIsLoadingSearch(false);
    }

    const loadOptions = async (inputValue: string, state: Function) => {
        if (inputValue.length >= 3) {
            try {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaClienteNome/${inputValue}`;

                const response = await axios.get(url);
                const data = await response.data;

                return ((data.map((item) => ({
                    value: item.idCliente,
                    label: item.idCliente + ' - ' + item.clienteFantasia,
                }
                )
                )))
            } catch (error) {
                console.error('Erro ao buscar opções:', error);
                return [];
            }
        };
    }

    const loadOptionsEvento = async (inputValue: string, state: Function) => {
            try {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logs/evento/options`;

                const response = await axios.get(url);
                const data = await response.data;
                console.log(data.id)

                return ((data.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                }
                )
                )))
            } catch (error) {
                console.error('Erro ao buscar opções:', error);
                return [];
        };
    }

    const loadOptionsTipoEntidade = async (inputValue: string, state: Function) => {
            try {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logs/tipoEntidade/options`;

                const response = await axios.get(url);
                const data = await response.data;
                console.log(data.id)

                return ((data.map((item) => ({
                    value: item.id,
                    label: item.descricao,
                }
                )
                )))
            } catch (error) {
                console.error('Erro ao buscar opções:', error);
                return [];
        };
    }
    const loadOptionsUsuario = async (inputValue: string, state: Function) => {
            try {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/optionsUsers/${inputValue}`;

                const response = await axios.get(url);
                const data = await response.data;
                console.log(data)

                return ((data.map((item) => ({
                    value: item.id,
                    label: item.id + ' - ' + item.nome,
                }
                )
                )))
            } catch (error) {
                console.error('Erro ao buscar opções:', error);
                return [];
        };
    }

    const handleDateRangeChange = (
        value: DateRange | null,
        event: React.SyntheticEvent<Element, Event>
    ) => {
        if (value === null) {
            setSelectedDate(null);
        } else {
            setSelectedDate([(value[0]), value[1]]);
        }
    };

    const clearFiltros = () => {
        setIsLoading(!isLoadingSearch);

        setTimeout(() => {
            console.log(selectedStatus)
            setIsLoading(false);
            setSelectedIdEntidade(0)
            setSelectedStatus(null)
            console.log(selectedStatus)
            setSelectedTipoEntidade(null)
            setSelectedTipoEvento(null)
            setSelectedDate(null)
            // setSelectedConsultor(null)
            setSelectedUsuario(null)
        }, 0);
    }

    const handleChangeEntida =(event)=>{
        setSelectedIdEntidade(event.target.value)
    }

    const handleChangeEvent =(event)=>{
        // console.log(typeof(event.target.value))
        console.log(event.target.value)
        if(event.target.value==='null'){
            setSelectedStatus(null)
        }
        else(
            setSelectedStatus(event.target.value)
        )
    }

    useEffect(() => {
        if (logs.length > 10) {
            setHasMore(true)
        }
    }, [logs])


    return (
        <div>

            <div
                className='mb-3 form-label-container'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Listagem de Logs
                </h1>
                {/* <button
                    //   onClick={onClickNovoCadastro}
                    className='btn btn-success btn-sm '
                    style={{ fontSize: '14px' }}
                >
                    + Novo Log
                </button> */}
            </div>
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div className='mt-10'>
                <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
                <form onSubmit={handleSearch} className='form-control-solid row gap-6 mb-5'>
                    <Row className='col-12 mb-4 row mt-8'>
                        <Col>
                            <label htmlFor="" className='form-label'>Tipo de Evento</label>
                            <AsyncSelect
                                value={selectedTipoEvento}
                                onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEvento(selectedOption);
                                }}
                                placeholder='Pesquise o Tipo de Evento'
                                loadOptions={loadOptionsEvento}

                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEvento(null);
                                        // setIdCliente('');
                                    }
                                }}
                            ></AsyncSelect>
                        </Col>
                        <Col>
                            <label htmlFor="" className='form-label'>Tipo de Entidade</label>
                            <AsyncSelect

                                loadOptions={loadOptionsTipoEntidade}
                                value={selectedTipoEntidade}
                                onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEntidade(selectedOption);

                                }}

                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEntidade(null);
                                        // setIdCliente('');
                                    }
                                }}
                                placeholder='Pesquise o Tipo de Entidade'
                            ></AsyncSelect>
                        </Col>
                        <Col>
                            <label htmlFor="" className='form-label'>Id da Entidade</label>
                            <input type="number" value={selectedIdEntidade?selectedIdEntidade:''} onChange={handleChangeEntida} className='form-control' placeholder='Pesquise o id da Entidade' />
                        </Col>
                    </Row>
                    <Row className='col-12 mb-4'>
                        <Col>
                            <label htmlFor="" className='form-label'>Usuario</label>
                            <AsyncSelect
                                loadOptions={loadOptionsUsuario}
                                value={selectedUsuario}
                                onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedUsuario(selectedOption);
                                
                                }}

                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedUsuario(null);
                                        // setIdCliente('');
                                    }
                                }}
                                placeholder='Pesquise o Usuario'
                                ></AsyncSelect>
                            {/* <input type="number" className='form form-control' value={selectedUsuario!} onChange={changeUsuario} /> */}
                        </Col>
                        
                        <Col>
                            <label htmlFor="" className='form-label'>Data</label>
                            <DateRangePicker
                                size='lg'
                                appearance='default'
                                onChange={handleDateRangeChange}
                                value={selectedDate}
                                format='dd-MM-yyyy'
                                ranges={[
                                    {
                                        label: 'Ontem',
                                        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                                    },
                                    {
                                        label: 'Hoje',
                                        value: [new Date(), new Date()],
                                    },
                                    {
                                        label: 'Último 7 dias',
                                        value: [subDays(new Date(), 6), new Date()],
                                    },
                                    {
                                        label: 'Este mês',
                                        value: [startOfMonth(new Date()), endOfMonth(new Date())],
                                    },
                                ]}
                                placeholder='DATA EMISSAO'
                                defaultValue={[new Date(), new Date()]}
                                className={`w-100`}
                                style={{
                                    backgroundColor: '#fff',
                                }}
                            //locale={ptBR}
                            />
                        </Col>
                        <Col>
                            <label htmlFor="" className='form-label'>Status</label>
                            <div className='col-sm-'>
                                <select
                                    onChange={handleChangeEvent}
                                    className='form-select'
                                    value={selectedStatus?selectedStatus:''}
                                    // onChange={(e) => selectedStatus(e.target.value)}
                                    // style={{ marginTop: '26px' }}
                                >
                                    <option value='null'>SELECIONE O STATUS</option>
                                    <option value='FALHA'>FAILED</option>
                                    <option value='SUCESSO'>SUCCESS</option>
                                    {/* <option value='0'>CANCELADO</option> */}
                                    {/* <option value='9'>SINCRONIZADO</option> */}
                                    {/* <option value='-1'>TODOS</option> */}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <div className='col-12 mt-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> :
                                    'Limpar'
                                }
                            </button>
                            <button
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                        {/* <span>Pesquisar</span>  */}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {loading ? (
                <Skeleton count={countSkeleton} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Evento</th>
                                    <th className='text-center'>Tipo Evento</th>
                                    <th className='text-center'>Data</th>
                                    <th className='text-center'>Usuario</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Entidade</th>
                                    <th className='text-center'>Id Entidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.map((log) => (
                                    <tr>
                                        <td className='text-center'>{log.evento}</td>
                                        <td className='text-center'>
                                            {log.logEvento}
                                        </td>
                                        <td className='text-center'>
                                            {formatarData(log.data)}
                                        </td>
                                        <td className='text-center'>
                                            {log.usuario}
                                        </td>
                                        <td className='text-center'>
                                            {/* <Badge bg='danger' style={{ color: 'white' }}> */}
                                                {log.status}
                                                {/* {log.vencimento} */}
                                            {/* </Badge> */}
                                        </td>

                                        <td className='text-center'>
                                            {log.tipoEntidade}
                                        </td>

                                        <td className='text-center'>
                                            {log.idEntidade}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )} */}
                    </div>
                </>
            )}
        </div>
    )
}
export default LogListagem