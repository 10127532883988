import React, {useEffect, useRef, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Modal, Button, Badge, DropdownButton, Dropdown} from 'react-bootstrap';
import {useAuth} from '../../../modules/auth';
import {
  clonarPrevenda,
  imprimirPrevenda,
  sincronizarPrevenda,
  buscaHistoricoCompras,
  visualizarPDFPrevenda,
  receberPagamento,
  visualizarPDFLightkorp,
  imprimirPDFLightkorp,
} from '../listagem_requests/listagem_requests';
import {DateRangePicker, Slider} from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import '../clientes/styles/tableHistorico.css';

//import useBuscaAlmoxarifadoEmpresa from '../../useUtils/useBuscaAlmoxarifadoEmpresa';

import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import {toZonedTime, formatInTimeZone} from 'date-fns-tz';
import {DateRange} from 'rsuite/esm/DateRangePicker';
import format from 'date-fns/format';
import AvatarConsultor from './components/AvatarConsultor';
import {ClienteInfo, Prevenda} from './Interface';
import {
  formatClone,
  formatStatus,
  formatStatusPagamento,
  formataCliente,
  formataCnpjCpf,
  formatarData,
  formatarHorario,
  formatarTotal,
} from './components/formsPedido';
import PDFPrevenda from './components/PdfPrevendaPedido';
import AsyncSelect from 'react-select/async';
import {ClienteOption} from '../../cadastros/proposta-pedido/types/interface';
import {
  AsyncSelectCliente,
  AsyncSelectConsultor,
  AsyncSelectEmpresa,
  ISelectOption,
  PDFView,
  useModal,
} from '../../../shared';
import {min, set} from 'date-fns';
import ModalGerarPedido from './components/ModalGerarPedido';
import {smoothScrollToBottom, smoothScrollToTop} from '../../../shared/core';
import Skeleton from 'react-loading-skeleton';
import ModalGerarPrazo from './components/ModalGerarPrazo';
import RangePriceBetween from '../../../shared/components/RangePriceBetween/RangePriceBetween';
import {is} from 'date-fns/locale';
import ModalSolicitarGarantia from './components/ModalViewGarantiaDevolucao';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface LocationState {
  id?: string; // ou number, dependendo do tipo do ID
}

export function ListagemPropostaPedido({tipo}) {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;
  const novoId = state?.id;
  const [contSkeleton, setcountSkeleton] = useState<number>(40);
  const [selectedId, setSelectedId] = useState({}); // Vetor para armazenar IDs dos itens selecionados.
  const [isGerarPedidoModal, openGerarPedidoModal, closeGerarPedidoModal] = useModal();
  const [isGerarPrazoModal, openGerarPrazoModal, closeGerarPrazoModal] = useModal();
  const [isSolicitarGarantiaModal, openSolicitarGarantiaModal, closeGerarGarantiaModal] =
    useModal();
  // const [modalShowHistoricoCompraCliente, setModalShowHistoricoCompraCliente] = useState(false);
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [isAmbientado, setIsAmbientado] = useState(false);
  const [isFrete, setIsFrete] = useState(false);
  const [isFluxoLogistica, setIsFluxoLogistica] = useState(false);

  const [prevendas, setPrevendas] = useState<Prevenda[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [idPrevenda, setIdPrevenda] = useState('');
  const [idPrevendaG2, setIdPrevendaG2] = useState('');
  const [idCliente, setIdCliente] = useState('');
  const [idEmpresa, setIdEmpresa] = useState('');
  const [idConsultor, setIdConsultor] = useState('');
  const [idOrcamentista, setIdOrcamentista] = useState('');
  const [idParceiro, setIdParceiro] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [nomeConsultor, setNomeConsultor] = useState('');
  const [nomeOrcamentista, setNomeOrcamentista] = useState('');
  const [nomeParceiro, setNomeParceiro] = useState('');
  const [statusOrcamento, setStatusOrcamento] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');
  const [isPedido, setIsPedido] = useState<boolean | undefined>();
  const [valueDateRange, setValueDateRange] = useState<DateRange | null>([new Date(), new Date()]);
  const {currentUser} = useAuth();

  const [historicoCompras, setHistoricoCompras] = useState([]);
  const [modalShowHistoricoCompraCliente, setModalShowHistoricoCompraCliente] = useState(false);
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [modalShowPDFViewer2, setModalShowPDFViewer2] = useState(false);
  const [tipoPDF, setTipoPDF] = useState('');

  const [clienteSelecionado, setClienteSelecionado] = useState<ClienteInfo | null>(null);
  //const {almoxarifadoUser} = useBuscaAlmoxarifadoEmpresa();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  function convertToLocalTimeZone(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd');
  }

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const tableEndRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const [selectedCliente, setSelectedCliente] = useState<ISelectOption | null>(null);
  const [selectedConsultor, setSelectedConsultor] = useState<ClienteOption | null>(null);
  const [selectedParceiro, setSelectedParceiro] = useState<ClienteOption | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null);
  const [selectedOrcamentista, setSelectedOrcamentista] = useState<ClienteOption | null>(null);
  const [isIdPrevenda, setIsIdPrevenda] = useState<number | string>();
  // function convertHorario(dateString: string) {
  //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  //   const date = new Date(dateString)
  //   const zonedDate = toZonedTime(date, timeZone)
  //   return format(zonedDate, timeZone , 'yyyy-MM-dd HH:mm:ss');
  // }

  const handleOpenSolicitarGarantiaModal = (idPrevenda: number | string) => {
    console.log('id prevenda', idPrevenda);

    openSolicitarGarantiaModal();
    setIsIdPrevenda(idPrevenda);
  };

  useEffect(() => {
    console.log('select client: ', selectedCliente);
  }, [selectedCliente]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setPrevendas([]);
    setPage(0);
    limpaInputIdPrevenda();
    handleDateRangeChange(
      [new Date(), new Date()],
      new Event('change') as unknown as React.SyntheticEvent
    );
  }, [tipo]);

  const isPedidoTipo = tipo === 'propostas' ? false : true;

  if (isPedidoTipo) {
    localStorage.setItem('tipo', isPedidoTipo.toString());
  } else {
    localStorage.setItem('tipo', isPedidoTipo.toString());
  }

  useEffect(() => {
    console.log(tipo);
  }, [tipo]);

  const handleDateRangeChange = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRange(null);
    } else {
      setValueDateRange([value[0], value[1]]);
    }
  };

  const loadOptionsClientes = async (inputValue: string) => {
    // if (typeof inputValue === "number") {
    // Lógica para número
    // console.log("É um número. Multiplicando por 2:", inputValue * 2);
    // } else if (typeof inputValue === "string") {
    // Lógica para string
    // console.log("É uma string. Convertendo para maiúsculas:", inputValue.toUpperCase());
    // }
    // const input= inputValue
    // let idValue=inputValue
    // if () {
    //   idValue = isNaN(String())
    //     ?  // Caso seja texto, mantêm como string.
    //     : String();
    //   }
    // console.log(idValue.length)
    // console.log(typeof(idValue))
    // console.log(idValue.length)
    if (inputValue.length >= 3) {
      try {
        let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaClienteNome/${inputValue}`;

        const response = await axios.get(url);
        const data = await response.data;

        return data.map((item) => ({
          value: item.idCliente,
          label: item.idCliente + ' - ' + item.clienteFantasia,
        }));
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
        return [];
      }
    }
  };

  const loadOptionsParceiro = async (inputValue: string) => {
    try {
      let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaNomeParceiroApi/${inputValue}`;

      const response = await axios.get(url);
      const data = await response.data;

      return data.map((item) => ({
        value: item.idParceiro,
        label: item.idParceiro + ' - ' + item.parceiro,
      }));
    } catch (error) {}
  };

  const handleOptionsAsyncConsultor = (selectedOption) => {
    if (selectedOption) {
      setSelectedConsultor(selectedOption);
      setIdConsultor(selectedOption.value);
    }
  };
  const handleOptionsAsyncOrcamentista = (selectedOption) => {
    if (selectedOption) {
      setSelectedOrcamentista(selectedOption);
      setIdOrcamentista(selectedOption.value);
    }
  };
  const fetchPrevendas = async (
    idPrevenda = '',
    idPrevendaG2 = '',
    idCliente = '',
    statusOrcamento = '',
    nomeCliente = '',
    idConsultor = '',
    nomeConsultor = '',
    idOrcamentista = '',
    nomeOrcamentista = '',
    idParceiro = '',
    nomeParceiro = '',
    dataInicial = '',
    dataFinal = '',
    isAmbientado = false,
    isFluxoLogistica = false,
    isFrete = false,
    isPedido = isPedidoTipo,
    totalFim = '',
    totalInicio = '',
    idEmpresa = '',
    page = 0
  ) => {
    setLoading(true);
    try {
      if (statusOrcamento === '-1') {
        setStatusOrcamento('');
      }

      localStorage.getItem('tipo') === 'true' ? setIsPedido(true) : setIsPedido(false);
      console.log(selectedEmpresa);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas`,
        {
          params: {
            idPrevenda,
            idCliente,
            statusOrcamento,
            nomeCliente,
            idConsultor,
            nomeConsultor,
            idOrcamentista,
            nomeOrcamentista,
            idParceiro,
            nomeParceiro,
            startDate: dataInicial,
            endDate: dataFinal,
            idPrevendaG2,
            isPedido,
            isambientado: isAmbientado ? isAmbientado : null,
            fluxoLogistico: isFluxoLogistica ? isFluxoLogistica : null,
            fretestatus: isFrete ? isFrete : null,
            totalFim: String(totalFim) === '0' ? null : totalFim,
            totalInicio:
              String(totalInicio) === '0' ? (String(totalFim) === '0' ? null : 0) : totalInicio,
            empresaId: selectedEmpresa?.value,
            page,
          },
        }
      );

      if (response.status === 200) {
        if (page === 0) {
          setPrevendas([]);
        }
        setPrevendas((prev) =>
          page === 0 ? response.data.content : [...prev, ...response.data.content]
        );

        setHasMore(response.data.totalPages > page);

        if (response.data.last) {
          setHasMore(false);
        }
      } else if (response.status === 204) {
        setPrevendas([]);
        setHasMore(false);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros de ${
            tipo === 'propostas' ? 'proposta comercial' : 'pedido'
          } para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setIdPrevenda('');
    setIdPrevendaG2('');
    setSelectedCliente(null);
    setIdCliente('');
    setSelectedConsultor(null);
    setSelectedParceiro(null);
    setSelectedOrcamentista(null);
    setIdOrcamentista('');
    setStatusOrcamento('');
    setSelectedEmpresa(null);
    setValueDateRange([new Date(), new Date()]);
    setMaxPrice('');
    setMinPrice('');
    setIsFluxoLogistica(false);
    setIsAmbientado(false);
    setIsFrete(false);
  };

  useEffect(() => {
    setHasMore(false);
    clearFilters();
  }, [tipo]);

  // useEffect(()=>{handleSearch},[])
  const handleSearch = (event) => {
    setPrevendas([]);

    event.preventDefault();
    let dataInicial = '';
    let dataFinal = '';
    if (valueDateRange && valueDateRange.length > 0) {
      dataInicial = convertToLocalTimeZone(valueDateRange[0]);
      dataFinal = convertToLocalTimeZone(valueDateRange[1]);
    }
    fetchPrevendas(
      idPrevenda,
      idPrevendaG2,
      idCliente,
      statusOrcamento,
      nomeCliente,
      idConsultor,
      nomeConsultor,
      idOrcamentista,
      nomeOrcamentista,
      idParceiro,
      nomeParceiro,
      dataInicial,
      dataFinal,
      isAmbientado,
      isFluxoLogistica,
      isFrete,
      isPedidoTipo,
      maxPrice,
      minPrice
    );
  };
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    setcountSkeleton((count) => count + 40);
    smoothScrollToBottom();
  };
  useEffect(() => {
    if (page !== 0) {
      let dataInicial = '';
      let dataFinal = '';
      // if (valueDateRange && valueDateRange.length > 0) {
      //   dataInicial = convertToLocalTimeZone(valueDateRange[0]);
      //   dataFinal = convertToLocalTimeZone(valueDateRange[1]);
      // }
      fetchPrevendas(
        idPrevenda,
        idPrevendaG2,
        idCliente,
        statusOrcamento,
        nomeCliente,
        idConsultor,
        nomeConsultor,
        idOrcamentista,
        nomeOrcamentista,
        idParceiro,
        nomeParceiro,
        dataInicial,
        dataFinal,
        isAmbientado,
        isFluxoLogistica,
        isFrete,
        isPedido,
        maxPrice,
        minPrice,
        idEmpresa,
        page
      );
    }
  }, [page]);

  useEffect(() => {
    if (shouldScroll && tableEndRef.current) {
      (tableEndRef.current as HTMLElement).scrollIntoView({behavior: 'smooth'});
      setShouldScroll(false);
    }
  }, [shouldScroll, prevendas]);

  const limpaInputIdPrevenda = () => {
    localStorage.setItem('idPrevenda', '');
    setIdPrevenda('');
  };

  const prevendaId = (prevenda) => {
    tipo === 'propostas'
      ? navigate(`/editar-proposta-comercial/${prevenda}`)
      : navigate(`/editar-pedidos/${prevenda}`);
    localStorage.setItem('idPrevenda', prevenda);
    setIdPrevenda(prevenda);
    setValueDateRange(null);
  };

  const handleGerarPedidoSwal = async (prevendaId) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, gerar pedido!',
      cancelButtonText: 'Não, cancelar!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${prevendaId}/gerar-pedido`
          );

          if (response.status === 200) {
            Swal.fire({
              title: 'Pedido Gerado!',
              text: 'Seu pedido foi gerado com sucesso!',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            }).then(() => {
              navigate('/listagem-pedidos');
            });
          }
        } catch (error: any) {
          Swal.fire({
            title: 'Erro!',
            text: error.response.data[0].mensagem,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  const executarProcedure = async (prevenda) => {
    try {
      await axios.post(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/produtos-prazos/excutar-procedure?idPrevenda=${
          prevenda!['id']
        }`
      );
      handleOpenGerarPrazoModal(prevenda);
    } catch {}
  };

  const handleGerarPrazo = async (prevenda) => {
    console.log(prevenda.isPedido);
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, gerar prazo!',
      cancelButtonText: 'Não, cancelar!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        executarProcedure(prevenda);
        fetchPrevendas();
        // try {
        //     Swal.fire({
        //       title: 'Pedido Gerado!',
        //       text: 'Seu pedido foi gerado com sucesso!',
        //       icon: 'success',
        //       timer: 2000,
        //       timerProgressBar: true,
        //       showConfirmButton: false,
        //     }).then(() => {
        //       navigate('/listagem-pedidos');
        //     });
        //   // }
        // } catch (error: any) {
        //   Swal.fire({
        //     title: 'Erro!',
        //     text: error.response.data[0].mensagem,
        //     icon: 'error',
        //     confirmButtonText: 'OK',
        //   });
        // }
      }
    });
  };

  const handleVisualizarPrazo = async (prevenda) => {
    console.log(prevenda.isPedido);
    handleOpenGerarPrazoModal(prevenda);
  };

  const handleReordenarProposta = async (prevendaId) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Essa ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Reordenar!',
      cancelButtonText: 'Não, cancelar!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/${prevendaId}/reordenar-proposta`
          );

          if (response.status === 200) {
            Swal.fire({
              title: 'Proposta Reordenada',
              text: 'Proposta Reordenada com sucesso!',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error: any) {
          Swal.fire({
            title: 'Erro!',
            text: 'Houve um problema com sua solicitação',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  const handleClickCompras = async (cliente: ClienteInfo) => {
    setClienteSelecionado(cliente);

    try {
      setLoading(true);
      const codigosEmpresas = currentUser?.empresasVinculadas?.map((empresa) => empresa.codigo);
      const codigosEmpresasString = codigosEmpresas?.join(',');

      const response = await buscaHistoricoCompras(cliente.codigo, codigosEmpresasString || '');

      if (response.status === 200) {
        setLoading(false);
        setHistoricoCompras(response.data);
        setModalShowHistoricoCompraCliente(true);
      } else {
        setLoading(false);
        console.error('Erro ao buscar histórico de compras');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchData = (savedId: string | null) => {
    setShouldScroll(false);
    setLoading(true);
    // let idConsultorCarregado = idConsultor;

    if (
      currentUser?.funcionariosUsuarios &&
      currentUser.funcionariosUsuarios.length > 0 &&
      !savedId
    ) {
      const idFuncionario = currentUser.funcionariosUsuarios[0].idFuncionario.toString();
      // setIdConsultor(idFuncionario);
      // idConsultorCarregado = idFuncionario;
    }
    let dataInicial = '';
    let dataFinal = '';

    if (!savedId) {
      if (valueDateRange && valueDateRange.length > 0) {
        dataInicial = convertToLocalTimeZone(valueDateRange[0]);
        dataFinal = convertToLocalTimeZone(valueDateRange[1]);
      }
    }

    fetchPrevendas(
      savedId || '',
      '',
      '',
      '',
      '',
      // idConsultorCarregado ||
      '',
      '',
      '',
      '',
      '',
      '',
      dataInicial,
      dataFinal
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    const savedId = localStorage.getItem('idPrevenda');
    if (savedId) {
      setIdPrevenda(savedId);
      setValueDateRange(null);
    }
    fetchData(savedId);
  }, [currentUser, tipo]); // double recarregar

  useEffect(() => {
    localStorage.setItem('pdfUrl', pdfUrl!);
  }, [pdfUrl]);

  useEffect(() => {
    //erro aqui tbm
    return () => {
      localStorage.removeItem('idPrevenda');
    };
  }, [location]);

  const renderizarHistoricoCompras = (historicoCompras) => {
    return (
      <div className='text-center table-container table-responsive'>
        <table className=' table-hover tablehist table-rounded table-row-bordered'>
          <thead className='thead-dark'>
            <tr className='fw-bold fs-6 text-gray-800'>
              <th className='text-center' style={{width: '5%'}}>
                Id. Prevenda
              </th>
              <th className='text-center' style={{width: '10%'}}>
                Consultor
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Id. Produto
              </th>
              <th className='text-center' style={{width: '40%'}}>
                Produto
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Quant.
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Preço Venda
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Tipo Produto
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Prod. Filho
              </th>
              <th className='text-center' style={{width: '5%'}}>
                Grupo
              </th>
              <th className='text-center' style={{width: '5%', marginRight: '10px'}}>
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {historicoCompras.map((item, index) => (
              <tr key={index}>
                <td className='text-center'>{item.idPrevenda}</td>
                <td className='text-center' style={{width: '30px', height: '30px'}}>
                  {item.idConsultor} - {item.consultor}
                </td>
                <td className='text-center'>{item.idProduto}</td>
                <td className='text-center' style={{width: '50px', height: '50px'}}>
                  {item.produto}
                </td>
                <td className='text-center'>{item.produtoQuantidade}</td>
                <td className='text-center'>{`R$${parseFloat(item.precoVenda).toLocaleString(
                  'pt-BR',
                  {minimumFractionDigits: 2, maximumFractionDigits: 2}
                )}`}</td>
                <td className='text-center'>{item.tipoProduto}</td>
                <td className='text-center'>{item.produtoFilho}</td>
                <td className='text-center'>{item.grupo}</td>
                <td className='text-center'>
                  {format(new Date(item.dataFinalizacaoPrevenda), 'dd/MM/yyyy')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const widthInput = document.querySelector('#idG2');

  const [isNotAllowedActions, setIsNotAllowedActions] = useState<boolean>(false);

  const handleOpenAnexarPdfModal = (prevenda) => {
    openGerarPedidoModal();
    setSelectedId(prevenda);
  };

  const handleOpenGerarPrazoModal = (prevenda) => {
    // openGerarPrazoModal();
    setSelectedId(prevenda);
    openGerarPrazoModal();
  };

  useEffect(() => {}, []);

  const handleSelectClient = (selectOption: ISelectOption) => {
    setSelectedCliente(selectOption);
    setIdCliente(selectOption.value.toString());
  };

  const handleSelectEmpresa = (selectOption: ISelectOption) => {
    setSelectedEmpresa(selectOption);
    setIdEmpresa(selectOption.value.toString());
  };

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de {tipo === 'propostas' ? 'Propostas Comerciais' : 'Pedidos'}
        </h1>
        {tipo === 'propostas' && (
          <Link
            to={tipo === 'propostas' ? '/propostas-comerciais' : '/pedidos'}
            className='btn btn-success btn-sm '
            style={{fontSize: '14px'}}
          >
            + {tipo === 'propostas' ? 'Nova Proposta Comercial' : 'Novo Pedido'}
          </Link>
        )}
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA </h4>
        <form onSubmit={handleSearch} className='gap-6'>
          <div className='row mt-8'>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                ID {tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'}
              </label>
              <div className='input-group mb-3'>
                <input
                  type='text'
                  className='form-control '
                  value={idPrevenda}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setIdPrevenda(value);
                  }}
                />
                <div className='input-group-append'>
                  <span
                    className='input-group-text'
                    style={{
                      borderRadius: '0 4px 4px 0',
                      color: '#888989',
                      cursor: 'pointer',
                    }}
                    onClick={limpaInputIdPrevenda}
                  >
                    X
                  </span>
                </div>
              </div>
            </div>
            <div className='col-sm-3 '>
              <label htmlFor='' className='form-label'>
                ID G2
              </label>
              <input
                id='idG2'
                type='text'
                className='form-control '
                value={idPrevendaG2}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdPrevendaG2(value);
                }}
              />
            </div>
            <div className=' col-sm-3'>
              <label htmlFor='' className='form-label'>
                Cliente
              </label>
              <AsyncSelectCliente
                handleOptions={(option) => handleSelectClient(option!)}
                inputOption={selectedCliente}
                isSelectDOM={false}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedCliente(null);
                    setIdCliente('');
                  }
                }}
              />
              {/* <input
                type='text'
                className='form-control  '
                value={idCliente}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdCliente(value);
                }}
              /> */}
            </div>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Consultor
              </label>
              <AsyncSelectConsultor
                handleOptions={(optionSelected) => handleOptionsAsyncConsultor(optionSelected)}
                inputOption={selectedConsultor}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedConsultor(null);
                    setIdConsultor('');
                  }
                }}
              />
              {/* <input
                type='text'
                className='form-control '
                value={idConsultor}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdConsultor(value);
                }}
              /> */}
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Profissional
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({...provided, zIndex: 9999}),
                  menuPortal: (provided) => ({...provided, zIndex: 9999}),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Pesquise o profissional'
                loadOptions={loadOptionsParceiro}
                value={selectedParceiro}
                onChange={(selectedOption: ClienteOption | null) => {
                  setSelectedParceiro(selectedOption);
                  setIdParceiro(selectedOption ? selectedOption.value.toString() : '');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedParceiro(null);
                    setIdParceiro('');
                  }
                }}
              />
              {/* <input
                type='text'
                className='form-control '
                value={idParceiro}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdParceiro(value);
                }}
              /> */}
            </div>

            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Orçamentista
              </label>
              <AsyncSelectConsultor
                handleOptions={(optionSelected) => handleOptionsAsyncOrcamentista(optionSelected)}
                inputOption={selectedOrcamentista}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setSelectedOrcamentista(null);
                    setIdOrcamentista('');
                  }
                }}
              />
              {/* <input
                type='text'
                className='form-control '
                value={idOrcamentista}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdOrcamentista(value);
                }}
              /> */}
            </div>

            {/* <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Nome Cliente
              </label>
              <input
                type='text'
                className='form-control'
                value={nomeCliente}
                onChange={(e) => {
                  setNomeCliente(e.target.value);
                }}
              />
            </div>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Nome Consultor
              </label>
              <input
                type='text'
                className='form-control '
                value={nomeConsultor}
                onChange={(e) => {
                  setNomeConsultor(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-3'>
              <label htmlFor='' className='form-label'>
                Nome Parceiro
              </label>
              <input
                type='text'
                className='form-control '
                value={nomeParceiro}
                onChange={(e) => {
                  setNomeParceiro(e.target.value);
                }}
              />
            </div>

            <div className='col-sm-3'>
              <label htmlFor='' className='form-label '>
                Nome Orçamentista
              </label>
              <input
                type='text'
                className='form-control '
                value={nomeOrcamentista}
                onChange={(e) => {
                  setNomeOrcamentista(e.target.value);
                }}
              />
            </div> */}

            <div className='col-sm-3' style={{marginTop: '26px'}}>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChange}
                value={valueDateRange}
                format='dd-MM-yyyy'
                ranges={[
                  {
                    label: 'Ontem',
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: 'Hoje',
                    value: [new Date(), new Date()],
                  },
                  {
                    label: 'Último 7 dias',
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: 'Este mês',
                    value: [startOfMonth(new Date()), endOfMonth(new Date())],
                  },
                ]}
                placeholder='DATA EMISSAO'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  backgroundColor: '#fff',
                }}
                //locale={ptBR}
              />
            </div>

            <div className='col-sm-3'>
              <select
                className='form-select'
                value={statusOrcamento}
                onChange={(e) => setStatusOrcamento(e.target.value)}
                style={{marginTop: '26px'}}
              >
                <option value=''>SELECIONE O STATUS</option>
                <option value='1'>EM ABERTO</option>
                <option value='2'>NEGOCIACAO ENCERRADA</option>
                <option value='0'>CANCELADO</option>
                <option value='9'>SINCRONIZADO</option>
                <option value='-1'>TODOS</option>
              </select>
            </div>
          </div>

          <div className='row mt-8'>
            <div className='col-sm-6 py-1'>
              <label htmlFor='' className='form-label mb-2'>
                Empresa
              </label>
              <AsyncSelectEmpresa
                handleOptions={(option) => {
                  setSelectedEmpresa(option);
                  console.log(option);
                }}
                inputOption={selectedEmpresa}
                onKeyDown={() => {
                  setSelectedEmpresa(null);
                }}
              />
            </div>

            <div className='col-sm-3 mt-5 mt-md-0 mt-lg-0 col-md-6 col-lg-3'>
              <div className='d-flex flex-column '>
                <div className='row w-100'>
                  <label htmlFor='' className='form-label'>
                    Valor do Pedido
                  </label>
                </div>
                <RangePriceBetween
                  setMaxPrice={setMaxPrice}
                  setMinPrice={setMinPrice}
                  maxPrice={maxPrice}
                  minPrice={minPrice}
                />
              </div>
            </div>

            <div className='col-md-6 col-lg-3 d-flex gap-20'>
              <div className='col-1 col-md-1 mt-5'>
                <label htmlFor='' className='form-label'>
                  Ambientado
                </label>
                <div className='form-check form-switch mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault1'
                    checked={isAmbientado}
                    onChange={() => {
                      setIsAmbientado((prev) => !prev);
                      console.log(isAmbientado);
                    }}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault1'>
                    <b>{isAmbientado === true ? 'Sim' : 'Não'}</b>
                    {/* <b>Sim</b> */}
                  </label>
                </div>
              </div>
              <div className='col-1 col-md-1 mt-5'>
                <label htmlFor='' className='form-label'>
                  Frete
                </label>
                <div className='form-check form-switch mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault2'
                    checked={isFrete}
                    onChange={() => setIsFrete((prev) => !prev)}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault2'>
                    <b>{isFrete === true ? 'Sim' : 'Não'}</b>
                  </label>
                </div>
              </div>
              <div className='col-1 mt-5'>
                <label htmlFor='' className='form-label text-nowrap'>
                  Fluxo Logistico
                </label>
                <div className='form-check form-switch mt-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault3'
                    checked={isFluxoLogistica}
                    onChange={() => setIsFluxoLogistica((prev) => !prev)}
                  />
                  <label className='form-check-label ms-2' htmlFor='flexSwitchCheckDefault3'>
                    <b>{isFluxoLogistica === true ? 'Sim' : 'Não'}</b>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-8 mb-6'>
            <div className='col-sm-2'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        {loading ? (
          <Skeleton count={contSkeleton} height={25} />
        ) : (
          <>
            <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
              <thead className='thead-dark'>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th className='text-center'>Id</th>
                  <th className='text-center'>Empresa/G2</th>
                  <th className='text-center'>Data Emissão</th>
                  <th className='text-center'>Cliente</th>
                  <th className='text-center'>Total Geral</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Consultor</th>
                  <th className='text-center'>Impressão</th>
                  <th className='text-center'>Clonar</th>
                  <th className='text-center'>Ações</th>
                  <th className='text-center'>Sincronizar</th>
                </tr>
              </thead>
              <tbody>
                {prevendas.map((prevenda) => (
                  <tr key={prevenda.id}>
                    <td className='text-center'>{prevenda.id}</td>
                    <td className='text-center'>
                      {prevenda.empresa}
                      <br></br>
                      <Badge bg='info' style={{color: 'white'}}>
                        {prevenda.idPrevendag2}
                      </Badge>
                    </td>
                    <td className='text-center'>
                      <div>{formatarData(prevenda.dataemissao)}</div>
                      <div>{formatarHorario(prevenda.dataemissao)}</div>
                      {prevenda.isAmbientado && (
                        <div className='mt-2'>
                          <Badge style={{color: 'white'}} bg='info'>
                            AMBIENTADO
                          </Badge>
                        </div>
                      )}
                      {prevenda.fretestatus !== 0 && (
                        <div className='mt-2'>
                          <Badge style={{color: 'white'}} bg='warning'>
                            FRETE
                          </Badge>
                        </div>
                      )}
                    </td>
                    <td className='text-center'>
                      <div
                        onClick={() => handleClickCompras(prevenda.clienteInfo)}
                        style={{cursor: 'pointer'}}
                      >
                        {prevenda.clienteInfo.fantasia || ''}
                      </div>
                      <div>{formataCliente(prevenda.clienteInfo.status) || ''}</div>
                      <div className='mt-2'>
                        {formataCnpjCpf(prevenda.clienteInfo.cpf, prevenda.clienteInfo.cnpj)}
                      </div>
                    </td>
                    <td className='text-center'>
                      {formatarTotal(prevenda.total)}
                      {
                        <div className='mt-2'>
                          <Badge style={{color: 'white'}}>Mg: {prevenda.margemProposta}%</Badge>
                        </div>
                      }
                    </td>
                    <td className='text-center'>
                      <div>{formatStatus(prevenda.status) || 'N/A'}</div>
                      <div style={{marginTop: '5px'}}>
                        {formatClone(prevenda.versao, prevenda.idPrevendaOriginal)}
                      </div>
                      <div style={{marginTop: '5px'}}>
                        {formatStatusPagamento(prevenda.statusPagamento)}
                        {prevenda.kanban && prevenda.kanban.length > 0 && (
                          <div style={{marginTop: '5px'}}>
                            {prevenda.kanban.map((kanbanItem, index) => (
                              <Badge
                                key={index}
                                style={{
                                  backgroundColor: '#e0f7e8',
                                  color: '#fff',
                                  textTransform: 'uppercase',
                                  marginRight: '5px',
                                  marginBottom: '5px',
                                }}
                              >
                                Kanban: {kanbanItem.kanban || 'N/A'}
                              </Badge>
                            ))}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className='text-center'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <AvatarConsultor
                          idFuncionario={prevenda.consultorInfo.idFuncionario}
                          funcionario={prevenda.consultorInfo.funcionario}
                        />
                      </div>
                    </td>
                    <td className='text-center'>
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-sm btn-primary dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          <i className='bi bi-file-earmark-arrow-down'>PDF</i>
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() =>
                                visualizarPDFPrevenda(
                                  setModalShowPDFViewer,
                                  prevenda.id,
                                  setLoading,
                                  setPdfUrl,
                                  setIdPrevenda,
                                  setTipoPDF,
                                  'padrao'
                                )
                              }
                            >
                              PADRÃO
                            </button>
                          </li>

                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() =>
                                visualizarPDFPrevenda(
                                  setModalShowPDFViewer,
                                  prevenda.id,
                                  setLoading,
                                  setPdfUrl,
                                  setIdPrevenda,
                                  setTipoPDF,
                                  'revenda'
                                )
                              }
                            >
                              REVENDA
                            </button>
                          </li>

                          <li>
                            <hr className='dropdown-divider' />
                          </li>

                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() =>
                                visualizarPDFLightkorp(
                                  setModalShowPDFViewer2,
                                  prevenda.id,
                                  setLoading,
                                  setPdfUrl,
                                  setIdPrevenda
                                )
                              }
                            >
                              LIGHTKORP
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td className='text-center'>
                      {/* {prevenda.versao !== null ? (
                        // || tipo === 'pedidos' // Desabilita a clonagem de pedidos
                        <Link
                          to={`/clonarPrevenda/${prevenda.id}`}
                          style={{ display: 'none' }}
                          className='btn btn-info btn-sm'
                        >
                          Clonar
                        </Link>
                      ) : ( */}
                      <div className='dropdown position-static'>
                        <button
                          className='btn btn-info btn-sm dropdown-toggle'
                          data-bs-toggle='dropdown'
                        >
                          Clonar
                        </button>
                        <ul
                          className='dropdown-menu'
                          style={{
                            position: 'absolute',
                            zIndex: 1050,
                            top: '100%',
                            left: '0',
                          }}
                        >
                          <li>
                            <button
                              style={{fontWeight: 600, color: '#5014d0'}}
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    `Você tem certeza que deseja clonar  ${
                                      tipo === 'propostas' ? ' a proposta comercial' : 'o pedido'
                                    } com id: ` +
                                    prevenda.id +
                                    '?',
                                  icon: 'info',
                                  showCancelButton: true,
                                  confirmButtonText: 'Sim',
                                  cancelButtonText: 'Não',
                                  reverseButtons: true,
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    clonarPrevenda(prevenda.id)
                                      .then((response) => {
                                        if (response.status === 200) {
                                          Swal.fire({
                                            icon: 'success',
                                            title: 'Prevenda clonada com sucesso!',
                                            confirmButtonText: 'Ok',
                                            reverseButtons: true,
                                          });
                                          fetchPrevendas(); // Recarrega a lista de prevendas após a clonagem
                                        } else {
                                          Swal.fire({
                                            icon: 'error',
                                            title: 'Erro ao clonar prevenda',
                                            text: `Ocorreu um erro ao tentar clonar ${
                                              tipo === 'propostas'
                                                ? ' a proposta comercial'
                                                : 'o pedido'
                                            }. Por favor, tente novamente mais tarde.`,
                                            confirmButtonText: 'Ok',
                                          });
                                        }
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Erro ao clonar prevenda',
                                          text: `Ocorreu um erro ao tentar clonar ${
                                            tipo === 'propostas'
                                              ? ' a proposta comercial'
                                              : 'o pedido'
                                          }. Por favor, tente novamente mais tarde.`,
                                          confirmButtonText: 'Ok',
                                        });
                                      });
                                  }
                                });
                              }}
                            >
                              CÓPIA SIMPLES
                            </button>
                          </li>

                          <li>
                            <button disabled className='dropdown-item' type='button'>
                              SIMILAR A
                            </button>
                          </li>
                          <li>
                            <button disabled className='dropdown-item' type='button'>
                              SIMILAR B
                            </button>
                          </li>
                          <li>
                            <button disabled className='dropdown-item' type='button'>
                              SIMILAR C
                            </button>
                          </li>
                        </ul>
                      </div>

                      {/* )} */}
                    </td>
                    <td className='text-center'>
                      {prevenda.status === 9 ||
                      prevenda.status === 2 ||
                      prevenda.status === 3 ||
                      prevenda.status === 4 ||
                      tipo === 'pedidos' ||
                      prevenda.prazoGerado ? (
                        <DropdownButton className='position-static' title={<i className='bi bi-search'></i>} size='sm'>
                          {/* Visualizar == fixo sempre */}
                          <Dropdown.Item
                            as={Link}
                            onClick={() => prevendaId(prevenda.id)}
                            to={
                              tipo === 'propostas'
                                ? `/editar-proposta-comercial/${prevenda.id}`
                                : `/editar-pedidos/${prevenda.id}`
                            }
                          >
                            <i className='bi bi-search'></i> Visualizar
                          </Dropdown.Item>

                          {/* Prazo acordado == somente se for pedido */}
                          {tipo === 'pedidos' && prevenda.status !== 0 ? (
                            <>
                              <Dropdown.Divider style={{color: 'black'}} />

                              <Dropdown.Item
                                as={'button'}
                                onClick={() =>
                                  prevenda.prazoGerado
                                    ? handleVisualizarPrazo(prevenda)
                                    : handleGerarPrazo(prevenda)
                                }
                              >
                                <i className='bi bi-calendar'></i>{' '}
                                {prevenda.prazoGerado ? 'Ver Prazo' : 'Gerar Prazo'}
                              </Dropdown.Item>

                              <Dropdown.Divider style={{color: 'black'}} />

                              {prevenda.prazoGerado && (
                                <Dropdown.Item
                                  as={'button'}
                                  onClick={() => {
                                    handleOpenSolicitarGarantiaModal(prevenda.id);
                                  }}
                                >
                                  <i className='bi bi-shield-check'></i> Solicitar
                                  Garantia/Devolução
                                </Dropdown.Item>
                              )}
                            </>
                          ) : (
                            ''
                          )}

                          {currentUser?.isFinanceiro && prevenda.status !== 0 ? (
                            <>
                              <Dropdown.Divider style={{color: 'black'}} />
                              <Dropdown.Item
                                style={{
                                  color: '#28a745',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  Swal.fire({
                                    title: `Você tem certeza que deseja receber o pagamento ${
                                      tipo === 'propostas'
                                        ? ' desta proposta comercial'
                                        : 'deste pedido'
                                    } com id: ${prevenda.id}?`,
                                    icon: 'info',
                                    showCancelButton: true,
                                    confirmButtonText: 'Sim',
                                    cancelButtonText: 'Não',
                                    reverseButtons: true,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      // Exibe o Swal de carregamento
                                      Swal.fire({
                                        title: 'Processando pagamento...',
                                        text: 'Aguarde enquanto o pagamento está sendo recebido.',
                                        allowOutsideClick: false,
                                        didOpen: () => {
                                          Swal.showLoading();
                                        },
                                      });

                                      receberPagamento(prevenda.id)
                                        .then((response) => {
                                          // Fecha o Swal de carregamento
                                          Swal.close();

                                          if (response.status === 200) {
                                            Swal.fire({
                                              icon: 'success',
                                              title: 'Pagamento recebido com sucesso!',
                                              confirmButtonText: 'Ok',
                                              reverseButtons: true,
                                            });
                                            fetchPrevendas(); // Recarrega a lista de prevendas após recebimento do pagamento
                                          } else {
                                            Swal.fire({
                                              icon: 'error',
                                              title: 'Erro ao receber pagamento',
                                              text: `Ocorreu um erro ao tentar receber o pagamento deste pedido. Por favor, tente novamente mais tarde.`,
                                              confirmButtonText: 'Ok',
                                            });
                                          }
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                          // Fecha o Swal de carregamento
                                          Swal.close();
                                          Swal.fire({
                                            icon: 'error',
                                            title: 'Erro ao receber pagamento',
                                            text: `Ocorreu um erro ao tentar receber o pagamento deste pedido. Por favor, tente novamente mais tarde.`,
                                            confirmButtonText: 'Ok',
                                          });
                                        });
                                    }
                                  });
                                }}
                              >
                                <i className='bi bi-wallet2' style={{color: '#28a745'}}></i> Receber
                                Pagamento
                              </Dropdown.Item>
                            </>
                          ) : (
                            ''
                          )}
                        </DropdownButton>
                      ) : prevenda.status !== 0 &&
                        prevenda.status !== 2 &&
                        prevenda.status !== 3 &&
                        prevenda.status !== 4 ? (
                        <div className='dropdown position-static'>
                          <button
                            className='btn btn-success btn-sm dropdown-toggle'
                            data-bs-toggle='dropdown'
                          >
                            <i className=''>Opções</i>
                          </button>
                          <ul
                            className='dropdown-menu'
                            style={{
                              position: 'absolute',
                              zIndex: 1050,
                              top: '100%',
                              left: '0',
                            }}
                          >
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => prevendaId(prevenda.id)}
                              >
                                EDITAR
                              </button>
                            </li>

                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => handleGerarPedidoSwal(prevenda.id)}
                              >
                                GERAR PEDIDO
                              </button>
                            </li>

                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => {
                                  handleOpenAnexarPdfModal(prevenda);
                                }}
                              >
                                ANEXAR PDF
                              </button>
                            </li>
                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            {/* <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={()=>{handleGerarPrazo(prevenda)}}
                              >
                                GERAR PRAZOS
                              </button>
                            </li> */}
                            <li>
                              <hr className='dropdown-divider' />
                            </li>

                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => handleReordenarProposta(prevenda.id)}
                              >
                                REORDENAR PROPOSTA (BUG)
                              </button>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </td>
                    <td className='text-center'>
                      {prevenda.status !== 4 ||
                      prevenda.clienteInfo.status !== 9 ||
                      (prevenda.clienteInfo.cpf === null && prevenda.clienteInfo.cnpj === null) ||
                      tipo !== 'pedidos' ? (
                        <button
                          className='btn btn-primary btn-sm disabled'
                          style={{display: 'none'}}
                        >
                          Sincronizar
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title:
                                'Você tem certeza que deseja sincronizar o pedido com id: ' +
                                prevenda.id +
                                '?',
                              icon: 'info',
                              showCancelButton: true,
                              confirmButtonText: 'Sim',
                              cancelButtonText: 'Não',
                              reverseButtons: true,
                              showLoaderOnConfirm: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setLoading(true);
                                sincronizarPrevenda(prevenda.id)
                                  .then((response) => {
                                    let responseinfo = response.data;
                                    if (response.status === 200) {
                                      setLoading(false);
                                      Swal.fire({
                                        icon: 'success',
                                        title: 'Pedido sincronizada com sucesso!',
                                        confirmButtonText: 'Ok',
                                        reverseButtons: true,
                                      });
                                      fetchPrevendas(); // Recarrega a lista de prevendas após a clonagem
                                    } else if (response.status === 400) {
                                      setLoading(false);
                                      const errorMessage = responseinfo.message;
                                      Swal.fire({
                                        icon: 'info',
                                        title: 'Verifique as informações',
                                        text:
                                          errorMessage ||
                                          'Ocorreu um erro ao tentar sincronizar o pedido. Por favor, tente novamente mais tarde.',
                                        confirmButtonText: 'Ok',
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    setLoading(false);
                                    console.error(error);
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Erro ao sincronizar pedido',
                                      text:
                                        error.response?.data?.message ||
                                        'Ocorreu um erro ao tentar sincronizar o pedido. Por favor, tente novamente mais tarde.',
                                      confirmButtonText: 'Ok',
                                    });
                                  });
                              }
                            });
                          }}
                          className='btn btn-success btn-sm'
                          style={{backgroundColor: '#3fd975', borderColor: '#0099ff'}}
                        >
                          Sincronizar
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div ref={tableEndRef}></div>
            {hasMore && (
              <div className='d-flex justify-content-center align-items-center'>
                <button className='btn btn-primary m-5' onClick={loadMore}>
                  Carregar Mais
                </button>
              </div>
            )}
          </>
        )}
      </div>

      <ModalGerarPrazo
        isOpen={isGerarPrazoModal}
        onHide={closeGerarPrazoModal}
        selectItens={selectedId}
      ></ModalGerarPrazo>

      <ModalGerarPedido
        isOpen={isGerarPedidoModal}
        onHide={closeGerarPedidoModal}
        selectItens={selectedId}
      ></ModalGerarPedido>

      <ModalSolicitarGarantia
        isOpen={isSolicitarGarantiaModal}
        onHide={closeGerarGarantiaModal}
        idItem={isIdPrevenda}
      />

      <Modal
        size={'xl'}
        show={modalShowHistoricoCompraCliente}
        centered={true}
        onHide={() => setModalShowHistoricoCompraCliente(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Histórico de Compras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clienteSelecionado && (
            <div className=''>
              {/* Defina a altura máxima e adicione rolagem vertical  */}
              <h3>{clienteSelecionado.nome}</h3>
              <hr />
              <div>
                {historicoCompras.length > 0 ? (
                  renderizarHistoricoCompras(historicoCompras)
                ) : (
                  <p>Não há histórico de compras disponível para este cliente.</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalShowHistoricoCompraCliente(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button
            variant='primary'
            onClick={() => imprimirPrevenda(idPrevenda, setLoading, tipoPDF)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{pdfUrl && <PDFView source={pdfUrl} />}</Modal.Body>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer2}
        onHide={() => setModalShowPDFViewer2(false)}
        style={{width: '100%'}}
      >
        <Modal.Header style={{justifyContent: 'flex-end', gap: 10}}>
          <Button variant='primary' onClick={() => imprimirPDFLightkorp(idPrevenda, setLoading)}>
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer2(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{pdfUrl && <PDFView source={pdfUrl} />}</Modal.Body>
      </Modal>
    </div>
  );
}
