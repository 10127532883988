import * as Yup from 'yup';

export const pedidoCompraValidationSchema = Yup.object().shape({
  valorPedido: Yup.number().required('Por favor, adicione um valor do pedido'),
});

export const pedidoCompraItemsValidationSchema = Yup.object().shape({
  idProduto: Yup.number().required('Por favor, adicione um produto'),
});

export const pedidoCompraNotaFiscalSchema = Yup.object().shape({
  chave: Yup.number()
    .required('Por favor, adicione uma chave')
    .max(10 ** 44 - 1, 'A chave deve ter no máximo 44 dígitos'), // Limita a 44 dígitos
  numeroNota: Yup.number()
    .required('Por favor, adicione um número de nota')
    .max(10 ** 9 - 1, 'O número da nota deve ter no máximo 9 dígitos'), // Limita a 9 dígitos
  valor: Yup.number().required('Por favor, adicione um valor'),
});
