import axios, { AxiosResponse } from 'axios';

export interface HttpRequestParams<D = any> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  data?: D;
  id?: string | number;
}

export class HttpClient {
  private API_SERVER_URL = process.env.REACT_APP_API_URL;

  async request<T, D = undefined>(params: HttpRequestParams<D>): Promise<AxiosResponse<T>> {
    let finalUrl = `${this.API_SERVER_URL}/${params.url}`;

    if ((params.method === 'GET' || params.method === 'DELETE') && params.id) {
      finalUrl = `${this.API_SERVER_URL}/${params.url}/${params.id}`;
    }

    if (params.method === 'PUT' && params.id) {
      finalUrl = `${this.API_SERVER_URL}/${params.url}/${params.id}`;
    }

    const response: AxiosResponse<T> = await axios({
      method: params.method,
      url: finalUrl,
      data: params.method === 'POST' || params.method === 'PUT' ? params.data : undefined,
    });

    return response;
  }
}
