import {useContext, useEffect, useMemo, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';
import {
  DescriptionComponent,
  formatarData,
  IConsultaProdutos,
  INotaFiscal,
  Modal,
} from '../../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useParams} from 'react-router-dom';
import {
  getConsultaByNotaFiscal,
  getNotaFiscalByPedidoCompraId,
} from '../../../../../listagem/listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {CustomRow} from '../../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {vincularNotaFiscal} from '../../../../cadastro_requests/cadastro_requests';
import {FaFileInvoice} from 'react-icons/fa';
import {usePedidoCompraContext} from '../../../hook/pedido-compra-hook';
import PedidoCompraHookContext from '../../../context/pedido-compra-context';
import {IoMdSearch} from 'react-icons/io';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  handleRequest?: () => void;
};

const NotaFiscalModal: React.FC<Props> = ({isOpen, onHide, selectItens, handleRequest}) => {
  const context = useContext(PedidoCompraHookContext);

  const renderCellValue = (value: any) => (value ? value : '...');

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [isSelectedChave, setIsSelectedChave] = useState<string | number | BigInt>();

  const handleRadioChange = (id: number, keyNfe: string | number | BigInt) => {
    setSelectedRow(id);
    setIsSelectedChave(keyNfe);
  };

  const pedidoCompraNotaFiscalColumns: Column<INotaFiscal>[] = [
    {
      Header: 'Vinculação',
      accessor: 'id',
      Cell: ({value, row}) => (
        <div className='text-center d-flex flex-column align-items-center'>
          <div className='form-check form-check-custom form-check-solid '>
            <input
              className='form-check-input'
              type='radio'
              name='vinculacao'
              value={value}
              onChange={() => handleRadioChange(Number(value), row.original.chave!)}
            />
          </div>
        </div>
      ),
    },
    {
      Header: 'Fornecedor',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Número da Nota',
      accessor: 'numeroNota',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Chave',
      accessor: 'chave',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataEmissaonota',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },

    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
  ];

  const consultaProdutosColumns: Column<IConsultaProdutos>[] = [
    {
      Header: 'Código de SKU',
      accessor: 'codigoSku',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Volume Nfe',
      accessor: 'nfeVolume',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },

    {
      Header: 'Quantidade Solicitada',
      accessor: 'quantidadeSolicitada',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor do Item',
      accessor: 'precoItem',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },

    {
      Header: 'Valor Nfe',
      accessor: 'nfeValorItem',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
  ];

  const [isPedidoNotaFiscalData, setIsPedidoNotaFiscalData] = useState<INotaFiscal[]>([]);
  const [isProdutoConsultaData, setIsProdutoConsultaData] = useState<IConsultaProdutos[]>([]);

  const [isConsultaNfe, setIsConsultaNfe] = useState<boolean>(false);

  const [isLoadingNotaFiscal, setIsLoadingNotaFiscal] = useState<boolean>(false);

  const [isLoadingConsulta, setIsLoadingConsulta] = useState<boolean>(false);

  const data = useMemo(() => isPedidoNotaFiscalData, [isPedidoNotaFiscalData]);
  const columns = useMemo(() => pedidoCompraNotaFiscalColumns, []);

  const data2 = useMemo(() => isProdutoConsultaData, [isProdutoConsultaData]);
  const columns2 = useMemo(() => consultaProdutosColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  useEffect(() => {
    setIsConsultaNfe(false);
  }, [selectedRow]);

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headers: headers2,
    rows: rows2,
    prepareRow: prepareRow2,
  } = useTable({
    columns: columns2,
    data: data2,
  });

  const {id} = useParams();

  const submitButton = isConsultaNfe ? (
    <Button
      type='submit'
      variant='success'
      disabled={!selectedRow ? true : false}
      onClick={() => confirmAction()}
    >
      {isLoadingNotaFiscal ? <Spinner animation='border' size='sm' /> : `Vincular Nfe `}
    </Button>
  ) : (
    <Button
      type='submit'
      variant='success'
      disabled={!selectedRow ? true : false}
      onClick={() => getConsulta()}
    >
      {isLoadingConsulta ? <Spinner animation='border' size='sm' /> : `Consultar `}
      {isLoadingConsulta ? <></> : <IoMdSearch />}
    </Button>
  );

  const confirmAction = async () => {
    const result = await Swal.fire({
      title: 'Confirmação:',
      text: 'Deseja realmente associar os itens à nota fiscal?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, editar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      handleVinculacaoNota();
    }
  };

  const onClose = () => {
    onHide!();

    setIsConsultaNfe(false);
  };

  const handleVinculacaoNota = async () => {
    setIsLoadingNotaFiscal(true);

    try {
      await vincularNotaFiscal(selectedRow!, selectItens!, id!);

      let text;

      if (selectItens?.length === 1) {
        text = 'Item vinculado à nota fiscal com sucesso';
      } else {
        text = 'Itens vinculados à nota fiscal com sucesso';
      }

      setIsLoadingNotaFiscal(false);

      onSuccess(text);

      if (handleRequest) {
        handleRequest();
      }

      if (context) {
        context.actionTap(); // Chama a função configurada em outra página
      }

      setSelectedRow(null)
    } catch (errors: any) {
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingNotaFiscal(false);
    }
  };

  const getConsulta = async () => {
    setIsLoadingConsulta(true);
    try {
      const response = await getConsultaByNotaFiscal(selectedRow!, selectItens!, id!);

      setIsConsultaNfe(true);

      const {data} = response;

      setIsProdutoConsultaData(data);

      setIsLoadingConsulta(false);
    } catch (errors: any) {
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingConsulta(false);
      setIsConsultaNfe(false);
    }
  };

  const onSuccess = (text: string) => {
    Swal.fire({
      icon: 'success',
      title: text,
      showCancelButton: false,
      timer: 3000,
      timerProgressBar: false,
    });

    onClose();
  };

  const loadNotaFiscalByPedidoCompraId = async () => {
    if (id) {
      try {
        const response = await getNotaFiscalByPedidoCompraId(id);

        const {content} = response.data;



        setIsPedidoNotaFiscalData(content);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadNotaFiscalByPedidoCompraId();
    }
  }, [isOpen]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Vinculação de Nota Fiscal',
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  return (
    <>
      <Modal {...modalConfigs} icon={FaFileInvoice}>
        <DescriptionComponent
          description='Listagem de Notas Fiscais Referente ao Pedido de Compra'
          isSub
        />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<INotaFiscal>, index: number) => (
                  <th
                    key={index}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<INotaFiscal>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={row.id || `row-${i}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM NOTAS CADASTRADAS PARA O PEDIDO
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isConsultaNfe && (
          <>
            <DescriptionComponent description='Consulta de Produtos' isSub />
            <div className='table-responsive' style={{maxHeight: 350}}>
              <table
                id='kt_table_formularios'
                className='table table-hover table-striped table-rounded table-row-bordered border px-4'
                {...getTableProps2()}
              >
                <thead className='thead-dark'>
                  <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers2.map((column: ColumnInstance<IConsultaProdutos>, index: number) => (
                      <th
                        key={index}
                        className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps2()}>
                  {rows2.length > 0 ? (
                    rows2.map((row: Row<IConsultaProdutos>, i) => {
                      prepareRow2(row);
                      return <CustomRow row={row} key={row.id || `row-${i}`} />;
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          SEM ITENS
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default NotaFiscalModal;
