import {useEffect, useMemo, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';
import {DescriptionComponent, formatarData, IFrete, INotaFiscal, Modal} from '../../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useParams} from 'react-router-dom';
import {
  getFretesByPedidoCompraId,
  getNotaFiscalByPedidoCompraId,
} from '../../../../../listagem/listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {CustomRow} from '../../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {vincularFrete, vincularNotaFiscal} from '../../../../cadastro_requests/cadastro_requests';
import { FaTruck } from "react-icons/fa";

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  handleRequest?: () => void;
};

const FreteModal: React.FC<Props> = ({isOpen, onHide, selectItens, handleRequest}) => {
  const renderCellValue = (value: any) => (value ? value : '...');

  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const handleRadioChange = (id: number) => {
    setSelectedRow(id);
  };

  const pedidoCompraFreteColumns: Column<IFrete>[] = [
    {
      Header: 'Vinculação',
      accessor: 'id',
      Cell: ({value}) => (
        <div className='text-center d-flex flex-column align-items-center'>
          <div className='form-check form-check-custom form-check-solid '>
            <input
              className='form-check-input'
              type='radio'
              name='vinculacao'
              value={value}
              onChange={() => handleRadioChange(Number(value))}
            />
          </div>
        </div>
      ),
    },
    {
      Header: 'Id do Pedido de Compra',
      accessor: 'idPedidoCompra',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Tipo de Frete',
      accessor: 'tipoFrete',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Modelo',
      accessor: 'modelo',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Endereço de Origem',
      accessor: 'enderecoOrigem',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Endereço de Destino',
      accessor: 'enderecoDestino',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Data de Entrega',
      accessor: 'dataEntrega',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data Prevista',
      accessor: 'dataPrevisao',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Saída',
      accessor: 'dataSaida',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor do Frete',
      accessor: 'valorFrete',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Valor do Seguro',
      accessor: 'valorSeguro',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Fornecedor',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
  ];

  const [isFreteData, setIsFreteData] = useState<INotaFiscal[]>([]);

  const [isLoadingFrete, setIsLoadingFrete] = useState<boolean>(false);

  const data = useMemo(() => isFreteData, [isFreteData]);
  const columns = useMemo(() => pedidoCompraFreteColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const {id} = useParams();

  const submitButton = (
    <Button
      type='submit'
      variant='success'
      disabled={!selectedRow ? true : false}
      onClick={() => handleVinculacaoNota()}
    >
      {isLoadingFrete ? <Spinner animation='border' size='sm' /> : 'Vincular Frete'}
    </Button>
  );

  const handleVinculacaoNota = async () => {
    setIsLoadingFrete(true);

    try {
      await vincularFrete(selectedRow!, selectItens!);

      let text;

      if (selectItens?.length === 1) {
        text = 'Item vinculado ao frete com sucesso';
      } else {
        text = 'Itens vinculados ao frete com sucesso';
      }

      setIsLoadingFrete(false);

      onSuccess(text);

      if (handleRequest) {
        handleRequest();
      }
    } catch (errors: any) {
      const {data} = errors.response;
      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingFrete(false);
    }
  };
  
  const onClose = () => {
    onHide!();
  };

  const onSuccess = (text: string) => {
    Swal.fire({
      icon: 'success',
      title: text,
      showCancelButton: false,
      timer: 3000,
      timerProgressBar: false,
    });

    onClose();
  };

  const loadFreteById = async () => {
    if (id) {
      try {
        const response = await getFretesByPedidoCompraId(id);

        const {content} = response.data;

        setIsFreteData(content);

        console.log(response);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadFreteById();
    }
  }, [isOpen]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Vinculação de Frete',
    actions: [submitButton],
    onHide: onClose,
    size: 'xl',
  };

  return (
    <>
      <Modal {...modalConfigs} icon={FaTruck}>
      <DescriptionComponent
      description='Listagem de Fretes Referente ao Pedido de Compra'
      isSub
      />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IFrete>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IFrete>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM FRETES CADASTRADOS PARA O PEDIDO
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default FreteModal;
