import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
// import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
// import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
import { is } from "date-fns/locale";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { AsyncSelectCliente, AsyncSelectEmpresa, AsyncSelectFornecedor, AsyncSelectProjetos, Modal } from "../../../../shared";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalFaturaProjeto = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isConfirme, setIsConfirme] = useState<boolean>(false)

    const [valor, setValor] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [selectedStatus, setSelectedStatus] = useState<ClienteOption | null>(null)
    const [selectedProjeto, setSelectedProjeto] = useState<ClienteOption | null>(null)
    const [dataEmissao, setDataEmissao] = useState<string | null>(null);
    const [dataVencimento, setDataVencimento] = useState<string | null>(null);
    const [dataPagamento, setDataPagamento] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>('ATIVO')

    const cadastrarFaturaProjeto = async () => {
        try {
            setIsLoading(true)
            if (
                selectedProjeto && 
                selectedStatus  &&
                (valor !== null && valor !== '0') && 
                (dataEmissao !== null && dataEmissao !== '') &&
                (dataVencimento !== null && dataVencimento !== '') &&
                (dataPagamento !== null && dataPagamento !== '')
                ){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-fatura`;

            const response = await axios.post(url, {
                status: status,
                idStatusPagamento: selectedStatus ? selectedStatus.value : null,
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                dataEmissao: dataEmissao ? `${dataEmissao}T17:00:52.864` : null,
                dataVencimento: dataVencimento ? `${dataVencimento}T17:00:52.864` : null,
                dataPagamento: dataPagamento ? `${dataPagamento}T17:00:52.864` : null,
                valorFatura: valor ? valor : null,
                descricao: descricao ? descricao : null,
            });

            Swal.fire({
                icon: 'success',
                title: `Fatura do Projeto cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                reload!()
                onClose()
            })

            }
        }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }
    const loadOptionsStatus = async (inputValue: string, state: Function) => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro-pagamento-status/listar`;

            const response = await axios.get(url, {
                params: { status: 'ATIVO' }
            });
            const data = await response.data;
            return ((data.content.map((item) => ({
                value: item.id,
                label: item.titulo,
            }
            )
            )))
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        }
    };

    const editarFaturaProjeto = async () => {
        try {
            setIsLoading(true)
            if (
                selectedProjeto && 
                selectedStatus  &&
                (valor !== null && valor !== '0') && 
                (dataEmissao !== null && dataEmissao !== '') &&
                (dataVencimento !== null && dataVencimento !== '') &&
                (dataPagamento !== null && dataPagamento !== '')
                ){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-fatura/${dataLine[0]}`;
            const response = await axios.put(url, {
                status: status,
                idStatusPagamento: selectedStatus ? selectedStatus.value : null,
                idProjeto: selectedProjeto ? selectedProjeto.value : null,
                dataEmissao: dataEmissao ? `${dataEmissao}T17:00:52.864` : null,
                dataVencimento: dataVencimento ? `${dataVencimento}T17:00:52.864` : null,
                dataPagamento: dataPagamento ? `${dataPagamento}T17:00:52.864` : null,
                valorFatura: valor ? valor : null,
                descricao: descricao ? descricao : null,
            });
            Swal.fire({
                icon: 'success',
                title: `Fatura do Projeto editada com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                reload!()
                onClose()

            });

            }
        }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
        finally{
            setIsLoading(false)
        }
    }

    const onClose = () => {
        clearForm()
        onHide!();
    }

    const clearForm = () => {
        setSelectedStatus(null)
        setSelectedProjeto(null)
        setValor(null)
        setDataEmissao(null)
        setDataVencimento(null)
        setDataPagamento(null)
        setDescricao(null)
    }

    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarFaturaProjeto() : cadastrarFaturaProjeto()}
            disabled={isConfirme===false}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };

    function formatDate(inputDate: string): string {
        const date = new Date(inputDate);

        if (isNaN(date.getTime())) {
            throw new Error("Data inválida");
        }

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const fetchDataProjetosById = async (page = 0) => {

        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/projetos-fatura/${dataLine[0]}`);

            const data = response.data
            if (response.status === 200) {
                if (page === 0) {
                    // setLogs([]);
                }
                console.log(response.data.content)
                setSelectedProjeto({value: data.idProjeto, label: ''})
                setValor(data.valorFatura)
                setDataEmissao(formatDate(data.dataEmissao))
                setDataVencimento(formatDate(data.dataVencimento))
                setDataPagamento(formatDate(data.dataPagamento))
                setSelectedStatus({value: data.idStatusPagamento, label: data.tituloStatusPagamento})
                setStatus(data.status)
                setDescricao(data.descricao)
            }
        } 
        catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    // setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } 
    }

    useEffect(() => {
        if(isEdit){
        fetchDataProjetosById()
    }
    },[dataLine])

    useEffect(()=>{
        if(selectedProjeto && 
            selectedStatus  &&
            (valor !== null && valor !== '0') && 
            (dataEmissao !== null && dataEmissao !== '') &&
            (dataVencimento !== null && dataVencimento !== '') &&
            (dataPagamento !== null && dataPagamento !== '')){
                setIsConfirme(true)
            }
        else{
            setIsConfirme(false)
        }
    },[
        selectedProjeto,
        selectedStatus,
        valor,
        dataEmissao,
        dataVencimento,
        dataPagamento,
    ])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Fatura do Projeto
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form className='form-control-solid row gap-6 mb-5'>
                    <Row className='col-12 mt-5'>
                        <Col>
                            <label htmlFor="" className='form-label'>Projeto:</label>
                            <AsyncSelectProjetos inputOption={selectedProjeto} onKeyDown={() => { setSelectedProjeto(null) }} handleOptions={(option) => { 
                                console.log(option)
                                setSelectedProjeto(option)}}  ></AsyncSelectProjetos>
                            {(selectedProjeto === null) && <span className={` form-label text-danger`}>*Por favor, informe o Projeto </span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="" className='form-label'>Valor da Fatura:</label>
                            <input type="text" value={
                                valor
                                    ? `R$ ${parseFloat(String(valor)).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                    : 'R$ 0,00'
                            }
                                onChange={(event) => {
                                    const rawValue = event.target.value;
                                    const cleanedValue = rawValue.replace(/[^\d]/g, '');  // Remove caracteres não numéricos
                                    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;  // Divide por 100 se houver valor
                                    setValor(numericValue.toString());
                                }} className="form-control" placeholder="Digite o valor" />
                                {(valor === null || valor === '0') && <span className={` form-label text-danger`}>*Por favor, informe o Valor da Fatura </span>}
                        </Col>
                        <Col>
                            <label className='form-label' htmlFor="">Data de  emissão</label>
                            <input type="date" onChange={(e) => setDataEmissao(e.target.value)} value={dataEmissao ? dataEmissao : ""} name="" className='form-control' id="" />
                            {(dataEmissao === null || dataEmissao === '' ) && <span className={` form-label text-danger`}>*Por favor, informe a Data de emissão </span>}

                        </Col>
                    </Row>

                    <Row className='col-12 '>

                        <Col>
                            <label className='form-label' htmlFor="">Data de vencimento</label>
                            <input type="date" onChange={(e) => setDataVencimento(e.target.value)} value={dataVencimento ? dataVencimento : ""} name="" className='form-control' id="" />
                            {(dataVencimento  === null || dataVencimento === '' ) && <span className={` form-label text-danger`}>*Por favor, informe a Data de vencimento </span>}

                        </Col>
                        <Col>
                            <label className='form-label' htmlFor="">Data de pagamento</label>
                            <input type="date" onChange={(e) => setDataPagamento(e.target.value)} value={dataPagamento ? dataPagamento : ""} name="" className='form-control' id="" />
                            {(dataPagamento  === null || dataPagamento === '' ) && <span className={` form-label text-danger`}>*Por favor, informe a Data de Pagamento </span>}
                        </Col>
                    </Row>
                    <Row>


                        <Col>
                            <label className='form-label' htmlFor="">Status do Pagamento</label>
                            <AsyncSelect defaultOptions value={selectedStatus} onKeyDown={() => { setSelectedStatus(null) }} onChange={(option) => { setSelectedStatus(option) }} loadOptions={loadOptionsStatus} placeholder="Selecione o Status do Pagamento" ></AsyncSelect>
                            {(selectedStatus  === null) && <span className={` form-label text-danger`}>*Por favor, informe o Status do Pagamento </span>}
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className=''>
                        <Col>
                            <label className='form-label' htmlFor="">Descrição</label>
                            <textarea name="" onChange={(e) => setDescricao(e.target.value)} value={descricao ? descricao : ""} placeholder='Digite a descrição do formularios' className='form-control' id=""></textarea>
                        </Col>
                    </Row>
                </form>
            </div>
        </Modal >
    )
}

export default ModalFaturaProjeto